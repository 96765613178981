import React from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { BANKS } from 'services/constants/bank'
import { comma } from 'services/utils'

import { Header, Lnr, Caret, Checkbox, DataSet, MetaSet, Meta, Buttons, Button } from './utils'

class SummaryContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  initialState(props) {
    const state = { error: false, loading: true || {} }
    return state
  }

  async initialize() {
    this.setState({ error: false, loading: false })
  }

  render() {
    const { error, loading } = this.state
    if (loading) { return null }

    if (error) {
      return (
        <div>
          <Header>
            <Header.Lead>등록정보</Header.Lead>
          </Header>
          <div>
            <div>문제가 발생하였습니다.</div>
            <div><small>{error}</small></div>
          </div>
        </div>
      )
    }

    const { parent } = this.props
    const item = parent.item || {}

    // 메타정보
    const meta = {}
    meta.bankAccountLastAt = _.get(item, 'bankAccount.updatedAt') || _.get(item, 'bankAccount.createdAt')
    meta.withdrawLimitAt = _.get(item, 'withdrawLimitAt')

    return (
      <div>
        <Header>
          <Header.Lead>등록정보</Header.Lead>
          <Header.Buttons>
          </Header.Buttons>
        </Header>

        <MetaSet>
          <Meta>
            <div>계정번호</div>
            <div><strong>{_.get(item, 'userNo') || '정보없음'}</strong></div>
          </Meta>
          <Meta>
            <div>계정명(ID)</div>
            <Meta.Control>
              <input
                type="text" value={item.accountId || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.accountId': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>실명</div>
            <Meta.Control>
              <input
                type="text" value={item.name || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.name': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>주민번호</div>
            <div
              className="pointer"
              onClick={e => parent.openSider('userRegisterNumber', {})}
            >
              <Caret c={_.get(item, 'registrationNumber.certed') ? "success" : 'danger'} />
              <strong>{_.get(item, 'registrationNumber.certed') ? '등록완료' : '미등록'}</strong>
            </div>
          </Meta>
          <Meta>
            <div>생일</div>
            <Meta.Control>
              <input
                type="text" value={item.birthday || ''} placeholder={`19700101`}
                onChange={e => parent.handle({ 'item.birthday': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>성별</div>
            <Meta.Control>
              <select
                value={item.gender}
                onChange={e => parent.handle({ 'item.gender': e.target.value })}
                >
                <option value="none">🥷 미설정</option>
                <option value="man">🙍 남성</option>
                <option value="woman">🙍‍♀️ 여성</option>
              </select>
            </Meta.Control>
          </Meta>
          <Meta>
            <div>닉네임</div>
            <Meta.Control>
              <input
                type="text"
                value={item.nickName || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.nickName': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>이메일</div>
            <Meta.Control>
              <input
                type="text"
                value={item.email || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.email': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>휴대폰</div>
            <Meta.Control>
              <input
                type="text"
                value={item.mobile || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.mobile': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>🙅‍♀️ 로그인오류</div>
            <Meta.Control>
              <input
                type="text"
                value={item.wrongCount || ''} placeholder={`0`}
                onChange={e => parent.handle({ 'item.wrongCount': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>마이홈</div>
            <Meta.Control>
              <select
                value={item.usedProfile}
                onChange={e => parent.handle({ 'item.usedProfile': e.target.value })}
                >
                <option value="used">프로필 사용</option>
                <option value="notUsed">프로필 미사용</option>
              </select>
            </Meta.Control>
          </Meta>
          <Meta>
            <div>이모지</div>
            <Meta.Control>
              <input
                type="text" value={item.emoji || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.emoji': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <Meta.Control style={{ minWidth: '100%', height: '100px' }}>
              <textarea
                value={item.introduce || ''} placeholder={`미설정`} style={{ textAlign: 'left', height: '100px' }}
                onChange={e => parent.handle({ 'item.introduce': e.target.value })}
              />
            </Meta.Control>
          </Meta>
        </MetaSet>

        <Header>
          <Header.Lead>계좌정보</Header.Lead>
        </Header>

        <MetaSet>
          <Meta>
            <div>예금주</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'bankAccount.owner') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.bankAccount.owner': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>은행</div>
            <Meta.Control>
              <select
                value={_.get(item, 'bankAccount.bankCode') || ''}
                onChange={e => {
                  if (!e.target.value) { return }
                  const next = {}
                  next['item.bankAccount.bankCode'] = BANKS[e.target.value].name
                  next['item.bankAccount.bankName'] = BANKS[e.target.value].text
                  return parent.handle(next)
                }}
                >
                  <option value="">선택</option>
                  {Object.keys(BANKS).map((key) => <option key={`bankCode_${key}`} value={key}>{BANKS[key].text}({BANKS[key].name})</option>)}
              </select>
            </Meta.Control>
          </Meta>
          <Meta>
            <div>계좌번호</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'bankAccount.number') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.bankAccount.number': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>
              인출가능금액
              <small style={{ marginLeft: '0.15rem' }}>원</small>
            </div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'withdrawLimit') || ''} placeholder={`0`}
                onChange={e => parent.handle({ 'item.withdrawLimit': e.target.value })}
                onBlur={e => {
                  let next = _.get(item, 'withdrawLimit') || 10
                  if (next < 10) { next = 10 }
                  next = parseInt(Math.floor(next/10) * 10)
                  parent.handle({ 'item.withdrawLimit': next })
                }}
              />
            </Meta.Control>
          </Meta>
        </MetaSet>

        <Header>
          <Header.Lead>대표 배송지</Header.Lead>
        </Header>

        {(() => {
          // 기본배송지가 있는지 살피고, 없다면 0번을 배정하는 과정을 갖는다.
          const dIdx = _.get(item, 'deliveries').findIndex(delivery => delivery.default)
          let cIdx = dIdx >= 0 ? dIdx : 0
          return (
            <MetaSet>
              <Meta>
                <div>배송지명</div>
                <Meta.Control>
                  <input
                    type="text"
                    value={_.get(item, `deliveries[${cIdx}].text`)} placeholder={`미설정`}
                    onChange={e => parent.handle({ [`item.deliveries[${cIdx}].text`]: e.target.value })}
                  />
                </Meta.Control>
              </Meta>
              <Meta>
                <div>기본배송지</div>
                <div
                  className="pointer"
                  onClick={e => {
                    const modified = !_.get(item, `deliveries[${cIdx}].default`)
                    const next = {}
                    // 기존 다른 배송지들은 기본배송지에서 취소처리를 해준다.
                    if (item.deliveries.length > 1 && modified) {
                      item.deliveries.forEach((delivery, index) => {
                        next[`item.deliveries[${index}].default`] = false
                      })
                    }
                    // 현재 지정된 cIdx 값의 배송지 정보를 지정해준다.
                    next[`item.deliveries[${cIdx}].default`] = modified
                    return parent.handle(next)
                  }}
                >
                  <Checkbox c={_.get(item, `deliveries[${cIdx}].default`) ? 'on' : 'off'} />
                  <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, `deliveries[${cIdx}].default`) ? '설정됨' : '미설정'}</strong>
                </div>
              </Meta>
              <Meta>
                <div>수령자</div>
                <Meta.Control>
                  <input
                    type="text"
                    value={_.get(item, `deliveries[${cIdx}].receiver`) || ''} placeholder={`미설정`}
                    onChange={e => parent.handle({ [`item.deliveries[${cIdx}].receiver`]: e.target.value })}
                  />
                </Meta.Control>
              </Meta>
              <Meta>
                <div>우편번호</div>
                <Meta.Control>
                  <input
                    type="text" readOnly
                    defaultValue={_.get(item, `deliveries[${cIdx}].zip`)} placeholder={`미설정`}
                    onClick={e => {
                      const options = {}
                      options.onSelected = (selected) => {
                        parent.handle({ [`item.deliveries[${cIdx}].zip`]: selected.zipNo, [`item.deliveries[${cIdx}].address1`]: selected.roadAddr })
                      }
                      return parent.openSider('userFindAddress', options)
                    }}
                  />
                </Meta.Control>
              </Meta>
              <Meta>
                <div>동주소</div>
                <Meta.Control>
                  <input
                    type="text" readOnly
                    defaultValue={_.get(item, `deliveries[${cIdx}].address1`) || ''} placeholder={`미설정`}
                    onClick={e => {
                      const options = {}
                      options.onSelected = (selected, cb) => {
                        parent.handle({ [`item.deliveries[${cIdx}].zip`]: selected.zipNo, [`item.deliveries[${cIdx}].address1`]: selected.roadAddr }, cb)
                      }
                      return parent.openSider('userFindAddress', options)
                    }}
                  />
                </Meta.Control>
              </Meta>
              <Meta>
                <div>상세주소</div>
                <Meta.Control>
                  <input
                    type="text"
                    value={_.get(item, `deliveries[${cIdx}].address2`) || ''} placeholder={`미설정`}
                    onChange={e => parent.handle({ [`item.deliveries[${cIdx}].address2`]: e.target.value })}
                  />
                </Meta.Control>
              </Meta>
              <Meta>
                <div>연락처</div>
                <Meta.Control>
                  <input
                    type="text"
                    value={_.get(item, `deliveries[${cIdx}].mobile`) || ''} placeholder={`미설정`}
                    onChange={e => parent.handle({ [`item.deliveries[${cIdx}].mobile`]: e.target.value })}
                  />
                </Meta.Control>
              </Meta>
            </MetaSet>
          )
        })()}

      </div>
    )
  }
}

SummaryContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  sider: PropTypes.object,
  openSider: PropTypes.func,
  closeSider: PropTypes.func,

  item: PropTypes.object,
  initialize: PropTypes.func
}

SummaryContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  
  sider: {},
  openSider: () => {},
  closeSider: () => {},
  
  item: {},
  initialize: () => {},
  handle: () => {},
  handleSave: () => {}
}

export default SummaryContainer
