import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Page from 'components/layouts/Page'
import StockList from 'components/extras/StockList' // 현재 재고현황에 대한 리스트를 보여주는 컴포넌트
import InvoiceParcelList from 'components/extras/InvoiceParcelList' // 입금확인, 발송준비중 단계로 전환해주는 기능
import InvoiceRequestList from 'components/extras/InvoiceRequestList' // 신규발주 기능
import InvoiceQuantityList from 'components/extras/InvoiceQuantityList' // 기존발주, 수령완료 2가지를 보여주는 리스트 컴포넌트

class InvoicePageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}

    let breadcrumb = ['홈', '발주관리'], title = '발주관리'

    let navs = []
    navs.push({ name: 'stock', text: '재고현황', active: false })
    navs.push({ name: 'ready', text: '입금확인', active: false })
    navs.push({ name: 'request', text: '신규발주', active: false })
    navs.push({ name: 'working', text: '기존발주', active: false })
    navs.push({ name: 'finished', text: '수령완료', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    } 

    return (
      <Page>
        <Helmet>
          <title>발주관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>

          <Route
              path="/invoices/:nav?"
              exact
              component={(props) => {
                const { location } = props
                const search = location.search ? qs.parse(location.search) : {}

                const cProps = { admin, title: currentNav.text, nav, ...props }

                //  페이지 인자 정리
                cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
                if (search.limit) {
                  cProps.limit = Number(search.limit)
                  if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
                }
                if (search.blockLimit) {
                  cProps.blockLimit = Number(search.blockLimit)
                  if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
                }
                if (search.sorts) { cProps.sorts = search.sorts.split(',') }
                if (search.startAt) { cProps.startAt = new Date(search.startAt) }
                if (search.endAt) { cProps.endAt = new Date(search.endAt) }

                if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
                if (search.keywords) { cProps.keywords = search.keywords }

                // 필터구문 정리
                const propsKeys = Object.keys(search)
                const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
                if (filtersKeys && filtersKeys.length) {
                  cProps.filters = {}
                  filtersKeys.forEach(filtersKey => {
                    const key = filtersKey.replace('filters_', '')
                    cProps.filters[key] = search[filtersKey]
                  })
                }

                return <>
                  <Page.Nav>
                    <Page.Nav.Items>
                      {navs.map((item, index) => 
                        <Page.Nav.Item
                          key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                          onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/invoices/${item.name}`]}
                        >
                          <span>{item.text}</span>
                        </Page.Nav.Item>
                      )}
                    </Page.Nav.Items>
                  </Page.Nav>
                  {['stock'].includes(nav) ? <StockList {...cProps} nav="products" /> : null}
                  {['ready'].includes(nav) ? <InvoiceParcelList {...cProps} /> : null}
                  {['request'].includes(nav) ? <InvoiceRequestList {...cProps} /> : null}
                  {['working', 'finished'].includes(nav) ? <InvoiceQuantityList {...cProps} /> : null}
                </>
              }}
            />

        </Switch>
      </Page>
    )
  }
}

InvoicePageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

InvoicePageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default InvoicePageContainer
