// @ 유통사 코드 강제 배열
export const retailsKeys = { 
  yes24: 'Yes24',  kyobo: '교보',  aladin: '알라딘',  bookxen: '북센', 
  ypmungo: '영풍',  millie: '밀리',  ridibooks: '리디',  bookcube: '북큐브',  
  others: '기타' 
}

// @ 자동 점검 체크 기능이 활성화된 유통사
export const enabledAutoChecks = ['kyobo', 'yes24', 'ypmungo', 'aladin', 'bookxen']

export default { retailsKeys, enabledAutoChecks }