import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'
import salesExportParser from './export.sales'

import NoColumn from './Columns/NoColumn'
import PayColumn from './Columns/PayColumn'
import PriceColumn from './Columns/PriceColumn'
import DeliveryColumn from './Columns/DeliveryColumn'
import DownloadColumn from './Columns/DownloadColumn'
import StateColumn from './Columns/StateColumn'
import UserDetailColumn from './Columns/UserDetailColumn'
import CreatedDateColumn from './Columns/CreatedDateColumn'
import PaidDateColumn from './Columns/PaidDateColumn'

import Dropdown from './Dropdown'
// import Menus from './Menus'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, resource, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({ key: 'NoColumn', header: '주문번호', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'CreatedDateColumn', header: '주문일시', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'PaidDateColumn', header: '결제일시', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'PayColumn', header: '주문 결제내역', style: { minWidth: '450px', maxWidth: '450px', justifyContent: 'flex-start' } })
  columns.push({ key: 'PriceColumn', header: '총 결제금액', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center' } })
  columns.push({ key: 'ReceiverFromUserDetailColumn', header: '수취인명', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', textAlign: 'center' } })
  columns.push({ key: 'AccountIdFromUserDetailColumn', header: '계정명', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'center', textAlign: 'center' } })
  if (['book'].includes(nav)) {
    // columns.push({ key: 'DeliveryColumn', header: '상세정보', style: { minWidth: '240px', maxWidth: '240px', justifyContent: 'flex-start' } })
  } else if (['paperBook'].includes(nav)) {
    // columns.push({ key: 'DeliveryColumn', header: '발송지 정보', style: { minWidth: '240px', maxWidth: '240px', justifyContent: 'flex-start' } })
  } else if (['electronicBook'].includes(nav)) {
    columns.push({ key: 'DownloadColumn', header: '다운로드 정보', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start' } })
  }
  columns.push({ key: 'BlankColumn', header: '', style: { minWidth: '0', flex: '1 1 auto', justifyContent: 'flex-start' } })
  columns.push({ key: 'StateColumn', header: '결제내역 주문상태', style: { minWidth: '250px', maxWidth: '250px', justifyContent: 'center' } })
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })

  // 관리자로부터 아애 도서 제출 취소 기능(바로 취소상태로 옮겨지는 기능)
  const deleteApproval = async (item) => {
    if (!window.confirm(`도서제출 내역을 삭제시, 제출된 도서는 임시도서로 이동하고, 제출된 문서는 취소처리, 임시상품이 삭제되며, 사용자 화면에서 이미 종결된 문서로 노출되지 않고 삭제 됩니다. 완전 소거를 할까요?`)) { return false }
    return await api.delete(`/approvals/admin2/${item.id}/bookSubmit`)
      .then((data) => {
        alert(`정상적으로 처리하였습니다.`)
        return loadItems()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return loadItems()
      })
  }

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  const actions = {}
  
  // @ 전체 데이터 불러오기
  actions.getItems = async (query = {}, options = {}) => {
    const lump = 100
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length} 블록으로 나누어 다운로드중...`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 검색필터 기준으로 도서 데이터 가져오기
  actions.downloadExcel = async (items = selecteds, options = {}) => {
    if (!items || items.length === 0) { return alert(`선택한 데이터가 없습니다.`) }

    const wired = []
    if (options && ['orders'].includes(options.pivot)) {
      // 반대로 자식에 부모를 extra로 변환해 붙여준다. product 값을 써야한다 요청 @ 박수호 (서점정보, 카테고리 등 필요)
      const orders = items.reduce((report, row) => {
        const rebasedOrders = (row.currentOrders || [])
          .map(order => {
            if (!order.extras) { order.extras = {} }
            const pay = JSON.parse(JSON.stringify(row))
            delete pay.currentOrders
            order.extras.pay = pay
            return order
          })
        report.push(...rebasedOrders)
        return report
      }, [])
      wired.push(...orders)
    }

    console.log('items', items)
    console.log('wired', wired)

    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser: salesExportParser, items: wired })
      .download(`판매현황 - 총 ${items.length}건 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
          
        <Header.Options>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.downloadExcel(selecteds, { pivot: 'orders' })
            }}
          >
              주문별 선택엑셀 📥
          </a>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const targets = await actions.getItems({}, { progress: true }).catch(e => [])
              return await actions.downloadExcel(targets, { pivot: 'orders' })
            }}
          >
              주문별 전체엑셀 📥
          </a>
        </Header.Options>
      </Header>

      {/* 
        {nav === 'bookSubmit'
          ? (
            <Menus
              items={filterOptions.bookType.conditions}
              isActive={item => {
                if (!filters.bookType) { return false }
                return item.name === filters.bookType
              }}
              usedAll
              onActive={item => handle({ page: 1, filters: { ...filters, bookType: item.name } })}
              {...commonProps}
            />
          )
        : null} */}

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.parent = _.get(item, 'parent')
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.isBrunch = _.get(item, 'content.brunch')
                    meta.productId = _.get(item, 'id') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'NoColumn':
                              return <NoColumn {...columnProps} />
                            case 'CreatedDateColumn':
                              return <CreatedDateColumn {...columnProps} />
                            case 'PaidDateColumn':
                              return <PaidDateColumn {...columnProps} />
                            case 'PayColumn':
                              return <PayColumn {...columnProps} />
                            case 'PriceColumn':
                              return <PriceColumn {...columnProps} />
                            case 'ReceiverFromUserDetailColumn':
                              return <UserDetailColumn columns={['receiver']} {...columnProps} />
                            case 'AccountIdFromUserDetailColumn':
                              return <UserDetailColumn columns={['accountId']} {...columnProps} />
                            case 'StateColumn':
                              return <StateColumn {...columnProps} />
                            case 'DeliveryColumn':
                              return <DeliveryColumn {...columnProps} />
                            case 'DownloadColumn':
                              return <DownloadColumn {...columnProps} />
                            case 'ControlColumn':
                              return (
                                <div>
                                  {['wait', 'review', 'cancel'].includes(item.status)
                                    ?<a href="#deleteApproval" onClick={e => [e.stopPropagation(), e.preventDefault(), deleteApproval(item)]} style={{ margin: '0 0.35rem' }}>삭제</a>
                                    : null}
                                </div>
                              )

                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
