import moment from 'services/moment'

const parser = {}

parser.columns = [
  { header: '수하인명', key: 'receiver', view: (item) => item.receiver },
  { header: '수하인전화번호', key: 'mobile', view: (item) => item.mobile },
  { header: '받는분담당자', key: 'receiver', view: (item) => item.receiver },
  { header: '수하인핸드폰번호', key: 'mobile', view: (item) => item.mobile },
  { header: '우편번호', key: 'zip', view: (item) => item.zip },
  { header: '수하인주소', key: 'address', view: (item) => `${item.address1} ${item.address2}` },
  { header: '박스수량', key: 'amount', view: (item) => 1 },
  {
    header: '품목', key: 'parcelType',
    view: (item) => {
      const meta = {}
      switch (item.parcelType) {
        case 'paperBook':
          meta.text = '종이도서'
          break
        default: 
          meta.text = '기타'
      }
      return meta.text
    }
  },
  { header: '택배운임', key: 'type', view: (item) => 'A' },
  {
    header: '운임구분', key: 'charge',
    view: (item) => {
      const meta = {}
      switch (item.retail) {
        case 'bookk':
          meta.text = item.charge === 'pre' ? '' : '착불'
          break
        default: 
          meta.text = item.charge === 'pre' ? '선불' : ''
      }
      return meta.text
    }
  },
  {
    header: '주문번호', key: 'pay',
    view: (item) => `${item.pays[0]}`
  },
  {
    header: '배송메시지',
    key: 'message',
    view: (item) => item.message
  },
]

export default parser
