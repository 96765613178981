import React from 'react'
import api from 'services/api'

import { Item } from './utils'
import _ from 'lodash'
import moment from 'services/moment'

const UserLogs = ({ ...props }) => {
  console.log(`props`, props)

  const [state, setState] = React.useState({ items: [], count: 0, loading: true })
  const actions = {}
  actions.loadItems = async () => {
    const userId = _.get(props, 'item.id')
    const { rows, count } = await api.get(`/users/admin2/${userId}/logs`).catch((e) => ({ rows: [], count: 0 }))
    setState({ ...state, loading: false, items: rows, count })
  }

  React.useEffect(() => {
    actions.loadItems()
  }, [])

  if (state.loading) { return null }

  const items = state.items || []

  return (
    <Item.Group>
      {items.map((item, idx) => {
        const meta = {}
        meta.createdMt = item.createdAt ? moment(item.createdAt) : null
        meta.content = item.content || '빈 로그입니다.'
        meta.context = item.context || {}
        return (
          <Item key={`item-${idx}`}>
            <details>
              <summary style={{ padding: '0.5rem 0', cursor: 'pointer' }}>
                <span style={{ marginRight: '0.35rem' }}>
                  {meta.content}
                </span>
                <span>
                  <span style={{ marginRight: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</span>
                  <small style={{ opacity: 0.5 }}>
                    {meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}
                  </small>
                </span>
              </summary>
              <div style={{ padding: '0.75rem', borderRadius: '0.5rem', background: '#fafafa' }}>
                <small style={{ opacity: '0.5' }}>
                  {JSON.stringify(meta.context)}
                </small>
              </div>
            </details>
          </Item>
        )
      })}
    </Item.Group>
  )
}

export default UserLogs