import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'

import { comma } from 'services/utils'
import { BANKS } from 'services/constants/bank'

import Modal from 'components/utils/Modal'

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.5rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

// 수기환불 모달 구성
class CreateRefundApprovalModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    const pay = props.pay || {}

    this.initializedState = {
      loading: true,
      form: {
        payId: props.payId,
        orderIds: pay.orders, 
        refundSubject: `${pay.subject} 환불 수기신청(관리자 수기 기재)`,
        refundPrice: pay.totalPrice || 0,
        bank: null,
        bankName: '',
        walletOwner: '',
        walletAddress: ''
      }
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doCreate = this.doCreate.bind(this)
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const form = JSON.parse(JSON.stringify(this.initializedState.form))
    const next = { form }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async doCreate() {
    const { payId, onSuccess } = this.props

    const { form } = this.state

    if (!form.refundSubject) { return alert('환불 사유 정보가 없습니다.') } 
    if (!form.refundPrice) { return alert('환불 대상 금액이 없습니다.') } 
    if (!form.bankName) { return alert('반드시 은행명을 지정해주세요.') } 
    if (!form.walletOwner) { return alert('예금주명이 올바르지 않습니다.') } 
    if (!form.walletAddress) { return alert('계좌번호를 반드시 기재해주세요.') }

    if (!window.confirm(`해당 내역 기준으로 수기환불내역을 생성합니다. 기존에 종결되지 않았거나, 이미 완료한 내역이 있다면 등록되지 않습니다. 진행할까요?`)) { return }
    
    const result = await api
      .post(`/pays/admin2/${payId}/refundApproval`, form)
      .catch((e) => { return { error: true, message: `오류가 발생하였습니다. ${e.message}` } })

    alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`)
    return !result.error ? onSuccess(result) : null
  }
  
  // 랜더링
  render() {
    const { doCreate } = this
    const { onClose } = this.props
    const { loading, form } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // 알림종류에 따른 구분정보
    const banks = Object.values(BANKS)
      .filter(bank => bank.active)

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>수기환불 생성</Form.Header>
          <Form.Body>

            <div className="control">
              <select
                value={_.get(form, 'bank.name') || ''}
                style={{ marginRight: '0.5rem' }}
                onChange={(e) => {
                  const value = e.target.value
                  if (!value) { return false }
                  const next = { ...form }
                  next.bank = BANKS[value]
                  next.bankName = _.get(BANKS, `${value}.text`)
                  return this.setState({ form: next })
                }}
              >
                <option value="">은행선택</option>
                {banks.map((bank, bIdx) => {
                  return <option key={bIdx} value={bank.name}>{bank.text}({bank.name})</option>
                })}
              </select>
              <input
                type="text"
                placeholder={`예금주`}
                value={form.walletOwner || ''}
                onChange={e => {
                  const value = e.target.value
                  const next = { ...form }
                  next.walletOwner = value ? `${value}` : ''
                  return this.setState({ form: next })
                }}
                onBlur={e => {
                  const next = { ...form }
                  next.walletOwner = `${form.walletOwner || ''}`.replace(/ /ig, '').trim()
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="control">
              <input
                type="text"
                placeholder={`환불 받을 계좌번호`}
                value={form.walletAddress || ''}
                onChange={e => {
                  const value = e.target.value
                  const next = { ...form }
                  next.walletAddress = value ? `${value}` : ''
                  return this.setState({ form: next })
                }}
                onBlur={e => {
                  const next = { ...form }
                  next.walletAddress = `${form.walletAddress || ''}`.replace(/[^0-9]/ig, '')
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="control">
              <input
                type="number"
                placeholder={`환불액수`}
                value={form.refundPrice || '0'}
                onChange={e => {
                  const value = e.target.value
                  const next = { ...form }
                  next.refundPrice = value ? `${value}` : ''
                  return this.setState({ form: next })
                }}
                onBlur={e => {
                  const next = { ...form }
                  next.refundPrice = form.refundPrice
                    ? (`${form.refundPrice || ''}`.replace(/[^0-9]/ig, '') * 1).toFixed(0) * 1
                    : ''
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="control">
              <textarea
                placeholder={`환불 처리 특이사항`}
                value={form.memo || ''}
                style={{ height: '80px' }}
                onChange={(e) => {
                  const value = e.target.value
                  const next = { ...form }
                  next.memo = value || ''
                  return this.setState({ form: next })
                }}
              />
            </div>

            <div className="buttons">
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doCreate()]
                }}
              >
                생성
              </button>
            </div>
          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default CreateRefundApprovalModalContainer
