import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { palette } from 'styled-theme'

const colors = {}

colors.forbidden = css` background: ${palette('danger', 7)}; `
colors.danger = css` background: ${palette('danger', 16)}; `
colors.info = css` background: ${palette('primary', 6)}; `
colors.success = css` background: ${palette('primary', 16)}; `
colors.muted = css` background: ${palette('muted', 15)}; `

const StyledCaret = styled.span`
  display: inline-block;
  width: 10px; height: 10px; overflow: hidden; border-radius: 50%; position: relative; font-size: 0;
  margin: 0 3px;
  ${({ c }) => c && colors[c] ? colors[c] : null};
`

const Caret = (props) => <StyledCaret {...props} />

Caret.propTypes = {
  c: PropTypes.string,
}

Caret.defaultProps = {
  c: 'muted',
}

export default Caret
