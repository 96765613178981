import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { item: {} }

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  // 이미 보유한 item.currentUser 에서 가져온다.
  async initialize() {
    const currentUser = _.get(this, 'props.item.currentUser') || {}
    if (!currentUser || !currentUser.id) { return null }
    this.setState({ item: currentUser })
  }
  
  render() {
    const { item } = this.state
    if (!item.id) { return null } 

    const meta = {
      name: item.name || '실명 미기재',
      mobile: item.mobile || '00000000000',
      email: item.email || '이메일 없음',
      accountId: item.accountId || '계정명 없음',
      nickName: item.nickName || '닉네임 없음'
    }

    return (
      <Column>
        <Meta.Group style={{ minWidth: '100%', maxWidth: '100%' }}>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <section
                style={{ cursor: 'pointer' }}
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  await navigator.clipboard.writeText(meta.accountId)
                  // alert(`${meta.accountId} 가 복사되었습니다.`)
                }}
              >
              <strong>{meta.accountId}</strong>
            </section>
            <section
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                await navigator.clipboard.writeText(meta.email)
                // alert(`${meta.email} 가 복사되었습니다.`)
              }}
              style={{ cursor: 'pointer', marginBottom: '0.5rem' }}
            >
                <small>
                  {meta.email}
                </small>  
            </section>
            <section onClick={async (e) => await navigator.clipboard.writeText(meta.name)} style={{ cursor: 'pointer' }}>
              <strong>{meta.name}</strong>
            </section>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
