import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import qs from 'query-string'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class ApprovalDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    this.setState({ datas: [] })
  }
  
  render() {
    const { item = {} } = this.props

    // 표현될 기획 내용 : 상태, 등록일, 상태일, 가격보조금 결제 여부, 작가서비스 구매여부
    const meta = {
      firstAt: null,
      currentStatus: _.get(item, 'status'), currentStatusText: '-',
      currentAt: null, currentMt: null
    }
    
    // 최초 제출일시를 기록한다.
    meta.firstAt = _.get(item, 'createdAt')
    meta.firstMt = meta.firstAt ? moment(meta.firstAt) : null

    // 문서 제출 상태에 따른 표현방식을 구현하기
    if (meta.currentStatus === 'wait') {
      meta.currentStatusText = '제출일시'
      meta.currentAt = _.get(item, 'waitAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'review') {
      meta.currentStatusText = '심사전환일'
      meta.currentAt = _.get(item, 'reviewAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'reject') {
      meta.currentStatusText = '반려처리일시'
      meta.currentAt = _.get(item, 'rejectAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'accept') {
      meta.currentStatusText = '승인처리일시'
      meta.currentAt = _.get(item, 'acceptAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'cancel') {
      meta.currentStatusText = '취소처리일시'
      meta.currentAt = _.get(item, 'cancelAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }

    // 커버 디자인 전략, 커버 디자인 텍스트
    meta.coverStrategy = _.get(item, 'content.book.cover.strategy') || { name: 'etc', text: '알수없음' }
    meta.isPaidCover = _.get(item, 'content.book.cover.strategy.name') === 'purchased'
    meta.imageOrderId = _.get(item, 'content.book.cover.imageOrderId')

    return (
      <Column>
        <Meta.Group style={{ minWidth: '100%', maxWidth: '100%' }}>
          <Meta>
            <header>
              <small>표지전략</small>
            </header>
            <section>
              <strong>{_.get(meta, 'coverStrategy.text')}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              <small>작가서비스</small>
            </header>
            <section
              style={{ cursor: 'pointer' }}
              onClick={async (e) => {
                if (!meta.imageOrderId) { return alert(`구매한 내역을 찾을 수 없었습니다.`) }
                
                const query = {}
                query.keywords = meta.imageOrderId
                query.keywordsOption = 'orderId'

                const url = `/pays/solution?${qs.stringify(query)}`
                return window.open(url)
              }}
            >
              <Caret c={meta.isPaidCover ? 'info' : 'muted'} style={{ marginRight: '0.25rem' }} />
              <strong>{meta.isPaidCover ? `구매자` : '비구매자'}</strong>
            </section>
          </Meta>
        </Meta.Group>
        <Meta.Group>
          <Meta>
            <header>
              <small>생성일시</small>
            </header>
            <section>
              <strong>{meta.firstMt ? meta.firstMt.format('YY.MM.DD') : '-'}</strong>
            </section>
            <section>
              <small>{meta.firstMt ? meta.firstMt.format('HH:mm') : '-'}</small>
            </section>
          </Meta>
          {meta.currentStatusText
            ? (
              <Meta>
                <header>
                  <small>{meta.currentStatusText}</small>
                </header>
                <section>
                  <strong>{meta.currentMt ? meta.currentMt.format('YY.MM.DD') : '-'}</strong>
                </section>
                <section>
                  <small>{meta.currentMt ? meta.currentMt.format('HH:mm') : '-'}</small>
                </section>
              </Meta>
            )
            : null}
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

ApprovalDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

ApprovalDetailColumnContainer.defaultProps = {
  item: {}
}

export default ApprovalDetailColumnContainer
