// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import moment from 'services/moment'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Modal from 'components/utils/Modal'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.5rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

// 업로드폼 구성하기
const UploadForm = styled.form`
  position: relative; box-sizing: border-box;
  ${controlStyles}
  ${buttonStyles}
`

// 프레뷰 장르를 구성
const Preview = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; justify-content: center;
  margin: 0.5rem 0;
  & > img {
    position: relative; box-sizing: border-box;
    border-radius: 5px;
  }
`

Preview.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 새로 무료표지 장르를 올리거나, 수정하는 기능
class FreeBookCoverModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedState = {
      loading: true,
      mode: (props.itemName) ? 'update' : 'create',
      setup: {},
      item: {}
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doCreate = this.doCreate.bind(this)
    this.doUpdate = this.doUpdate.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async loadSetup() {
    return await api.get(`/resources/admin2/takeBookStoreGenres`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return {} }
        return data
      })
      .catch(e => ({}))
  }

  async initialize() {
    const { itemName } = this.props
    const { mode } = this.state
    const next = { item: {} }
    next.setup = await this.loadSetup()
    if (['update'].includes(mode)) { next.item = _.get(next, `setup.genres.${itemName}`) || { sortOrder: -1 } }
    if (!['update'].includes(mode)) { next.item = { sortOrder: -1 } }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async doCreate() {
    const { onClose } = this.props
    if (!window.confirm(`해당 장르를 서버에 추가할까요?`)) { return }
    const { item } = this.state

    const form = { ...item }
    if (!form.text) { return alert(`장르명칭을 지정하지 않았습니다.`) }
    if (!form.name) { return alert(`장르코드를 지정하지 않았습니다.`) }
    if (form.sortOrder === '') { form.sortOrder = -1 }
    
    const result = await api.post(`/resources/admin2/bookStoreGenres`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`) }

    alert(`정상적으로 추가하였습니다.`)

    return onClose()
  }

  async doUpdate() {
    const { onClose } = this.props
    if (!window.confirm(`해당 장르를 서버에 업데이트 할까요?`)) { return }

    const { item } = this.state

    const form = { ...item }
    if (!form.text) { return alert(`장르명칭을 지정하지 않았습니다.`) }
    if (!form.name) { return alert(`장르코드를 지정하지 않았습니다.`) }
    if (form.sortOrder === '') { form.sortOrder = -1 }
    
    const result = await api.put(`/resources/admin2/bookStoreGenres/${item.name}`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`) }

    alert(`정상적으로 업데이트 하였습니다.`)

    return onClose()
  }
  
  // 랜더링
  render() {
    const { doCreate, doUpdate } = this
    const { onClose } = this.props
    const { loading, setup, item, mode } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    const genres =  _.get(setup, 'genres') || {}
    const rootGenres = Object.values(genres)
      .reduce((s, o) => {
          if (!o.parent) { s[o.name] = o }
          return s
        }, {})

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>서점 장르</Form.Header>
          <Form.Body>

            <div className="control">
              <input
                type="text"
                placeholder={`장르명칭(한글명칭)`}
                value={item.text || ''}
                style={{ marginRight: '0.5rem' }}
                onChange={(e) => {
                  const next = { ...item }
                  next.text = e.target.value
                  return this.setState({ item: next })
                }}
              />
              {['create'].includes(mode)
                ? (
                  <input
                    type="text"
                    placeholder={item.name || '장르 코드명(영문,숫자조합)'}
                    readOnly
                    onClick={e => {
                      // 프롬프트로 바꾸기
                      const value = window.prompt(`영문 또는 숫자만 가능합니다.`)

                      // 동일한 다른 개체가 있는지 검사한다.
                      const has = _.get(setup, `genres.${value}`)
                      if (has) { return alert(`해당 장르명은 이미 존재합니다.`) }

                      const next = { ...item }
                      next.name = `${value}`.replace( /[^0-9a-zA-Z]/ig, '')
                      return this.setState({ item: next })
                    }}
                  />
                )
                : null}
              {!['create'].includes(mode)
                ? (
                  <input
                    type="text"
                    defaultValue={item.name || ''}
                    readOnly
                  />
                )
                : null}
            </div>

            <div className="control">
              <select
                value={`${item.parent}`}
                style={{ marginRight: '0.5rem' }}
                onChange={e => {
                  if (!e.target.value) { return alert(`반드시 한가지 타입을 선택해야합니다.`) }
                  const next = { ...item }
                  next.parent = e.target.value
                  return this.setState({ item: next })
                }}
              >
                <option value="">부모 선택안함</option>
                {Object.values(rootGenres).map((genre, gIdx) => {
                  return <option key={`${genre.name}_${gIdx}`} value={genre.name}>{genre.text}({genre.name})</option>
                })}
              </select>
              <select
                value={`${item.status}`}
                onChange={e => {
                  if (!e.target.value) { return alert(`반드시 한가지 타입을 선택해야합니다.`) }
                  const next = { ...item }
                  next.status = e.target.value
                  return this.setState({ item: next })
                }}
              >
                <option value="activated">노출중</option>
                <option value="deactivated">노출안함</option>
              </select>
            </div>

            <div className="control">
              <input
                type="text"
                placeholder={`-1`}
                value={item.sortOrder}
                onChange={e => {
                  const next = { ...item }
                  next.sortOrder = e.target.value ? `${e.target.value}` : ''
                  return this.setState({ item: next })
                }}
                onBlur={e => {
                  if (!item.sortOrder || !_.isInteger(item.sortOrder * 1)) {
                    const next = { ...item }
                    next.sortOrder = -1
                    return this.setState({ item: next })
                  }
                }}
              />
            </div>

            <div className="buttons">
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), ['create'].includes(mode) ? await doCreate() : await doUpdate()]
                }}
              >
                {['create'].includes(mode) ? `생성` : null}
                {!['create'].includes(mode) ? `수정` : null}
              </button>
            </div>
          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default FreeBookCoverModalContainer