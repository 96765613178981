import _ from 'lodash'

const parser = {}

parser.columns = [
  { header: 'ISBN', key: 'isbn', view: (item) => _.get(item, 'isbn') || '' },
  { header: '상품ID', key: 'productId', view: (item) => _.get(item, 'product.id') || '' },
  { header: '상품명', key: 'productName', view: (item) => _.get(item, 'product.name') || '' },
  { header: '도서ID', key: 'contentId', view: (item) => _.get(item, 'product.content.id') || _.get(item, 'product.content._id') || '' },
  { header: '저자명', key: 'author', view: (item) => _.get(item, 'product.content.author') || '' },
  { header: '수량', key: 'amount', view: (item) => _.get(item, 'amount') || 0 },
]

export default parser
