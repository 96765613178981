import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import StatusColumn from './Columns/StatusColumn'
import DetailColumn from './Columns/DetailColumn'
import PriceColumn from './Columns/PriceColumn'
import IdentityColumn from './Columns/IdentityColumn'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import RetailsModal from './Setups/RetailsModal'
import CreatePayUploadModal from './Setups/CreatePayUploadModal'
import PayUploadDetailModal from './Setups/PayUploadDetailModal'

import { Header, Task, Items, DataSet, Pager, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, resource, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {

  const commonProps = { user, location, history, match }

  // const isSelectedAll = () => items.length === selecteds.length ? true : false
  // const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // // 전체 선택
  // const checkAll = () => {
  //   if (!items.length) { return null }
  //   const prev = selecteds || []
  //   const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
  //   const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
  //   return handle({ selecteds: next })
  // }

  // // 선택한 한가지 데이터
  // const doCheck = (item) => {
  //   const prev = selecteds, next = []
  //   if (prev.find(_item => _item.id === item.id)) {
  //     next.push(...prev.filter(_item => _item.id !== item.id))
  //   } else {
  //     next.push(...prev, item)
  //   }
  //   return handle({ selecteds: next })
  // }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'IdentityColumn', header: '구분', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center' } })
  columns.push({ key: 'ProductTypeColumn', header: '상품구분', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'PriceColumn', header: '반영건수·거래액', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center' } })
  columns.push({ key: 'DetailColumn', header: '상세내역', style: { minWidth: '768px', maxWidth: '768px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: '0', maxWidth: '100%', justifyContent: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // 들어오자마자 관리자창을 보여주는 기능은 따로 유지보수할 때만 쓰인다.
  // React.useState(() => {
  //   if (!modal) {
  //     setModal({ name: 'PayUploadDetailModal', props: { onClose: () => [setModal(null), initialize()] } })
  //   }
  // }, [])

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}

      {modal && modal.name === 'RetailsModal' ? <RetailsModal {...(modal.props || {})} /> : null}
      {modal && modal.name === 'CreatePayUploadModal' ? <CreatePayUploadModal {...(modal.props || {})} /> : null}
      {modal && modal.name === 'PayUploadDetailModal' ? <PayUploadDetailModal {...(modal.props || {})} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">                
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a href="#CreatePayUploadModal" onClick={e => [e.stopPropagation(), e.preventDefault(), setModal({ name: 'CreatePayUploadModal', props: { onClose: () => [setModal(null), initialize()] } })]}>매출내역 업로드</a>
          <a href="#RetailsModal" onClick={e => [e.stopPropagation(), e.preventDefault(), setModal({ name: 'RetailsModal', props: { onClose: () => [setModal(null), initialize()] } })]}>유통관리</a>
        </Header.Options>
          
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'IdentityColumn':
                              return <IdentityColumn {...columnProps} />
                            case 'ProductTypeColumn':
                              return (
                                <div>
                                  <strong style={{ fontWeight: 700 }}>
                                    {item.payType === 'paperBook' ? `종이도서` : null}
                                    {item.payType === 'electronicBook' ? `전자도서` : null}
                                  </strong>
                                </div>
                              )
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />
                            case 'DetailColumn':
                              return <DetailColumn {...columnProps} />
                            case 'PriceColumn':
                              return <PriceColumn {...columnProps} />
                            case 'ControlColumn':
                              return (
                                <div>
                                  <a
                                    href="#PayUploadDetailModal"
                                    style={{ margin: '0 0.35rem' }}
                                    onClick={e => {
                                      const modalProps = { name: 'PayUploadDetailModal', props: { itemId: item.id, onClose: () => [setModal(null), initialize()] } }
                                      return [e.stopPropagation(), e.preventDefault(), setModal(modalProps)]
                                    }}
                                  >
                                    상세내역
                                  </a>
                                </div>
                              )

                            // case 'checkbox':
                            //   return (
                            //     <>
                            //       <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                            //     </>
                            //   )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 매출내역 데이터가 존재하지 않습니다다.</header>
          <section>아직 매출내역을 업로드하지 않았습니다.<br/>혹시 유통관리에서 유통사를 지정을 해두었는지 체크해주세요.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '매출업로드',
  nav: 'upload'
}

export default List
