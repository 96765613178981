import styled from 'styled-components'

export const Item = styled.div`
  position: relative; box-sizing: border-box;
  border-radius: 0.5rem; background: white; margin-bottom: 0.5rem;
  padding: 0.75rem;
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
`
