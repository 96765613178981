import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  {
    header: '참여회차', key: 'round',
    view: (item) => {
      const content = item.content || {}
      const round = _.get(content, 'round') || '-'
      return `${round}회차`
    }
  },
  {
    header: '참여일자', key: 'createdAt',
    view: (item) => {
      const meta = {}
      meta.createdMt = _.get(item, 'createdAt')
        ? moment(_.get(item, 'createdAt'))
        : null
      return meta.createdMt
       ? meta.createdMt.format('YYYY년 MM월 DD일 HH시 mm분 ss초')
       : ''
    }
  },
  {
    header: '계정명', key: 'accountId',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'accountId') || ''
    }
  },
  {
    header: '이메일', key: 'email',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'email') || ''
    }
  },
  {
    header: '연락처', key: 'mobile',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'mobile') || ''
    }
  },
  {
    header: '도서번호', key: 'bookNo',
    view: (item) => {
      const content = item.content || {}
      const bookNo = _.get(content, 'bookNo')
      return bookNo ? `${bookNo}` : ``
    }
  },
  {
    header: '도서구분', key: 'bookType',
    view: (item) => {
      const content = item.content || {}
      const bookType = _.get(content, 'bookType')
      return ['paperBook'].includes(bookType)
        ? `종이도서`
        : ['electronicBook'].includes(bookType) ? `전자도서` : `기타`
    }
  },
  {
    header: '도서명', key: 'bookTitle',
    view: (item) => {
      const content = item.content || {}
      const bookTitle = _.get(content, 'bookTitle')
      return `${bookTitle || ''}`
    }
  },
  {
    header: '저자명', key: 'bookAuthor',
    view: (item) => {
      const content = item.content || {}
      const bookAuthor = _.get(content, 'bookAuthor')
      return `${bookAuthor || ''}`
    }
  },
  {
    header: '브런치 매거진코드', key: 'brunchMagazine',
    view: (item) => {
      const content = item.content || {}
      const brunchMagazine = _.get(content, 'brunchMagazine')
      return `${brunchMagazine || ''}`
    }
  },
  {
    header: '브런치 발급번호', key: 'brunchMagazineNo',
    view: (item) => {
      const content = item.content || {}
      const brunchMagazineNo = _.get(content, 'brunchMagazineNo')
      return `${brunchMagazineNo || ''}`
    }
  },
  {
    header: '브런치 계정ID', key: 'brunchUserId',
    view: (item) => {
      const content = item.content || {}
      const brunchUserId = _.get(content, 'brunchUserId')
      return `${brunchUserId || ''}`
    }
  },
  {
    header: '진행단계', key: 'status',
    view: (item) => {
      const meta = {}
      meta.statusText = '알수없음' 
      if (['wait'].includes(item.status)) { meta.statusText = '신청완료' }
      if (['review'].includes(item.status)) { meta.statusText = '확인중' }
      if (['reject'].includes(item.status)) { meta.statusText = '선정탈락' }
      if (['accept'].includes(item.status)) { meta.statusText = '선정완료' }
      if (['cancel'].includes(item.status)) { meta.statusText = '신청취소' }
      return meta.statusText
    }
  }
]

export default parser
