import _ from 'lodash'
import moment from 'services/moment'
import { retailsKeys } from 'services/constants/retail'

const parser = {}

parser.columns = [
  // 계정 정보
  { header: '계정명', key: 'accountId', view: (item) => _.get(item, 'accountId') },
  { header: '닉네임', key: 'nickName', view: (item) => _.get(item, 'nickName') },
  { header: '실명', key: 'name', view: (item) => _.get(item, 'name') },
  { header: '이메일', key: 'email', view: (item) => _.get(item, 'email') },
  { header: '전화번호', key: 'mobile', view: (item) => _.get(item, 'mobile') },
  { header: '생년월일', key: 'birthday', view: (item) => _.get(item, 'birthday') },

  // 상태 정보
  {
    header: '상태', key: 'status',
    view: (item) => {
      const key = _.get(item, 'status')
      if (key === 'normal') { return '정상' }
      if (key === 'blocked') { return '차단' }
      if (key === 'temporary') { return '임시' }
      if (key === 'leaved') { return '탈퇴' }
      return `오류`
    }
  },
  {
    header: '성인여부', key: 'isAdult',
    view: (item) => {
      return _.get(item, 'isAdult') ? `성인` : `미확인`
    }
  },
  {
    header: '성별', key: 'gender',
    view: (item) => {
      const key = _.get(item, 'gender')
      if (key === 'man') { return '남성' }
      if (key === 'woman') { return '여성' }
      return `미설정`
    }
  },

  // 인증 정보
  {
    header: '메일인증', key: 'activatedToEmail',
    view: (item) => _.get(item, 'activatedToEmail') ? `인증` : `미인증`
  },
  {
    header: '모바일인증', key: 'activatedToMobile',
    view: (item) => _.get(item, 'activatedToMobile') ? `인증` : `미인증`
  },
  {
    header: '계좌인증', key: 'activatedToBankAccount',
    view: (item) => _.get(item, 'activatedToBankAccount') ? `인증` : `미인증`
  },
  {
    header: '사업자인증', key: 'activatedToBusiness',
    view: (item) => _.get(item, 'activatedToBusiness') ? `인증` : `미인증`
  },

  // 플래그 정보
  {
    header: '종이도서', key: 'paperBookProductCount',
    view: (item) => _.get(item, 'paperBookProductCount') || 0
  },
  {
    header: '전자도서', key: 'electronicBookProductCount',
    view: (item) => _.get(item, 'electronicBookProductCount') || 0
  },
  {
    header: '서비스', key: 'solutionProductCount',
    view: (item) => _.get(item, 'solutionProductCount') || 0
  },

  // 활동태그
  {
    header: '소속', key: 'joined',
    view: (item) => {
      const keys = _.get(item, 'joined')
      const metas = []
      keys.forEach(key => {
        let grade = '미지정'
        if (key.grade === 'normal') { grade  = '일반' }
        if (key.grade === 'vip') { grade = 'VIP' }
        let text = '일반'
        if (key.group === 'author') { text  = '작가' }
        if (key.group === 'expert') { text = '전문가' }
        metas.push(`${text} (${grade}, ${key.scroe || 0}점)`)
      })
      return metas.join(',')
    }
  },
  {
    header: '활동태그', key: 'family',
    view: (item) => {
      const keys = _.get(item, 'family')
      const metas = []
      Object.values(retailsKeys).forEach((key) => {
        const rName = retailsKeys[key]
        if (keys.includes(`${key}Author`)) { metas.push(`${rName} 작가`) }
      })
      if (keys.includes('brunchAuthor')) { metas.push('브런치 작가') }
      return metas.join(',')
    }
  },

  // 기록
  { header: '가입일', key: 'createdAt', view: (item) => moment(_.get(item, 'createdAt')).format('YYYY-MM-DD HH:mm:ss') },
  { header: '탈퇴일', key: 'leavedAt', view: (item) => moment(_.get(item, 'leavedAt')).format('YYYY-MM-DD HH:mm:ss') },
  { header: '최종업데이트', key: 'updatedAt', view: (item) => moment(_.get(item, 'updatedAt')).format('YYYY-MM-DD HH:mm:ss') },
]

export default parser
