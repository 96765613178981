import _ from 'lodash'
import moment from 'services/moment'

import * as crypto from 'services/crypto'

const parser = {}

parser.systemPassword = '' // 코드 받아야 진행 가능

parser.columns = [
  { header:  '순번', key: 'rIdx', view: (item, idx) => `${idx+1}` },
  { header:  '유저번호', key: 'userNo', view: (item) => _.get(item, 'extras.user.userNo') },
  { header:  '아이디', key: 'userAccount', view: (item) => _.get(item, 'extras.user.accountId') },
  { header:  '이름', key: 'userName', view: (item) => _.get(item, 'extras.user.name') },
  { header:  '이메일', key: 'userEmail', view: (item) => _.get(item, 'extras.user.email') },
  { header:  '연락처', key: 'userMobile', view: (item) => _.get(item, 'extras.user.mobile') },

  // 계정구분 처리
  {
    header:  '구분', key: 'userType',
    view: (item) => {
      const activatedToBusiness = _.get(item, 'extras.user.activatedToBusiness')
      return activatedToBusiness ? '사업자' : '개인'
    }
  },

  // 지급액 정보
  { header:  '대상액', key: 'profitTotal', view: (item) => _.get(item, 'total') },

  // 개인이거나 사업자 대표자 정보
  {
    header:  '과세자',
    key: 'registrationNumberName', view: (item) => _.get(item, 'extras.user.registrationNumber.name')
  },
  {
    header:  '주민등록번호', key: 'registrationNumber',
    view: (item, idex, arr, rIdx, parser) => {
      const value = _.get(item, 'extras.user.registrationNumber.number')
      return value && parser.systemPassword ? crypto.dec(value, parser.systemPassword) : ``
    }
  },

  // 사업자정보
  { header:  '사업자명', key: 'businessName', view: (item) => _.get(item, 'extras.user.business.name') },
  { header:  '사업자번호', key: 'businessRegistrationNumber', view: (item) => _.get(item, 'extras.user.business.registrationNumber') },

  // 지급정보 배치
  { header:  '은행명', key: 'bankAccountBankName', view: (item) => _.get(item, 'extras.user.bankAccount.bankName') },
  { header:  '예금주', key: 'bankAccountOwner', view: (item) => _.get(item, 'extras.user.bankAccount.owner') },
  { header:  '계좌번호', key: 'bankAccountNumber', view: (item) => _.get(item, 'extras.user.bankAccount.number') },
  {
    header:  '지급상태', key: 'status',
    view: (item) => {
      if (['pending'].includes(_.get(item, 'status'))) { return `지급대기` }
      if (['resolved'].includes(_.get(item, 'status'))) { return `지급완료` }
      if (['rejected'].includes(_.get(item, 'status'))) { return `지급취소` }
      return `알수없음`
    }
  }
]

export default parser
