import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Page from 'components/layouts/Page'
import BillingList from 'components/extras/BillingList'

// 빌링관리 페이지
class BillingPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav = 'all' } = match.params || {}

    let breadcrumb = ['홈', '빌링관리'], title = '빌링관리'

    let navs = []
    navs.push({ name: 'all', text: '전체', active: false })
    navs.push({ name: 'activated', text: '사용중', active: false })
    navs.push({ name: 'deactivated', text: '사용종료', active: false })
    navs = navs.map(n => (n.to ? window.location.href.indexOf(n.to) >= 0 : n.name === nav) ? { ...n, active: true } : n)

    const currentNav = navs.find(n =>(n.to ? window.location.href.indexOf(n.to) >= 0 : n.name === nav)) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }

    return (
      <Page>
        <Helmet>
          <title>빌링관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>
          <Route
            path="/billings/:nav?"
            exact
            component={(props) => {
              const { location } = props
              const search = location.search ? qs.parse(location.search) : {}

              const cProps = { admin, title: currentNav.text, nav, ...props }

              //  페이지 인자 정리
              cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
              if (search.limit) {
                cProps.limit = Number(search.limit)
                if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
              }
              if (search.blockLimit) {
                cProps.blockLimit = Number(search.blockLimit)
                if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
              }
              if (search.sorts) { cProps.sorts = search.sorts.split(',') }
              if (search.startAt) { cProps.startAt = new Date(search.startAt) }
              if (search.endAt) { cProps.endAt = new Date(search.endAt) }

              if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
              if (search.keywords) { cProps.keywords = search.keywords }

              // 필터구문 정리
              const propsKeys = Object.keys(search)
              const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
              if (filtersKeys && filtersKeys.length) {
                cProps.filters = {}
                filtersKeys.forEach(filtersKey => {
                  const key = filtersKey.replace('filters_', '')
                  cProps.filters[key] = search[filtersKey]
                })
              }

              return <>
                <Page.Nav>
                  <Page.Nav.Items>
                    {navs.map((item, index) => 
                      <Page.Nav.Item
                        key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                        onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = item.to ? `/billings/${item.to}` : `/billings/${item.name}`]}
                      >
                        <span>{item.text}</span>
                      </Page.Nav.Item>
                    )}
                  </Page.Nav.Items>
                </Page.Nav>
                <BillingList {...cProps} />
              </>
            }}
          />
        </Switch>
      </Page>
    )
  }
}

BillingPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

BillingPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default BillingPageContainer
