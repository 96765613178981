import qs from 'query-string'

const { REACT_APP_CLIENT_URL } = process.env

// 특정계정의 로그인 기능을 의미
export const login = async (id = 'guest', returnUrl) => {
  const password = '1PcnblVxfcLJKKkiVlyYmVtfLchx808SLO4iKKSW0F6lgJYHRqKE5rXUyyH5iDAN4Dzk3LbEi0qxqCWLlXMluVFWppsLR2df1Q3l'
  const query = { password, target: id, returnUrl: returnUrl || `${REACT_APP_CLIENT_URL}/me` }

  const headers = {}
  headers['Access-Control-Allow-Origin'] = '*'
  headers['Content-Type'] = 'application/json'
  const url = `${REACT_APP_CLIENT_URL}/auth/magic?${qs.stringify(query)}`
  window.open(url)
}

export default { login }