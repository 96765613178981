import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'
import React from 'react'

import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'
import { comma } from 'services/utils'

const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; transition: all 0.3s;
  padding: 0.25rem; color: ${palette('muted', 5)};
  background: white; border-radius: 3px;
  border: 1px solid ${palette('muted', 12)};
  margin: 0.75rem 0; opacity: 0.9;
  &:hover { border: 1px solid ${palette('muted', 1)}; opacity: 1; }
`

Item.Col = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem; color: ${palette('muted', 5)};
  & strong { color: ${palette('muted', 1)}; }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

Item.NotFound = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 1.5rem; 
  border: 1px solid ${palette('muted', 12)};
  background: white; border-radius: 3px;
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1rem 0; font-weight: 700; color: ${palette('muted', 2)};
  }
  & > section {
    position: relative; box-sizing: border-box;
    font-size: 0.8em;
    padding: 1rem 0; color: ${palette('muted', 4)};
  }
`

// 메타정보
const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em; color: ${palette('muted', 4)};
    padding: 0.25rem 0;
  }
  & > section {
    position: relative; box-sizing: border-box;
    & small { color: ${palette('muted', 7)}; }
    padding: 0.35rem;
  }
  & > div.extra {
    position: relative; box-sizing: border-box;
    padding: 0.35rem;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 하위 개체 컴포넌트를 랩핑한다.
const Form = styled.article`
  position: relative; box-sizing: border-box;
`

const controlStyles = css`
  & input[type=text],
  & input[type=number],
  & input[type=date],
  & input[type=time] {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & select {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem 0;
  display: flex; align-items: center;
  & > * { width: 100%; flex: 1 1 100%; min-width: 0; }
  & > div.label { font-size: 0.9em; }
  ${controlStyles}
`

// 입력상자 디자인
Form.Control = styled.div`
  position: relative; box-sizing: border-box;
`

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}  
  }

  render() {
    const { parentProps = {} } = this.props

    // 기타결제 구매단계 상태
    const dealStatusTypes = {
      none: { name: 'none', text: '임시', signal: '⚪' },
      wait: { name: 'wait', text: '다운로드 대기', signal: '🟡' },
      accept: { name: 'accept', text: '다운로드 완료', signal: '🔵' },
      cancel: { name: 'cancel', text: '주문취소', signal: '⚪' }
    }

    // 환불 타입
    const refundStatusTypes = {}
    refundStatusTypes.none = { name: 'none', text: '해당없음' }
    refundStatusTypes.wait = { name: 'wait', text: '환불신청중' }
    refundStatusTypes.review = { name: 'review', text: '환불 처리중' }
    refundStatusTypes.reject = { name: 'reject', text: '환불거절' }
    refundStatusTypes.accept = { name: 'accept', text: '환불완료' }
    refundStatusTypes.cancel = { name: 'cancel', text: '환불취소' }

    const items = this.props.items || []
    
    if (!items || !items.length) {
      return (
        <Item.NotFound>
          <header>주문서가 존재하지 않습니다. 🌧️</header>
          <section>
            주문서가 이미 삭제되었거나, 존재하지 않은채로<br/>
            결제내역이 생성되었습니다.
          </section>
        </Item.NotFound>
      )
    }

    return (
      <Item.Group>
        {items.map((item, idx) => {
          const extras = item.extras || {}
          
          const meta ={}
          meta.idx = idx + 1
          meta.orderId = item.id
          meta.user = item.user
          meta.seller = item.seller
          meta.bookNo = _.get(item, 'content.bookNo') || '0'
          meta.bookAuthor = _.get(item, 'content.bookAuthor') || '-'
          meta.bookTypeText = ['paperBook'].includes(_.get(item, 'orderType'))
            ? `종이도서`
            : (['electronicBook'].includes(_.get(item, 'content.bookType'))  ? `전자도서` : `기타`)
          meta.subject = item.subject || '상품명 없음'
          meta.amount = item.amount || 0
          meta.eachPrice = item.eachPrice || 0
          meta.totalPrice = item.totalPrice || 0

          meta.productId = _.get(item, 'productId')
          meta.bookNo = _.get(item, 'content.bookNo')

          // 해당 주문에 대한 상태값
          meta.refundApprovals = item.refundApprovals || []
          meta.refundApprovalsCount = item.refundApprovals.length || 0
          meta.refundStatus = _.get(refundStatusTypes, `${item.refundStatus}`) || refundStatusTypes.none

          // 전자책인 경우 다운로드 여부를 파악한다.
          meta.hasDownload = ['electronicBook'].includes(item.orderType) && item.isDownload
          meta.downloadAt = meta.hasDownload ? _.get(item, 'downloadAt') : null
          meta.downloadMt = meta.downloadAt ? moment(meta.downloadAt) : null

          // 수익금 배정상태에 대한 정보를 구성
          meta.profits = _.get(extras, 'profits') || []
          meta.sumProfitTotal = meta.profits.reduce((s, profit) => s + profit.total * 1, 0)

          return (
              <Item  key={idx}>
                <Item.Col style={{ minWidth: '80px', maxWidth: '80px' }}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <div style={{ marginBottom: '0.35rem' }}>
                      <strong style={{ fontSize: '2em', fontWeight: 900 }}>{meta.idx}</strong>
                    </div>
                    <div>
                      <small>{meta.bookTypeText}</small>
                    </div>
                  </div>
                </Item.Col>

                {/* 종이도서 주문내역 */}
                {['paperBook'].includes(item.orderType)
                  ? (
                    <>
                      <Item.Col style={{ flex: '1 1 100%', wordBreak: 'break-all' }}>
                        <div style={{ maxWidth: '100%' }}>
                          <div style={{ marginBottom: '0.35rem' }}>
                            <div
                              style={{ fontWeight: 900, color: '#111', cursor: 'pointer' }}
                              onClick={e => {
                                if (!meta.bookNo) { return alert(`도서번호가 존재하지 않는 도서입니다.`) }
                                  return window.open(`/products/book?keywordsOption=bookNo&keywords=${meta.bookNo}`)
                              }}
                            >{meta.subject}</div>
                            <div>
                              <small>{meta.bookAuthor}</small>
                              <small style={{ marginLeft: '0.35rem' }}>{meta.bookNo}</small>
                            </div>
                          </div>

                          <div>
                            <small>{comma(meta.eachPrice)}</small>
                            <small style={{ marginLeft: '0.25rem' }}>원</small>
                            <small style={{ marginLeft: '0.235rem' }}>({comma(meta.amount)}개)</small>
                            <small style={{ marginLeft: '0.235rem' }}><strong>{comma(meta.totalPrice)}원</strong></small>
                          </div>

                          <div style={{ padding: '0.35rem 0', marginTop: '0.5rem', borderTop: '1px dashed #eee' }}>
                            <small style={{ marginRight: '0.75rem' }}>
                              <span style={{ marginRight: '0.25rem' }}>배정된 수익금</span>
                              <strong style={{ marginRight: '0.15rem' }}>{comma(meta.sumProfitTotal)}원</strong>
                              <small>({comma(_.get(meta, 'profits.length'))}건)</small>
                            </small>
                          </div>

                        </div>
                      </Item.Col>
                    </>
                  )
                  : null}

                  {/* 전자도서 주문내역 */}
                  {['electronicBook'].includes(item.orderType)
                    ? (
                      <>
                        <Item.Col style={{ flex: '1 1 100%', wordBreak: 'break-all' }}>
                          <div style={{ marginBottom: '0.35rem' }}>
                            <div
                              style={{ fontWeight: 900, color: '#111', cursor: 'pointer' }}
                              onClick={e => {
                                if (!meta.bookNo) { return alert(`도서번호가 존재하지 않는 도서입니다.`) }
                                  return window.open(`/products/book?keywordsOption=bookNo&keywords=${meta.bookNo}`)
                              }}
                            >{meta.subject}</div>
                            <div style={{ fontSize: '0.85em' }}>
                              <small>{meta.bookAuthor}</small>
                              <small style={{ marginLeft: '0.35rem' }}>{meta.bookNo}</small>
                            </div>
                          </div>
                          <div>
                            <small>{comma(meta.eachPrice)}</small>
                            <small style={{ marginLeft: '0.25rem' }}>원</small>
                            <small style={{ marginLeft: '0.235rem' }}>({comma(meta.amount)}개)</small>
                          </div>

                          <div style={{ padding: '0.35rem 0', marginTop: '0.5rem', borderTop: '1px dashed #eee' }}>
                            <small style={{ marginRight: '0.75rem' }}>
                              <span style={{ marginRight: '0.35rem' }}>다운로드</span>
                              <strong>
                                {meta.hasDownload ? meta.downloadMt.format('YYYY.MM.DD HH:mm') : `0000.00.00 00:00`}
                              </strong>
                            </small>

                            <small
                              style={{ marginRight: '0.75rem', cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                
                                const query = {}
                                query.keywords = item.id
                                query.keywordsOption = 'orderId'
                                const url = `/profits/deposits?${qs.stringify(query)}`
                                return window.open(url)
                              }}
                            >
                              <span style={{ marginRight: '0.25rem' }}>배정된 수익금</span>
                              <strong style={{ marginRight: '0.15rem' }}>{comma(meta.sumProfitTotal)}원</strong>
                              <small>({comma(_.get(meta, 'profits.length'))}건)</small>
                            </small>
                          </div>

                        </Item.Col>
                      </>
                    )
                    : null}
              </Item>
          )
        })}
      </Item.Group>
    )
  }
}

export default OrdersContainer
