import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'

import { isId } from 'services/utils'

import Page from 'components/layouts/Page'

import ProductForm from 'components/extras/ProductForm'
import ProductList from 'components/extras/ProductList'

import LegalList from 'components/extras/LegalList'
// 상품관리자 페이지
class ProductPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav = 'book' } = match.params

    let breadcrumb = ['홈', '상품관리'], title = '상품관리'

    let navs = []
    navs.push({ name: 'book', text: '도서전체', active: false })
    navs.push({ name: 'paperBook', text: '종이도서', active: false })
    navs.push({ name: 'electronicBook', text: '전자도서', active: false })
    navs.push({ name: 'solution', text: '작가서비스', active: false })
    navs.push({ name: 'legal', text: '납본/ISBN 현황', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }

    return (
      <Page>
        <Helmet>
          <title>상품관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        
        <Switch>
          <Route
            path="/products/:nav/:id"
            component={(props) => {
              const { match } = props
              const { params } = match
              const nav = params.nav
              const cProps = { admin, ...props, itemId: params.id }
              if (params.id && isId(params.id)) {
                return <ProductForm {...cProps} />
              }
              return <Redirect to={nav ? `/products/${nav}` : '/products'} />
            }}
          />
          
          <Route
            path="/products/:nav?"
            exact
            component={(props) => {
              const { location } = props
              const search = location.search ? qs.parse(location.search) : {}

              const cProps = { admin, title: currentNav.text, nav, ...props }

              //  페이지 인자 정리
              cProps.page = !_.isNaN(search.page * 1) && _.isNumber(search.page * 1) ? search.page * 1 : 1
              if (search.limit) {
                cProps.limit = Number(search.limit)
                if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
              }
              if (search.blockLimit) {
                cProps.blockLimit = Number(search.blockLimit)
                if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
              }
              if (search.sorts) { cProps.sorts = search.sorts.split(',') }
              if (search.startAt) { cProps.startAt = new Date(search.startAt) }
              if (search.endAt) { cProps.endAt = new Date(search.endAt) }

              if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
              if (search.keywords) { cProps.keywords = search.keywords }

              // 필터구문 정리
              const propsKeys = Object.keys(search)
              const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
              if (filtersKeys && filtersKeys.length) {
                cProps.filters = {}
                filtersKeys.forEach(filtersKey => {
                  const key = filtersKey.replace('filters_', '')
                  cProps.filters[key] = search[filtersKey]
                })
              }

              console.log(`search`, search)
              console.log(`cProps`, cProps)

              return <>
                <Page.Nav>
                  <Page.Nav.Items>
                    {navs.map((item, index) => 
                      <Page.Nav.Item
                        key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                        onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/products/${item.name}`]}
                      >
                        <span>{item.text}</span>
                      </Page.Nav.Item>
                    )}
                  </Page.Nav.Items>
                </Page.Nav>
                {!['legal'].includes(nav) ? <ProductList {...cProps} /> : null}
                {['legal'].includes(nav) ? <LegalList {...cProps} /> : null}
              </>
            }}
          />
        </Switch>

      </Page>
    )
  }
}

ProductPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

ProductPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {}
}

export default ProductPageContainer
