import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.sheetProps = {
  pageSetup: {
    paperSize: 9,
    orientation: 'portrait',
    fitToPage: true,
    margins: { top: 0, right: 0, bottom: 0, left: 0, header: 0, footer: 0 },
  },
}

// 마지막으로 꾸며주기
parser.willWriteWorksheet = async (worksheet) => {
  const curAt = new Date(), curMt = moment(curAt)
  
  worksheet.spliceRows(1, 1)
  worksheet.getCell('A1').font = { size: 20, bold: true }
  worksheet.getCell('A1').alignment = { horizontal: 'left', vertical: 'middle' }
  worksheet.getCell('F1').font = { bold: true }
  worksheet.getCell('F1').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('A3').value = '날짜'
  worksheet.getCell('A3').font = { bold: true }
  worksheet.getCell('A3').alignment = { horizontal: 'center', vertical: 'middle' }
  
  worksheet.getCell('B3').value = '도서명'
  worksheet.getCell('B3').font = { bold: true }
  worksheet.getCell('B3').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('C3').value = '정가'
  worksheet.getCell('C3').font = { bold: true }
  worksheet.getCell('C3').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('D3').value = '부수'
  worksheet.getCell('D3').font = { bold: true }
  worksheet.getCell('D3').alignment = { horizontal: 'center', vertical: 'middle' }
  
  worksheet.getCell('E3').value = '합계금'
  worksheet.getCell('E3').font = { bold: true }
  worksheet.getCell('E3').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('F3').value = '공급률'
  worksheet.getCell('F3').font = { bold: true }
  worksheet.getCell('F3').alignment = { horizontal: 'center', vertical: 'middle' }
  
  worksheet.getCell('A4').value = curMt.format(`YYYY-MM-DD`)

  const lastRowIdx = worksheet.rowCount
  worksheet.getRow(lastRowIdx).font = { bold: true }
  worksheet.getRow(lastRowIdx).alignment = { vertical: 'middle' }
}

const columns = [
  {
    header:  '날짜', key: 'createdDate',
    columnProps: { width: 12 },
    view: (item, itemIdx) => _.get(item, 'createdDate')
  },
  {
    header:  '도서명', key: 'productName',
    columnProps: { width: 54 },
    view: (item) => _.get(item, 'productName')
  },
  {
    header:  '정가', key: 'eachPrice',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'eachPrice')
  },
  {
    header:  '부수', key: 'amount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'amount')
  },
  {
    header:  '합계금', key: 'totalPrice',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'totalPrice')
  },
  {
    header:  '공급률', key: 'supplyPrice',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'supplyPrice')
  }
]

parser.columns = columns

export default parser
