import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Caret from 'components/utils/Caret'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class CertificationDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {}
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}

    const meta = {}
    meta.activatedToEmail = currentUser.activatedToEmail
    meta.activatedToMobile = currentUser.activatedToMobile
    meta.activatedToBankAccount = currentUser.activatedToBankAccount
    meta.activatedToIdentity = currentUser.activatedToIdentity

    return (
      <Column>
        <Meta.Group>
          <Meta>
            <header>
              <small>이메일</small>
            </header>

            {meta.activatedToEmail
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>완료</strong>
                </section>
              )
              : null}

            {!meta.activatedToEmail
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>미완료</strong>
                </section>
              )
              : null}

          </Meta>
          <Meta>
            <header>
              <small>휴대폰</small>
            </header>

            {meta.activatedToMobile
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>완료</strong>
                </section>
              )
              : null}

            {!meta.activatedToMobile
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>미완료</strong>
                </section>
              )
              : null}

          </Meta>
          <Meta>
            <header>
              <small>계좌</small>
            </header>

            {meta.activatedToBankAccount
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>완료</strong>
                </section>
              )
              : null}

            {!meta.activatedToBankAccount
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>미완료</strong>
                </section>
              )
              : null}

          </Meta>
          <Meta>
            <header>
              <small>주민번호</small>
            </header>

            {meta.activatedToIdentity
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>완료</strong>
                </section>
              )
              : null}

            {!meta.activatedToIdentity
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>미완료</strong>
                </section>
              )
              : null}

          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

CertificationDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

CertificationDetailColumnContainer.defaultProps = {
  item: {}
}

export default CertificationDetailColumnContainer
