import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, report, filters, sorts, items: oItems, selecteds,
  total, page, limit, blockLimit,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const curAt = new Date(), curMt = moment(curAt)

  const items = oItems.map(item => {
    item.report = report.rows.find(row => row.id === item.id)
    return item
  })

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    const targets = items.filter(item => ['enabled'].includes(_.get(item, 'report.status')))
    if (!targets.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = targets.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...targets.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    if (!['enabled'].includes(_.get(item, 'report.status'))) { return }
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'PayColumn', header: '주문번호', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PaidDateColumn', header: '입금일', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BuyerColumn', header: '결제자', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReceiverColumn', header: '수령인', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'OrdersDetailColumn', header: '주문정보', style: { minWidth: '330px', maxWidth: '330px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'AddressDetailColumn', header: '발송내역 정보', style: { minWidth: '300px', maxWidth: '300px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DurationColumn', header: '경과일', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PrimaryIndexColumn', header: '처리순위', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex:  '1 1 auto', minWidth: 0, maxWidth: '100%' } })
  columns.push({ key: 'StatusColumn', header: '발송가능 체크', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const parcelTypes = {}
  parcelTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  parcelTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  parcelTypes.solution = { name: 'solution', text: '작가서비스' }
  parcelTypes.etc = { name: 'etc', text: '기타' }

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}

  // 배정우선순위 변경기능
  actions.doUpdatePrimaryIndex = async (item = {}) => {
    const n = item.primaryIndex

    if (!_.isInteger(n)) { return alert(`반드시 값을 지정해주어야합니다.`) }
    if (!window.confirm(`우선순위를 변경하면 다시 발송체크를 검증합니다. 진행할까요?`)) { return }

    const body = { primaryIndex: n }
    const result = await api.put(`/parcels/admin2/${item.id}/updatePrimaryIndex`, body).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    
    return initialize()
  }
  
  // 단일로 포장중으로 단계를 변경하는 기능
  actions.doUpadtePackingStatus = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 발송내역을 포장중 단계로 전환 할까요?`)) { return }
    const body = {}
    const result = await api.put(`/parcels/admin2/${item.id}/updatePackingStatus`, body).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`성공적으로 처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 선택한 내역으로 발주를 진행하는 기능
  actions.doUpdatePackingStatusBySelecteds = async (items = selecteds) => {
    if (!items.length) { return alert(`선택한 발송내역이 존재하지 않습니다.`) }
    if (!window.confirm(`해당 발송내역(총 ${items.length}건)을 포장중 단계로 변경처리할까요?`)) { return }

    const result = await items.reduce((prev, item, idx) => prev.then(async (report) => {
      const result = await actions.doUpadtePackingStatus(item, {returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) { alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`) }
    if (!result.failure.length) { alert(`${result.success.length}건이 성공적으로 처리하였습니다.`) }
    return initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 <strong>발송대기</strong> 내역이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        {['enabled'].includes(filters.status)
          ? (
            <Header.Options>
              <a href="#doOpenDepositCreateModal" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doUpdatePackingStatusBySelecteds()]}>포장중 단계전환</a>
            </Header.Options>
            )
          : null}
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}

                    const meta = {}

                    meta.parcelType = (item.parcelType && parcelTypes[item.parcelType])
                      ? parcelTypes[item.parcelType]
                      : parcelTypes.etc

                    meta.pay = _.get(extras, 'currentPays[0]') || {}
                    meta.payNo = _.get(meta.pay, 'payNo') || '-'
                    meta.paidAt = _.get(meta.pay, 'paidAt')
                    meta.payTotalPrice = _.get(meta.pay, 'totalPrice')
                    meta.paidMt = meta.paidAt ? moment(meta.paidAt) : null
                    meta.parcelName = _.get(item, 'name') || _.get(meta.pay, 'subject')

                    meta.user = _.get(extras, 'currentUser') || {}
                    meta.userId = _.get(meta.pay, 'user')
                    meta.userNo = _.get(meta.user, 'userNo') || '-'
                    meta.userAccountId = _.get(meta.user, 'accountId') || '-'
                    meta.receiver = _.get(item, 'receiver') || '-'
                    meta.mobile = _.get(item, 'mobile') || '-'

                    meta.sentAt = _.get(item, 'sentAt')
                    meta.sentMt = meta.sentAt ? moment(meta.sentAt) : null

                    meta.zip = _.get(item, 'zip') || '00000'
                    meta.address1 = _.get(item, 'address1') || '동주소 없음'
                    meta.address2 = _.get(item, 'address2') || '상세주소 없음'
                    meta.trackingNumber = _.get(item, 'trackingNumber') || ''

                    meta.createdAt = _.get(item, 'createdAt')
                    meta.createdMt = meta.createdAt ? moment(meta.createdAt) : null
                    meta.durationDayCount = meta.createdAt
                      ? curMt.diff(meta.createdMt, 'days')
                      : 0

                    meta.primaryIndex = _.get(item, 'primaryIndex')
                    meta.report = item.report || {}

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} disabled={!['enabled'].includes(_.get(meta.report, 'status'))} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'PayColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    const payType = _.get(meta.pay, 'payType')
                                    const id = _.get(meta.pay, 'id')
                                    return window.open(`/pays/${payType}/${id}`)
                                  }}
                                  style={{
                                    cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal',
                                    width: '100%', minWidth: '100%', maxWidth: '100%', textAlign: 'center'
                                  }}
                                >
                                  <div><strong>{meta.payNo}</strong></div>
                                  <div><small>{_.get(meta, 'parcelType.text')}</small></div>
                                </div>
                              )
                            case 'PaidDateColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <strong>{meta.paidMt ? meta.paidMt.format('YYYY.MM.DD') : '0000.00.00'}</strong><br/>
                                  <small>{meta.paidMt ? meta.paidMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'BuyerColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    return window.open(`/users/${meta.userId}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal', textAlign: 'center' }}
                                >
                                  <div><strong>{meta.userAccountId}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}><small>{meta.userNo}</small></div>
                                </div>
                              )
                            case 'ReceiverColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal', textAlign: 'center' }}>
                                  <div><strong>{meta.receiver}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}><small>{meta.mobile}</small></div>
                                </div>
                              )
                            case 'OrdersDetailColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <div><strong>{meta.parcelName}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}>
                                    <small>
                                      <small>총 결제액</small>
                                      <strong style={{ marginLeft: '0.35rem' }}>{comma(meta.payTotalPrice)}</strong>
                                      <small style={{ marginLeft: '0.25rem' }}>원</small>
                                    </small>
                                  </div>
                                </div>
                              )
                            case 'AddressDetailColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <div>
                                    <small style={{ marginRight: '0.35rem' }}>({meta.zip})</small>
                                    <strong>{meta.address1}</strong>
                                  </div>
                                  <div style={{ paddingTop: '0.15rem' }}><small>{meta.address2}</small></div>
                                </div>
                              )
                            case 'DurationColumn':
                              return (
                                <div>
                                  {meta.durationDayCount > 3
                                    ? (
                                      <>
                                        <span>🥵</span>
                                        <strong>+{meta.durationDayCount}일</strong>
                                      </>
                                    )
                                    : null}
                                  {meta.durationDayCount <= 3
                                    ? (
                                      <>
                                        <span>🤭</span>
                                        <strong>+{meta.durationDayCount}일</strong>
                                      </>
                                    )
                                    : null}
                                </div>
                              )
                            case 'PrimaryIndexColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    value={meta.primaryIndex}
                                    style={{ marginRight: '0.35rem', textAlign: 'center' }}
                                    onChange={e => {
                                      const n  = _.isInteger(e.target.value * 1) ? e.target.value * 1 : 0
                                      const next = {}
                                      next[`items[${itemIdx}].primaryIndex`] = n
                                      return handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdatePrimaryIndex(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>
                              )
                            case 'StatusColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={e => {
                                    console.log(meta.report)
                                  }}
                                >
                                  {['enabled'].includes(_.get(meta.report, 'status')) ? (<strong>🟢 발송가능</strong>) : null}
                                  {!['enabled'].includes(_.get(meta.report, 'status')) ? (<strong>🔴 발송불가</strong>) : null}
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>당일 발송체크 가능한 발송내역이 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 300,
  blockLimit: 5,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '발송체크',
  nav: 'ready'
}

export default List
