const parser = {}

parser.columns = [
  { header: '날짜', key: 'date', view: (item) => item.date },
  { header: '도서명', key: 'title', view: (item) => item.title },
  { header: '정가', key: 'price', view: (item) => item.price },
  { header: '부수', key: 'amount', view: (item) => item.amount },
  { header: '합계금', key: 'total', view: (item) => item.total },
  { header: '공급률', key: 'rate', view: (item) => item.total * 0.8 },
]

export default parser
