import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  font-family: ${font('primary')};

  & > div.solutionType { position: relative; box-sizing: border-box; }
  & > div.solutionName { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.sellerName { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class SolutionDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }
  
  render() {
    const { item = {} } = this.props

    const solution = _.get(item, 'content.solution') || {} // 문서 제출 당시의 정보 기반으로 설정한다.

    const meta = {}
    meta.solutionName = _.get(solution, 'name') || '작가서비스명 미기재'
    meta.sellerName = _.get(solution, 'sellerName') || '판매자명 미기재'
    meta.categoryAlias = _.get(solution, 'category.text')

    meta.userId = _.get(item, 'user') || null
    meta.productId = _.get(item, 'content.productId') || null

    return (
      <Column>
        <div className="solutionType">
          <small style={{ marginRight: '0.15rem' }}>{meta.categoryAlias}</small>
        </div>
        <div className="solutionName">
          <a
            href={`/products/solution/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong>{meta.solutionName}
            </strong>
          </a>
        </div>
        <div className="sellerName">
          <a
            href={`/users/${meta.userId}`}
            target="_blank"
            onClick={e => {
              if (!meta.userId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`유저정보개 배정되지 못한 문서입니다. 시스템 관리자에게 문의해주세요.`)]
              }
              return
            }}
          >
            <small>{meta.sellerName}</small>
          </a>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

SolutionDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

SolutionDetailColumnContainer.defaultProps = {
  item: {}
}

export default SolutionDetailColumnContainer
