// @ 산간지방 배송비 테이블
const areas = []
areas.push({ zip: '59133', price: 5000 })
areas.push({ zip: '59154', price: 5000 })
areas.push({ zip: '59155', price: 5000 })
areas.push({ zip: '59156', price: 5000 })
areas.push({ zip: '59157', price: 5000 })
areas.push({ zip: '59158', price: 5000 })
areas.push({ zip: '59159', price: 5000 })
areas.push({ zip: '59170', price: 5000 })
areas.push({ zip: '59106', price: 5000 })
areas.push({ zip: '59102', price: 5000 })
areas.push({ zip: '59103', price: 5000 })
areas.push({ zip: '59103', price: 5000 })
areas.push({ zip: '11020', price: 5000 })
areas.push({ zip: '11021', price: 5000 })
areas.push({ zip: '11022', price: 5000 })
areas.push({ zip: '11023', price: 5000 })
areas.push({ zip: '29011', price: 5000 })
areas.push({ zip: '29012', price: 5000 })
areas.push({ zip: '29013', price: 5000 })
areas.push({ zip: '29014', price: 5000 })
areas.push({ zip: '29015', price: 5000 })
areas.push({ zip: '59149', price: 5000 })
areas.push({ zip: '59150', price: 5000 })
areas.push({ zip: '59151', price: 5000 })
areas.push({ zip: '59152', price: 5000 })
areas.push({ zip: '59137', price: 5000 })
areas.push({ zip: '59160', price: 5000 })
areas.push({ zip: '59161', price: 5000 })
areas.push({ zip: '59162', price: 5000 })
areas.push({ zip: '59163', price: 5000 })
areas.push({ zip: '59144', price: 5000 })
areas.push({ zip: '59145', price: 5000 })
areas.push({ zip: '59153', price: 5000 })
areas.push({ zip: '59164', price: 5000 })
areas.push({ zip: '59165', price: 5000 })
areas.push({ zip: '59166', price: 5000 })
areas.push({ zip: '59143', price: 4500 })
areas.push({ zip: '23000', price: 4000 })
areas.push({ zip: '23001', price: 4000 })
areas.push({ zip: '23002', price: 4000 })
areas.push({ zip: '23003', price: 4000 })
areas.push({ zip: '23004', price: 4000 })
areas.push({ zip: '23005', price: 4000 })
areas.push({ zip: '23006', price: 4000 })
areas.push({ zip: '23007', price: 4000 })
areas.push({ zip: '23009', price: 4000 })
areas.push({ zip: '23008', price: 4000 })
areas.push({ zip: '23010', price: 4000 })
areas.push({ zip: '23105', price: 9900 })
areas.push({ zip: '23106', price: 9900 })
areas.push({ zip: '23100', price: 9900 })
areas.push({ zip: '23101', price: 9900 })
areas.push({ zip: '23102', price: 9900 })
areas.push({ zip: '23103', price: 9900 })
areas.push({ zip: '23104', price: 9900 })
areas.push({ zip: '23129', price: 7700 })
areas.push({ zip: '23130', price: 7700 })
areas.push({ zip: '23131', price: 7700 })
areas.push({ zip: '23132', price: 7700 })
areas.push({ zip: '23133', price: 7700 })
areas.push({ zip: '23134', price: 7700 })
areas.push({ zip: '23135', price: 7700 })
areas.push({ zip: '23136', price: 7700 })
areas.push({ zip: '23107', price: 7700 })
areas.push({ zip: '23108', price: 7700 })
areas.push({ zip: '23109', price: 7700 })
areas.push({ zip: '23124', price: 7700 })
areas.push({ zip: '23125', price: 7700 })
areas.push({ zip: '23126', price: 7700 })
areas.push({ zip: '23127', price: 7700 })
areas.push({ zip: '23128', price: 7700 })
areas.push({ zip: '23110', price: 6000 })
areas.push({ zip: '23111', price: 6000 })
areas.push({ zip: '23112', price: 6000 })
areas.push({ zip: '23113', price: 6000 })
areas.push({ zip: '23114', price: 6000 })
areas.push({ zip: '23115', price: 6000 })
areas.push({ zip: '23116', price: 6000 })
areas.push({ zip: '37133', price: 2000 })
areas.push({ zip: '40200', price: 6500 })
areas.push({ zip: '40201', price: 6500 })
areas.push({ zip: '40202', price: 6500 })
areas.push({ zip: '40203', price: 6500 })
areas.push({ zip: '40204', price: 6500 })
areas.push({ zip: '40205', price: 6500 })
areas.push({ zip: '40206', price: 6500 })
areas.push({ zip: '40207', price: 6500 })
areas.push({ zip: '40208', price: 6500 })
areas.push({ zip: '40209', price: 6500 })
areas.push({ zip: '40210', price: 6500 })
areas.push({ zip: '40211', price: 6500 })
areas.push({ zip: '40212', price: 6500 })
areas.push({ zip: '40213', price: 6500 })
areas.push({ zip: '40214', price: 6500 })
areas.push({ zip: '40215', price: 6500 })
areas.push({ zip: '40216', price: 6500 })
areas.push({ zip: '40217', price: 6500 })
areas.push({ zip: '40218', price: 6500 })
areas.push({ zip: '40219', price: 6500 })
areas.push({ zip: '40220', price: 6500 })
areas.push({ zip: '40221', price: 6500 })
areas.push({ zip: '40222', price: 6500 })
areas.push({ zip: '40223', price: 6500 })
areas.push({ zip: '40224', price: 6500 })
areas.push({ zip: '40225', price: 6500 })
areas.push({ zip: '40226', price: 6500 })
areas.push({ zip: '40227', price: 6500 })
areas.push({ zip: '40228', price: 6500 })
areas.push({ zip: '40229', price: 6500 })
areas.push({ zip: '40230', price: 6500 })
areas.push({ zip: '40231', price: 6500 })
areas.push({ zip: '40232', price: 6500 })
areas.push({ zip: '40233', price: 6500 })
areas.push({ zip: '40234', price: 6500 })
areas.push({ zip: '40235', price: 6500 })
areas.push({ zip: '40236', price: 6500 })
areas.push({ zip: '40237', price: 6500 })
areas.push({ zip: '40238', price: 6500 })
areas.push({ zip: '40239', price: 6500 })
areas.push({ zip: '40240', price: 6500 })
areas.push({ zip: '37102', price: 4000 })
areas.push({ zip: '37102', price: 4000 })
areas.push({ zip: '37100', price: 4000 })
areas.push({ zip: '37101', price: 4000 })
areas.push({ zip: '37102', price: 4000 })
areas.push({ zip: '53031', price: 4000 })
areas.push({ zip: '53099', price: 4000 })
areas.push({ zip: '53100', price: 4000 })
areas.push({ zip: '53098', price: 4000 })
areas.push({ zip: '53100', price: 4000 })
areas.push({ zip: '53101', price: 4000 })
areas.push({ zip: '53092', price: 4000 })
areas.push({ zip: '53094', price: 4000 })
areas.push({ zip: '53096', price: 4000 })
areas.push({ zip: '53092', price: 4000 })
areas.push({ zip: '53095', price: 4000 })
areas.push({ zip: '53093', price: 4000 })
areas.push({ zip: '53092', price: 4000 })
areas.push({ zip: '53103', price: 4000 })
areas.push({ zip: '53103', price: 4000 })
areas.push({ zip: '53104', price: 4000 })
areas.push({ zip: '58809', price: 5000 })
areas.push({ zip: '58817', price: 5000 })
areas.push({ zip: '58816', price: 5000 })
areas.push({ zip: '58810', price: 5000 })
areas.push({ zip: '58814', price: 5000 })
areas.push({ zip: '58800', price: 5000 })
areas.push({ zip: '58801', price: 5000 })
areas.push({ zip: '58802', price: 5000 })
areas.push({ zip: '58803', price: 5000 })
areas.push({ zip: '58804', price: 5000 })
areas.push({ zip: '58805', price: 5000 })
areas.push({ zip: '58806', price: 5000 })
areas.push({ zip: '58828', price: 5000 })
areas.push({ zip: '58829', price: 5000 })
areas.push({ zip: '58830', price: 5000 })
areas.push({ zip: '58831', price: 5000 })
areas.push({ zip: '58832', price: 5000 })
areas.push({ zip: '58833', price: 5000 })
areas.push({ zip: '58834', price: 5000 })
areas.push({ zip: '58835', price: 5000 })
areas.push({ zip: '58836', price: 5000 })
areas.push({ zip: '58837', price: 5000 })
areas.push({ zip: '58838', price: 5000 })
areas.push({ zip: '58839', price: 5000 })
areas.push({ zip: '58840', price: 5000 })
areas.push({ zip: '58841', price: 5000 })
areas.push({ zip: '58842', price: 5000 })
areas.push({ zip: '58843', price: 5000 })
areas.push({ zip: '58844', price: 5000 })
areas.push({ zip: '58845', price: 5000 })
areas.push({ zip: '58846', price: 5000 })
areas.push({ zip: '58847', price: 5000 })
areas.push({ zip: '58848', price: 5000 })
areas.push({ zip: '58849', price: 5000 })
areas.push({ zip: '58850', price: 5000 })
areas.push({ zip: '58851', price: 5000 })
areas.push({ zip: '58852', price: 5000 })
areas.push({ zip: '58853', price: 5000 })
areas.push({ zip: '58854', price: 5000 })
areas.push({ zip: '58855', price: 5000 })
areas.push({ zip: '58856', price: 5000 })
areas.push({ zip: '58857', price: 5000 })
areas.push({ zip: '58858', price: 5000 })
areas.push({ zip: '58859', price: 5000 })
areas.push({ zip: '58860', price: 5000 })
areas.push({ zip: '58861', price: 5000 })
areas.push({ zip: '58862', price: 5000 })
areas.push({ zip: '58863', price: 5000 })
areas.push({ zip: '58864', price: 5000 })
areas.push({ zip: '58865', price: 5000 })
areas.push({ zip: '58866', price: 5000 })
areas.push({ zip: '58862', price: 8000 })
areas.push({ zip: '58863', price: 8000 })
areas.push({ zip: '58864', price: 8000 })
areas.push({ zip: '58865', price: 8000 })
areas.push({ zip: '58866', price: 8000 })
areas.push({ zip: '59784', price: 5500 })
areas.push({ zip: '59785', price: 5500 })
areas.push({ zip: '59786', price: 5500 })
areas.push({ zip: '59787', price: 5500 })
areas.push({ zip: '59788', price: 4000 })
areas.push({ zip: '59789', price: 4000 })
areas.push({ zip: '59790', price: 4000 })
areas.push({ zip: '59650', price: 5500 })
areas.push({ zip: '59781', price: 5500 })
areas.push({ zip: '59783', price: 5500 })
areas.push({ zip: '57068', price: 3000 })
areas.push({ zip: '57069', price: 3000 })
areas.push({ zip: '59551', price: 1000 })
areas.push({ zip: '59563', price: 5000 })
areas.push({ zip: '58953', price: 5000 })
areas.push({ zip: '58954', price: 5000 })
areas.push({ zip: '58955', price: 5000 })
areas.push({ zip: '58956', price: 5000 })
areas.push({ zip: '58957', price: 5000 })
areas.push({ zip: '58958', price: 5000 })
areas.push({ zip: '54000', price: 5000 })
areas.push({ zip: '56347', price: 3000 })
areas.push({ zip: '56348', price: 3000 })
areas.push({ zip: '56349', price: 3000 })
areas.push({ zip: '33411', price: 4000 })
areas.push({ zip: '15654', price: 6000 })
areas.push({ zip: '52571', price: 2000 })
areas.push({ zip: '52570', price: 2000 })
areas.push({ zip: '59766', price: 5500 })
areas.push({ zip: '59617', price: 4000 })
areas.push({ zip: '58762', price: 5000 })
areas.push({ zip: '58760', price: 5000 })

areas.push({ zip: '63000', price: 5000 })
areas.push({ zip: '63001', price: 5000 })


areas.push({ zip: '63000', price: 3000 })
areas.push({ zip: '63001', price: 3000 })
areas.push({ zip: '63002', price: 3000 })
areas.push({ zip: '63003', price: 3000 })
areas.push({ zip: '63004', price: 3000 })
areas.push({ zip: '63005', price: 3000 })
areas.push({ zip: '63006', price: 3000 })
areas.push({ zip: '63007', price: 3000 })
areas.push({ zip: '63008', price: 3000 })
areas.push({ zip: '63009', price: 3000 })
areas.push({ zip: '63010', price: 3000 })
areas.push({ zip: '63011', price: 3000 })
areas.push({ zip: '63012', price: 3000 })
areas.push({ zip: '63013', price: 3000 })
areas.push({ zip: '63014', price: 3000 })
areas.push({ zip: '63015', price: 3000 })
areas.push({ zip: '63016', price: 3000 })
areas.push({ zip: '63017', price: 3000 })
areas.push({ zip: '63018', price: 3000 })
areas.push({ zip: '63019', price: 3000 })
areas.push({ zip: '63020', price: 3000 })
areas.push({ zip: '63021', price: 3000 })
areas.push({ zip: '63022', price: 3000 })
areas.push({ zip: '63023', price: 3000 })
areas.push({ zip: '63024', price: 3000 })
areas.push({ zip: '63025', price: 3000 })
areas.push({ zip: '63026', price: 3000 })
areas.push({ zip: '63027', price: 3000 })
areas.push({ zip: '63028', price: 3000 })
areas.push({ zip: '63029', price: 3000 })
areas.push({ zip: '63030', price: 3000 })
areas.push({ zip: '63031', price: 3000 })
areas.push({ zip: '63032', price: 3000 })
areas.push({ zip: '63032', price: 3000 })
areas.push({ zip: '63033', price: 3000 })
areas.push({ zip: '63034', price: 3000 })
areas.push({ zip: '63035', price: 3000 })
areas.push({ zip: '63036', price: 3000 })
areas.push({ zip: '63037', price: 3000 })
areas.push({ zip: '63038', price: 3000 })
areas.push({ zip: '63039', price: 3000 })
areas.push({ zip: '63040', price: 3000 })
areas.push({ zip: '63041', price: 3000 })
areas.push({ zip: '63042', price: 3000 })
areas.push({ zip: '63043', price: 3000 })
areas.push({ zip: '63044', price: 3000 })
areas.push({ zip: '63045', price: 3000 })
areas.push({ zip: '63046', price: 3000 })
areas.push({ zip: '63047', price: 3000 })
areas.push({ zip: '63048', price: 3000 })
areas.push({ zip: '63049', price: 3000 })
areas.push({ zip: '63050', price: 3000 })
areas.push({ zip: '63051', price: 3000 })
areas.push({ zip: '63052', price: 3000 })
areas.push({ zip: '63053', price: 3000 })
areas.push({ zip: '63054', price: 3000 })
areas.push({ zip: '63055', price: 3000 })
areas.push({ zip: '63056', price: 3000 })
areas.push({ zip: '63057', price: 3000 })
areas.push({ zip: '63058', price: 3000 })
areas.push({ zip: '63059', price: 3000 })
areas.push({ zip: '63060', price: 3000 })
areas.push({ zip: '63061', price: 3000 })
areas.push({ zip: '63062', price: 3000 })
areas.push({ zip: '63063', price: 3000 })
areas.push({ zip: '63064', price: 3000 })
areas.push({ zip: '63065', price: 3000 })
areas.push({ zip: '63066', price: 3000 })
areas.push({ zip: '63067', price: 3000 })
areas.push({ zip: '63067', price: 3000 })
areas.push({ zip: '63068', price: 3000 })
areas.push({ zip: '63069', price: 3000 })
areas.push({ zip: '63070', price: 3000 })
areas.push({ zip: '63071', price: 3000 })
areas.push({ zip: '63072', price: 3000 })
areas.push({ zip: '63073', price: 3000 })
areas.push({ zip: '63074', price: 3000 })
areas.push({ zip: '63075', price: 3000 })
areas.push({ zip: '63076', price: 3000 })
areas.push({ zip: '63077', price: 3000 })
areas.push({ zip: '63078', price: 3000 })
areas.push({ zip: '63079', price: 3000 })
areas.push({ zip: '63080', price: 3000 })
areas.push({ zip: '63081', price: 3000 })
areas.push({ zip: '63082', price: 3000 })
areas.push({ zip: '63083', price: 3000 })
areas.push({ zip: '63084', price: 3000 })
areas.push({ zip: '63085', price: 3000 })
areas.push({ zip: '63086', price: 3000 })
areas.push({ zip: '63087', price: 3000 })
areas.push({ zip: '63088', price: 3000 })
areas.push({ zip: '63089', price: 3000 })
areas.push({ zip: '63090', price: 3000 })
areas.push({ zip: '63091', price: 3000 })
areas.push({ zip: '63092', price: 3000 })
areas.push({ zip: '63093', price: 3000 })
areas.push({ zip: '63094', price: 3000 })
areas.push({ zip: '63095', price: 3000 })
areas.push({ zip: '63096', price: 3000 })
areas.push({ zip: '63097', price: 3000 })
areas.push({ zip: '63098', price: 3000 })
areas.push({ zip: '63099', price: 3000 })
areas.push({ zip: '63100', price: 3000 })
areas.push({ zip: '63101', price: 3000 })
areas.push({ zip: '63102', price: 3000 })
areas.push({ zip: '63103', price: 3000 })
areas.push({ zip: '63104', price: 3000 })
areas.push({ zip: '63105', price: 3000 })
areas.push({ zip: '63106', price: 3000 })
areas.push({ zip: '63107', price: 3000 })
areas.push({ zip: '63108', price: 3000 })
areas.push({ zip: '63109', price: 3000 })
areas.push({ zip: '63110', price: 3000 })
areas.push({ zip: '63111', price: 3000 })
areas.push({ zip: '63112', price: 3000 })
areas.push({ zip: '63113', price: 3000 })
areas.push({ zip: '63114', price: 3000 })
areas.push({ zip: '63115', price: 3000 })
areas.push({ zip: '63116', price: 3000 })
areas.push({ zip: '63117', price: 3000 })
areas.push({ zip: '63118', price: 3000 })
areas.push({ zip: '63119', price: 3000 })
areas.push({ zip: '63120', price: 3000 })
areas.push({ zip: '63121', price: 3000 })
areas.push({ zip: '63122', price: 3000 })
areas.push({ zip: '63123', price: 3000 })
areas.push({ zip: '63124', price: 3000 })
areas.push({ zip: '63125', price: 3000 })
areas.push({ zip: '63126', price: 3000 })
areas.push({ zip: '63127', price: 3000 })
areas.push({ zip: '63128', price: 3000 })
areas.push({ zip: '63129', price: 3000 })
areas.push({ zip: '63130', price: 3000 })
areas.push({ zip: '63131', price: 3000 })
areas.push({ zip: '63132', price: 3000 })
areas.push({ zip: '63133', price: 3000 })
areas.push({ zip: '63134', price: 3000 })
areas.push({ zip: '63135', price: 3000 })
areas.push({ zip: '63136', price: 3000 })
areas.push({ zip: '63137', price: 3000 })
areas.push({ zip: '63138', price: 3000 })
areas.push({ zip: '63139', price: 3000 })
areas.push({ zip: '63140', price: 3000 })
areas.push({ zip: '63141', price: 3000 })
areas.push({ zip: '63142', price: 3000 })
areas.push({ zip: '63143', price: 3000 })
areas.push({ zip: '63144', price: 3000 })
areas.push({ zip: '63145', price: 3000 })
areas.push({ zip: '63146', price: 3000 })
areas.push({ zip: '63147', price: 3000 })
areas.push({ zip: '63148', price: 3000 })
areas.push({ zip: '63149', price: 3000 })
areas.push({ zip: '63150', price: 3000 })
areas.push({ zip: '63151', price: 3000 })
areas.push({ zip: '63152', price: 3000 })
areas.push({ zip: '63153', price: 3000 })
areas.push({ zip: '63154', price: 3000 })
areas.push({ zip: '63155', price: 3000 })
areas.push({ zip: '63156', price: 3000 })
areas.push({ zip: '63157', price: 3000 })
areas.push({ zip: '63158', price: 3000 })
areas.push({ zip: '63159', price: 3000 })
areas.push({ zip: '63160', price: 3000 })
areas.push({ zip: '63161', price: 3000 })
areas.push({ zip: '63162', price: 3000 })
areas.push({ zip: '63163', price: 3000 })
areas.push({ zip: '63164', price: 3000 })
areas.push({ zip: '63165', price: 3000 })
areas.push({ zip: '63166', price: 3000 })
areas.push({ zip: '63167', price: 3000 })
areas.push({ zip: '63168', price: 3000 })
areas.push({ zip: '63169', price: 3000 })
areas.push({ zip: '63170', price: 3000 })
areas.push({ zip: '63171', price: 3000 })
areas.push({ zip: '63172', price: 3000 })
areas.push({ zip: '63173', price: 3000 })
areas.push({ zip: '63174', price: 3000 })
areas.push({ zip: '63175', price: 3000 })
areas.push({ zip: '63176', price: 3000 })
areas.push({ zip: '63177', price: 3000 })
areas.push({ zip: '63178', price: 3000 })
areas.push({ zip: '63179', price: 3000 })
areas.push({ zip: '63180', price: 3000 })
areas.push({ zip: '63181', price: 3000 })
areas.push({ zip: '63182', price: 3000 })
areas.push({ zip: '63183', price: 3000 })
areas.push({ zip: '63184', price: 3000 })
areas.push({ zip: '63185', price: 3000 })
areas.push({ zip: '63186', price: 3000 })
areas.push({ zip: '63187', price: 3000 })
areas.push({ zip: '63188', price: 3000 })
areas.push({ zip: '63189', price: 3000 })
areas.push({ zip: '63190', price: 3000 })
areas.push({ zip: '63191', price: 3000 })
areas.push({ zip: '63192', price: 3000 })
areas.push({ zip: '63193', price: 3000 })
areas.push({ zip: '63194', price: 3000 })
areas.push({ zip: '63195', price: 3000 })
areas.push({ zip: '63196', price: 3000 })
areas.push({ zip: '63197', price: 3000 })
areas.push({ zip: '63198', price: 3000 })
areas.push({ zip: '63199', price: 3000 })
areas.push({ zip: '63200', price: 3000 })
areas.push({ zip: '63201', price: 3000 })
areas.push({ zip: '63202', price: 3000 })
areas.push({ zip: '63203', price: 3000 })
areas.push({ zip: '63204', price: 3000 })
areas.push({ zip: '63205', price: 3000 })
areas.push({ zip: '63206', price: 3000 })
areas.push({ zip: '63207', price: 3000 })
areas.push({ zip: '63208', price: 3000 })
areas.push({ zip: '63209', price: 3000 })
areas.push({ zip: '63210', price: 3000 })
areas.push({ zip: '63211', price: 3000 })
areas.push({ zip: '63212', price: 3000 })
areas.push({ zip: '63213', price: 3000 })
areas.push({ zip: '63214', price: 3000 })
areas.push({ zip: '63215', price: 3000 })
areas.push({ zip: '63216', price: 3000 })
areas.push({ zip: '63217', price: 3000 })
areas.push({ zip: '63218', price: 3000 })
areas.push({ zip: '63219', price: 3000 })
areas.push({ zip: '63220', price: 3000 })
areas.push({ zip: '63221', price: 3000 })
areas.push({ zip: '63222', price: 3000 })
areas.push({ zip: '63223', price: 3000 })
areas.push({ zip: '63224', price: 3000 })
areas.push({ zip: '63225', price: 3000 })
areas.push({ zip: '63226', price: 3000 })
areas.push({ zip: '63227', price: 3000 })
areas.push({ zip: '63228', price: 3000 })
areas.push({ zip: '63229', price: 3000 })
areas.push({ zip: '63230', price: 3000 })
areas.push({ zip: '63231', price: 3000 })
areas.push({ zip: '63232', price: 3000 })
areas.push({ zip: '63233', price: 3000 })
areas.push({ zip: '63234', price: 3000 })
areas.push({ zip: '63235', price: 3000 })
areas.push({ zip: '63236', price: 3000 })
areas.push({ zip: '63237', price: 3000 })
areas.push({ zip: '63238', price: 3000 })
areas.push({ zip: '63239', price: 3000 })
areas.push({ zip: '63240', price: 3000 })
areas.push({ zip: '63241', price: 3000 })
areas.push({ zip: '63242', price: 3000 })
areas.push({ zip: '63243', price: 3000 })
areas.push({ zip: '63244', price: 3000 })
areas.push({ zip: '63245', price: 3000 })
areas.push({ zip: '63246', price: 3000 })
areas.push({ zip: '63247', price: 3000 })
areas.push({ zip: '63248', price: 3000 })
areas.push({ zip: '63249', price: 3000 })
areas.push({ zip: '63250', price: 3000 })
areas.push({ zip: '63251', price: 3000 })
areas.push({ zip: '63252', price: 3000 })
areas.push({ zip: '63253', price: 3000 })
areas.push({ zip: '63254', price: 3000 })
areas.push({ zip: '63255', price: 3000 })
areas.push({ zip: '63256', price: 3000 })
areas.push({ zip: '63257', price: 3000 })
areas.push({ zip: '63258', price: 3000 })
areas.push({ zip: '63259', price: 3000 })
areas.push({ zip: '63260', price: 3000 })
areas.push({ zip: '63261', price: 3000 })
areas.push({ zip: '63262', price: 3000 })
areas.push({ zip: '63263', price: 3000 })
areas.push({ zip: '63264', price: 3000 })
areas.push({ zip: '63265', price: 3000 })
areas.push({ zip: '63266', price: 3000 })
areas.push({ zip: '63267', price: 3000 })
areas.push({ zip: '63268', price: 3000 })
areas.push({ zip: '63269', price: 3000 })
areas.push({ zip: '63270', price: 3000 })
areas.push({ zip: '63271', price: 3000 })
areas.push({ zip: '63272', price: 3000 })
areas.push({ zip: '63273', price: 3000 })
areas.push({ zip: '63274', price: 3000 })
areas.push({ zip: '63275', price: 3000 })
areas.push({ zip: '63276', price: 3000 })
areas.push({ zip: '63277', price: 3000 })
areas.push({ zip: '63278', price: 3000 })
areas.push({ zip: '63279', price: 3000 })
areas.push({ zip: '63280', price: 3000 })
areas.push({ zip: '63281', price: 3000 })
areas.push({ zip: '63282', price: 3000 })
areas.push({ zip: '63283', price: 3000 })
areas.push({ zip: '63284', price: 3000 })
areas.push({ zip: '63285', price: 3000 })
areas.push({ zip: '63286', price: 3000 })
areas.push({ zip: '63287', price: 3000 })
areas.push({ zip: '63288', price: 3000 })
areas.push({ zip: '63289', price: 3000 })
areas.push({ zip: '63290', price: 3000 })
areas.push({ zip: '63291', price: 3000 })
areas.push({ zip: '63292', price: 3000 })
areas.push({ zip: '63293', price: 3000 })
areas.push({ zip: '63294', price: 3000 })
areas.push({ zip: '63295', price: 3000 })
areas.push({ zip: '63296', price: 3000 })
areas.push({ zip: '63297', price: 3000 })
areas.push({ zip: '63298', price: 3000 })
areas.push({ zip: '63299', price: 3000 })
areas.push({ zip: '63300', price: 3000 })
areas.push({ zip: '63301', price: 3000 })
areas.push({ zip: '63302', price: 3000 })
areas.push({ zip: '63303', price: 3000 })
areas.push({ zip: '63304', price: 3000 })
areas.push({ zip: '63305', price: 3000 })
areas.push({ zip: '63306', price: 3000 })
areas.push({ zip: '63307', price: 3000 })
areas.push({ zip: '63308', price: 3000 })
areas.push({ zip: '63309', price: 3000 })
areas.push({ zip: '63310', price: 3000 })
areas.push({ zip: '63311', price: 3000 })
areas.push({ zip: '63312', price: 3000 })
areas.push({ zip: '63313', price: 3000 })
areas.push({ zip: '63314', price: 3000 })
areas.push({ zip: '63315', price: 3000 })
areas.push({ zip: '63316', price: 3000 })
areas.push({ zip: '63317', price: 3000 })
areas.push({ zip: '63318', price: 3000 })
areas.push({ zip: '63319', price: 3000 })
areas.push({ zip: '63321', price: 3000 })
areas.push({ zip: '63322', price: 3000 })
areas.push({ zip: '63323', price: 3000 })
areas.push({ zip: '63324', price: 3000 })
areas.push({ zip: '63325', price: 3000 })
areas.push({ zip: '63326', price: 3000 })
areas.push({ zip: '63327', price: 3000 })
areas.push({ zip: '63328', price: 3000 })
areas.push({ zip: '63329', price: 3000 })
areas.push({ zip: '63330', price: 3000 })
areas.push({ zip: '63331', price: 3000 })
areas.push({ zip: '63332', price: 3000 })
areas.push({ zip: '63333', price: 3000 })
areas.push({ zip: '63334', price: 3000 })
areas.push({ zip: '63335', price: 3000 })
areas.push({ zip: '63336', price: 3000 })
areas.push({ zip: '63337', price: 3000 })
areas.push({ zip: '63338', price: 3000 })
areas.push({ zip: '63339', price: 3000 })
areas.push({ zip: '63340', price: 3000 })
areas.push({ zip: '63341', price: 3000 })
areas.push({ zip: '63342', price: 3000 })
areas.push({ zip: '63343', price: 3000 })
areas.push({ zip: '63344', price: 3000 })
areas.push({ zip: '63345', price: 3000 })
areas.push({ zip: '63346', price: 3000 })
areas.push({ zip: '63347', price: 3000 })
areas.push({ zip: '63348', price: 3000 })
areas.push({ zip: '63349', price: 3000 })
areas.push({ zip: '63350', price: 3000 })
areas.push({ zip: '63351', price: 3000 })
areas.push({ zip: '63352', price: 3000 })
areas.push({ zip: '63353', price: 3000 })
areas.push({ zip: '63354', price: 3000 })
areas.push({ zip: '63355', price: 3000 })
areas.push({ zip: '63356', price: 3000 })
areas.push({ zip: '63357', price: 3000 })
areas.push({ zip: '63358', price: 3000 })
areas.push({ zip: '63359', price: 3000 })
areas.push({ zip: '63360', price: 3000 })
areas.push({ zip: '63361', price: 3000 })
areas.push({ zip: '63362', price: 3000 })
areas.push({ zip: '63363', price: 3000 })
areas.push({ zip: '63364', price: 3000 })
areas.push({ zip: '63365', price: 3000 })
areas.push({ zip: '63500', price: 3000 })
areas.push({ zip: '63501', price: 3000 })
areas.push({ zip: '63502', price: 3000 })
areas.push({ zip: '63503', price: 3000 })
areas.push({ zip: '63504', price: 3000 })
areas.push({ zip: '63505', price: 3000 })
areas.push({ zip: '63506', price: 3000 })
areas.push({ zip: '63507', price: 3000 })
areas.push({ zip: '63508', price: 3000 })
areas.push({ zip: '63509', price: 3000 })
areas.push({ zip: '63510', price: 3000 })
areas.push({ zip: '63511', price: 3000 })
areas.push({ zip: '63512', price: 3000 })
areas.push({ zip: '63513', price: 3000 })
areas.push({ zip: '63514', price: 3000 })
areas.push({ zip: '63515', price: 3000 })
areas.push({ zip: '63516', price: 3000 })
areas.push({ zip: '63517', price: 3000 })
areas.push({ zip: '63518', price: 3000 })
areas.push({ zip: '63519', price: 3000 })
areas.push({ zip: '63520', price: 3000 })
areas.push({ zip: '63521', price: 3000 })
areas.push({ zip: '63522', price: 3000 })
areas.push({ zip: '63523', price: 3000 })
areas.push({ zip: '63524', price: 3000 })
areas.push({ zip: '63525', price: 3000 })
areas.push({ zip: '63526', price: 3000 })
areas.push({ zip: '63527', price: 3000 })
areas.push({ zip: '63528', price: 3000 })
areas.push({ zip: '63529', price: 3000 })
areas.push({ zip: '63530', price: 3000 })
areas.push({ zip: '63530', price: 3000 })
areas.push({ zip: '63531', price: 3000 })
areas.push({ zip: '63532', price: 3000 })
areas.push({ zip: '63533', price: 3000 })
areas.push({ zip: '63534', price: 3000 })
areas.push({ zip: '63535', price: 3000 })
areas.push({ zip: '63536', price: 3000 })
areas.push({ zip: '63537', price: 3000 })
areas.push({ zip: '63538', price: 3000 })
areas.push({ zip: '63539', price: 3000 })
areas.push({ zip: '63540', price: 3000 })
areas.push({ zip: '63541', price: 3000 })
areas.push({ zip: '63542', price: 3000 })
areas.push({ zip: '63543', price: 3000 })
areas.push({ zip: '63544', price: 3000 })
areas.push({ zip: '63545', price: 3000 })
areas.push({ zip: '63546', price: 3000 })
areas.push({ zip: '63547', price: 3000 })
areas.push({ zip: '63548', price: 3000 })
areas.push({ zip: '63549', price: 3000 })
areas.push({ zip: '63550', price: 3000 })
areas.push({ zip: '63551', price: 3000 })
areas.push({ zip: '63552', price: 3000 })
areas.push({ zip: '63553', price: 3000 })
areas.push({ zip: '63554', price: 3000 })
areas.push({ zip: '63555', price: 3000 })
areas.push({ zip: '63556', price: 3000 })
areas.push({ zip: '63557', price: 3000 })
areas.push({ zip: '63558', price: 3000 })
areas.push({ zip: '63559', price: 3000 })
areas.push({ zip: '63560', price: 3000 })
areas.push({ zip: '63561', price: 3000 })
areas.push({ zip: '63562', price: 3000 })
areas.push({ zip: '63563', price: 3000 })
areas.push({ zip: '63564', price: 3000 })
areas.push({ zip: '63565', price: 3000 })
areas.push({ zip: '63566', price: 3000 })
areas.push({ zip: '63567', price: 3000 })
areas.push({ zip: '63568', price: 3000 })
areas.push({ zip: '63569', price: 3000 })
areas.push({ zip: '63570', price: 3000 })
areas.push({ zip: '63571', price: 3000 })
areas.push({ zip: '63572', price: 3000 })
areas.push({ zip: '63573', price: 3000 })
areas.push({ zip: '63574', price: 3000 })
areas.push({ zip: '63575', price: 3000 })
areas.push({ zip: '63576', price: 3000 })
areas.push({ zip: '63577', price: 3000 })
areas.push({ zip: '63578', price: 3000 })
areas.push({ zip: '63579', price: 3000 })
areas.push({ zip: '63580', price: 3000 })
areas.push({ zip: '63581', price: 3000 })
areas.push({ zip: '63582', price: 3000 })
areas.push({ zip: '63583', price: 3000 })
areas.push({ zip: '63584', price: 3000 })
areas.push({ zip: '63585', price: 3000 })
areas.push({ zip: '63586', price: 3000 })
areas.push({ zip: '63587', price: 3000 })
areas.push({ zip: '63588', price: 3000 })
areas.push({ zip: '63589', price: 3000 })
areas.push({ zip: '63590', price: 3000 })
areas.push({ zip: '63591', price: 3000 })
areas.push({ zip: '63592', price: 3000 })
areas.push({ zip: '63593', price: 3000 })
areas.push({ zip: '63594', price: 3000 })
areas.push({ zip: '63595', price: 3000 })
areas.push({ zip: '63596', price: 3000 })
areas.push({ zip: '63597', price: 3000 })
areas.push({ zip: '63598', price: 3000 })
areas.push({ zip: '63599', price: 3000 })
areas.push({ zip: '63600', price: 3000 })
areas.push({ zip: '63601', price: 3000 })
areas.push({ zip: '63602', price: 3000 })
areas.push({ zip: '63603', price: 3000 })
areas.push({ zip: '63604', price: 3000 })
areas.push({ zip: '63605', price: 3000 })
areas.push({ zip: '63606', price: 3000 })
areas.push({ zip: '63607', price: 3000 })
areas.push({ zip: '63608', price: 3000 })
areas.push({ zip: '63609', price: 3000 })
areas.push({ zip: '63610', price: 3000 })
areas.push({ zip: '63611', price: 3000 })
areas.push({ zip: '63612', price: 3000 })
areas.push({ zip: '63613', price: 3000 })
areas.push({ zip: '63614', price: 3000 })
areas.push({ zip: '63615', price: 3000 })
areas.push({ zip: '63616', price: 3000 })
areas.push({ zip: '63617', price: 3000 })
areas.push({ zip: '63618', price: 3000 })
areas.push({ zip: '63619', price: 3000 })
areas.push({ zip: '63620', price: 3000 })
areas.push({ zip: '63621', price: 3000 })
areas.push({ zip: '63622', price: 3000 })
areas.push({ zip: '63623', price: 3000 })
areas.push({ zip: '63624', price: 3000 })
areas.push({ zip: '63625', price: 3000 })
areas.push({ zip: '63626', price: 3000 })
areas.push({ zip: '63627', price: 3000 })
areas.push({ zip: '63628', price: 3000 })
areas.push({ zip: '63629', price: 3000 })
areas.push({ zip: '63630', price: 3000 })
areas.push({ zip: '63631', price: 3000 })
areas.push({ zip: '63632', price: 3000 })
areas.push({ zip: '63633', price: 3000 })
areas.push({ zip: '63634', price: 3000 })
areas.push({ zip: '63635', price: 3000 })
areas.push({ zip: '63636', price: 3000 })
areas.push({ zip: '63637', price: 3000 })
areas.push({ zip: '63638', price: 3000 })
areas.push({ zip: '63639', price: 3000 })
areas.push({ zip: '63640', price: 3000 })
areas.push({ zip: '63641', price: 3000 })
areas.push({ zip: '63642', price: 3000 })
areas.push({ zip: '63643', price: 3000 })
areas.push({ zip: '63644', price: 3000 })

// @ 산간지방 가격불러오기
const getRegionPrice = (zip) => {
  if (!zip) { return 2700 }
  const a = areas.find(area => area.zip === zip)
  return a ? a.price || 2700 : 2700
}

// @ 도서산간지역 해당하는 우편번호 데이터
const regionZips = areas.map(area => area.zip)

// @ 배송업체
const express = {
  lotte: { name: 'lotte', text: '롯데택배' },
  postoffice: { name: 'postoffice', text: '우체국' }
}

export { areas, express, regionZips, getRegionPrice }