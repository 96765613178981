import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import Lounge from 'components/utils/Lounge'
import List from './List'

const constructors = {}

// 작가서비스 기획전 관리
constructors.solutionStoreCorners = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'activated' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-sortOrder'] }
    return watch
  })

  // 사용 기준
  that.filterOptions.status = { name: 'status', text: '진행상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'activated', text: '진행중' })
  that.filterOptions.status.conditions.push({ name: 'deactivated', text: '마감' })

  // 소트 셋팅
  that.sortOptions.sortOrder = { name: 'sortOrder', text: '노출순서', multiple: true, asc: { text: '오름' }, desc: { text: '내림' } }

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.code = { name: 'name', text: '코드명' }
  that.keywordsOptions.name = { name: 'text', text: '기획전명' }
}

class SolutionStoreCornerList extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['solutionStoreCorners']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, items: [], total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  async initialize() {
    await new Promise((r) => this.setState({ loading: true, selecteds: [] }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) {
      return this.setState({ loading: false, error: true })
    }
    await this.loadItems()
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    query.startAt = startAt
    query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')

    query.flags = [].join(',')

    // 쿼리 설정부
    if (this.props.nav === 'solutionStoreCorners') {
      query.status = filters.status
    }
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const output = await api.get(`/resources/admin2/solutionStoreCorners?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return <Lounge /> }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    return <List {...listProps} />
  }
}

SolutionStoreCornerList.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

SolutionStoreCornerList.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '작가서비스 기획전 관리',
  nav: 'solutionStoreCorners',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-10, 'years').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(SolutionStoreCornerList))
