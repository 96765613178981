import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'

import UserDetailWithApprovalColumn from './Columns/UserDetailWithApprovalColumn'
import QuestionDetailColumn from './Columns/QuestionDetailColumn'
import CommentDetailColumn from './Columns/CommentDetailColumn'
import ApprovalDetailColumn from './Columns/ApprovalDetailColumn'
import StatusColumn from './Columns/StatusColumn'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems
}) => {
  const commonProps = { user, location, history, match }

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 선택박스에 대한 설정
  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'QuestionDetailColumn', header: '신고구분', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center' } })
  columns.push({ key: 'UserDetailColumn', header: '등록자', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'CommentDetailColumn', header: '신고내용', style: { minWidth: '768px', maxWidth: '768px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: '0', maxWidth: '100%', justifyContent: 'flex-start' } })
  columns.push({ key: 'ApprovalDetailColumn', header: '제출정보', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'flex-start' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })

  const actions = {}

  // 선택한 문서를 기준으로 처리중을 해주는 기능
  actions.updateStatus = async (status = 'review', items = selecteds) => {
    if (!items.length) { return alert(`체크박스를 통해 대상을 선택해주세요.`) }
    if (!window.confirm(`총 선택된 문서는 ${items.length}건, 진행할까요?`)) { return }
    const comment = window.prompt(`일괄적으로 작성할 문구가 있다면 작성해주세요.`)

    const results = await items
      .reduce((prev, item, itemIdx) => 
        prev.then(async (report) => {
          const data = { comment: comment }
          const result = await api.put(`/approvals/admin2/${item.id}/report/${status}`, data)
            .catch((e) => { return { error: true, message: e.message } })
          report.push(result)
          return report
        }),
        Promise.resolve([]))
        .catch(e => [])

    const successCount = results.filter((result) => { return !result.error }).length
    const failureCount = results.filter((result) => { return result.error }).length

    alert(`처리결과 : ${successCount}건 성공, ${failureCount}건 실패`)

    await handle({ selecteds: [] })
    await initialize().catch(e => null)
  }
  
  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
      </Header>
      <Header>
        <Header.Options>
          <a
            href="#updateStatusReview"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('review')
            }}
          >
            처리중
          </a>
          <a
            href="#updateStatusReject"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('reject')
            }}
          >
            반려처리
          </a>
          <a
            href="#updateStatusAccept"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('accept')
            }}
          >
            처리완료
          </a>
          <a
            href="#updateStatusCancel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('cancel')
            }}
          >
            취소처리
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.bookNo = _.get(item, 'content.book.bookNo')
                    meta.parent = _.get(item, 'parent')
                    meta.isBrunch = _.get(item, 'content.book.brunch')
                    meta.productId = _.get(item, 'content.productId') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'UserDetailColumn':
                              return <UserDetailWithApprovalColumn {...columnProps} />
                            case 'QuestionDetailColumn':
                              return <QuestionDetailColumn {...columnProps} />
                            case 'CommentDetailColumn':
                              return <CommentDetailColumn {...columnProps} />
                            case 'ApprovalDetailColumn':
                              return <ApprovalDetailColumn {...columnProps} />
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  total: 1,
  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
