// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import moment from 'services/moment'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Modal from 'components/utils/Modal'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.5rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

// 알림 생성 모달 : 계정명 기준 생성해주기
class CreateNotificationModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedState = {
      loading: true,
      item: {}
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doCreate = this.doCreate.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const next = { item: {} }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async doCreate() {
    const { onClose } = this.props
    if (!window.confirm(`해당 작성내용을 기준으로 알림을 보내기 할까요?`)) { return }
    const { item } = this.state

    const form = { ...item }
    if (!form.accountId) { return alert(`계정명이 없습니다.`) }

    if (!form.subject) { return alert(`알림을 읽을 내용을 반드시 기재해주세요.`) }
    if (!form.message) { return alert(`알림을 전달할 메시지를 반드시 기재해주세요.`) }
    if (form.priority === '') { form.priority = -1 }
    
    const result = await api.post(`/activities/admin2/notifications`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`) }

    alert(`정상적으로 보냈습니다.`)

    return onClose()
  }
  
  // 랜더링
  render() {
    const { doCreate } = this
    const { onClose } = this.props
    const { loading, item } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // @ 알림 카테고리
    // @ src\api\notification\model.js 에서 복붙
    const categoryTypes = {
      bookSubmit: { name: 'bookSubmit', text: '도서 입점승인' }, // 도서 승인
      solutionSubmit: { name: 'solutionSubmit', text: '작가서비스 입점승인' }, // 작가서비스 승인
      bookRetail: { name: 'bookRetail', text: '판매신청' }, // 도서 외부유통 판매신청
      bookStopRetail: { name: 'bookStopRetail', text: '중지신청' }, // 도서 외부유통 판매중지
      deliveryProcess: { name: 'deliveryProcess', text: '주문발송' }, // 도서 발송준비중, 도서 포장중, 도서 발송완료 3가지
      solutionOrderStatus: { name: 'solutionOrderStatus', text: '작업안내' }, // 작가서비스 상태 변경(승락 또는 거절 상태/최종상태)
      solutionOrderComment: { name: 'solutionOrderComment', text: '작업대화' }, // 작가서비스 주문 댓글
      bookSales: { name: 'bookSales', text: '도서 판매' },
      solutionSales: { name: 'solutionSales', text: '작가서비스 판매' },
      bookReview: { name: 'bookReview', text: '도서 후기' }, // 도서 구매 후기
      solutionReview: { name: 'solutionReview', text: '작가서비스 후기' }, // 작가서비스 구매 후기
      feedComment: { name: 'feedComment', text: '댓글알림' }, // 작가서비스 주문 댓글, 댓댓글 포함
      etc: { name: 'etc', text: '기타' }, // 기타 알림
    }

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>알림 보내기</Form.Header>
          <Form.Body>

            <div className="control">
              <input
                type="text"
                placeholder={`계정명`}
                value={item.accountId || ''}
                onChange={(e) => {
                  const next = { ...item }
                  next.accountId = e.target.value
                  return this.setState({ item: next })
                }}
              />
            </div>

            <div className="control">
              <select
                value={_.get(item, 'category.name') || ''}
                style={{ marginRight: '0.5rem' }}
                onChange={(e) => {
                  const next = { ...item }
                  next.category = { name: e.target.value, text: e.target.text }
                  return this.setState({ item: next })
                }}
              >
                <option value="">알림 종류</option>
                {Object
                  .values(categoryTypes)
                  .map((categoryType, cIdx) => {
                    return <option key={cIdx} value={categoryType.name}>{categoryType.text}</option>
                  })}
              </select>
              <select
                value={`${(item.priority || item.priority === 0) ? item.priority : ''}`}
                onChange={(e) => {
                  const next = { ...item }
                  next.priority = _.isInteger(e.target.value * 1) ? (e.target.value * 1) : -1
                  return this.setState({ item: next })
                }}
              >
                <option value="">중요도</option>
                <option value="-1">일반</option>
                <option value="0">중요</option>
                <option value="1">매우중요</option>
                <option value="2">긴급</option>
              </select>
            </div>
            <div className="control">
              <select
                value={_.get(item, 'usedSms') || ''}
                style={{ marginRight: '0.5rem' }}
                onChange={(e) => {
                  const next = { ...item }
                  next.usedSms = e.target.value
                  return this.setState({ item: next })
                }}
              >
                <option value="">SMS 발송여부</option>
                <option value="used">📱 문자메시지 보내기</option>
                <option value="notUsed">❌ 문자메시지 보내기 안함</option>
              </select>
            </div>

            <div className="control">
              <input
                type="text"
                placeholder={`알림 제목`}
                value={item.subject || ''}
                onChange={(e) => {
                  const next = { ...item }
                  next.subject = e.target.value
                  return this.setState({ item: next })
                }}
              />
            </div>

            <div className="control">
              <textarea
                placeholder={`알림 내용`}
                value={item.message || ''}
                style={{ height: '180px' }}
                onChange={(e) => {
                  const next = { ...item }
                  next.message = e.target.value
                  return this.setState({ item: next })
                }}
              />
            </div>

            <div className="control">
              <input
                type="text"
                placeholder={`이동할 링크 URL`}
                value={item.url || ''}
                onChange={e => {
                  const next = { ...item }
                  next.url = e.target.value ? `${e.target.value}` : ''
                  return this.setState({ item: next })
                }}
                onBlur={e => {
                  const next = { ...item }
                  next.url = item.url ? `${item.url}` : ''
                  return this.setState({ item: next })
                }}
              />
            </div>

            <div className="buttons">
              <button
                type="button"
                className="primary"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doCreate()]
                }}
              >
                반영
              </button>
            </div>
          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default CreateNotificationModalContainer