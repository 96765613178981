import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all; text-align: center;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 주문일자 구성
class DateColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item, columns = [] } = this.props

    const meta = {}
    
    // 결제내역 생성일자
    meta.createdAt = _.get(item, 'createdAt')
    meta.createdMt = meta.createdAt ? moment(meta.createdAt) : null

    // 결제내역 결제일자
    meta.paidAt = _.get(item, 'paidAt')
    meta.paidMt = meta.paidAt ? moment(meta.paidAt) : null

    return (
      <Column>
        {columns.includes('createdAt')
          ? (
            <div style={{ minWidth: '100%', maxWidth: '100%' }}>
              <section>
                <strong>{meta.createdMt ? meta.createdMt.format('YY.MM.DD') : '-'}</strong>
              </section>
              <section>
                <small>{meta.createdMt ? meta.createdMt.format('HH:mm') : '-'}</small>
              </section>
            </div>
          )
          : null}
        {columns.includes('paidAt')
          ? (
            <div style={{ minWidth: '100%', maxWidth: '100%' }}>
              <section>
                <strong>{meta.paidMt ? meta.paidMt.format('YY.MM.DD') : '-'}</strong>
              </section>
              <section>
                <small>{meta.paidMt ? meta.paidMt.format('HH:mm') : '-'}</small>
              </section>
            </div>
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

DateColumnContainer.propTypes = {
  item: PropTypes.object,
}

DateColumnContainer.defaultProps = {
  item: {}
}

export default DateColumnContainer
