import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import moment from 'services/moment'

import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import bookModifyApprovalExportParser from './export.bookModifyApproval' // 외부유통 신청 엑셀다운로드 템플릿

import BookThumbnailColumn from './Columns/BookThumbnailColumn'
import BookDetailWithApprovalColumn from './Columns/BookDetailWithApprovalColumn'
import BookRetailWithApprovalColumn from './Columns/BookRetailWithApprovalColumn'
import UserDetailColumn from './Columns/UserDetailColumn'
import ApprovalDetailColumn from './Columns/ApprovalDetailColumn'
import StatusColumn from './Columns/StatusColumn'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems
}) => {
  const commonProps = { user, location, history, match }

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 선택박스에 대한 설정
  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'BookNoColumn', header: '번호', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center' } })
  columns.push({ key: 'BookThumbnailColumn', header: '썸네일', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookDetailWithApprovalColumn', header: '변경요청 도서', style: { minWidth: '280px', maxWidth: '280px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BookRetailWithApprovalColumn', header: '입점된 위치', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'flex-start' } })
  columns.push({ key: 'UserDetailColumn', header: '요청자', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center' } })
  columns.push({ key: 'ApprovalDetailColumn', header: '제출정보', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 0 auto', minWidth: 0, maxWidth: '100%' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })

  // 선택한 문서를 기준으로 처리중을 해주는 기능
  const actions = {}

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const output = await loadItems(query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const lump = query.limit || limit || 100
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length}`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          await new Promise(r => setTimeout(r, 300))
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 서점정보 변경 신청 또는 중지 관련한 내역을 다운로드 받는 기능
  actions.downloadExcel = async (items = selecteds) => {
    if (!items || items.length === 0) { return alert(`다운로드할 데이터가 없습니다.`) }
    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser: bookModifyApprovalExportParser, items })
      .download(`서점정보 변경요청 총 ${items.length}건 (${curMt.format('YYYY.MM.DD HH시 mm분 ss초')})`)
      .catch(e => {
        alert(`엑셀 파일로 변환하여 다운로드하려던 도중 문제가 발생하였습니다. ${e.message}`)
        console.log(e.message, e.stack)
        return false
      })
  }

  // 상태처리 통합화 작업 @2023.03.11
  actions.updateStatus = async (status = 'review', items = selecteds) => {
    if (!items.length) { return alert(`체크박스를 통해 대상을 선택해주세요.`) }
    if (!window.confirm(`총 선택된 문서는 ${items.length}건, 진행할까요?`)) { return }

    const comment = window.prompt(`일괄적으로 작성할 문구가 있다면 작성해주세요.`)

    const results = await items
      .reduce((prev, item, itemIdx) => 
        prev.then(async (report) => {
          const data = { comment: comment }
          const result = await api.put(`/approvals/admin2/${item.id}/bookModify/${status}`, data)
            .catch((e) => { return { error: true, message: e.message } })
          report.push(result)
          return report
        }),
        Promise.resolve([]))
        .catch(e => [])

    const successCount = results.filter((result) => { return !result.error }).length
    const failureCount = results.filter((result) => { return result.error }).length

    alert(`처리결과 : ${successCount}건 성공, ${failureCount}건 실패`)

    await handle({ selecteds: [] })
    await initialize().catch(e => null)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#downloadExcel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.downloadExcel(selecteds)
            }}
          >
            선택엑셀
          </a>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const targets = await actions
                .getItemsAll({}, { progress: true })
                .catch(e => [])
              return await actions.downloadExcel(targets)
            }}
          >
              전체엑셀
          </a>
        </Header.Options>
      </Header>
      <Header>
        <Header.Options>
          <a
            href="#updateStatusReview"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('review')
            }}
          >
              처리중
          </a>
          <a
            href="#updateStatusReject"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('reject')
            }}
          >
            반려처리
          </a>
          <a
            href="#updateStatusAccept"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateStatus('accept')
            }}
          >
            처리완료
          </a>
          <a
            href="#updateStatusCancel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return actions.updateStatus('cancel')
            }}
          >
            취소처리
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.bookNo = _.get(item, 'content.book.bookNo')
                    meta.parent = _.get(item, 'parent')
                    meta.productId = _.get(item, 'content.productId') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'BookNoColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <a
                                    href={`/products/book/${meta.productId}`}
                                    target="_blank"
                                    onClick={e => {
                                      if (!meta.productId) {
                                        return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
                                      }
                                      return
                                    }}
                                  >
                                    <strong style={{ fontSize: '0.9em' }}>
                                      {meta.bookNo || '미발급'}
                                    </strong>
                                  </a>
                                </div>
                              )
                            case 'BookThumbnailColumn':
                              return <BookThumbnailColumn {...columnProps} />
                            case 'BookDetailWithApprovalColumn':
                              return <BookDetailWithApprovalColumn {...columnProps} />
                            case 'BookRetailWithApprovalColumn':
                              return <BookRetailWithApprovalColumn {...columnProps} />
                            case 'UserDetailColumn':
                              return <UserDetailColumn {...columnProps} />
                            case 'ApprovalDetailColumn':
                              return <ApprovalDetailColumn {...columnProps} />
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  total: 1,
  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
