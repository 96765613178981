import React from 'react'

import api from 'services/api'
import styled from 'styled-components'
import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'

import StartStockStorageModal from '../Modals/StartStockStorageModal'
import StopStockStorageModal from '../Modals/StopStockStorageModal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  background: white;
  border: 1px solid #dedede; border-radius: 0.35rem;
  margin-bottom: 1rem;
`

Column.Lead = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.768rem;
  border-bottom: 1px solid #ededed;
  display: flex; align-items: center; justify-content: space-between;
  & > div.header {
    font-weight: 100; flex: 1 1 100%;
    & strong { font-weight: 900; font-size: 1.3em; }
  }
  & > div.btns {
    & > a { color: #999; text-decoration: none; white-space: nowrap; }
  }
`

Column.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex;
  & a {
    position: relative; box-sizing: border-box;
    display: block; font-size: 0.9em;
    flex: 1 1 100%; text-align: center;
    background: #f1f1f1; padding: 0.75rem 0; color: #111;
    text-decoration: none; border-radius: 0.5rem; margin: 0.5rem;
    &:hover { opacity: 0.8; }
  }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%;
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: flex-start;
  padding: 0.768rem;
`

Meta.Label = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 0.9em; color: #444;
  padding-bottom: 0.35rem;
`

Meta.Value = styled.div`
  position: relative; box-sizing: border-box;
  & small { opacity: 0.8; }
`

function BookStockStorageColumn({ ...props }) {
  const { item = {} } = props

  const [modal, setModal] = React.useState(null)

  // @ 종이도서, 도서전체에 뿌려지는 컴포넌트 임으로, 전자도서에는 해당하지 않도록
  if (!['paperBook'].includes(item.productType)) { return null }

  const book = item.content || {}
  const stock = _.get(item, 'extras.currentStock') || {}
  const stockStorage = _.get(item, 'extras.activatedStockStorage') || {}

  const meta = {}

  // 이용상태
  meta.stage = _.get(stockStorage, 'stage') || 'none' // 사용하지 않는 상태로 기본을 두기
  meta.status = _.get(stockStorage, 'status') || 'deactivated' // 사용하지 않는 상태로 기본을 두기
  meta.activatedAt = _.get(stockStorage, 'activatedAt') || null
  meta.activatedMt = meta.activatedAt ? moment(meta.activatedAt) : null
  meta.deactivatedAt = _.get(stockStorage, 'deactivatedAt') || null
  meta.activatedMt = meta.deactivatedAt ? moment(meta.deactivatedAt) : null

  // 이용기간
  const curAt = new Date(), curMt = moment(curAt)
  meta.firstAt = _.get(stockStorage, 'firstAt')
  meta.firstMt = meta.firstAt ? moment(meta.firstAt) : null
  meta.startAt = _.get(stockStorage, 'startAt')
  meta.startMt = meta.startAt ? moment(meta.startAt) : null
  meta.endAt = _.get(stockStorage, 'endAt')
  meta.endMt = meta.endAt ? moment(meta.endAt) : null
  meta.durationExpired = meta.endMt ? (meta.endMt.toDate() < curAt) : false
  meta.expired = _.get(stockStorage, 'expired') ||  meta.durationExpired || false
  meta.expiredMessage = stockStorage.expiredMessage || ''

  // 자동발주수, 총계약수
  meta.checkmateAmount = _.get(stockStorage, 'checkmate') || 0
  meta.contractAmount = _.get(stockStorage, 'contract') || 0
  meta.instockAmount = _.get(stock, 'instock') || 0

  // 책장번호
  meta.storageNo = _.get(stockStorage, 'storageNo') || 0
  
  // @ 링크 이동을 위한 값 배정
  meta.payUrl = `/pays/etcBookStockStorage?keywords=${item.id}`


  const actions = {}

  // 책장번호 변경 기능
  actions.doUpdateBookStockStorageNo = async () => {
    if (!window.confirm('✅ 책장번호를 변경하시겠나요?')) { return false }
    
    const { item } = props
    
    const stockStorage = _.get(item, 'extras.activatedStockStorage') || {}
    const storageNo = _.get(stockStorage, 'storageNo') || 0

    const value = window.prompt(`📚 책장 중복번호 주의, 0을 입력시, 자동숫자 배정`, storageNo)
    const n = `${value}`.toString().replace(/[^0-9]/ig, '')
    if (!n && ((n * 1) !== 0)) { return alert(`1 이상의 숫자로 입력해주세요.`) }
    
    const result = await api.put(`/products/admin2/${item.id}/updateStockStorageNo`, { no: n })
      .catch((e) => ({ error: false, message: `책장번호를 업데이트 도중 문제가 발생하였습니다. (${e.message})` }))
    
    alert(result.message || '처리 메시지를 전달받지 못했습니다.')
    return window.location.reload()
  }

  // 계약수량 변경 기능
  actions.doUpdateBookStockStorageContract = async () => {
    if (!window.confirm('✅ 수량을 변경하시겠나요? 자동 발주수량도 함께 변경됩니다. 증산이면 반영 후 신규발주 캐시 재생성을 반드시 눌러주세요.')) { return false }
    
    const { item } = props
    
    const stockStorage = _.get(item, 'extras.activatedStockStorage') || {}
    const contract = _.get(stockStorage, 'contract') || 0

    const value = window.prompt(`숫자를 입력해주세요. (짝수여야 합니다.)`, contract)
    const n = `${value}`.toString().replace(/[^0-9]/ig, '') * 1
    if (!n && ((n * 1) !== 0)) { return alert(`1 이상의 숫자로 입력해주세요.`) }
    if (n <= 0) { return alert(`0 이상여야 합니다.`) }
    if (n%2) { return alert(`짝수가 아닙니다.`) }
    
    const result = await api
      .put(`/products/admin2/${item.id}/updateStockStorageContract`, { contract: n })
      .catch((e) => ({ error: false, message: `책장번호를 업데이트 도중 문제가 발생하였습니다. (${e.message})` }))
    
    alert(result.message || '처리 메시지를 전달받지 못했습니다.')
    return window.location.reload()
  }

  // 재고서비스를 강제로 시작하게 해주는 기능
  actions.doForcedStartStockStorage = async () => {
    // @ 이용시작일, 계약수부, 체크메이크 수, 책장번호 입력란 구성
    const modal = { name: 'doForcedStartStockStorage' }
    setModal(modal)
  }

  // 재고서비스를 강제로 종료해주는 기능
  actions.doForcedStopStockStorage = async () => {
    // @ 배송지 입력창을 열어주기
    const modal = { name: 'StopStockStorageModal' }
    setModal(modal)
  }

  return (
    <Column>
        {modal && ['doForcedStartStockStorage'].includes(modal.name) ? <StartStockStorageModal productId={item.id} product={item} isOpen={true} onClose={() => setModal(null)} /> : null}
        {modal && ['StopStockStorageModal'].includes(modal.name) ? <StopStockStorageModal productId={item.id} isOpen={true} onClose={() => setModal(null)} /> : null}

      <Column.Lead>
        <div className="header">
          <span style={{ marginRight: '0.35rem' }}>📚 No.</span>
          <strong>{meta.storageNo || '미배정'}</strong>
        </div>
        <div className="btns">
          <a
            href="#moreStockStoragePays"
            onClick={(e) => {
              return window.open(meta.payUrl)
            }}
          >
            주문기록
          </a>
        </div>
      </Column.Lead>
      <Meta.Group>
        <Meta>
          <Meta.Label>이용상태</Meta.Label>
          {['activated'].includes(meta.status)
            ? (
              <Meta.Value>
                {meta.expired ? <strong>만료/미납 🌧️</strong> : <strong>정상 이용중 🔥</strong>}
                <br/>
                {['none'].includes(meta.stage) ? <small>해당없음</small> : null}
                {['free'].includes(meta.stage) ? <small>무료기간</small> : null}
                {['normal'].includes(meta.stage) ? <small>유료기간</small> : null}
              </Meta.Value>
            )
            : null}
          {['deactivated'].includes(meta.status)
            ? (
              <Meta.Value>
                <strong>비사용중 ☔</strong><br/>
                <small>해당없음</small>
              </Meta.Value>
            )
            : null}
        </Meta>
        <Meta>
          <Meta.Label>이용기간</Meta.Label>
          <Meta.Value>
              {meta.endMt
                ? (<strong>~ {meta.endMt.format('YYYY.MM.DD')} {meta.endMt.format('HH:mm')}</strong>)
                : (<strong>~ 0000.00.00 00:00</strong>)}
              <br />
              {meta.startMt
                ? (<small>{meta.startMt.format('YYYY.MM.DD')} {meta.startMt.format('HH:mm')} ~</small>)
                : (<small>0000.00.00 00:00 ~</small>)}
          </Meta.Value>
        </Meta>
      </Meta.Group>
      <Meta.Group>
        <Meta>
          <Meta.Label>자동/계약</Meta.Label>
          <Meta.Value>
            <div>
              <strong>{meta.checkmateAmount}</strong>
              <small style={{ margin: '0 0.35rem' }}>/</small>
              <strong>{meta.contractAmount}</strong>
            </div>
            <small>
              <strong style={{ marginRight: '0.35rem' }}>{meta.instockAmount}</strong>
              <span>부 보유</span>
            </small>
          </Meta.Value>
        </Meta>
        <Meta>
          <Meta.Label>최초시작기간</Meta.Label>
          {meta.firstMt
            ? (
              <Meta.Value>
                <strong>{meta.firstMt.format('YYYY.MM.DD')}</strong><br/>
                <small>{meta.firstMt.format('HH:mm')}</small>
              </Meta.Value>
            )
            : null}
          {!meta.firstMt
            ? (
              <Meta.Value>
                <strong>0000.00.00</strong><br/>
                <small>00:00</small>
              </Meta.Value>
            )
            : null}
        </Meta>
      </Meta.Group>
      
      {meta.expiredMessage
        ? (
          <div style={{ margin: '0.5rem', padding: '0.75rem' , borderRadius: '0.5rem', background: '#eaeaea'}}>{meta.expiredMessage}</div>
        )
        : null}

      <Column.Buttons>
        <a
          href="#doForcedStopStockStorage"
          onClick={async (e) => {
            return [e.stopPropagation(), e.preventDefault(), await actions.doForcedStartStockStorage()]
          }}
        >
          🟢 시작
        </a>
        <a
          href="#doUpdateBookStockStorageNo"
          onClick={async (e) => {
            return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateBookStockStorageNo()]
          }}
        >
          📚 책장변경
        </a>
        <a
          href="#doUpdateBookStockStorageContract"
          onClick={async (e) => {
            return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateBookStockStorageContract()]
          }}
        >
          🔃물량변경
        </a>
        <a
          href="#doForcedStopStockStorage"
          onClick={async (e) => {
            return [e.stopPropagation(), e.preventDefault(), await actions.doForcedStopStockStorage()]
          }}
        >
          🔴 중단
        </a>
      </Column.Buttons>
    </Column>
  )
}

export default BookStockStorageColumn
