// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import api from 'services/api'
import qs from 'query-string'

import moment from 'services/moment'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'

import Modal from 'components/utils/Modal'
import Pager from 'components/utils/Pager'
import DataSet from 'components/utils/DataSet'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

const controlStyles = css`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0.5rem;

  & div.header {
    position: relative; box-sizing: border-box;
    padding: 1rem;
    & small { color: ${palette('muted', 8)}; }
  }

  & > * { flex: 1 1 100%; }
  & input[type=text],
  & input[type=number],
  & input[type=time],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.5rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & select {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    font-family: ${font('primary')};
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    font-family: ${font('primary')};
    line-height: 1.55rem; border: 1px solid #e1e2e3;
    width: 100%; border: 1px solid #e1e2e3; outline: none;
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    &:hover { background: #fafafa; border: 1px solid #929394 }
  }
  & button {
    position: relative; box-sizing: border-box;
    font-size: 1em; border-radius: 3px;
    font-family: ${font('primary')};
    white-space: nowrap;
    line-height: 1.55rem;
    width: max-content; outline: none;
    padding: 0.35rem 0.75rem; transition: 0.3s all;
    background: #333; border: 1px solid #333; color: white;
    cursor: pointer;
    &:hover { background: #000; border: 1px solid #000; }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
  ${controlStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 100;
  font-size: 1.43em; font-family: ${font('primary')};
  & strong { font-weight: 900; }
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

class StockModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedState = {
      loading: true, error: false, message: '',
      form: { addAmount: 0 },
      stock: {}, product: {}, seller: {},
      count: 0, items: [],
      page: 1, limit: 5, blockLimit: 5, sorts: ['-createdAt']
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.loadItems = this.loadItems.bind(this)
    this.initialize = this.initialize.bind(this)
    this.doAddStockInstockAmount = this.doAddStockInstockAmount.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  // 상품을 기반으로 상품정보, 재고현황, 재고변동기록 3가지를 불러온다.
  async loadItems(_query = {}, options = { returnal: false }) {
    const { page, limit } = this.state
    const { stockId } = this.props

    if (!stockId) {
      const next = {}
      next.error = true
      next.message = '오류가 발생하였습니다.'
      await new Promise((r) => this.setState({ ...next, loading: false }, r))
    }

    const query = { ..._query }
    query.usedCount = typeof _query.usedCount !== 'undefined' ? _query.usedCount : true
    query.page = typeof _query.page !== 'undefined' ? _query.page : page
    query.limit = typeof _query.limit !== 'undefined' ? _query.limit : limit

    const result = await api
      .get(`/stocks/admin2/${stockId}/stockLogsWithStates?${qs.stringify(query)}`)
      .catch((e) => { return { error: true, message: e.message } })
    
    const next = { ...this.state }
    next.form = { addAmount: '' }
    next.stock = result.stock || {}
    next.product = result.product || {}
    next.seller = result.seller || {}

    next.count = result.count || 0
    next.items = result.items || {}

    if (options.returnal) { return next }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async initialize() {
    await new Promise((r) => this.setState({ loading: true }, r))
    await this.loadItems()
  }

  // 재고물량 업데이트 기능 : 음수 또는 양수로 조정이 가능하게 구성한다.
  async doAddStockInstockAmount() {
    const { stockId } = this.props
    const { stock } = this.state

    const instockAmount = this.state.form.addAmount * 1
    if (!instockAmount || _.isNaN(instockAmount)) {
      return alert(`올바른 숫자 기입이 아닙니다.`)
    }

    const form = {}
    form.instock = stock.instock
      ? stock.instock * 1 + instockAmount * 1
      : instockAmount * 1

    form.memo = `재고현황의 재고상세모달에서 관리자가 추가재고분 반영`
    
    const result = await api
      .put(`/stocks/admin2/${stockId}/stockAmounts`, { form })
      .catch(e => { return { error: false, message: '재고량 변동처리 실패' } })

    if (!result || result.error) {
      return alert(
        result && result.message
          ? `${result.message}`
          : `오류가 발생하였습니다.`)
    }

    alert((result && result.message) || `정상적으로 처리하였습니다.`)

    return this.initialize()
  }
  
  // 랜더링
  render() {
    const { onClose } = this.props
    const {
      loading, error, message,
      form,
      stock, product, seller,
      count, items,
      page, limit, blockLimit
    } = this.state
    if (loading) { return null }
    if (error) {
      alert(message)
      return null
    }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // 재고로그 컬럼부 배치
    const columns = {}
    columns.createdAt = { name: '', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', textAlign: 'center' } }
    columns.previousAmount = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.nextAmount = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.modifiedAmount = { name: '', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', textAlign: 'center' } }
    columns.memo = { name: '', style: { flex: '1 1 auto' } }

    const parentMeta = {}
    parentMeta.bookNo = _.get(product, 'content.bookNo') || '도서번호 정보없음'
    parentMeta.bookTitle = _.get(product, 'content.title') || '도서명을 불러오지 못했습니다.'
    parentMeta.purpose = _.get(product, 'content.purpose') || { name: 'error', text: '알수없음'}
    parentMeta.isbn = ['external'].includes(_.get(parentMeta, 'purpose.name'))
      ? _.get(product, 'content.isbn') || ''
      : ''

    parentMeta.instockAmount = stock.instock || 0
    parentMeta.invoiceAmount = stock.invoice || 0
    parentMeta.overflowAmount = stock.overflow || 0
    parentMeta.totalAmount = parentMeta.instockAmount + parentMeta.invoiceAmount + parentMeta.overflowAmount

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '1080px', minWidth: '1080px', width: '1080px' }}>
          <Form.Body>

            <Form.Header style={{ marginBottom: '1rem' }}>
              <div style={{ padding: '1.5rem 0' }}>
                <small style={{ marginRight: '0.5rem' }}>{parentMeta.bookNo}</small>
                <small style={{ marginRight: '0.5rem' }}>
                  <strong>{_.get(parentMeta, 'purpose.text') || '용도 알수없음'}</strong>
                </small>
                {parentMeta.isbn ? <small>{parentMeta.isbn}</small> : null}
              </div>
              <div>
                <strong style={{ fontSize: '1.4em' }}>{parentMeta.bookTitle}</strong>
              </div>
            </Form.Header>

            <div
              style={{
                display: 'flex', padding: '0 0.5rem',
                justifyContent: 'space-between', alignItems: 'middle'
              }}>
              <div style={{ display: 'flex', whiteSpace: 'nowrap', flex: '1 1 100%' }}>

                <div style={{ marginRight: '0.75rem' }}>
                  <span style={{ marginRight: '0.5rem' }}>현재 보유재고</span>
                  <strong style={{ fontSize: '1.2em', fontWeight: 900, marginRight: '0.25rem', textDecoration: 'underline' }}>
                    {comma(parentMeta.instockAmount)}
                  </strong>
                  <span>건</span>
                </div>

                <div style={{ marginRight: '0.75rem' }}>
                  <span style={{ marginRight: '0.5rem' }}>필요입고</span>
                  <strong style={{ fontSize: '1.2em', fontWeight: 900, marginRight: '0.25rem', textDecoration: 'underline' }}>
                    {comma(parentMeta.invoiceAmount)}
                  </strong>
                  <span>건</span>
                </div>

                <div style={{ marginRight: '0.75rem' }}>
                  <span style={{ marginRight: '0.5rem' }}>추가입고</span>
                  <strong style={{ fontSize: '1.2em', fontWeight: 900, marginRight: '0.25rem', textDecoration: 'underline' }}>
                    {comma(parentMeta.overflowAmount)}
                  </strong>
                  <span>건</span>
                </div>

                <div style={{ marginRight: '0.75rem' }}>
                  <span style={{ marginRight: '0.5rem' }}>총 보유</span>
                  <strong style={{ fontSize: '1.2em', fontWeight: 900, marginRight: '0.25rem', textDecoration: 'underline' }}>
                    {comma(parentMeta.totalAmount)}
                  </strong>
                  <span>건</span>
                </div>

              </div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '200px', maxWidth: '200px' }}
              >
                <input
                  type="text"
                  placeholder={`추가재고량 입력`}
                  value={form.addAmount || ''}
                  style={{ marginRight: '0.5rem' , flex: '1 1 100%', width: 0, minWidth: 0, maxWidth: '100%'}}
                  onChange={(e) => {
                    const { form } = this.state
                    const next = { ...form }
                    next.addAmount = e.target.value
                    return this.setState({ form: next })
                  }}
                  onBlur={(e) => {
                    const { form } = this.state

                    const next = { ...form }
                    next.addAmount = !_.isNaN(next.addAmount * 1)
                      ? next.addAmount * 1
                      : 0
                    next.addAmount = Math.ceil(next.addAmount) // 강제올림 처리

                    return this.setState({ form: next })
                  }}
                />
                <button
                  type="button"
                  className="primary"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    return await this.doAddStockInstockAmount()
                  }}
                >
                  반영
                </button>
              </div>
            </div>

            <div style={{ padding: '0.5rem' }}>
              <DataSet>
                <DataSet.Header>
                  <DataSet.Item>
                    <DataSet.Col style={columns.createdAt.style}>날짜</DataSet.Col>
                    <DataSet.Col style={columns.previousAmount.style}>변경 전</DataSet.Col>
                    <DataSet.Col style={columns.nextAmount.style}>변경 후</DataSet.Col>
                    <DataSet.Col style={columns.modifiedAmount.style}>변동량</DataSet.Col>
                    <DataSet.Col style={columns.memo.style}>변경 사유</DataSet.Col>
                  </DataSet.Item>
                </DataSet.Header>

                {items.length
                  ? (
                    <DataSet.Body>
                      {items.map((item, itemIdx) => {
                        const meta = {}
                        meta.createdMt = _.get(item, 'createdAt')
                          ? moment(_.get(item, 'createdAt'))
                          : null
                        meta.previousAmount = item.previousAmount
                        meta.nextAmount = item.nextAmount
                        meta.modifiedAmount = meta.nextAmount - meta.previousAmount
                        meta.memo = item.memo || ''

                        return (
                          <DataSet.Item key={itemIdx}>
                            <DataSet.Col style={columns.createdAt.style}>
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  <strong>{meta.createdMt
                                    ? meta.createdMt.format('YYYY년 MM월 DD일')
                                    : '0000년 00월 00일'}
                                  </strong><br />
                                  <small>
                                    {meta.createdMt
                                      ? meta.createdMt.format('HH시 mm분')
                                      : '00시 00분'}
                                  </small>
                                </div>
                            </DataSet.Col>
                            <DataSet.Col style={columns.previousAmount.style}>
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  <strong style={{ fontSize: '1.4em', fontWeight: '900', marginRight: '0.35rem' }}>
                                    {comma(meta.previousAmount)}
                                  </strong>
                                  <small>개</small>
                                </div>
                            </DataSet.Col>
                            <DataSet.Col style={columns.nextAmount.style}>
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  <strong style={{ fontSize: '1.4em', fontWeight: '900', marginRight: '0.35rem' }}>
                                    {comma(meta.nextAmount)}
                                  </strong>
                                  <small>개</small>
                                </div>
                            </DataSet.Col>
                            <DataSet.Col style={columns.modifiedAmount.style}>
                                {meta.modifiedAmount >= 0
                                  ? (
                                    <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                      {meta.modifiedAmount === 0
                                        ? (<strong style={{ fontSize: '1.4em', fontWeight: '900', marginRight: '0.35rem' }}>변동없음</strong>)
                                        : null}
                                      {meta.modifiedAmount > 0
                                        ? (<strong style={{ fontSize: '1.4em', fontWeight: '900', marginRight: '0.35rem' }}>+{comma(meta.modifiedAmount)}</strong>)
                                        : null}
                                      <small>개</small>
                                    </div>
                                  )
                                  : null}
                                {meta.modifiedAmount < 0
                                  ? (
                                    <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                      <strong style={{ fontSize: '1.4em', fontWeight: '900', marginRight: '0.35rem', color: 'red' }}>
                                        {comma(meta.modifiedAmount)}
                                      </strong>
                                      <small>개</small>
                                    </div>
                                  )
                                  : null}
                              </DataSet.Col>
                            <DataSet.Col style={columns.memo.style}>
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  {meta.memo}
                                </div>
                            </DataSet.Col>
                          </DataSet.Item>
                        )
                      })}
                    </DataSet.Body>
                  )
                  : null}
                
                {!items.length
                  ? (
                    <DataSet.NotFound>
                      <header>🏖️ 해당 재고내역은 아직 변동기록이 존재하지 않습니다.</header>
                      <section>발주가 없었거나, 주문되지 않아 변동기록이 존재하지 않습니다.</section>
                    </DataSet.NotFound>
                  )
                  : null}
              </DataSet>

              <Pager
                total={count} page={page} limit={limit} blockLimit={blockLimit}
                setPage={page => this.setState({ page }, () => this.initialize())}
              />
            </div>

          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default StockModalContainer