import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.sheetProps = {
  pageSetup: {
    paperSize: 9,
    orientation: 'portrait',
    fitToPage: true,
    margins: { top: 0, right: 0, bottom: 0, left: 0, header: 0, footer: 0 },
  },
}

// 마지막으로 꾸며주기
parser.willWriteWorksheet = async (worksheet) => {
  const curAt = new Date(), curMt = moment(curAt)
  
  worksheet.spliceRows(1, 1)
  
  worksheet.getCell('A1').font = { size: 20, bold: true }
  worksheet.getCell('A1').alignment = { horizontal: 'left', vertical: 'middle' }
  worksheet.getCell('A1').value = `납본명세서 - 부크크 (${curMt.format(`YYYY-MM-DD`)})`
  worksheet.mergeCells('A1:J1 ')

  worksheet.getCell('A2').value = '번호'
  worksheet.getCell('A2').font = { bold: true }
  worksheet.getCell('A2').alignment = { horizontal: 'center', vertical: 'middle' }
  
  worksheet.getCell('B2').value = 'ISBN'
  worksheet.getCell('B2').font = { bold: true }
  worksheet.getCell('B2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('C2').value = '자료명'
  worksheet.getCell('C2').font = { bold: true }
  worksheet.getCell('C2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('D2').value = '저작자명'
  worksheet.getCell('D2').font = { bold: true }
  worksheet.getCell('D2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('E2').value = '페이지수'
  worksheet.getCell('E2').font = { bold: true }
  worksheet.getCell('E2').alignment = { horizontal: 'center', vertical: 'middle' }
  
  worksheet.getCell('F2').value = '발행처'
  worksheet.getCell('F2').font = { bold: true }
  worksheet.getCell('F2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('G2').value = '발행일'
  worksheet.getCell('G2').font = { bold: true }
  worksheet.getCell('G2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('H2').value = '자료형태'
  worksheet.getCell('H2').font = { bold: true }
  worksheet.getCell('H2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('I2').value = '시가'
  worksheet.getCell('I2').font = { bold: true }
  worksheet.getCell('I2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('J2').value = '수량'
  worksheet.getCell('J2').font = { bold: true }
  worksheet.getCell('J2').alignment = { horizontal: 'center', vertical: 'middle' }

  worksheet.getCell('K2').value = '보상가'
  worksheet.getCell('K2').font = { bold: true }
  worksheet.getCell('K2').alignment = { horizontal: 'center', vertical: 'middle' }
  
  const lastRowIdx = worksheet.rowCount
  worksheet.getRow(lastRowIdx).font = { bold: true }
  worksheet.getRow(lastRowIdx).alignment = { vertical: 'middle' }
}

const columns = [
  {
    header:  `번호`, key: 'no',
    columnProps: { width: 12 },
    view: (item, idx) => `${item.no}`,
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  'ISBN', key: 'bookIsbn',
    columnProps: { width: 15 },
    view: (item) => {
      return _.get(item, 'bookIsbn') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '자료명', key: 'bookName',
    columnProps: { width: 54 },
    view: (item) => {
      return _.get(item, 'bookName') || ''
    },
    viewProps: { alignment: { horizontal: 'left', vertical: 'middle' } },
  },
  {
    header:  '저작자명', key: 'bookAuthor',
    columnProps: { width: 20 },
    view: (item) => {
      return _.get(item, 'bookAuthor') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '페이지수', key: 'bookTextPageCount',
    columnProps: { width: 20 },
    view: (item) => {
      return _.get(item, 'bookTextPageCount') || 0
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '발행처', key: 'publisher',
    columnProps: { width: 15 },
    view: (item) => {
      return _.get(item, 'publisher') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '발행일', key: 'publishedAt',
    columnProps: { width: 15 },
    view: (item) => {
      return _.get(item, 'publishedAt') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '자료형태', key: 'bookType',
    columnProps: { width: 15 },
    view: (item) => {
      return _.get(item, 'bookType') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '시가', key: 'eachPrice',
    columnProps: { width: 20 },
    view: (item) => {
      return _.get(item, 'eachPrice') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '수량', key: 'amount',
    columnProps: { width: 20 },
    view: (item) => {
      return _.get(item, 'amount') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
  },
  {
    header:  '보상가', key: 'totalValue',
    columnProps: { width: 20 },
    view: (item) => {
      return _.get(item, 'totalValue') || ''
    },
    viewProps: { alignment: { horizontal: 'center', vertical: 'middle' } },
   }
]

parser.columns = columns

export default parser
