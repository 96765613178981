import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'
import backdoor from 'services/backdoor'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 유저정보
class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const extras = item.extras || {}
    const currentUser = extras.user || {}

    // 계정명, 이메일, 연락처
    const meta = {}
    meta.accountId = currentUser.accountId || '-'
    meta.userNo = currentUser.userNo || '0'
    meta.email = currentUser.email || '-'

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
            <section>
              <strong>{meta.accountId}</strong>
            </section>
            <section>
              <small>{meta.email}</small>
            </section>
            <a
              href="#login"
              style={{
                display: 'block', textDecoration: 'none', margin: '0.5rem 0', color: '#333',
                fontSize: '0.9em', padding: '0.35rem 0.65rem', borderRadius: '0.5rem', background: '#eaeaea'
              }}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return await backdoor.login(item.user, `/author/profits/withdraw`)
              }}
            >
              🔐 로그인
            </a>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
