import moment from 'services/moment'

const parser = {}

parser.columns = [
  { header: '주문번호', key: 'id', view: (item) => item.id },
  { header: '주문일', key: 'createdAt', view: (item) => moment(item.createdAt).format('LLL') },
  { header: '업데이트일', key: 'updatedAt', view: (item) => moment(item.updatedAt).format('LLL') },
]

export default parser
