import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'services/moment'
import api from 'services/api'
import styled from 'styled-components'
import { font, palette } from 'styled-theme'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class BookSubmitPayColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}
  }

  componentDidMount() {
  }
  
  render() {
    const { item = {} } = this.props
    const extras = _.get(item, 'extras') || {}

    const meta = {}

    meta.user = _.get(extras, 'currentUser') || {}

    // 종이도서 + 흑백도서 + 정가인하 기능 사용 = 가격변경비가 필요한 도서
    meta.requiredBookSubmitPay = ![
      ['paperBook'].includes(_.get(item, 'type')),
      ['black'].includes(_.get(item, 'text.color.name')),
      _.get(item, 'usedDiscount')
    ].includes(false)

    if (!meta.requiredBookSubmitPay) {
      return (
        <Column>
          <div style={{ textAlign: 'center' }}>
            미해당
          </div>
        </Column>
      )
    }

    meta.bookSubmitOrder = _.get(extras, 'lastBookSubmitOrder') || {}
    meta.bookSubmitPay = _.get(extras, 'lastBookSubmitPay') || {}

    // 가격변경비 결제상태에 따른 분류
    meta.bookSubmitPayStatus = { name: 'required', text: '결제필요', emoji: '🔴' }    
    if (_.get(meta, 'bookSubmitPay.status')) {
      if (['wait'].includes(_.get(meta, 'bookSubmitPay.status'))) {
        meta.bookSubmitPayStatus = { name: 'wait', text: '결제대기', emoji: '🟡' }
      }
      if (['finished'].includes(_.get(meta, 'bookSubmitPay.status'))) {
        meta.bookSubmitPayStatus = { name: 'finished', text: '결제완료', emoji: '🟢' }
      }
      if (['cancel'].includes(_.get(meta, 'bookSubmitPay.status'))) {
        meta.bookSubmitPayStatus = { name: 'cancel', text: '결제취소', emoji: '⚫' }
      }
    }

    meta.paidMt = _.get(meta, 'bookSubmitPay.paidAt')
      ? moment(_.get(meta, 'bookSubmitPay.paidAt'))
      : null

    return (
      <Column>

        <Meta.Group>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>결제상태</small>
            </header>
            <section>
              <strong
                onClick={(e) => {
                  const payType = _.get(meta, 'bookSubmitPay.payType')
                  const payId = _.get(meta, 'bookSubmitPay.id')
                  if (!payId) { return [e.stopPropagation(), e.preventDefault(), alert(`Pay 번호가 발급되지 않은 상태단계입니다.`)] }
                  const url = `/pays/${payType}/${payId}`
                  return [e.stopPropagation(), e.preventDefault(), window.open(url)]
                }}
                title={`${_.get(meta, 'bookSubmitPay.id') || '미생성'}`}
                style={{ cursor: 'pointer' }}
              >
                {_.get(meta, 'bookSubmitPayStatus.emoji')} {_.get(meta, 'bookSubmitPayStatus.text')}
              </strong>
            </section>
          </Meta>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>결제일자</small>
            </header>
            <section>
              <strong>{meta.paidMt ? meta.paidMt.format('YYYY.MM.DD') : '0000.00.00'}</strong><br />
              <small>{meta.paidMt ? meta.paidMt.format('HH:mm') : '00:00'}</small>
            </section>
          </Meta>
        </Meta.Group>

      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookSubmitPayColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookSubmitPayColumnContainer.defaultProps = {
  item: {}
}

export default BookSubmitPayColumnContainer
