import React from 'react'
import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Wrapper = styled.div`
  position: fixed; box-sizing: border-box;
  display: block; left: 0; top: 0; width: 100%; height: 100%;
  background: rgba(1, 1, 1, 0.5); z-index: 9999;
`

const Box = styled.div`
  position: relative; line-height: 1.62em;
  margin: 10rem auto; max-width: 480px;
  padding: 1.5rem; border-radius: 0.35rem;
  background: white; text-align: center;
`

const Progress = styled.div`
  position: relative;
  width: 100%; border-radius: 1rem;
  height: 0.5rem;
  background: rgba(1, 1, 1, 0.1);
`

Progress.Bar = styled.div`
  position: relative; border-radius: 1rem;
  height: 100%; overflow: hidden; transition: all 0.3s;
  background: #111;
  
`

// 백분률로 불러와줘야한다.
const ProgressModal = ({ message = '처리중입니다. 잠시만 기다려주세요.', rate = 0, onClose = () => {} }) => {
  return (
    <Wrapper onClick={onClose}>
      <Box onClick={e => [e.stopPropagation()]}>
        <div style={{ padding: '1rem 0' }}>{message}</div>
        {rate ? <Progress><Progress.Bar style={{ width: `${rate}%` }} /></Progress> : null}
      </Box>
    </Wrapper>
  )
}

export default ProgressModal
