import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import _ from 'lodash'
import api from 'services/api'

import Form from './Form'

class UserFormContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItem = this.loadItem.bind(this)
    this.handle = this.handle.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this._mounted = true
    this.initialize()
  }

  componentDidUpdate(prevProps) {
    const curProps = this.props
    if (JSON.stringify(curProps) !== JSON.stringify(prevProps)) {
      this.setState(this.initialState(curProps), () => this.initialize())
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  initialState(props) {
    const state = {
      loading: true, error: false,
      item: { id: props.itemId }
    }
    return state
  }

  async initialize() {
    await this.loadItem()
  }

  async loadItem() {
    const { id } = this.state.item
    if (!id) { return Promise.resolve({ loading: false, error: true, item: { id: null } }) }
    return await api.get(`/users/admin/${id}`)
      .then((item) => this.setState({ loading: false, item }))
      .catch(() => this.setState({ loading: false, error: true, item: { id: null } }))
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  async handleSave(data = null, cb = () => { }) {
    const { item } = this.state
    return api.put(`/users/admin/${item.id}`, { form: data || item })
      .then(next => {
        this.initialize()
        return this.setState({ item: next }, () => cb(null, next))
      })
      .catch(error => {
        alert(`에러가 발생하였습니다. (${error.message})`)
        return cb(error, item)
      })
  }

  render() {
    const { error, loading } = this.state
    if (loading) { return null }
    if (error) { return <div style={{ padding: '1rem' }}>오류가 발생하였습니다.</div> }

    const { admin, location, history, match } = this.props
    const { initialize, handle, handleSave } = this
    const { item } = this.state

    const commonProps = { admin, location, history, match }
    const formProps = { ...commonProps, item, initialize, handle, handleSave }

    return (
      <>
        <Helmet>
          <title>{item.emoji ? `${item.emoji}` : ['man'].includes(item.gender) ? `🧔` : `👩`} ({item.userNo.toString()}) {item.accountId || 'unknown'} 계정 - BOOKK</title>
        </Helmet>
        <Form {...formProps} />
      </>
    )
  }
}

UserFormContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  itemId: PropTypes.string
}

UserFormContainer.defaultProps = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  itemId: null
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default connect(mapStateToProps, null)(withRouter(UserFormContainer))
