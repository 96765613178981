const theme = {
  fonts: {
    primary: "'Noto Sans KR', '맑은 고딕', sans-serif",
    static: "'Noto Serif KR', '맑은 고딕', sans-serif",
    normal: "'Nanum Gothic', '맑은 고딕', sans-serif",
  },
  palette: {
    primary: ["#001016", "#001f2b", "#002e40", "#003e55", "#004d6a", "#005c7f", "#006b94", "#007ba9", "#008abe", "#0099d3", "#00a8e8", "#17afea", "#2eb7ec", "#45bfee", "#5cc7f0", "#73cff2", "#8bd7f4", "#a2dff6", "#b9e7f8", "#d0effa", "#e7f7fc","#ffffff"],
    danger: ["#120204", "#240407", "#36070B", "#49090E", "#5B0B12", "#6D0D15", "#7F1019", "#91121D", "#A31420", "#B61624", "#C81927", "#DA1B2B", "#E42535", "#E63946", "#E94957", "#EB5C68", "#ED6E78", "#EF8089", "#F2929A", "#F4A4AB", "#F6B6BC", "#F8C9CD", "#FBDBDD", "#FDEDEE", "#FFFFFF"],
    darkblue: ["#000303", "#000506", "#000709", "#00090c", "#000b0f", "#000d11", "#000f14", "#001117", "#00131a", "#00151d", "#00171f", "#172c33", "#2e4147", "#45565c", "#5c6b70", "#738084", "#8b9599", "#a2aaad", "#b9bfc1", "#d0d4d6", "#e7e9ea","#ffffff"],
    muted: ["#131313", "#262626", "#383838", "#4b4b4b", "#5d5d5d", "#707070", "#828282", "#959595", "#a7a7a7", "#bababa", "#cccccc", "#d0d0d0", "#d5d5d5", "#d9d9d9", "#dedede", "#e3e3e3", "#e7e7e7", "#ececec", "#f1f1f1", "#f5f5f5", "#fafafa","#ffffff"],
    warn: ['#c0392b'],
    orange: ['#d35400', '#e67e22'],
    green: ['#27ae60', '#2ecc71']
  }
}

export default theme
