// 수동모달
import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'

import moment from 'services/moment'

import Modal from 'components/utils/Modal'
import Lnr from 'components/utils/Lnr'

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 640px; min-width: 780px; width: 780px;
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  min-width: 280px; max-width: 280px;
  & > div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem;
    & > input {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 0.35rem;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
  }
  & > div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.5rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 0.35rem;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0 1rem; flex: 1 1 auto;
`

const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  border-radius: 0.35rem; border: 1px solid #e1e2e3; cursor: pointer;
  margin: 0.35rem 0; padding: 0.5rem 1rem;
  background: white;
  
  & > div.meta {
    position: relative; box-sizing: border-box;
    flex: 1 1 auto; line-height: 1.5rem;
    & details { outline: none; }
    & small { opacity: 0.8; }
  }
  & > div.btns {
    position: relative; box-sizing: border-box;
    flex: 1 1 auto; max-width: min-content;
    & > a {
      white-space: nowrap; text-decoration: none;
      font-size: 0.75em; color: #929394; transition: 0.3s all;
      border-radius: 0.35rem; border: 1px solid transparent;
      display: inline-block; padding: 0.25rem 0.5rem;
      background: #eee;
      &:hover { color: #232425; border: 1px solid #e1e2e3; }
    }
  }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 상위 컴포넌트가 생명주기를 관장하는 수동적 모달
class BookFileChangeDayModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedSetup = { days: [] }
    
    this.initializedState = {
      loading: true,
      setup: JSON.parse(JSON.stringify(this.initializedSetup)),
      item: {}
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.loadSetup = this.loadSetup.bind(this)
    this.createItem = this.createItem.bind(this)
    this.removeItem = this.removeItem.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const setup = await this.loadSetup()
    await new Promise((r) => this.setState({ loading: false, setup }, r))
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/bookFileChangeDays.json)
  async loadSetup() {
    return await api.get(`/approvals/admin2/bookFileChangeDays`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return JSON.parse(JSON.stringify(this.initializedSetup)) }
        return data
      })
      .catch(e => JSON.parse(JSON.stringify(this.initializedSetup)))
  }

  // 개별개체를 추가하는 기능 : 추가할 때 반영하고 나서 반드시 정렬해줘야 한다.
  // { at: new Date() 형식, message: '' }
  async createItem(item) {
    if (!item.at) { return alert(`반드시 일자와 요일을 지정해야합니다.`) }
    if (!window.confirm(`추가하더라도 반드시 적용 버튼을 눌러야 반영됩니다. 추가 할까요?`)) { return }
    const next = _.get(this, 'state.setup') || JSON.parse(JSON.stringify(this.initializedSetup))
    next.days.push(item)
    next.days.sort((a, b) => _.get(a, 'at') < _.get(b, 'at') ? -1 : 1)
    return await new Promise((r) => this.setState({ setup: next }, r))
  }

  // 요일을 삭제하는 기능 : 해당 번호 기준으로만 삭제 한다.
  async removeItem(idx) {
    if (!window.confirm(`제거하더라도 반드시 적용 버튼을 눌러야 반영됩니다. 제거 할까요?`)) { return }
    const next = _.get(this, 'state.setup') || JSON.parse(JSON.stringify(this.initializedSetup))
    next.days = next.days.filter((day, dIdx) => dIdx !== idx)
    return await new Promise((r) => this.setState({ setup: next }, r))
  }

  // 서버에 저장하는 기능 : 서버에 원고수정일자를 기록하는 리소스파일을 생성한다. (api 서버의 resources/bookFileChangeDays.json)
  async saveSetup(setup) {
    if (!window.confirm(`저장하게되면 원고수정일 신청이 일반 사용자 화면에 적용됩니다. 진행할까요?`)) { return }
    const dayCount = _.get(setup, 'days.length')
    if (!dayCount) { return alert(`반드시 원고 수정일 날짜를 지정해야만 저장됩니다. (최소 1개)`) }
    const payload = {}
    payload.setup = setup ? setup : _.get(this, 'state.setup')
    return await api.post(`/approvals/admin2/bookFileChangeDays`, payload)
      .then((res) => {
        if (res.error) { return alert(`에러가 발생하였습니다. (${res.message})`) }
        return alert(`성공적으로 업데이트 하였습니다.`)
      })
      .catch(e => {
        return alert(`에러가 발생하였습니다. ${e.message}`)
      })
  }
  

  render() {
    const { onClose } = this.props
    const { loading, setup, item } = this.state
    if (loading) { return null }

    const daysAlias = ['일','월','화','수','목','금','토']
    const days = _.get(setup, 'days') || []

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form>
          <Form.Header>파일교체일 및 작업공지 설정</Form.Header>
          <Form.Grid>
            <Form.Content>
              <Item.Group>
                {(!days || !days.length)
                  ? (
                    <Item>
                        <div className="meta">
                          우측 파일교체일 추가 기능<br />
                          통해서 추가해주세요.
                        </div>
                      </Item>
                    )
                  : null}
                {days.map((day, dayIdx) => {
                  const meta = {}
                  meta.at = _.get(day, 'at')
                  meta.message = _.get(day, 'message') || `별도 공지사항을 작성하지 않았습니다.`

                  meta.mt = meta.at ? moment(meta.at) : null
                  meta.ftD = meta.mt ? meta.mt.format('YYYY.MM.DD') : '0000.00.00'
                  meta.ftT = meta.mt ? meta.mt.format('HH:mm') : '00:00'
                  meta.ftA = meta.mt ? (daysAlias[meta.mt.day()] || '미') : '미'

                  return (
                    <Item key={`day_${dayIdx}`}>
                      <div className="meta">
                        <details>
                          <summary>
                            <strong style={{ marginRight: '0.35rem' }}>{meta.ftA}요일</strong>
                            <strong style={{ marginRight: '0.35rem' }}>{meta.ftD}</strong>
                            <small style={{ marginRight: '0.25rem' }}>{meta.ftT} 까지</small>
                          </summary>
                          <div style={{ padding: '0.5rem 0' }}>
                            <small>{meta.message}</small>
                          </div>
                        </details>
                      </div>
                      <div className="btns">
                        <a
                          href="#removeItem"
                          onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await this.removeItem(dayIdx)]}
                        >
                          <Lnr c="cross" />
                        </a>
                      </div>
                    </Item>
                  )
                })}
              </Item.Group>
            </Form.Content>
            <Form.Aside>
              <div className="control">
                <input
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  value={item.at ? moment(item.at).format('YYYY-MM-DD') : ''}
                  onChange={(e) => {
                    const curAt = new Date()
                    const prevAt = item.at
                      ? new Date(item.at)
                      : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                    const prevTime = [prevAt.getHours(), prevAt.getMinutes(), prevAt.getSeconds()]
                    const value = e.target.value || '0000-00-00'
                    const [year, month, day] = value.split('-')
                    const at = new Date(year, month * 1 ? (month * 1 - 1) : null, day, ...prevTime)
                    const next = { ...item, at: at.toJSON() }
                    return this.setState({ item: next })
                  }}
                />
              </div>
              <div className="control">
                <input
                  type="time"
                  pattern="\d{2}:\d{2}:\d{2}"
                  value={item.at ? moment(item.at).format('HH:mm:ss') : ''}
                  onChange={(e) => {
                    const curAt = new Date()
                    const prevAt = item.at
                      ? new Date(item.at)
                      : new Date(curAt.getFullYear(), curAt.getMonth(), curAt.getDate(), 12, 0, 0, 0)
                    const prevDay = [prevAt.getFullYear(), prevAt.getMonth(), prevAt.getDate()]
                    const value = e.target.value || '00:00:00'
                    const at = new Date(...prevDay, ...value.split(':'))
                    const next = { ...item, at: at.toJSON() }
                    return this.setState({ item: next })
                  }}
                />
              </div>
              <div className="control">
                <textarea
                  placeholder="파일교체일이 특정 연휴 또는 단축업무 등에 대한 문구를 적어주세요."
                  style={{ height: '90px' }}
                  value={item.message}
                  onChange={e => {
                    const next = { ...item, message: e.target.value || '' }
                    return this.setState({ item: next })
                  }}
                />
              </div>
              <div className="buttons">
                <button
                  type="button"
                  onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await this.createItem(_.get(this, 'state.item'))]}
                >
                  추가
                </button>
                <button
                  type="button"
                  className="primary"
                  onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await this.saveSetup(_.get(this, 'state.setup'))]}
                >
                  적용
                </button>
              </div>
            </Form.Aside>
          </Form.Grid>
        </Form>
      </Modal>
    )
  }
}

export default BookFileChangeDayModalContainer