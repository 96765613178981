import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
export { Lnr }

export const Box = styled.div`
  position: relative; box-sizing: border-box;
  margin: -0.25rem 0; padding: 0 0.25rem; margin-top: 0;
  display: flex; align-items: center; 
  & > select {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.25rem; padding-right: 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 5)}; margin: 0 0.25rem; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    & :last-child { margin-left: 0.25rem; }
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }
`
