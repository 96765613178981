import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { comma } from 'services/utils'

const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  padding: 0.75rem; color: ${palette('muted', 5)};
  background: white; border-radius: 3px;
  border: 1px solid ${palette('muted', 12)};
  margin: 0.75rem 0;
`

Item.Col = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem; color: ${palette('muted', 5)};
  & strong { color: ${palette('muted', 1)}; }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

Item.NotFound = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 1.5rem; 
  border: 1px solid ${palette('muted', 12)};
  background: white; border-radius: 3px;
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1rem 0; font-weight: 700; color: ${palette('muted', 2)};
  }
  & > section {
    position: relative; box-sizing: border-box;
    font-size: 0.8em;
    padding: 1rem 0; color: ${palette('muted', 4)};
  }
`

// 메타정보
const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em; color: ${palette('muted', 4)};
    padding: 0.15rem 0;
  }
  & > section {
    position: relative; box-sizing: border-box;
    & small { color: ${palette('muted', 7)}; }
    padding: 0.15rem;
  }
  & > div.extra {
    position: relative; box-sizing: border-box;
    padding: 0.15rem;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 하위 개체 컴포넌트를 랩핑한다.
const Form = styled.article`
  position: relative; box-sizing: border-box;
`

const controlStyles = css`
  & input[type=text],
  & input[type=number],
  & input[type=date],
  & input[type=time] {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & select {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem 0;
  display: flex; align-items: center;
  & > * { width: 100%; flex: 1 1 100%; min-width: 0; }
  & > div.label { font-size: 0.9em; }
  ${controlStyles}
`

// 입력상자 디자인
Form.Control = styled.div`
  position: relative; box-sizing: border-box;
`

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    const { parentProps = {} } = this.props

    // 환불 타입
    const refundStatusTypes = {}
    refundStatusTypes.none = { name: 'none', text: '해당없음' }
    refundStatusTypes.wait = { name: 'wait', text: '환불신청중' }
    refundStatusTypes.review = { name: 'review', text: '환불 처리중' }
    refundStatusTypes.reject = { name: 'reject', text: '환불거절' }
    refundStatusTypes.accept = { name: 'accept', text: '환불완료' }
    refundStatusTypes.cancel = { name: 'cancel', text: '환불취소' }

    const items = this.props.items || []
    
    if (!items || !items.length) {
      return (
        <Item.NotFound>
          <header>주문서가 존재하지 않습니다. 🌧️</header>
          <section>
            주문서가 이미 삭제되었거나, 존재하지 않은채로<br/>
            결제내역이 생성되었습니다.
          </section>
        </Item.NotFound>
      )
    }

    return (
      <Item.Group>
        {items.map((item, idx) => {
          const extras = item.extras || {}
          
          const meta ={}
          meta.idx = idx + 1
          meta.orderId = item.id

          // 구매자와 제공자 정보를 기재한다.
          meta.user = _.get(item, 'extras.user') || {}
          meta.seller = _.get(item, 'extras.seller') || {}

          // 구매한 상품정보와 주문내역을 불러온다.
          meta.subject = item.subject || '상품명 없음'
          meta.amount = item.amount || 0
          meta.eachPrice = item.eachPrice || 0
          meta.totalPrice = item.totalPrice || 0

          // 기타결제 작업단계 상태
          let dealStatusTypes = {
            none: { name: 'none', text: '임시', signal: '⚪' },
            wait: { name: 'wait', text: '대기', signal: '🟡' },
            review: { name: 'review', text: '작업중', signal: '🟢' },
            reject: { name: 'reject', text: '작업거절', signal: '🟠' },
            accept: { name: 'accept', text: '작업완료', signal: '🔵' },
            cancel: { name: 'cancel', text: '작업취소', signal: '⚪' }
          }

          // 어떤타입인지 체크
          meta.etcType = 'etc.etc'

          // 가격변경비 타입
          if (['etc'].includes(item.orderType)
            && ['approval'].includes(_.get(item, 'content.category'))
            && ['bookSubmit'].includes(_.get(item, 'content.type'))) {
            meta.etcType = 'etc.approval.bookSubmit'
            dealStatusTypes = {
              none: { name: 'none', text: '임시', signal: '⚪' },
              wait: { name: 'wait', text: '대기중', signal: '🟡' },
              review: { name: 'review', text: '제출됨', signal: '🟢' },
              reject: { name: 'reject', text: '진행거절', signal: '🟠' },
              accept: { name: 'accept', text: '제출완료', signal: '🔵' },
              cancel: { name: 'cancel', text: '결제취소', signal: '⚪' }
            }
          }

          // 파일교체비 관련
          if (['etc'].includes(item.orderType)
            && ['approval'].includes(_.get(item, 'content.category'))
            && ['bookFileChange'].includes(_.get(item, 'content.type'))) {
            meta.etcType = 'etc.approval.bookFileChange'
          }
          
          // 재고서비스 관련
          if (['etc'].includes(item.orderType)
            && ['etc'].includes(_.get(item, 'content.category'))
            && ['bookStockStorage'].includes(_.get(item, 'content.type'))) {
            meta.etcType = 'etc.etc.bookStockStorage'
            dealStatusTypes = {
              none: { name: 'none', text: '임시', signal: '⚪' },
              wait: { name: 'wait', text: '대기중', signal: '🟡' },
              review: { name: 'review', text: '확인중', signal: '🟢' },
              reject: { name: 'reject', text: '거절됨', signal: '🟠' },
              accept: { name: 'accept', text: '처리완료', signal: '🔵' },
              cancel: { name: 'cancel', text: '결제취소', signal: '⚪' }
            }

            meta.product = _.get(extras, 'product') || null
            meta.book = _.get(meta, 'product.content') || null
            meta.activatedStockStorage = _.get(extras, 'activatedStockStorage') || null
            meta.childrenParcelStockStoragePay = _.get(extras, 'childrenParcelStockStoragePay') || null
          }

          // 판매상태와 환불상태를 명기한다.
          meta.dealStatus = _.get(dealStatusTypes, `${item.dealStatus}`) || dealStatusTypes.none
          meta.refundApprovals = item.refundApprovals || []
          meta.refundApprovalsCount = item.refundApprovals.length || 0
          meta.refundStatus = _.get(refundStatusTypes, `${item.refundStatus}`) || refundStatusTypes.none

          // 수익금 배정상태에 대한 정보를 구성
          meta.profits = _.get(extras, 'profits') || []
          meta.sumProfitTotal = meta.profits.reduce((s, profit) => s + profit.total * 1, 0)

          // 부모주문 가져오기
          meta.parentOrder = _.get(extras, 'parentOrder') || {}
          meta.parentOrderType = _.get(meta, 'parentOrder.orderType') || 'etc'
          
          return (
              <Item  key={idx}>
                <Item.Col style={{ flex: '1 1 100%', wordBreak: 'break-all' }}>
                  <div style={{ width: '100%' }}>

                    <div style={{ padding: '0.35rem' }}>
                      <div style={{ marginBottom: '1.95rem' }}>
                        <strong style={{ fontSize: '1.2em', fontWeight: 900, color: '#111' }}>{meta.subject}</strong>
                      </div>

                      <div style={{ marginBottom: '1rem' }}>
                        <strong style={{ fontSize: '1.8em', fontWeight: 900 }}>{comma(meta.eachPrice)}</strong>
                        <small style={{ marginLeft: '0.25rem' }}>원</small>
                        <small style={{ marginLeft: '0.235rem' }}>({comma(meta.amount)}개)</small>
                      </div>
                    </div>

                    {/* 파일교체비 기타결제 */}
                    {['etc.approval.bookFileChange'].includes(meta.etcType)
                      ? (
                        <Meta.Group>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>번호</span>
                                <strong style={{ marginRight: '0.35rem' }}>{_.get(item, 'content.bookNo') || '0000000'}</strong>
                                {['paperBook'].includes(_.get(item, 'content.bookType')) ? <small>{'종이도서'}</small> : null}
                                {['electronicBook'].includes(_.get(item, 'content.bookType')) ? <small>{'전자도서'}</small> : null}
                                {!['paperBook', 'electronicBook'].includes(_.get(item, 'content.bookType')) ? <small>{'기타'}</small> : null}
                              </small>
                            </section>
                          </Meta>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>작업내역</span>
                                <a
                                  href='#goApprovals'
                                  style={{ fontWeight: '900' }}
                                  onClick={async (e) => {
                                    const keywords = _.get(item, 'content.bookNo') || 0
                                    const query = { keywords, keywordsOption: 'bookNo', filters_status: 'all' }
                                    const url = `/manages/bookFileChange?${qs.stringify(query)}`
                                    return [e.stopPropagation(), e.preventDefault(), window.open(url)]
                                  }}
                                >
                                  보기
                                </a>
                              </small>
                            </section>
                          </Meta>
                        </Meta.Group>
                      )
                      : null}
                    
                    {/* 가격변경비 기타결제 */}
                    {['etc.approval.bookSubmit'].includes(meta.etcType)
                      ? (
                        <Meta.Group>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>번호</span>
                                <strong style={{ marginRight: '0.35rem' }}>{_.get(item, 'content.bookNo') || '0000000'}</strong>
                              </small>
                            </section>
                          </Meta>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>종류</span>
                                {['paperBook'].includes(_.get(item, 'content.bookType')) ? <strong>{'종이도서'}</strong> : null}
                                {['electronicBook'].includes(_.get(item, 'content.bookType')) ? <strong>{'전자도서'}</strong> : null}
                                {!['paperBook', 'electronicBook'].includes(_.get(item, 'content.bookType')) ? <strong>{'기타'}</strong> : null}
                              </small>
                            </section>
                          </Meta>
                        </Meta.Group>
                      )
                      : null}
                    
                    {/* 재고서비스 관련 결제 */}
                    {['etc.etc.bookStockStorage'].includes(meta.etcType)
                      ? (
                        <Meta.Group>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>도서번호</span>
                                <strong
                                  style={{ marginRight: '0.35rem', cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return window.open(`/products/paperBook/${meta.product.id}`)
                                  }}
                                >
                                  {_.get(meta, 'book.bookNo') || '0000000'}
                                </strong>
                              </small>
                            </section>
                          </Meta>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>상품ID</span>
                                <strong
                                  style={{ marginRight: '0.35rem', cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    const productId = _.get(meta, 'product.id')
                                    return window.open(`/products/paperBook/${productId}`)
                                  }}
                                >
                                  {_.get(meta, 'product.id') || '미기재'}
                                </strong>
                              </small>
                            </section>
                          </Meta>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>책장번호</span>
                                <strong>{_.get(meta, 'activatedStockStorage.storageNo')  || '-'}</strong>
                              </small>
                            </section>
                          </Meta>
                          <Meta>
                            <section>
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.35rem' }}>🚚 발송ID</span>
                                <strong
                                  style={{ marginRight: '0.35rem', cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    const payId = _.get(meta, 'childrenParcelStockStoragePay.id')
                                    const url = `/pays/paperBook/${payId}`
                                    return window.open(url)
                                  }}
                                >
                                  {_.get(meta, 'childrenParcelStockStoragePay.id') || '미발급'}
                                </strong>
                              </small>
                            </section>
                          </Meta>
                        </Meta.Group>
                      )
                      : null}

                    {!item.disabledProfit
                      ? (
                        <div style={{ padding: '0.35rem 0', marginTop: '0.5rem', borderTop: '1px dashed #eee' }}>
                          <small
                            style={{ marginRight: '0.75rem', cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              
                              const query = {}
                              query.keywords = item.id
                              query.keywordsOption = 'orderId'
                              const url = `/profits/deposits?${qs.stringify(query)}`
                              return window.open(url)
                            }}
                          >
                            <span style={{ marginRight: '0.25rem' }}>배정된 수익금</span>
                            <strong style={{ marginRight: '0.15rem' }}>{comma(meta.sumProfitTotal)}원</strong>
                            <small>({comma(_.get(meta, 'profits.length'))}건)</small>
                          </small>
    
                          {item.parent
                            ? (
                              <small style={{ marginRight: '0.75rem' }}>
                                <span style={{ marginRight: '0.25rem' }}>👨‍👩‍👦 상위 주문내역</span>
                                <strong
                                  style={{ marginRight: '0.15rem', cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    
                                    const query = {}
                                    query.keywords = item.parent
                                    query.keywordsOption = 'orderId'
                                    const url = `/pays/${meta.parentOrderType}?${qs.stringify(query)}`
                                    return window.open(url)
                                  }}
                                >
                                  보기
                                </strong>
                              </small>
                            )
                            : null}
                        </div>
                      )
                      : null}

                  </div>
                </Item.Col>
              </Item>
          )
        })}
      </Item.Group>
    )
  }
}

export default OrdersContainer
