import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all; text-align: center;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { item: {} }

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  // 이미 보유한 item.currentUser 에서 가져온다.
  async initialize() {
    const currentUser = _.get(this, 'props.item.currentUser') || {}
    if (!currentUser || !currentUser.id) { return null }
    this.setState({ item: currentUser })
  }
  
  render() {
    const { columns } = this.props
    const { item } = this.state
    if (!item.id) { return null } 

    const meta = {
      name: item.name || '실명 미기재',
      mobile: item.mobile || '00000000000',
      email: item.email || '이메일 없음',
      accountId: item.accountId || '계정명 없음',
      nickName: item.nickName || '닉네임 없음'
    }

    return (
      <Column>
        {columns.includes('accountId')
          ? (
            <div style={{ minWidth: '100%', maxWidth: '100%' }}>
              <strong  onClick={async (e) => await navigator.clipboard.writeText(meta.accountId)} style={{ cursor: 'pointer' }}>
                {meta.accountId}
              </strong>
            </div>
          )
          : null}
        {columns.includes('email')
          ? (
            <div style={{ minWidth: '100%', maxWidth: '100%' }}>
              <strong onClick={async (e) => await navigator.clipboard.writeText(meta.email)} style={{ cursor: 'pointer' }}>
                {meta.email}
              </strong>
            </div>
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
