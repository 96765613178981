import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import qs from 'query-string'
import api from 'services/api'
import moment from 'services/moment'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import withdrawPendingExportParser from './export.withdrawPending'

import StatusColumn from './Columns/StatusColumn'
import UserDetailColumn from './Columns/UserDetailColumn'
import DetailColumn from './Columns/DetailColumn'
import SummaryColumn from './Columns/SummaryColumn'
import IdentityColumn from './Columns/IdentityColumn'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  admin, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {

  const commonProps = { admin, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'IdentityColumn', header: '구분', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center' } })
  columns.push({ key: 'UserDetailColumn', header: '유저', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '단계', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'DetailColumn', header: '수익금 지급 상세내역', style: { minWidth: '700px', maxWidth: '700px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BlankColumn', header: '', style: { minWidth: '0', flex: '1 1 100%', maxWidth: '100%', justifyContent: 'flex-start' } })
  columns.push({ key: 'SummaryColumn', header: '정산건수·지급대상액', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 인터페이스 기능
  const actions = {}

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const lump = query.limit || limit || 30
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length} 블록으로 나누어 다운로드중...`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 전체 엑셀 다운로드 기능
  actions.doDownloadExcel = async (items = selecteds) => {
    if (!items) { return alert(`다운로드를 할만한 데이터가 없습니다.`) }

    const curAt = new Date(), curMt = moment(curAt)
    const parser = Object.assign({}, withdrawPendingExportParser)

    parser.systemPassword = _.get(admin, '__SYSCD')
    if (!parser.systemPassword) { return alert('시스템 SYSCD를 올바르게 전달받지 못했습니다. 시스템 관리자에게 문의해주세요.') }

    // 맨 우측에 명수를 기재 @2023.03.03
    // 첫 컬럼에 기간명시 @2023.03.03
    _.set(parser, 'columns[0].header', `${curMt.format('YYYY')}년 ${curMt.format('MM')}월`)
    parser.columns.push({ header:  `${items.length}명`, key: 'summary', view: (item = {}) => ''})

    return await new Exporter({ parser, items })
      .download(`수익금 지급대기 내역 총 ${items.length}건 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
  }

  // 선택한 유저만 이월처리하는 기능
  actions.doUpdateWithdrawToDeposit = async (items = selecteds, opts = {}) => {
    if (!items || !items.length) { return alert(`이월을 할 지급대기 내역을 반드시 선택해주셔야합니다.`) }
    if (!window.confirm(`선택된 지급대기내역(총 ${items.length}건) 입니다. 이월(정산대기로 전환)을 진행할까요?`)) { return }

    // 선택한 유저 이월처리 과정을 만들기
    const result = await items.reduce((prev, item) => 
      prev.then(async (report) => {
        const payload = {}
        payload.forced = opts.forced || false
        const result = await api.put(`/profits/admin2/withdrawToDeposit/${item.id}`, payload).catch(e => null)
        if (!result || result.error) {
          report.failure.push({ error: true, message: (result && result.message) || '알수 없는 오류가 발생하였습니다.', item })
          return report
        }
        report.success.push({ error: false, message: '정상적으로 정산대기로 전환하여 반영하였습니다.', item })
        return report
      }),
      Promise.resolve({ success: [], failure: [] })).catch(e => ({ success: [], failure: [] }))

    // 오류가 발생하여처리구간이 훼손된 경우
    if (result.failure.length) {
      alert(`${result.success.length}건 성공, ${result.failure.length}건 실패 하였습니다. 진행되지 않은 지급대기건은 목록에 남아있습니다.`)
      return initialize()
    }
    if (!result.success.length) {
      alert(`처리된 내역이 없습니다. 오류가 발생하였습니다.`)
      return initialize()
    }

    alert(`성공적으로 지급대기건을 지급대기로 전환하여 이월 처리하였습니다.`)
    return initialize()
  }

  // 지급완료엑셀 업로드
  actions.doUploadWithdrawResolvedExcelFile = async () => {
    if (!window.confirm(`A열에 반드시 부크크 로그인시 사용되는 계정명이 기재되어 있어야합니다. 계정기반임으로 만약 한 계정에 2건의 지급대기내역이 있다면 모두 지급완료처리가 될 수 있습니다. 송금한 계정들로 채워진 엑셀이 필요합니다. 진행할까요?`)) { return }

    // 계정명을 A열에 담아 처리한다.
    const parser = { name: `수익금 지급완료 엑셀 업로드` }
    parser.columns = {}
    parser.columns['A'] = { name: 'accountId', text: '계정명', parse: (cell) => { return cell ? `${cell}`.trim() : `` } }

    const output = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, 1))
      .catch(e => ({ items: [] }))
    const items = output.items
    if (!items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }
    
    // 빈값 제거, 중복항 제거
    const targets = [
      ...new Set(
        items
        .map(item => `${item.accountId}`)
        .filter(item => item)
      )]
    if (!targets.length) { return alert(`중복계정명, 빈값 제거후 체크한 결과 문제가 발생하였습니다.`) }

    const payload = { targets }
    const result = await api
      .put(`/profits/admin2/withdrawResolvedByAccountIds`, payload)
      .catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? `${result.message}` : `지급완료처리구간의 처리결과 데이터를 불러오지 못했습니다.`)
    }

    alert((result && result.message) || `성공적으로 지급완료로 전환(계정명 매칭방식)을 처리하였습니다.`)
    return initialize()
  }

  // 전체 지급완료처리 기능
  actions.doUpdateWithdrawResolved = async (items = selecteds) => {
    if (!items) { return alert(`지급완료 처리할 데이터가 없습니다.`) }
    if (!window.confirm(`지급완료 대상 건수는 총 ${items.length}건입니다. 진행할까요?`)) { return }

    const payload = { targets: items.map(item => item.id) }
    const result = await api
      .put(`/profits/admin2/withdrawResolvedByTargets`, payload)
      .catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? `${result.message}` : `지급완료처리구간의 처리결과 데이터를 불러오지 못했습니다.`)
    }

    alert((result && result.message) || `성공적으로 지급완료로 전환을 처리하였습니다.`)
    return initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>

          {['resolved'].includes(filters.status)
            ? (
              <div className="selector">
                <input
                  type="date"
                  value={moment(startAt).format('YYYY-MM-DD')}
                  onChange={e => {
                    if (!e.target.value) { return }
                    const [year, month, date] = (e.target.value).split('-')
                    const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                    const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                    return startMt.diff(prevEndMt) > 0
                      ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                      : handle('startAt', startMt.toDate(), () => { initialize() })
                  }}
                />
                <input
                  type="date"
                  value={moment(endAt).format('YYYY-MM-DD')}
                  onChange={e => {
                    if (!e.target.value) { return }
                    const [year, month, date] = (e.target.value).split('-')
                    const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                    const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                    return prevStartMt.diff(endMt) > 0
                      ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                      : handle('endAt', endMt.toDate(), () => { initialize() })
                  }}
                />
              </div>
            )
            : null}
        </Header.Search>
        <Header.Options>
          <a
            href="#doDownloadExcelBySelecteds"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadExcel()
            }}
          >
            선택엑셀
          </a>
          <a
            href="#doDownloadExcelByAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doDownloadExcel(items)
            }}
          >
            전체엑셀
          </a>

          {['pending'].includes(filters.status)
            ? (
              <>
                <a
                  href="#doUpdateWithdrawToDepositByAll"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    const items = await actions.getItemsAll({}, { progress: true })
                    return await actions.doUpdateWithdrawToDeposit(items, { forced: true })
                  }}>
                  이월 전체처리
                </a>
                <a
                  href="#doUpdateWithdrawToDeposit"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doUpdateWithdrawToDeposit(selecteds, { forced: true })
                  }}>
                    이월 선택처리
                </a>
                <a
                  href="#doUpdateWithdrawResolved"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doUpdateWithdrawResolved()
                  }}>
                    지급완료 선택전환
                </a>
                <a
                  href="#doUpdateWithdrawResolvedAll"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    const items = await actions.getItems({}, { progress: true })
                    return await actions.doUpdateWithdrawResolved(items)
                  }}>
                    지급완료 전체전환
                </a>
                <a
                  href="#doUploadWithdrawResolvedExcelFile"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doUploadWithdrawResolvedExcelFile({})
                  }}>
                    지급완료 엑셀업로드
                </a>
              </>
            )
            : null}
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    // const meta = {}

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'UserDetailColumn':
                              return <UserDetailColumn {...columnProps} />
                            case 'IdentityColumn':
                              return <IdentityColumn {...columnProps} />
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />
                            case 'DetailColumn':
                              return <DetailColumn {...columnProps} />
                            case 'SummaryColumn':
                              return <SummaryColumn {...columnProps} />
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>지급대기, 지급완료, 지급취소 데이터만 노출됩니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '지급관리',
  nav: 'withdraws'
}

export default List
