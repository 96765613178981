import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import api from 'services/api'
import backdoor from 'services/backdoor'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const { REACT_APP_CLIENT_URL } = process.env

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'DateColumn', header: '작성일', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ClassifyColumn', header: '구분', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  // columns.push({ key: 'ClaimColumn', header: '신고', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'IdColumn', header: '게시글ID', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'FeedColumn', header: '게시글', style: { minWidth: '618px', maxWidth: '618px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'UserColumn', header: '작성자', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'FileCountColumn', header: '첨부', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'CommentCountColumn', header: '댓글', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '단계', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { minWidth: '0', maxWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '삭제', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  const classifyTypes = {}
  classifyTypes.faq = { name: 'faq', text: '자주묻는질문' }
  classifyTypes.notice = { name: 'notice', text: '공지사항' }
  classifyTypes.qna = { name: 'qna', text: '지식iN' }
  classifyTypes.how = { name: 'how', text: '작가노하우' }
  classifyTypes.free = { name: 'free', text: '자유게시판' }
  classifyTypes.debate = { name: 'debate', text: '독서토론' }
  classifyTypes.etc = { name: 'etc', text: '기타' }

  const statusTypes = {}
  statusTypes.wait = { name: 'wait', text: '대기' }
  statusTypes.review = { name: 'review', text: '확인' }
  statusTypes.reject = { name: 'reject', text: '반려' }
  statusTypes.accept = { name: 'accept', text: '완료' }
  statusTypes.cancel = { name: 'cancel', text: '취소' }
  statusTypes.none = { name: 'none', text: '게재' }
  
  // 바로가기 기능들을 배치해주기
  const actions = {}

  // 특정 결제내역으로 바로 이동하기
  actions.doOpenFeed = async (item = {}) => {
    const url = `${REACT_APP_CLIENT_URL}/community/${item.classify}/all?id=${item.id}`
    return window.open(url)
  }

  // 특정 상품을 바로 접근하기
  actions.doOpenUser = async (item = {}) => {
    if (!item.user) { return alert(`아이디가 존재하지 않는 게시물입니다`) }
    const url = `/users/${item.user}`
    return window.open(url)
  }

  // 글의 단계를 변경하는 기능
  actions.doUpdateItemStatus = async (item = {}) => {
    if (!window.confirm(`게시글 단계 상태 정보를 변경할까요?`)) { return }

    const form = {}
    form.status = item.status
    
    const result = await api.put(`/activities/admin2/feeds/${item.id}/status`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return await initialize()
  }

  // 해당 게시글 삭제 기능
  actions.doDeleteItem = async (item ={}) => {
    if (!window.confirm(`해당 게시글을 삭제할까요?`)) { return }
    const result = await api.delete(`/activities/admin2/feeds/${item.id}`).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // @ 해당유저로 로그인하여 웹 접속하기
  actions.doLogin = async (item = {}) => { return await backdoor.login(item.user) }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 게시글이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentUser = extras.currentUser || {}

                    const meta = {}

                    meta.uniqueId = item.id
                    meta.title = item.title || '작성 글 제목이 기재되지 않았습니다.'
                    meta.classifyType = classifyTypes[item.classify] ? classifyTypes[item.classify] : classifyTypes.etc
                    meta.statusType = statusTypes[item.status] ? statusTypes[item.status] : statusTypes.none

                    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null

                    meta.fileCount = _.get(item, 'files.length') || 0
                    meta.commentCount = _.get(item, 'comments.length') || 0

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )

                            case 'DateColumn':
                              return (
                                <div>
                                  <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )

                            case 'ClassifyColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <div><strong>{_.get(meta, 'classifyType.text')}</strong></div>
                                </div>
                              )

                            // case 'ClaimColumn':
                            //   return (
                            //     <div style={{ textAlign: 'center' }}>
                            //       {item.claim ? <span>🚩</span> : null}
                            //       {!item.claim ? <span>-</span> : null}
                            //     </div>
                            //   )

                            case 'IdColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [e.stopPropagation(), e.preventDefault(), await actions.doOpenFeed(item)]
                                  }}
                                >
                                  <strong>{meta.uniqueId}</strong>
                                </div>
                              )
                              
                            case 'FeedColumn':
                              return (
                                <div
                                  style={{ whiteSpace: 'normal', width: '100%', wordBreak: 'break-all', overflow: 'hidden', maxWidth: 'fit-content', minWidth: 0, cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [e.stopPropagation(), e.preventDefault(), await actions.doOpenFeed(item)]
                                  }}
                                >
                                  <strong>{meta.title}</strong>
                                </div>
                              )

                            case 'UserColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [e.stopPropagation(), e.preventDefault(), await actions.doOpenUser(item)]
                                  }}
                                >
                                  <strong>{_.get(currentUser, 'nickName') || _.get(currentUser, 'name') || '-'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{_.get(currentUser, 'accountId') || '-'}</small>
                                </div>
                              )
                              
                            case 'FileCountColumn':
                              return (
                                <div>
                                  <strong style={{ fontWeight: 800 }}>{comma(meta.fileCount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>개</small>
                                </div>
                              )

                            case 'CommentCountColumn':
                              return (
                                <div>
                                  <strong style={{ fontWeight: 800 }}>{comma(meta.commentCount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>개</small>
                                </div>
                              )

                            case 'StatusColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <select
                                    value={item.status}
                                    style={{ marginRight: '0.5rem' }}
                                    onChange={e => {
                                      const next = {}
                                      next[`items[${itemIdx}].status`] = e.target.value || 'none'
                                      return handle(next)
                                    }}
                                  >
                                    {Object.values(statusTypes).map((type, tIdx) => {
                                      return <option key={tIdx} value={type.name}>{type.text}</option>
                                    })}
                                  </select>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateItemStatus(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>

                              )

                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doDeleteItem(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>글이 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '전체 글 관리',
  nav: 'feeds'
}

export default List
