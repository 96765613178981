import React from 'react'
import PropTypes from 'prop-types'
import _, { remove } from 'lodash'
import api, { REACT_APP_FILE_URL } from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & .filePreview {
    position: relative; box-sizing: border-box;
    & > div.file {
      position: relative; box-sizing: border-box;
      padding: 0.15rem 0; transition: all 0.3s; border-radius: 0.35rem;
      cursor: pointer;

      & div.image {
        position: relative; box-sizing: border-box;
        display: flex; justify-content: center; align-items: center;
        min-height: 80px; max-height: 80px; height: 80px;
        margin: 0.35rem 0;
        & > img { height: 100%; }
      }

      & div.document {
        position: relative; box-sizing: border-box;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }

      & div.empty {
        position: relative; box-sizing: border-box; opacity: 0.3;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }

      & div.meta {
        text-align: center;
        font-size: 0.95em;
      }
      
      &:hover { background: ${palette('muted', 15)}; }
    }
  }

  & a.downloadButton {
    padding: 0.35rem; border-radius: 0.35rem; font-weight: 500;
    transition: all 0.3s; cursor: pointer; margin: 0.25rem 0;
    text-decoration: none;
    background: ${palette('muted', 2)}; color: ${palette('muted', 21)};
    &:hover { background: ${palette('muted', 0)}; color: ${palette('muted', 21)}; }
  }
`

// 도서상품의 썸네일파일을 가져오는기능
class BookThumbnailByProductColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() {}
  
  render() {
    const { item } = this.props

    const extras = _.get(item, 'extras') || {}
    const currentProduct = _.get(extras, 'currentProduct') || {}

    const book = currentProduct.content || {}

    // 해당 문건의 썸네일 파일이 문서인지, 이미지인지 구분한다.
    const file = _.get(currentProduct, 'content.thumbnail.path') || ''
    const createdAt = _.get(currentProduct, 'content.thumbnail.createdAt') || null
    const createdMt = createdAt ? moment(createdAt) : null
    const ext = (file ? file.split('.').reverse()[0] : '').toLowerCase()

    // 메타로 제정립
    const meta = {}
    meta.imageExts = ['JPG', 'JPEG', 'PNG', 'GIF', 'JFIF']
    meta.documentExts = ['PDF', 'DOC', 'DOCX']
    meta.allowExts = [...meta.imageExts, ...meta.documentExts]

    return (
      <Column>
        <div className="filePreview">
          <div
            className="file"
            title={file}
            onClick={async (e) => {
              return [e.stopPropagation(), e.preventDefault(), 
                await fetch(`${REACT_APP_FILE_URL}${file}`)
                  .then(response => response.blob())
                  .then(blob => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = `[${book.bookNo}] ${book.title}_${book.author}.${ext}`
                    document.body.appendChild(a);
                    a.click();    
                    a.remove();
                  })
                  .catch(e => alert(`다운로드를 할 수 없습니다.`))
              ]
            }}
          >

            {meta.imageExts.includes(ext.toUpperCase())
              ? (
                <>
                  <div className="image">
                    <img src={`${REACT_APP_FILE_URL}${file}`} onError={e => e.target.src = '/sample.png' } />
                  </div>
                  <div className="meta">
                    {createdMt ? (
                      <>
                        <div><small style={{ fontWeight: '500' }}><strong>{createdMt.format('YYYY.MM.DD')}</strong></small></div>
                        <div><small>{createdMt.format('HH:mm')}</small></div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}

            {!file || !meta.imageExts.includes(ext.toUpperCase()) ? (
              <>
                <div className="empty">
                  <div><strong>-</strong></div>
                  <div><small>빈파일</small></div>
                </div>
              </>
            ) : null}

          </div>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookThumbnailByProductColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

BookThumbnailByProductColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default BookThumbnailByProductColumnContainer
