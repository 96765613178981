import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all; text-align: center;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 대상도서의 재고량을 가져온다. @2023.02.18 extras 기반으로 추출해온다.
class StockColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() { }

  render() {
    const { part = 'invoiceAndOverflow', item = {} } = this.props

    const extras = item.extras || {}
    const stock = extras.currentStock || {}

    // 수량 세팅
    const meta = {}
    meta.amount = 0
    if (['invoiceAndOverflow'].includes(part)) { meta.amount = _.get(stock, 'invoice') + _.get(stock, 'overflow') }
    if (['instock'].includes(part)) { meta.amount = _.get(stock, 'instock') }
    
    return (
      <Column>
        <div>
          <strong>{comma(meta.amount)}</strong>
          <span style={{ marginLeft: '0.236rem' }}>개</span>
        </div>
      </Column>
    )
  }
}

export default StockColumnContainer
