import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import CreateNotificationModal from './Setups/CreateNotificationModal'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'CreatedDateColumn', header: '발송날짜', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReadedDateColumn', header: '읽음날짜', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'CategoryColumn', header: '구분', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'UserColumn', header: '계정정보', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ContentColumn', header: '발송내역', style: { minWidth: '618px', maxWidth: '618px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWiidth: '0', maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '삭제', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)
  
  // @ 알림 카테고리
  // @ src\api\notification\model.js 에서 복붙
  const categoryTypes = {
    bookSubmit: { name: 'bookSubmit', text: '도서 입점승인' }, // 도서 승인
    solutionSubmit: { name: 'solutionSubmit', text: '작가서비스 입점승인' }, // 작가서비스 승인
    bookRetail: { name: 'bookRetail', text: '판매신청' }, // 도서 외부유통 판매신청
    bookStopRetail: { name: 'bookStopRetail', text: '중지신청' }, // 도서 외부유통 판매중지
    deliveryProcess: { name: 'deliveryProcess', text: '주문발송' }, // 도서 발송준비중, 도서 포장중, 도서 발송완료 3가지
    solutionOrderStatus: { name: 'solutionOrderStatus', text: '작업안내' }, // 작가서비스 상태 변경(승락 또는 거절 상태/최종상태)
    solutionOrderComment: { name: 'solutionOrderComment', text: '작업대화' }, // 작가서비스 주문 댓글
    bookSales: { name: 'bookSales', text: '도서 판매' },
    solutionSales: { name: 'solutionSales', text: '작가서비스 판매' },
    bookReview: { name: 'bookReview', text: '도서 후기' }, // 도서 구매 후기
    solutionReview: { name: 'solutionReview', text: '작가서비스 후기' }, // 작가서비스 구매 후기
    feedComment: { name: 'feedComment', text: '댓글알림' }, // 작가서비스 주문 댓글, 댓댓글 포함
    etc: { name: 'etc', text: '기타' }, // 기타 알림
  }

  // 로우 작동 액션들
  const actions = {}

  // 알림 생성 모달 만들기
  actions.doOpenCreateNotificationModal = async (item = {}) => {
    const modalProps = {
      name: 'CreateNotificationModal',
      props: { name: 'CreateNotificationModal', item, onClose: async () => [setModal(null), await initialize()] }
    }
    return setModal(modalProps)
  }

  // 알림 주소 업데이트 기능
  actions.doUpdateItem = async (item = {}) => {
    if (!window.confirm(`알림  발송 주소 정보를 변경할까요?`)) { return }

    const form = {}
    form.subject = item.subject
    form.message = item.message
    form.url = item.url
    
    const result = await api.put(`/activities/admin2/notifications/${item.id}`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // 알림 업데이트 기능
  actions.doUpdateItemReadedStatus = async (item = {}) => {
    if (!window.confirm(`알림의 읽음 상태 정보를 변경할까요?`)) { return }
    const curAt = new Date()

    const form = {}
    form.readed = item.readed
    if (form.readed) {
      form.readedAt = curAt
      // 행위 업데이트도 해준다.
      form.didAction = true
      form.actionAt = curAt
    } else {
      form.readedAt = null
    }
    
    const result = await api.put(`/activities/admin2/notifications/${item.id}/readedStatus`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // 알림 삭제기능
  actions.doDeleteItem = async (item ={}) => {
    if (!window.confirm(`알림를 삭제할까요?`)) { return }
    const result = await api.delete(`/activities/admin2/notifications/${item.id}`).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // 개발중 테스트 처리
  // React.useState(() => {
  //     const modalProps = { name: 'CreateNotificationModal', props: { name: 'CreateNotificationModal', onClose: async () => [setModal(null), await initialize()] } }
  //     setModal(modalProps)
  //   }, [])

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 알림내역이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a href="#doCreateNotification" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenCreateNotificationModal()]}>알림 보내기</a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentUser = extras.currentUser || {}

                    const meta = {}

                    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
                    meta.readedMt = _.get(item, 'readedAt') ? moment(_.get(item, 'readedAt')) : null
                    meta.categoryType = _.get(categoryTypes, _.get(item, 'category.name')) ? _.get(categoryTypes, _.get(item, 'category.name')) : categoryTypes.etc
                    meta.userAccountId = _.get(currentUser, 'accountId')
                      ? _.get(currentUser, 'accountId')
                      : ''

                    meta.subject = item.subject || '알람 제목이 작성되지 않았습니다.'
                    meta.message = item.message || '추가 메시지 작성 내용은 없습니다.'
                    meta.action = item.action || 'url'
                    meta.url = item.url || '주소가 입력되지 않았습니다.'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )

                            case 'CreatedDateColumn':
                              return (
                                <div>
                                  <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )


                            case 'ReadedDateColumn':
                              return (
                                <div>
                                  <strong>{meta.readedMt ? meta.readedMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.readedMt ? meta.readedMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )

                            case 'CategoryColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <div><strong>{_.get(meta, 'categoryType.text') || '알수없음'}</strong></div>
                                  {(_.get(item, 'priority') === -1) ? <div><small>일반</small></div> : null}
                                  {(_.get(item, 'priority') === 0) ? <div><small>중요</small></div> : null}
                                  {(_.get(item, 'priority') === 1) ? <div><small>매우중요</small></div> : null}
                                  {(_.get(item, 'priority') === 2) ? <div><small>긴급</small></div> : null}
                                </div>
                              )

                            case 'UserColumn':
                              return (
                                <div>
                                  <strong>{meta.userAccountId || '-'}</strong>
                                </div>
                              )
                              
                            case 'ContentColumn':
                              return (
                                <div style={{ width: '100%' }}>
                                  <div style={{ width: '100%', marginBottom: '0.35rem' }}>
                                    <input
                                      type="text"
                                      placeholder={`알림 제목`}
                                      value={item.subject}
                                      style={{ fontWeight: 700 }}
                                      onChange={(e) => {
                                        const next = {}
                                        next[`items[${itemIdx}].subject`] = e.target.value
                                        return handle(next)
                                      }}
                                    />
                                  </div>
                                  <div style={{ width: '100%', marginBottom: '0.35rem' }}>
                                    <textarea
                                      placeholder={`알림 내용`}
                                      value={item.message}
                                      style={{ height: '80px' }}
                                      onChange={(e) => {
                                        const next = {}
                                        next[`items[${itemIdx}].message`] = e.target.value
                                        return handle(next)
                                      }}
                                    />
                                  </div>
                                  <div style={{ display: 'flex', width: '100%' }}>
                                    <input
                                      type="text"
                                      placeholder={`알림이 바라보는 경로`}
                                      style={{ marginRight: '0.35rem' }}
                                      value={item.url}
                                      onChange={(e) => {
                                        const next = {}
                                        next[`items[${itemIdx}].url`] = e.target.value
                                        return handle(next)
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className="button"
                                      onClick={e => {
                                        return [e.stopPropagation(), e.preventDefault(), actions.doUpdateItem(item)]
                                      }}
                                    >
                                      변경
                                    </button>
                                  </div>
                                </div>
                              )
                            case 'StatusColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <select
                                    value={item.readed ? 'true' : 'false'}
                                    style={{ marginRight: '0.35rem' }}
                                    onChange={e => {
                                      const next = {}
                                      next[`items[${itemIdx}].readed`] = e.target.value === 'true' ? true : false
                                      return handle(next)
                                    }}
                                  >
                                    <option value="true">읽음</option>
                                    <option value="false">읽지않음</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doUpdateItemReadedStatus(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>
                              )
                            case 'TotalPriceColumn':
                              return (
                                <div>
                                  <strong>{comma(item.totalPrice)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>원</small>
                                </div>
                              )
                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doDeleteItem(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>알림가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

      {modal && modal.name ? <CreateNotificationModal {...modal.props} /> : null}
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '알림 관리',
  nav: 'notifications'
}

export default List
