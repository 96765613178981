import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { palette } from 'styled-theme'
import Clipboard from 'clipboard'

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${palette('success', 2)};
  }
`

const Hidden = styled.button`
  position: absolute;
  opacity: 0;
`

class Copiable extends Component {
  constructor(props) {
    super(props)
    this.ref = createRef()
    this.hidden = createRef()
    this.state = { text: props.text || props.value }
  }

  componentDidMount() {
    this.clipboard = new Clipboard(
      this.ref.current, {
      target: () => this.hidden.current
    })

    this.clipboard.on('success', e => {
      e.clearSelection()
      this.setState({ text: 'Copied' }, () => {
        setTimeout(() => {
          this.setState({ text: this.props.text || this.props.value })
        }, 450)
      })
    })
  }

  render() {
    return (
      <div style={this.props.inline ? { display: 'inline-block', margin: '0 0.2rem' } : null}>
        <StyledButton onClick={ev => ev.stopPropagation()} ref={this.ref}>{this.state.text}</StyledButton>
        <Hidden ref={this.hidden}>{this.props.value}</Hidden>
      </div>
    )
  }
}

Copiable.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string,
  inline: PropTypes.bool
}

Copiable.defaultProps = {
  text: '',
  inline: false
}

export default Copiable
