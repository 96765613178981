import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components'
import ReactModal from 'react-modal'
import { font, palette } from 'styled-theme'

ReactModal.setAppElement('#root')

createGlobalStyle`
  margin: 0;
  body.ReactModal__Body--open {
    overflow: hidden;
  }
`

const overlayStyles = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[class*="after-open"] {
    opacity: 1;
  }
  &[class*="before-close"] {
    opacity: 0;
  }
`

const ModalBox = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  font-family: ${font('primary')};
  font-size: 1rem;
  background-color: ${palette('grayscale', 0, true)};
  overflow: hidden;
  border-radius: 0.5em;
  color: ${palette('grayscale', 0)};
  outline: none;
  box-sizing: border-box;
  min-width: 320px;
  max-width: calc(100% - 1rem);
  max-height: calc(100% - 1rem);
  @media screen and (max-width: 640px) {
    width: calc(100% - 1rem);
    min-width: 0;
  }
`

const Content = styled.div`
  overflow: auto;
  padding: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledReactModal = styled(({ className, ...props }) => (
  <ModalBox overlayClassName={className} closeTimeoutMS={250} {...props} />
))`${overlayStyles}`

const Modal = ({
  children, title, closeable, onClose, ...props
}) => {
  const { reverse } = props
  const hasHeader = title || closeable
  return (
    <StyledReactModal
      contentLabel={title || 'Modal'}
      onRequestClose={onClose}
      hasHeader={hasHeader}
      {...props}
    >
      <Content>
        {children}
      </Content>
    </StyledReactModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  closeable: PropTypes.bool,
  reverse: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default Modal
