import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  // 이미 보유한 item.currentUser 에서 가져온다.
  async initialize() { }
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}

    const meta = {}
    meta.name = currentUser.name || '실명 미기재'
    meta.mobile = currentUser.mobile || '00000000000'
    meta.email = currentUser.email || '이메일 없음'
    meta.accountId = currentUser.accountId || '계정명 없음'
    meta.nickName = currentUser.nickName || '닉네임 없음'

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ maxWidth: '100%' }}>
            <header>
              <small>닉네임</small>
            </header>
            <section>
              <strong>{meta.nickName}</strong>
            </section>
            <section>
              <small><strong style={{ marginRight: '0.15rem' }}>{meta.name}</strong></small>
              <small>@{meta.accountId}</small>
            </section>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
