import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all; font-family: ${font('primary')};

  & > div.bookType { position: relative; box-sizing: border-box; }
  & > div.bookTitle { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookAuthor { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookPrice { position: relative; box-sizing: border-box; font-size: 0.85em; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class BookDetailWithApprovalColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }
  
  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() { }
  
  render() {
    const { item } = this.props

    const meta = {}
    meta.bookTitle = _.get(item, 'content.book.title') || '도서명 미기재'
    meta.bookAuthor = _.get(item, 'content.book.author') || '저자명 미기재'
    meta.bookTypeAlias = _.get(item, 'content.book.type') === 'paperBook'
        ? '종이도서'
        : (_.get(item, 'content.book.type') === 'electronicBook' ? '전자도서' : '기타')
    meta.bookPurpose = _.get(item, 'content.book.purpose.text') || '출판목적 불분명'
    meta.isbn = _.get(item, 'extras.currentBook.isbn') || ''
    
    // 링크 이동을 위한 값 배정
    meta.productId = _.get(item, 'content.productId') || null
    meta.userId = _.get(item, 'user') || null

    // 해당 도서 가격에 대한 추적(출판목적, 도서종류)
    meta.bookPrice = 0
    if (['external', 'internal'].includes(_.get(item, 'content.book.purpose.name'))) {
      meta.bookPrice = _.get(item, 'content.book.type') === 'paperBook'
        ? _.get(item, 'content.book.basicPrice') * 1
        : _.get(item, 'content.book.defaultPrice') * 1
    }
    if (_.get(item, 'content.book.purpose.name') === 'collection') {
      meta.bookPrice = _.get(item, 'content.book.collectionPrice') * 1
    }
    meta.bookPriceWithComma = meta.bookPrice ? comma(meta.bookPrice) : 0

    return (
      <Column>
        <div className="bookType">
          <small style={{ marginRight: '0.15rem' }}>{meta.bookTypeAlias}</small>
          <small><strong>{meta.isbn || meta.bookPurpose}</strong></small>
        </div>
        <div className="bookTitle">
          <a
            href={`/products/book/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong>{meta.bookTitle}
            </strong>
          </a>
        </div>
        <div className="bookAuthor">
          <a
            href={`/users/${meta.userId}`}
            target="_blank"
            onClick={e => {
              if (!meta.userId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`유저정보개 배정되지 못한 문서입니다. 시스템 관리자에게 문의해주세요.`)]
              }
              return
            }}
          >
            <small>{meta.bookAuthor}</small>
          </a>
        </div>
      </Column>
    )
  }
}

BookDetailWithApprovalColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookDetailWithApprovalColumnContainer.defaultProps = {
  item: {}
}

export default BookDetailWithApprovalColumnContainer
