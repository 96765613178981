import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

// 환불 컬럼을 구성한다. 
const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

// 환불 신청 계좌 정보
class RefundAccountColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    this.setState({ datas: [] })
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item } = this.props

    const meta = {}
    meta.refundPrice =_.get(item, 'content.refundPrice') || 0
    meta.bankName =_.get(item, 'content.bankName') || '은행'
    meta.walletOwner =_.get(item, 'content.walletOwner') || '예금주 미기재'
    meta.walletAddress =_.get(item, 'content.walletAddress') || '계좌 미기재'

    return (
      <Column>
        <div>
          <small style={{ marginRight: '0.35rem' }}><strong>{meta.walletOwner}</strong></small>
          <small>{meta.bankName}</small>
        </div>
        <div>
          <strong>{meta.walletAddress}</strong>
        </div>
      </Column>
    )
  }
}

RefundAccountColumnContainer.propTypes = {
  item: PropTypes.object,
}

RefundAccountColumnContainer.defaultProps = {
  item: {}
}

export default RefundAccountColumnContainer
