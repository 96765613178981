import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import SolutionStoreCategoryModal from './Setups/SolutionStoreCategoryModal'

import { Header, Task, Items, DataSet, Lnr, Pager, ProgressModal } from './utils'

const { REACT_APP_CLIENT_URL, REACT_APP_FILE_URL } = process.env

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'DIdxColumn', header: '순번', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'IconColumn', header: '아이콘', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'NameColumn', header: '경로', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DesktopColumn', header: 'PC', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'MobileColumn', header: '모바일', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'TextColumn', header: '명칭', style: { minWidth: '300px', maxWidth: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DescriptionColumn', header: '설명', style: { flex: '1 1 auto', maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'SortOrderColumn', header: '순서변경', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  const [form, setForm] = React.useState({})

  // 만들면, 삭제가 불가능한 네임들
  const staticNames = ['index', 'coverDesignLimited', 'coverDesignNormal', 'textDesign', 'textCorrection', 'etc']

  // 로우 작동 액션들
  const actions = {}

  // 작가서비스 카테고리 생성 & 수정
  actions.doOpenSolutionStoreCategoryModal = async (item = {}) => {
    const modalProps = { name: 'SolutionStoreCategoryModal' }
    modalProps.props = { name: 'SolutionStoreCategoryModal', name: item.name || null, onClose: async () => [setModal(null), await initialize()] }
    setModal(modalProps)
  }

  // 작가서비스 카테고리 상태 업데이트 구문
  actions.doUpdateSolutionStoreCategoryStatus = async (item = {}, status) => {
    if (!window.confirm(`해당 카테고리 상태값을 변경할까요?`)) { return false }
    const form = {}
    form.status = status
    const result = await api.put(`/resources/admin2/solutionStoreCategories/${item.name}`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `상태를 변경하던 중 문제가 발생했습니다.`) }
    alert(`성공적으로 반영하였습니다.`)
    return initialize()
  }

  // 작가서비스 카테고리 노출순서 업데이트 구문
  actions.doUpdateSolutionStoreCategorySortOrder = async (item = {}, sortOrder = -1) => {
    if (!window.confirm(`해당 카테고리 상태값을 변경할까요?`)) { return false }
    const form = {}
    form.sortOrder = sortOrder
    const result = await api.put(`/resources/admin2/solutionStoreCategories/${item.name}`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `상태를 변경하던 중 문제가 발생했습니다.`) }
    alert(`성공적으로 반영하였습니다.`)
    return initialize()
  }

  // 작가서비스 카테고리 삭제처리 구문
  actions.doDeleteSolutionStoreCategory = async (item = {}) => {
    if (staticNames.includes(item.name)) { alert('삭제가 불가능합니다.'); return false }
    if (!window.confirm(`이미 사용중인 도서가 존재한다면 문제가 발생할 수 있습니다. 삭제를 진행할까요?`)) { return false }
    const result = await api.delete(`/resources/admin2/solutionStoreCategories/${item.name}`)
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `상태를 변경하던 중 문제가 발생했습니다.`) }
    alert(`성공적으로 삭제하였습니다.`)
    return initialize()
  }

  // 개발중 테스트 처리
  // React.useState(() => {
  //     const modalProps = { name: 'SolutionStoreCategoryModal', props: { name: 'SolutionStoreCategoryModal', onClose: async () => [setModal(null), await initialize()] } }
  //     setModal(modalProps)
  //   }, [])

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 작가서비스 카테고리가 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          {/* <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div> */}
        </Header.Search>
        <Header.Options>
          <a href="#doOpenModal" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal()]}>새 카테고리 만들기</a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={itemIdx}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const meta = {}

                    meta.desktopPath = `${REACT_APP_FILE_URL}/${_.get(item, `desktop.path`)}`
                    meta.mobilePath = `${REACT_APP_FILE_URL}/${_.get(item, `mobile.path`)}`

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            // case 'checkbox':
                            //   return (
                            //     <>
                            //       <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                            //     </>
                            //   )
                            case 'DIdxColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.dIdx + 1}
                                </div>
                              )
                              
                            case 'IconColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div style={{ textAlign: 'center', fontSize: '2em' }}>
                                    {item.icon ? <Lnr c={item.icon} /> : null}
                                    {!item.icon ? <Lnr c="cross" /> : null}
                                  </div>
                                  <div style={{ textAlign: 'center' }}>
                                    <small>{item.icon || '미지정'}</small>
                                  </div>
                                </div>
                              )

                            case 'TextColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{item.text || '미지정'}</strong>
                                </div>
                              )
                                
                            case 'DesktopColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    src={meta.desktopPath || `/sample.png`}
                                    style={{ height: '50px', display: 'block', margin: '0 auto' }}
                                    onError={(e) => {
                                      e.target.src = `/sample.png`
                                    }}
                                  />
                                </div>
                              )
                              
                            case 'MobileColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    src={meta.mobilePath || `/sample.png`}
                                    style={{ height: '50px', display: 'block', margin: '0 auto' }}
                                    onError={(e) => {
                                      e.target.src = `/sample.png`
                                    }}
                                  />
                                </div>
                              )
                              
                            case 'NameColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{item.name || '지정하지 않았습니다.'}</strong>
                                </div>
                              )
                              
                            case 'DescriptionColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenSolutionStoreCategoryModal(item)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{item.description || '지정하지 않았습니다.'}</strong>
                                </div>
                              )
                              
                            case 'StatusColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <select
                                    value={item.status}
                                    style={{ flex: '1 1 100%', marginRight: '0.35rem' }}
                                    onChange={e => {
                                      const value = e.target.value
                                      const next = {}
                                      next[`items[${itemIdx}].status`] = value
                                      handle(next)
                                    }}
                                  >
                                    <option value={`activated`}>사용중</option>
                                    <option value={`deactivated`}>사용안함</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateSolutionStoreCategoryStatus(item, item.status)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            // 순서관리
                            case 'SortOrderColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    step={1}
                                    value={item.sortOrder || -1}
                                    style={{ flex: '1 1 100%', marginRight: '0.35rem' }}
                                    onChange={e => {
                                      const value = e.target.value
                                      const next = {}
                                      next[`items[${itemIdx}].sortOrder`] = value
                                      handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateSolutionStoreCategorySortOrder(item, item.sortOrder)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            case 'ControlColumn':
                              return (
                                <div>
                                  {!staticNames.includes(item.name)
                                    ? (
                                      <button
                                        type="button"
                                        className="button"
                                        onClick={async (e) => {
                                          return [e.stopPropagation(), e.preventDefault(), await actions.doDeleteSolutionStoreCategory(item)]
                                        }}
                                      >
                                        삭제
                                      </button>
                                    )
                                    : null}
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>아직 작가서비스 카테고리가 배치되지 않았습니다.</header>
          <section>노출처리하지 않으면 작가서비스샵의 탭에 보여지지 않습니다. 🐦</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

      <div>
        부크크 작가서비스샵(작가샵) 예약어
      </div>
      <ul>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>index</strong>
          <span>공홈 상단부 배너를 의미하며, index 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore`} target="_blank">미리보기</a></span>
        </li>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>coverDesignLimited</strong>
          <span>고급표지 디자인키값을 의미하며, coverDesignLimited 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore/categories/coverDesignLimited`} target="_blank">미리보기</a></span>
        </li>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>coverDesignNormal</strong>
          <span>표지 디자이너키값을 의미하며, coverDesignNormal 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore/categories/coverDesignNormal`} target="_blank">미리보기</a></span>
        </li>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>textDesign</strong>
          <span>내지 디자인키값을 의미하며, textDesign 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore/categories/textDesign`} target="_blank">미리보기</a></span>
        </li>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>textCorrection</strong>
          <span>교정교열 키값을 의미하며, textCorrection 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore/categories/textCorrection`} target="_blank">미리보기</a></span>
        </li>
        <li>
          <strong style={{ marginRight: '0.5rem' }}>etc</strong>
          <span>교정교열 키값을 의미하며, etc 코드를 입력해야 연동됩니다. 반드시 존재해야 합니다. <a href={`${REACT_APP_CLIENT_URL}/solutionStore/categories/etc`} target="_blank">미리보기</a></span>
        </li>
      </ul>

      {modal && modal.name ? <SolutionStoreCategoryModal {...modal.props} /> : null}
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '작가서비스 카테고리',
  nav: 'solutionStoreCategories'
}

export default List
