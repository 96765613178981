import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

class AddressColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {}
    meta.receiver =_.get(item, 'content.receiver') || '미기재'
    meta.address1 =_.get(item, 'content.address1') || '동주소 정보 없음'
    meta.address2 =_.get(item, 'content.address2') || '상세 주소 정보 없음'
    meta.zipCode =_.get(item, 'content.zipCode') || '00000'

    return (
      <Column>
        <div
          style={{ cursor: 'pointer', marginBottom: '0.35rem' }}
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            await navigator.clipboard.writeText(`${meta.receiver || ''}`)
          }}
        >
          <span style={{ marginRight: '0.15rem' }}>📮</span>
          <strong style={{ fontSize: '1.15em', fontWeight: 900 }}>{meta.receiver}</strong>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            const text = `(${meta.zipCode}) ${meta.address1} ${meta.address2}`
            await navigator.clipboard.writeText(text)
          }}
        >
          <small style={{ marginRight: '0.25rem' }}>(우){meta.zipCode}</small>
          <strong style={{ marginRight: '0.25rem' }}>{meta.address1}</strong>
          <strong>{meta.address2}</strong>
        </div>
      </Column>
    )
  }
}

AddressColumnContainer.propTypes = {
  item: PropTypes.object,
}

AddressColumnContainer.defaultProps = {
  item: {}
}

export default AddressColumnContainer
