import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
export { Lnr }

export const Menu = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; margin: -0.25rem;
`

Menu.Items = styled.nav`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; display: flex; overflow-x: auto; padding: 0.75rem 0.25rem;
  @media screen and (max-width: 960px) { overflow-x: auto; }
`

Menu.Item = styled.a`
  position: relative; box-sizing: border-box;
  flex: 1 1 auto; min-width: min-content; max-width: min-content; text-align: center; white-space: pre; padding: 0.75rem 3.5rem; font-weight: 700;
  text-decoration: none; font-family: ${font('primary')}; font-size: 1.2em; color: ${palette('muted', 5)}; 
  border-bottom: 1px solid transparent; border-bottom: 1px solid ${palette('muted', 12)};
  &:hover { color: ${palette('darkblue', 5)}; }
  &.active {
    color: ${palette('muted', 1)}; font-weight: 900;
    border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;
    border: 1px solid ${palette('muted', 12)}; border-bottom: 1px solid transparent;
  }
  @media screen and (max-width: 960px) { min-width: max-content; }
`
