import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'

import moment from 'services/moment'

import Modal from 'components/utils/Modal'

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.65rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0; margin: 0 -0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
`

Form.Footer = styled.aside`
  position: relative; box-sizing: border-box;
  padding: 1rem;
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem;
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
`

// 신규로 정산내역을 관리자가 임의로 넣어주는 기능
class RetailsModalContainer extends React.Component {
  constructor(props) {
    super(props)
    
    // 초기화구간
    this.initializedState = {
      setups: {},
      form: {
        accountId: '',
        retail: 'bookk',
        profitType: 'paperBook',
        subject: '',
        description: '',
        eachProfit: 0
      }
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.loadSetup = this.loadSetup.bind(this)
    this.initialize = this.initialize.bind(this)
    this.doSave = this.doSave.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/retails.json)
  async loadSetup() {
    return await api.get(`/payUploads/admin2/retails`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return JSON.parse(JSON.stringify(this.initializedSetup)) }
        return data
      })
      .catch(e => JSON.parse(JSON.stringify(this.initializedSetup)))
  }

  async initialize() {
    const setup = await this.loadSetup()
    await new Promise((r) => this.setState({ loading: false, setup }, r))
  }

  // 서버측으로 신규 내역을 저장하는 기능
  async doSave() {
    const { onClose } = this.props
    const { form } = this.state

    const payload = { form }
    const result = await api.post('/profits/admin2/depositPendingByManual', payload).catch(e => null)
    if (!result || result.error) { return alert((result && result.error) ? `${result.message}` : ``) }

    alert(`성공적으로 추가했습니다.`)
    return onClose()
  }

  // 랜더링
  render() {
    const { onClose } = this.props
    const { loading, setup, form } = this.state
    if (loading) { return null }

    const retails = setup ? Object.values(setup) : []

    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '380px', minWidth: '380px', width: '380px' }}>
          <Form.Header>신규 수익금 추가</Form.Header>
          <Form.Grid>

            <Form.Content>
              <Form.Field>
                <div className="control">
                  <input
                    type="text"
                    placeholder="계정명"
                    value={form.accountId}
                    onChange={e => {
                      const next = { ...form }
                      next.accountId = e.target.value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <select
                    value={form.profitType}
                    onChange={e => {
                      const value = e.target.value
                      if (!value) { return }
                      const next = { ...form }
                      next.profitType = value
                      return this.setState({ form: next })
                    }}
                  >
                    <option value="">수익금 비목선택</option>
                    <option value="paperBook">종이도서</option>
                    <option value="electronicBook">전자도서</option>
                    <option value="solution">작가서비스</option>
                    <option value="etc">기타</option>
                  </select>
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <select
                    value={form.retail}
                    onChange={e => {
                      const value = e.target.value
                      if (!value) { return }
                      const next = { ...form }
                      next.retail = value
                      return this.setState({ form: next })
                    }}
                  >
                    <option value="">수익발생 유통선택</option>
                    {retails.map((retail, rIdx) => {
                      return <option key={rIdx} value={retail.name}>{retail.text}</option>
                    })}
                  </select>
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <input
                    type="text"
                    placeholder="0원"
                    value={form.eachProfit}
                    onChange={e => {
                      const value = e.target.value
                      if (!value) { return }
                      const next = { ...form }
                      next.eachProfit = value
                      return this.setState({ form: next })
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      if (!value) { return }
                      const next = { ...form }
                      next.eachProfit = _.isInteger(value  * 1) ? value * 1 : 0
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <input
                    type="text"
                    placeholder="수익금 비목명"
                    value={form.subject}
                    onChange={e => {
                      const value = e.target.value
                      const next = { ...form }
                      next.subject = value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <textarea
                    placeholder='수익금 설명'
                    value={form.description}
                    onChange={e => {
                      const value = e.target.value
                      const next = { ...form }
                      next.description = value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
            </Form.Content>
              
            <Form.Footer>
              <div className="buttons">
                <button
                  type="button"
                  onClick={async (e) => {
                    return [e.stopPropagation(), e.preventDefault(), onClose()]
                  }}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="primary"
                  onClick={async (e) => {
                    return [e.stopPropagation(), e.preventDefault(), await this.doSave()]
                  }}
                >
                  생성
                </button>
              </div>
            </Form.Footer>

          </Form.Grid>
        </Form>
      </Modal>
    )
  }
}

export default RetailsModalContainer