import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/api'
import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'

import { Importer, Exporter } from 'services/transform'
import userExportParser from './export.user' // 외부유통 신청 엑셀다운로드 템플릿

import * as backdoor from 'services/backdoor'
import { comma } from 'services/utils'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import CreateEtcCartModal from './Setups/CreateEtcCartModal'
import MakeUserByManualModal from './Setups/MakeUserByManualModal'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, ProgressModal } from './utils'

const { REACT_APP_CLIENT_URL } = process.env

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, resource, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })

  columns.push({ key: 'UserNo', header: '회원번호', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'UserDetailColumn', header: '회원정보', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'flex-start' } })

  columns.push({ key: 'BrithdayColumn', header: '생년월일', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'isAdultColumn', header: '성인', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'ActivatedToEmailColumn', header: '이메일', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'ActivatedToMobileColumn', header: '모바일', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'ActivatedToIdentityColumn', header: '주번', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'ActivatedToBankAccountColumn', header: '계좌', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'ActivatedToBusinessColumn', header: '사업자', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })

  columns.push({ key: 'PaperBookCountColumn', header: '종이도서', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })
  columns.push({ key: 'ElectronicBookCountColumn', header: '전자도서', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })
  columns.push({ key: 'SolutionCountColumn', header: '서비스', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })

  columns.push({ key: 'CreatedDateColumn', header: '가입일', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })
  columns.push({ key: 'UsedProfileColumn', header: '마이홈', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' } })

  columns.push({ key: 'DepositTotalColumn', header: '총수익금', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'flex-end' } })
  columns.push({ key: 'WithdrawTotalColumn', header: '총지급금', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'flex-end' } })

  columns.push({ key: 'BlankColumn', header: '', style: { minWidth: '0', maxWidth: '100%', flex: '1 1 auto' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '360px', maxWidth: '360px', justifyContent: 'right' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)
  
  // 상품 타입에 대한 설정
  const cartTypes = {}
  cartTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  cartTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  cartTypes.solution = { name: 'solution', text: '작가서비스' }
  cartTypes.etc = { name: 'etc', text: '기타' }

  // 로우 작동 액션들
  const actions = {}

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const lump = query.limit || 100
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length} 블록으로 나누어 다운로드중...`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump // 리미트 구성
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }
  
  // 선택한 회원, 현재 페이지 엑셀다운로드 기능 : 2023.01.28 튜닝
  // await actions.downloadExcel(selecteds)
  actions.downloadExcel = async (items = selecteds) => {
    if (!items || items.length === 0) { return alert(`데이터가 없습니다.`) }

    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser: userExportParser, items })
      .download(`유저 ${items.length}건 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
  }

  // 해당 유저 마이그레이션 재시행
  actions.sendRequireCertifiation = async (item = {}) => {
    const form = { accountId: item.accountId }
    return await api.post(`/users/admin2/sendRequireCertifiation`, { form })
      .then((result) => result ? alert(result.message) : alert(`오류가 발생하였습니다.`))
      .catch((e) => alert(e.message))
  }

  // 특정유저로 로그인하는 기능 : 2023.01.28 튜닝
  actions.doLogin = async (item = {}) => {
    if (!window.confirm(`${item.id}(${item.userNo})\n해당 계정으로 로그인을 진행할까요?`)) { return }
    return backdoor.login(item.id, `${REACT_APP_CLIENT_URL}/carts/etc`)
  }

  // 특정유저의 비밀번호를 변경해주는 기능 : 2023.01.28 튜닝
  actions.doResetUserPassword = async (item = {}) => {
    if (!item.id) { return null }
    const nowAt = Date.now(), randSt = Math.ceil(Math.random() * 100)
    const tempPassword = `${randSt}${nowAt.toString(16)}`
    const newPassword = window.prompt(`변경할 비밀번호를 입력해주세요.`, tempPassword)
    if (!newPassword) { return alert(`변경할 비밀번호가 없어, 취소하였습니다.`) }

    return await api
      .put(`/users/admin2/${item.id}/resetUserPassword`, { password: newPassword })
      .then((res) => (alert(res.message) || true))
      .catch((e) => (alert(`문제가 발생하였습니다. (${e.message})`) || false))
  }

  // 해당 유저의 상세 내역으로 접속
  actions.doMoveUserDetail = async (item = {}) => {
    return window.open(`/users/${item.id}`)
  }

  // 특정유저의 보유도서로 접속
  actions.doMoveUserProducts = async (item = {}, productType = 'book') => {
    const query = { keywordsOption: 'accountId', keywords: item.accountId }
    const url = `/products/${productType}`
    return window.open(`${url}?${qs.stringify(query)}`)
  }

  // 특정유저의 수기수익금내역으로 이동하기
  actions.doMoveUserDepositProfits = async (item ={}) => {
    const query = { keywordsOption: 'accountId', keywords: item.accountId }
    const url = `/profits/deposits`
    return window.open(`${url}?${qs.stringify(query)}`)
  }

  // 특정유저의 수기수익금내역으로 이동하기
  actions.doMoveUserWithdrawProfits = async (item ={}) => {
    const query = { keywordsOption: 'accountId', keywords: item.accountId, filters_status: 'resolved' }
    const url = `/profits/withdraws`
    return window.open(`${url}?${qs.stringify(query)}`)
  }

  // 특정 문자열 복사 기능
  actions.doCopyText = async (text) => {
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // 개인결제 장바구니를 만들어주는 기능 : 2023.01.28 튜닝
  actions.doCreateEtcCartModal = async (item = {}) => {
    const modalProps = {}
    modalProps.name = `CreateEtcCartModal`
    modalProps.props = {
      parent: { item, initialize },
      onClose: () => [setModal(null), initialize()]
    }
    return setModal(modalProps)
  }

  // 새로운 유저를 만드는 모달창 : 2023.01.28 튜닝
  actions.doOpenMakeUserByManualModal = async () => {
    const modalProps = {}
    modalProps.name = `MakeUserByManualModal`
    modalProps.props = {
      onClose: () => [setModal(null), initialize()]
    }
    return setModal(modalProps)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}

      {modal && modal.name === 'CreateEtcCartModal' ? <CreateEtcCartModal {...(modal.props || {})} /> : null}
      {modal && modal.name === 'MakeUserByManualModal' ? <MakeUserByManualModal {...(modal.props || {})} /> : null}
      
      <Task>
        <strong>{comma(total)}명</strong>의 회원이 검색되었습니다.
      </Task>

      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#doOpenMakeUserModal"
            onClick={async (e) => [
              e.stopPropagation(),
              e.preventDefault(),
              await actions.doOpenMakeUserByManualModal()
            ]}
          >
            계정생성 🧙‍♂️
          </a>
          <a
            href="#downloadExcelBySelecteds"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              await actions.downloadExcel(selecteds)
            }}
          >
            선택엑셀 📥
          </a>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              if (total > 70000) { return alert(`🙋‍♂️ 7만개 이상 데이터의 경우 시스템 관리자에게 요청하세요.`) }

              const items = await actions.getItemsAll({}, { progress: true }).catch(e => [])
              await actions.downloadExcel(items)
              }}
            >
              전체엑셀 📥
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}

                    const meta = {}
                    meta.userNo = _.get(item, 'userNo') || '0'
                    meta.emoji = _.get(item, 'emoji') || '🦔'
                    meta.name = _.get(item, 'name')
                    meta.nickName = _.get(item, 'nickName')
                    meta.accountId = _.get(item, 'accountId') || '-'
                    meta.email = _.get(item, 'email') || '-'
                    meta.birthday = _.get(item, 'birthday') || '-'
                    meta.isAdult = _.get(item, 'isAdult') || false

                    meta.status = _.get(item, 'status') || 'temporary'
                    meta.paperBookProductCount = _.get(extras, 'paperBookProductCount') || 0
                    meta.electronicBookProductCount = _.get(extras, 'electronicBookProductCount') || 0
                    meta.solutionProductCount = _.get(extras, 'solutionProductCount') || 0
                    meta.depositTotalAmount = _.get(extras, 'depositTotalAmount') || 0
                    meta.withdrawTotalAmount = _.get(extras, 'withdrawTotalAmount') || 0
                    meta.usedProfile = _.get(item, 'usedProfile') || 'notUsed'

                    meta.activatedToEmail = _.get(item, 'activatedToEmail') ? true : false
                    meta.activatedToEmailMt = _.get(item, 'activatedToEmailAt')
                      ? moment(_.get(item, 'activatedToEmailAt'))
                      : null
                    meta.activatedToMobile = _.get(item, 'activatedToMobile') ? true : false
                    meta.activatedToMobileMt = _.get(item, 'activatedToMobileAt')
                      ? moment(_.get(item, 'activatedToMobileAt'))
                      : null
                    meta.activatedToIdentity = _.get(item, 'activatedToIdentity') ? true : false
                    meta.activatedToIdentityMt = _.get(item, 'activatedToIdentityAt')
                      ? moment(_.get(item, 'activatedToIdentityAt'))
                      : null
                    meta.activatedToBankAccount = _.get(item, 'activatedToBankAccount')
                    meta.activatedToBankAccountMt = _.get(item, 'activatedToBankAccountAt')
                      ? moment(_.get(item, 'activatedToBankAccountAt'))
                      : null
                    meta.activatedToBusiness = _.get(item, 'activatedToBusiness') ? true : false
                    meta.activatedToBusinessMt = _.get(item, 'activatedToBusinessAt')
                      ? moment(_.get(item, 'activatedToBusinessAt'))
                      : null
                    
                    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
                    meta.leavedMt = _.get(item, 'leavedAt') ? moment(_.get(item, 'leavedAt')) : null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input
                                      type="checkbox"
                                      checked={isExistsSelected(item)}
                                      onClick={e => [e.stopPropagation(), doCheck(item)]}
                                      readOnly
                                    />
                                </>
                              )

                            case 'UserNo':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [
                                      e.stopPropagation(),
                                      e.preventDefault(),
                                      await actions.doMoveUserDetail(item)
                                    ]
                                  }}
                                >
                                  <strong>{meta.userNo}</strong>
                                </div>
                              )
                            case 'StatusColumn':
                              return (
                                <div>
                                  {['normal'].includes(meta.status) ? <Caret c="success" title="정상" /> : null}
                                  {['temporary', 'blocked'].includes(meta.status) ? <Caret c="muted" title="이슈있음" /> : null}
                                  {['leaved'].includes(meta.status) ? <Caret c="danger" title="탈퇴" /> : null}
                                </div>
                              )
                            case 'UsedProfileColumn':
                              return (
                                <div>
                                  {['used'].includes(meta.usedProfile) ? <Caret c="success" title="공개됨" /> : null}
                                  {['notUsed'].includes(meta.usedProfile) ? <Caret c="muted" title="비공개" /> : null}
                                </div>
                              )
                            case 'UserDetailColumn':
                              return (
                                <div style={{ maxWidth: '100%', textOverflow: 'ellipsis',  overflow: 'hidden' }}>
                                  <span style={{ marginRight: '0.25rem' }}>{meta.emoji}</span>
                                  <strong
                                    style={{ marginRight: '0.35rem', cursor: 'pointer' }}
                                    onClick={async (e) => {
                                      return [
                                        e.stopPropagation(),
                                        e.preventDefault(),
                                        await actions.doCopyText(meta.accountId)
                                      ]
                                    }}
                                  >
                                    {meta.name || meta.accountId}
                                  </strong><br />
                                  <small
                                    style={{ cursor: 'pointer' }}
                                    onClick={async (e) => {
                                      return [
                                        e.stopPropagation(),
                                        e.preventDefault(),
                                        await actions.doCopyText(meta.email)
                                      ]
                                    }}
                                  >
                                    {meta.email}
                                  </small>
                                </div>
                              )
                            case 'isAdultColumn':
                              return (
                                <div>
                                  {meta.isAdult ? <Caret c="success" /> : null}
                                  {!meta.isAdult ? <Caret c="danger" /> : null}
                                </div>
                              )
                            case 'ActivatedToEmailColumn':
                              return (
                                <div title={meta.activatedToEmailMt ? meta.activatedToEmailMt.format('YYYY.MM.DD HH:mm') : `-`}>
                                  {meta.activatedToEmail ? <Caret c="success" /> : null}
                                  {!meta.activatedToEmail ? <Caret c="danger" /> : null}
                                </div>
                              )
                            case 'ActivatedToMobileColumn':
                              return (
                                <div title={meta.activatedToMobileMt ? meta.activatedToMobileMt.format('YYYY.MM.DD HH:mm') : `-`}>
                                  {meta.activatedToMobile ? <Caret c="success" /> : null}
                                  {!meta.activatedToMobile ? <Caret c="danger" /> : null}
                                </div>
                              )
                            case 'ActivatedToIdentityColumn':
                              return (
                                <div title={meta.activatedToIdentityMt ? meta.activatedToIdentityMt.format('YYYY.MM.DD HH:mm') : `-`}>
                                  {meta.activatedToIdentity ? <Caret c="success" /> : null}
                                  {!meta.activatedToIdentity ? <Caret c="danger" /> : null}
                                </div>
                              )
                            case 'ActivatedToBankAccountColumn':
                              return (
                                <div title={meta.activatedToBankAccountMt ? meta.activatedToBankAccountMt.format('YYYY.MM.DD HH:mm') : `-`}>
                                  {meta.activatedToBankAccount ? <Caret c="success" /> : null}
                                  {!meta.activatedToBankAccount ? <Caret c="danger" /> : null}
                                </div>
                              )
                            case 'ActivatedToBusinessColumn':
                              return (
                                <div title={meta.activatedToBusinessMt ? meta.activatedToBusinessMt.format('YYYY.MM.DD HH:mm') : `-`}>
                                  {meta.activatedToBusiness ? <Caret c="success" /> : null}
                                  {!meta.activatedToBusiness ? <Caret c="danger" /> : null}
                                </div>
                                )
                            case 'PaperBookCountColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [
                                      e.stopPropagation(),
                                      e.preventDefault(),
                                      await actions.doMoveUserProducts(item, 'paperBook')
                                    ]
                                  }}
                                >
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {comma(meta.paperBookProductCount)}
                                  </strong>
                                  <small>종</small>
                                </div>
                              )
                            case 'ElectronicBookCountColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [
                                      e.stopPropagation(),
                                      e.preventDefault(),
                                      await actions.doMoveUserProducts(item, 'electronicBook')
                                    ]
                                  }}
                                >
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {comma(meta.electronicBookProductCount)}
                                  </strong>
                                  <small>종</small>
                                </div>
                              )
                            case 'SolutionCountColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    return [
                                      e.stopPropagation(),
                                      e.preventDefault(),
                                      await actions.doMoveUserProducts(item, 'solution')
                                    ]
                                  }}
                                >
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {comma(meta.solutionProductCount)}
                                  </strong>
                                  <small>개</small>
                                </div>
                              )
                            case 'CreatedDateColumn':
                              return (
                                <div>
                                  {meta.createdMt
                                    ? `${meta.createdMt.format('YYYY.MM.DD')}`
                                    : '-'}
                                </div>
                              )
                            case 'BrithdayColumn':
                              return (
                                <div>
                                  <strong>{meta.birthday}</strong>
                                </div>
                              )

                            case 'DepositTotalColumn':
                              return (
                                <div>
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {comma(meta.depositTotalAmount)}
                                  </strong>
                                  <small>원</small>
                                </div>
                              )

                            case 'WithdrawTotalColumn':
                              return (
                                <div>
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {comma(meta.withdrawTotalAmount)}
                                  </strong>
                                  <small>원</small>
                                </div>
                              )

                            case 'BlankColumn':
                              return (<div></div>)

                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.35rem' }}
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      e.preventDefault()

                                      return await actions
                                        .sendRequireCertifiation(item)
                                        .catch(e => alert(e.message))
                                    }}
                                  >
                                    인증요청
                                  </button>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.35rem' }}
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      e.preventDefault()

                                      return await actions
                                        .doLogin(item)
                                        .catch(e => alert(e.message))
                                    }}
                                  >
                                    로그인
                                  </button>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.35rem' }}
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      e.preventDefault()

                                      return actions
                                        .doResetUserPassword(item)
                                        .catch(e => alert(e.message))
                                    }}
                                  >
                                    비번
                                  </button>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      e.preventDefault()

                                      return await actions.doCreateEtcCartModal(item)
                                        .catch(e => alert(e.message))
                                    }}
                                  >
                                    개인결제
                                  </button>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>검색어를 변경해보거나, 필터 값을 확인해보세요.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  resource: PropTypes.object,
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  resource: {},
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '전체회원',
  nav: 'users'
}

export default List
