import React from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import _ from 'lodash'
import api, { checkStatus } from 'services/api'

import { Snippet, Items, Item, Search, Description, Pagination, Lnr } from './utils'

class UserFindAddressSnippetContainer extends React.Component {

  constructor(props) {
    super(props)

    this.form = React.createRef()
    this.ctrl = React.createRef()

    this.initialState = this.initialState.bind(this)
    this.loadItems = this.loadItems.bind(this)

    this.doExit = this.doExit.bind(this)
    this.doSave = this.doSave.bind(this)

    this.state = this.initialState(props)
  }

  initialState(props) {
    const state = { keywords: [], total: 0, items: [], page: 1, limit: 6, selected: {} }
    return state
  }

  async loadItems() {
    const confmKey = process.env.REACT_APP_JUSO_API_KEY
    const url = process.env.NODE_ENV === 'production'
      ? 'https://www.juso.go.kr/addrlink/addrLinkApiJsonp.do'
      : 'http://www.juso.go.kr/addrlink/addrLinkApiJsonp.do'

    return await (new Promise((resolve, reject) =>
      this.setState({ loading: true, total: 0, items: [] }, () => {
        if (!this.state.keywords) { return reject({}) }
        resolve({
          confmKey,
          keyword: this.state.keywords,
          currentPage: this.state.page,
          countPerPage: this.state.limit,
          resultType: 'json',
        })
      }))
    )
      .then(query => {
        return api.fetch(`${url}?${qs.stringify(query)}`, {})
          .then(checkStatus)
          .then(response => response.text().then(text => eval(text)))
      })
      .then(({ results }) => {
        const { common, juso } = results
        return this.setState({
          total: common && common.totalCount ? common.totalCount : 0,
          items: juso || []
        })
      })
      .catch((error) => {
        this.setState({ total: 0, items: [] })
      })
  }

  async doExit() {
    this.setState(this.initialState(this.props), () => this.props.closeSider())
  }

  async doSave() {
    const { selected } = this.state
    this.props.onSelected(selected)
    return this.props.closeSider()
  }

  render() {
    const { keywords, items, selected, total, page, limit } = this.state

    return (
      <Snippet>
        <Snippet.Header>
          <Snippet.Header.Lead>주소 검색</Snippet.Header.Lead>
        </Snippet.Header>
        <Snippet.Body>
          <Search ref={this.form} onSubmit={async (e) => [e.preventDefault(), this.loadItems()]}>
            <input
              ref={this.ctrl} type="text" value={keywords} placeholder={`동 또는 건물명`}
              onChange={e => this.setState({ keywords: e.target.value, selected: {}, page: 1 })}
            />
          </Search>

          {(() => {
            if (!items.length) { return null }
            return (
              <>

                <Items>
                  {items.map((item, index) => {
                    return (
                      <Item
                        key={`key_${index}`} className={selected.roadAddr === item.roadAddr ? `active` : null}
                        onClick={e => this.setState({ selected: item })}
                      >
                        <div>
                          <small>{item.zipNo}</small>
                        </div>
                        <div className="address">{item.roadAddr}</div>
                      </Item>
                    )
                  })}
                </Items>

                <Pagination>
                  {page !== 1
                    ? (
                      <a href="#prevPage" onClick={e => [e.stopPropagation(), e.preventDefault(), this.setState({ page: page - 1 }, () => this.loadItems())]}>
                        <Lnr c="chevron-left" />
                      </a>
                    )
                    : null}
                  {Math.ceil(total/limit) > 1 && Math.ceil(total/limit) !== page
                    ? (
                      <a href="#nextPage" onClick={e => [e.stopPropagation(), e.preventDefault(), this.setState({ page: page + 1 }, () => this.loadItems())]}>
                        <Lnr c="chevron-right" />
                      </a>
                    )
                    : null}
                </Pagination>
              </>
            )
          })()}

          <Description>
            <div>동 또는 건물명을 검색해주세요.</div>
          </Description>
        </Snippet.Body>
        <Snippet.Footer>
          <Snippet.Buttons>
            <Snippet.Button onClick={e => this.doExit()}>닫기</Snippet.Button>
            <Snippet.Button className="primary" onClick={e => this.doSave()}>선택완료</Snippet.Button>
          </Snippet.Buttons>
        </Snippet.Footer>
      </Snippet>
    )
  }
}

UserFindAddressSnippetContainer.propTypes = {
  onSelected: PropTypes.func,
  closeSider: PropTypes.func,
}

UserFindAddressSnippetContainer.defaultProps = {
  onSelected: () => {},
  closeSider: () => {}
}

export default UserFindAddressSnippetContainer
