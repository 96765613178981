import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import DataSet from 'components/utils/DataSet'

const Page = styled.article`
  position: relative; box-sizing: border-box;
  font-family: ${font('primary')};
  padding: 1rem; 
`

Page.Header = styled.header`
  position: relative; box-sizing: border-box;
  font-family: ${font('primary')};
  padding: 1rem; font-size: 1.618em; font-weight: 900;
`

Page.Tools = styled.section`
  position: relative; box-sizing: border-box;
  font-family: ${font('primary')}; padding: 2rem 1rem;
  display: flex; justify-content: space-between;
`

Page.Button = styled.a`
  position: relative; box-sizing: border-box;
  font-family: ${font('primary')}; font-size: 1.0618em;
  border-radius: 0.381rem; text-decoration: none;
  box-sizing: border-box; padding: 0.381rem 1.2168rem;
  border: 1px solid ${palette('muted', 4)}; background: ${palette('muted', 21)};
  color: ${palette('muted', 4)}; transition: all 0.3s;
  &:hover {
    border: 1px solid ${palette('muted', 1)}; background: ${palette('muted', 21)};
    color: ${palette('muted', 1)};
  }

`

export { Page, DataSet }