import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// extras에 의존성 있는 유저정보
class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { item: {} }

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  // 이미 보유한 item.currentUser 에서 가져온다.
  async initialize() {
    // this.setState({ item: currentUser })
  }
  
  render() {
    // const { item } = this.state
    // if (!item.id) { return null } 
    const { item = {} } = this.props
    
    const extras = _.get(item, 'extras') || {}

    const meta = {}
    meta.user = _.get(extras, 'currentUser') || {}

    meta.accountId = _.get(meta.user, 'accountId') || '알수없음'
    meta.email = _.get(meta.user, 'email') || ''

    if (!meta.user) {
      return (  
        <Column>
          손님의 체험한 정보
        </Column>
      )
    }

    return (
      <Column>
        <Meta.Group>
          <Meta>
            <header>
              <small>계정명</small>
            </header>
            <section
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                await navigator.clipboard.writeText(meta.accountId)
                // alert(`${meta.accountId} 가 복사되었습니다.`)
              }}
              style={{ cursor: 'pointer' }}>
              <strong>{meta.accountId}</strong>
            </section>
          </Meta>
        </Meta.Group>
        <Meta.Group>
          <Meta style={{ minWidth: '0', maxWidth: '100%' }}>
            <header>
              <small>이메일</small>
            </header>
            <section>
              <strong
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  await navigator.clipboard.writeText(meta.email)
                  // alert(`${meta.email} 가 복사되었습니다.`)
                }}
                style={{ cursor: 'pointer' }}>
                {meta.email}
              </strong>
            </section>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
