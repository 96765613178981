import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import { comma } from 'services/utils'

import StockLogModal from './Setups/StockLogModal'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Pager, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {

  if (!items.length) { items.push({  }) }

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'FCodeColumn', header: 'F-CODE', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'NoColumn', header: '순번', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'CreatedAtColumn', header: '요청일자', style: { minWidth: '160px', maxWidth: '160px', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '제작상태', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BookDetailColumn', header: '작업명', style: { flex: '1 1 100%', minWidth: 0, display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'AmountColumn', header: '요청량', style: { minWidth: '260px', maxWidth: '260px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PrintingFilesColumn', header: '제작발주폴더 파일명', style: { minWidth: '460px', maxWidth: '460px', justifyContent: 'flex-start', textAlign: 'left', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DropboxFilesColumn', header: '드롭박스', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', textAlign: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const productTypes = {}
  productTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  productTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  productTypes.solution = { name: 'solution', text: '작가서비스' }
  productTypes.etc = { name: 'etc', text: '기타' }

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}

  // 재고현황정보를 변경하는 기능을 의미한다.
  actions.doUpdateStock = async (item = {}) => {
    if (!window.confirm(`강제로 현황을 변경해도 재고기록에는 남지만 발주내역은 추가되지 않습니다. 진행할까요?`)) { return }
    const stock = _.get(item, 'extras.currentStock') || {}
    if (!_.isInteger(stock.invoice * 1)) { return alert(`필요입고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.overflow * 1)) { return alert(`추가입고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.instock * 1)) { return alert(`보유재고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.forwarding * 1)) { return alert(`출고예정량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.outstock * 1)) { return alert(`누적출고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.destroy * 1)) { return alert(`폐기량 숫자가 올바르지 않습니다.`) }

    const form = {}
    form.invoice = stock.invoice
    form.overflow = stock.overflow
    form.instock = stock.instock
    form.forwarding = stock.forwarding
    form.outstock = stock.outstock
    form.destroy = stock.destroy

    const result = await api.put(`/stocks/admin2/${stock.id}/stockAmounts`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `재고현황을 변경하던 도중 문제가 발생했습니다.`) }

    alert(`성공적으로 변경하였습니다.`)
    return await initialize()
  }

  // 재고현황 모달창 열기
  actions.doOpenStockLogModal = async (item ={}) => {
    // 현재 연동된 재고현황 내역을 불러온다.
    const extras = item.extras || {}
    const stockId = _.get(extras, 'currentStock.id')
    if (!stockId) { return alert(`해당 상품의 재고관려ID가 누락되어있습니다. 관리자에게 문의해주세요.`) }

    // 모달을 연다.
    const modalProps = {}
    modalProps.name = 'StockLogModal'
    modalProps.props = { stockId, onClose: () => [setModal(null), initialize()] }

    return setModal(modalProps)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      {modal && modal.name === 'StockLogModal' ? <StockLogModal {...(modal.props || {})} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 생산요청서가 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
              href="#"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return
              }}>
              📥 엑셀 다운로드
          </a>
          <a
              href="#"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return
              }}>
              🚧 작업중
          </a>
          <a
              href="#"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return
              }}>
              🟥 작업취소
          </a>
          <a
              href="#"
              className="primary"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return
              }}>
              ✅ 작업완료
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      <Items.Body>

        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
            </DataSet.Item>
          </DataSet.Header>
          <DataSet.Body>
            {items.map((item, itemIdx) => (
              <DataSet.Item key={item.id}>
                {columns.map((column, columIdx) => {
                  const columnProps = { column, columIdx, item, loadItems }

                  const extras = item.extras || {}

                  // extras.product // @ 필수

                  const meta = {}

                  meta.oid = item._id || item.id
                  meta.code = item.code
                  meta.no = item.no
                  meta.createdMt = item.createdAt ? moment(item.createdAt) : null
                  meta.subject = item.subject || '작업명 미기재'


                  meta.productId = _.get(item, 'id') || null
                  meta.productType = _.get(item, 'productType')
                    ? productTypes[_.get(item, 'productType')]
                    : productTypes.etc

                  meta.alivedStatus = _.get(item, 'deleted') ?`⛔` : ``

                  meta.userId = _.get(item, 'extras.currentUser.id')
                  meta.userAccountId = _.get(item, 'extras.currentUser.accountId')
                  meta.userNo = _.get(item, 'extras.currentUser.userNo')
                  meta.productName = _.get(item, 'name')
                  meta.bookNo = _.get(item, 'content.bookNo')
                  meta.bookNo = _.get(item, 'content.bookNo')
                  meta.isbn = _.get(item, 'content.isbn')
                  meta.bookPurpose = _.get(item, 'content.purpose')
                    ? _.get(item, 'content.purpose')
                    : { text: '알수없음' }
                  meta.productPrice = 0
                  if (['paperBook', 'electronicBook'].includes(_.get(item, 'productType'))) { meta.productPrice = _.get(item, 'content.basicPrice') || 0 }
                  if (['solution'].includes(_.get(item, 'productType'))) { meta.productPrice = _.get(item, 'content.defaultPrice') || 0 }
                  meta.produce = `알수없음`
                  if (['inhouse'].includes(_.get(item, 'produce'))) { meta.produce = '자체' }
                  if (['outsourcing'].includes(_.get(item, 'produce'))) { meta.produce = '외부' }

                  // 주문량, 필요입고량, 추가입고량, 재고, 총보유, 출고예정, 출고, 폐기
                  meta.parcelStates = _.get(item, 'extras.parcelStates') || { count: 0, totalAmount: 0 }
                  meta.invoiceAmount = _.get(item, 'extras.currentStock.invoice') || 0
                  meta.overflowAmount = _.get(item, 'extras.currentStock.overflow') || 0
                  meta.instockAmount = _.get(item, 'extras.currentStock.instock') || 0
                  meta.forwardingAmount = _.get(item, 'extras.currentStock.forwarding') || 0
                  meta.outstockAmount = _.get(item, 'extras.currentStock.outstock') || 0
                  meta.destroyAmount = _.get(item, 'extras.currentStock.destroy') || 0
                  meta.totalStockAmount = meta.invoiceAmount + meta.overflowAmount + meta.instockAmount

                  return (
                    <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                      {(key => {
                        switch (key) {
                          case 'checkbox':
                            return (
                              <>
                                <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                              </>
                            )
                          case 'FCodeColumn':
                            return (
                              <div>
                                <div><strong>{meta.code || 'F00000000000000'}</strong></div>
                                <div><small>{meta.oid || '-'}</small></div>
                              </div>
                            )
                          case 'NoColumn':
                            return (
                              <div>
                                <strong style={{ fontWeight: 900, fontSize: '1.6rem' }}>{meta.no || '1'}</strong>
                              </div>
                            )
                          case 'CreatedAtColumn':
                            return (
                              <div>
                                <div>
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}
                                  </strong>
                                </div>
                                <div><small>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small></div>
                              </div>
                            )
                          case 'StatusColumn':
                            return (
                              <div>
                                <span style={{ marginRight: '0.35rem' }}>🚧</span>
                                <strong>작업중</strong>
                              </div>
                            )
                          case 'BookDetailColumn':
                            return (
                              <div
                                onClick={(e) => {
                                  const id = _.get(extras.product, 'id')
                                  const productType = _.get(extras.product, 'productType')
                                  return window.open(`/products/${productType}/${id}`)
                                }}
                                style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                              >
                                <div style={{ padding: '0.35rem 0' }}>
                                  <strong style={{ fontWeight: 900, fontSize: '1.3168em' }}>{meta.subject}</strong>
                                </div>
                                <div>
                                  <small>
                                    <strong>{_.get(extras.product, 'id') || '상품고유키값 미존재'}</strong>
                                    <small style={{ marginLeft: '0.35rem' }}>{_.get(extras.product, 'content.bookNo') || '0000'}</small>
                                  </small>
                                </div>
                              </div>
                            )
                          case 'AmountColumn':
                            return (
                              <div>
                                <strong style={{ fontSize: '1.6em', fontWeight: '900' }}>
                                  <span style={{ textDecoration: 'underline', marginRight: '0.35rem' }}>63</span>
                                  <small>P</small>
                                </strong>
                                <span style={{ margin: '0 0.75rem' }}>·</span>
                                <strong style={{ fontSize: '1.6em', fontWeight: '900' }}>CS + IMP</strong>
                              </div>
                            )
                            
                          case 'PrintingFilesColumn':
                            return (
                              <div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    CV
                                  </button>
                                  <span>✅ 표지 파일명.912903192382302.pdf</span>
                                </div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    CS
                                  </button>
                                  <span>✅ [CS]표지 파일명.912903192382302.pdf</span>
                                </div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    IMP
                                  </button>
                                  <span>✅ [IMP]표지 파일명.912903192382302.pdf</span>
                                </div>
                              </div>
                            )
                            
                          case 'DropboxFilesColumn':
                            return (
                              <div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    CV
                                  </button>
                                </div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    CS
                                  </button>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    IMP
                                  </button>
                                </div>
                                <div style={{ padding: '0.35rem 0' }}>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    SR
                                  </button>
                                  <button
                                    type="button"
                                    className="button"
                                    style={{ marginRight: '0.4618rem' }}
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault()]
                                    }}
                                  >
                                    TR
                                  </button>
                                </div>
                              </div>
                            )

                          default:
                        }
                      })(column.key)}
                    </DataSet.Col>
                  )
                })}
              </DataSet.Item>
            ))}
      
            {!items.length ? (
              <Items.NotFound>
                <header>생산요청서가 존재하지 않습니다.</header>
                <section>기간 또는 검색어가 올바른지 확인해주세요.</section>
              </Items.NotFound>
            ) : null}
          </DataSet.Body>
        </DataSet>

      </Items.Body>

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '생산요청서',
  nav: 'tasks'
}

export default List
