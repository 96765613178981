import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & .statusBtn {
    padding: 0.5rem; border-radius: 0.35rem; font-weight: 500;
    transition: all 0.3s; cursor: pointer; margin: 0.25rem 0;
    background: ${palette('muted', 18)}; color: ${palette('muted', 3)};
    &:hover { background: ${palette('muted', 1)}; color: ${palette('muted', 21)}; }

    &.wait {
      background: ${palette('primary', 13)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 15)}; color: ${palette('muted', 21)}; }
    }
    &.review {
      background: ${palette('primary', 8)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 10)}; color: ${palette('muted', 21)}; }
    }
    &.reject {
      background: ${palette('danger', 10)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('danger', 18)}; color: ${palette('muted', 21)}; }
    }
    &.accept {
      background: ${palette('primary', 5)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 15)}; color: ${palette('muted', 21)}; }
    }
    &.cancel {
      background: ${palette('muted', 18)}; color: ${palette('muted', 3)};
      &:hover { background: ${palette('muted', 1)}; color: ${palette('muted', 21)}; }
    }
  }
`

const ModalForm = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; padding-top: 0;
  
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1.5rem 0; font-size: 1.3em;
    font-weight: 700;
  }

  & > section {
    position: relative; box-sizing: border-box;
    & > div { padding: 0.5rem 0; }
  }

  & > footer {
    position: relative; box-sizing: border-box;
    padding-top: 1rem;
  }

  & input[type=text],
  & input[type=date],
  & input[type=number] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 5)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & input[type=file] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & select {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem; font-weight: 500;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & textarea {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.75rem; line-height: 1.615rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & a.button {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    background: ${palette('darkblue', 8)}; color: ${palette('muted', 21)};
    border: 0; border-radius: 0.35rem; font-weight: 700; text-decoration: none;
    text-align: center; padding: 0.75rem 0; font-size: 1em;
    &:hover { background: ${palette('darkblue', 15)}; color: ${palette('muted', 21)}; }
  }
`
// 상태 값을 바꾸어주는 모달창
const UpdateApprovalStatusModal = ({
  item = {},
  isOpen = false,
  onClose = () => {},
  parent = { loadItems: async () => {} },
}) => {

  const initForm = { status: item.status || '', comment: item.comment || '' }
  const [form, setForm] = React.useState(initForm)

  const modalProps = { isOpen, onClose }

  const doSubmit = async (form) => {
    if (!form.status) { return alert(`상태 변경 값을 지정해줘야합니다.`) }

    // 데이터 값 저정해주기 (comment 데이터 넘기기)
    const data = {
      comment: form.comment
    }

    return await api.put(`/approvals/admin2/${item.id}/bookRetail/${form.status}`, data)
      .then(async ({ error, message }) => {
        if (error) {
          alert(`처리 도중 문제가 발생하였습니다. (${message})`)
          return false
        }
        await parent.loadItems()
        alert(`성공적으로 처리하였습니다.`)
        onClose()
      })
      .catch(e => {
        alert(`데이터 저장중 문제가 발생하였습니다. (${e.message})`)
        return false
      })
  }
  
  return (
    <Modal {...modalProps}>
      <ModalForm>
        <header>
          단계변경
        </header>
        <section>
          <div>
            <select
              value={form.status}
              onChange={e => {
                const value = e.target.value || ''
                return setForm({ ...form, status: value, comment: '' })
              }}
            >
              <option value='"'>처리단계 선택</option>
              <option value="review">처리중</option>
              <option value="reject">반려</option>
              <option value="accept">처리완료</option>
              <option value="cancel">취소</option>
            </select>
          </div>
          <div>
            <textarea
              placeholder="반려사유 또는 전달할 메시지를 기재 할 수 있습니다."
              value={form.comment}
              onChange={e => {
                const value = e.target.value || ''
                return setForm({ ...form, comment: value })
              }}
              style={{ height: '200px' }}
            />
          </div>
        </section>
        <footer>
          <a
            href="#save"
            className="button"
            style={{ minWidith: '100%', maxWidith: '100%', width: '100%' }}
            onClick={(e) => [e.stopPropagation(), e.preventDefault(), doSubmit(form)]}
          >
            반영완료
          </a>
        </footer>
      </ModalForm>
    </Modal>
  )
}

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class StatusColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { modal: {} }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const that = this
    const { modal } = this.state
    const { item, loadItems } = this.props

    const meta = {}

    return (
      <Column>
        <div
          className={`statusBtn ${item.status}`}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={e => {
            if (['wait', 'review', 'accept', 'reject'].includes(item.status)) {
              return [e.stopPropagation(), e.preventDefault(), that.setState({ modal: { name: 'UpdateApprovalStatusModal' } })]
            } else {
              return alert(`문서 단계가 종료되어 있습니다.`)
            }
          }}
        >
          {item.status === 'wait' ? <span>제출됨</span> : null}
          {item.status === 'review' ? <span>처리중</span> : null}
          {item.status === 'reject' ? <span>반려</span> : null}
          {item.status === 'accept' ? <span>처리완료</span> : null}
          {item.status === 'cancel' ? <span>취소</span> : null}
        </div>

        {modal && modal.name === 'UpdateApprovalStatusModal'
          ? (
            <UpdateApprovalStatusModal
              item={item} parent={{ loadItems }}
              isOpen={true} onClose={e => that.setState({ modal: {} })}
            />
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

StatusColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

StatusColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default StatusColumnContainer
