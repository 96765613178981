import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class RoundDetailColumnConatiner extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {}
    meta.setup = _.get(item, 'content.setup') || {}

    return (
      <Column>
        <div style={{ minWidth: '100%', maxWidth: '100%' }}>
          <strong style={{ fontWeight: 700, fontSize: '1.3em' }}>{_.get(meta.setup, 'name') || '브런치 표지제작'}</strong>
        </div>
      </Column>
    )
  }
}

RoundDetailColumnConatiner.propTypes = {
  item: PropTypes.object,
}

RoundDetailColumnConatiner.defaultProps = {
  item: {}
}

export default RoundDetailColumnConatiner
