import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

const columns = []

columns.push({ header:  '순번', key: 'no', view: (item, idx) => `${idx+1}` })

columns.push({
  header:  '종류', key: 'bookType',
  view: (item) => {
    if (['paperBook'].includes(_.get(item, 'content.type'))) { return '종이도서' }
    if (['electronicBook'].includes(_.get(item, 'content.type'))) { return '전자도서' }
    return `알수없음`
  }
})

columns.push({
  header:  '도서번호', key: 'bookNo',
  view: (item) => {
    const value = _.get(item, 'content.bookNo') || ''
    return value
  }
})

columns.push({
  header:  '도서명', key: 'bookTitle',
  view: (item) => {
    const value = _.get(item, 'content.title') || '도서명 없음'
    return value
  }
})

columns.push({
  header:  '저자', key: 'bookAuthor',
  view: (item) => {
    const value = _.get(item, 'content.author') || '미기재'
    return value
  }
})

columns.push({
  header:  '용도', key: 'bookPurpose',
  view: (item) => {
    const value = _.get(item, 'content.purpose.text') || '알수없음'
    return value
  }
})

columns.push({
  header:  '납본상태', key: 'legalDeposit',
  view: (item) => {
    const legalDeposit = _.get(item, 'content.legalDeposit') || {}
    const status = legalDeposit.status || 'none'
    if (['required'].includes(status)) { return `납본필요` }
    if (['resolved'].includes(status)) { return `납본완료` }
    if (['rejected'].includes(status)) { return `납본취소` }
    return `해당없음`
  }
})

columns.push({
  header:  '출판일', key: 'publishedAt',
  view: (item) => {
    const publishedAt = _.get(item, 'content.publishedAt')
    const publishedMt = publishedAt ? moment(publishedAt) : null
    const value = publishedMt.format('YYYY년 MM월 DD일')
    return value
  }
})

columns.push({
  header:  '컬러여부', key: 'bookTextColor',
  view: (item) => {
    const value = ['color'].includes(_.get(item, 'content.text.color.name'))
      ? '컬러'
      : ''
    return value
  }
})

columns.push({
  header:  '규격', key: 'bookSize',
  view: (item) => {
    const value = _.get(item, 'content.bookSize.text') || '알수없음'
    return value
  }
})

columns.push({
  header:  'ISBN', key: 'ISBN',
  view: (item) => {
    const value = _.get(item, 'content.isbn') || ''
    return value
  }
})

columns.push({
  header:  '교보문고 ISBN', key: 'ISBN',
  view: (item) => {
    const value = _.get(item, 'content.retailIsbns') || []
    const retail = value.find(o => o.name === 'kyobo') || {}
    return _.get(retail, 'isbn') || ''
  }
})

columns.push({
  header:  '표지재질', key: 'bookCoverMaterial',
  view: (item) => {
    const value = _.get(item, 'content.cover.material.text') || '정보없음'
    return value
  }
})

columns.push({
  header:  '코팅', key: 'bookCoverCoating',
  view: (item) => {
    const value = ['SPM250', 'ARM210'].includes(_.get(item, 'content.cover.material.name')) ? `무광` : `유광`
    return value
  }
})

columns.push({
  header:  '날개', key: 'bookCoverFlap',
  view: (item) => {
    const value = _.get(item, 'content.cover.flap') ? `날개있음` : `날개없음`
    return value
  }
})

columns.push({
  header:  '내지재질', key: 'bookTextMaterial',
  view: (item) => {
    const value = _.get(item, 'content.text.material.text') || `정보없음`
    return value
  }
})

columns.push({
  header:  '비고', key: 'blank', view: (item) => ``
})

parser.columns = columns

export default parser
