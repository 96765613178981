import Excel from 'exceljs'

// file - items - parser 를 통한 데이터 시리얼라이즈화.
class Importer {
  constructor(props) {
    this.parser = typeof props.parser === 'string'
      ? require(`./parsers/${props.parser}`).default
      : props.parser
    this.importedFile = props.importedFile || null
    this.items = props.items || []
    this.state = { error: null }

    this.parse = this.parse.bind(this)
    this.read = this.read.bind(this)
    this.open = this.open.bind(this)
    this.getItems = this.getItems.bind(this)
  }

  parse(row, rowIndex) {
    const { columns } = this.parser
    const item = { '_rIndex': rowIndex, '_raw': row.values }
    Object.keys(columns).forEach(key => {
      const column = columns[key]
      item[column.name] = column.parse ? column.parse(row.getCell(key).value) : row.getCell(key).value
    })

    return item
  }

  async read(file = this.importedFile, start = 1) {
    if (!file) { return this }
    const that = this

    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = async function () {
        const workbook = new Excel.Workbook()
        await workbook.xlsx.load(reader.result)
        const idx = workbook.worksheets[0].id
        const worksheet = workbook.getWorksheet(idx)
        that.items = []
        if (worksheet && worksheet.eachRow) {
          worksheet.eachRow((row, rowIndex) => rowIndex >= start
            ? that.items.push(that.parse(row, rowIndex))
            : null)
        }

        return resolve(that)
      }

      reader.readAsArrayBuffer(file)
    })
  }

  async open(file = null) {
    return new Promise(resovle => {
      if (file) { this.importedFile = file }
      if (!this.importedFile) {
        const input = document.createElement('input')
        input.type = 'file'
        input.addEventListener('change', async (e) => {
          this.importedFile = e.target.files[0]
          resovle(this)
          document.body.removeChild(input)
        })
        document.body.appendChild(input)

        return input.click()
      }
      resovle(this)
    })
  }

  getItems() {
    return this.items
  }
}

export default Importer
