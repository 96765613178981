import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

// `currentUser` 데이터를 기반으로 한다.
// 반드시 `백엔드 엔드포인트` flag를 전달자로 줄 것.

parser.columns = [
  // 신청회차 정보
  { 
    header: '참여회차', key: 'brunchIndex', 
    view: (item) => {
      const value = _.get(item, 'content.round') || 0
      return value ? value : '-'
    }
  },
  { 
    header: '브런치매거진명', key: 'brunchMagazine', 
    view: (item) => {
      const value = _.get(item, 'content.brunchMagazine') || 0
      return value ? value : '-'
    }
  },
  { 
    header: '브런치매거진번호', key: 'brunchMagazineNo', 
    view: (item) => {
      const value = _.get(item, 'content.brunchMagazineNo') || 0
      return value ? value : '-'
    }
  },
  { 
    header: '브런치유저ID', key: 'brunchUserId', 
    view: (item) => {
      const value = _.get(item, 'content.brunchUserId') || 0
      return value ? value : '-'
    }
  },

  // 신청도서정보
  { 
    header: '도서번호', key: 'bookNo', 
    view: (item) => {
      const value = _.get(item, 'content.bookNo') || 0
      return value ? value : '-'
    }
  },
  { 
    header: '도서명', key: 'bookTitle', 
    view: (item) => {
      const value = _.get(item, 'content.bookTitle') || 0
      return value ? value : '-'
    }
  },
  { 
    header: '저자명', key: 'bookAuthor', 
    view: (item) => {
      const value = _.get(item, 'content.bookAuthor') || 0
      return value ? value : '-'
    }
  },

  // 계정 정보
  { 
    header: '계정명', key: 'accountId', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return _.get(currentUser, 'accountId')
    }
  },
  { 
    header: '닉네임', key: 'nickName', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return  _.get(currentUser, 'nickName')
    }
  },
  { 
    header: '실명', key: 'name', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return  _.get(currentUser, 'name')
    }
  },
  { 
    header: '이메일', key: 'email', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return  _.get(currentUser, 'email')
    }
  },
  { 
    header: '전화번호', key: 'mobile', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return _.get(currentUser, 'mobile')
    }
  },
  { 
    header: '생년월일', key: 'birthday', 
    view: (item) => {
      const currentUser = item.currentUser || {}
      return _.get(currentUser, 'birthday')
    }
  },
  { 
    header: '신청일', key: 'createdAt', 
    view: (item) => {
      const createdAt = _.get(item, 'createdAt')
      return createdAt
        ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
        : '-'
    }
  },
]

export default parser
