import styled from 'styled-components'
import { font, palette } from 'styled-theme'

// 하위 개체 컴포넌트를 랩핑한다.
export const PayForm = styled.div`
  position: relative; box-sizing: border-box;
`

// 오류에 대한 메시지창을 의미
PayForm.Message = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 480px; margin: 5rem auto;
  & > header {
    position: relative; box-sizing: border-box;
    font-weight: 900; font-size: 1.3em;
    padding: 2rem 1rem;
    border-top: 3px solid ${palette('muted', 1)};
  }
  & > section {
    position: relative; box-sizing: border-box;
    padding: 1rem;
  }
`
