import api from 'services/api'
import { getStorage } from './selectors'

export const ADMIN_PREPARE = 'ADMIN_PREPARE'
export const ADMIN_SIGNIN = 'ADMIN_SIGNIN'
export const ADMIN_SIGNOUT = 'ADMIN_SIGNOUT'

export const prepareAdmin = async (adminToken, safty) => {
  const storage = getStorage(), data = {}
  data.adminToken = adminToken ? adminToken : storage.getItem('adminToken')
  if (!data.adminToken) { return await signoutAdmin() }
  data.safty = safty ? safty : storage.getItem('safty')
  const result = await api.post(`/admins/auth`, data)
  if  (!result || result.error) { return await signoutAdmin() }
  const payload = {}
  payload.admin = result.admin || {}
  payload.adminToken = result.adminToken ? result.adminToken : storage.getItem('adminToken')
  storage.setItem('adminToken', payload.adminToken)
  payload.safty = result.safty
  if (!payload.safty) { storage.removeItem('safty') }

  return { type: ADMIN_PREPARE, payload }
}

export const signinAdmin = async (admin = null, adminToken = '', safty) => {
  const storage = getStorage(), payload = { admin, adminToken, safty }
  storage.setItem('adminToken', payload.adminToken)
  if (payload.safty) { storage.setItem('safty', payload.safty) } else { storage.removeItem('safty') }

  return { type: ADMIN_SIGNIN, payload }
}

export const signoutAdmin = async () => {
  const storage = getStorage()
  storage.removeItem('adminToken')
  storage.removeItem('safty')
  
  return { type: ADMIN_SIGNOUT, payload: {} }
}
