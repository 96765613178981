import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

const defaultFontStyle = { color: { argb: 'FF000000' }, size: 8, }

const defaultFillStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }
const defaultTextFilleStyle = { fgColor: { argb: '1AD1FFD1' } }
const defaultFlapFillStyle = { fgColor: { argb: 'A6202020' } }

parser.options = {
  rowHeight: 12.75,
  defaultRowHeight: 12.75
}

parser.columns = [
  {
    header: '일자', key: 'day',
    width: 3.57, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return moment(item.createdAt).format('M.D')
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '시간', key: 'time',
    width: 3.57, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return moment(item.createdAt).format('HH:mm')
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '도서명', key: 'title',
    width: 55.71, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.title') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '용도', key: 'purpose',
    width: 3.29, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.purpose.text') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '저자', key: 'author',
    width: 4.43, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.author') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '수량', key: 'amount',
    width: 8.43, style: { font: { size: 8 } },
    view: (item) => {
      return item.total || 0
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '수량', key: 'amount',
    width: 8.43, style: { font: { size: 8 } },
    view: (item) => {
      return item.total || 0
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '규격', key: 'bookSize',
    width: 4.29, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.bookSize.text') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '내지색상', key: 'textColor',
    width: 8.43, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.text.color.name') === 'color' ? `컬러` : ``
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '표지재질', key: 'coverMaterial',
    width: 13.29, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.cover.material.text') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '내지재질', key: 'textMaterial',
    width: 13.29, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.text.material.text') || '확인요망'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '코팅', key: 'coverCoating',
    width: 4.14, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.cover.material.coating') === 'gloss' ? '유광' : '무광'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
  {
    header: '날개', key: 'flap',
    width: 7.29, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'extras.product.content.cover.flap') ? `★` : '날개없음'
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.cover.flap')) { return { ...fill, defaultFlapFillStyle } }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle, horizontal: 'right' }
      if (_.get(item, 'extras.product.content.cover.flap')) { return { ...font, color: { argb: 'FFFFFFFF' } } }
      return font
    }
  },
  {
    header: '메모', key: 'memo',
    width: 8.43, style: { font: { size: 8 } },
    view: (item) => {
      if (item._group) { return '' }
      return _.get(item, 'memo') || ''
    },
    fill: (item = {}) => {
      if (item._group) { return defaultFillStyle }
      const fill = { ...defaultFillStyle }
      if (_.get(item, 'extras.product.content.text.color.name') === 'color') { return { ...fill, ...defaultTextFilleStyle } }
      return fill
    },
    font: (item = {}) => {
      const font = { ...defaultFontStyle }
      return font
    }
  },
]

export default parser
