import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

const columns = []
columns.push({ header: '회원번호', key: 'userNo', view: (item) => { return item.userNo ? `${item.userNo}` : '' } })

columns.push({ header: '구분', key: 'purpose', view: (item) => { return ['business'].includes(item.purpose) ? `기업` : '개인' } })

columns.push({ header: '계정명', key: 'accountId', view: (item) => { return item.accountId ? `${item.accountId}` : '' } })
columns.push({ header: '이메일', key: 'email', view: (item) => { return item.email ? `${item.email}` : '' } })
columns.push({ header: '전화번호', key: 'email', view: (item) => { return item.mobile ? `${item.mobile}` : '' } })
columns.push({ header: '닉네임', key: 'nickName', view: (item) => { return item.nickName ? `${item.nickName}` : '' } })

columns.push({ header: '실명', key: 'name', view: (item) => { return item.name ? `${item.name}` : '' } })

columns.push({ header: '사업자명', key: 'businessCorporationName', view: (item) => { return _.get(item, 'business.name') } })
columns.push({ header: '대표자명', key: 'businessCorporationNumber', view: (item) => { return _.get(item, 'business.representative') } })
columns.push({ header: '사업자번호', key: 'businessCorporationNumber', view: (item) => { return _.get(item, 'business.registrationNumber') } })

columns.push({ header: '은행명', key: 'bankName', view: (item) => { return _.get(item, 'bankAccount.bankName') } })
columns.push({ header: '예금주', key: 'bankOwnerName', view: (item) => { return _.get(item, 'bankAccount.owner') } })
columns.push({ header: '계좌번호', key: 'bankAccountNumber', view: (item) => { return _.get(item, 'bankAccount.number') } })

columns.push({ header: '뉴스레터', key: 'allowLetter', view: (item) => { return _.get(item, 'allowLetter') ? `허용` : '거부' } })
columns.push({ header: '뉴스레터 수신수락일', key: 'allowLetterAt', view: (item) => { return _.get(item, 'allowLetterAt') ? moment(_.get(item, 'allowLetterAt')).format('YYYY-MM-DD') : '' } })
columns.push({ header: 'SMS', key: 'allowSms', view: (item) => { return _.get(item, 'allowSms') ? `허용` : '거부' } })
columns.push({ header: 'SMS수락일', key: 'allowSmsAt', view: (item) => { return _.get(item, 'allowSmsAt') ? moment(_.get(item, 'allowSmsAt')).format('YYYY-MM-DD') : '' } })

parser.columns = columns

export default parser



