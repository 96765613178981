import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

class BookDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentBook = extras.currentBook || {}

    const meta = {}
    meta.userId = _.get(item, 'user') || null

    meta.productId = _.get(item, 'content.productId') || '상품번호 없음'
    meta.bookNo = _.get(item, 'content.bookNo') || '도서번호 없음'

    meta.bookTitle = _.get(currentBook, 'title') || '도서명 미기재'
    meta.bookAuthor = _.get(currentBook, 'author') || '저자명 미기재'
    meta.bookTypeAlias = ['paperBook'].includes(_.get(currentBook, 'type'))
      ? '종이도서'
      : (['electronicBook'].includes(_.get(currentBook, 'type')) ? '전자도서' : '기타')
    meta.bookPurpose = [''].includes(_.get(currentBook, 'purpose.text')) || '기타'
    
    return (
      <Column>
        <div>
          <small style={{ marginRight: '0.15rem' }}>{meta.bookTypeAlias}</small>
          <small><strong>{meta.bookPurpose}</strong></small>
        </div>
        <div className="bookTitle">
          <a
            href={`/products/book/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong>{meta.bookTitle}
            </strong>
          </a>
        </div>
        <div>
          <a
            href={`/users/${meta.userId}`}
            target="_blank"
            onClick={e => {
              if (!meta.userId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`유저정보개 배정되지 못한 문서입니다. 시스템 관리자에게 문의해주세요.`)]
              }
              return
            }}
          >
            <small>{meta.bookAuthor}</small>
          </a>
        </div>
      </Column>
    )
  }
}

BookDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookDetailColumnContainer.defaultProps = {
  item: {}
}

export default BookDetailColumnContainer
