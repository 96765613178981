import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

const FormSet = styled.div`
  position: relative; box-sizing: border-box;
`

FormSet.Head = styled.header`
  position: relative; box-sizing: border-box; color: ${palette('muted', 1)};
  font-size: 1.05em; font-weight: 700; padding: 0.5rem; padding-top: 0; margin: 1rem 0;
  border-bottom: 2px solid ${palette('muted', 10)};
`

FormSet.Lead = styled.header`
  position: relative; box-sizing: border-box; color: ${palette('muted', 1)};
  display: flex; justify-content: space-between; align-items: center; padding: 0.25rem 0; margin-bottom: 0.5rem; padding-top: 0;
  border-bottom: 1px dotted ${palette('muted', 15)};
`

FormSet.Title = styled.header`
  position: relative; box-sizing: border-box;
  font-size: 1.05em; font-weight: 700; padding: 0.5rem;
`

FormSet.Group = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem; margin: 0.5rem 0;
  & & { background: ${palette('muted', 21)}; border-radius: 5px; }
`

FormSet.Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; min-width: max-content;
`

FormSet.Expend = styled.div`
  position: relative; box-sizing: border-box;
`

const textStyles = css`
  color: ${palette('muted', 6)};
  strong { color: ${palette('muted', 1)}; font-weight: 900; }
  a { color: ${palette('muted', 6)}; text-decoration: none; }
  details {
    & > summary {
      cursor: pointer; outline: none;
      &::-webkit-details-marker { display: none; }
    }
    & > div { padding: 0.5rem 0; }
  }
`

const requiredStyles = css`
  label {
    .text::after {
      content: '*';
      color: ${palette('warn', 0)};
    }
  }
  input[type=text],input[type=number],textarea,select {
    &::placeholder {
    }
    &:placeholder-shown {
      border: 1px solid ${palette('warn', 0)};
    }
  }
`

const fieldStyles = css`
  color: ${palette('muted', 6)};
  input[type=text],input[type=number] {
    display: block; box-sizing: border-box; border-radius: 5px;
    box-shadow: 0 0 2px ${palette('muted', 15)}; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0 10px;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
  textarea {
    height: 100%; max-width: 250px;
    display: block; box-sizing: border-box; border-radius: 5px;
    box-shadow: 0 0 2px ${palette('muted', 15)}; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0 10px;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem; resize: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &:focus { background: ${palette('muted', 15)}; }
  }
  select {
    display: block; box-sizing: border-box; border-radius: 5px; transition: all 0.3s;
    box-shadow: 0 0 2px ${palette('muted', 15)};
    border: 0; outline: none; display: block; width: 100%; padding: 0;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
    & > .text {
      padding-right: 0.5rem;
    }
    & > input[type=text],input[type=number],select {
      max-width: 140px;
    }
  }
  .preview {
    display: flex;
    justify-content: space-between;
    img {
      max-width: 50px;
    }
  }
`

FormSet.Col = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; 
  overflow: hidden; text-overflow: ellipsis; white-space: pre;
  padding: 0.75rem 0.5rem;
  ${textStyles}
  ${fieldStyles}
  ${({ required }) => required && requiredStyles}
  & > * { margin-bottom: 0.4rem; }
`

FormSet.Header = styled.header`
  position: relative; box-sizing: border-box;
  & > ${FormSet.Item} {
    & > ${FormSet.Col} {
      color: ${palette('muted', 1)}; padding: 0rem 0.5rem;
      font-size: 0.8em; font-weight: 500;
    }
  }
`

FormSet.Body = styled.div`
  position: relative; box-sizing: border-box;
  & > ${FormSet.Item} {
    & > ${FormSet.Col} {
      font-size: 0.9em;
    }
  }
  &:hover {
    & > ${FormSet.Item} {
      opacity: 0.9; transition: all 0.3s;
      &:hover { opacity: 1; }
    }
  }
`

FormSet.NotFound = styled.div`
  position: relative; box-sizing: border-box; text-align: center;
  padding: 1.5rem 1rem; background: ${palette('muted', 21)}; border-radius: 5px; margin: 1rem 0;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 1.05em; font-weight: 700; padding-bottom: 0.4rem; color: ${palette('muted', 1)};
  }
  & > section {
    position: relative; box-sizing: border-box;
    font-size: 0.9em; padding-bottom: 0.4rem; color: ${palette('muted', 9)};
  }
`

FormSet.Footer = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-end;
`

FormSet.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
`

FormSet.Button = styled.a`
  position: relative; box-sizing: border-box; text-decoration: none; cursor: pointer;
  padding: 0.15rem 0.75rem; margin: 0 0.25rem; font-size: 0.9em;
  border-radius: 5px; border: 1px solid ${palette('muted', 6)}; color: ${palette('muted', 6)};
`

export default FormSet
