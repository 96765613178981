import _ from 'lodash'
import moment from 'services/moment'

const methodTypes = {}
methodTypes.card = { name: 'card', text: '신용카드' }
methodTypes.card_rebill = { name: 'card_rebill', text: '정기결제' }
methodTypes.card_rebill_rest = { name: 'card_rebill_rest', text: '정기결제 (REST API)' }
methodTypes.bank = { name: 'bank', text: '계좌이체' }
methodTypes.phone = { name: 'phone', text: '휴대폰' }
methodTypes.vbank = { name: 'vbank', text: '가상계좌' }
methodTypes.kakao = { name: 'kakao', text: '카카오페이 간편결제' }
methodTypes.payco = { name: 'payco', text: '페이코 간편결제' }
methodTypes.npay = { name: 'npay', text: '네이버페이 간편결제' }
methodTypes.etc = { name: 'etc', text: '기타' }
methodTypes.free = { name: 'free', text: '무료' }

const statusTypes = {}
statusTypes.temporary = { name: 'temporary', text: '임시' }
statusTypes.wait = { name: 'wait', text: '결제대기' }
statusTypes.finished = { name: 'finished', text: '결제완료' }
statusTypes.error = { name: 'error', text: '결제오류' }
statusTypes.deleted = { name: 'deleted', text: '삭제됨' }


const refundStatusTypes = {}
refundStatusTypes.none = { name: 'none', text: '해당없음' }
refundStatusTypes.wait = { name: 'wait', text: '환불요청' }
refundStatusTypes.review = { name: 'review', text: '처리중' }
refundStatusTypes.reject = { name: 'reject', text: '환불거절' }
refundStatusTypes.accept = { name: 'accept', text: '환불완료' }
refundStatusTypes.cancel = { name: 'cancel', text: '환불취소' }

const supplyStatusTypes = {}
supplyStatusTypes.temporary = { name: 'temporary', text: '임시' }
supplyStatusTypes.wait = { name: 'wait', text: '작업대기' }
supplyStatusTypes.ready = { name: 'ready', text: '작업준비중' }
supplyStatusTypes.packing = { name: 'packing', text: '작업중' }
supplyStatusTypes.sent = { name: 'sent', text: '작업완료' }
supplyStatusTypes.cancel = { name: 'cancel', text: '작업취소' }
supplyStatusTypes.error = { name: 'error', text: '오류' }

const parser = {}

// parser.sheetProps = {
//   pageSetup: {
//     paperSize: 9,
//     orientation: 'landscape',
//     horizontalCentered: true,
//     verticalCentered: true,
//     // showGridLines: true,
//     fitToPage: true,
//     margins: { top: 0, right: 0, bottom: 0, left: 0, header: 0, footer: 0 },
//   },
// }

parser.rowProps = { height: 30 }

parser.columns = [
  { header: '결제번호', key: 'payNo', view: (item) => item.payNo },
  { header: '결제ID', key: 'payId', view: (item) => item.id },
  { header: '주문일', key: 'createdAt', view: (item) => moment(item.createdAt).format('YYYY년 MM월 DD일 HH시 mm분') },
  { header: '결제일', key: 'paidAt', view: (item) => moment(item.paidAt).format('YYYY년 MM월 DD일 HH시 mm분') },
  {
    header: '최종수정일',
    key: 'updatedAt',
    view: (item) => moment(item.updatedAt).format('YYYY년 MM월 DD일 HH시 mm분')
  },
  {
    header: '상품유형', key: 'solutionCategory',
    view: (item) => {
      const currentOrders = item.currentOrders || []
      const firstOrder = currentOrders[0]
      return _.get(firstOrder, 'content.solutionCategory.text') || '기타'
    }
  },
  {
    header: '상품명',
    key: 'subject',
    // columnProps: {
    //   width: 100,
    // },
    // viewProps: {
    //   font: { name: 'Noto Sans KR', color: { argb: '00FFFFFF' } },
    //   fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00111111' } },
    //   border: {
    //     top: { style: 'thin', color: { argb: '00111111' } },
    //     right: { style: 'thin', color: { argb: '00111111' } },
    //     bottom: { style: 'thin', color: { argb: '00111111' } },
    //     left: { style: 'thin', color: { argb: '00111111' } }
    //   },
    //   alignment: { horizontal: 'center', vertical: 'middle' }
    // },
    view: (item) => {
      return item.subject || '결제내역명 없음'
    }
  },
  {
    header: '금액',
    key: 'totalPrice',
    view: (item) => {
      return item.totalPrice || 0
    }
  },
  { 
    header: '주문자',
    key: 'buyer',
    view: (item) => {
      let value = null
      if (_.get(item, 'buyer.name')) { value = _.get(item, 'buyer.name') }
      if (!value && _.get(item, 'userNickName')) { value = _.get(item, 'userNickName') }
      if (!value && _.get(item, 'userAccountId')) { value = _.get(item, 'userAccountId') }
      if (!value) { value = item.user || '알수없음' }
      return value
    }
  },
  {
    header: '제공자',
    key: 'sellerName',
    view: (item) => {
      const currentSeller = item.currentSeller || {}
      let value = _.get(currentSeller, 'name') || _.get(currentSeller, 'nickName') || _.get(currentSeller, 'accountId') || '알수없음'
      return value
    }
  },
  {
    header: '결제방법',
    key: 'method',
    view: (item) => {
      const methodType = _.get(methodTypes, `${item.method}`) || {}
      const value = _.get(methodType, 'text') || '알수없음'
      return value
    }
  },
  {
    header: '결제상태',
    key: 'status',
    view: (item) => {
      const statusType = _.get(statusTypes, `${item.status}`) || {}
      const value = _.get(statusType, 'text') || '알수없음'
      return value
    }
  },
  {
    header: '환불상태',
    key: 'refundStatus',
    view: (item) => {
      const refundStatus = _.get(refundStatusTypes, `${item.refundStatus}`) || {}
      const value = _.get(refundStatus, 'text') || '알수없음'
      return value
    }
  },
  {
    header: '처리상태',
    key: 'supplyStatus',
    view: (item) => {
      const supplyStatusType = _.get(supplyStatusTypes, `${item.supplyStatus}`) || {}
      const value = _.get(supplyStatusType, 'text') || '알수없음'
      return value
    }
  }
]

export default parser
