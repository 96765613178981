import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import { connect } from 'react-redux'
import * as actions from 'store/actions'
import { fromAdmin } from 'store/selectors'

import Form from './Form'

class AdminSiginFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.intialState = this.intialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.handle = this.handle.bind(this)
    this.signin = this.signin.bind(this)

    this.state = this.intialState(props)
  }

  componentDidMount() {
    this.props.signoutAdmin().then(() => this.initialize())
  }

  intialState(props) {
    const state = { form: { accountId: '', password: '', safty: null }, returnUrl: props.returnUrl || '/' }
    return state
  }

  initialize() {
    this.setState(this.intialState(this.props))
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object') {
      values[0].length
        ? values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key])))
        : Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key]))
    }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  async signin() {
    const { form, returnUrl } = this.state
    const { history, signinAdmin, signoutAdmin } = this.props

    const result = await api.post(`/admins/signin`, form, { signal: this.abortController.signal })
      .catch((e) => ({ error: true, message: e.message }))
    
      if (!result) { return alert(`😥 계정명 또는 비밀번호를 확인해주세요.`) }

    if (result && result.error) {
      if (result.message) { return alert(`😥 오류 발생 : ${result.message}`) }
      await signoutAdmin().catch((e) => null)
    }

    const { admin, accessToken, safty } = result || {}

    await signinAdmin(admin, accessToken, safty)
      .catch((e) => alert(`😥 오류 발생 : ${e.message}`))

    return history.push(returnUrl)
  }

  render() {
    const { initialize, handle, signin } = this, { admin, location, history, match } = this.props, { form } = this.state
    const commonProps = { admin, location, history, match }, formProps = { ...commonProps, form, initialize, handle, signin }
    return <Form {...formProps} />
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

AdminSiginFormContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  returnUrl: PropTypes.string,
  signinAdmin: PropTypes.func,
  signoutAdmin: PropTypes.func
}

AdminSiginFormContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  returnUrl: null,
  signinAdmin: async () => { },
  signoutAdmin: async () => { }
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  signinAdmin: async (admin, adminToken, safty) => dispatch(await actions.signinAdmin(admin, adminToken, safty)),
  signoutAdmin: async () => dispatch(await actions.signoutAdmin())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminSiginFormContainer)
