import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import qs from 'query-string'
import _  from 'lodash'

import PackingList from 'components/extras/PackingList'

import Paper from 'components/layouts/Paper'

class PackingPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}
    const search = location.search ? qs.parse(location.search) : {}

    const commonProps = { admin, history, location, match, ...search }

    const listProps = { ...commonProps, nav }

    return (
      <Paper>
        <Helmet>
          <title>포장관리자({nav}) - BOOKK</title>
        </Helmet>
        <PackingList {...listProps} />
      </Paper>
    )
  }
}

PackingPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

PackingPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default PackingPageContainer
