import styled from 'styled-components'
import { palette, font } from 'styled-theme'

export const Box = styled.article`
  position: relative; box-sizing: border-box;
  font-family: ${font('primary')};
  `

Box.Grid = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
`

Box.Lead = styled.header`
  position: relative; box-sizing: border-box;
  font-size: 1.8em; line-height: 1.618em; font-weight: 900;
  padding: 0 1rem;
  & small { font-weight: 100; }
`

Box.Item = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 48.99%; max-width: 48.99%;
  margin: 1rem 0; padding: 1rem; border-radius: 0.5rem;
  border: 1px solid #ccc;
`

export const Dashboard = styled.div`
  position: relative; box-sizing: border-box;
`

Dashboard.State = styled.div`
  position: relative; box-sizing: border-box; padding: 0.75rem 1.25rem; margin: 0.5rem 0;
`

Dashboard.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex;
  padding: 1rem 0;
  & a {
    display: block; text-decoration: none;
    padding: 0.75rem 1.25rem; margin: 0.5rem;
    border-radius: 0.5rem; background: #f1f1f1; color: #666;
    &:hover { opacity: 0.8; }
  }
`

export const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  border-bottom:  1px solid #ededed;
  &.header { font-weight: 700; background: #f1f1f1; }
  `

Item.Col = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; white-space: nowrap; border-left: 1px solid #eaeaea;
  &:first-child { border-left: 0; }
  &.fully { flex: 1 1 100%; white-space: normal; }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
  border-top:  1px solid #ccc;
`
