import React from 'react'

import api from 'services/api'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { comma } from 'services/utils'
import backdoor from 'services/backdoor'

import Orders from './Orders'
import { Form, Meta } from './utils'

import CreateRefundApprovalModal from '../CreateRefundApprovalModal'

class EtcContainer extends React.Component {

  constructor(props) {
    super(props)

    this.initiForm = this.initiForm.bind(this)
    this.doCopyText = this.doCopyText.bind(this)
    this.getReceiptUrl = this.getReceiptUrl.bind(this)
    this.doSave = this.doSave.bind(this)
    this.doOpenCreateRefundApprovalModal = this.doOpenCreateRefundApprovalModal.bind(this)
    this.doCancelReceipt = this.doCancelReceipt.bind(this)
    this.doUpdateFinishedStatus = this.doUpdateFinishedStatus.bind(this)
    this.doUpdateRefundAcceptStatus = this.doUpdateRefundAcceptStatus.bind(this)
    this.doRemove = this.doRemove.bind(this)

    this.state = {
      modal: null,
      form: this.initiForm(props.item)
    }
  }

  /*
    작가서비스 {
      판매자계정정보(아이디, 닉네임, 이메일)
      작가서비스 주문내역은 댓글기능이 존재함
    }

    [기능]
    주문단계 변경가능, 주문서 삭제, 변경정보 저장, 결제취소 기능
  */
  initiForm(item = {}) {
    const form = {}

    // 결제 개요
    form.subject = _.get(item, 'subject')
    
    form.payRefundStatus = _.get(item, 'refundStatus')
    form.payRefundStateAt = _.get(item, 'refundStatusAt') // 상태 변경이 될 시간을 기록한다.

    form.method = _.get(item, 'method')

    // 구매자정보 취합 : 구매자명, 휴대폰, 구매자메시지
    form.buyer = _.get(item, 'buyer') || {}

    // 구매자정보 취합 : 구매자명, 휴대폰, 구매자메시지
    form.delivery = _.get(item, 'delivery') || {}

    // 결제방식 변경
    form.memo = _.get(item, 'memo')

    // 주문내역의 상태값을 변경하는 기능
    form.orders = _.get(item, 'extras.orders') || []

    return form
  }

  // 여러 파일명 복사 기능
  async doCopyText(text) {
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // @ 영수증 URL
  async getReceiptUrl() {
    const { item = {} } = this.props
    if (!item.id) { return '' }

    const result = await api.get(`/pays/admin2/${item.id}/receipt-url`).catch((e) => ({ error: false }))
    return _.get(result, 'receipt_url')
  }

  // 변경된 상품정보를 업데이트 한다.
  async doSave() {
    const { initialize } = this.props
    const previous = this.props.item, form = this.state.form
    if (!window.confirm(`해당 변경내용으로 반영을 할까요?`)) { return }
    const result = await api.put(`/pays/admin2/${previous.id}`, { previous, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 저장하였습니다.`)
    // return initialize ? initialize() : null
    // return window.location.reload()
  }

  // 상품을 삭제하는 기능
  async doCancelReceipt() {
    const { item, initialize } = this.props
    if (!window.confirm(`결제를 강제로 전액취소(잔액분 기준)하는 기능입니다. PG를 취소하더라도, 결제상태 또는 환불상태에 영향이 없습니다. 진행할까요?`)) { return }

    const result = await api.put(`/pays/admin2/${item.id}/cancelReceipt`, {}).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 처리하였습니다.`)
    // return initialize ? initialize() : null
    return window.location.reload()
  }

  // 상품을 삭제하는 기능
  async doRemove() {
    const { item, initialize } = this.props
    if (!window.confirm(`🥶  현재 결제 내역을 삭제할까요? (이미 단계가 지나서 보존해야하는 주문의 경우 삭제 불가능합니다.)`)) { return }

    const result = await api.delete(`/pays/admin2/${item.id}`).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 처리하였습니다.`)
    // return initialize ? initialize() : null
    return window.location.reload()
  }

  // 수기환불 신청대장
  async doOpenCreateRefundApprovalModal() {
    const that = this, props = this.props
    const pay = JSON.parse(JSON.stringify(props.item)) // 현재 결제내역을 전달하기

    // 수기환불 모달창을 여는 기능
    const modalProps = {}
    modalProps.name = 'CreateRefundApprovalModal'
    modalProps.props = {
      name: modalProps.name,
      payId: pay.id, pay,
      onClose: async () => { return that.setState({ modal: null }) },
      onSuccess: async () => { return window.location.reload() }
    }
    return new Promise((r) => that.setState({ modal: modalProps }, r))
  }

  // 관리자에 의해서 강제 결제완료처리하는 구간 구성해주기
  async doUpdateFinishedStatus() {
    const { item, initialize } = this.props
    if (!window.confirm(`해당 결제내역을 결제완료 상태로 전환할까요? (전환 후 되돌리기는 불가능합니다. 단 환불완료 기능으로 처리해서 거래종결이 가능합니다. PG취소 기능은 사용이 불가능합니다.)`)) { return }

    const ret = { error: false, message: '성공적으로 처리하였습니다.' }
    const result = await api
      .put(`/pays/admin2/${item.id}/updateFinishedStatus`)
      .catch(e => {
        ret.error = true
        ret.message = e.message
        return false
      })
    
    ret.error = (!result || result.error)
    ret.message = (result && result.message) ? result.message : ret.message
    if (ret.message) { alert(ret.message) }

    return window.location.reload()
  }

  // 관리자에 의해서 강제 환불처리하는 구간
  async doUpdateRefundAcceptStatus() {
    const { item, initialize } = this.props
    if (!window.confirm(`환불을 처리할까요?`)) { return }

    const ret = { error: false, message: '성공적으로 처리하였습니다.' }
    const result = await api
      .put(`/pays/admin2/${item.id}/updateRefundAcceptStatus`)
      .catch(e => {
        ret.error = true
        ret.message = e.message
        return false
      })
    
    ret.error = (!result || result.error)
    ret.message = (result && result.message) ? result.message : ret.message
    if (ret.message) { alert(ret.message) }
    if (ret.error) { return }

    // return window.location.reload()
  }

  render() {
    const { 
      doCopyText,
      doSave,
      doCancelReceipt,
      doUpdateFinishedStatus,
      doUpdateRefundAcceptStatus,
      doOpenCreateRefundApprovalModal,
      doRemove, getReceiptUrl
    } = this
    const { modal, form } = this.state
    const { item, initialize } = this.props

    const payMethodTypes = {
      card: { name: 'card', text: '신용카드' },
      card_rebill: { name: 'card_rebill', text: '정기결제' },
      card_rebill_rest: { name: 'card_rebill_rest', text: '정기결제 (REST API)' },
      bank: { name: 'bank', text: '은행계좌 이체' },
      phone: { name: 'phone', text: '휴대폰 결제' },
      vbank: { name: 'vbank', text: '가상계좌 결제' },
      kakao: { name: 'kakao', text: '카카오페이 간편결제' },
      payco: { name: 'payco', text: '페이코 간편결제' },
      npay: { name: 'npay', text: '네이버페이 간편결제' },
      etc: { name: 'etc', text: '기타 결제' },
      free: { name: 'free', text: '무료' }
    }

    // 결제상태정보 구간
    const payStatusTypes = {
      temporary: { name: 'temporary', text: '임시', emoji: '⚪' },
      wait: { name: 'wait', text: '결제대기', emoji: '🟡' },
      finished: { name: 'finished', text: '결제완료', emoji: '🟢' },
      cancel: { name: 'cancel', text: '결제취소', emoji: '🔴' },
      error: { name: 'error', text: '결제오류', emoji: '⚫' }
    }
    
    // 환불상태정보 구간
    const payRefundStatusTypes = {
      none: { name: 'none', text: '이슈없음', emoji: '⚪' },
      wait: { name: 'wait', text: '환불요청', emoji: '🟡' },
      review: { name: 'review', text: '환불처리중', emoji: '🟢' },
      reject: { name: 'reject', text: '환불거절', emoji: '🔴' },
      accept: { name: 'accept', text: '환불완료', emoji: '🟢' },
      cancel: { name: 'cancel', text: '환불취소', emoji: '⚪' }
    }

    // 주문내역 메타정보를 구성하기
    const meta = {}

    // 주문번호, 결제방법, 영수증, 디바이스정보
    meta.payId = _.get(item, 'id')
    meta.payNo = _.get(item, 'payNo')
    meta.payTypeText = `기타`
    meta.subject = _.get(item, 'subject')

    meta.status = _.get(item, 'status')
    meta.payStatus = (item.status && _.get(payStatusTypes, item.status))
      ? _.get(payStatusTypes, item.status)
      : payStatusTypes.error.
      
    meta.supplyStatus = _.get(item, 'supplyStatus')
    meta.supplyStatusMt = _.get(item, 'supplyStatusAt') ? moment(_.get(item, 'supplyStatusAt')) : null

    meta.refundPrice = _.get(item, 'refundPrice') || 0
    meta.refundStatus = _.get(item, 'refundStatus')
    meta.payRefundStatus = (item.refundStatus && _.get(payRefundStatusTypes, item.refundStatus))
      ? _.get(payRefundStatusTypes, item.refundStatus)
      : payRefundStatusTypes.none
    meta.refundStatusMt = _.get(item, 'refundStatusAt') ? moment(_.get(item, 'refundStatusAt')) : null

    meta.method = _.get(item, 'method') || 'etc'
    meta.totalPrice = _.get(item, 'totalPrice')

    // 구매자 정보
    meta.buyer = _.get(item, 'buyer') || {}
    meta.buyerAccount = _.get(item, 'extras.user') || {}

    // PG사 영수증 정보 배정(가상계좌 입금상태도 배치할 것)
    meta.receipt = _.get(item, 'receipt') || {}

    // 주문일자, 결제일자, 업데이트일자
    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
    meta.paidMt = _.get(item, 'paidAt') ? moment(_.get(item, 'paidAt')) : null
    meta.cancelMt = _.get(item, 'cancelAt') ? moment(_.get(item, 'cancelAt')) : null
    meta.supplyStatusAt = _.get(item, 'supplyStatusAt') ? moment(_.get(item, 'supplyStatusAt')) : null
    meta.updatedMt = _.get(item, 'updatedAt') ? moment(_.get(item, 'updatedAt')) : null
    meta.expiredWaitMt = _.get(item, 'expiredWaitAt') ? moment(_.get(item, 'expiredWaitAt')) : null

    // 주문자 계정정보
    meta.accountId = _.get(meta.buyer, 'accountId') || '-'
    meta.email = _.get(meta.buyer, 'email') || '-'
    meta.mobile = _.get(meta.buyer, 'mobile') || '-'

    // 주문상품내역 배치
    meta.orders = _.get(form, 'orders') || []
    meta.orderCount = _.get(meta, 'orders.length') || 0

    // 디바이스 체크
    meta.device = _.get(meta, 'device')
      ? { isMobile: false, agent: '기타', ip: '', ..._.get(meta, 'device') }
      : { isMobile: false, agent: '기타', ip: '' }
    meta.deviceName = _.get(meta, 'device.isMobile')
      ? `모바일(${meta.device.ip || meta.device.agent})`
      : `PC(${meta.device.ip || meta.device.agent})`

    // 수기환불 신청에 대한 상태값 구분
    const refundApprovalStatusTypes = {}
    refundApprovalStatusTypes.none = { name: 'none', text: '미신청' }
    refundApprovalStatusTypes.wait = { name: 'wait', text: '신청중' }
    refundApprovalStatusTypes.review = { name: 'review', text: '처리중' }
    refundApprovalStatusTypes.reject = { name: 'reject', text: '지급거절' }
    refundApprovalStatusTypes.accept = { name: 'accept', text: '지급완료' }
    refundApprovalStatusTypes.cancel = { name: 'cancel', text: '신청취소' }

    // 수기환불 신청정보를 가져온다.
    meta.refundApprovals = _.get(item, 'extras.refundApprovals') || []
    meta.lastRefundApproval = (meta.refundApprovals && meta.refundApprovals.length)
      ? meta.refundApprovals.slice(-1)[0]
      : null
    meta.refundApprovalStatus = _.get(meta,'lastRefundApproval.status') || 'none'
    meta.refundApprovalStatusText = _.get(refundApprovalStatusTypes, `[${meta.refundApprovalStatus}].text`) || refundApprovalStatusTypes.none.text

    // 후기이벤트 신청에 대한 상태값 구분
    const payReviewStatusTypes = {}
    payReviewStatusTypes.none = { name: 'none', text: '미신청' }
    payReviewStatusTypes.wait = { name: 'wait', text: '신청완료' }
    payReviewStatusTypes.review = { name: 'review', text: '확인중' }
    payReviewStatusTypes.reject = { name: 'reject', text: '인증실패' }
    payReviewStatusTypes.accept = { name: 'accept', text: '인증완료' }
    payReviewStatusTypes.cancel = { name: 'cancel', text: '신청취소' }

    // 후기이벤트 신청정보를 가져온다.
    meta.payReviewApprovals = _.get(item, 'extras.payReviewApprovals') || []
    meta.lastPayReviewApproval = (meta.payReviewApprovals && meta.payReviewApprovals.length)
      ? meta.payReviewApprovals.slice(-1)[0]
      : null
    meta.payReviewApprovalStatus = _.get(meta,'lastPayReviewApproval.status') || 'none'
    meta.payReviewApprovalStatusText = _.get(payReviewStatusTypes, `[${meta.payReviewApprovalStatus}].text`) || refundApprovalStatusTypes.none.text

    // 주문내역 상품을 넣어주기
    const ordersProps = { items: form.orders, parentProps: { form, item, setState: (state) =>  this.setState(state) } }

    return (
      <Form>
        {modal && modal.name === 'CreateRefundApprovalModal' ? <CreateRefundApprovalModal {...modal.props} /> : null}

        <Form.Header>
          <Form.Lead>기타 결제내역</Form.Lead>
          <Form.Units>

            <Form.Buttons>
              <Form.Button
                href="#doRemove"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doRemove()]
                }}
              >
                삭제 🧹
              </Form.Button>

              {['temporary', 'wait'].includes(item.status)
                ? (
                  <Form.Button
                    href="#doUpdateFinishedStatus"
                    onClick={async (e) => {
                      return [e.stopPropagation(), e.preventDefault(), await doUpdateFinishedStatus()]
                    }}
                  >
                    결제승인 🧙‍♂️
                  </Form.Button>
                )
                : null}
              
              {(['finished'].includes(item.status))
                ? (
                  <Form.Button
                    href="#doOpenCreateRefundApprovalModal"
                    onClick={async (e) => {
                      return [e.stopPropagation(), e.preventDefault(), await doOpenCreateRefundApprovalModal()]
                    }}
                  >
                    수기환불 ✍️
                  </Form.Button>
                )
                : null}

              {['finished'].includes(item.status)
                ? (
                  <Form.Button
                    href="#doUpdateRefundAcceptStatus"
                    onClick={async (e) => {
                      return [e.stopPropagation(), e.preventDefault(), await doUpdateRefundAcceptStatus()]
                    }}
                  >
                    강제환불처리 🪓
                  </Form.Button>
                )
                : null}

              {!['etc', 'free'].includes(item.method)
                ? (
                  <Form.Button
                    href="#doCancelReceipt"
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      const url = await getReceiptUrl()
                      if (!url) { return alert(`영수정보를 발급 할 수 없거나, 없는 거래건입니다.`) }
                      return window.open(url)
                    }}
                  >
                    영수증 📃
                  </Form.Button>
                )
                : null}

              {!['etc', 'free'].includes(item.method)
                ? (
                  <Form.Button
                    href="#doCancelReceipt"
                    onClick={async (e) => {
                      return [e.stopPropagation(), e.preventDefault(), await doCancelReceipt()]
                    }}
                  >
                    PG취소통보🧯
                  </Form.Button>
                )
                : null}

              <Form.Button
                href="#doLogin"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return await backdoor.login(item.user, `/pays/${item.id}/detail`)
                }}
              >
                🔐 로그인
              </Form.Button>

              <Form.Button
                className="primary"
                href="#doSave"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doSave()]
                }}
              >
                저장 ✅
              </Form.Button>
            </Form.Buttons>

          </Form.Units>          
        </Form.Header>
        <Form.Grid>
          <Form.Context>
            <Form.Boxs>
              <Form.Box style={{ minWidth: '23%', maxWidth: '23%',width: '23%' }}>

                <header>
                  구분
                </header>
                <section
                  style={{
                    border: '1px solid #b2b3b5', boxShadow: '0 0 2px rgba(1,1,1, 0.15)',
                    borderRadius: '0.618rem', padding: '0.618rem', margin: '0.618rem 0'
                  }}>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input type="text" placeholder={meta.payTypeText} disabled />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={meta.payNo}
                        style={{ cursor: 'pointer' }}
                        readOnly={true}
                        onClick={async (e) => {
                          await navigator.clipboard.writeText(`${meta.payNo}`)
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="결제내역명"
                        value={form.subject}
                        onChange={(e) => {
                          const next = { ...form }
                          next.subject = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

                <header>
                  결제상태 및 방식·결제금액
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="text"
                        placeholder={meta.deviceName}
                        disabled
                      />
                    </div>
                    <div className="control">
                      <input type="text" placeholder={`${meta.payStatus.emoji} ${meta.payStatus.text}`} disabled />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="text"
                        placeholder={`${_.get(payMethodTypes, `${item.method}.text`)}`}
                        disabled
                      />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${meta.totalPrice ? comma(meta.totalPrice) : 0}원`}
                        disabled
                      />
                    </div>
                  </Form.Field>
                </section>

                {['vbank'].includes(form.method)
                  ? (
                    <>
                      <header>
                        가상계좌 <small>만기시간</small>
                      </header>
                      <section>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder={`${_.get(meta,'receipt.payment_data.bankname') || '은행명'}`}
                              disabled
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={`${_.get(meta,'receipt.payment_data.accountholder') || '예금주'}`}
                              disabled
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={`${_.get(meta,'receipt.payment_data.account') || '계좌번호'}`}
                              disabled
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder={(_.get(meta,'receipt.payment_data.expiredate') ? _.get(meta,'receipt.payment_data.expiredate').split(' ')[0] : null) || `만기일자`}
                              disabled
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={(_.get(meta,'receipt.payment_data.expiredate') ? _.get(meta,'receipt.payment_data.expiredate').split(' ')[1] : null) || `만기일자`}
                              disabled
                            />
                          </div>
                        </Form.Field>
                      </section>
                    </>
                  )
                  : null}

                <header>
                  {['cancel'].includes(item.status)
                    ? `주문일자·결제취소일자`
                    : `주문일자·결제일자`}
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="date"
                        value={`${meta.createdMt ? meta.createdMt.format('YYYY-MM-DD') : '0000-00-00'}`}
                        disabled
                        style={{ marginBottom: '0.5rem' }}
                      />
                      <input
                        type="time"
                        disabled
                        value={`${meta.createdMt ? meta.createdMt.format('HH:mm:ss') : '00:00:00'}`}
                      />
                    </div>
                    {['cancel'].includes(item.status)
                      ? (
                        <div className="control">
                          <input
                            type="date"
                            value={`${meta.cancelMt ? meta.cancelMt.format('YYYY-MM-DD') : '0000-00-00'}`}
                            disabled
                            style={{ marginBottom: '0.5rem' }}
                          />
                          <input
                            type="time"
                            disabled
                            value={`${meta.cancelMt ? meta.cancelMt.format('HH:mm:ss') : '00:00:00'}`}
                          />
                        </div>
                      )
                      : null}
                    {!['cancel'].includes(item.status)
                      ? (
                        <div className="control">
                          <input
                            type="date"
                            value={`${meta.paidMt ? meta.paidMt.format('YYYY-MM-DD') : '0000-00-00'}`}
                            disabled
                            style={{ marginBottom: '0.5rem' }}
                          />
                          <input
                            type="time"
                            disabled
                            value={`${meta.paidMt ? meta.paidMt.format('HH:mm:ss') : '00:00:00'}`}
                          />
                        </div>
                      )
                      : null}
                  </Form.Field>
                </section>


                <header>
                  환불상태·액수·처리일자
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <select value={_.get(form, `payRefundStatus`)} disabled>
                        <option value="">결제 및 환불상태</option>
                        <option value="accept">🟢 환불완료</option>
                        <option value="none">⚪ 이슈없음</option>
                        {['wait'].includes(meta.refundStatus) ? <option value="wait">🟡 환불요청</option> : null}
                        {['review'].includes(meta.refundStatus) ? <option value="review">🟡 진행중</option> : null}
                        {['reject'].includes(meta.refundStatus) ? <option value="reject">🔴 환불보류</option> : null}
                        {['cancel'].includes(meta.refundStatus) ? <option value="cancel">⚪ 환불취소</option> : null}
                      </select>
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${meta.refundPrice ? comma(meta.refundPrice) : 0}원`}
                        disabled
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="date"
                        value={`${meta.refundStatusMt ? meta.refundStatusMt.format('YYYY-MM-DD') : '0000-00-00'}`}
                        disabled
                      />
                    </div>
                    <div className="control">
                      <input
                        type="time"
                        disabled
                        value={`${meta.refundStatusMt ? meta.refundStatusMt.format('HH:mm:ss') : '00:00:00'}`}
                      />
                    </div>
                  </Form.Field>
                </section>

              </Form.Box>
                            
              <Form.Box style={{ minWidth: '23%', maxWidth: '23%',width: '23%' }}>
                <header>주문자 정보·PG ReceiptID</header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${_.get(meta, 'buyer.name') || '결제자명'}`}
                        style={{ cursor: 'pointer' }}
                        readOnly={true}
                        onClick={async (e) => {
                          await navigator.clipboard.writeText(`${_.get(meta, 'buyer.name') || ''}`)
                        }}
                      />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${_.get(meta, 'buyer.mobile') || '결제자 연락처'}`}
                        style={{ cursor: 'pointer' }}
                        readOnly={true}
                        onClick={async (e) => {
                          await navigator.clipboard.writeText(`${_.get(meta, 'buyer.mobile') || ''}`)
                        }}
                      />
                    </div>
                  </Form.Field>
                  {_.get(item, 'receipt.receipt_id')
                    ? (
                      <Form.Field>
                        <div className="control" style={{ marginRight: '0.5rem' }}>
                          <input
                            type="text"
                            placeholder={`${_.get(item, 'receipt.receipt_id')}`}
                            style={{ cursor: 'pointer' }}
                            readOnly={true}
                            onClick={async (e) => {
                              await navigator.clipboard.writeText(`${_.get(item, 'receipt.receipt_id') || ''}`)
                            }}
                          />
                        </div>
                      </Form.Field>
                    )
                    : null}
                  <Form.Field>
                    <section>
                      <Form.Field>
                        <div className="control">
                          <textarea
                            style={{ height: '90px' }}
                            placeholder={_.get(meta, 'buyer.message') || `결제자 요청 및 요구사항`}
                            disabled
                          />
                        </div>
                      </Form.Field>
                    </section>
                  </Form.Field>
                </section>
                  
                <header>계정정보</header>

                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="text"
                        placeholder={`${_.get(meta, 'buyerAccount.accountId') || _.get(meta, 'buyerAccount.id') || '계정명 없음'}`}
                        disabled
                      />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${_.get(meta, 'buyerAccount.mobile') || '연락처 미기재'}`}
                        disabled
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder={`${_.get(meta, 'buyerAccount.email') || '이메일 정보 없음'}`}
                        disabled
                      />
                    </div>
                  </Form.Field>
                </section>

                {(['bookStockStorage.subscribe', 'bookStockStorage.unsubscribe'].includes(item.preset))
                  ? (
                    <>
                      <header>운송정보 <small>위수지역은 저장시 금액 산정반영</small></header>
                      <section
                        style={{
                          border: '1px solid #b2b3b5', boxShadow: '0 0 2px rgba(1,1,1, 0.15)',
                          borderRadius: '0.618rem', padding: '0.618rem', margin: '0.618rem 0'
                        }}>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder={`수령자명`}
                              value={_.get(form, 'delivery.receiver') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.receiver', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={`수령자 연락처`}
                              value={_.get(form, 'delivery.mobile') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.mobile', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem', maxWidth: '80px' }}>
                            <input
                              type="text"
                              placeholder="우편번호"
                              style={{ textAlign: 'center' }}
                              value={_.get(form, 'delivery.zip') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.zip', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="동 주소"
                              value={_.get(form, 'delivery.address1') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.address1', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="자세한 주소"
                              value={_.get(form, 'delivery.address2') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.address2', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control">
                            <textarea
                              placeholder="배송기사 메시지"
                              style={{ minHeight: '80px' }}
                              value={_.get(form, 'delivery.message') || ''}
                              onChange={(e) => {
                                const next = { ...form }
                                _.set(next, 'delivery.message', e.target.value)
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                        </Form.Field>
                        <div
                          style={{ padding: '0.35rem', cursor: 'pointer' }}
                          onClick={(e) => {
                            const next = { ...form }
                            _.set(next, 'delivery.receiver', '부크크')
                            _.set(next, 'delivery.mobile', '16708316')
                            _.set(next, 'delivery.zip', '08589')
                            _.set(next, 'delivery.region', false)
                            _.set(next, 'delivery.address1', '서울 금천구 가산디지털1로 119')
                            _.set(next, 'delivery.address2', 'SK트윈테크타워 A동 305-7호')
                            return this.setState({ form: next })
                          }}
                        >
                          🍀 부크크로 임의 배송지 넣기
                        </div>
                      </section>
                      
                      <header>
                        운임정보
                      </header>
                      <section>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder={['pre'].includes(_.get(meta, 'delivery.charge')) ? '선불' : `후불`}
                              disabled
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={_.get(meta, 'delivery.region') ? '산간지방' : `일반지역`}
                              disabled
                            />
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={_.get(meta, 'deliveryPrice') ? `${comma(_.get(meta, 'deliveryPrice'))}원` : `0원`}
                              disabled
                            />
                          </div>
                        </Form.Field>
                      </section>
                    </>
                  )
                  : null}

              </Form.Box>

              <Form.Box style={{ flex: '1 1 auto', minWidth: 0, maxWidth: '100%' }}>
                <header>
                  <span style={{ marginRight: '0.35rem' }}>주문 상품내역</span>
                  <small>{meta.orderCount ? comma(meta.orderCount) : 0}건</small>
                </header>
                <section>
                  <Orders {...ordersProps} />
                </section>
              </Form.Box>

              <Form.Box style={{ minWidth: '18%', maxWidth: '18%',width: '18%' }}>
                <header>
                  메모
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="PG사 결제로그, 시스템 관리상 냅둠"
                        value={form.memo}
                        style={{ minHeight: '105px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.memo = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

                {meta.lastRefundApproval
                  ? (
                    <>
                    <header>
                      <span style={{ marginRight: '0.35rem' }}>수기환불 정보</span>
                      <small>
                        <span>가장 최근수기 신청내역</span>
                        <span
                          style={{ cursor: 'pointer', marginLeft: '0.35rem' }}
                          onClick={(e) => {
                            const query = {}
                            query.keywords = meta.payNo
                            query.filters_status = 'all'
                            query.filters_payType = 'all'
                            query.filters_payMethod = 'all'
                            const url = `/manages/refund?${qs.stringify(query)}`
                            return window.open(url)
                          }}
                        >
                          <span>(</span>
                          <span style={{ marginRight: '0.35rem' }}>총</span>
                          <span style={{ color: '#333' }}>{comma(meta.refundApprovalCount)}건, 더보기</span>
                          <span>)</span>
                        </span>
                      </small>
                    </header>
                    <section>
                      <Form.Field>
                        <div className="control" style={{ marginRight: '0.5rem' }}>
                          <input
                            type="text"
                            placeholder={`${meta.refundApprovalStatusText}`}
                            disabled
                          />
                        </div>
                        <div className="control">
                          <input
                            type="text"
                            placeholder={
                              `${_.get(meta,'lastRefundApproval.content.refundPrice')
                                ? `${comma(_.get(meta,'lastRefundApproval.content.refundPrice'))}원`
                                : '0원'}`
                            }
                            style={{ fontWeight: 900 }}
                            disabled
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className="control" style={{ marginRight: '0.5rem' }}>
                          <input
                            type="text"
                            placeholder={`${_.get(meta,'lastRefundApproval.content.bankName') || '은행명'}`}
                            disabled
                          />
                        </div>
                        <div className="control">
                          <input
                            type="text"
                            placeholder={`${_.get(meta,'lastRefundApproval.content.walletOwner') || '예금주'}`}
                            disabled
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className="control">
                          <input
                            type="text"
                            placeholder={`${_.get(meta, 'lastRefundApproval.content.walletAddress') || '계좌번호'}`}
                            disabled
                          />
                        </div>
                      </Form.Field>
                    </section>
                  </>
                  )
                  : null}

                <header>
                  주문 플래그 관리
                </header>

                <section>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.enabledCancelReceipt}
                        onChange={(e) => {
                          const curAt = new Date(), next = { ...form }
                          _.set(next, `enabledCancelReceipt`, e.target.value)
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="true">🟢 카드 취소가능</option>
                        <option value="false">⚪ 카드 취소불가</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.enabledManualRefund}
                        onChange={(e) => {
                          const curAt = new Date(), next = { ...form }
                          _.set(next, `enabledManualRefund`, e.target.value)
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="true">🟢 수기 환불가능</option>
                        <option value="false">⚪ 수기 환불불가</option>
                      </select>
                    </div>
                  </Form.Field>
                </section>
                
              </Form.Box>
            </Form.Boxs>
          </Form.Context>
        </Form.Grid>
      </Form>
    )
  }
}

export default EtcContainer
