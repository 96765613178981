import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Caret from 'components/utils/Caret'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class SetupDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() { }
  
  render() {
    const { item = {} } = this.props

    const meta = {}
    meta.isLimited =_.get(item, 'content.solution.conditions.strategy') // 한정판매 상품인지 체크해주기
      ? ['limited'].includes(_.get(item, 'content.solution.conditions.strategy'))
      : false 
    meta.isAdult =_.get(item, 'content.solution.conditions.age') // 성인 상품인지 체크해주기
    ? ['adult'].includes(_.get(item, 'content.solution.conditions.age'))
    : false

    return (
      <Column>
        <Meta.Group>
          <Meta>
            <header>
              <small>판매</small>
            </header>

            {meta.isLimited
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>한정</strong>
                </section>
              )
              : null}

            {!meta.isLimited
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>다회</strong>
                </section>
              )
              : null}

          </Meta>
          <Meta>
            <header>
              <small>제한</small>
            </header>

            {meta.isAdult
              ? (
                <section>
                  <Caret c="success" style={{ marginRight: '0.35rem' }} />
                  <strong>성인</strong>
                </section>
              )
              : null}

            {!meta.isAdult
              ? (
                <section>
                  <Caret c="muted" style={{ marginRight: '0.35rem' }} />
                  <strong>전연령</strong>
                </section>
              )
              : null}

          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

SetupDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

SetupDetailColumnContainer.defaultProps = {
  item: {}
}

export default SetupDetailColumnContainer
