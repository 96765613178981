import Excel from 'exceljs'
import _ from 'lodash'

// items + parser 를 활용한 엑셀 파일 다운로드
class Experter {
  constructor(props) {
    this.parser = typeof props.parser === 'string'
      ? require(`./parsers/${props.parser}`).default
      : props.parser
    
    if (this.parser.prepareDatas) {
      this.items = this.parser.prepareDatas(props.items || [])
    } else {
      this.items = props.items || []
    }

    // console.log(props.items)
    this.output = {}

    this.parse = this.parse.bind(this)
    this.make = this.make.bind(this)
    this.download = this.download.bind(this)
    this.getItems = this.getItems.bind(this)
  }

  parse(item, idex, arr, rIdx){
    const columns = this.parser.columns
    return columns.map(column => column.view(item, idex, arr, rIdx, this.parser))
  }

  async make(name) {
    const now = new Date()
    
    // 파일 메타 정보 구성
    const workbook = new Excel.Workbook()
    workbook.creator = '부크크'
    workbook.lastModifiedBy = '관리자'
    workbook.created = now
    workbook.modified = now

    // 시트 추가
    /*
    Supported pageSetup settings
        Name	Value
        Letter	undefined
        Legal	5
        Executive	7
        A3	8
        A4	9
        A5	11
        B5 (JIS)	13
        Envelope #10	20
        Envelope DL	27
        Envelope C5	28
        Envelope B5	34
        Envelope Monarch	37
        Double Japan Postcard Rotated	82
        16K 197x273 mm	119

      parser.sheetProps = {
        pageSetup: {
          paperSize: 9,
          orientation: 'landscape',
          horizontalCentered: true,
          verticalCentered: true,
          margins: { top: 0, right: 0, bottom: 1, left: 0, header: 0, footer: 0 },
        },
      }
    */

    const worksheet = workbook.addWorksheet(name)
    const sheetProps = _.get(this, 'parser.sheetProps') || {}

    Object
      .keys(sheetProps)
      .forEach((key) => { worksheet[key] = sheetProps[key] })

    // 최상단 컬럼을 구성
    const columns = this.parser.columns

    if (['numeric'].includes(this.parser.mode)) {
      const maxKey = Math.max(...columns.map(column => column.key * 1))
      worksheet.columns = Array.from({ length: maxKey })
        .map((b, bIdx) => {
          const cIdx = bIdx + 1
          const column = columns.find(c => c.key * 1 === cIdx)
          const _props = column ? column.columnProps : {}
          const _col = column
            ? { header: column.header, key: column.key, ..._props }
            : { header: '', key: cIdx, ..._props }
          
          return _col
        })
    } else {
      worksheet.columns = columns
        .map((column) => {
          const _props = column.columnProps || {}
          const _col = { header: column.header, key: column.key, ..._props }
          return _col
        })
    }

    this.items.forEach((item, itemIndex) => {
      const rowIndex = itemIndex + 2
      const values = this.parse(item, itemIndex, this.items, rowIndex)
      worksheet.addRow(values)

      // 행 스타일 구성요소 배치
      // parser.rowProps = { height: 30 }
      const row = worksheet.getRow(rowIndex)

      const rowProps = _.get(this, 'parser.rowProps') || {}
      if (typeof rowProps === 'function') {
        const executer = rowProps(row, rowIndex, item, itemIndex)
        Object
          .keys(executer)
          .forEach((key) => { row[key] = executer[key] })
      } else {
        Object
          .keys(rowProps)
          .forEach((key) => { row[key] = rowProps[key] })
      }

      // 열 스타일 구성요소 배치
      /*
        {
          header: '상품명',
          key: 'subject',
          columnProps: {
            width: 100,
          },
          viewProps: {
            font: { name: 'Noto Sans KR', color: { argb: '00FFFFFF' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00111111' } },
            border: {
              top: { style: 'thin', color: { argb: '00111111' } },
              right: { style: 'thin', color: { argb: '00111111' } },
              bottom: { style: 'thin', color: { argb: '00111111' } },
              left: { style: 'thin', color: { argb: '00111111' } }
            },
            alignment: { horizontal: 'center', vertical: 'middle' }
          },
          view: (item) => {
            return item.subject || '결제내역명 없음'
          }
        },
      */
      columns
        .forEach((column, cIndx) => {
          if (!column.viewProps) { return }
          if (typeof column.viewProps === 'function') {
            const executer = column.viewProps(column, cIndx, row.getCell(column.key), item, itemIndex, row, rowIndex)
            Object.keys(executer)
              .map((key) => row.getCell(column.key)[key] = executer[key])
          } else {
            Object.keys(column.viewProps)
              .map((key) => row.getCell(column.key)[key] = column.viewProps[key])
          }
        })
      })

    // 내보내기전에 마지막으로 처리하는 트리거 함수
    if (this.parser.willWriteWorksheet) { await this.parser.willWriteWorksheet(worksheet) }
     
    return workbook.xlsx.writeBuffer({ base64: true , useStyles: true})
  }

  async download(name) {
    try {
      return this.make(name)
        .then(output => {
          const now = Date.now()
          const a = document.createElement("a")
          const data = new Blob([output], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
          const url = URL.createObjectURL(data)
          a.href = url
          a.download = `${name|| '파일 제목 없음'}.${now}.xlsx`
          document.body.appendChild(a)
          a.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
          }, 1)
        })
    } catch (error) {
      console.error(error)      
    }
  }

  getItems() {
    return this.items
  }
}

export default Experter
