import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & > div.defaultPrice { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.options { position: relative; box-sizing: border-box; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`
// 작가서비스의 가격정책에 대해서 처리
class PriceDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {}

    meta.defaultPrice =_.get(item, 'content.solution.defaultPrice')
    meta.usedOptions =_.get(item, 'content.solution.usedOptions')

    return (
      <Column>
        <div className="defaultPrice">
          <strong>{comma(meta.defaultPrice)}원</strong>
        </div>
        <div className="options">
          {meta.usedOptions
            ? <small style={{ marginRight: '0.15rem' }}>옵션가 사용중</small>
            : <small style={{ marginRight: '0.15rem' }}>옵션가 사용안함</small>}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

PriceDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

PriceDetailColumnContainer.defaultProps = {
  item: {}
}

export default PriceDetailColumnContainer
