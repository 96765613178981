import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import BookDetailWithApprovalColumn from './Columns/BookDetailWithApprovalColumn'
import BookMakeDetailWithApprovalColumn from './Columns/BookMakeDetailWithApprovalColumn'
import UserDetailWithApprovalColumn from './Columns/UserDetailWithApprovalColumn'
import ApprovalDetailColumn from './Columns/ApprovalDetailColumn'
import StatusColumn from './Columns/StatusColumn'
import BookCoverColumn from './Columns/BookCoverColumn'
import BookTextColumn from './Columns/BookTextColumn'
import BookThumbnailColumn from './Columns/BookThumbnailColumn'
import BookPreviewColumn from './Columns/BookPreviewColumn'

import Dropdown from './Dropdown'
// import Menus from './Menus'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'BookNoColumn', header: '번호', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center' } })
  columns.push({ key: 'BookDetailWithApprovalColumn', header: '도서', style: { minWidth: '280px', maxWidth: '280px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BookMakeDetailColumn', header: '판형', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start' } })
  columns.push({ key: 'UserDetailColumn', header: '등록자', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'flex-start' } })
  columns.push({ key: 'ApprovalDetailColumn', header: '제출정보', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BookCoverColumn', header: '표지(문서)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookTextColumn', header: '원고(문서)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookThumbnailColumn', header: '썸네일(상품)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookPreviewColumn', header: '미리보기(상품)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { justifyContent: 'center' } })

  const actions = {}

  // `제출완료` 단계 상태를 지닌 문서가 사용자에 의해서 변조되지 않도록 락을 거는 장치
  actions.updateBookSubmitLockContent = async (items = [], locked) => {
    if (!items || !items.length) { return alert(`작업을 시작할 문서를 선택해주셔야합니다.`) }
    if (locked && !window.confirm(`선택한 ${items.length}건의 문서에 해당하는 신청건에 대해서 작업중을 표기합니다. 해당 기능은 심사중에서만 작동합니다.`)) { return }
    if (!locked && !window.confirm(`선택한 ${items.length}건의 문서에 해당하는 신청건에 대해서 작업을 취소하면, 심사중상태로만 유지됩니다. 진행을 할까요? 해당 기능은 심사중에서만 작동합니다.`)) { return }

    // 개별적으로 던져야하도록 구성하고 넘기면 처리해준다.
    const report = await items.reduce((prev, item) => 
      prev.then(async (result) => {
        const payload = { locked }
        payload.comment = item.comment
        const output = await api.put(`/approvals/admin2/${item.id}/bookSubmitLockContent`, payload)
          .then((data) => {
            if (data.error) { return null }
            return data
          })
          .catch((e) => null)
        if (output) {
          result.success.push(item.id)
        } else {
          result.failure.push(item.id)
        }
        return result
      }),
      Promise.resolve({ success: [], failure: [] }))
      .catch(e => null)

    if (!report) { return alert(`작업상태에 대한 정보를 기록도중 문제가 발생하였습니다.`) }

    const successCount = report.success.length
    const failureCount = report.failure.length

    alert(`성공 ${successCount}건, 실패 ${failureCount}건`)

    return initialize()
  }

  // 관리자로부터 아애 도서 제출 취소 기능(바로 취소상태로 옮겨지는 기능)
  actions.deleteApproval = async (item) => {
    if (!window.confirm(`도서제출 내역을 삭제시, 제출된 도서는 임시도서로 이동하고, 제출된 문서는 취소처리, 임시상품이 삭제되며, 사용자 화면에서 이미 종결된 문서로 노출되지 않고 삭제 됩니다. 완전 소거를 할까요?`)) { return false }
    return await api.delete(`/approvals/admin2/${item.id}/bookSubmit`)
      .then((data) => {
        alert(`정상적으로 처리하였습니다.`)
        return initialize()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return initialize()
      })
  }

  // @ 최종입점 확인 메시지 전송 기능
  actions.sendBookSubmitAcceptMessage = async (item) => {
    if (!window.confirm(`해당 내역 유저에게 최종입점 확인 메시지를 보냅니다.`)) { return false }
    return await api.post(`/approvals/admin2/${item.id}/sendBookSubmitAcceptMessage`)
      .then((ret) => {
        alert(ret.message)
      })
      .catch(e => {
        console.log(`${e.message} ${e.stack}`)
        alert(`오류가 발생하였습니다.`)
      })
  }



  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize({ reload: true })) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize({ reload: true }) })
                  : handle('startAt', startMt.toDate(), () => { initialize({ reload: true }) })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize({ reload: true }) })
                  : handle('endAt', endMt.toDate(), () => { initialize({ reload: true }) })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#updateBookSubmitLockContent"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              await actions.updateBookSubmitLockContent(selecteds, true)
                .catch((e) => {
                  console.log(e.message, e.stack)
                  return alert(e.message)
                })
            }}
          >
            최초입점 작업시작
          </a>
          <a
            href="#updateBookSubmitLockContent"
            onClick={e => [e.stopPropagation(), e.preventDefault(), actions.updateBookSubmitLockContent(selecteds, false)]}
          >
            작업취소
          </a>
        </Header.Options>
          
      </Header>

      {/* 
        {nav === 'bookSubmit'
          ? (
            <Menus
              items={filterOptions.bookType.conditions}
              isActive={item => {
                if (!filters.bookType) { return false }
                return item.name === filters.bookType
              }}
              usedAll
              onActive={item => handle({ page: 1, filters: { ...filters, bookType: item.name } })}
              {...commonProps}
            />
          )
        : null} */}

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize({ reload: true }))
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize({ reload: true }))
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize({ reload: true }))
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize({ reload: true }))
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.bookNo = _.get(item, 'content.book.bookNo')
                    meta.parent = _.get(item, 'parent')
                    meta.isBrunch = _.get(item, 'content.book.brunch')
                    meta.productId = _.get(item, 'content.productId') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'BookNoColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <a
                                    href={`/products/book/${meta.productId}`}
                                    target="_blank"
                                    onClick={e => {
                                      if (!meta.productId) {
                                        return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
                                      }
                                      return
                                    }}
                                  >
                                    <strong style={{ fontSize: '0.9em' }}>
                                      {meta.bookNo || '미발급'}
                                    </strong>
                                  </a>
                                  <div>
                                    {meta.parent
                                      ? (
                                        <a
                                          href={`?keywordsOption=approvalId&keywords=${meta.parent}`}
                                          target="_blank"
                                        >
                                          <small>🚩재등록</small>
                                        </a>
                                      )
                                      :  <small>⭐신규</small>}
                                  </div>
                                  {meta.isBrunch
                                    ? (
                                    <div>
                                      <small>
                                        <small style={{ marginRight: '0.10rem' }}><strong>브런치</strong></small>
                                        <small>도서</small>
                                      </small>
                                    </div>
                                    ) : null}
                                </div>
                              )
                            case 'BookDetailWithApprovalColumn':
                              return <BookDetailWithApprovalColumn {...columnProps} />
                            case 'BookMakeDetailColumn':
                              return <BookMakeDetailWithApprovalColumn {...columnProps} />
                            case 'UserDetailColumn':
                              return <UserDetailWithApprovalColumn {...columnProps} />
                            case 'ApprovalDetailColumn':
                              return <ApprovalDetailColumn {...columnProps} />
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />
                            case 'BookCoverColumn':
                              return <BookCoverColumn {...columnProps} />
                            case 'BookTextColumn':
                              return <BookTextColumn {...columnProps} />
                            case 'BookThumbnailColumn':
                              return <BookThumbnailColumn {...columnProps} />
                            case 'BookPreviewColumn':
                              return <BookPreviewColumn {...columnProps} />
                            case 'ControlColumn':
                              return (
                                <div>
                                  {['wait', 'review', 'cancel'].includes(item.status)
                                    ? (
                                      <a
                                        href="#deleteApproval"
                                        style={{ margin: '0 0.35rem' }}
                                        onClick={e => [e.stopPropagation(), e.preventDefault(), actions.deleteApproval(item)]}
                                      >
                                        삭제
                                      </a>
                                    )
                                    : null}

                                  <a
                                    href="#sendBookSubmitAcceptMessage"
                                    style={{ margin: '0 0.35rem' }}
                                    onClick={e => [e.stopPropagation(), e.preventDefault(), actions.sendBookSubmitAcceptMessage(item)]}
                                  >
                                    입점확인요청
                                  </a>
                                </div>
                              )

                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize({ reload: true }))} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
