import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items: oItems, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {

  // 편집을 위해서 랩핑을 해준다.
  const items = oItems.map(item => {
    const obj = { ...item }
    obj.editCurrent = typeof obj.editCurrent === 'undefined' ? (item.total - item.current) : obj.editCurrent
    return obj
  })

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  // 발주번호, 도서ID, 도서명, 규격, 작가명, 발주시간, 발주량, 수령예정량, 수령, 작업(수령처리), 수령시간, 삭제관리, 상태
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'CreatedAtColumn', header: '발주시간', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ClassifyColumn', header: '구분', style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'IdentityColumn', header: '도서번호', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PurposeColumn', header: '용도', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProductColumn', header: '상품', style: {  minWidth: '618px', maxWidth: '618px' } })
  columns.push({ key: 'BookShapeColumn', header: '판형정보', style: { minWidth: '100px', maxWidth: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })

  columns.push({ key: 'TotalColumn', header: '발주량', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  if (['working'].includes(nav)) {
    columns.push({ key: 'NowColumn', header: '수령예정', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  }
  columns.push({ key: 'CurrentColumn', header: '수령', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  if (['working'].includes(nav)) {
    columns.push({ key: 'ActionColumn', header: '수령관리', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  }
  if (['finished'].includes(nav)) {
    columns.push({ key: 'EndAtColumn', header: '수령시간', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  }
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: 0, maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [processed, setProcessed] = React.useState(false)
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const productTypes = {}
  productTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  productTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  productTypes.solution = { name: 'solution', text: '작가서비스' }
  productTypes.etc = { name: 'etc', text: '기타' }

  // 로우 작동 액션들 : 수령처리, 삭제처리, 선택수령기능, 선택삭제 기능
  const actions = {}

  // 수령처리 단일건
  actions.doUpdateInvoiceQuantity = async (item = {}, value = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 내역을 수령처리할까요?`)) { return }
    const form = {}
    form.amount = value

    const result = await api.put(`/invoices/admin2/${item.id}/invoiceQuantityAmount`, { form }).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 수령처리 다중건
  actions.doUpdateInvoiceQuantityBySelecteds = async (items = selecteds) => {
    if (!items.length) { return alert(`선택한 내역이 존재하지 않습니다.`) }
    if (!window.confirm(`해당 내역(총 ${items.length}건)을 수령처리할까요?`)) { return }

    const result = await items.reduce((prev, item) => prev.then(async (report) => {
      const result = await actions.doUpdateInvoiceQuantity(item, item.editCurrent || 0, { returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }      
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) {
      alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`)
      setProcessed(false)
      return initialize()
    }

    alert(`${result.success.length}건이 성공적으로 처리하였습니다.`)
    setProcessed(false)
    return initialize()
  }

  // 선택삭제 단일건
  actions.doDeleteInvoiceQuantity = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 내역을 삭제처리할까요?`)) { return }
    const query = {}
    query.forced = false
    const result = await api.delete(`/invoices/admin2/${item.id}/invoiceQuantity?${qs.stringify(query)}`).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 선택삭제 다중건
  actions.doDeleteInvoiceQuantityBySelecteds = async (items = selecteds) => {
    if (!items.length) { return alert(`선택한 내역이 존재하지 않습니다.`) }
    if (!window.confirm(`해당 내역(총 ${items.length}건)을 삭제처리할까요?`)) { return }

    const result = await items.reduce((prev, item) => prev.then(async (report) => {
      const result = await actions.doDeleteInvoiceQuantity(item, { returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }      
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) {
      alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`)
      setProcessed(false)
      return initialize()
    }

    alert(`${result.success.length}건이 성공적으로 처리하였습니다.`)
    setProcessed(false)
    return initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 내역이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <select
              defaultValue={limit}
              onChange={e => {
                const n = e.target.value
                return window.location.href = `/invoices/working?limit=${n}`
              }}
            >
                {Array.from({ length: 10 }).map((b, idx) => {
                  const n = (idx+1) * 100
                  return <option key={`limit${idx}`} value={n}>{comma(n)}개씩 보기</option>
                })}
            </select>
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          {['working'].includes(nav)
            ? (
              <a
                href="#doUpdateInvoiceQuantityBySelecteds"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
                  setProcessed(true)
                  
                  return await actions.doUpdateInvoiceQuantityBySelecteds()
                }}>
                  선택수령
              </a>
            )
            : null}
          <a
            href="#doDeleteInvoiceQuantityBySelecteds"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
              setProcessed(true)

              return await actions.doDeleteInvoiceQuantityBySelecteds()
            }}>
              선택삭제
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentProduct = extras.currentProduct || {}

                    // 도서기본정보 및 판형정보
                    const meta = {}
                    meta.classify = '기타'
                    if (['invoice'].includes(_.get(item, 'classify'))) { meta.classify = '자동' }
                    if (['overflow'].includes(_.get(item, 'classify'))) { meta.classify = '수동' }

                    meta.bookNo = _.get(currentProduct, 'content.bookNo') || '-' // 도서번호 
                    meta.bookTitle = _.get(currentProduct, 'content.title') || '-' // 도서명
                    meta.bookAuthor = _.get(currentProduct, 'content.author') || '-' // 저자명
                    meta.userId = _.get(currentProduct, 'user')
                    meta.bookSize = _.get(currentProduct, 'content.bookSize.text') || '-' // 도서규격
                    meta.bookCoverFlap = _.get(currentProduct, 'content.cover.flap') ? `🟢 날개있음` : `⚪ 날개없음`
                    meta.bookPurpose = _.get(currentProduct, 'content.purpose') || { text: '알수없음' }
                    meta.isbn = _.get(currentProduct, 'content.isbn') || ''

                    meta.createdAt = _.get(item, 'createdAt')
                    meta.createdMt = meta.createdAt ? moment(meta.createdAt) : null

                    meta.finishedAt = _.get(item, 'finishedAt')
                    meta.finishedMt = meta.finishedAt ? moment(meta.finishedAt) : null

                    meta.total = _.get(item, 'total') || 0
                    meta.current = _.get(item, 'current') || 0
                    meta.now = (meta.total - meta.current) > 0 ? (meta.total - meta.current) : 0
                    meta.editCurrent = _.get(item, 'editCurrent')

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'CreatedAtColumn':
                              return (
                                <div>
                                  <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'ClassifyColumn':
                              return (
                                <div>
                                  {meta.classify}
                                </div>
                              )
                            case 'IdentityColumn':
                              return (
                                <div>
                                  <strong>{meta.bookNo}</strong>
                                </div>
                              )
                            case 'PurposeColumn':
                              return (
                                <div style={{ textOverflow: 'hidden', whiteSpace: 'normal', textAlign: 'center' }}>
                                  <strong>{_.get(meta, 'bookPurpose.text')}</strong>
                                  {['external'].includes(_.get(meta, 'bookPurpose.name'))
                                    ? (
                                      <div>
                                        <small>{_.get(meta, 'isbn') || 'ISBN 미기재'}</small>
                                      </div>
                                    )
                                    : null}
                                </div>
                              )
                            case 'ProductColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  <div
                                    onClick={(e) => {
                                      const id = _.get(item, 'product')
                                      const productType = _.get(item, 'productType')
                                      return window.open(`/products/${productType}/${id}`)
                                    }}
                                    style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                  >
                                    <strong>{meta.bookTitle}</strong>
                                  </div>
                                  <div
                                    onClick={(e) => {
                                      return window.open(`/users/${meta.userId}`)
                                    }}
                                    style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                  >
                                    <small>{meta.bookAuthor}</small>
                                  </div>
                                </div>
                              )
                            case 'BookShapeColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <div><strong style={{ fontWeight: 900, fontSize: '1.2em', marginRight: '0.35rem' }}>{meta.bookSize}</strong></div>
                                  <div><small>{meta.bookCoverFlap}</small></div>
                                </div>
                              )
                            case 'EndAtColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong>{meta.finishedMt ? meta.finishedMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.finishedMt ? meta.finishedMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'TotalColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong style={{ fontWeight: 900, fontSize: '1.1em' }}>{comma(_.get(meta, 'total'))}</strong>
                                </div>
                              )
                            case 'NowColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong style={{ fontWeight: 900, fontSize: '1.1em' }}>{comma(_.get(meta, 'now'))}</strong>
                                </div>
                              )
                            case 'CurrentColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong style={{ fontWeight: 900, fontSize: '1.1em' }}>
                                    {_.get(meta, 'current') ? comma(_.get(meta, 'current')) : '-'}
                                  </strong>
                                </div>
                              )
                              
                            case 'ActionColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    style={{ textAlign: 'right', marginRight: '0.35rem' }}
                                    value={`${meta.editCurrent}`}
                                    onChange={e => {
                                      const n = `${e.target.value}`.replace(/[^0-9]/g, '')
                                      const value = _.isInteger(n * 1) ? (n * 1) : meta.editCurrent
                                      const next = {}
                                      next[`items[${itemIdx}].editCurrent`] = value
                                      return handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateInvoiceQuantity(item, item.editCurrent)]
                                    }}
                                  >
                                    수령
                                  </button>
                                </div>
                              )

                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doDeleteInvoiceQuantity(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>해당 기간에 내역이 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '내역',
  nav: 'working'
}

export default List
