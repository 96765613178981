import React from 'react'
import { Helmet } from 'react-helmet-async'

import _ from 'lodash'
import api from 'services/api'
import { isId }  from 'services/utils'

import Book from './Book'
import Solution from './Solution'
import Etc from './Etc'

import { PayForm } from './utils'

// 종이도서, 전자도서, 작가서비스
// 파일교체비, 가격변경비, 기타결제 내역 불러오기
class PayFormContainer extends React.Component {

  constructor(props) {
    super(props)

    this.abortController = new AbortController()
    
    this.initialize = this.initialize.bind(this)
    this.loadItem = this.loadItem.bind(this)

    this.state = {
      loading: true, error: false, message: '',
      item: {}
    }
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController) { this.abortController.abort() }
  }

  // 데이터를 초기화해온다.
  async initialize() {
    return await this.loadItem()
  }

  // 주문을 불러온다.
  async loadItem() {
    const { itemId } = this.props
    if (!itemId && !isId(itemId)) {
      return this.setState({ item: {}, loading: false, error: true, message: `itemId가 존재하지 않거나 올바른 ID값이 아닙니다.` })
    }
    const result = await api.get(`/pays/admin2/${itemId}/detail`, { signal: this.abortController.signal }).catch(e => null)
    if (!result || result.error) {
      return this.setState({ item: {}, loading: false, error: true, message: (result && result.message) ? result.message : `데이터를 불러오는 도중 문제가 발생했습니다.` })
    }
    this.setState({ item: result.pay, loading: false, error: false, message: (result && result.message) ? result.message : '정상적으로 불러왔습니다.' })
  }

  render() {
    const  { initialize } = this
    const  { loading, error, message, item } = this.state
    if (loading) { return null }
    if (error) {
      return (
        <PayForm>
          <PayForm.Message>
            <header>처리 메시지</header>
            <section>{message}</section>
          </PayForm.Message>
        </PayForm>
      )
    }
    
    const payTypes = {}
    payTypes.paperBook = { name: 'paperBook', text: '종' }
    payTypes.electronicBook = { name: 'electronicBook', text: '전' }
    payTypes.solution = { name: 'solution', text: '작' }
    payTypes.etc = { name: 'etc', text: '기' }

    const childrenProps = { item, initialize }

    return (
      <PayForm>
        <Helmet>
          <title>({_.get(payTypes, `${item.payType}.text`)}){item.payNo} : {item.subject} 결제내역 - BOOKK</title>
        </Helmet>
        {['paperBook'].includes(item.payType) ? <Book type="paperBook" {...childrenProps} /> : null}
        {['electronicBook'].includes(item.payType) ? <Book type="electronicBook" {...childrenProps} /> : null}
        {['solution'].includes(item.payType) ? <Solution {...childrenProps} /> : null}
        {['etc'].includes(item.payType) ? <Etc {...childrenProps} /> : null}

        {/* 모달들이 들어갈 예정 */}
      </PayForm>
    )
  }
}

export default PayFormContainer
