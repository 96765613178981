import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

export const Box = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; min-width: 480px;
  @media screen and (max-device-width: 1200px) {
    min-width: auto;
  }
`

Box.Header = styled.header`
  position: relative; box-sizing: border-box;
  z-index: 1; color: ${palette('muted', 1)}; font-weight: 700;
  padding: 0.5rem 0;
`

Box.Close = styled.div`
  position: absolute; box-sizing: border-box;
  top: 1.5rem; right: 1rem; z-index: 3;
  font-size: 1.2em;
`

Box.Body = styled.div`
  position: relative; box-sizing: border-box;
`

const controlStyles = css`
  & input[type=text],
  & input[type=number],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & select {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
`

export const Form = styled.form`
  position: relative; box-sizing: border-box;
  margin: 1rem 0;
`

Form.Control = styled.div`
  position: relative; box-sizing: border-box;
  display: flex;  align-items: center;
  padding: 0.35rem 0;
  & > div.label {
    position: relative; box-sizing: border-box;
    white-space: nowrap; min-width: 90px;
    color: ${palette('muted', 2)};
    font-weight: 500;
  }
  & > div.value {
    position: relative; box-sizing: border-box;
    flex: 1 1 100%;
  }
  ${controlStyles}
`

Form.Description = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
`

export const Button = styled.button`
  position: relative; box-sizing: border-box;
  flex: 1 1 48%; margin: 0.35rem; cursor: pointer;
  padding: 0.5rem 0; text-align: center;
  background: transparent; font-size: 1.1em;
  font-weight: 900; border-radius: 3px;
  border: 1px solid ${palette('muted', 5)};
  color: ${palette('muted', 5)}; transition: all 0.3s;
  &:hover {
    color: ${palette('muted', 1)};
    border: 1px solid ${palette('muted', 1)};
  }
  &.primary {
    background: ${palette('muted', 4)};
    color: ${palette('muted', 21)}; border: 0;
    &:hover { background: ${palette('muted', 1)}; }
  }
`

Button.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
`
