import fetch from 'isomorphic-fetch'
import axios from 'axios'
import { stringify } from 'query-string'
import merge from 'lodash/merge'

const { REACT_APP_API_URL, REACT_APP_MIGRATION_URL, REACT_APP_FILE_URL } = process.env
export { REACT_APP_API_URL, REACT_APP_MIGRATION_URL, REACT_APP_FILE_URL }

export const checkStatus = (response) => {
  if (response.ok) {
    return response
  }
  const error = new Error(`${response.status} ${response.statusText}`)
  error.response = response
  throw error
}

export const checkStateText = (response) => {
  if (response.statusText || response.status === 200) {
    return response
  }
  const error = new Error(`${response.status} ${response.statusText}`)
  error.response = response
  throw error
}

export const parseData = (response) => 
  response.data ? Promise.resolve(response.data) : Promise.resolve(null)

export const parseJSON = (response) => {
  return response.json().catch((e) => {
    if (response.ok) { return }
    e.response = response
    throw e
  })
}

export const parseSettings = ({
  method = 'get', data, locale = 'ko', binary, ...otherSettings
} = {}) => {
  const headers = !binary ? {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    ...api.settings.headers
  } : {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Accept-Language': locale,
    ...api.settings.headers
  }
  const settings = !binary
    ? merge({ body: data ? JSON.stringify(data) : undefined, method, headers, redirect: "follow" }, otherSettings)
    : merge({ headers }, otherSettings)

  return settings
}

export const parseEndpoint = (endpoint, params, settings) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : (settings.base === 'api' ? REACT_APP_API_URL : REACT_APP_FILE_URL) + endpoint
  const querystring = params ? `?${stringify(params)}` : ''
  return `${url}${querystring}`
}

const api = {
  settings: { base: 'api', headers: {} },
}

api.setToken = (token) => {
  api.settings.headers = {
    ...api.settings.headers,
    Authorization: `Bearer ${token}`,
  }
}

api.unsetToken = () => {
  api.settings.headers = {
    ...api.settings.headers,
    Authorization: undefined,
  }
}

api.request = (endpoint, { params, ...settings } = {}) => {
  const localStorage = window.localStorage
  const token = localStorage.getItem('adminToken') 
  if (token) { api.setToken(token) }
  return !settings.binary
    ? fetch(parseEndpoint(endpoint, params, settings), parseSettings(settings))
      .then(checkStatus)
      .then(parseJSON)
    : axios[settings.method](parseEndpoint(endpoint, params, settings), settings.data, parseSettings(settings))
      .then(checkStateText)
      .then(parseData)
}

;['delete', 'get'].forEach((method) => {
  api[method] = (endpoint, settings) => api.request(endpoint, merge({ method }, api.settings, settings))
})

;['post', 'put', 'patch'].forEach((method) => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, merge({ method, data }, api.settings, settings))
})

;['upload'].forEach((method) => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, merge({ method: 'post', data, binary: true }, api.settings, settings))
})

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    api.settings.headers = {
      ...this.settings.headers,
      Authorization: `Bearer ${token}`,
    }
    // this.settings.headers = {
    //   ...this.settings.headers,
    //   Authorization: `Bearer ${token}`,
    // }
  },

  unsetToken() {
    api.settings.headers = {
      ...this.settings.headers,
      Authorization: undefined,
    }
  },

  request(endpoint, settings) {
    return api.request(endpoint, merge({}, this.settings, settings))
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, { method: 'post', data, ...settings })
  },

  upload(endpoint, data, settings) {
    return this.request(endpoint, { method: 'post', data, binary: true, ...settings })
  },

  get(endpoint, settings) {
    return this.request(endpoint, { method: 'get', ...settings })
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, { method: 'put', data, ...settings })
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, { method: 'patch', data, ...settings })
  },

  delete(endpoint, settings) {
    return this.request(endpoint, { method: 'delete', ...settings })
  },
})

// @ 마이그레이션 툴 연동처리 구간
api.migration = {}

api.migration.database = async (route = 'users', method = 'pairing', identities = []) => {
  const url = `${REACT_APP_MIGRATION_URL}/${route}/process/${method}`
  const headers = { 'Content-Type': 'application/json; charset=utf-8' }
  const body = JSON.stringify({ identities, options: { prepare: true } })
  return await fetch(url, { headers, body, method: 'post' })
    .then(res => res.json())
    .catch(e => { return { error: true, message: `${e.message} ${e.stack}` } })
}

// @ book, bookFileChange, solution, solutionOrder
api.migration.file = async (route = 'book', method = 'linking', identities = []) => {
  const url = `${REACT_APP_MIGRATION_URL}/files/${route}/${method}`
  const headers = { 'Content-Type': 'application/json; charset=utf-8' }
  const body = JSON.stringify({ identities, options: { prepare: true } })
  return await fetch(url, { headers, body, method: 'post' })
    .then(res => res.json())
    .catch(e => { return { error: true, message: `${e.message} ${e.stack}` } })
}

api.fetch = fetch
api.axios = axios

if (window) { window.api = api }

export default api