import React from 'react'
import PropTypes from 'prop-types'
import _, { remove } from 'lodash'
import api, { REACT_APP_FILE_URL } from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & .filePreview {
    position: relative; box-sizing: border-box;
    & > div.file {
      position: relative; box-sizing: border-box;
      padding: 0.15rem 0; transition: all 0.3s; border-radius: 0.35rem;
      cursor: pointer;
      & div.image {
        position: relative; box-sizing: border-box;
        display: flex; justify-content: center; align-items: center;
        min-height: 80px; max-height: 80px; height: 80px;
        margin: 0.35rem 0;
        & > img { height: 100%; }
      }
      & div.document {
        position: relative; box-sizing: border-box;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }
      & div.empty {
        position: relative; box-sizing: border-box; opacity: 0.3;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }
      & div.meta {
        text-align: center;
        font-size: 0.95em;
      }
      &:hover { background: ${palette('muted', 15)}; }
    }
  }

  & a.downloadButton {
    padding: 0.35rem; border-radius: 0.35rem; font-weight: 500;
    transition: all 0.3s; cursor: pointer; margin: 0.25rem 0;
    text-decoration: none;
    background: ${palette('muted', 2)}; color: ${palette('muted', 21)};
    &:hover { background: ${palette('muted', 0)}; color: ${palette('muted', 21)}; }
  }
`

// 도서상품의 표지파일을 다운로드하는 기능
class BookCoverByProductColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() {}
  
  render() {
    const { item } = this.props

    const extras = _.get(item, 'extras') || {}
    const currentProduct = _.get(extras, 'currentProduct') || {}

    // 해당 문건의 표지파일이 문서인지, 이미지인지 구분한다.
    const file = _.get(currentProduct, 'content.cover.file.path') || ''
    const createdAt = _.get(currentProduct, 'content.cover.file.createdAt') || null
    const createdMt = createdAt ? moment(createdAt) : null
    const ext = (file ? file.split('.').reverse()[0] : '').toLowerCase()

    // 메타로 제정립
    const meta = {}

    return (
      <Column>
        <a
          className={`downloadButton`}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          href={`#표지`}
          onClick={async (e) => {
            return [e.stopPropagation(), e.preventDefault(), 
              await fetch(`${REACT_APP_FILE_URL}${file}`)
                .then(response => response.blob())
                .then(blob => {
                  let url = window.URL.createObjectURL(blob)
                  let a = document.createElement('a')
                  a.href = url
                  a.download = `표지.${ext}`
                  document.body.appendChild(a);
                  a.click();    
                  a.remove();  
                })
                .catch(e => alert(`다운로드를 할 수 없습니다.`))
            ]
          }}
        >
          표지
        </a>

        <div style={{ textAlign: 'center' }}>
            {(!createdMt && file) ? (
              <>
                <div><small style={{ fontWeight: '500' }}>🙂 <strong>업로드 완료</strong></small></div>
              </>
            ) : null}
            {(!createdMt && !file) ? (
              <>
                <div><small style={{ fontWeight: '500' }}>🥶 <strong>미업로드</strong></small></div>
              </>
            ) : null}
        </div>
        
        <div style={{ textAlign: 'center' }}>
          {createdMt ? (
            <>
              <div><small style={{ fontWeight: '500' }}><strong>{createdMt.format('YYYY.MM.DD')}</strong></small></div>
              <div><small>{createdMt.format('HH:mm')}</small></div>
            </>
          ) : null}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookCoverByProductColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

BookCoverByProductColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default BookCoverByProductColumnContainer
