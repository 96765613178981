import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Wrapper, Header, Items, Item, Lnr } from './utils'

const Sorts = ({
  header, items, onActive, onCancel, current, style, ...props
}) => {
  const isActive = (item) => current && (current.includes(item.name) || current.includes(`-${item.name}`))
  return (
    <Wrapper style={style}>
      <Items>
        {items.map((item, index) => {
          return (    
            <Item
              key={`Sorts_items_${index}`} href={`#${item.name}`}
              className={isActive(item) ? 'active' : ''}
              onClick={e => [e.stopPropagation(), e.preventDefault(), onActive(item)]}
            >
              <span style={{ marginRight: '0.25rem' }}>{item.text}</span>
              {isActive(item) ? (
                <>
                  <small>{current.includes(`-${item.name}`) ? _.get(item, 'desc.text') : _.get(item, 'asc.text')}</small>
                  <small onClick={e => [e.stopPropagation(), e.preventDefault(), onCancel(item)]}><Lnr c="cross" /></small>
                </>
              ) : null}
            </Item>
          )
        })}
      </Items>
    </Wrapper>
  )
}

Sorts.propTypes = {
  header: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object),
  onActive: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.object,
}

Sorts.defaultProps = {
  header: null,
  items: [],
  isActive: () => {},
  onActive: () => {},
  onCancel: () => {},
  style: {},
}

export default Sorts
