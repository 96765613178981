import React from 'react'
import api from 'services/api'
import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'

import { Box, Dashboard, Item } from './utils.js'

const { REACT_APP_FILE_URL } = process.env

function BackupScheduler({ ...props }) {
  
  const initialDbScheduler = { live: false, files: [], pending: false }
  const initialFileScheduler = { live: false, folders: [], pending: false }
  const [state, setState] = React.useState({ dbScheduler: initialDbScheduler, fileScheduler: initialFileScheduler })

  const actions = {}

  // @ 데이터베이스 백업스케쥴러 작동
  actions.startBackupScheduler = async () => {
    if (!window.confirm(`🙆‍♂️ 스케쥴러를 실행할까요? 이미 실행되어 있다면 아무런 변화가 없습니다.`)) { return }
    await api.get('/backups/scheduler-start')
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })
    await actions.initialize()
  }

  // @ 데이터베이스 백업스케줄러 중지
  actions.killBackupScheduler = async () => {
    if (!window.confirm(`🙅‍♀️ 스케쥴러를 중지할까요? 이미 중지되어 있다면 아무런 변화가 없습니다.`)) { return }
    await api.get('/backups/scheduler-kill')
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })
    await actions.initialize()
  }

  // @ 데이터베이스 수동백업
  actions.executeSnapshot = async () => {
    if (_.get(state, 'dbScheduler.pending')) { return alert(`작동중입니다. 여러번 누르지 마세요. 😡`) }
    if (!window.confirm(`🧞‍♂️ 데이터베이스를 현재시점으로 수동으로 백업할까요? 응답이 길 수 있습니다. 기다려야합니다. 진행할까요?`)) { return }
    
    const nextState = { ...state }
    nextState.dbScheduler = nextState.dbScheduler
    nextState.dbScheduler.pending = true
    setState(nextState)

    await api.get('/backups/execute-snapshot')
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })

      await actions.initialize()
  }

  // @ 데이터베이스 스케쥴러 상태값 불러오기
  actions.getDatabaseBackupScheduler = async () => {
    return await api.get('/backups/scheduler')
      .catch((e) => { return { scheduler: initialDbScheduler.scheduler } })
  }

  // @ 데이터베이스 스케쥴러 상태값 불러오기
  actions.getDBBackupFiles = async () => {
    return await api.get('/backups/files')
      .catch((e) => { return { files: [] } })
  }












  const fileAccessQuery = { access_token: '--bookkFileBackup0725' }

  // @ 데이터베이스 백업스케쥴러 작동
  actions.startFileBackupScheduler = async () => {
    if (!window.confirm(`🙆‍♂️ 스케쥴러를 실행할까요? 이미 실행되어 있다면 아무런 변화가 없습니다.`)) { return }
    const url = `${REACT_APP_FILE_URL}/backups/scheduler-start?${qs.stringify(fileAccessQuery)}`
    await api.fetch(url)
      .then(res => res.json())
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })
    await actions.initialize()
  }

  // @ 데이터베이스 백업스케줄러 중지
  actions.killFileBackupScheduler = async () => {
    if (!window.confirm(`🙅‍♀️ 스케쥴러를 중지할까요? 이미 중지되어 있다면 아무런 변화가 없습니다.`)) { return }
    const url = `${REACT_APP_FILE_URL}/backups/scheduler-kill?${qs.stringify(fileAccessQuery)}`
    await api.fetch(url)
      .then(res => res.json())
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })
    await actions.initialize()
  }

  // @ 데이터베이스 수동백업
  actions.executeSyncFiles = async () => {
    if (_.get(state, 'fileScheduler.pending')) { return alert(`작동중입니다. 여러번 누르지 마세요. 😡`) }
    if (!window.confirm(`🧞‍♂️ 파일시스템 현재시점으로 수동으로 동기화 해둘까요? 응답이 길 수 있습니다. 기다려야합니다. 진행할까요?`)) { return }
    
    const nextState = { ...state }
    nextState.fileScheduler = nextState.fileScheduler
    nextState.fileScheduler.pending = true
    setState(nextState)

    await api.get(`${REACT_APP_FILE_URL}/backups/execute-sync?${qs.stringify(fileAccessQuery)}`)
      .catch((e) => { return { error: true, message: `${e.message} ${e.stack}` } })

      await actions.initialize()
  }

  // @ 데이터베이스 스케쥴러 상태값 불러오기
  actions.getFileBackupScheduler = async () => {
    const url = `${REACT_APP_FILE_URL}/backups/scheduler?${qs.stringify(fileAccessQuery)}`
    return await api.fetch(url)
      .then(res => res.json())
      .catch((e) => { return { scheduler: initialDbScheduler.scheduler } })
  }

  // @ 데이터베이스 스케쥴러 상태값 불러오기
  actions.getFileBackupFolders = async () => {
    const url = `${REACT_APP_FILE_URL}/backups/folders?${qs.stringify(fileAccessQuery)}`
    return await api.fetch(url)
      .then(res => res.json())
      .catch((e) => { return { folders: initialDbScheduler.folders } })
  }

  // @ 전체 목록 새로고침
  actions.initialize = async () => {
    // @ DB 백업 스케쥴러에 상태와 파일들을 가져온다.
    const db = await actions.getDatabaseBackupScheduler()
    const dbFiles = await actions.getDBBackupFiles()
    const dbScheduler = {}
    dbScheduler.pending = false
    dbScheduler.scheduler = db.scheduler || initialDbScheduler.live
    dbScheduler.files = dbFiles.files || initialDbScheduler.files

    const fileScheduler = {}
    const file = await actions.getFileBackupScheduler()
    const fileFolders = await actions.getFileBackupFolders()
    fileScheduler.pending = false
    fileScheduler.scheduler = file.scheduler || initialFileScheduler.live
    fileScheduler.folders = fileFolders.folders || initialFileScheduler.folders
    

    const nextState = { ...state }
    nextState.dbScheduler = dbScheduler
    nextState.fileScheduler = fileScheduler

    setState(nextState)
  }

  React.useEffect(() => {
    actions.initialize()
  }, [])

  const dbScheduler = state.dbScheduler
  const dbFiles = _.get(state, 'dbScheduler.files') || []
  const dbFileCount = _.get(dbScheduler, 'files.length') || 0

  const fileScheduler = state.fileScheduler
  const fileFolders = _.get(state, 'fileScheduler.folders') || []

  return (
    <Box>
      <Box.Grid>
        <Box.Item>
          <Box.Lead>
            데이터베이스 <small>총 <strong>{dbFileCount}개</strong> 보관중</small>
          </Box.Lead>
          <Dashboard>
            <Dashboard.State>
              {_.get(dbScheduler, 'scheduler.live')
                ? <span>🙆‍♂️ 작동중...</span>
                : <span>🙅‍♀️ 비작동중... <small style={{ color: '#666' }}>재기동시, 1분 내외 소요, 서버 리부팅 및 장애시 해당시점 자동 스케쥴러 및 백업작동</small></span>}
            </Dashboard.State>
            <Dashboard.Buttons>
              <a
                href="#start-database"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.startBackupScheduler()
                }}
              >
                🙆‍♂️ 작동
              </a>
              <a
                href="#stop-database"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.killBackupScheduler()
                }}
              >
                🙅‍♀️ 중지
              </a>
              <a
                href="#execute-database-backup"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.executeSnapshot()
                }}
              >
                ⚡ 수동백업
              </a>
            </Dashboard.Buttons>
          </Dashboard>
          <Item.Group>
            <Item className="header">
              <Item.Col className="fully">
                백업 파일명
              </Item.Col>
              <Item.Col style={{ minWidth: '130px', maxWidth: '130px', textAlign: 'center' }}>
                용량
              </Item.Col>
              <Item.Col style={{ minWidth: '160px', maxWidth: '160px', textAlign: 'center' }}>
                생성일자
              </Item.Col>
            </Item>
            {dbFiles.map((dbFile, idx) => {
              return (
                <Item key={`db-files-${idx}`}>
                  <Item.Col className="fully">
                    📀 {dbFile.fileName || '파일명 없음'}
                  </Item.Col>
                  <Item.Col style={{ minWidth: '130px', maxWidth: '130px', textAlign: 'center' }}>
                    <strong>{dbFile.fileSize || '0 MB'}</strong>
                  </Item.Col>
                  <Item.Col style={{ minWidth: '160px', maxWidth: '160px', textAlign: 'center' }}>
                    {dbFile.bFt}
                  </Item.Col>
                </Item>
              )
            })}
          </Item.Group>

          <div style={{ padding: '1rem' }}>
            🔥구워지는데, <strong style={{ color: '#111' }}>약 2.5 분정도</strong> 소요됩니다. (4GB 기준)
          </div>

          <div style={{ margin: '0.5rem', padding: '1.25rem', opacity: '0.6', fontSize: '0.7em', background: '#f1f1f1', borderRadius: '0.5rem' }}>
              export password='몽고디비패스워드'<br/>
              mongorestore --gzip --uri="mongodb://127.0.0.1:27017" --db api --authenticationDatabase admin --username "계정명" --password $password --archive=/home/bookkcokr/Dropbox/backups/202307241500.gz
          </div>

          <div style={{ margin: '0.5rem', padding: '1.25rem', opacity: '0.6', fontSize: '0.7em', background: '#f1f1f1', borderRadius: '0.5rem' }}>
              $password = '몽고디비패스워드'<br/>
              utils\\mongo-tools\\bin\\mongorestore.exe --authenticationDatabase admin --username "계정명" --password $password  --archive=..\\archives\\📀파일질라로 다운로드 받은.gz
          </div>
        </Box.Item>

        <Box.Item>
          <Box.Lead>
            파일시스템
          </Box.Lead>
          <Dashboard>
            <Dashboard.State>
              {_.get(fileScheduler, 'scheduler.live')
                ? <span>🙆‍♂️ 4시간 간격 동기화중...</span>
                : <span>🙅‍♀️ 비작동중... <small style={{ color: '#666' }}>재기동시, 1분 내외 소요</small></span>}
            </Dashboard.State>
            <Dashboard.Buttons>
              <a
                href="#start-file"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.startFileBackupScheduler()
                }}
              >
                🙆‍♂️ 작동
              </a>
              <a
                href="#stop-file"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.killFileBackupScheduler()
                }}
              >
                🙅‍♀️ 중지
              </a>
              <a
                href="#execute-file-backup"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return actions.executeSyncFiles()
                }}
              >
                ⚡ 수동 동기화
              </a>
            </Dashboard.Buttons>
          </Dashboard>

          <Item.Group style={{ marginBottom: '3rem' }}>
            <Item className="header">
              <Item.Col style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}>
                <strong>슬롯</strong>
              </Item.Col>
              <Item.Col className="fully">
                파일시스템 아카이브 경로
              </Item.Col>
              <Item.Col style={{ minWidth: '160px', maxWidth: '160px', textAlign: 'center' }}>
                변경일자
              </Item.Col>
            </Item>

            {fileFolders.map((fileFolder, idx) => {
              const bNo = idx + 1
              const mMt = moment(fileFolder.atime)
              return (
                <Item key={`public-files-${idx}`}>
                  <Item.Col style={{ minWidth: '60px', maxWidth: '60px', textAlign: 'center' }}>
                    <strong>{bNo}</strong>
                  </Item.Col>
                  <Item.Col className="fully">
                    <span>🗂️ {fileFolder.fileName ? `file-backups/${fileFolder.fileName}/public` : '폴더그룹 없음'}</span>
                  </Item.Col>
                  <Item.Col style={{ minWidth: '160px', maxWidth: '160px', textAlign: 'center' }}>
                    {mMt ? mMt.format('YYYY.MM.DD HH:mm') : `0000.00.00 00:00`}
                  </Item.Col>
                </Item>
              )
            })}
            
          </Item.Group>

          <div style={{ padding: '1rem' }}>
            <strong>🗂️ 슬롯1</strong> <small style={{ color: '#999' }}>일월</small>, <strong>🗂️ 슬롯2</strong> <small style={{ color: '#999' }}>화수목</small>, <strong>🗂️ 슬롯3</strong> <small style={{ color: '#999' }}>금토</small>
          </div>

          <div style={{ margin: '0.5rem', padding: '1.25rem', opacity: '0.6', fontSize: '0.7em', background: '#f1f1f1', borderRadius: '0.5rem' }}>
              🧙‍♂️ "안돼 돌아가, 개발서버에서는 안돌아가게 해두었다오, 사고방지~"
          </div>
          
        </Box.Item>
      </Box.Grid>
    </Box>
  )
}

export default BackupScheduler