import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; 
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class DeliveryColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    // 우편번호, 동주소, 상세주소
    const meta = {
      zip: _.get(item, 'delivery.zip') || '00000',
      address1: _.get(item, 'delivery.address1') || '동주소 없음',
      address2: _.get(item, 'delivery.address2') || '상세주소 없음'
    }

    return (
      <Column>
        <div style={{ minWidth: 0, width: '100%', maxWidth: '100%' }}>
          <Meta.Group style={{ minWidth: 0, width: '100%', maxWidth: '100%' }}>
            <Meta style={{ minWidth: 0, width: '100%', maxWidth: '100%' }}>
              <section>
                <strong style={{ marginRight: '0.25rem' }}>{meta.address1}</strong>
              </section>
              <header>
                <small style={{ marginRight: '0.25rem' }}>{meta.address2}</small>
                <small>({meta.zip})</small>
              </header>
            </Meta>
          </Meta.Group>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

DeliveryColumnContainer.propTypes = {
  item: PropTypes.object,
}

DeliveryColumnContainer.defaultProps = {
  item: {}
}

export default DeliveryColumnContainer
