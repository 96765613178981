import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import Link from 'components/utils/Link'
import Logo from 'components/utils/Logo'
export { Lnr, Link, Logo }

export const Page = styled.section`
  position: relative; box-sizing: border-box; min-height: 100vh;
  background: ${palette('muted', 19)};
`

const StyledBreadCrumb = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; border-bottom: 1px dotted ${palette('muted', 15)};
  padding: 0.75rem; font-family: ${font('primary')};
  overflow-x: auto;
  & > div {
    position: relative; box-sizing: border-box;
    color: ${palette('muted', 6)};
    white-space: nowrap;
    &:after {
      position: relative; box-sizing: border-box;
      color: ${palette('muted', 16)};
      content: '▶';  padding: 0.5rem; font-size: 0.2em;
    }
    &:last-child { 
      color: ${palette('muted', 1)}; font-weight: 700;
      &:after { display: none; }
    }
  }
`

export const BreadCrumb = ({ path }) => {
  return (
    <StyledBreadCrumb>
      {path.map((loc, index) => <div key={`BreadCrumb_${index}`}>{loc}</div>)}
    </StyledBreadCrumb>
  )
}

BreadCrumb.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
}

export const Aside = styled.aside`
  position: fixed; box-sizing: border-box; z-index: 1;
  min-height: max-content; overflow: hidden; height: 100%;
  background: ${palette('muted', 19)}; transition: all 0.6s;
  border-right: 1px solid ${palette('muted', 12)};
  box-shadow: 0 0 20px ${palette('muted', 15)};
  z-index: 99;
  &.active {
    border-right: 1px solid ${palette('muted', 10)};
    box-shadow: 0 0 15px ${palette('muted', 15)};
  }
`

Aside.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem;
`

Aside.Footer = styled.footer`
  box-sizing: border-box;
  position: absolute; left: 0; bottom: 0;
  text-align: center; width: 100%; padding: 1rem; 
  & a {
    display: block; box-sizing: border-box; transition: all 0.3s;
    font-size: 0.75em; color: ${palette('muted', 4)}; text-decoration: none;
    &:hover { color: ${palette('muted', 1)}; }
  }
`

export const Tree = styled.div`
  position: relative; box-sizing: border-box;
`

Tree.Header = styled.div`
  position: relative; box-sizing: border-box; z-index: 10; font-family: ${font('primary')};
  padding: 1rem 0.75rem; text-align: center; min-width: 100px; max-width: 100px; font-size: 1.3em;
  cursor: pointer; transition: all 0.6s;
  & > div.text { font-weight: 700; color: ${palette('muted', 3)}; }
  &.active > div.text { font-weight: 900; color: ${palette('muted', 1)}; text-decoration: underline; }
`

Tree.Items = styled.div`
  position: absolute; right: -230px; top: 0; box-sizing: border-box;
  min-width: 180px; max-width: 180px; z-index: 1;
  padding: 0.55rem; display: none; opacity: 0;
`

Tree.Item = styled.a`
  position: relative; box-sizing: border-box; font-family: ${font('primary')};
  display: block; text-decoration: none; font-weight: 300; transition: all 0.3s;
  padding: 0.768rem 0.25rem; color: ${palette('muted', 6)}; cursor: pointer; font-size: 1.15em;
  &:hover { text-decoration: none; color: ${palette('muted', 1)}; font-weight: 700; }
`

const expandCss = css`
  width: 300px;
  display: block; z-index: 9;
  & > ${Tree.Items} {
    display: block; opacity: 1; z-index: 5; right: 0;
    ${props => props.direction === 'bottom' ? `top: auto; bottom: 0;` : ``}
    transition-delay: 0.9s; transition: all 0.6s;
  }
`
Tree.Group = styled.div`
  position: relative; box-sizing: border-box; z-index: 1; transition-delay: 0.9s; transition: all 0.6s;
  &.opened {
    ${({ expand }) => expand && expandCss};
  };
`

export const Nav = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; margin: -0.25rem;
`

Nav.Items = styled.nav`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; display: flex; overflow-x: auto; padding: 0.75rem 0.75rem;
  @media screen and (max-width: 960px) { overflow-x: auto; }
`

Nav.Item = styled.a`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; min-width: 0; text-align: center; white-space: pre; padding: 0.75rem; font-weight: 700;
  text-decoration: none; font-family: ${font('primary')}; font-size: 1.2em; color: ${palette('muted', 5)}; 
  border-bottom: 2px solid transparent; border-bottom: 2px solid ${palette('muted', 16)};
  &:hover { color: ${palette('darkblue', 5)}; }
  &.active {
    color: ${palette('darkblue', 5)}; font-weight: 900;
    border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;
    border-bottom: 2px solid ${palette('darkblue', 3)};
  }
  @media screen and (max-width: 960px) { min-width: max-content; }
`

export const Container = styled.main`
  position: relative; box-sizing: border-box; padding: 1.5rem; z-index: 1;
  margin-left: 120px; min-height: 100vh; overflow-x: hidden;
  background: ${palette('muted', 20)}; box-shadow: 0 0 7px ${palette('muted', 19)};
  border-left: 1px solid ${palette('muted', 14)};
  & > * { max-width: 1980px; margin: 0 auto; }
  @media screen and (max-width: 980px) {
    &.wide { min-width: 1920px; }
  }
`
