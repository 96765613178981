import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import UserFindAddressSnippet from 'components/snippets/UserFindAddressSnippet'
import UserRegisterNumberSnippet from 'components/snippets/UserRegisterNumberSnippet'

import SummaryLeft from './SummaryLeft'
import SummaryRight from './SummaryRight'
import Panels from './Panels'
import UserLogs from './UserLogs'

import { DialogSet } from './utils'

const Form = ({ admin, location, history, match, item, initialize, handle, handleSave }) => {
  const [sider, toggleSider] = React.useState({ name: '', options: {}, opened: false })
  const openSider = (name = '', options = {}) => toggleSider({ name, options, opened: true })
  const closeSider = () => toggleSider({ name: '', options: {}, opened: false })
  const siderProps = { sider, openSider, closeSider }

  const commonProps = {
    admin, location, history, match,
    parent: { item, initialize, handle, handleSave, ...siderProps }
  }

  return (
    <DialogSet>
      {sider.opened ? (
        <>
          <DialogSet.Cover onClick={e => closeSider()}></DialogSet.Cover>
          <DialogSet.Sider>
            {sider.name === 'userFindAddress' ?
              <UserFindAddressSnippet
                {...commonProps}
                onSelected={_.get(sider, 'options.onSelected') || (() => {})}
              />
              : null}
            {sider.name === 'userRegisterNumber' ?
              <UserRegisterNumberSnippet
                {...commonProps}
                onSaved={(data) => {
                  const next = { registrationNumber: { ...item.registrationNumber, ...data } }
                  if (next.registrationNumber.certed) {
                    next['activatedToIdentity'] = true
                    next['activatedToIdentityAt'] = next.registrationNumber.updatedAt || new Date()
                  } else {
                    next['activatedToIdentity'] = null
                    next['activatedToIdentityAt'] = null
                  }
                  return handle({ 'item.registrationNumber': data }, () => handleSave(next))
                }}
                closeSider={() => closeSider()}
              />
              : null}
          </DialogSet.Sider>
        </>
      ) : null}
      <DialogSet.Aside>
        <SummaryLeft {...commonProps} />
      </DialogSet.Aside>
      <DialogSet.Main>
        <Panels {...commonProps} />
        <UserLogs item={item} />
      </DialogSet.Main>
      <DialogSet.Aside>
        <SummaryRight {...commonProps} />
      </DialogSet.Aside>
    </DialogSet>
  )
}

Form.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  item: PropTypes.object,
  initialize: PropTypes.func ,
  handle: PropTypes.func,
  handleSave: PropTypes.func  
}

Form.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},

  item: {},
  initialize: () => {},
  handle: () => {},
  handleSave: () => {}
}

export default Form