import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  {
    header:  '도서번호',
    key: 'bookNo',
    view: (item) => _.get(item, 'extras.currentBook.bookNo')
  },
  {
    header:  '도서명',
    key: 'bookTitle',
    view: (item) => _.get(item, 'extras.currentBook.title')
  },
  {
    header:  '분류',
    key: 'bookGenre',
    view: (item) => _.get(item, 'extras.currentBook.genre.text')
  },
  {
    header:  '저자명',
    key: 'bookAuthor',
    view: (item) => _.get(item, 'extras.currentBook.author')
  },
  {
    header:  '출판사명',
    key: 'bookPublisher',
    view: (item) => _.get(item, 'extras.currentBook.publisher')
  },
  {
    header:  'ISBN',
    key: 'bookIsbn',
    view: (item) => _.get(item, 'extras.currentBook.isbn')
  },
  {
    header:  '책소개',
    key: 'bookDetail',
    view: (item) => _.get(item, 'extras.currentBook.detail')
  },
  {
    header:  '목차',
    key: 'bookIndex',
    view: (item) => _.get(item, 'extras.currentBook.index')
  },
  {
    header:  '작가소개',
    key: 'bookIntroduce',
    view: (item) => _.get(item, 'extras.currentBook.introduce')
  },
  {
    header:  '출판사서평',
    key: 'bookPublisherComment',
    view: (item) => _.get(item, 'extras.currentBook.publisherComment')
  },
  {
    header:  '정가',
    key: 'bookBasicPrice',
    view: (item) => _.get(item, 'extras.currentBook.basicPrice')
  },
  {
    header:  '판형정보',
    key: 'bookSize',
    view: (item) => {
      if (_.get(item, 'extras.currentBook.type') === 'electronicBook') { return `전자책` }
      return _.get(item, 'extras.currentBook.bookSize.text') || `기타`
    }
  },
  {
    header:  '성인물',
    key: 'bookAgeLimit',
    view: (item) => {
      return (_.get(item, 'extras.currentBook.ageLimit') && _.get(item, 'extras.currentBook.ageLimit') === 'adult')
        ? `Y`
        : ''
    }
  },
  {
    header:  '출판일',
    key: 'bookPublishedAt',
    view: (item) => {
      return _.get(item, 'extras.currentBook.publishedAt')
        ? moment(_.get(item, 'extras.currentBook.publishedAt')).format('YYYYMMDD')
        : ''
    }
  },
  {
    header:  '제본방식',
    key: 'bookCoverFlap',
    view: (item) => _.get(item, 'extras.currentBook.cover.flap') ? `날개있음` : `날개없음`
  },
  {
    header:  '코팅종류',
    key: 'bookCoating',
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const book = extras.currentBook || {}
      if (['gloss'].includes(_.get(book, 'cover.material.coating'))) { return `유광코팅` }
      return `무광코팅`
    }
  },
  {
    header:  '표지재질',
    key: 'bookCoverMaterial',
    view: (item) => {
      if (_.get(item, 'extras.currentBook.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'extras.currentBook.cover.material.text')
    }
  },
  {
    header:  '내지재질',
    key: 'bookTextMaterial',
    view: (item) => {
      if (_.get(item, 'extras.currentBook.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'extras.currentBook.text.material.text')
    }
  },
  {
    header:  '칼라/흑백',
    key: 'bookTextColor',
    view: (item) => {
      // 전자책은 내지 모두 컬러기 때문에 그대로 가져다 쓴다.
      return _.get(item, 'extras.currentBook.text.color.text')
    }
  },
  {
    header:  '페이지수',
    key: 'bookTextPageCount',
    view: (item) => {
      // 페이지수 구성 완료
      return _.get(item, 'extras.currentBook.text.pageCount')
        ? Number(_.get(item, 'extras.currentBook.text.pageCount'))
        : 0
    }
  },
  {
    header:  '부분칼라적용',
    key: 'bookTextSomeColor',
    view: (item) => ''
  },
  {
    header:  '신청외부유통',
    key: 'retail',
    view: (item) => {
      return _.get(item, 'content.retail.text') || '알수없음'
    }
  },
  {
    header:  '진행목적',
    key: 'approvalClassify',
    view: (item) => {
      return `판매중지`
    }
  },
  {
    header:  '신청상태',
    key: 'approvalStatus',
    view: (item) => {
      const status = _.get(item, 'status') || `none`
      if (status === 'wait') { return `요청중` }
      if (status === 'review') { return `처리중` }
      if (status === 'reject') { return `반려됨` }
      if (status === 'accept') { return `처리완료` }
      if (status === 'cancel') { return `취소됨` }
      return `알수없음`
    }
  },
  {
    header:  'KC마크여부',
    key: 'kcCerted',
    view: (item) => {
      return _.get(item, 'extras.currentBook.kcCerted')
        ? `Y`
        : `N`
    }
  },
  {
    header:  '교보ISBN',
    key: 'kyoboIsbn',
    view: (item) => {
      // 반드시 최신정보를 items에 붙여서 불러와줘야 실행이 가능함.
      const retailIsbns = _.get(item, 'extras.currentBook.retailIsbns') || []
      const retailIsbn = retailIsbns.find(retailIsbn => retailIsbn.name === 'kyobo')
      return retailIsbn && retailIsbn.name
        ? retailIsbn.isbn
        : ''
    }
  },
  {
    header:  '외부규격',
    key: 'bookExtraSize',
    view: (item) => {
      return _.get(item, 'extras.currentBook.extraSize')
        ? 'Y'
        : 'N'
    }
  },
  {
    header:  '신청일',
    key: 'createdAt',
    view: (item) => {
      const createdAt = _.get(item, 'createdAt')
      return createdAt
        ? moment(createdAt).format('YYYYMMDD')
        : ''
    }
  }
]

export default parser



