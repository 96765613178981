import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all; text-align: center;
  font-family: ${font('primary')};

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 도서정보를 표현하는 컴포넌트 컬럼, Approval Structure 기반으로 짜여짐
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 저자명. 종이책전자책 구분, 소비자가격, 출판목적
class BookNoColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {
      payId: _.get(item, 'id'),
      subject: _.get(item, 'subject') || '결제내역명 없음',
      payType: _.get(item, 'payType') === 'paperBook'
        ? '종이도서'
        : (_.get(item, 'payType') === 'electronicBook' ? '전자도서' : '기타'),
    }

    // 첫번째 주문내역을 불러오기
    const firstOrder = item.currentOrders
      ? item.currentOrders[0] || {}
      : {}

    // 첫번쨰 주문내역의 content에서 도서정보를 불러온다.
    meta.bookNo = _.get(firstOrder, 'content.bookNo') || '0'
    
    return (
      <Column>
        <strong style={{ marginRight: '0.15rem' }}>{meta.bookNo}</strong>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookNoColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookNoColumnContainer.defaultProps = {
  item: {}
}

export default BookNoColumnContainer
