import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class DownloadColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const orders = item.currentOrders || []
    const downloadedOrders = orders.filter(o => o.isDownload)

    // 주문내역을 불러오고, 주문내역에서 다운로드한 종목과 아닌종목을 나누기
    const meta = {}
    meta.downloadedOrderCount = downloadedOrders.length
    meta.orderCount = orders.length

    return (
      <Column>
        <div>
          <Meta.Group>
            <Meta style={{ minWidth: 0, maxWIdth: '100%' }}>
              <section>
                <strong style={{ marginRight: '0.25rem' }}>{comma(meta.downloadedOrderCount)} / {comma(meta.orderCount)}</strong>
                <small>종</small>
              </section>
              <section>
                <small>다운로드 완료</small>
              </section>
            </Meta>
          </Meta.Group>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

DownloadColumnContainer.propTypes = {
  item: PropTypes.object,
}

DownloadColumnContainer.defaultProps = {
  item: {}
}

export default DownloadColumnContainer
