import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import Caret from 'components/utils/Caret'
import Pager from 'components/utils/Pager'
import DataSet from 'components/utils/DataSet'
import Copiable from 'components/utils/Copiable'
import ProgressModal  from 'components/utils/ProgressModal'

export { Lnr, Pager, DataSet, Caret, Copiable, ProgressModal }

export const Task = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0.75rem; max-width: max-content;
  margin: 1rem 0.5rem; margin-bottom: -0.5rem; border-radius: 5px;
  background: ${palette('muted', 18)}; color: ${palette('muted', 6)}; font-size: 0.9em;
  & > strong { color: ${palette('muted', 1)}; }
`

export const Header = styled.header`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; padding: 1rem 0.5rem;
`

Header.Search = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 auto; transition: all 0.3s; overflow-x: auto;
  & > div.container {
    position: relative; box-sizing: border-box; transition: all 0.3s;
    & > input {
      position: relative; box-sizing: border-box; font-family: ${font('primary')}; margin: 0.5rem;
      outline: none; border: 0; background: transparent; color: ${palette('muted', 1)}; 
      font-size: 1.8em; font-weight: 700; letter-spacing: -0.1rem;
    }
  }
  & > div.tools {
    position: relative; box-sizing: border-box; display: flex;
    padding: 1rem 0;
    & > a {
      position: relative; box-sizing: border-box; font-size: 0.9em; white-space: nowrap;
      padding: 0.35rem 0.55rem; text-decoration: none; color: ${palette('muted', 6)}; border-radius: 5px;
      &.active { background: ${palette('muted', 2)}; font-weight: 700; color: ${palette('muted', 20)}; }
    }
  }
  & > div.selector {
    display: flex; position: relative; box-sizing: border-box; margin: 0.5rem 0;
    & > input {
      margin: 0.5rem; border: 0; box-sizing: border-box; display: block; outline: none;
      font-size: 1.1rem; font-weight: 700; letter-spacing: -0.1rem; padding: 0.35rem;
      background: ${palette('muted', 18)}; border-radius: 5px; min-width: 180px; max-width: 180px;
      color: ${palette('muted', 1)}; font-family: ${font('primary')};
    }
  }
`

Header.OptionDrawer = styled.div`
  display: none; box-sizing: border-box; position: absolute; right: 0; top: 99.99%; overflow: hidden;
  font-family: ${font('primary')};
  border-radius: 0.5rem; box-shadow: 0 0 5px ${palette('muted', 10)}; text-decoration: none; min-width: 240px; z-index: 99;
  &:hover { color: ${palette('darkblue', 3)}; }
  & > a {
    display: block; padding: 0.75rem 1rem; text-decoration: none; cursor: pointer;
    color: ${palette('muted', 5)}; background: ${palette('muted', 20)};
    &:hover { color: ${palette('muted', 1)}; background: ${palette('muted', 18)}; font-weight: 700; }
  }
`

Header.Options = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; min-width: max-content;

  & > a {
    position: relative; box-sizing: border-box;
    font-family: ${font('primary')};
    text-decoration: none; padding: 0.5rem 0.95rem; margin: 0.25rem;
    border: 1px solid ${palette('muted', 8)}; border-radius: 3px;
    color: ${palette('muted', 6)}; transition: all 0.3s;
    ${({ cursor }) => cursor && `cursor: ${cursor}`};
    &:hover {
      color: ${palette('darkblue', 3)};
      & > ${Header.OptionDrawer} { display: block; }
    }
  }
  
`
export const Items = styled.section`
  position: relative; box-sizing: border-box;
  @media screen and (max-device-width: 960px) {
    & > * { min-width: 1100px; }
  }
`

Items.Tools = styled.main`
  position: relative; box-sizing: border-box;
  display: flex; margin-bottom: 0.5rem;
  overflow-y: hidden; overflow-x: auto;
  & > * {
    transition: all 0.3s;
    flex: 1 1 100%; min-width: max-content; max-width: max-content;
  }
`

Items.NotFound = styled.div`
  position: relative; box-sizing: border-box; padding: 3rem;
  flex: 1 1 100%; border-bottom: 1px dotted ${palette('muted', 10)};
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 1.1em; letter-spacing: -0.05rem; color: ${palette('darkblue', 5)};
    padding-bottom: 0.5rem;
  }
  & > section {
    position: relative; box-sizing: border-box;
    font-size: 0.95em; letter-spacing: -0.05rem; color: ${palette('muted', 5)};
  }
`

Items.Body = styled.main`
  position: relative; box-sizing: border-box;
  overflow-x: auto;
`

Items.Footer = styled.footer`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: center; align-items: center;
  padding: 1rem 0;
`