import _ from 'lodash'

const parser = {}

parser.columns = [
  { header: '상품종류', key: 'userAccountId', view: (item) => _.get(item, 'extras.user.id') },
  { header: '용도', key: 'userMobile', view: (item) => _.get(item, 'extras.user.mobile') },
  { header: '소유자', key: 'userEmail', view: (item) => _.get(item, 'extras.user.email') },
  { header: '이메일', key: 'bankAccountName', view: (item) => _.get(item, 'bill.bankAccount.name') },
  { header: '상품명', key: 'bankAccountNumber', view: (item) => _.get(item, 'bill.bankAccount.number') },
  { header: '상품등록일', key: 'bankAccountText', view: (item) => _.get(item, 'bill.bankAccount.text') },
  { header: '상품승인일', key: 'profitTotal', view: (item) => item.total },
  { header: '주문', key: 'subject', view: (item) => item.subject },
  { header: '입고예정', key: 'subject', view: (item) => item.subject },
  { header: '재고', key: 'subject', view: (item) => item.subject },
  { header: '출고예정', key: 'subject', view: (item) => item.subject },
  { header: '출고', key: 'subject', view: (item) => item.subject },
  { header: '폐기', key: 'subject', view: (item) => item.subject },
  { header: '총보유', key: 'subject', view: (item) => item.subject },
  { header: '출고가능', key: 'subject', view: (item) => item.subject },
  { header: '생산방식', key: 'subject', view: (item) => item.subject },
]

export default parser
