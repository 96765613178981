import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api, { REACT_APP_FILE_URL } from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import bookFileChangeApprovalExportParser from './export.bookFileChangeApproval' // 외부유통 신청 엑셀다운로드 템플릿

import BookDetailWithApprovalColumn from './Columns/BookDetailWithApprovalColumn'
import BookMakeDetailWithApprovalColumn from './Columns/BookMakeDetailWithApprovalColumn'
import UserDetailWithApprovalColumn from './Columns/UserDetailWithApprovalColumn'
import ApprovalDetailColumn from './Columns/ApprovalDetailColumn'
import StatusColumn from './Columns/StatusColumn'

import BookCoverByApprovalColumn from './Columns/BookCoverByApprovalColumn'
import BookTextByApprovalColumn from './Columns/BookTextByApprovalColumn'

import BookCoverByProductColumn from './Columns/BookCoverByProductColumn'
import BookTextByProductColumn from './Columns/BookTextByProductColumn'
import BookThumbnailByProductColumn from './Columns/BookThumbnailByProductColumn'

import StockColumn from './Columns/StockColumn'



import Dropdown from './Dropdown'
import Menus from './Menus'
import Sorts from './Sorts'

import BookFileChangeDayModal from './Setups/BookFileChangeDayModal'


import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const Menu = Menus.Menu

const List = ({
  user, location, history, match, error,
  title, nav,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'BookNoColumn', header: '번호', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })
  columns.push({ key: 'BookDetailWithApprovalColumn', header: '파일교체 신청도서', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BookMakeDetailColumn', header: '판형 또는 파일정보', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start' } })
  columns.push({ key: 'UserDetailColumn', header: '등록자', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'flex-start' } })
  columns.push({ key: 'ApprovalDetailColumn', header: '일자정보', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })

  columns.push({ key: 'BookCoverByApprovalColumn', header: '표지(문서)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'BookTextByApprovalColumn', header: '원고(문서)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })

  columns.push({ key: 'BookThumbnailByProductColumn', header: '썸네일(상품)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookCoverByProductColumn', header: '표지(상품)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookTextByProductColumn', header: '원고(상품)', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })

  columns.push({ key: 'InvoiceStockColumn', header: '발주', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })
  columns.push({ key: 'InstockStockColumn', header: '재고', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center' } })

  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: 0, justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '상태', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'ControlColumn', header: '비고', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })

  // 엑셀 다운로드 포맷

  // UI에 필요한 인터페이스 기능들
  const actions = {}

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const lump = query.limit || limit || 100
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length}`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump 
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          await new Promise(r => setTimeout(r, 300))
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 파일교체된 데이터를 받는 기능
  actions.downloadExcel = async (items = selecteds) => {
    if (!items || items.length === 0) { return alert(`다운로드할 데이터가 없습니다.`) }
    const curAt = new Date(), curMt = moment(curAt)

    const options = _.get(filterOptions, 'workAlias.conditions') || [], current = filters.workAlias
    const workAlias = options.find(option => option.name === current)

    const fileName = workAlias
      ? `${workAlias.text} 파일교체 - 총 ${items.length}건 (${curMt.format('YYYY.MM.DD HH시 mm분 ss초')})`
      : `파일교체 - 총 ${items.length}건 (${curMt.format('YYYY.MM.DD HH시 mm분 ss초')})`

    return await new Exporter({ parser: bookFileChangeApprovalExportParser, items })
      .download(fileName)
      .catch(e => {
        alert(`엑셀 파일로 변환하여 다운로드하려던 도중 문제가 발생하였습니다. ${e.message}`)
        console.log(e.message, e.stack)
        return false
      })
  }

  // 선택한 문서의 썸네일만 다운로드하는 기능
  actions.downloadThumbnails = async (items = selecteds) => {
    if (!items || items.length === 0) { return alert(`썸네일 다운로드 할 문서가 없습니다.`) }
    if (!window.confirm(`썸네일 다운로드 할 총 선택된 문서는 ${items.length}건, 진행할까요? 브라우저에 여러 또는 연속 다운로드를 켜주세요.`)) { return }

    await items
    .filter(selected => _.get(selected, 'extras.currentBook.thumbnail.path'))
    .map(selected => {
      const currentBook = _.get(selected, 'extras.currentBook') || {}
      const bookNo = `${_.get(currentBook, 'bookNo')}`
      const fileName = `${_.get(currentBook, 'title') || `도서명`}_${_.get(currentBook, 'author') || `저자명`}`
      const filePath = _.get(currentBook, 'thumbnail.path') || ''
      const ext = (filePath ? [...filePath.split('.')].pop() : 'png').toLowerCase()
      return {
        fileRenamed: `[${bookNo}] ${fileName}.${ext}`.toString(),
        remotePath: `${REACT_APP_FILE_URL}${_.get(currentBook, 'thumbnail.path')}`
      }
    }).reduce((prev, file, sIdx, arr) => 
      prev.then(async (result) => {
        if (!file.remotePath) {
          return result
        }

        const image = await fetch(file.remotePath)
          .then(res => res.blob())
          .then(blob => URL.createObjectURL(blob))
          .catch(e => null)
        if (!image) { return result }
        
        const a = document.createElement("a")
        a.href = image
        a.download = `${file.fileRenamed}`
        document.body.appendChild(a)
        a.click()

        // 다운로드 개체를 삭제
        await new Promise(r => {
          window.URL.revokeObjectURL(file.remotePath)
          document.body.removeChild(a)
          return setTimeout(r, 300)
        })

        setProgressModal({ message: `${sIdx+1}번째, 썸네일을 다운로드중 입니다.`, rate: ((sIdx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) })

        result.push(file.remotePath)
        return result
      }),
      Promise.resolve([])
    )
    .then(async (result) => {
      setProgressModal(null)
      await new Promise(r => setTimeout(r, 600))
      return alert(`성공적으로 다운로드 하였습니다.`)
    })
    .catch(e => console.log(e.message))
  }

  // 개별 신청내역을 삭제하는 기능
  actions.deletelApproval = async (item = {}) => {
    if (!window.confirm(`파일 수정 신청내역을 완전히 삭제합니다. 사용자화면, 관리자화면 모두 사라집니다. 그래도 좋습니까?`)) { return false }
    return await api.delete(`/approvals/admin2/${item.id}/bookFileChange`)
      .then((data) => {
        if (data.error) { alert(`에러가 발생하였습니다. (${data.message})`) }
        alert(`정상적으로 처리하였습니다.`)
        return initialize()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return initialize()
      })
  }

  // 파일교체일 변경 모달 열기
  actions.doOpenBookFileChangeDayModal = async () => {
    const modalProps = {}
    modalProps.name = `BookFileChangeDayModal`
    modalProps.props = {
      onClose: () => setModal(null)
    }
    return setModal(modalProps)
  }

  // 미제출자 메일 통보기능 (원고 또는 표지 기준으로 신청 하지 못한 사람들 체크)
  actions.sendEmailForBookFileChange = async (hasNoticeEmail = true) => {
    if (!window.confirm(`미제출, 제출완료, 반려 대상의 미제출자에게 메일을 일괄 송부합니다. 괜찮을까요?`)) { return }
    const payload = {}
    payload.hasNoticeEmail = hasNoticeEmail
    return await api.put(`/approvals/admin2/sendEmailForAllWithBookFileChange`, payload)
      .then((data) => {
        if (data.error) { alert(`에러가 발생하였습니다. (${data.message})`) }
        alert(`정상적으로 메일을 보내두었습니다.`)
        return initialize()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return initialize()
      })
  }

  // `제출완료` 단계 상태를 지닌 문서가 사용자에 의해서 변조되지 않도록 락을 거는 장치
  actions.updateBookFileChangeLockContent = async (items = [], locked) => {
    if (!items || !items.length) { return alert(`작업을 시작할 문서를 선택해주셔야합니다.`) }
    if (locked && !window.confirm(`선택한 ${items.length}건의 문서에 해당하는 신청건에 대해서 작업을 시작하면 일반사용자는 파일업로드를 더이상 할 수 없습니다. 진행을 할까요?`)) { return }
    if (!locked && !window.confirm(`선택한 ${items.length}건의 문서에 해당하는 신청건에 대해서 작업을 취소하면, 다시 업로드 가능합니다. 진행을 할까요?`)) { return }

    // 개별적으로 던져야하도록 구성하고 넘기면 처리해준다.
    const report = await items.reduce((prev, item) => 
      prev.then(async (result) => {
        const payload = { locked }
        payload.comment = payload.locked
          ? `파일교체 작업이 진행중입니다. 진행중인 경우에는 표지 및 내지 파일 업로드가 불가능합니다.`
          : `파일교체 작업 전입니다. 표지와 내지 파일이 올바른지 다시 한 번 점검해보세요.`
        const output = await api.put(`/approvals/admin2/${item.id}/updateBookFileChangeLockContent`, payload)
          .then((data) => {
            if (data.error) { return null }
            return data
          })
          .catch((e) => null)
        if (output) {
          result.success.push(item.id)
        } else {
          result.failure.push(item.id)
        }
        return result
      }),
      Promise.resolve({ success: [], failure: [] }))
      .catch(e => null)

    if (!report) { return alert(`작업상태에 대한 정보를 기록도중 문제가 발생하였습니다.`) }

    const successCount = report.success.length
    const failureCount = report.failure.length

    alert(`성공 ${successCount}건, 실패 ${failureCount}건`)

    return loadItems()
  }

  // 선택한 내역 재고정리 작업
  actions.resetBookFileChangeResetStocks = async (items = []) => {
    if (!items || !items.length) { return alert(`재고를 정리할 파일교체 신청문서를 선택해주셔야 합니다.`) }
    if (!window.confirm(`선택한 ${items.length}건의 문서에 해당하는 도서들의 재고를 0으로 초기화 할까요?`)) { return }

    // 개별적으로 던져야하도록 구성하고 넘기면 처리해준다.
    const report = await items.reduce((prev, item) => 
      prev.then(async (result) => {
        const payload = { hasResetStock: true }
        const output = await api.put(`/approvals/admin2/${item.id}/resetBookFileChangeResetStock`, payload)
          .then((data) => {
            if (data.error) { return null }
            return data
          })
          .catch((e) => null)
        if (output) {
          result.success.push(item.id)
        } else {
          result.failure.push(item.id)
        }
        return result
      }),
      Promise.resolve({ success: [], failure: [] }))
      .catch(e => null)

    if (!report) { return alert(`재고 정리 작업에 대한 프로그램 오류가 발생하였습니다.`)}

    const successCount = report.success.length
    const failureCount = report.failure.length

    alert(`성공 ${successCount}건, 실패 ${failureCount}건`)

    return loadItems()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}

      {modal && modal.name === 'BookFileChangeDayModal' ? <BookFileChangeDayModal {...(modal.props || {})} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#bookFileChangeDayModal"
            onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookFileChangeDayModal()]}
          >
            📆 파일교체일
          </a>
          <a
            href="#sendEmailForBookFileChange"
            onClick={e => [e.stopPropagation(), e.preventDefault(), actions.sendEmailForBookFileChange(true)]}
          >
            📮 업로드 메일요청
          </a>
        </Header.Options>          
      </Header>
      <Header>
        <Header.Options style={{ maxWidth: '100%', minWidth: '100%', justifyContent: 'flex-start' }}>
          {['review'].includes(filters.status) ? (
            <>
              <a
                href="#updateBookFileChangeLockContentToTrue"
                onClick={e => [e.stopPropagation(), e.preventDefault(), actions.updateBookFileChangeLockContent(selecteds, true)]}
              >
                파일교체 작업시작
              </a>
              <a
                href="#updateBookFileChangeLockContentToFalse"
                onClick={e => [e.stopPropagation(), e.preventDefault(), actions.updateBookFileChangeLockContent(selecteds, false)]}
              >
                작업취소
              </a>
              <a
                href="#resetBookFileChangeResetStockBySelecteds"
                onClick={e => [e.stopPropagation(), e.preventDefault(), actions.resetBookFileChangeResetStocks(selecteds)]}
              >
                재고리셋
              </a>
            </>
          ) : null}

          <a
            href="#downloadThumbnailBySelecteds"
            onClick={e => [e.stopPropagation(), e.preventDefault(), actions.downloadThumbnails(selecteds)]}
          >
            선택 썸네일 🌠
          </a>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              const targets = await actions
                .getItemsAll({}, { progress: true })
                .catch(e => [])
              return await actions.downloadThumbnails(targets)
            }}
          >
              전체 썸네일 🌠
          </a>
          <a
            href="#downloadExcelBySelecteds"
            onClick={e => [e.stopPropagation(), e.preventDefault(), actions.downloadExcel(selecteds)]}
          >
            선택엑셀 📥
          </a>
          <a
            href="#downloadExcelAll"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              const targets = await actions
                .getItemsAll({}, { progress: true })
                .catch(e => [])
              return await actions.downloadExcel(targets)
            }}
          >
            전체엑셀 📥
          </a>
        </Header.Options>
          
      </Header>
          
      {nav === 'bookFileChange'
        ? (
          <Menu>
              <Menu.Items>
                <Menu.Item
                  href="#menu_0"
                  className={!filters.status || ['all'].includes(filters.status) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `all`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  전체
                </Menu.Item>
                <Menu.Item
                  href="#menu_1"
                  className={['wait'].includes(filters.status) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `wait`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  미제출
                </Menu.Item>
                <Menu.Item
                  href="#menu_2"
                  className={['reject'].includes(filters.status) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `reject`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  반려
                </Menu.Item>
                <Menu.Item
                  href="#menu_3"
                  className={['review'].includes(filters.status) && ['uploadedFiles'].includes(filters.workStatus) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `review`
                    query.filters_workStatus = `uploadedFiles`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  제출완료
                </Menu.Item>
                <Menu.Item
                  href="#menu_4"
                  className={['review'].includes(filters.status) && ['startChangeFile'].includes(filters.workStatus) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `review`
                    query.filters_workStatus = `startChangeFile`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  작업중
                </Menu.Item>
                <Menu.Item
                  href="#menu_5_1"
                  className={['review'].includes(filters.status) && ['waitClearStocks'].includes(filters.workStatus) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `review`
                    query.filters_workStatus = `waitClearStocks`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  재고정리필요
                </Menu.Item>
                <Menu.Item
                  href="#menu_5_1"
                  className={['review'].includes(filters.status) && ['finishedClearStocks'].includes(filters.workStatus) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `review`
                    query.filters_workStatus = `finishedClearStocks`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  재고정리완료
                </Menu.Item>
                <Menu.Item
                  href="#menu_6"
                  className={['accept'].includes(filters.status) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `accept`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  작업완료
                </Menu.Item>
                <Menu.Item
                  href="#menu_7"
                  className={['cancel'].includes(filters.status) ? `active` : ``}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const query = {}
                    query.filters_status = `cancel`
                    return window.location.href = `/manages/bookFileChange?${qs.stringify(query)}`
                  }}
                >
                  제출취소
                </Menu.Item>
                <Menu.Item href="#" onClick={e => [e.stopPropagation(), e.preventDefault()]} style={{ flex: '1 1 100%', minWidth: 'auto', maxWidth: '100%', width: '100%'  }}></Menu.Item>
              </Menu.Items>
          </Menu>
        )
      : null}

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.parent = _.get(item, 'parent')
                    meta.productId = _.get(item, 'content.productId') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'BookNoColumn':
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  <a
                                    href={`/products/book/${meta.productId}`}
                                    target="_blank"
                                    onClick={e => {
                                      if (!meta.productId) {
                                        return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
                                      }
                                      return
                                    }}
                                  >
                                    <strong style={{ fontSize: '0.9em' }}>{meta.bookNo || '미발급'}</strong>
                                  </a>
                                  <div>
                                    {meta.parent
                                      ? (
                                        <a
                                          href={`?keywordsOption=approvalId&keywords=${meta.parent}`}
                                          target="_blank"
                                        >
                                          <small>🚩재등록</small>
                                        </a>
                                      )
                                      : <small>⭐신규</small>}
                                  </div>
                                </div> 
                              )
                            case 'BookDetailWithApprovalColumn':
                              return <BookDetailWithApprovalColumn {...columnProps} />
                            case 'BookMakeDetailColumn':
                              return <BookMakeDetailWithApprovalColumn {...columnProps} />
                            case 'UserDetailColumn':
                              return <UserDetailWithApprovalColumn {...columnProps} />
                            case 'ApprovalDetailColumn':
                              return <ApprovalDetailColumn {...columnProps} />
                            case 'StatusColumn':
                              return <StatusColumn {...columnProps} />

                            case 'BookCoverByApprovalColumn':
                              return <BookCoverByApprovalColumn {...columnProps} />
                            case 'BookTextByApprovalColumn':
                              return <BookTextByApprovalColumn {...columnProps} />

                            case 'BookCoverByProductColumn':
                              return <BookCoverByProductColumn {...columnProps} />
                            case 'BookTextByProductColumn':
                              return <BookTextByProductColumn {...columnProps} />
                            case 'BookThumbnailByProductColumn':
                              return <BookThumbnailByProductColumn {...columnProps} />

                            case 'InvoiceStockColumn':
                              return <StockColumn part="invoiceAndOverflow" {...columnProps} />
                            case 'InstockStockColumn':
                              return <StockColumn part="instock" {...columnProps} />

                            case 'ControlColumn':
                              return (
                                <div>
                                  {_.get(item, 'content.payId')
                                    ? (
                                      <div style={{ padding: '0.35rem 0' }}>
                                        {['wait', 'review', 'cancel'].includes(item.status)
                                          ?<a
                                              href="#doMovePayDetail"
                                              style={{ margin: '0 0.35rem' }}
                                              onClick={async (e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                const meta = {}
                                                meta.payId = _.get(item, 'content.payId')
                                                return window.open(`/pays/etc/${meta.payId}`)
                                              }}
                                            >
                                              <strong>결제내역</strong>
                                            </a>
                                          : null}
                                      </div>
                                    )
                                    : null}
                                  {!_.get(item, 'content.payId')
                                    ? (
                                      <div style={{ padding: '0.35rem 0' }}>
                                        {['wait', 'review', 'cancel'].includes(item.status)
                                          ? (
                                            <a
                                              href="#deletelApproval"
                                              style={{ margin: '0 0.35rem' }}
                                              onClick={e => [e.stopPropagation(), e.preventDefault(), actions.deletelApproval(item)]}
                                            >
                                              <strong>내역삭제</strong>
                                            </a>
                                          )
                                          : null}
                                      </div>
                                    )
                                    : null}
                                </div>
                              )
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
