import styled from 'styled-components'
import { font, palette } from 'styled-theme'

export const Box = styled.article`
  position: relative; box-sizing: border-box;
`

Box.Header = styled.header`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  padding: 0rem;
`

Box.Lead = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 0.8em;
  & > small { font-weight: 300; }
`

Box.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  & > a {
    display: block; text-decoration: none; padding: 0.35rem 1.5rem;
    font-weight: 500; transition: all 0.3s;
    color: ${palette('muted', 20)}; background: ${palette('muted', 4)};
    border-radius: 3px; border: 0;
    &:hover { background: ${palette('muted', 1)}; }
  }
`

Box.Body = styled.section`
  position: relative; box-sizing: border-box;
`

export const Generator = styled.div`
  position: relative; box-sizing: border-box;
  border-radius: 3px; background: white;
  margin: 1rem 0; padding: 1rem;
  border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
`

Generator.Group = styled.div`
  position: relative; box-sizing: border-box;
`

Generator.Header = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  padding: 0.35rem 0;
  & > .caret {
    display: flex; justify-content: center; align-items: center;
    position: relative; box-sizing: border-box;
  }
  & > .lead {
    position: relative; box-sizing: border-box;
    flex: 1 1 auto; min-width: 0; text-align: left;
    padding: 0 0.5rem; line-height: 30px;
    & > strong { font-weight: 900; }
    & > small { margin-left: 0.35rem; font-weight: 300; }
  }
`

Generator.Options = styled.div`
  position: relative; box-sizing: border-box;
  border-top: 1px dashed ${palette('muted', 15)};
  margin: 0.5rem 0; transition: all 0.3s;
`

Generator.Option = styled.div`
  position: relative; box-sizing: border-box;
  margin-bottom: 1rem;
  & > div.desc {
    position: relative; box-sizing: border-box;
    color: ${palette('muted', 5)}; padding: 1rem 0;
  }
`

export const Button = styled.button`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; color: ${palette('muted', 5)};
  padding: 0.5rem 0; transition: all 0.3s; border-radius: 3px;
  display: block; border: 0; background: transparent;
  font-size: 16px;
  cursor: pointer;
  &:hover { color: ${palette('primary', 1)}; }
`

Button.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  padding: 0.15rem 0; margin: 0.5rem 0;
  background: ${palette('muted', 18)};
  border-radius: 3px;
`

/** */

export const Meta = styled.div`
  display: block; position: relative; box-sizing: border-box;
  flex: 1;
  display: flex; justify-content: space-between;
  letter-spacing: -0.01rem;
  color: ${palette('muted', 2)};
`

Meta.Label = styled.div`
  display: block; position: relative; box-sizing: border-box;
  color: ${palette('muted', 3)}; min-width: 0px; max-width: fit-content;
  letter-spacing: -0.01rem; padding: 0 0.75rem;
`

Meta.Value = styled.div`
  display: block; position: relative; box-sizing: border-box;
  flex: 1 1 auto; min-width: 0; text-align: right;
  color: ${palette('muted', 5)}; font-weight: 700;
  letter-spacing: -0.01rem; padding: 0 0.75rem;
  &.primary { color: ${palette('primary', 1)}; }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box; display: flex;
`