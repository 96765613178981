import _ from 'lodash'

const parser = {}

parser.columns = [
  { header: '계정명', key: 'userAccountId', view: (item) => _.get(item, 'extras.user.id') },
  { header: '연락처', key: 'userMobile', view: (item) => _.get(item, 'extras.user.mobile') },
  { header: '이메일', key: 'userEmail', view: (item) => _.get(item, 'extras.user.email') },
  { header: '예금주', key: 'bankAccountName', view: (item) => _.get(item, 'bill.bankAccount.name') },
  { header: '계좌번호', key: 'bankAccountNumber', view: (item) => _.get(item, 'bill.bankAccount.number') },
  { header: '은행명', key: 'bankAccountText', view: (item) => _.get(item, 'bill.bankAccount.text') },
  { header: '수익액', key: 'profitTotal', view: (item) => item.total },
  { header: '내용', key: 'subject', view: (item) => item.subject },
  { header: '설명', key: 'description', view: (item) => item.description },
]

export default parser
