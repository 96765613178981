import styled from 'styled-components'
import { font, palette, size } from 'styled-theme'

const PanelSet = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; margin: 0.5rem 0;
`

PanelSet.Panel = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; background: ${palette('muted', 21)}; margin: 0.5rem;
  box-shadow: 0 3px 3px ${palette('muted', 18)}; border-radius: 5px;
  border-bottom: 2px solid ${palette('muted', 2)};
`

PanelSet.Header = styled.header`
  position: relative; box-sizing: border-box;
  border-bottom: 1px solid ${palette('muted', 18)};
  padding: 0.75rem 1rem; color: ${palette('muted', 1)}; font-size: 0.9em; margin-bottom: -0.5rem;
`

PanelSet.Body = styled.div`
  position: relative; box-sizing: border-box; padding: 1rem;
`

export default PanelSet
