import _ from 'lodash'
import moment from 'services/moment'

  // 결제상품 종류
  const payTypes = {}
  payTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  payTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  payTypes.solution = { name: 'solution', text: '작가서비스' }
  payTypes.etc = { name: 'etc', text: '기타' }

  // 결제방법 종류
  const payMethodTypes = {}
  payMethodTypes.card = { name: 'card', text: '신용카드' }
  payMethodTypes.card_rebill = { name: 'card_rebill', text: '정기결제' }
  payMethodTypes.card_rebill_rest = { name: 'card_rebill_rest', text: '정기결제 (REST API)' }
  payMethodTypes.bank = { name: 'bank', text: '계좌이체' }
  payMethodTypes.phone = { name: 'phone', text: '휴대폰' }
  payMethodTypes.vbank = { name: 'vbank', text: '가상계좌' }
  payMethodTypes.kakao = { name: 'kakao', text: '카카오페이 간편결제' }
  payMethodTypes.payco = { name: 'payco', text: '페이코 간편결제' }
  payMethodTypes.npay = { name: 'npay', text: '네이버페이 간편결제' }
  payMethodTypes.etc = { name: 'etc', text: '기타' }
  payMethodTypes.free = { name: 'free', text: '무료' }

const parser = {}

parser.columns = [
  {
    header: '신청일', key: 'createdAt',
    view: (item) => {
      const meta = {}
      meta.createdMt = _.get(item, 'createdAt')
        ? moment(_.get(item, 'createdAt'))
        : null
      return meta.createdMt
       ? meta.createdMt.format('YYYY년 MM월 DD일 HH시 mm분 ss초')
       : ''
    }
  },
  {
    header: '계정명', key: 'accountId',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'accountId') || ''
    }
  },
  {
    header: '연락처', key: 'mobile',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'mobile') || ''
    }
  },
  {
    header: '주문종류', key: 'payType',
    view: (item) => {
      const meta = {}
      meta.payType = _.get(item, 'content.payType')
        ? (_.get(payTypes, `${_.get(item, 'content.payType')}`) || payTypes.etc)
        : payTypes.etc
      return _.get(meta.payType, 'text') || '기타'
    }
  },
  {
    header: '주문번호', key: 'payNo',
    view: (item) => {
      const extras = item.extras || {}
      const currentPay = _.get(extras, 'currentPay') || {}
      const meta = {}
      meta.payNo = _.get(currentPay, 'payNo') || ''
      return meta.payNo
    }
  },
  {
    header: '결제방법', key: 'payMethod',
    view: (item) => {
      const meta = {}
      meta.payMethod = _.get(item, 'content.payMethod')
        ? (_.get(payMethodTypes, `${_.get(item, 'content.payMethod')}`) || payMethodTypes.etc)
        : payMethodTypes.etc
      return _.get(meta.payMethod, 'text') || '기타'
    }
  },
  {
    header: '환불신청 내역', key: 'subject',
    view: (item) => {
      const meta = {}
      meta.subject = _.get(item, 'subject') || ''
      return meta.subject
    }
  },
  {
    header: '은행명', key: 'bankName',
    view: (item) => {
      const meta = {}
      meta.bankName = _.get(item, 'content.bankName') || ''
      return meta.bankName
    }
  },
  {
    header: '예금주', key: 'walletOwner',
    view: (item) => {
      const meta = {}
      meta.walletOwner = _.get(item, 'content.walletOwner') || ''
      return meta.walletOwner
    }
  },
  {
    header: '계좌번호', key: 'walletAddress',
    view: (item) => {
      const meta = {}
      meta.walletAddress = _.get(item, 'content.walletAddress') || ''
      return meta.walletAddress
    }
  },
  {
    header: '환불금액', key: 'refundPrice',
    view: (item) => {
      const meta = {}
      meta.refundPrice = _.get(item, 'content.refundPrice') || 0
      return meta.refundPrice
    }
  },
  {
    header: '진행단계', key: 'status',
    view: (item) => {
      const meta = {}
      meta.statusText = '알수없음' 
      if (['wait'].includes(item.status)) { meta.statusText = '환불요청' }
      if (['review'].includes(item.status)) { meta.statusText = '환불진행중' }
      if (['reject'].includes(item.status)) { meta.statusText = '환불거절' }
      if (['accept'].includes(item.status)) { meta.statusText = '환불완료' }
      if (['cancel'].includes(item.status)) { meta.statusText = '환불취소' }
      return meta.statusText
    }
  }
]

export default parser
