import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Caret from 'components/utils/Caret'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class BusinessDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { item: {} }

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {}
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}

    const meta = {}
    meta.name = _.get(currentUser, 'business.name') || '미기재'
    meta.registrationNumber = _.get(currentUser, 'business.registrationNumber') || '알수없음'
    meta.representative = _.get(currentUser, 'business.representative') || '알수없음'

    // 비지니스 계정정보인경우와 일반 개인계정인경우 다르게 보이기
    meta.isBusinessCerted = currentUser.activatedToBusiness

    // 사업자계정이라면
    if (!meta.isBusinessCerted) { return <Column style={{ textAlign: 'center' }}>-</Column> }

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>사업자정보</small>
            </header>

            <strong>{meta.name}</strong>
          </Meta>

          <Meta>
            <header>
              <small>대표자명</small>
            </header>

            <strong>{meta.representative}</strong>
          </Meta>

          <Meta>
            <header>
              <small>사업자번호</small>
            </header>
            <strong>{meta.registrationNumber}</strong>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BusinessDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

BusinessDetailColumnContainer.defaultProps = {
  item: {}
}

export default BusinessDetailColumnContainer
