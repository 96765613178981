import React from 'react'

import _ from 'lodash'

import Caret from 'components/utils/Caret'
import Lnr from 'components/utils/Lnr'

import Modal from 'components/utils/Modal'

import { Box, Form, Button } from './utils'

// 옵션별 단수 옵션으로 개발요청을 초기에 했고
// 단수 옵션이였다가 추후 변경이될 수 있기에 (납기일, 진행일 기준 변경에 따른 단가수율 조정)
const Detail = ({ ...props }) => {
  const { solution = {}, handleSave } = props.parentProps

  // 모달 열고 닫고 하는 기능
  const modalProps = {}
  modalProps.isOpen = true
  modalProps.onClose = props.onClose
    ? props.onClose
    : () => {}

  const actions = {}

  // parentOption + options[firstChildrenOptions] 구조로 들어오기 때문에 결합 정보수정으로 가야한다.
  const item = { ...props.item }
  const firstOption = _.get(item, 'options[0]') || {}
  const [form, setForm] = React.useState({
    text: item.text,
    description: item.description,
    price: firstOption.price || 1000,
    planing: firstOption.planing || 3,
    days: firstOption.days || 5
  })

  // 창닫기
  actions.doClose = async () => {
    return props.onClose ? props.onClose() : null
  }

  // 해당 옵션정보를 변경하고 트리를 변경해주기
  actions.doSave = async () => {
    // 데이터 검증처리
    if (!form.text) { return alert(`옵션명을 반드시 기재해야합니다.`) }
    if (!form.description) { return alert(`가격에 대한 설명이 올바르게 작성되어 있어야합니다.`) }

    if (!form.price || _.isNaN(form.price * 1)) { return alert(`가격이 설정되어 있어야합니다.`) }
    if (form.price < 1000) { return alert(`가격이 1천원 이상이여야 합니다.`) }
    if (!_.isInteger(form.price * 1) || form.price < 1000) { return alert(`1천원 이상이여야 하며, 정수여야 합니다.`) }
    
    if (!form.planing || _.isNaN(form.planing * 1)) { return alert(`수정 최대 수를 반드시 숫자로 적어주셔야합니다.`) }
    if (!_.isInteger(form.planing * 1) || form.planing < 1) { return alert(`수정 최대수는 1 이상의 정수여야 합니다.`) }
    
    if (!form.days || _.isNaN(form.days * 1)) { return alert(`최대 작업일 수를 반드시 숫자로 적어주셔야합니다.`) }
    if (!_.isInteger(form.days * 1) || form.days < 1) { return alert(`작업일 수는 1 이상의 정수여야 합니다.`) }

    // 기존 최상위 정보에서 데이터를 수급받아온다.
    const next = [...solution.options]
    next[item._idx] = { ...item }

    // 그룹옵션에 값을 반영해주는 기능
    next[item._idx].text = form.text
    next[item._idx].description = form.description

    // 복합폼을 반영해주는 기능
    const firstOption = _.get(next[item._idx], 'options[0]') || {}
    firstOption.price = parseInt(Math.floor(form.price/500)*500)
    firstOption.planing = form.planing
    firstOption.days = form.days
    _.set(next, `[${item._idx}].options[0]`, firstOption)

    handleSave({ options: next })
    setTimeout(() => props.onClose ? props.onClose() : null, 1)
  }

  return (
    <Modal {...modalProps}>
      <Box>
        <Box.Close
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            return await actions.doClose()
          }}
        >
          <Lnr c="cross" />
        </Box.Close>
        <Box.Header>
          가격옵션 관리
        </Box.Header>
        <Box.Body style={{ marginBottom: '2rem' }}>
          <Form>
            <Form.Control>
              <div className="label">
                 옵션명
              </div>
              <div className="value">
                <input
                  type="text"
                  value={form.text}
                  onChange={(e) => {
                    const value = `${e.target.value || ''}`
                    setForm({ ...form, text: value })
                  }}
                />
              </div>
            </Form.Control>
            <Form.Control>
              <div className="label">
                 설명
              </div>
              <div className="value">
                <textarea
                  value={form.description}
                  style={{ minHeight: '180px' }}
                  onChange={(e) => {
                    const value = `${e.target.value || ''}`
                    setForm({ ...form, description: value })
                  }}
                />
              </div>
            </Form.Control>
            <Form.Control>
              <div className="label">
                 가격
              </div>
              <div className="value">
                <input
                  type="number"
                  value={form.price}
                  onChange={(e) => {
                    const value = (e.target.value && !_.isNaN(e.target.value * 1))
                      ? e.target.value
                      : 1000
                    setForm({ ...form, price: value })
                  }}
                  onBlur={(e) => {
                    const value = (e.target.value && !_.isNaN(e.target.value * 1))
                      ? parseInt(Math.floor(e.target.value/500)*500)
                      : 1000
                    setForm({ ...form, price: value })
                  }}
                />
              </div>
            </Form.Control>
            <Form.Control>
              <div className="label">
                 수정 횟수
              </div>
              <div className="value">
                <input
                  type="number"
                  value={form.planing}
                  onChange={(e) => {
                    const value = (e.target.value && !_.isNaN(e.target.value * 1))
                      ? parseInt(e.target.value)
                      : 1
                    setForm({ ...form, planing: value })
                  }}
                />
              </div>
            </Form.Control>
            <Form.Control>
              <div className="label">
                 최대 작업일
              </div>
              <div className="value">
                <input
                  type="number"
                  value={form.days}
                  onChange={(e) => {
                    const value = (e.target.value && !_.isNaN(e.target.value * 1))
                      ? parseInt(e.target.value)
                      : 1
                    setForm({ ...form, days: value })
                  }}
                />
              </div>
            </Form.Control>
          </Form>
        </Box.Body>
        <Button.Group>
          <Button
            type="button"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doClose()
            }}
          >
            취소
          </Button>
          <Button
            type="button"
            className="primary"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doSave()
            }}
          >
            완료
          </Button>
        </Button.Group>
      </Box>
    </Modal>
  )
}

export default Detail