import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class MagazineDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {}
    meta.brunchMagazine = _.get(item, 'content.brunchMagazine') || null
    meta.brunchUserId = _.get(item, 'content.brunchUserId') || null

    return (
      <Column>
        <Meta.Group>
          <Meta>
            <header>
              <small>매거진 코드</small>
            </header>
            <section
              style={{ cursor: 'pointer' }}
              onClick={e => {
                if (!meta.brunchMagazine) { return alert(`매거진 코드값을 부여받지 않은 도서입니다.`) }
                return window.open(`https://brunch.co.kr/@${meta.brunchMagazine}`)
              }}
            >
              <strong>@{meta.brunchMagazine || '알수없음'}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              <small>브런치 UID</small>
            </header>
            <section>
              <strong>{meta.brunchUserId || '알수없음'}</strong>
            </section>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }
}

MagazineDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

MagazineDetailColumnContainer.defaultProps = {
  item: {}
}

export default MagazineDetailColumnContainer
