import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 도서의 외부유통 신청정보를 기반으로 함
class BookRetailWithApprovalColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  async initialize() {}

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item } = this.props

    const meta = {
      retailName: _.get(item, 'content.retail.name') || '알수없음',
      retailText: _.get(item, 'content.retail.text') || '알수없음',
    }

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>유통사</small>
            </header>
            <section>
              <strong style={{ marginRight: '0.35rem' }}>{meta.retailText || '알수없음'}</strong>
              <small>{meta.retailName}</small>
            </section>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }
}

BookRetailWithApprovalColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookRetailWithApprovalColumnContainer.defaultProps = {
  item: {}
}

export default BookRetailWithApprovalColumnContainer
