import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const curAt = new Date(), curMt = moment(curAt)

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  // 발주번호, 도서ID, 도서명, 규격, 작가명, 발주시간, 발주량, 수령예정량, 수령, 작업(수령처리), 수령시간, 삭제관리, 상태
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'NoColumn', header: '주문번호', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ParcelTypeColumn', header: '구분', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'CreatedAtColumn', header: '입금확인일', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DurationColumn', header: '경과일', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'AccountColumn', header: '결제자', style: { minWidth: '220px', maxWidth: '220px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReceiverColumn', header: '수취인', style: { minWidth: '150px', maxWidth: '150px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DetailColumn', header: '발송 상세내역', style: { minWidth: '618px', maxWidth: '618px' } })
  columns.push({ key: 'PriceColumn', header: '총결제금액', style: { minWidth: '180px', maxWidth: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: 0, maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PayMethodColumn', header: '결제방법', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const parcelTypes = {}
  parcelTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  parcelTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  parcelTypes.solution = { name: 'solution', text: '작가서비스' }
  parcelTypes.etc = { name: 'etc', text: '기타' }

  // 결제방법 구분
  const payMethodTypes = {}
  payMethodTypes.card = { name: 'card', text: '신용카드' }
  payMethodTypes.card_rebill = { name: 'card_rebill', text: '정기결제' }
  payMethodTypes.card_rebill_rest = { name: 'card_rebill_rest', text: '정기결제 (REST API)' }
  payMethodTypes.phone = { name: 'phone', text: '휴대폰결제' }
  payMethodTypes.bank = { name: 'bank', text: '계좌이체' }
  payMethodTypes.vbank = { name: 'vbank', text: '가상계좌' }
  payMethodTypes.kakao = { name: 'kakao', text: '카카오페이 간편결제' }
  payMethodTypes.payco = { name: 'payco', text: '페이코 간편결제' }
  payMethodTypes.npay = { name: 'npay', text: '네이버페이 간편결제' }
  payMethodTypes.free = { name: 'free', text: '무료' }
  payMethodTypes.etc = { name: 'etc', text: '기타' }

  // 로우 작동 액션들 : 수령처리, 삭제처리, 선택수령기능, 선택삭제 기능
  const actions = {}

  // 발송준비중 단계 처리 기능
  actions.doUpdateParcelReadyStatus = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`📦 해당 주문내역을 발송준비중단계로 전환할까요? 구매자분의 부크크 알림에 발송준비를 알리게 됩니다. 진행할까요?`)) { return }
    const form = {}
    const result = await api.put(`/invoices/admin2/${item.id}/updateParcelReadyStatus`, { form }).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      await api.put(`/invoices/admin2/refreshInvoices`, {}).catch(e => null)
      return window.location.reload()
    }
    return result
  }

  // @ 발송준비중 단계로 전환하는 기능
  actions.doUpdateParcelsReadyStatusBySelecteds = async (items = selecteds) => {
    if (!items.length) { return alert(`선택한 주문내역이 존재하지 않습니다. 체크를 반드시 해주시고 진행해주세요.`) }
    if (!window.confirm(`해당 주문내역(총 ${items.length}건)을 📦 발송준비중 단계로 전환 시 신규발주 재생성 기능이 사용됩니다. 구매자분의 부크크 알림에 발송준비를 알리게 됩니다. 진행할까요?`)) { return }

    const result = await items
      .reduce((prev, item, idx) => 
        prev.then(async (report) => {
          const no = idx + 1
          const result = await actions.doUpdateParcelReadyStatus(item, { returnal: true }).catch(e => null)
          if (result && result.error) {
            report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
            return report
          }
          report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
          setProgressModal({ message: `${no}/${items.length}, 작업을 진행중입니다. 🦕`, rate: ((no + 1)/items.length) * 100, onClose: () => null })
          return report
        }),
        Promise.resolve({ success: [], failure: [] }))
      .catch((e) => ({ success: [], failure: [] }))

    setProgressModal(null)

    // 주문내역들 기반으로 발주필요량 계산하여 자동 인보이스 재구성
    const success = result.success || [], failure = result.failure || []
    if (success.length) { await api.put(`/invoices/admin2/refreshInvoices`, {}).catch(e => null) }

    alert(`🫡 성공 ${success.length}건, 실패 ${failure.length}건이 발생하였습니다.`)
    return window.location.reload()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 발송주문이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#removeItems"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doUpdateParcelsReadyStatusBySelecteds()
                .catch((e) => alert(`응답에 오류가 발생하였습니다. ${e.message} ${e.stack}`))
            }}
          >
              📦 발송준비중 전환
          </a>
        </Header.Options>
      </Header>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentUser = extras.currentUser || {} // 현재 발송내역의 주문자 정보
                    const currentPays = _.get(extras, 'currentPays.length') // 현재 발송내역의 결제내역 리스트
                      ? [..._.get(extras, 'currentPays')]
                      : []
                    const lastCurrentPay = currentPays[0] || {} // 발송내역의 마지막 결제내역을 불러온다.
                    const currentProducts = extras.currentProducts || []

                    // 에러방지를 위한 메타값 정리
                    const meta = {}
                    meta.payId = _.get(lastCurrentPay, 'id')
                    meta.payNo = _.get(lastCurrentPay, 'payNo') || '-'
                    meta.paySubject = _.get(lastCurrentPay, 'subject') || ''
                    meta.parcelType = parcelTypes[_.get(item, 'parcelType')] || parcelTypes.etc

                    meta.basedAt = _.get(item, 'basedAt') || _.get(item, 'updatedAt')
                    meta.basedMt = meta.basedAt ? moment(meta.basedAt) : null

                    meta.durationDayCount = meta.basedAt ? curMt.diff(meta.basedMt, 'days') : 0 // 경과일
                    
                    meta.name = _.get(item, 'name') || meta.paySubject ||  '배송명 없음'
                    meta.payTotalPrice = _.get(lastCurrentPay, 'totalPrice') || 0
                    meta.payMethod = _.get(lastCurrentPay, 'method') || 'etc'
                    meta.payMethodType = payMethodTypes[meta.payMethod] || payMethodTypes.etc

                    meta.itemsInParcel = item.items || []

                    meta.userId = _.get(item, 'user')
                    meta.userAccountId = _.get(currentUser, 'accountId') || '알수없음'
                    meta.buyerName = _.get(lastCurrentPay, 'buyer.name') || meta.userAccountId || `알수없음`
                    meta.receiverName = _.get(item, 'receiver') || '알수없음'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'NoColumn':
                              return (
                                <div
                                onClick={(e) => {
                                  const parcelType = _.get(item, 'parcelType')
                                  return window.open(`/pays/${parcelType}/${meta.payId}`)
                                }}
                                style={{ cursor: 'pointer', maxWidth: '100%', whiteSpace: 'normal' }}
                                >
                                  <strong>{meta.payNo}</strong>
                                </div>
                              )
                            case 'ParcelTypeColumn':
                              return (
                                <div style={{ maxWidth: '100%', whiteSpace: 'normal' }}>
                                  <strong>{_.get(meta, 'parcelType.text')}</strong>
                                </div>
                              )
                            case 'CreatedAtColumn':
                              return (
                                <div>
                                  <strong>{meta.basedMt ? meta.basedMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.basedMt ? meta.basedMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'DurationColumn':
                              return (
                                <div>
                                  {meta.durationDayCount > 3
                                    ? (
                                      <>
                                        <span>🥵</span>
                                        <strong>+{meta.durationDayCount}일</strong>
                                      </>
                                    )
                                    : null}
                                  {meta.durationDayCount <= 3
                                    ? (
                                      <>
                                        <span>🤭</span>
                                        <strong>+{meta.durationDayCount}일</strong>
                                      </>
                                    )
                                    : null}
                                </div>
                              )
                            case 'AccountColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    return window.open(`/users/${meta.userId}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: '100%', whiteSpace: 'normal' }}
                                >
                                  <strong>{meta.buyerName}</strong>
                                  <small style={{ marginLeft: '0.382rem' }}>{meta.userAccountId}</small>
                                </div>
                              )
                            case 'DetailColumn':
                              return (
                                <details style={{ maxWidth: '100%', whiteSpace: 'normal' }} open={true}>
                                  <summary style={{ cursor: 'pointer', listStyle: 'none' }}>
                                    <strong>{meta.name}</strong>
                                  </summary>
                                  <div style={{ paddingTop: '0.786rem' }}>
                                    {meta.itemsInParcel
                                      .filter(pItem => pItem.name)
                                      .map((pItem, pIdx) => {
                                        const ppMeta = {}
                                        ppMeta.product = (pItem.product && currentProducts.find(o => `${o.id}` === `${pItem.product}`)) || {}
                                        const book = _.get(ppMeta, 'product.content') || {}
                                        return (
                                          <div
                                            key={`${item.id}_items_${pIdx}`}
                                            style={{ cursor: pItem.product ? 'pointer' : 'normal' }}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              if (!pItem.product) { return }
                                              return window.open(`/products/${item.parcelType}/${pItem.product}`)
                                            }}
                                          >
                                            <small>
                                              {_.get(book, 'bookNo')
                                                ? <span style={{ marginRight: '0.35rem' }}>({_.get(book, 'bookNo')})</span>
                                                : ``}
                                              <strong>{pItem.name}</strong>
                                            </small>
                                            <small style={{ marginLeft: '0.35rem' }}>
                                              <strong style={{ fontWeight: 900, color: pItem.amount > 1 ? 'red' : '' }}>{pItem.amount}</strong>
                                              <span style={{ marginLeft: '0.15rem' }}>개</span>
                                            </small>
                                          </div>
                                        )
                                      })}
                                  </div>
                                </details>
                              )
                            case 'PriceColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta, 'payTotalPrice'))}</strong>
                                  <small style={{ marginLeft: '0.25rem' }}>원</small>
                                </div>
                              )
                            case 'ReceiverColumn':
                              return (
                                <div style={{ maxWidth: '100%', whiteSpace: 'normal' }}>
                                  <strong>{meta.receiverName}</strong>
                                </div>
                              )
                            case 'PayMethodColumn':
                              return (
                                <div style={{ maxWidth: '100%', whiteSpace: 'normal' }}>
                                  <span>{_.get(meta, 'payMethodType.text')}</span>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>해당 기간에 발주내역이 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '발주내역',
  nav: 'working'
}

export default List
