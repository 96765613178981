import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  text-align: center;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 매출업로드의 총계 표현부
class PriceColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  
  render() {
    const { item } = this.props

    // 반영건수, 거래액 표현해주기
    const meta = {}

    meta.totalPayCount = item.totalPayCount || 0
    meta.totalAmount = item.totalAmount || 0
    meta.totalPrice = item.totalPrice || 0

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ maxWidth: '100%' }}>
            <section>
              <strong style={{ marginRight: '0.35rem', fontWeight: '700' }}>{comma(meta.totalPrice)}</strong>
              <small>원</small>
            </section>
            <header>
              <small style={{ marginRight: '0.35rem' }}>총</small>
              <strong style={{ marginRight: '0.35rem' }}>{comma(meta.totalPayCount)}</strong>
              <small style={{ marginRight: '0.35rem' }}>주문</small>
              <small style={{ marginRight: '0.35rem' }}>/</small>
              <small style={{ marginRight: '0.35rem' }}>총</small>
              <strong style={{ marginRight: '0.35rem' }}>{comma(meta.totalAmount)}</strong>
              <small>개</small>
            </header>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }
}

PriceColumnContainer.propTypes = {
  item: PropTypes.object,
}

PriceColumnContainer.defaultProps = {
  item: {}
}

export default PriceColumnContainer
