import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  {
    header: '신청일자', key: 'createdAt',
    view: (item) => {
      const meta = {}
      meta.createdMt = _.get(item, 'createdAt')
        ? moment(_.get(item, 'createdAt'))
        : null
      return meta.createdMt
       ? meta.createdMt.format('YYYY년 MM월 DD일 HH시 mm분 ss초')
       : ''
    }
  },
  {
    header: '계정명', key: 'accountId',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'accountId') || ''
    }
  },
  {
    header: '이메일', key: 'email',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'email') || ''
    }
  },
  {
    header: '계정 연락처', key: 'mobile',
    view: (item) => {
      const extras = item.extras || {}
      const currentUser = extras.currentUser || {}
      return _.get(currentUser, 'mobile') || ''
    }
  },
  {
    header: '수령자명', key: 'receiver',
    view: (item) => {
      const content = item.content || {}
      const receiver = _.get(content, 'receiver')
      return receiver ? `${receiver}` : ``
    }
  },
  {
    header: '수령자 연락처', key: 'recevierMobile',
    view: (item) => {
      const content = item.content || {}
      const recevierMobile = `${_.get(content, 'mobile') || ''}`
      return recevierMobile
    }
  },
  {
    header: '우편번호', key: 'bookTitle',
    view: (item) => {
      const content = item.content || {}
      const zipCode = `${_.get(content, 'zipCode') || ''}`
      return zipCode
    }
  },
  {
    header: '우편주소지', key: 'addess',
    view: (item) => {
      const content = item.content || {}
      const address = `${_.get(content, 'address1')} ${_.get(content, 'address2')}`
      return address
    }
  },
  {
    header: '발송 및 처리단계', key: 'status',
    view: (item) => {
      const meta = {}
      meta.statusText = '알수없음' 
      if (['wait'].includes(item.status)) { meta.statusText = '신청완료' }
      if (['review'].includes(item.status)) { meta.statusText = '처리중' }
      if (['reject'].includes(item.status)) { meta.statusText = '반려' }
      if (['accept'].includes(item.status)) { meta.statusText = '발송완료' }
      if (['cancel'].includes(item.status)) { meta.statusText = '신청취소' }
      return meta.statusText
    }
  }
]

export default parser
