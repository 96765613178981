import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

class PayDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentPay = extras.currentPay || {}

    const meta = {}
    meta.payId = currentPay.id || null
    meta.payType = currentPay.payType || 'etc'
    meta.paySubject = _.get(currentPay, 'subject') || '결제내역 없음'
    meta.totalPrice = _.get(currentPay, 'totalPrice') || 0

    return (
      <Column>
        <div style={{ minWidth: '100%', maxWidth: '100%' }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                if (!meta.payId) { return alert(`종이 주문상세내역이 존재하지 않습니다.`) }
                const url = `/pays/${meta.payType}/${meta.payId}`
                return window.open(url)
              }}
            >
              <strong>{meta.paySubject}</strong>
            </div>
            <div>
              <small>
                <strong style={{ marginRight: '0.15rem', fontWeight: 900 }}>{comma(meta.totalPrice)}</strong>
              </small>
              <small>원</small>
            </div>
          </div>
      </Column>
    )
  }
}

PayDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

PayDetailColumnContainer.defaultProps = {
  item: {}
}

export default PayDetailColumnContainer
