import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Page from 'components/layouts/Page'

import FactoryTasksList from 'components/extras/FactoryTasksList' // factory에 기재된 task를 확인 할 수 있는 목록 컴포넌트

class InvoicePageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}

    let breadcrumb = ['홈', '생산요청서'], title = '생산요청서'

    let navs = []
    navs.push({ name: 'tasks', text: '생산요청서', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    } 

    return (
      <Page>
        <Helmet>
          <title>생산관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>

          <Route
              path="/factories/:nav?"
              exact
              component={(props) => {
                const { location } = props
                const search = location.search ? qs.parse(location.search) : {}

                const cProps = { admin, title: currentNav.text, nav, ...props }

                //  페이지 인자 정리
                cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
                if (search.limit) {
                  cProps.limit = Number(search.limit)
                  if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
                }
                if (search.blockLimit) {
                  cProps.blockLimit = Number(search.blockLimit)
                  if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
                }
                if (search.sorts) { cProps.sorts = search.sorts.split(',') }
                if (search.startAt) { cProps.startAt = new Date(search.startAt) }
                if (search.endAt) { cProps.endAt = new Date(search.endAt) }

                if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
                if (search.keywords) { cProps.keywords = search.keywords }

                // 필터구문 정리
                const propsKeys = Object.keys(search)
                const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
                if (filtersKeys && filtersKeys.length) {
                  cProps.filters = {}
                  filtersKeys.forEach(filtersKey => {
                    const key = filtersKey.replace('filters_', '')
                    cProps.filters[key] = search[filtersKey]
                  })
                }

                return <>
                  {!nav || ['tasks'].includes(nav) ? <FactoryTasksList {...cProps} nav="tasks" /> : null}
                </>
              }}
            />

        </Switch>
      </Page>
    )
  }
}

InvoicePageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

InvoicePageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default InvoicePageContainer
