import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 유저정보 컴포넌트
class UserDetailWithApprovalColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() { 
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() {}
  
  render() {
    const { item } = this.props

    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}

    const meta = {}
    meta.name = currentUser.name || '실명 미기재'
    meta.mobile = currentUser.mobile || '00000000000'
    meta.email = currentUser.email || '이메일 없음'
    meta.accountId = currentUser.accountId || '계정명 없음'
    meta.nickName = currentUser.nickName || '닉네임 없음'

    return (
      <Column>
        <div style={{ maxWidth: '100%', textAlign: 'center' }}>
          <section>
            <strong>{meta.nickName}</strong>
          </section>
          <section>
            <small>@{meta.accountId}</small>
          </section>
        </div>
      </Column>
    )
  }
}

UserDetailWithApprovalColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailWithApprovalColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailWithApprovalColumnContainer
