import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import { isId } from 'services/utils'

import Page from 'components/layouts/Page'
import PayQuoteForm from 'components/extras/PayQuoteForm'
import PayForm from 'components/extras/PayForm'
import PayUploadList from 'components/extras/PayUploadList'
import PayList from 'components/extras/PayList'

// 주문관리 페이지
class PayPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav = 'book' } = match.params

    let breadcrumb = ['홈', '주문관리'], title = '주문관리'

    let navs = []
    navs.push({ name: 'upload', text: '매출업로드', active: false })
    navs.push({ name: 'book', text: '도서전체', active: false })
    navs.push({ name: 'paperBook', text: '종이도서', active: false })
    navs.push({ name: 'electronicBook', text: '전자도서', active: false })
    navs.push({ name: 'solution', text: '작가서비스', active: false })
    navs.push({ name: 'etcSolutionWork', text: '추가작업비', active: false })
    navs.push({ name: 'etc', text: '기타', active: false })
    navs.push({ name: 'etcApprovalBookFileChange', text: '파일교체비', active: false })
    navs.push({ name: 'etcApprovalBookSubmit', text: '가격변경비', active: false })
    navs.push({ name: 'etcBookStockStorage', text: '재고서비스', active: false })
    navs.push({ name: 'etcEtc', text: '개인결제', active: false })
    navs.push({ name: 'quote', text: '견적서', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }

    return (
      <Page>
        <Helmet>
          <title>주문관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        
        <Switch>
          <Route
            path="/pays/:nav/:id?"
            component={(props) => {
              const { match, location } = props
              const { params } = match
              const { nav, id } = params

              const search = location.search ? qs.parse(location.search) : {}
              const cProps = { admin, ...props, itemId: params.id }
              if (id && isId(id)) { return <PayForm {...cProps} /> }

              if (!id || !isId(id)) {
                //  페이지 인자 정리
                const cProps = { admin, title: currentNav.text, nav, ...props }
                cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
                if (search.limit) {
                  cProps.limit = Number(search.limit)
                  if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
                }
                if (search.blockLimit) {
                  cProps.blockLimit = Number(search.blockLimit)
                  if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
                }
                if (search.sorts) { cProps.sorts = search.sorts.split(',') }
                if (search.startAt) { cProps.startAt = new Date(search.startAt) }
                if (search.endAt) { cProps.endAt = new Date(search.endAt) }
  
                if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
                if (search.keywords) { cProps.keywords = search.keywords }
  
                // 필터구문 정리
                const propsKeys = Object.keys(search)
                const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
                if (filtersKeys && filtersKeys.length) {
                  cProps.filters = {}
                  filtersKeys.forEach(filtersKey => {
                    const key = filtersKey.replace('filters_', '')
                    cProps.filters[key] = search[filtersKey]
                  })
                }
                
                return (
                  <>
                    <Page.Nav>
                      <Page.Nav.Items>
                        {navs
                          .filter(nav => !['etc'].includes(nav.name)) // etc는 예외처리, 상세뷰에서만 쓰임
                          .map((item, index) => 
                          <Page.Nav.Item
                            key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                            onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/pays/${item.name}`]}
                          >
                            <span>{item.text}</span>
                          </Page.Nav.Item>
                        )}
                      </Page.Nav.Items>
                    </Page.Nav>
                    {['quote'].includes(nav) ? <PayQuoteForm {...cProps} /> : null}
                    {['upload'].includes(nav) ? <PayUploadList {...cProps} /> : null}
                    {!['upload'].includes(nav) ? <PayList {...cProps} /> : null}
                  </>
                )
              }

              return <Redirect to={`/pays/book`} />
            }}
          />
        </Switch>
      </Page>
    )
  }
}

PayPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

PayPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {}
}

export default PayPageContainer
