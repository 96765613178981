import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'services/moment'
import api from 'services/api'
import styled from 'styled-components'
import { font, palette } from 'styled-theme'
import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class ResultColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}
  }

  componentDidMount() { }
  
  render() {
    const { item = {} } = this.props
    const extras = _.get(item, 'extras') || {}

    const meta = {}

    meta.user = _.get(extras, 'currentUser') || {}
    meta.product = _.get(extras, 'currentProduct') || {} // 상품화 또는 임시 상품 정보
    meta.bookSubmitApproval = _.get(extras, 'lastBookSubmitApproval') || {} // 도서제출문서 가장 최근문서

    meta.hasReviewCompleted = _.get(meta, 'bookSubmitApproval.status')
      ? ['accept'].includes(_.get(meta, 'bookSubmitApproval.status'))
      : false
    meta.reviewStatus = { text: '미제출', emoji: '⚫' }
    if (['wait'].includes(_.get(meta, 'bookSubmitApproval.status'))) { meta.reviewStatus = { text: '심사요청', emoji: '🟡' } }
    if (['review'].includes(_.get(meta, 'bookSubmitApproval.status'))) { meta.reviewStatus = { text: '심사중', emoji: '🟠' } }
    if (['reject'].includes(_.get(meta, 'bookSubmitApproval.status'))) { meta.reviewStatus = { text: '반려', emoji: '🔴' } }
    if (['accept'].includes(_.get(meta, 'bookSubmitApproval.status'))) { meta.reviewStatus = { text: '승인', emoji: '🟢' } }
    if (['cancel'].includes(_.get(meta, 'bookSubmitApproval.status'))) { meta.reviewStatus = { text: '취소', emoji: '🔴' } }

    meta.hasProductCompleted = _.get(meta, 'product.status')
      ? ['activated', 'deactivated'].includes(_.get(meta, 'product.status'))
      : false

    return (
      <Column>

        <Meta.Group>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>심사문서</small>
            </header>
            <section
              onClick={async (e) => {
                const approvalId = _.get(meta, 'bookSubmitApproval.id')
                if (!approvalId) { return alert(`해당정보는 상품화가 되지 못했습니다.`) }
                const url = `/reviews/bookSubmit?keywordsOption=approvalId&keywords=${approvalId}&filters_status=all`
                return window.open(url)
              }}
              title={`${_.get(meta, 'bookSubmitApproval.id') || '없음'}`}
              style={{ cursor: 'pointer' }}
            >
              <strong>{meta.reviewStatus.emoji} {meta.reviewStatus.text}</strong>
            </section>
          </Meta>
        </Meta.Group>

        <Meta.Group>
          <Meta style={{ minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>상품정보</small>
            </header>
            <section
              onClick={async (e) => {
                const productType = _.get(meta, 'product.productType')
                const productId = _.get(meta, 'product.id')
                if (!productId) { return alert(`해당정보는 상품화가 되지 못했습니다.`) }
                const url = `/products/${productType}/${productId}`
                return window.open(url)
              }}
              style={{ cursor: 'pointer' }}
            >
              {meta.hasProductCompleted
                ? <strong>🟢 승인</strong>
                : (_.get(meta, 'product.id') ? <strong>⚪ 임시</strong> : <strong>⚫ 없음</strong>)}
            </section>
          </Meta>
        </Meta.Group>

      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

ResultColumnContainer.propTypes = {
  item: PropTypes.object,
}

ResultColumnContainer.defaultProps = {
  item: {}
}

export default ResultColumnContainer
