import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.willWriteWorksheet = async (worksheet) => {
  const lastRowIdx = worksheet.rowCount
  Array
    .from({ length: lastRowIdx })
    .forEach((r, rIdx) => {
      worksheet.getRow(rIdx + 1).height = 20
      worksheet.getRow(rIdx + 1).alignment = { horizontal: 'center', vertical: 'middle' }
    })

  worksheet.getRow(1).font = { size: 12, bold: true }
  worksheet.getRow(1).height = { size: 30, bold: true }
}

parser.columns = [
  { 
    header:  '도서번호', key: 'rIdx',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      
      return `${meta.bookNo}`
    }
  },
  { 
    header:  '도서명', key: 'userNo',
    columnProps: { width: 50 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookTitle = _.get(book, 'title')

      return `${meta.bookTitle}`
    }
  },
  { 
    header:  '', key: 'blank',
    view: (product) => {
      return ``
    }
  },
  { 
    header:  '정가', key: 'bookPrice',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')
      meta.bookPrice = _.get(book, 'basicPrice')

      return `${meta.bookPrice}`
    }
  },
  { 
    header:  '본인구매', key: 'userEmail',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')

      if (['paperBook'].includes(meta.bookType)) {
        meta.selfPrice = _.get(book, 'usedDiscount')
          ? book.defaultPrice
          : book.basicPrice
        meta.selfPrice = Math.ceil(meta.selfPrice * (['color'].includes(meta.bookTextColorName) ? 0.85 : 0.65))
        if (['collection'].includes(meta.purposeName)) { meta.selfPrice = _.get(book, 'collectionPrice') }
      } else {
        meta.selfPrice = book.basicPrice
      }


      return `${meta.selfPrice}`
    }
  },
  { 
    header:  '유통가', key: 'userMobile',
    columnProps: { width: 15 },
    view: (product) => {
      const book = product.content || {}
      const othersRetailRoyalty = _.get(product, 'royaltyAmounts.others') || { amount: 0 }

      const meta = {}
      meta.bookNo = _.get(book, 'bookNo')
      meta.bookType = _.get(book, 'type')
      meta.purposeName = _.get(book, 'purpose.name')
      meta.bookTextColorName = _.get(book, 'text.color.name')

      if (['paperBook'].includes(meta.bookType)) {
        meta.retailPrice = Math.ceil(_.get(book, 'basicPrice') * 0.8)
      } else {
        meta.retailPrice = Math.ceil(book.basicPrice * 0.8)
      }

      return `${meta.retailPrice}`
    }
  },
  { 
    header:  '저자명', key: 'bookAuthor',
    columnProps: { width: 20 },
    view: (product) => {
      const book = product.content || {}

      const meta = {}
      meta.bookAuthor = _.get(book, 'author')

      return `${meta.bookAuthor}`
    }
  }
]

export default parser
