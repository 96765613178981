import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  font-family: ${font('primary')};

  & > div.payType { position: relative; box-sizing: border-box; }
  & > div.subject { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookAuthor { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookPrice { position: relative; box-sizing: border-box; font-size: 0.85em; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 매출내역명 기재내역을 요약해서 보여주는 컬럼
class DetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() { }
  
  render() {
    const { item } = this.props

    // 발송방식 종류
    const parcelCreatedMethodTypes = {}
    parcelCreatedMethodTypes.mixed = { name: 'mixed', text: '🧮 혼합 발송' }
    parcelCreatedMethodTypes.product = { name: 'product', text: '📦 종별 발송' }
    parcelCreatedMethodTypes.address = { name: 'address', text: '🙍 수취인별 발송' }
    parcelCreatedMethodTypes.none = { name: 'none', text: '🙅‍♀️ 정산기록만 반영' }

    // 수익금 방식 종류
    const disabledProfitTypes = {}
    disabledProfitTypes.used = { name: 'used', text: '🙅‍♀️ 수익금 없음' }
    disabledProfitTypes.notUsed = { name: 'notUsed', text: '🙆‍♂️ 수익금 있음' }

    const meta = { subject: _.get(item, 'subject') || '매출내역명 없음' }

    // 리테일 정보를 불러오기
    meta.retail = item.config || { parcelCreatedMethod: 'none' }
    meta.parcelCreatedMethodType = item.parcelCreatedMethod
      ? parcelCreatedMethodTypes[item.parcelCreatedMethod]
      : parcelCreatedMethodTypes.none
    meta.disabledProfit = ['used'].includes(_.get(item, 'disabledProfit')) ? disabledProfitTypes.used : disabledProfitTypes.notUsed
    console.log(item)
    
    return (
      <Column>
        <div className="subject">
          <div>
            <strong style={{ marginRight: '0.35rem' }}>{meta.subject}</strong>
          </div>
        </div>        
        <div className="payType">
          <small style={{ marginRight: '0.5rem' }}>{_.get(meta, 'parcelCreatedMethodType.text')}</small>
          <small style={{ marginRight: '0.5rem' }}>{_.get(meta, 'disabledProfit.text')}</small>
        </div>
      </Column>
    )
  }
}

DetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

DetailColumnContainer.defaultProps = {
  item: {}
}

export default DetailColumnContainer
