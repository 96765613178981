import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import BookSubmitList from './BookSubmitList'
import BookRetailList from './BookRetailList'
import BookStopRetailList from './BookStopRetailList'
import ExpertApplyList from './ExpertApplyList'
import SolutionSubmitList from './SolutionSubmitList'

/*
  [잊지 말아야할 절차]
  1. constructors에 추가
  2. this.navs 에 추가
  3. loadItems에 구분자값 전달 추가
  4. render에 컴포넌트 표현
  5. cloned import 컴포넌트

  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' } // 비동기
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' } // 비동기
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
*/

const constructors = {}

// 기본공통 구간
constructors.common = (that = {}) => {
  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  that.keywordsOptions.approvalId = { name: 'approvalId', text: '문서ID' }
  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' }
  that.keywordsOptions.userNo = { name: 'userNo', text: '회원번호' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
  that.keywordsOptions.nickName = { name: 'nickName', text: '닉네임' }
  that.keywordsOptions.name = { name: 'name', text: '실명' }
  that.keywordsOptions.email = { name: 'email', text: '이메일' }
  that.keywordsOptions.mobile = { name: 'mobile', text: '연락처' }
}

// 도서 공통구간
constructors.bookCommon=  (that = {}) => {
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.bookNo = { name: 'bookNo', text: '도서번호' }
  that.keywordsOptions.bookTitle = { name: 'bookTitle', text: '도서명' }
  that.keywordsOptions.bookAuthor = { name: 'bookAuthor', text: '저자명' }
  that.keywordsOptions.bookIsbn = { name: 'bookIsbn', text: 'ISBN' }
}

// 작가서비스 공통구간
constructors.solutionCommon=  (that = {}) => {
  that.keywordsOptions.solutionNo = { name: 'solutionNo', text: '작가서비스번호' } // OK, 비동기
  that.keywordsOptions.solutionName = { name: 'solutionName', text: '작가서비스명' } // OK, 비동기
}

constructors.bookSubmit = (that = {}) => {
  // 필터 셋팅
  that.filterOptions.tryType = { name: 'tryType', text: '구분', path: 'tryType', state: 'filters.tryType', item: 'tryType', conditions: [], type: 'tool' }
  that.filterOptions.tryType.conditions.push({ name: 'first', text: '⭐ 최초입점' })
  that.filterOptions.tryType.conditions.push({ name: 'retry', text: '🚩 재등록' })

  // 필터 셋팅
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '심사중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려됨' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '승인됨' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  that.filterOptions.bookType = { name: 'bookType', text: '도서종류', path: 'bookType', state: 'filters.bookType', conditions: [], type: 'tool' }
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  that.filterOptions.bookCoverColor = { name: 'bookCoverColor', text: '인쇄색상', path: 'bookCoverColor', state: 'filters.bookCoverColor', conditions: [], type: 'tool' }
  that.filterOptions.bookCoverColor.conditions.push({ name: 'black', text: '흑백도서' })
  that.filterOptions.bookCoverColor.conditions.push({ name: 'color', text: '컬러도서' })

  that.filterOptions.bookPurpose = { name: 'bookPurpose', text: '목적', path: 'bookPurpose', state: 'filters.bookPurpose', conditions: [], type: 'tool' }
  that.filterOptions.bookPurpose.conditions.push({ name: 'external', text: 'ISBN' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'internal', text: '일반판매용' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'collection', text: '소장용' })

  that.filterOptions.terminated = { name: 'terminated', text: '📪 확인여부', path: 'terminated', state: 'filters.terminated', conditions: [], type: 'tool' }
  that.filterOptions.terminated.conditions.push({ name: 'true', text: '🙆‍♂️ 확인완료' })
  that.filterOptions.terminated.conditions.push({ name: 'false', text: '🙅‍♀️ 미확인' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.bookCommon(that)
  constructors.common(that)

  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
}

constructors.bookRetail = (that = {}) => {
  // 와치 처리

  // 필터 셋팅
  that.filterOptions.retails = { name: 'retails', text: '외부유통', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '처리중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려됨' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '처리완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  that.filterOptions.bookType = { name: 'bookType', text: '도서종류', path: 'bookType', state: 'filters.bookType', conditions: [], type: 'tool' }
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.bookCommon(that)
  constructors.common(that)

  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { retails: 'kyobo', status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
}

constructors.bookStopRetail = (that = {}) => {

  // 필터 셋팅
  that.filterOptions.retails = { name: 'retails', text: '외부유통', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '처리중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려됨' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '처리완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })


  that.filterOptions.bookType = { name: 'bookType', text: '도서종류', path: 'bookType', state: 'filters.bookType', conditions: [], type: 'tool' }
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.bookCommon(that)
  constructors.common(that)

  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { retails: 'kyobo', status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
}

// 전문가등록
constructors.expertApply = (that = {}) => {
  // 필터 셋팅
  that.filterOptions.accountType = { name: 'accountType', text: '구분', path: 'accountType', state: 'filters.accountType', item: 'content.activatedToBusiness', conditions: [], type: 'tool' }
  that.filterOptions.accountType.conditions.push({ name: 'personal', text: '개인' })
  that.filterOptions.accountType.conditions.push({ name: 'business', text: '사업자' })

  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '심사중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려됨' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '승인완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.common(that)

  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
}

// 작가서비스 등록
constructors.solutionSubmit = (that = {}) => {
  // 필터 셋팅
  that.filterOptions.category = { name: 'category', text: '분류', path: 'category', state: 'filters.category', item: 'content.solution.category.name', conditions: [], type: 'tool' }
  that.filterOptions.category.conditions.push({ name: 'coverDesign', text: '표지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textDesign', text: '내지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textCorrection', text: '교정교열' })
  that.filterOptions.category.conditions.push({ name: 'etc', text: '기타' })

  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '심사중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려됨' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '승인완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.solutionCommon(that)
  constructors.common(that)
  
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
}

class ReviewListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['bookSubmit', 'bookRetail', 'bookStopRetail', 'expertApply', 'solutionSubmit']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.loadResource = this.loadResource.bind(this)
    this.prepared = this.prepared.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)
    this.getQuery = this.getQuery.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, items: [], total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  // 리소스 불러오기 @ 2023.01.30 최종판
  async loadResource(name, method) {
    const that = this
    const { resource = {} } = this.state

    // 리소스를 가져오면 필터의 컨디션에 세팅을 해준다.
    resource[name] = await api.get(`/resources/admin2/${method}`).catch(e => ({}))

    await new Promise(r => that.setState({ resource }, r))
  }

  async initialize(options = { reload: false }) {
    if (options.reload && window) {
      const query = this.getQuery()
      window.location.href = `${window.location.pathname}?${query}`
      return
    }

    await new Promise((r) => this.setState({ loading: true, selecteds: [] }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) {
      return this.setState({ loading: false, error: true })
    }
    await this.loadResource('retail', 'takeRetails') // 유통사 정보 리소스 불러오기
    await this.prepared()
    await this.loadItems()
  }

  // 필요한 리소스 또는 컴포넌트 사용준비를 하는 구간
  async prepared() {
    const that = this
    const { resource = {} } = this.state
    
    // 외부유통 활동 유저를 찾기 위한 구분자
    if (resource.retail && _.get(that, 'filterOptions.retails')) {
      const conditions = Object.values(resource.retail)
        .map((retail) => ({ name: `${retail.name}`, text: `${retail.text}` }))
      _.set(that, 'filterOptions.retails.conditions', conditions)
    }
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    query.startAt = startAt
    query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')

    // 도서최초 등록 인자 설정
    if (this.props.nav === 'bookSubmit') {
      query.tryType = filters.tryType
      query.status = filters.status
      query.bookType = filters.bookType
      query.bookCoverColor = filters.bookCoverColor
      query.bookPurpose = filters.bookPurpose
      query.terminated = filters.terminated
      query.flags = ['currentUser', 'currentProduct', 'currentBook'].join(',')
    }

    // 도서 외부유통 판매시작
    if (this.props.nav === 'bookRetail') {
      query.status = filters.status
      query.bookType = filters.bookType
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentBook', 'currentProduct'].join(',')
    }

    // 도서 외부유통 판매중지
    if (this.props.nav === 'bookStopRetail') {
      query.status = filters.status
      query.bookType = filters.bookType
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentBook', 'currentProduct'].join(',')
    }

    // 전문가 등록심사
    if (this.props.nav === 'expertApply') {
      query.status = filters.status
      query.accountType = filters.accountType
      query.flags = ['currentUser'].join(',')
    }

    // 작가서비스 최초 입점 심사
    if (this.props.nav === 'solutionSubmit') {
      query.status = filters.status
      query.category = filters.category
      query.flags = ['currentUser', 'currentSolution', 'currentProduct'].join(',')
    }
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  getQuery() {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

   const queries = {} 
   Object.keys(filters)
    .forEach((key) => {
      const name = `filters_${key}`
      queries[name] = filters[key]
    })
   if (sorts && sorts.length) { queries.sorts = sorts.join(',') }
   if (keywordsOption) { queries.keywordsOption = keywordsOption }
   if (keywords) { queries.keywords = keywords }
   if (page) { queries.page = page }
   if (limit) { queries.limit = limit }
   if (startAt) { queries.startAt = startAt }
   if (endAt) { queries.endAt = endAt }

   return qs.stringify(queries)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return null }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    if (nav ==='bookSubmit') { return <BookSubmitList {...listProps} /> }
    if (nav ==='bookRetail') { return <BookRetailList {...listProps} /> }
    if (nav ==='bookStopRetail') { return <BookStopRetailList {...listProps} /> }
    if (nav ==='expertApply') { return <ExpertApplyList {...listProps} /> }
    if (nav ==='solutionSubmit') { return <SolutionSubmitList {...listProps} /> }

    return null
  }
}

ReviewListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

ReviewListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '심사관리',
  nav: 'bookSubmit',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-3, 'month').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(ReviewListContainer))
