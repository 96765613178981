const parser = {}

parser.columns = [
  { header: '', key: 'a', view: (item) => '' }, // A
  { header: 'ISBN', key: 'isbn', view: (item) => item ? item.isbn : '' }, // B
  { header: '', key: 'c', view: (item) => '' }, // C
  { header: '', key: 'd', view: (item) => '' }, // D
  { header: '발주량', key: 'amount', view: (item) => item ? item.amount : 0 }, // E
]

export default parser
