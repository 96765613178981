import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'
import api from 'services/api'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Pager, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav,
  keywordsOption, keywords, filters, sorts, selecteds,
  total, page, limit, blockLimit, scheduler,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, sumTotal,
  ...props
}) => {
  const items = props.items || []
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    const _items = (items || [])
    if (!_items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id)
    const cIdxs = _items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ..._items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  const actions = {}

  // @ 집계 시작
  actions.startScheduler = async () => {
    const result = await api.get(`/profits/admin2/schedulerCommands/start`)
      .then((res = {}) => ({ error: false, message: ((res && res.message) ? res.message : '오류가 발생하였습니다.') }))
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
    alert(result.message)
    return window.location.reload()
  }

  // @ 집계 중단
  actions.stopScheduler = async () => {
    const result = await api.get(`/profits/admin2/schedulerCommands/stop`)
      .then((res = {}) => ({ error: false, message: ((res && res.message) ? res.message : '오류가 발생하였습니다.') }))
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
    alert(result.message)
    return window.location.reload()
  }

  // @ 전체 비우기
  actions.clearScheduleAll = async () => {
    const result = await api.delete(`/profits/admin2/clearScheduleAll`)
      .then((res = {}) => ({ error: false, message: ((res && res.message) ? res.message : '오류가 발생하였습니다.') }))
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
    alert(result.message)
    return window.location.reload()
  }

  // @ 대상자 개별 제외 처리
  actions.removeSchedule = async (item = {}) => {
    if (!item) { return alert(`올바른 접근이 아닙니다.`) }
    return await api.delete(`/profits/admin2/schedules/${item.id}`)
      .then((res = {}) => ({ error: false, message: ((res && res.message) ? res.message : '오류가 발생하였습니다.') }))
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
  }

  // @ 지급대기 전체 개별 전환
  actions.executeSchedule = async (item = {}) => {
    return await api.post(`/profits/admin2/executeSchedule/${item.id}`)
      .then((res = {}) => ({ error: false, message: ((res && res.message) ? res.message : '오류가 발생하였습니다.') }))
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
  }

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItems = async (query = {}, options = { progress: true }) => {
    const output = await loadItems({ limit: 100, page: 1, usedCount: true, ...query }, { returnal: true })
      .catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }

    const pageCount = Math.ceil(output.count/100)
    return await Array.from({ length: pageCount })
      .reduce((prev, b, idx, arr) => 
        prev.then(async (rows) => {
          const page = idx + 1
          if (options.progress) { setProgressModal({ message: `${page}/${arr.length} 블록으로 나누어 다운로드중... 🧙‍♂️`, rate: (page/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const output = await loadItems({ page, limit: 100, usedCount: false, ...query }, { returnal: true })
            .catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve([]))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch((e) => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 대상유저의 정산대기를 정산완료로 전환하고, 지급완료로 전환해주는 기능
  actions.doExecute = async (selecteds, method, options = { progress: true, clear: false }) => {
    const items = (selecteds && selecteds === 'all') ? await actions.getItems().catch((e) => []) : selecteds
    if (!method) { return alert(`처리 메소드가 올바르지 않습니다.`) }
    if (!items.length) { return alert(`선택된 내역이 없습니다.`) }

    const total = items.length
    if (!window.confirm(`총 ${total}건 입니다. 진행할까요?`)) { return false }
    
    const result = await items
      .reduce((prev, item, idx, arr) => 
        prev.then(async (report) => {
          const ex = idx + 1
          if (options.progress) { setProgressModal({ message: `${ex}/${arr.length} 작업 진행중... 💸`, rate: (ex/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const result = await actions[method](item)
          if (!result || result.error) {
            report.failure.push(result)
            return report
          }
          report.success.push(result)
          return report
        }),
        Promise.resolve({ success: [], failure: [] }))
      .then(async (report) => {
        if (options.progress) { setProgressModal(null) }
        const success = (report.success || []).length
        const failure = (report.failure || []).length
        return { ...report, error: false, message: `총 진행건수 ${items.length}건, ${success}건 처리성공, ${failure}건 처리실패` }
      })
      .catch((e) => {
        if (options.progress) { setProgressModal(null) }
        return { error: true, message: `오류가 발생하였습니다. ${e.message} ${e.stack}`, success: [], failure: [] }
      })

    if (options.clear) { await actions.clearScheduleAll().catch((e) => null) }
    alert(result.message || '처리는 마무리 되었지만, 메시지가 전달되지 않았습니다.')
    return window.location.href = `/profits/withdraws?filters_status=pending`
  }

  // 컬럼 구성
  const columns = []

  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'UserColumn', header: '대상정보', style: { minWidth: '330px', maxWidth: '330px', justifyContent: 'flex-start' } })
  columns.push({ key: 'DepositTotalColumn', header: '💰 총수익금(T)', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center', background: '#eee', borderRight: '1px solid #aaa' } })
  columns.push({ key: 'WithdrawPendingTotalColumn', header: '총지급대기(A)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'WithdrawResolvedTotalColumn', header: '총지급완료(B)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'WithdrawTotalColumn', header: '💸 총지급액(C=A+B)', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center', background: '#eee', borderRight: '1px solid #aaa' } })
  columns.push({ key: 'BookkLockedColumn', header: '부크크 제외(D)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'OthersLockedColumn', header: '외부 제외(E)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center' } })
  columns.push({ key: 'totalLockedColumn', header: '🔒 제외액(F=D+E)', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', background: '#eee', borderRight: '1px solid #aaa' } })
  columns.push({ key: 'BlankColumn', header: '', style: { minWidth: '0', flex: '1 1 100%', maxWidth: '100%', justifyContent: 'flex-start' } })
  columns.push({ key: 'FinalColumn', header: '최종결정액/10원', style: { minWidth: '260px', maxWidth: '260px', justifyContent: 'center' } })

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task>
        {!scheduler || ['idle'].includes(scheduler.status) ? (<span>👀 <strong>{comma(total)}명</strong>이 검색되었습니다.</span>) : null}
        {scheduler && ['stop'].includes(scheduler.status) ? (<span>🚫 집계를 중지중입니다!</span>) : null}
        {scheduler && ['running'].includes(scheduler.status) ? (<span>🧙‍♂️ 지급 집계중 : <strong>{scheduler.lastMessage}</strong></span>) : null}
      </Task>

      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        {['users'].includes(nav)
          ? (
            <Header.Options>
              <a href="#start-scheduler-command" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.startScheduler()]}>⏰ 집계시작</a>
              <a href="#stop-scheduler-command" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.stopScheduler()]}>⏳ 집계중지</a>
              <a href="#clear-schedule-all" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.clearScheduleAll()]}>🧹 전체 비우기</a>
              <a href="#execute-remove-schdule" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doExecute(selecteds, 'removeSchedule', { progress: true })]}>✂️  대상자 제외</a>
              <a href="#execute-schdule" onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                await actions.doExecute('all', 'executeSchedule', { progress: true, clear: true })
              }}
              >
                🧙‍♂️ 지급대기 전체전환
              </a>
            </Header.Options>
          )
          : null}
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      <Items.Body>

        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
            </DataSet.Item>
          </DataSet.Header>
          <DataSet.Body>
            {items.map(item => (
              <DataSet.Item key={item.id} style={{ borderBottom: '1px solid #cfcfcf' }}>
                {columns.map((column, columIdx) => {
                  const columnProps = { column, columIdx, item, loadItems }
                  
                  const meta = {}
                  meta.emoji = ['personal'].includes(item.purpose) ? `🦔`: `🦕`
                  meta.accountId = item.accountId || '계정명 없음'
                  meta.accountId = item.accountId || '계정명 없음'
                  meta.leaved = item.leaved ? '탈퇴' : '정상'
                  meta.tinyGap = item.targetAmount - item.withdrawAmount

                  return (
                    <DataSet.Col key={`${column.key}_${columIdx}`} style={{ ...column.style }}>
                      {(key => {
                        switch (key) {
                          case 'checkbox':
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  checked={isExistsSelected(item)}
                                  readOnly
                                  disabled={meta.disabled}
                                  onClick={e => [e.stopPropagation(), !meta.disabled ? doCheck(item) : e.preventDefault()]}
                                />
                              </>
                            )
                          case 'UserColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{meta.emoji} {meta.accountId}</strong>
                                <small>{meta.leaved}</small>
                              </div>
                            )
                          case 'DepositTotalColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.depositAmount)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'WithdrawPendingTotalColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.totalPendingWithdraw)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'WithdrawResolvedTotalColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.totalResolvedWithdraw)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'WithdrawTotalColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.totalPendingWithdraw + item.totalResolvedWithdraw)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'BookkLockedColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.exceptionBookkAmount)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'OthersLockedColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.exceptionOthersAmount)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'totalLockedColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.exceptionAmount)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'LimitAmountColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.targetAmount)}</strong>
                                <small>원</small>
                              </div>
                            )
                          case 'FinalColumn':
                            return (
                              <div>
                                <strong style={{ marginRight: '0.25rem' }}>{comma(item.withdrawAmount)}</strong>
                                {meta.tinyGap
                                  ? (<small>원 ({item.targetAmount - item.withdrawAmount}원 남음)</small>)
                                  : <small>원</small>}
                              </div>
                            )
                          default:
                        }
                      })(column.key)}
                    </DataSet.Col>
                  )
                })}
              </DataSet.Item>
            ))}
            {!items.length ? (
              <Items.NotFound>
                <header>🙋 대상자 선정을 통해서 처리 가능합니다.</header>
                <section>대상자 선정공식 : <strong>[총수익액] - [총지급액] - [제외액]</strong></section>
                <section>
                  <ul>
                    <li>
                      <strong>총수익액</strong> : 정산대기 + 정산완료 금액의 합산 금액(순정산 + 역정산 합산액)<br/>
                      <small>순정산은 양수로 정산된 내역을 의미, 역정산은 음수로 정산된 내역을 의미</small>
                    </li>
                    <li>
                      <strong>총지급액(예정+최종)</strong> : 당월지급대기(선처리했던 금액) + 전체 지급했던 금액
                    </li>
                    <li>
                      <strong>제외액</strong> : 부크크 당월, 외부유통 전월
                    </li>
                  </ul>
                </section>
              </Items.NotFound>
            ) : null}
            <div style={{ padding: '1rem', textAlign: 'right', fontSize: '1.5em' }}>
              <strong style={{ fontWeight: 900 }}>{comma(sumTotal)}</strong> 원
            </div>
          </DataSet.Body>
        </DataSet>

      </Items.Body>
      

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '미리보기',
  nav: 'preview'
}

export default List
