// 수동모달
import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import api from 'services/api'

import moment from 'services/moment'
import { lettersToNumber } from 'services/utils'

import Modal from 'components/utils/Modal'

import { Exporter } from 'services/transform'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL





const Uploader = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem; display: flex;
  & > div.box { padding: 1rem; }
  & a { text-decoration: none; color: ${palette('muted', 5)}; }
  & img { border-radius: 0.35rem; }
`

// 퍄일업로드 구간 만들기
const UploadForm = (props = {}) => {
  const formControl = React.useRef(null)

  const actions = {}
  
  actions.doUpload = async (file) => {
    const formData = new FormData()
    formData.append(`file`, file)
    const result = await api.upload('/resources/retails/logo', formData, { base: 'file' }).catch(e => null)
    if (!result) { return alert(`업로드 도중 문제가 발생했습니다.`) }
    await props.onUploaded(result)
  }

  // props.item
  const meta = {}
  meta.file = props.file || ''
  
  return (
    <Uploader>
      <div className="box">
        <img
          src={meta.file ? `${REACT_APP_FILE_URL}/${meta.file}` : ``}
          style={{ width: '100px', height: '100px' }}
          onError={(e) => {
            return e.target.src = `/sample.png`
          }}
         />
      </div>
      <div className="box">
        <form ref={formControl}>
          <input
            type="file"
            onChange={async (e) => {
              if (!e.target.files[0]) { return }
              await actions.doUpload(e.target.files[0])
              formControl.current.reset()
            }}
          />
          <div style={{ padding: '0.5rem 0' }}>
            <small>정사각형 이미지(480px * 480px 디자인이 최적)</small>
          </div>
          <div style={{ padding: '0.5rem 0' }}>
            <a
              href="#deleteFile"
              onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await props.onUploaded({ path: '' })]}
            >
              비우기
            </a> 
          </div>
        </form>
      </div>
    </Uploader>
  )
}
















































const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
  & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0; margin: 0 -0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

const Creator = styled.div`
  position: relative; box-sizing: border-box;
  ${controlStyles}
  ${buttonStyles}
`

Creator.Header = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0.5rem;
  & small { color: ${palette('muted', 8)}; }
`

Creator.Form = styled.div`
  position: relative; box-sizing: border-box;
`

Creator.Columns = styled.div`
  position: relative; box-sizing: border-box;
`

Creator.Column = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0.5rem;
  display: flex; align-items: center; justify-content: space-between;
  & a { color: #232323; text-decoration: none; }
`

// 매출업로드 엑셀관리 기능이라든지 상위 컴포넌트에 엑셀 폼셋 규격에 맞춰서 배치해주는 컴포넌트
const Formset = (props = {}) => {
  const { item, lead, onUpdate = () => {} } = props
  
  const parentValue = props.value || { start: 0, identity: 'productId', columns: {} }
  const [form, setForm] = React.useState({ column: '', data: ''  })

  const columns = parentValue.columns ? Object.values(parentValue.columns) : []

  // 맵핑데이터 키값
  const dataTypes = {}
  // 기본적으로 갖춰야하는 동일 선상의 기능
  dataTypes.identity = { name: 'identity', text: '매칭키' }
  dataTypes.quantity = { name: 'quantity', text: '발주량' }

  // 동주소가 동일한 경우와 받는이 모두 슈퍼키로 맞춰야한다.
  dataTypes.receiver = { name: 'receiver', text: '받는이' }
  dataTypes.mobile = { name: 'mobile', text: '받는이 연락처' }
  dataTypes.zip = { name: 'zip', text: '우편번호' }
  dataTypes.address1 = { name: 'address1', text: '동주소' }
  dataTypes.address2 = { name: 'address2', text: '상세주소' }
  dataTypes.message = { name: 'message', text: '배송요청 메시지' }
  dataTypes.charge = { name: 'charge', text: '선불후불(착불) 여부' }
  dataTypes.trackingNumber = { name: 'trackingNumber', text: '운송장번호' }
  dataTypes.externalContractName = { name: 'externalContractName', text: '특약키' }
  dataTypes.externalContractProfitAmount = { name: 'externalContractProfitAmount', text: '수기특약액수' }

  // 부모로 부터 받아온 값을 배치한다.
  const actions = {}
  actions.doDeleteColumn = (col = {}) => {
    const next = { ...parentValue }
    delete next.columns[col.column]
    return onUpdate(next)
  }

  actions.doCreateColumn = (col = {}) => {
    const next = { ...parentValue }
    if (!col.column) { return alert(`엑셀열명을 적어주세요.(예, A, AB, BA, GA, AAA)`) }
    if (!col.data) { return alert(`맵핑할 소스값을 입력해주세요.`) }

    // 이미 추가했는지 검증하기
    if (!next.start) { next.start = 0 }
    if (!next.identity) { next.identity = 'productId' }
    if (!next.columns) { next.columns = {} }
    if (next.columns[col.column]) { return alert(`이미 존재합니다. 존재하는 열을 삭제해주세요.`) }

    next.columns[col.column] = col
    return onUpdate(next)
  }

  // 해당 양식으로 다운로드 받는 기능
  actions.downloadExcelSample = async () => {
    const parser = {}
    parser.columns = columns
      .map((cell) => {
        const meta = {}
        meta.text = dataTypes[cell.data] ? dataTypes[cell.data].text : `알수없음`
        meta.id = lettersToNumber(cell.column)
        return  { key: meta.id, header: `${meta.text}(${cell.column})`, view: () => { return `` } }
      })
    parser.mode = 'numeric'
    return await new Exporter({ parser, items: [] })
      .download(`${item.name} ${lead} 엑셀 업로드 예시양식`)
      .catch(e => {
        console.log(`${e.message} ${e.stack}`)
        alert(e.message)
      })
  }

  return (
    <Creator>
      <Creator.Header>
        <strong style={{ marginRight: '0.35rem' }}>{lead}</strong>
        <span style={{ marginRight: '0.35rem' }}>엑셀양식</span>
        <small>매핑키(상품 인식번호), 시작줄 번호</small>
      </Creator.Header>
      <Creator.Form>
        <div className="control">
          <select
            value={parentValue.identity}
            style={{ marginRight: '0.5rem' }}
            onChange={(e) => {
              let value = e.target.value
              if (!value) { return }
              const next = { ...parentValue }
              next.identity = value
              return onUpdate(next)
            }}
          >
            <option value="">매칭키 구분</option>
            <option value={`auto`}>자동매칭</option>
            <option value={`productId`}>상품ID</option>
            <option value={`bookNo`}>도서 번호</option>
            <option value={`bookIsbn`}>부크크 ISBN</option>
            <option value={`retailIsbn`}>외부유통사 ISBN</option>
          </select>
          <input
            type="number"
            step={1}
            value={parentValue.start || 0}
            style={{ marginRight: '0.5rem' }}
            onChange={(e) => {
              const next = { ...parentValue }
              let value = e.target.value
              next.start = value
              return onUpdate(next)
            }}
            onBlur={(e) => {
              let value = e.target.value * 1
              if (_.isNaN(value)) { return }
              if (!_.isInteger(value)) { return }
              if (value < 0) { value = 0 }
              const next = { ...parentValue }
              next.start = value * 1
              return onUpdate(next)
            }}
          />
        </div>
      </Creator.Form>

      <Creator.Header>
      <span style={{ marginRight: '0.35rem' }}>컬럼설정</span>
      <small>엑셀열, 매칭소스</small>
    </Creator.Header>
    <Creator.Form>
      <div className="control">
        <input
          type="text"
          value={form.column}
          placeholder={`A`}
          onChange={e => {
            let value = `${e.target.value}`.toUpperCase()
            value = value.replace(/[^A-Za-z]/ig, '')
            return setForm({ ...form, column: value })
          }}
          style={{ marginRight: '0.5rem' }}
        />
        <select
          style={{ marginRight: '0.5rem' }}
          value={form.data}
          onChange={e => {
            return setForm({ ...form, data: e.target.value })
          }}
        >
          <option value="">구분</option>
          {Object.values(dataTypes).map((type, tIdx) => {
            return <option key={tIdx} value={type.name}>{type.text}</option>
          })}
        </select>
        <button
          type="button"
          onClick={e => {
            return actions.doCreateColumn(form)
          }}
        >
          추가
        </button>
      </div>
    </Creator.Form>

    <Creator.Columns>
      {columns.map((column, cIdx) => {
        const meta = {}
        meta.text = dataTypes[column.data] ? dataTypes[column.data].text : `알수없음`

        return (
          <Creator.Column key={cIdx}>
            <div>
              <strong style={{ fontWeight: 900 }}>{column.column}열</strong>
              <span> - {meta.text}</span>
            </div>
            <div>
              <a
                href="#deleteColumn"
                onClick={e => {
                  return [e.stopPropagation(), e.preventDefault(), actions.doDeleteColumn(column)]
                }}
              >
                삭제
              </a>
            </div>
          </Creator.Column>
        )
      })}
    </Creator.Columns>
    
    <div style={{ padding: '1rem 0', textAlign: 'right' }}>
      <a
        href="#downloadExcelSample"
        style={{ textDecoration: 'none', color: '#666' }}
        onClick={async (e) => {
          return [e.stopPropagation(), e.preventDefault(), await actions.downloadExcelSample()]
        }}
      >
        📥 양식 다운로드
      </a>
    </div>
  </Creator>
  )
}























// @ 특약인세 관리
const ExternalContractForm = ({ ...props }) => {

  const initForm = { name: '', rate: 0, payType: 'electronicBook' }
  const [form, setForm] = React.useState(initForm)

  const actions = {}

  actions.onUpdate = (next) => {
    if (props.onUpdate) { props.onUpdate(next) }
  }

  const columns = _.get(props, 'value.length') ? (props.value || []) : []

  return (
    <Creator>
      <Creator.Header>
        <strong style={{ marginRight: '0.35rem' }}>인세특약</strong>
        <small>특약명 매출업로드내 키구분값,  0~1(1이하의 값 비율값)</small>
      </Creator.Header>
      <Creator.Form>
        <div className="control">
          <select
            value={form.payType}
            placeholder='비율(예시, 0.5)'
            onChange={(e) => {
              let value = e.target.value
              return setForm({ ...form, rate: value })
            }}
            style={{ marginRight: '0.5rem' }}
          >
            <option value="paperBook">종이도서</option>
            <option value="electronicBook">전자도서</option>
          </select>
          <input
            type="text"
            value={form.column}
            placeholder='특약명(예시, B2B)'
            onChange={e => {
              let value = e.target.value
              return setForm({ ...form, name: value })
            }}
            style={{ marginRight: '0.5rem' }}
          />
          <input
            type="text"
            placeholder='비율(예시, 0.5)'
            value={form.column}
            onChange={(e) => {
              let value = parseFloat(e.target.value ? e.target.value : 0)
              if (!value) { value = 0 }
              if (value < 0) { value = 0 }
              if (value > 1) { value = 1 }
              return setForm({ ...form, rate: value })
            }}
            style={{ marginRight: '0.5rem' }}
          />
          <button
            type="button"
            title="추가"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              if (!form.payType) { return alert(`적용범위가 올바르지 않습니다.`) }
              if (!form.name) { return alert(`키값이 올바르지 않습니다.`) }
              if (!form.rate) { return alert(`정책에 반영할 비율이 올바르지 않습니다..`) }
              const next = [...columns]
              const _idx = next.findIndex(o => o.name === form.name)
              if (_idx > 0) {
                next[_idx] = form
              } else {
                next.push(form)
              }
              setForm(initForm)
              return actions.onUpdate(next)
            }}
          >
            추가
          </button>
        </div>
      </Creator.Form>
      <Creator.Columns>
        {columns.map((column, cIdx) => {
          const meta = {}
          meta.payTypeText = column.payType === 'paperBook' ? '종이도서' : '기타'
          meta.payTypeText = column.payType === 'electronicBook' ? '전자도서' : meta.payTypeText
          return (
            <Creator.Column key={cIdx}>
              <div title={`${column.payType}`}>
                <strong style={{ fontWeight: 900, marginRight: '0.35rem' }}>[{meta.payTypeText}]</strong>
                <strong style={{ fontWeight: 900, marginRight: '0.35rem' }}>{column.name}</strong>
                <strong style={{ fontWeight: 900, marginRight: '0.35rem' }}>{column.rate}</strong>
                <small>{`${parseInt(column.rate * 100)}%`} 로얄티 배정</small>
              </div>
              <div>
                <a
                  href="#deleteColumn"
                  title="추가"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    const next = columns.filter(o => o.name !== column.name)
                    actions.onUpdate(next)
                  }}
                >
                  삭제
                </a>
              </div>
            </Creator.Column>
          )
        })}
      </Creator.Columns>

    </Creator>
  )
}



























































// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem;
  display: flex;
  & > div.lead {
    flex: 1 1 auto; min-width: 0;
    position: relative; box-sizing: border-box;
    font-weight: 900; font-size: 1.43em; font-family: ${font('primary')};
  }
  & > div.buttons {
    padding: 0 1rem;
    min-width: max-content; max-width: max-content;
    position: relative; box-sizing: border-box;
    & a {
      text-decoration: none; color: ${palette('muted', 3)};
      &:hover { color: ${palette('muted', 1)}; }
    }
  }
`

Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  min-width: 340px; max-width: 340px;
  padding: 1rem;
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0 1rem; flex: 1 1 auto;
`

// 유통리스트
const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  border-radius: 0.35rem; border: 3px solid ${palette('muted', 18)}; cursor: pointer;
  margin: 0.35rem 0; padding: 0.5rem 1rem;
  background: white; transition: all 0.35s;

  &:hover { background: ${palette('muted', 19)}; }
  &.active { border: 3px solid #111; }
  
  & > div.meta {
    position: relative; box-sizing: border-box;
    flex: 1 1 auto; line-height: 1.5rem;
    & small { opacity: 0.8; }
  }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 상위 컴포넌트가 생명주기를 관장하는 수동적 모달
class RetailsModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedSetup = {}

    // 기본 형틀
    this.initializedItem = {
      name: "newRetail",
      text: "새로운 유통사",

      logo: '', // 유통사 로고주소
      autoAccept: false,
      shown: true,

      description: "신규 유통사 관리를 의미합니다.",
      uses: ["paperBook,electronicBook"],

      env: {
          paperBook: {
              supportedTextColor: ["black", "color"],
              royalties: {
                  blackAuthorRoyaltyRate: 0.2,
                  colorAuthorRoyaltyRate: 0.15
              }
          },
          electronicBook: {
              royalties: { authorRoyaltyRate: 0.7 }
          }
      },
      sortIdx: 99,

      supplyStatusAtPayUploadResolved: 'ready', // 매출업로드 반영당시 finished의 경우 교보문고에서 사용, 거래완료상태로 처리를 마감지을 것인가에 대한 옵션
      parcelCreatedMethod: 'mixed', // 종이도서인경우 도서 종별묶음방식(product), 배송지별 묶음방식(address), 발주없음(none)
      profitCreateAtPayUploadResolved: 'notUsed', // 수익금 반영여부를 발송처리로 미루고자 하면 notUsed, 처리를 사용하는 경우 used 처리

      // 엑셀업로드 양식
      formsets: {
        bookSalesRetail: { start: 0, identity: 'productId', columns: {} }
      }
    }
    
    this.initializedState = {
      loading: true,
      setup: JSON.parse(JSON.stringify(this.initializedSetup)),
      item: null
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.loadSetup = this.loadSetup.bind(this)
    this.createItem = this.createItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.saveSetup = this.saveSetup.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const setup = await this.loadSetup()
    await new Promise((r) => this.setState({ loading: false, setup, item: setup.bookk }, r))
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/retails.json)
  async loadSetup() {
    return await api.get(`/payUploads/admin2/retails`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return JSON.parse(JSON.stringify(this.initializedSetup)) }
        return data
      })
      .catch(e => JSON.parse(JSON.stringify(this.initializedSetup)))
  }

  // 개별개체를 추가하는 기능 : 추가할 때 반영하고 나서 반드시 정렬해줘야 한다.
  // { at: new Date() 형식, message: '' }
  async createItem(item) {
    const next = _.get(this, 'state.setup') || JSON.parse(JSON.stringify(this.initializedSetup))
    next[item.name] = item
    return await new Promise((r) => this.setState({ setup: next, item }, r))
  }

  // 요일을 삭제하는 기능 : 해당 번호 기준으로만 삭제 한다.
  async removeItem(item = {}) {
    if (!window.confirm(`유통사를 제거 할까요?`)) { return }
    const next = _.get(this, 'state.setup') || JSON.parse(JSON.stringify(this.initializedSetup))
    delete next[item.name]
    await new Promise((r) => this.setState({ setup: next }, r))
    return await this.saveSetup(next) 
  }

  // 서버에 저장하는 기능 : 서버에 원고수정일자를 기록하는 리소스파일을 생성한다. (api 서버의 resources/retails.json)
  async saveSetup(setup) {
    const { onClose } = this.props
    const payload = {}
    payload.setup = setup ? setup : _.get(this, 'state.setup')
    return await api.post(`/payUploads/admin2/retails`, payload)
      .then(async (res) => {
        if (res.error) { return alert(`에러가 발생하였습니다. (${res.message})`) }
        alert(`성공적으로 처리 하였습니다.`)
        return onClose() 
      })
      .catch(e => {
        return alert(`에러가 발생하였습니다. ${e.message}`)
      })
  }
  
  // 랜더링
  render() {
    const that = this
    const { createItem, removeItem, saveSetup } = this
    const { onClose } = this.props
    const { loading, setup, item } = this.state
    if (loading) { return null }

    const retails = Object.values(setup || {})
    retails.sort((a, b) => a.sortIdx > b.sortIdx ? 1 : -1)


    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    // 폼 때문에 캐스트해야하는 항목들
    const form = {}
    form.uses = item && item.uses ? `${item.uses}` : ''
    form.env = _.get(item, 'env') || {}
    form.isNotUsedParcel = !form.uses.includes('paperBook')
    form.usedProfit = item && ['used'].includes(item.usedProfit)

    // 도서 내지 색상 구별구간
    form.supportedTextColor = _.get(item, 'env.paperBook.supportedTextColor') || []
    
    // 흑백도서의 로얄티 설정구간
    form.usedBlackPaperBook = form.supportedTextColor.includes('black')
      ? `used`
      : `notUsed`
    form.blackPaperBookRoyalty =_.get(item, 'env.paperBook.royalties.blackAuthorRoyaltyRate') || 0

    // 컬러도서의 로얄티 설정구간
    form.usedColorPaperBook = form.supportedTextColor.includes('color')
      ? `used`
      : `notUsed`
    form.colorPaperBookRoyalty =_.get(item, 'env.paperBook.royalties.colorAuthorRoyaltyRate') || 0

    // 전자도서의 로얄티 설정구간
    form.electronicBookRoyalty =_.get(item, 'env.electronicBook.royalties.authorRoyaltyRate') || 0

    // 전자도서의 로얄티 설정구간
    form.solutionRoyalty =_.get(item, 'env.solution.royalties.expertRoyaltyRate') || 0

    // 하위 컴포넌트 프로퍼티 관리
    const bookSalesRetailProps = {
      item,
      lead: '도서 매출내역',
      value: _.get(item, 'formsets.bookSalesRetail'),
      onUpdate: (value) => {
        console.log(value, value)
        if (!value) { return }
        const next = item
        _.set(next, 'formsets.bookSalesRetail', value)
        return this.setState({ item: next })
      }
    }

    // @ 매출업로드 강제 인세조항
    const externalContractFormProps = {
      value: _.get(item, 'formsets.externalContract'),
      onUpdate: (value) => {
        if (!value) { return }
        const next = item
        _.set(next, 'formsets.externalContract', value)
        return this.setState({ item: next })
      }
    }

    // const solutionSalesRetailProps = {
    //   item,
    //   lead: '작가서비스 매출내역',
    //   value: _.get(item, 'formsets.solutionSalesRetail'),
    //   onUpdate: (value) => {
    //     console.log(value, value)
    //     if (!value) { return }
    //     const next = item
    //     _.set(next, 'formsets.solutionSalesRetail', value)
    //     return this.setState({ item: next })
    //   }
    // }

    // const stateRetailProps = {
    //   item,
    //   lead: '판매상태',
    //   value: _.get(item, 'formsets.stateRetail'),
    //   onUpdate: (value) => {
    //     if (!value) { return }
    //     const next = item
    //     _.set(next, 'formsets.stateRetail', value)
    //     return this.setState({ item: next })
    //   }
    // }

    return (
      <Modal {...modalProps}>
        <Form style={item && item.name ? { maxWidth: '960px', minWidth: '960px', width: '960px' } : { maxWidth: 'fit-content', minWidth: 'fit-content', width: 'fit-content' }}>
          <Form.Header>
            <div className="lead">유통정책 관리자</div>
            <div className="buttons">
              <a
                href="#addRetail"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (!window.confirm(`현재 수정하고 있는 유통사 정보가 있다면 사라집니다. 저장을 먼저 해주시고 진행해주세요. 새로 만들기를 진행 할까요?`)) { return }
                  
                  const empty = JSON.parse(JSON.stringify(this.initializedItem))
                  empty.name = window.prompt(`새로운 유통사 코드(영문으로 시작, 첫자 영어) 입력해주세요.`) || `r${Date.now()}`
                  empty.text = window.prompt(`유통사명을 입력해주세요.(한글)`) || `${empty.name}`
                  empty.sortIdx = 99

                  await createItem(empty)

                  setTimeout(async () => {
                    const setup = _.get(that, 'state.setup')
                    await saveSetup(setup)
                  }, 1)
                }}
              >
                신규추가
              </a>
            </div>
          </Form.Header>
          <Form.Grid>

              {item && item.name
                ? (
                  <Form.Content>

                    <UploadForm
                      file={item.logo}
                      onUploaded={async ({ path }) => {
                        const next = item
                        next.logo = path
                        return this.setState({ item: next })
                      }}
                    />

                    <div className="control">
                      <input
                        type="text"
                        value={item.text || ''}
                        style={{ marginRight: '0.5rem', fontWeight: '900' }}
                        onChange={(e) => {
                          const next = item
                          next.text = e.target.value
                          return this.setState({ item: next })
                        }}
                      />
                      <select
                        value={item.autoAccept ? `true` : `false`}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.autoAccept = ['true'].includes(e.target.value)
                          return this.setState({ item: next })
                        }}
                      >
                        <option value="">자동승인</option>
                        <option value={`true`}>🟩 자동 승인처리</option>
                        <option value={`false`}>⬜ 심사후 승인처리</option>
                      </select>
                    </div>

                    <div className="control">
                      <select
                        style={{ marginRight: '0.5rem' }}
                        value={item.shown ? `true` : `false`}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.shown = ['true'].includes(e.target.value)
                          return this.setState({ item: next })
                        }}
                      >
                        <option value="">일반사용자 선택가능여부</option>
                        <option value={`true`}>🟩 사용중(공개중)</option>
                        <option value={`false`}>⬜ 사용안함(비공개)</option>
                      </select>
                      <input
                        type="number"
                        step={1}
                        value={item.sortIdx || 0}
                        onChange={(e) => {
                          let value = e.target.value * 1
                          if (_.isNaN(value)) { return }
                          if (!_.isInteger(value)) { return }
                          if (value < 0) { value = 0 }
                          const next = item
                          next.sortIdx = value * 1
                          return this.setState({ item: next })
                        }}
                      />
                    </div>

                    <div className="control">
                      <select
                        style={{ marginRight: '0.5rem' }}
                        value={form.uses}
                        onChange={e => {
                          if (!e.target.value) { return alert(`반드시 한가지 상품유형을 선택해주세요.`) }
                          const next = item
                          next.uses = `${e.target.value}`.split(',')

                          // 종이도서가 있을때
                          if (!next.env) { next.env = {} }
                          if (item.uses.includes('paperBook')) {
                            next.env.paperBook = {
                              supportedTextColor: ['black', 'color'],
                              royalties: {
                                blackAuthorRoyaltyRate: 0.35,
                                colorAuthorRoyaltyRate: 0.2
                              },
                            }
                          }

                          // 전자도서가 있을때
                          if (item.uses.includes('electronicBook')) {
                            next.env.paperBook = { royalties: { authorRoyaltyRate: 0.7 } }
                          }

                          // 작가서비스 있을때
                          if (item.uses.includes('solution')) {
                            next.env.solution = { royalties: { expertRoyaltyRate: 0.7 } }
                          }

                          // 전자도서만 있는경우 발주기능을 사용안함처리
                          if (['electronicBook'].includes(e.target.value)) {
                            next.parcelCreatedMethod = 'none'
                          } else {
                            next.parcelCreatedMethod = 'product'
                          }

                          return this.setState({ item: next })
                        }}
                      >
                        <option value={''}>구분</option>
                        <option value={'paperBook,electronicBook'}>전체</option>
                        <option value={'paperBook'}>종이도서</option>
                        <option value={'electronicBook'}>전자도서</option>
                        {/* <option value={'solution'}>작가서비스</option> */}
                      </select>
                      
                      <input
                        type="text"
                        placeholder={item && item.name || '유통사 관리코드'}
                        readOnly
                        onClick={e => {
                          const prevItem = item, nextSetup = setup
                            let value = window.prompt(`입력할 유통사 코드를 작성해주세요.`, prevItem.name)
                            value = value ? value.replace(/[^A-Za-z0-9]/ig, '') : ``
                            if (!value) { return alert(`반드시 값을 입력해야하며 영문으로 입력해야합니다.`) }
                            const next = JSON.parse(JSON.stringify(prevItem))
                            next.name = value
                            delete nextSetup[prevItem.name]
                            nextSetup[next.name] = next
                            return this.setState({ item: next, setup: nextSetup })
                        }}
                      />
                    </div>

                    <div className="control">
                      <select
                        value={_.get(item, 'parcelCreatedMethod')}
                        style={{ marginRight: '0.5rem' }}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.parcelCreatedMethod = e.target.value
                          return this.setState({ item: next })
                        }}
                        disabled={form.isNotUsedParcel}
                      >
                        <option value={''}>발주내역 생성방식</option>
                        <option value={'mixed'}>상품종별, 배송지별 혼합</option>
                        <option value={'product'}>상품종별 묶음</option>
                        <option value={'address'}>배송지별 묶음</option>
                        <option value={'none'}>발주안함</option>
                      </select>

                      <select
                        value={_.get(item, 'supplyStatusAtPayUploadResolved')}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.supplyStatusAtPayUploadResolved = e.target.value
                          return this.setState({ item: next })
                        }}
                      >
                        <option value={''}>매출업로드 최종반영시</option>
                        <option value={'ready'}>⬜ 거래중 상태(기본, 발주있음)</option>
                        <option value={'sent'}>🟩 거래완료 및 마감(발주 없음)</option>
                      </select>
                    </div>

                    <div className="control" style={{ marginBottom: '1.5rem' }}>
                      <select
                        value={item.usedProfit}
                        style={{ marginRight: '0.5rem' }}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.usedProfit = e.target.value
                          if (['notUsed'].includes(next.usedProfit)) { next.profitCreateAtPayUploadResolved = 'notUsed' }
                          return this.setState({ item: next })
                        }}
                      >
                        <option value={''}>유통사 수익금 기능 사용여부</option>
                        <option value={'used'}>🟩 수익금 사용</option>
                        <option value={'notUsed'}>⬜ 수익금 사용안함</option>
                      </select>

                      <select
                        disabled={!['used'].includes(item.usedProfit)}
                        value={item.profitCreateAtPayUploadResolved}
                        onChange={e => {
                          if (!e.target.value) { return }
                          const next = item
                          next.profitCreateAtPayUploadResolved = e.target.value
                          return this.setState({ item: next })
                        }}
                      >
                        <option value={''}>매출업로드 반영직후 수익금</option>
                        <option value={'used'}>🟩 반영시 수익금 배정</option>
                        <option value={'notUsed'}>⬜ 출고처리시 배정</option>
                      </select>
                    </div>

                    {form.usedProfit
                      ? (
                        <div style={{ marginBottom: '1.5rem' }}>
                          {item.uses.includes('paperBook')
                            ? (
                              <>
                                <div className="header">
                                  <strong style={{ marginRight: '0.35rem' }}>종이도서</strong>
                                  <span>로얄티</span>
                                  <small style={{ marginLeft: '0.3rem' }}>1 ~ 0.01%</small>
                                </div>
                                <div className="control">
                                  <select
                                    style={{ marginRight: '0.5rem' }}
                                    value={form.usedBlackPaperBook}
                                    onChange={e => {
                                      if (!e.target.value) { return }
                                      const next = item
                                      let modified = _.get(next, 'env.paperBook.supportedTextColor') || []

                                      // 흑백을 사용할 경우
                                      if (['used'].includes(e.target.value)) {
                                        modified = modified.filter(o => o !== 'black')
                                        modified.push('black')
                                      } else {
                                        modified = modified.filter(o => o !== 'black')
                                      }

                                      _.set(next, 'env.paperBook.supportedTextColor', modified)
                                      return this.setState({ item: next })
                                    }}
                                  >
                                    <option value="used">흑백 사용중</option>
                                    <option value="notUsed">흑백 사용안함</option>
                                  </select>
                                  <input
                                    type="number"
                                    step={0.01}
                                    value={form.blackPaperBookRoyalty}
                                    onChange={(e) => {
                                      let value = new Number(e.target.value)
                                      if (_.isNaN(value)) { return }
                                      if (!value) { value = 0 }
                                      const next = item
                                      _.set(next, 'env.paperBook.royalties.blackAuthorRoyaltyRate', value)
                                      return this.setState({ item: next })
                                    }}
                                  />
                                </div>
                                <div className="control">
                                  <select
                                    style={{ marginRight: '0.5rem' }}
                                    value={form.usedBlackPaperBook}
                                    onChange={e => {
                                      if (!e.target.value) { return }
                                      const next = item
                                      let modified = _.get(next, 'env.paperBook.supportedTextColor') || []

                                      // 컬러 사용할 경우
                                      if (['used'].includes(e.target.value)) {
                                        modified = modified.filter(o => o !== 'color')
                                        modified.push('color')
                                      } else {
                                        modified = modified.filter(o => o !== 'color')
                                      }

                                      _.set(next, 'env.paperBook.supportedTextColor', modified)
                                      return this.setState({ item: next })
                                    }}
                                  >
                                    <option value="used">컬러 사용중</option>
                                    <option value="notUsed">컬러 사용안함</option>
                                  </select>
                                  <input
                                    type="number"
                                    step={0.01}
                                    value={form.colorPaperBookRoyalty}
                                    onChange={(e) => {
                                      let value = new Number(e.target.value)
                                      if (_.isNaN(value)) { return }
                                      if (!value) { value = 0 }
                                      const next = item
                                      _.set(next, 'env.paperBook.royalties.colorAuthorRoyaltyRate', value)
                                      return this.setState({ item: next })
                                    }}
                                  />
                                </div>
                              </>
                            )
                            : null}
                            {item.uses.includes('electronicBook')
                              ? (
                                <>
                                  <div className="header">
                                    <strong style={{ marginRight: '0.35rem' }}>전자도서</strong>
                                    <span>로얄티</span>
                                    <small style={{ marginLeft: '0.3rem' }}>1 ~ 0.01%</small>
                                  </div>
                                  <div className="control">
                                    <input
                                      type="number"
                                      step={0.01}
                                      value={form.electronicBookRoyalty}
                                      onChange={(e) => {
                                        let value = new Number(e.target.value)
                                        if (_.isNaN(value)) { return }
                                        if (!value) { value = 0 }
                                        const next = item
                                        _.set(next, 'env.electronicBook.royalties.authorRoyaltyRate', value)
                                        return this.setState({ item: next })
                                      }}
                                    />
                                    <div></div>
                                  </div>
                                </>
                              )
                              : null}
                            {/* {item.uses.includes('solution')
                              ? (
                                <>
                                  <div className="header">
                                    <strong style={{ marginRight: '0.35rem' }}>작가서비스</strong>
                                    <span>로얄티</span>
                                    <small style={{ marginLeft: '0.3rem' }}>1 ~ 0.01%</small>
                                  </div>
                                  <div className="control">
                                    <input
                                      type="number"
                                      step={0.01}
                                      value={form.solutionRoyalty}
                                      onChange={(e) => {
                                        let value = new Number(e.target.value)
                                        if (_.isNaN(value)) { return }
                                        if (!value) { value = 0 }
                                        const next = item
                                        _.set(next, 'env.solution.royalties.expertRoyaltyRate', value)
                                        return this.setState({ item: next })
                                      }}
                                    />
                                    <div></div>
                                  </div>
                                </>
                              )
                              : null} */}
                        </div>
                      )
                      : null}

                    

                    <ExternalContractForm {...externalContractFormProps} />
                    <Formset {...bookSalesRetailProps} />
                                        
                    <div className="buttons">
                      <button
                        type="button"
                        onClick={async (e) => {
                          return [e.stopPropagation(), e.preventDefault(), await removeItem(item)]
                        }}
                      >
                        삭제
                      </button>
                      <button
                        type="button"
                        className="primary"
                        onClick={async (e) => {
                          if (!window.confirm(`해당 폼정보로 정보를 저장할까요?`)) { return }
                          const next = _.get(this, 'state.setup')
                          next[item.name] = item
                          return  [e.stopPropagation(), e.preventDefault(), await saveSetup(next)]
                        }}
                      >
                        반영
                      </button>
                    </div>
                  </Form.Content>
                )
                : null}

            <Form.Aside>
              <Item.Group>
                {retails.map((retail, rIdx) => {
                  const meta = {}
                  meta.retailText = _.get(retail, 'text')
                  meta.shown = _.get(retail, 'shown')
                  meta.sortIdx = _.get(retail, 'sortIdx')
                  meta.isActive = item && retail.name === item.name

                  return (
                    <Item
                      key={`retail_${rIdx}`}
                      className={meta.isActive ? `active` : ``}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        const that = this
                        this.setState({ item: null }, () => {
                          return that.setState({ item: retail })
                        })
                      }}
                    >
                      <div className="meta">
                        <span style={{ marginRight: '0.5rem' }}>{meta.retailText}</span>
                        <small style={{ marginRight: '0.5rem' }}>{meta.shown ? `사용함(공개중)` : `사용안함(비공개중)`}</small>
                        <small>{meta.sortIdx ? `${meta.sortIdx}` : ``}</small>
                      </div>
                    </Item>
                  )
                })}
              </Item.Group>
            </Form.Aside>

          </Form.Grid>
        </Form>
      </Modal>
    )
  }
}

export default RetailsModalContainer