import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Doc = styled.div`
  position: relative; box-sizing: border-box;
`

const Paper = ({ ...props }) => {
  const { children } = props
  return <Doc>{children}</Doc>
}

export default Paper