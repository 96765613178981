import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  text-align: center;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 정산내역 총계를 표현하기
class SummaryColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  
  render() {
    const { item } = this.props

    const meta = {}    
    meta.eachProfit = item.eachProfit || 0
    meta.amount = item.amount || 0
    meta.total = item.total || 0
    meta.depositCount = item.fromDeposits
      ? _.get(item, 'fromDeposits.length') || 0
      : 0

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ maxWidth: '100%' }}>
            <section>
              <strong style={{ marginRight: '0.35rem', fontWeight: '700' }}>{comma(meta.total)}</strong>
              <small>원</small>
            </section>
            <header>
              <small style={{ marginRight: '0.35rem' }}>각</small>
              <strong style={{ marginRight: '0.35rem' }}>{comma(meta.eachProfit)}</strong>
              <small style={{ marginRight: '0.35rem' }}>원</small>

              <strong style={{ marginRight: '0.35rem' }}>{comma(meta.amount)}</strong>
              <small>건</small>
            </header>
          </Meta>
        </Meta.Group>
      </Column>
    )
  }
}

SummaryColumnContainer.propTypes = {
  item: PropTypes.object,
}

SummaryColumnContainer.defaultProps = {
  item: {}
}

export default SummaryColumnContainer
