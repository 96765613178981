import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import backdoor from 'services/backdoor'

import styled from 'styled-components'
import { palette } from 'styled-theme'

const { REACT_APP_CLIENT_URL } = process.env

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class BookNoColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
    this.doEditBook = this.doEditBook.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  // 프론트에서 자동으로 로그인 후에 해당 유저의 입장으로 접근할 수 있도록 처리해주기
  async doEditBook(item = {}) {
    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}
    if (!currentUser || !currentUser.id) { return alert(`해당 도서의 계정 정보가 올바르지 않습니다.`) }
    if (!window.confirm(`해당 계정(${currentUser.accountId})의 입장으로 로그인하여 도서를 수정하는 모드로 접근합니다. 진행할까요?`)) { return }
    return await backdoor.login(item.user, `${REACT_APP_CLIENT_URL}/author/make/${item.type}/${item.id}`)
  }
  
  render() {
    const { item } = this.props

    // 도서의 머리를 의미한다.
    const meta = {}
    meta.parent = _.get(item, 'parent')
    meta.bookNo = _.get(item, 'bookNo')
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <a
            href={`#editBook`}
            target="_blank"
            onClick={e => {
              return [e.stopPropagation(), e.preventDefault(), this.doEditBook(item)]
            }}
          >
            <strong style={{ fontSize: '0.9em' }}>
              {meta.bookNo || '미발급'}
            </strong>
          </a>
          <div style={{ textAlign: 'center' }}>
            <small>{meta.parent ? `🚩재등록` : `⭐신규`}</small>
          </div>
          {meta.isBrunch
            ? (
            <div>
              <small>
                <small style={{ marginRight: '0.10rem' }}><strong>브런치</strong></small>
                <small>도서</small>
              </small>
            </div>
            ) : null}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookNoColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookNoColumnContainer.defaultProps = {
  item: {}
}

export default BookNoColumnContainer
