import React from 'react'

import _ from 'lodash'
import { comma, randomString } from 'services/utils'

import Modal from './Modal'

import Caret from 'components/utils/Caret'
import Lnr from 'components/utils/Lnr'

import { Box, Generator, Button, Meta } from './utils'

/**
 *  기본형으로 옵션그룹별 옵션 한개만 사용하는 방식이 선적용됨
 */
const OptionGenerator = ({ ...props }) => {
  const { solution = {}, handleSave } = props
  const [modal, setModal] = React.useState(false)
  
  const meta = {}
  meta.category = solution.category || {}
  meta.categoryName = _.get(meta, 'category.name')
  meta.options = solution.options || []

  const actions = {}

  // 내지디자인 기본 옵션 틀 구성하기
  actions.createInitializedTextDesign = () => {
    const curAt = new Date()
    
    const papers = {
      p46: { name: 'p46', text: '46판', description: '127 * 188 mm 규격 도서인 경우의 페이지당 단가를 의미합니다.', price: 1500 },
      pa5: { name: 'pa5', text: 'A5', description: '148 * 210 mm 규격 도서인 경우의 페이지당 단가를 의미합니다.', price: 1500 },
      pb5: { name: 'pb5', text: 'B5', description: '182 * 257 mm 규격 도서인 경우의 페이지당 단가를 의미합니다.', price: 3000 },
      pa4: { name: 'pa4', text: 'A4', description: '210 * 297 mm 규격 도서인 경우의 페이지당 단가를 의미합니다.', price: 3000 },
    }

    return Object.values(papers).map((paper, pIdx) => {
      return {
        _idx: pIdx,
        id: randomString(),
        text: paper.text,
        description: paper.description,
        createdAt: curAt,
        options: [{ id: randomString(), price: paper.price, planing: 3, days: 5, createdAt: curAt }],
        primary: pIdx === 0
      }
    })
  }
  
  // 기타 상품 틀 구성해주기
  actions.createInitializedDefault = () => {
    const curAt = new Date()

    const item = {
      _idx: 0,
      id: randomString(),
      text: `기본`,
      description: `기본 옵션을 추가하였습니다. 새롭게 정의해주시거나 내용을 변경해주세요.`,
      createdAt: curAt,
      options: [{ id: randomString(), price: 3000, planing: 3, days: 5, createdAt: curAt }],
      primary: true
    }

    return [item]
  }

  // 따로 현재 컴포넌트에 옵션이 정의되어있지 않은 상태에서 고급옵션이 열렸다면 상위 모달에 자동으로
  // 옵션을 초기화해주는 기능을 실행하고 프로퍼티가 업데이트 된 이후에 다시 랜더한다.
  // 생성된 이후로는 삭제하고 싶어도, 1개 이하는 절대 삭제 되지 않게 해야 한다. (고급옵션을 상위에서 사용안함해야 해당 컴포넌트에서 비워지는 개념이 되어야하기 때문이다)
  // _idx 정렬을 위한 순차 정보저장(원래 순서를 기록하기 위함)
  const items = !meta.options.length
    ? (['textDesign'].includes(meta.categoryName)
      ? actions.createInitializedTextDesign()
      : actions.createInitializedDefault())
    : [...meta.options].map((o, oIdx) => ({ _idx: oIdx, ...o }))

  // 해당 idx 배치가 마무리되었는지 체크한다.
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    if (!loaded) {
      // 옵션가가 기본가를 건들여서 변경시켜준다.
      const primaryOption = items.find(o => o.primary)
      const next = { options: items }
      if (primaryOption) {
        next.defaultPrice = _.get(primaryOption, 'options[0].price') || solution.defaultPrice
      }
      handleSave(next) // 새롭게 배치된 정보를 상위 컴포넌트에 보고해주기
      setLoaded(true)
    }
  }, [items])

  // 신규 생성을 하는 기능을 만든다
  actions.doCreateItem = async () => {
    const curAt = new Date()

    const item = {
      _idx: items.length,
      id: randomString(),
      text: `새로운 옵션`,
      description: `새로운 옵션이 추가되었습니다. 설명 작성이 필요합니다.`,
      createdAt: curAt,
      options: [{ id: randomString(), price: 3000, planing: 3, days: 5, createdAt: curAt }],
      primary: false
    }

    const next = [...items, item]
    handleSave({ options: next })
  }

  // 수정을 하는 모달창을 의미한다.
  actions.doOpenModal = async (option = {}) => {
    return setModal({
      name: 'Modal',
      item: option,
      parentProps: props,
      onClose: () => setModal(false)
    })
  }
  
  // 옵션을 기본으로 설정하는 기능
  actions.doSetPrimaryItem = async (option = {}) => {
    if (!window.confirm(`맨위로 올라오면서 기본옵션으로 설정됩니다.`)) { return }

    const next = items.map(item => ({ ...item, primary: false }))
    next[option._idx] = { ...next[option._idx], primary: true }

    // 고급옵션에서 기본가를 변경하면 기본금액이 자동으로 변경되도록 설정한다.
    const defaultPrice = _.get(next[option._idx], 'options[0].price') || solution.defaultPrice
    
    handleSave({ defaultPrice, options: next })
  }

  // 옵션을 위로, 아래로 미는 기능
  actions.doUpdateListOrder = async (option = {}, direction, gidx) => {
    const isOne = !items.length || items.length === 1
    if (isOne) { return alert(`옵션을 비교하여 움직일 옵션 개수가 적습니다. 순서를 변경할 수 없습니다.`) }
    if (!direction || !['up', 'down'].includes(direction)) { return alert(`순서 변경 방향이 올바르지 않거나 없습니다.`) }

    const next = [...items]

    // 위로 올라가는 방향인 경우(한단계 위로 올려주는 작업)
    if (['up'].includes(direction)) {
      if (!gidx) { return alert(`이미 최상위입니다.`) }
      next.splice(gidx, 1)
      next.splice(gidx-1, 0, option)
    }

    // 아래로 내려가는 방향인경우
    if (['down'].includes(direction)) {
      if (gidx === (next.length - 1)) { return alert(`이미 제일 아래입니다.`) }
      next.splice(gidx, 1)
      next.splice(gidx + 1, 0, option)
    }

    handleSave({ options: next.map((o, oIdx) => ({ _idx: oIdx, ...o })) })
  }

  // 옵션을 삭제하는 기능
  actions.doRemoveItem = async (option = {}) => {
    const isOne = !items.length || items.length === 1
    if (isOne) { return alert(`더이상 삭제 할 수 없습니다.`) }
    if (option.primary) { return alert(`기본 옵션은 삭제 할 수 없습니다.`) }
    const oIdx = option._idx
    const next = items.filter(item => item._idx !== oIdx)
    handleSave({ options: next })
  }

  return (
    <Box>
      {modal && modal.name === 'Modal' ? <Modal {...modal} /> : null}
      
      <Box.Header>
        <Box.Lead>
          <span>고급옵션</span>
          <small style={{ marginLeft: '0.5rem' }}>0개</small>
        </Box.Lead>
        <Box.Buttons>
          <a
            href="#addOption"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              return actions.doCreateItem()
            }}
          >
            추가
          </a>
        </Box.Buttons>
      </Box.Header>
      <Box.Body>

        <Generator.Group>
          {items.map((item, gIdx) => {
            const childrenOptions = item.options || []
            const firstChildrenOption = childrenOptions[0] || {}

            const _meta = {}
            _meta.defaultOption = firstChildrenOption

            _meta.name = `${_.get(item, 'id')}.${_.get(_meta, 'defaultOption.id')}`
            _meta.price = _.get(_meta, 'defaultOption.price') || 0
            _meta.days = _.get(_meta, 'defaultOption.days') || 0
            _meta.planing = _.get(_meta, 'defaultOption.planing') ||  0

            const isActive = false

            return (
              <Generator key={gIdx}>
                <Generator.Header>          
                  <div className="caret">
                    <Caret c={item.primary ? `success` : 'muted'} style={{ width: '10px', height: '10px' }}/>
                  </div>
                  <div className="lead">
                    <strong>{comma(_meta.price)}원</strong>
                    <small style={{ marginLeft: '0.35rem' }}>
                      {item.text || '옵션명 없음'}
                    </small>
                  </div>
                </Generator.Header>
                <Generator.Options className={isActive ? 'active' : ''}>
                  <Generator.Option>
                    <div className="desc">
                      {item.description || '설명을 작성하지 않았습니다.'}
                    </div>
                    <Meta.Group>
                      <Meta>
                        <Meta.Label>
                          <Lnr c="calendar-empty" />
                          <span style={{ marginLeft: '0.35rem' }}>작업일</span>
                        </Meta.Label>
                        <Meta.Value>
                          ~ {_meta.days}일
                        </Meta.Value>
                      </Meta>
                      <Meta>
                        <Meta.Label>
                          <Lnr c="eraser" />
                          <span style={{ marginLeft: '0.35rem' }}>수정횟수</span>
                        </Meta.Label>
                        <Meta.Value>
                          ~ {_meta.planing}회
                        </Meta.Value>
                      </Meta>
                    </Meta.Group>
                  </Generator.Option>
                  <Button.Group>
                    <Button
                      type="button"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return await actions.doSetPrimaryItem(item)
                      }}
                    >
                      기본
                    </Button>
                    <Button
                      type="button"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return await actions.doOpenModal(item)
                      }}
                    >
                      관리
                    </Button>
                    <Button
                      type="button"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return await actions.doUpdateListOrder(item, 'up', gIdx)
                      }}
                    >
                      위로
                    </Button>
                    <Button
                      type="button"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return await actions.doUpdateListOrder(item, 'down', gIdx)
                      }}
                    >
                      아래
                    </Button>
                    <Button
                      type="button"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        return await actions.doRemoveItem(item)
                      }}
                    >
                      삭제
                    </Button>
                  </Button.Group>
                </Generator.Options>
              </Generator>
            )
          })}
        </Generator.Group>

      </Box.Body>
    </Box>
  )
}

export default OptionGenerator
