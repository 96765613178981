import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import DataSet from 'components/utils/DataSet'
export { DataSet }

// 박스상자 배치
export const Box = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; font-size: 12px;
  & > div.thumbnail { position: relative; box-sizing: border-box; padding: 0 0.5rem; }
  & > div { position: relative; box-sizing: border-box; padding: 0 0.5rem; }
`
