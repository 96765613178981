import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layouts/Page'
import NotFound from 'components/utils/NotFound'

class GenericPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { admin, history, location, match, nav } = this.props
    const commonProps = { admin, history, location, match }

    return (
      <Page>
        {nav === 'text' ? <div>일반 페이지급</div> : null}
        {!nav ? <NotFound {...commonProps} /> : null}
      </Page>
    )
  }
}

GenericPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,

  nav: PropTypes.string,
}

GenericPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},

  nav: null,
}

export default GenericPageContainer
