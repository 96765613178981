import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import * as crypto from 'services/crypto'

import moment from 'services/moment'

import { Snippet, Control, Description, Lnr, Caret, Checkbox, Meta, MetaSet } from './utils'

class UserRegisterNumberSnippetContainer extends React.Component {

  constructor(props) {
    super(props)

    this.form = React.createRef()
    this.ctrl = React.createRef()
    this.password = _.get(props, 'admin.__ACCD')
    this.systemPassword = _.get(props, 'admin.__SYSCD')

    console.log(props.admin)

    this.initialState = this.initialState.bind(this)

    this.doCheck = this.doCheck.bind(this)
    this.doExit = this.doExit.bind(this)
    this.doSave = this.doSave.bind(this)

    this.state = this.initialState(props)
  }

  initialState(props) {
    const state = {
      form: 'check', 
      password: '',
      item: {}
    }
    return state
  }

  async doCheck() {
    if (!this.password) { return alert(`정보확인용 ACCD 코드 값을 부여받지 못했습니다. 관리자에게 문의해주세요.`) }
    if (!this.systemPassword) { return alert(`시스템 SYSCD 코드 값을 부여받지 못했습니다. 관리자에게 문의해주세요.`) }
    if (this.state.password !== this.password && this.password) { return alert(`관리자용 비밀번호가 일치하지 않습니다.`) }
    this.setState({ form: 'modify', item: _.get(this, 'props.parent.item.registrationNumber') })
  }

  async doExit() {
    this.setState(this.initialState(this.props), () => this.props.closeSider())
  }

  async doSave() {
    const { item } = this.state
    const saveAt = new Date()
    const next = { ...item, updatedAt: saveAt, createdAt: item.createdAt || saveAt }

    this.props.onSaved(next)
    return this.props.closeSider()
  }

  render() {
    const { form, password, item } = this.state
    // console.log(item)

    return (
      <Snippet>
        {form === 'check' ? (
          <>
            <Snippet.Header>
              <Snippet.Header.Lead>관리자 확인 비밀번호</Snippet.Header.Lead>
            </Snippet.Header>
            <Snippet.Body>
              <Control ref={this.form} onSubmit={async (e) => [e.preventDefault(), this.doCheck()]}>
                <input
                  ref={this.ctrl} type="password" value={password} placeholder={`비밀번호 입력`}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </Control>
              <Description>
                <div>민감정보 관리자 확인 및 실수로 인한 스크린샷 방지를 위한 비밀번호 입력 부탁드립니다.</div>
              </Description>
            </Snippet.Body>
            <Snippet.Footer>
              <Snippet.Buttons>
                <Snippet.Button onClick={e => this.doExit()}>닫기</Snippet.Button>
                <Snippet.Button className="primary" onClick={e => this.doCheck()}>확인</Snippet.Button>
              </Snippet.Buttons>
            </Snippet.Footer>
          </>
        ) : null}
        
        {form === 'modify' ? (
          <>
            <Snippet.Header>
              <Snippet.Header.Lead>신원정보 내역</Snippet.Header.Lead>
            </Snippet.Header>
            <Snippet.Body>
              <MetaSet>
                <Meta>
                  <div>실명</div>
                  <Meta.Control>
                    <input
                      type="text"
                      value={item.name || ''}
                      placeholder={`미설정`}
                      onChange={e => {
                        const next = { ...item, name: e.target.value }
                        this.setState({ item: next })
                      }}
                    />
                  </Meta.Control>
                </Meta>
                <Meta>
                  <div>주민번호</div>
                  <Meta.Control>
                    <input
                      type="text"
                      value={crypto.dec(item.number, this.systemPassword)}
                      placeholder={`미설정`}
                      onChange={e => {
                        const next = { ...item, number: crypto.enc(e.target.value, this.systemPassword) }
                        this.setState({ item: next })
                      }}
                    />
                  </Meta.Control>
                </Meta>
                <Meta>
                  <div>상태</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const modifed = !_.get(item, 'certed')
                      const next = { ...this.state.item, certed: modifed, certedAt: modifed ? new Date() : null }
                      this.setState({ item: next })
                    }}
                  >
                    <Checkbox c={_.get(item, 'certed') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>
                      {_.get(item, 'certed') ? '완료' : '미완료'}
                    </strong>
                  </div>
                </Meta>
                <Meta>
                  <div>최초신청일</div>
                  <div>{item.createdAt ? <>{moment(item.createdAt).format('YYYY.MM.DD')} <small>{moment(item.createdAt).format('HH:mm')}</small></> : '정보없음'}</div>
                </Meta>
                <Meta>
                  <div>최종변경일</div>
                  <div>{item.updatedAt ? <>{moment(item.updatedAt).format('YYYY.MM.DD')} <small>{moment(item.updatedAt).format('HH:mm')}</small></> : '정보없음'}</div>
                </Meta>
                <Meta>
                  <div>제출완료일</div>
                  <div>{item.certedAt ? <>{moment(item.certedAt).format('YYYY.MM.DD')} <small>{moment(item.certedAt).format('HH:mm')}</small></> : '정보없음'}</div>
                </Meta>
              </MetaSet>
              <Description>
                <div>주민등록번호 정보는 암호화되어 저장됩니다.</div>
              </Description>
            </Snippet.Body>
            <Snippet.Footer>
              <Snippet.Buttons>
                <Snippet.Button onClick={e => this.doExit()}>닫기</Snippet.Button>
                <Snippet.Button className="primary" onClick={e => this.doSave()}>적용</Snippet.Button>
              </Snippet.Buttons>
            </Snippet.Footer>
          </>
        ) : null}

      </Snippet>
    )
  }
}

UserRegisterNumberSnippetContainer.propTypes = {
  count: PropTypes.number,
  fileSize: PropTypes.number,
  accept: PropTypes.string,

  onSaved: PropTypes.func,
  closeSider: PropTypes.func,
}

UserRegisterNumberSnippetContainer.defaultProps = {
  count: 1,
  fileSize: 1,
  accept: 'image/*',

  onSaved: () => {},
  closeSider: () => {}
}

export default UserRegisterNumberSnippetContainer
