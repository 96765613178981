import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import BookList from './BookList'
import SolutionList from './SolutionList'

/*
  [잊지 말아야할 절차]
  1. constructors에 추가
  2. this.navs 에 추가
  3. loadItems에 구분자값 전달 추가
  4. render에 컴포넌트 표현
  5. cloned import 컴포넌트

  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' } // 비동기
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' } // 비동기
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.approvalId = { name: 'approvalId', text: '문서ID' } // OK
*/

const constructors = {}

constructors.common = (that = {}) => {
  // 와치처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = {} }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
  
  that.sortOptions.createdAt = { name: 'createdAt', text: '생성일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 판매상태
  that.filterOptions.saleStatus = { name: 'saleStatus', text: '판매상태', path: 'saleStatus', state: 'filters.saleStatus', conditions: [], type: 'tool' }
  that.filterOptions.saleStatus.conditions.push({ name: 'on', text: '판매중' })
  that.filterOptions.saleStatus.conditions.push({ name: 'off', text: '판매중지' })

  // 진열상태
  that.filterOptions.status = { name: 'status', text: '진열상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'activated', text: '진열됨' })
  that.filterOptions.status.conditions.push({ name: 'deactivated', text: '진열숨김' })
  that.filterOptions.status.conditions.push({ name: 'temporary', text: '임시' })

  // 공통 지원 키워드 검색 옵션
  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' }
  that.keywordsOptions.userNo = { name: 'userNo', text: '회원번호' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
  that.keywordsOptions.nickName = { name: 'nickName', text: '닉네임' }
  that.keywordsOptions.name = { name: 'name', text: '실명' }
  that.keywordsOptions.email = { name: 'email', text: '이메일' }
  that.keywordsOptions.mobile = { name: 'mobile', text: '연락처' }
}

constructors.bookCommon = (that = {}) => {
  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.storageNo = { name: 'storageNo', text: '책장번호' }
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.bookNo = { name: 'bookNo', text: '도서번호' }
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' }
  that.keywordsOptions.bookTitle = { name: 'bookTitle', text: '도서명' }
  that.keywordsOptions.bookAuthor = { name: 'bookAuthor', text: '저자명' }
  that.keywordsOptions.bookIsbn = { name: 'bookIsbn', text: 'ISBN' }
}

constructors.paperBook = (that = {}) => {
  // 용도구분
  that.filterOptions.bookPurpose = { name: 'bookPurpose', text: '목적', path: 'bookPurpose', state: 'filters.bookPurpose', conditions: [], type: 'tool' }
  that.filterOptions.bookPurpose.conditions.push({ name: 'external', text: 'ISBN' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'internal', text: '일반판매용' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'collection', text: '소장용' })

  that.filterOptions.publish = { name: 'publish', text: '출판사', path: 'publish', state: 'filters.publish', item: 'publish', conditions: [], type: 'tool' }
  that.filterOptions.publish.conditions.push({ name: 'bookk', text: '부크크 계열' })
  that.filterOptions.publish.conditions.push({ name: 'others', text: '외부 출판사' })

  // 브런치
  that.filterOptions.hasBrunch = { name: 'hasBrunch', text: '등록구분', path: 'hasBrunch', state: 'filters.hasBrunch', conditions: [], type: 'tool' }
  that.filterOptions.hasBrunch.conditions.push({ name: 'used', text: '브런치' })
  that.filterOptions.hasBrunch.conditions.push({ name: 'notUsed', text: '부크크' })

  // 도서규격
  that.filterOptions.bookSize = { name: 'bookSize', text: '도서규격', path: 'bookSize', state: 'filters.bookSize', item: 'bookSize', conditions: [], type: 'tool' }
  that.filterOptions.bookSize.conditions.push({ name: 'pa4', text: 'A4' })
  that.filterOptions.bookSize.conditions.push({ name: 'pb5', text: 'B5' })
  that.filterOptions.bookSize.conditions.push({ name: 'p46', text: '46판' })
  that.filterOptions.bookSize.conditions.push({ name: 'pa5', text: 'A5' })

  // 날개여부
  that.filterOptions.bookCoverFlap = { name: 'bookCoverFlap', text: '날개여부', path: 'bookCoverFlap', state: 'filters.bookCoverFlap', conditions: [], type: 'tool' }
  that.filterOptions.bookCoverFlap.conditions.push({ name: 'exists', text: '날개있음' })
  that.filterOptions.bookCoverFlap.conditions.push({ name: 'notExists', text: '날개없음' })

  // 입점위치 : 비동기 conditions
  that.filterOptions.retails = { name: 'retails', text: '입점위치', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  // 인쇄색상
  that.filterOptions.bookTextColor = { name: 'bookTextColor', text: '인쇄색상', path: 'bookTextColor', state: 'filters.bookTextColor', conditions: [], type: 'tool' }
  that.filterOptions.bookTextColor.conditions.push({ name: 'black', text: '흑백도서' })
  that.filterOptions.bookTextColor.conditions.push({ name: 'color', text: '컬러도서' })

  // 생산방식
  that.filterOptions.produce = { name: 'produce', text: '생산방식', path: 'produce', state: 'filters.produce', conditions: [], type: 'tool' }
  that.filterOptions.produce.conditions.push({ name: 'inhouse', text: '자체생산' })
  that.filterOptions.produce.conditions.push({ name: 'outsourcing', text: '위탁생산' })

  // 구판여부
  that.filterOptions.old = { name: 'old', text: '구판여부', path: 'old', state: 'filters.old', conditions: [], type: 'tool' }
  that.filterOptions.old.conditions.push({ name: 'used', text: '구판' })
  that.filterOptions.old.conditions.push({ name: 'notUsed', text: '해당없음' })
  
  // 전연령, 성인여부
  that.filterOptions.ageLimit = { name: 'ageLimit', text: '연령제한', path: 'ageLimit', state: 'filters.ageLimit', item: 'ageLimit', conditions: [], type: 'tool' }
  that.filterOptions.ageLimit.conditions.push({ name: 'allAge', text: '전연령' })
  that.filterOptions.ageLimit.conditions.push({ name: 'adult', text: '성인' })
  
  // 재고서비스 이용현황
  that.filterOptions.stockStorage = { name: 'stockStorage', text: '재고서비스', path: 'stockStorage', state: 'filters.stockStorage', item: 'stockStorage', conditions: [], type: 'tool' }
  that.filterOptions.stockStorage.conditions.push({ name: 'activated', text: '🔥 사용중(책장번호 보유)' })
  that.filterOptions.stockStorage.conditions.push({ name: 'deactivated', text: '☔ 비사용중' })
  that.filterOptions.stockStorage.conditions.push({ name: 'expired', text: '🕐 사용중+만료' })

  constructors.bookCommon(that)
  constructors.common(that)
}

constructors.electronicBook = (that = {}) => {
  // 입점위치 : 비동기 conditions
  that.filterOptions.retails = { name: 'retails', text: '입점위치', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  // 용도구분
  that.filterOptions.bookPurpose = { name: 'bookPurpose', text: '목적', path: 'bookPurpose', state: 'filters.bookPurpose', conditions: [], type: 'tool' }
  that.filterOptions.bookPurpose.conditions.push({ name: 'external', text: 'ISBN' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'internal', text: '일반판매용' })

  that.filterOptions.publish = { name: 'publish', text: '출판사', path: 'publish', state: 'filters.publish', item: 'publish', conditions: [], type: 'tool' }
  that.filterOptions.publish.conditions.push({ name: 'bookk', text: '부크크 계열' })
  that.filterOptions.publish.conditions.push({ name: 'others', text: '외부 출판사' })

  // 확장자명
  that.filterOptions.fileType = { name: 'fileType', text: '확장자명', path: 'fileType', state: 'filters.fileType', conditions: [], type: 'tool' }
  that.filterOptions.fileType.conditions.push({ name: 'pdf', text: 'PDF' })
  that.filterOptions.fileType.conditions.push({ name: 'epub', text: 'EPUB' })
  
  // 전연령, 성인여부
  that.filterOptions.ageLimit = { name: 'ageLimit', text: '연령제한', path: 'ageLimit', state: 'filters.ageLimit', item: 'ageLimit', conditions: [], type: 'tool' }
  that.filterOptions.ageLimit.conditions.push({ name: 'allAge', text: '전연령' })
  that.filterOptions.ageLimit.conditions.push({ name: 'adult', text: '성인' })

  constructors.bookCommon(that)
  constructors.common(that)
}

constructors.book = (that = {}) => {
  // 도서구분
  that.filterOptions.bookType = { name: 'bookType', text: '도서구분', path: 'bookType', state: 'filters.bookType', conditions: [], type: 'tool' }
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  constructors.paperBook(that)
  constructors.electronicBook(that)

  // 용도구분(전자책이 후에 나오기에 오버라이팅을 한번 더해준다.)
  that.filterOptions.bookPurpose = { name: 'bookPurpose', text: '목적', path: 'bookPurpose', state: 'filters.bookPurpose', conditions: [], type: 'tool' }
  that.filterOptions.bookPurpose.conditions.push({ name: 'external', text: 'ISBN' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'internal', text: '일반판매용' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'collection', text: '소장용' })

  that.filterOptions.publish = { name: 'publish', text: '출판사', path: 'publish', state: 'filters.publish', item: 'publish', conditions: [], type: 'tool' }
  that.filterOptions.publish.conditions.push({ name: 'bookk', text: '부크크 계열' })
  that.filterOptions.publish.conditions.push({ name: 'others', text: '외부 출판사' })

  // 전연령, 성인여부
  that.filterOptions.ageLimit = { name: 'ageLimit', text: '연령제한', path: 'ageLimit', state: 'filters.ageLimit', item: 'ageLimit', conditions: [], type: 'tool' }
  that.filterOptions.ageLimit.conditions.push({ name: 'allAge', text: '전연령' })
  that.filterOptions.ageLimit.conditions.push({ name: 'adult', text: '성인' })

  constructors.bookCommon(that)
  constructors.common(that)
}

// 작가서비스 등록
constructors.solution = (that = {}) => {
  // 카테고리
  that.filterOptions.category = { name: 'category', text: '카테고리', path: 'category', state: 'filters.category', item: 'content.solution.category.name', conditions: [], type: 'tool' }
  that.filterOptions.category.conditions.push({ name: 'coverDesign', text: '표지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textDesign', text: '내지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textCorrection', text: '교정교열' })
  that.filterOptions.category.conditions.push({ name: 'etc', text: '기타' })
  
  // 판매전략
  that.filterOptions.strategy = { name: 'strategy', text: '판매전략', path: 'strategy', state: 'filters.strategy', conditions: [], type: 'tool' }
  that.filterOptions.strategy.conditions.push({ name: 'limited', text: '한정판' })
  that.filterOptions.strategy.conditions.push({ name: 'normal', text: '해당없음' })
  
  // 복합옵션
  that.filterOptions.usedOptions = { name: 'usedOptions', text: '복합옵션', path: 'usedOptions', state: 'filters.usedOptions', item: 'usedOptions', conditions: [], type: 'tool' }
  that.filterOptions.usedOptions.conditions.push({ name: 'used', text: '옵션 사용함' })
  that.filterOptions.usedOptions.conditions.push({ name: 'notUsed', text: '옵션 사용안함' })

  // 성인서비스
  that.filterOptions.ageLimit = { name: 'ageLimit', text: '연령제한', path: 'ageLimit', state: 'filters.ageLimit', item: 'ageLimit', conditions: [], type: 'tool' }
  that.filterOptions.ageLimit.conditions.push({ name: 'allAge', text: '전연령' })
  that.filterOptions.ageLimit.conditions.push({ name: 'adult', text: '성인' })

  // 썸네일
  that.filterOptions.thumbnailFile = { name: 'thumbnailFile', text: '썸네일', path: 'thumbnailFile', state: 'filters.thumbnailFile', item: 'thumbnailFile', conditions: [], type: 'tool' }
  that.filterOptions.thumbnailFile.conditions.push({ name: 'uploaded', text: '썸네일 파일있음' })
  that.filterOptions.thumbnailFile.conditions.push({ name: 'notUploaded', text: '썸네일 파일없음' })

  // 포트폴리오
  that.filterOptions.portfolioFiles = { name: 'portfolioFiles', text: '포트폴리오', path: 'portfolioFiles', state: 'filters.portfolioFiles', item: 'portfolioFiles', conditions: [], type: 'tool' }
  that.filterOptions.portfolioFiles.conditions.push({ name: 'uploaded', text: '포트폴리오 파일있음' })
  that.filterOptions.portfolioFiles.conditions.push({ name: 'notUploaded', text: '포트폴리오 파일없음' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.solutionNo = { name: 'solutionNo', text: '작가서비스번호' }
  that.keywordsOptions.solutionId = { name: 'solutionId', text: '작가서비스ID' }
  that.keywordsOptions.solutionName = { name: 'solutionName', text: '작가서비스명' }
  that.keywordsOptions.solutionSellerName = { name: 'solutionSellerName', text: '판매자명' }

  constructors.common(that)
}

class ProductListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['book', 'paperBook', 'electronicBook', 'solution']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.getQuery = this.getQuery.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadResource = this.loadResource.bind(this)
    this.prepared = this.prepared.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, resource: {}, items: [], total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  // 리소스 불러오기 @ 2023.01.30 최종판
  async loadResource(name, method) {
    const that = this
    const { resource = {} } = this.state

    // 리소스를 가져오면 필터의 컨디션에 세팅을 해준다.
    resource[name] = await api.get(`/resources/admin2/${method}`).catch(e => ({}))

    await new Promise(r => that.setState({ resource }, r))
  }

  getQuery() {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

   const queries = {} 
   Object.keys(filters)
    .forEach((key) => {
      const name = `filters_${key}`
      queries[name] = filters[key]
    })
   if (sorts && sorts.length) { queries.sorts = sorts.join(',') }
   if (keywordsOption) { queries.keywordsOption = keywordsOption }
   if (keywords) { queries.keywords = keywords }
   if (page) { queries.page = page }
   if (limit) { queries.limit = limit }
   if (startAt) { queries.startAt = startAt }
   if (endAt) { queries.endAt = endAt }

   return qs.stringify(queries)
  }

  async initialize(options = { reload: false }) {
    if (options.reload && window) {
      const query = this.getQuery()
      window.location.href = `${window.location.pathname}?${query}`
      return
    }

    await new Promise((r) => this.setState({ loading: true, selecteds: [] }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) {
      return this.setState({ loading: false, error: true })
    }
    await this.loadResource('retail', 'takeRetails') // 유통사 정보 리소스 불러오기
    await this.prepared()
    await this.loadItems()
  }

  // 필요한 리소스 또는 컴포넌트 사용준비를 하는 구간
  async prepared() {
    const that = this
    const { resource = {} } = this.state
    
    // 외부유통 활동 유저를 찾기 위한 구분자
    if (resource.retail && _.get(that, 'filterOptions.retails')) {
      const conditions = Object.values(resource.retail)
        .map((retail) => ({ name: `${retail.name}`, text: `${retail.text}` }))
      _.set(that, 'filterOptions.retails.conditions', conditions)
    }
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    // query.startAt = startAt
    // query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')

    query.flags = ['currentUser']
    
    // 종이도서, 전자도서 공통인자
    if (this.props.nav === 'book') {
      query.bookType = filters.bookType
      query.bookPurpose = filters.bookPurpose
      query.bookSize = filters.bookSize
      query.retails = filters.retails
      query.fileType = filters.fileType
      query.hasBrunch = filters.hasBrunch
      query.bookTextColor = filters.bookTextColor
      query.bookCoverFlap = filters.bookCoverFlap
      query.produce = filters.produce
      query.old = filters.old
      query.ageLimit = filters.ageLimit
      query.publish = filters.publish
      query.stockStorage = filters.stockStorage
      
      query.flags.push('currentStock', 'activatedStockStorage') // @ 재고서비스 가져오기
    }

    if (this.props.nav === 'paperBook') {
      query.stockStorage = filters.stockStorage
      query.bookPurpose = filters.bookPurpose
      query.retails = filters.retails
      query.bookSize = filters.bookSize
      query.bookTextColor = filters.bookTextColor
      query.bookCoverFlap = filters.bookCoverFlap
      query.hasBrunch = filters.hasBrunch
      query.produce = filters.produce
      query.old = filters.old
      query.ageLimit = filters.ageLimit
      query.publish = filters.publish

      query.flags.push('currentStock', 'activatedStockStorage') // @ 재고서비스 가져오기
    }

    if (this.props.nav === 'electronicBook') {
      query.retails = filters.retails
      query.bookPurpose = filters.bookPurpose
      query.fileType = filters.fileType
      query.ageLimit = filters.ageLimit
      query.publish = filters.publish
    }

    // 작가서비스 최초 입점 심사
    if (this.props.nav === 'solution') {
      query.category = filters.category
      query.strategy = filters.strategy
      query.usedOptions = filters.usedOptions
      query.old = filters.old
      query.ageLimit = filters.ageLimit
    }

    // 공통인자
    query.status = filters.status
    query.saleStatus = filters.saleStatus
    query.flags = query.flags.join(',')
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const output = await api.get(`/products/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return null }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, resource, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, resource, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    if (nav ==='book') { return <BookList {...listProps} /> }
    if (nav ==='paperBook') { return <BookList type="paperBook" {...listProps} /> }
    if (nav ==='electronicBook') { return <BookList type="electronicBook" {...listProps} /> }
    if (nav ==='solution') { return <SolutionList {...listProps} /> }

    return null
  }
}

ProductListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

ProductListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '상품관리',
  nav: 'book',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-10, 'years').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(ProductListContainer))
