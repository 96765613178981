import mongoose from 'mongoose'
import xbytes from 'xbytes'

export const comma = x => !x ? '0' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const kb = (bytes, usedComma) => { return usedComma ? comma(Math.round(bytes / 1024)) : Math.round(bytes / 1024) }

export const fileSize = (bytes) => { return bytes ? xbytes(bytes) : `0 Bytes` }

export const camelCase = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase())

export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)

export const randomString = (length = 4) => {
  const string = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length)

  return string.length >= length ? string : string + randomString(length - string.length)
}

export function unit(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + (sizes[i] ? sizes[i] : sizes[0])
}

// 몽고디비의 Object 아이디값이 맞는지 체크한다.
export const isId = (s) => {
  try {
    const objectID = new mongoose.Types.ObjectId(s)
    return objectID.toString() === s
  } catch (e){
    return false
  } 
}

// A, AB, AC ~ 문자열을 간단히 숫자로 변환해주는 기능
export const lettersToNumber = (letters) => {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
}

// @ 윈도우 파일명에 허용되지 않는 문자들을 정규식으로 필터링
export const filterFileName = (fileName) => {
  const invalidChars = /[<>:"\/\\|?*\x00-\x1F]/g
  const filteredName = fileName.replace(invalidChars, '')
  return filteredName
}