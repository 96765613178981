import React from 'react'
import PropTypes from 'prop-types'
import { Pages, Page } from './utils.js'

const Pager = ({
  total, page: current, limit, blockLimit, setPage,
}) => {
  if (typeof total !== 'number' || total <= 0) { return null }
  if (typeof limit !== 'number' || limit <= 0) { return null }
  if (typeof blockLimit !== 'number' || blockLimit <= 0) { return null }

  const totalPageCount = total && limit ? parseInt(total / limit) + (total % limit > 0 ? 1 : 0) : 0
  const currentPage = typeof current === 'number' && current > 0 ? (current > totalPageCount ? totalPageCount : current) : 1

  const totalBlock = parseInt(totalPageCount / blockLimit) + (totalPageCount % blockLimit ? 1 : 0)
  const currentBlock = parseInt(currentPage / blockLimit) + (currentPage % blockLimit ? 1 : 0)

  const startSequnce = (currentBlock - 1) * blockLimit + 1
  const endSequnce = totalBlock === currentBlock ? totalPageCount : currentBlock * blockLimit

  const prevPage = startSequnce - 1 > 0 ? startSequnce - 1 : 1
  const nextPage = endSequnce + 1 <= totalPageCount ? endSequnce + 1 : totalPageCount

  const pages = []
  for (let i = startSequnce; i <= endSequnce; i++) { pages.push(i) }

  if (nextPage === prevPage) {
    return null
  }

  return (
    <Pages>
      {currentBlock !== 1 ? (
        <Page
          href={`#${prevPage}`}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            return setPage(prevPage)
          }}
        >
          &lt;
        </Page>
      ) : null}
      {pages.map((page, index) =>
        <Page
          key={page}
          href={`#${page}`}
          className={currentPage === page ? 'active' : null}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            return setPage(page)
          }}
        >
          {page}
        </Page>
      )}
      {currentBlock !== totalBlock ? (
        <Page
          href={`#${nextPage}`}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            return setPage(nextPage)
          }}
        >
          &gt;
        </Page>
      ) : null}
    </Pages>
  )
}

Pager.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  setPage: PropTypes.func,
}

Pager.defaultProps = {
  total: 0,
  page: 0,
  limit: 0,
  blockLimit: 0,
  setPage: (page = 1, cb = () => { }) => { },
}

export default Pager
