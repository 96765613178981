import React from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { comma } from 'services/utils'

import { Header, Lnr, Caret, Checkbox, PanelSet, MetaSet, Meta } from './utils'

class PanelsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)

    this.loadProductCount = this.loadProductCount.bind(this)
    this.loadOrderCount = this.loadOrderCount.bind(this)
    this.loadAccumulatedProfitAmount = this.loadAccumulatedProfitAmount.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  initialState(props) {
    const state = {
      error: false, loading: true,
      counts: {},
      amounts: {}
    }
    return state
  }

  async initialize() {

    // 보유 콘텐츠, 구매자 역할 관련, 판매자 역할 관련
    const counts = {}
    counts.paperBookProduct = await this.loadProductCount({ productType: 'paperBook' })
    counts.electronicBookProduct = await this.loadProductCount({ productType: 'electronicBook' })
    counts.solutionProduct = await this.loadProductCount({ productType: 'solution' })

    counts.buyerPaperBookOrder = await this.loadOrderCount({ orderType: 'paperBook', role: 'buyer' })
    counts.buyerElectronicBookOrder = await this.loadOrderCount({ orderType: 'electronicBook', role: 'buyer' })
    counts.buyerBookOrder = counts.buyerPaperBookOrder + counts.buyerElectronicBookOrder
    counts.buyerSolutionOrder = await this.loadOrderCount({ orderType: 'solution', role: 'buyer' })

    counts.sellerPaperBookOrder = await this.loadOrderCount({ orderType: 'paperBook', role: 'seller' })
    counts.sellerElectronicBookOrder = await this.loadOrderCount({ orderType: 'electronicBook', role: 'seller' })
    counts.sellerBookOrder = counts.sellerPaperBookOrder + counts.sellerElectronicBookOrder
    counts.sellerSolutionOrder = await this.loadOrderCount({ orderType: 'solution', role: 'seller' })

    // 수익금 관련 (누적 수익금, 지급 예정액, 누적 수익금, 미지급금)
    const amounts = {}
    amounts.depositProfitAmount = await this.loadAccumulatedProfitAmount({ type: 'deposit', status: ['pending', 'resolved'], deleted: { '$ne': true } }) // 누적 수익금
    amounts.withdrawResolvedProfitAmount = await this.loadAccumulatedProfitAmount({ type: 'withdraw', status: ['resolved'], deleted: { '$ne': true } }) // 누적 지급액
    amounts.remainedProfitAmount = amounts.depositProfitAmount - amounts.withdrawResolvedProfitAmount

    this.setState({ error: false, loading: false, counts, amounts })
  }

  // 보유한 종이도서, 전자도서, 작가서비스 개수
  async loadProductCount(options = {}) {
    const { parent } = this.props
    const query = {}
    if (options.productType) { query.productType = options.productType }
    if (options.startAt) { query.startAt = options.startAt }
    if (options.endAt) { query.endAt = options.endAt }
    return api.get(`/users/admin/${parent.item.id}/products/count?${qs.stringify(query, { arrayFormat: ',' })}`)
      .then(({ count }) => count)
      .catch(e => 0)
  }

  // 판매(도서, 서비스), 구매(도서, 서비스)
  async loadOrderCount(options = {}) {
    const { parent } = this.props
    const query = {}
    if (options.role) { query.role = options.role } // 구매자 입장에서의 주문, 판매자 입장에서의 주문
    if (options.orderType) { query.type = options.orderType }
    if (options.startAt) { query.startAt = options.startAt }
    if (options.endAt) { query.endAt = options.endAt }
    return api.get(`/users/admin/${parent.item.id}/orders/count?${qs.stringify(query, { arrayFormat: ',' })}`)
      .then(({ count }) => count)
      .catch(e => 0)
  }

  // 수익액 합산(당월, 누적), 정산액 합산(당월, 누적)
  async loadAccumulatedProfitAmount(options = {}) {
    const { parent } = this.props
    const query = {}
    if (options.type) { query.type = options.type }
    if (options.profitType) { query.profitType = options.profitType }
    if (options.status) { query.status = options.status }
    if (options.startAt) { query.startAt = options.startAt }
    if (options.endAt) { query.endAt = options.endAt }
    return api.get(`/users/admin/${parent.item.id}/profits/accumulated?${qs.stringify(query, { arrayFormat: ',' })}`)
      .then(({ amount }) => amount)
      .catch(e => 0)
  }

  render() {
    const { error, loading, counts, amounts } = this.state
    if (loading) { return null }
    
    const { parent } = this.props
    const item = parent.item || {}

    return (
      <>
        <PanelSet>
          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>메모</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <Meta.Control style={{ minWidth: '100%' }}>
                    <textarea
                      value={item.memo || ''} placeholder={`미설정`} style={{ height: '240px', textAlign: 'left', color: 'red' }}
                      onChange={e => parent.handle({ 'item.memo': e.target.value })}
                    />
                  </Meta.Control>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>
        </PanelSet>
        <PanelSet>
          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>인증</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <div>상태</div>
                  <Meta.Control>
                    <select
                      value={item.status}
                      onChange={e => parent.handle({ 'item.status': e.target.value })}
                      onBlur={() => parent.handleSave()}
                      >
                      <option value="temporary">임시</option>
                      <option value="normal">정상</option>
                      <option value="blocked">차단</option>
                      <option value="leaved">탈퇴</option>
                    </select>
                  </Meta.Control>
                </Meta>
                <Meta>
                  <div>성인여부</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.isAdult': !_.get(item, 'isAdult') }
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'isAdult') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'isAdult') ? '성인' : '미확인'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>이메일</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.activatedToEmail': !_.get(item, 'activatedToEmail') }
                      next['item.activatedToEmailAt'] = next['item.activatedToEmail'] ? new Date : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'activatedToEmail') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'activatedToEmail') ? '인증' : '미인증'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>휴대폰인증</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.activatedToMobile': !_.get(item, 'activatedToMobile') }
                      next['item.activatedToMobileAt'] = next['item.activatedToMobile'] ? new Date : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'activatedToMobile') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'activatedToMobile') ? '인증' : '미인증'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>주민번호인증</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.activatedToIdentity': !_.get(item, 'activatedToIdentity') }
                      next['item.activatedToIdentityAt'] = next['item.activatedToIdentity'] ? new Date : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'activatedToIdentity') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'activatedToIdentity') ? '인증' : '미인증'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>계좌인증</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.activatedToBankAccount': !_.get(item, 'activatedToBankAccount') }
                      next['item.activatedToBankAccountAt'] = next['item.activatedToBankAccount'] ? new Date : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'activatedToBankAccount') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'activatedToBankAccount') ? '인증' : '미인증'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>사업자인증</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.activatedToBusiness': !_.get(item, 'activatedToBusiness') }
                      next['item.activatedToBusinessAt'] = next['item.activatedToBusiness'] ? new Date : null
                      // 사업자 인증일, 업데이트일자 등을 변경해줘야한다.
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'activatedToBusiness') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'activatedToBusiness') ? '인증' : '미인증'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>대리인(~14세)</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.childrenAgreement': !_.get(item, 'childrenAgreement') }
                      next['item.childrenAgreementAt'] = next['item.childrenAgreement'] ? new Date() : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'childrenAgreement') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'childrenAgreement') ? '완료' : '미대상'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>문자수신</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.allowSms': !_.get(item, 'allowSms') }
                      next['item.allowSmsAt'] = next['item.allowSms'] ? new Date() : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'allowSms') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'allowSms') ? '수신' : '거부'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>레터수신</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.allowLetter': !_.get(item, 'allowLetter') }
                      next['item.allowLetterAt'] = next['item.allowLetter'] ? new Date() : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={_.get(item, 'allowLetter') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'allowLetter') ? '수신' : '거부'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>레터동의창</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = { 'item.askLetter': !_.get(item, 'askLetter') }
                      next['item.askLetterAt'] = next['item.askLetter'] ? new Date() : null
                      return parent.handle(next, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={!_.get(item, 'askLetter') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{_.get(item, 'askLetter') ? '비활성' : '활성'}</strong>
                  </div>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>

          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>수익·정산</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <div>4대인증</div>
                  <div className="pointer">
                    {(() => {
                      const enabledWithdraw = (
                        _.get(item, 'activatedToEmail') && _.get(item, 'activatedToEmail')
                        && _.get(item, 'activatedToIdentity') && _.get(item, 'activatedToMobile')
                      ) 
                      return (
                        <>
                          <Caret c={enabledWithdraw ? 'success' : 'danger'} />
                          <strong>{enabledWithdraw ? '인증완료' : '불가'}</strong>
                        </>
                      )
                    })()}
                  </div>
                </Meta>

                <Meta>
                  <div>누적 수익금</div>
                  <div
                    className="pointer"
                    onClick={(e) => {
                      const queries = { keywordsOption: 'accountId', keywords: item.accountId }
                      const url = `/profits/deposits?${qs.stringify(queries)}`
                      return window.open(url)
                    }}
                  >
                    <strong style={{ fontWeight: 900 }}>{comma(amounts.depositProfitAmount)} 원</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>지급 완료액</div>
                  <div
                    className="pointer"
                    onClick={(e) => {
                      const queries = { filters_status: 'resolved', keywordsOption: 'accountId', keywords: item.accountId }
                      const url = `/profits/withdraws?${qs.stringify(queries)}`
                      return window.open(url)
                    }}
                  >
                    <strong style={{ fontWeight: 900 }}>{comma(amounts.withdrawResolvedProfitAmount)} 원</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>수익잔액<br/><small>지급대기 제외</small></div>
                  <div>
                    <strong style={{ fontWeight: 900 }}>{comma(amounts.remainedProfitAmount)} 원</strong>
                  </div>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>

          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>그룹·등급</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              {(() => {
                
                const keys = [{ group: 'customer', text: '구매자' }, { group: 'author', text: '작가' }, { group: 'expert', text: '전문가' }]
                const joineds = keys.map(key => ({ platform: 'bookk', group: key.group, text: key.text, grade: 'none', score: 0, createdAt: new Date() }))

                const prev = _.get(item, 'joined') || []
                const current = joineds
                  .map((joined, index) => {
                    const exists = prev.find(p => p && p.platform === joined.platform && p.group === joined.group)
                    const existsIdx = prev.findIndex(p => p && p.platform === joined.platform && p.group === joined.group)
                    return exists ? { ...joined, ...exists, index: existsIdx } : { ...joined, _idx: prev.length + index }
                  })

                return (
                  <MetaSet>
                    {current.map((joined, index) => {
                      return (
                        <div key={`joined_${index}`}>
                          <Meta>
                            <div>
                              <strong style={{ fontWeight: 900 }}>{joined.text}</strong>
                              <small>그룹</small>
                            </div>
                            <Meta.Control>
                              <select
                                value={_.get(item, `joined[${index}].grade`) || ''}
                                onChange={e => {
                                  const next = {}
                                  next[`item.joined[${index}].platform`] = 'bookk'
                                  next[`item.joined[${index}].group`] = joined.group || keys[index].group || 'etc'
                                  next[`item.joined[${index}].text`] = joined.text || keys[index].text || '기타그룹'
                                  next[`item.joined[${index}].grade`] = e.target.value
                                  return parent.handle(next)
                                }}
                                >
                                <option value="none">미지정</option>
                                <option value="normal">일반</option>
                                <option value="vip">VIP</option>
                              </select>
                            </Meta.Control>
                          </Meta>

                          {_.get(item, `joined[${index}].grade`) && _.get(item, `joined[${index}].grade`) !== 'none' ? (
                            <Meta>
                              <div></div>
                              <Meta.Control>
                                <input
                                  type="text"
                                  value={_.get(item, `joined[${index}].score`) || 0} placeholder={`미설정`}
                                  onChange={e => {
                                    const next = {}
                                    next[`item.joined[${index}].score`] = e.target.value
                                    parent.handle(next)
                                  }}
                                />
                              </Meta.Control>
                            </Meta>
                          ) : null}
                        </div>
                      )
                    })}
                  </MetaSet>
                )
              })()}
            </PanelSet.Body>
          </PanelSet.Panel>

        </PanelSet>
        <PanelSet>
          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>콘텐츠</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <div>종이도서</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.paperBookProduct)} 종</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>전자도서</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/produacts`)
                    }}
                  >
                    <strong>{comma(counts.electronicBookProduct)} 종</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>작가서비스</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.solutionProduct)} 개</strong>
                  </div>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>
          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>활동</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <div>도서구매</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.buyerBookOrder)} 건</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>서비스구매</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.buyerSolutionOrder)} 건</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>도서판매</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.sellerBookOrder)} 건</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>서비스판매</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const query = {}
                      return window.open(`/products`)
                    }}
                  >
                    <strong>{comma(counts.sellerSolutionOrder)} 건</strong>
                  </div>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>
          <PanelSet.Panel>
            <PanelSet.Header>
              <strong>이용제한</strong>
            </PanelSet.Header>
            <PanelSet.Body>
              <MetaSet>
                <Meta>
                  <div>장바구니</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('cart')
                        ? next.filter(o => !['cart'].includes(o))
                        : [...next, 'cart']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('cart') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('cart') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>결제</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('pay')
                        ? next.filter(o => !['pay'].includes(o))
                        : [...next, 'pay']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('pay') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('pay') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>서점</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('bookStore')
                        ? next.filter(o => !['bookStore'].includes(o))
                        : [...next, 'bookStore']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('bookStore') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('bookStore') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>작가서비스샵</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('solutionStore')
                        ? next.filter(o => !['solutionStore'].includes(o))
                        : [...next, 'solutionStore']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('solutionStore') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('solutionStore') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>전문가공간</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('expertActivity')
                        ? next.filter(o => !['expertActivity'].includes(o))
                        : [...next, 'expertActivity']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('expertActivity') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('expertActivity') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>책만들기</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('authorActivity')
                        ? next.filter(o => !['authorActivity'].includes(o))
                        : [...next, 'authorActivity']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('authorActivity') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('authorActivity') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>커뮤니티</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('community')
                        ? next.filter(o => !['community'].includes(o))
                        : [...next, 'community']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('community') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('community') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>수익창출</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('profit')
                        ? next.filter(o => !['profit'].includes(o))
                        : [...next, 'profit']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('profit') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('profit') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
                <Meta>
                  <div>계좌등록</div>
                  <div
                    className="pointer"
                    onClick={e => {
                      const next = item.blockedServices || []
                      item.blockedServices = next.includes('bankAccount')
                        ? next.filter(o => !['bankAccount'].includes(o))
                        : [...next, 'bankAccount']
                      return parent.handle({ item }, () => parent.handleSave())
                    }}
                  >
                    <Checkbox c={(_.get(item, 'blockedServices') || []).includes('bankAccount') ? 'on' : 'off'} />
                    <strong style={{ marginLeft: '0.35rem' }}>{(_.get(item, 'blockedServices') || []).includes('bankAccount') ? '제한중' : '이용가능'}</strong>
                  </div>
                </Meta>
              </MetaSet>
            </PanelSet.Body>
          </PanelSet.Panel>
        </PanelSet>
      </>
    )
  }
}

PanelsContainer.propTypes = {

}

PanelsContainer.defaultProps = {

}

export default PanelsContainer
