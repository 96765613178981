import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Formset = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > form {
    position: relative; box-sizing: border-box;
    & > input {
      border: 0; display: block; max-width: 100%; width: 100%; box-sizing: border-box;
      background: ${palette('muted', 18)}; outline: none;
      padding: 0.35rem 0.5rem; transition: all 0.3s;
      font-size: 1em; font-family: ${font('primary')}; color: ${palette('muted', 5)};
      border-radius: 0.35rem; margin: 0.25rem 0;
      &:hover { background: ${palette('muted', 16)}; font-weight: 500; color: ${palette('muted', 2)}; }
      &:focus { background: ${palette('muted', 12)}; font-weight: 500; color: ${palette('muted', 1)}; }
    }
  }
`

Formset.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 도서의 외부유통 신청정보를 기반으로 함
// 과거에 제출했던 정보를 기반으로 하는 문서임
// 표현될 기획 내용 : 유통사명, 반영될 리테일 수익액
class BookIsbnFormColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)

    this.state = { form: { code: '' } }

    // 신방식인 retailIsbns를 사용하는 경우 (최종적으로 신방식을 적용해준다.)
    const initRetailIsbns = _.get(props, 'item.extras.currentBook.retailIsbns') || []
    if (initRetailIsbns && initRetailIsbns.length) {
      const retailIsbn = initRetailIsbns.find(retailIsbn => retailIsbn.name === 'kyobo' && _.get(retailIsbn, 'isbn')) || {}
      let kyoboIsbn = retailIsbn.isbn || ``
      if (kyoboIsbn) { kyoboIsbn = kyoboIsbn.replace(/[^0-9]/g, '') }
      if (kyoboIsbn && kyoboIsbn.length > 0) { this.state.form.code = kyoboIsbn }
    }
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    // this.setState({ datas: [] })
  }

  render() {
    const that = this
    const { item } = this.props
    const { form } = this.state

    return (
      <Column>
        <Formset.Group>
          <Formset style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
            <header>
              <small>교보문고 ISBN</small>
            </header>
            <form>
              <input
                placeholder={`13자리의 이상의 ISBN 번호`}
                value={form.code || ''}
                onChange={e => {
                  const value = e.target.value
                    ? `${e.target.value}`.replace(/[^0-9]/g, '')
                    : ''
                  const next = {
                    ...form,
                    code: value
                  }
                  that.setState({ form: next })
                }}
                onBlur={e => {
                  const value = e.target.value
                    ? `${e.target.value}`.replace(/[^0-9]/g, '')
                    : ''
                  const next = {
                    ...form,
                    code: value
                  }
                  that.setState({ form: next }, () => that.props.getForm(next))
                }}
              />
            </form>
          </Formset>
        </Formset.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookIsbnFormColumnContainer.propTypes = {
  item: PropTypes.object,
  getForm: PropTypes.func
}

BookIsbnFormColumnContainer.defaultProps = {
  item: null,
  getForm: () => {},
}

export default BookIsbnFormColumnContainer
