import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import { retailsKeys } from 'services/constants/retail'
import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import BookFileChangeList from './BookFileChangeList'
import BookModifyList from './BookModifyList'
import RefundList from './RefundList'
import PayReviewList from './PayReviewList'
import BrunchEventList from './BrunchEventList'
import PaperSampleList from './PaperSampleList'
import ReportList from './ReportList'

/*
  [잊지 말아야할 절차]
  1. constructors에 추가
  2. this.navs 에 추가
  3. loadItems에 구분자값 전달 추가
  4. render에 컴포넌트 표현
  5. cloned import 컴포넌트

  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' } // 비동기
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' } // 비동기
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
*/

const constructors = {}

constructors.common = (that = {}) => {
  that.keywordsOptions.approvalId = { name: 'approvalId', text: '문서ID' }
  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' }
  that.keywordsOptions.userNo = { name: 'userNo', text: '회원번호' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
  that.keywordsOptions.nickName = { name: 'nickName', text: '닉네임' }
  that.keywordsOptions.name = { name: 'name', text: '실명' }
  that.keywordsOptions.email = { name: 'email', text: '이메일' }
  that.keywordsOptions.mobile = { name: 'mobile', text: '연락처' }
}

// 도서 공통구간
constructors.bookCommon=  (that = {}) => {
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.bookNo = { name: 'bookNo', text: '도서번호' }
  that.keywordsOptions.bookTitle = { name: 'bookTitle', text: '도서명' }
  that.keywordsOptions.bookAuthor = { name: 'bookAuthor', text: '저자명' }
  that.keywordsOptions.bookIsbn = { name: 'bookIsbn', text: 'ISBN' }
}

// 작가서비스 공통구간
constructors.solutionCommon=  (that = {}) => {
  that.keywordsOptions.solutionNo = { name: 'solutionNo', text: '작가서비스번호' } // OK, 비동기
  that.keywordsOptions.solutionName = { name: 'solutionName', text: '작가서비스명' } // OK, 비동기
}

// 원고수정 관리에 필요한 기본 세팅 설정
constructors.bookFileChange = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'review', workStatus: 'uploadedFiles' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 필터 셋팅
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'custom' } // tool
  that.filterOptions.workStatus = { name: 'workStatus', text: '파일교체작업 여부', path: 'workStatus', state: 'filters.workStatus', item: 'workStatus', conditions: [], type: 'custom' } // tool

  // 필터 셋팅
  that.filterOptions.bookType = { name: 'bookType', text: '도서종류', path: 'bookType', state: 'filters.bookType', item: 'bookType', conditions: [], type: 'tool' } // tool
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })
  
  // 판매상태
  that.filterOptions.bookPurpose = { name: 'bookPurpose', text: '용도', path: 'bookPurpose', state: 'filters.bookPurpose', conditions: [], type: 'tool' }
  that.filterOptions.bookPurpose.conditions.push({ name: 'external', text: 'ISBN 출판 유통판매용' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'internal', text: '일반판매용' })
  that.filterOptions.bookPurpose.conditions.push({ name: 'collection', text: '소장용' })

  // 필터 셋팅
  that.filterOptions.hasNoticeEmail = { name: 'hasNoticeEmail', text: '메일요청', path: 'hasNoticeEmail', state: 'filters.hasNoticeEmail', item: 'hasNoticeEmail', conditions: [], type: 'tool' } // tool
  that.filterOptions.hasNoticeEmail.conditions.push({ name: 'true', text: '메일보냄' })
  that.filterOptions.hasNoticeEmail.conditions.push({ name: 'false', text: '해당없음' })

  // 필터 셋팅
  that.filterOptions.workAlias = { name: 'workAlias', text: '외부유통 업무별 신청서 불러오기', path: 'workAlias', state: 'filters.workAlias', item: 'workAlias', conditions: [], type: 'tool' } // tool
  Object.keys(retailsKeys).forEach((key) => {
    const rName = retailsKeys[key]
    that.filterOptions.workAlias.conditions.push({ name: `${key}Cover`, text: `${rName} 표지 변경 통지 대상` })
  })
  that.filterOptions.workAlias.conditions.push({ name: 'kyoboText', text: '교보 원고 변경 통지 대상' })
  that.filterOptions.workAlias.conditions.push({ name: 'kyoboCoverWithIsbnAndBookRetail', text: '교보 (표지 또는 내지)+교보ISBN+외부유통 신청중 통지 대상' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.payNo = { name: 'payNo', text: '주문번호' }
  that.keywordsOptions.payId = { name: 'payId', text: '결제ID' }

  constructors.bookCommon(that)
  constructors.common(that)
}

// 도서정보 관리에 필요한 정보 추가
constructors.bookModify = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait', retails: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 필터 셋팅
  that.filterOptions.bookType = { name: 'bookType', text: '도서종류', path: 'bookType', state: 'filters.bookType', item: 'bookType', conditions: [], type: 'tool' } // tool
  that.filterOptions.bookType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.bookType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  // 외부유통
  that.filterOptions.retails = { name: 'retails', text: '외부유통', path: 'retails', state: 'filters.retails', item: 'retails', conditions: [], type: 'tool' } // tool

  // 처리단계
  that.filterOptions.status = { name: 'status', text: '처리단계', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' } // tool
  that.filterOptions.status.conditions.push({ name: 'wait', text: '요청됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '처리중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '처리완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.bookCommon(that)
  constructors.common(that)
}

// 수기환불 관리에 필요한 정보 추가
constructors.refund = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait', payType: 'all', payMethod: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 상품유형
  that.filterOptions.payType = { name: 'payType', text: '상품유형', path: 'payType', state: 'filters.payType', item: 'content.payType', conditions: [], type: 'tool' } // tool
  that.filterOptions.payType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.payType.conditions.push({ name: 'electronicBook', text: '전자도서' })
  that.filterOptions.payType.conditions.push({ name: 'solution', text: '작가서비스' })
  that.filterOptions.payType.conditions.push({ name: 'etc', text: '기타' })

  // 환불단계
  that.filterOptions.status = { name: 'status', text: '환불단계', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' } // tool
  that.filterOptions.status.conditions.push({ name: 'wait', text: '환불요청' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '환불완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '환불취소' })

  // 결제방법
  that.filterOptions.payMethod = { name: 'payMethod', text: '결제방법', path: 'payMethod', state: 'filters.payMethod', item: 'content.payMethod', conditions: [], type: 'tool' } // tool
  that.filterOptions.payMethod.conditions.push({ name: 'bank', text: '무통장입금' })
  that.filterOptions.payMethod.conditions.push({ name: 'card', text: '신용카드' })
  that.filterOptions.payMethod.conditions.push({ name: 'card_rebill', text: '정기결제' })
  that.filterOptions.payMethod.conditions.push({ name: 'card_rebill_rest', text: '정기결제 (REST API)' })
  that.filterOptions.payMethod.conditions.push({ name: 'vbank', text: '가상계좌' })
  that.filterOptions.payMethod.conditions.push({ name: 'kakao', text: '카카오페이 간편결제' })
  that.filterOptions.payMethod.conditions.push({ name: 'payco', text: '페이코' })
  that.filterOptions.payMethod.conditions.push({ name: 'npay', text: '네이버페이 간편결제' })
  that.filterOptions.payMethod.conditions.push({ name: 'etc', text: '무료' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }

  that.keywordsOptions.payNo = { name: 'payNo', text: '주문번호' }
  that.keywordsOptions.payId = { name: 'payId', text: '결제ID' }

  constructors.common(that)
}

// 수기환불 관리에 필요한 정보 추가
constructors.payReview = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
  
  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 필터 셋팅
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' } // tool
  that.filterOptions.status.conditions.push({ name: 'wait', text: '참여완료' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '확인중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '인증탈락' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '인증완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.common(that)
}

// 수기환불 관리에 필요한 정보 추가
constructors.brunchEvent = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait', round: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })
  
  // 회차정보
  that.filterOptions.round = { name: 'round', text: '이벤트 진행회차', path: 'round', state: 'filters.round', item: 'round', conditions: [], type: 'tool' }

  // 참여상태
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'wait', text: '참여완료' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '확인중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '선정탈락' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '선정완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })
  
  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.common(that)
}

// 수기환불 관리에 필요한 정보 추가
constructors.paperSample = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 필터 셋팅
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' } // tool
  that.filterOptions.status.conditions.push({ name: 'wait', text: '요청중' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '처리중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '반려' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '발송완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })
  
  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.common(that)
}

// 수기환불 관리에 필요한 정보 추가
constructors.report = (that = {}) => {
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'wait' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 필터 셋팅
  that.filterOptions.status = { name: 'status', text: '상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' } // tool
  that.filterOptions.status.conditions.push({ name: 'wait', text: '제출됨' })
  that.filterOptions.status.conditions.push({ name: 'review', text: '확인중' })
  that.filterOptions.status.conditions.push({ name: 'reject', text: '처리불가' })
  that.filterOptions.status.conditions.push({ name: 'accept', text: '처리완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '취소됨' })

  
  // 소트 셋팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '신청일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  constructors.common(that)
}

class ReviewListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['bookFileChange', 'bookModify', 'refund', 'payReview', 'brunchEvent', 'paperSample', 'report']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.loadResource = this.loadResource.bind(this)
    this.prepared = this.prepared.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)
    this.getQuery = this.getQuery.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), async () => await this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, items: [], total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  // 리소스 불러오기 @ 2023.01.30 최종판
  async loadResource(name, method) {
    const that = this
    const { resource = {} } = this.state

    // 리소스를 가져오면 필터의 컨디션에 세팅을 해준다.
    resource[name] = await api.get(`/resources/admin2/${method}`).catch(e => ({}))

    await new Promise(r => that.setState({ resource }, r))
  }

  async initialize(options = { reload: false }) {
    if (options.reload && window) {
      const query = this.getQuery()
      window.location.href = `${window.location.pathname}?${query}`
      return
    }

    await new Promise((r) => this.setState({ loading: true }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) {
      return this.setState({ loading: false, error: true })
    }
    await this.loadResource('retail', 'takeRetails') // 유통사 정보 리소스 불러오기
    await this.loadResource('brunchEventRound', 'takeBrunchEventRound') // 브런치이벤트 회차정보 리소스 불러오기
    await this.prepared()
    await this.loadItems()
  }

  // 필요한 리소스 또는 컴포넌트 사용준비를 하는 구간
  async prepared() {
    const that = this
    const { resource = {} } = this.state
    
    // 외부유통 활동 유저를 찾기 위한 구분자
    if (resource.retail && _.get(that, 'filterOptions.retails')) {
      const conditions = Object.values(resource.retail)
        .map((retail) => ({ name: `${retail.name}`, text: `${retail.text}` }))
      _.set(that, 'filterOptions.retails.conditions', conditions)
    }
    
    // 브런치 이벤트 회차정보를 찾기 위한 구분자
    if (resource.brunchEventRound && _.get(that, 'filterOptions.round')) {
      const currentRound = _.get(resource, 'brunchEventRound.round') || 1
      const conditions = Array
        .from({ length: currentRound })
        .map((b, bIdx) => ({ name: `${bIdx + 1}`, text: `${bIdx+ 1}회차` }))
      _.set(that, 'filterOptions.round.conditions', conditions)
    }
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    query.startAt = startAt
    query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')

    query.status = filters.status

    // 도서최초 등록 인자 설정
    if (this.props.nav === 'bookFileChange') {
      query.bookType = filters.bookType
      query.workStatus = filters.workStatus
      query.hasNoticeEmail = filters.hasNoticeEmail
      query.workAlias = filters.workAlias
      query.flags = ['currentUser', 'currentBook', 'currentProduct', 'currentStock', 'activeBookRetailApprovals'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    // 서점정보 변경신청 인자 설정
    if (this.props.nav === 'bookModify') {
      query.bookType = filters.bookType
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentBook', 'currentProduct'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    // 수기환불 인자설정
    if (this.props.nav === 'refund') {
      query.payType = filters.payType
      query.payMethod = filters.payMethod
      query.flags = ['currentUser', 'currentPay', 'currentOrder'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    // 후기인증 인자설정
    if (this.props.nav === 'payReview') {
      query.flags = ['currentUser', 'currentPay', 'currentOrder'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    //  브런치이벤트 인자설정
    if (this.props.nav === 'brunchEvent') {
      query.round = filters.round
      query.flags = ['currentUser', 'currentBook'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    //  샘플신청 인자설정
    if (this.props.nav === 'paperSample') {
      query.flags = ['currentUser'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }

    //  샘플신청 인자설정
    if (this.props.nav === 'report') {
      query.flags = ['currentUser'].join(',') // 확장 플래그 기술로 현재 도서 가져오기
    }
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  getQuery() {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

   const queries = {} 
   Object.keys(filters)
    .forEach((key) => {
      const name = `filters_${key}`
      queries[name] = filters[key]
    })
   if (sorts && sorts.length) { queries.sorts = sorts.join(',') }
   if (keywordsOption) { queries.keywordsOption = keywordsOption }
   if (keywords) { queries.keywords = keywords }
   if (page) { queries.page = page }
   if (limit) { queries.limit = limit }
   if (startAt) { queries.startAt = startAt }
   if (endAt) { queries.endAt = endAt }

   return qs.stringify(queries)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return null }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    if (nav ==='bookFileChange') { return <BookFileChangeList {...listProps} /> }
    if (nav ==='bookModify') { return <BookModifyList {...listProps} /> }
    if (nav ==='refund') { return <RefundList {...listProps} /> }
    if (nav ==='payReview') { return <PayReviewList {...listProps} /> }
    if (nav ==='brunchEvent') { return <BrunchEventList {...listProps} /> }
    if (nav ==='paperSample') { return <PaperSampleList {...listProps} /> }
    if (nav ==='report') { return <ReportList {...listProps} /> }

    return null
  }
}

ReviewListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

ReviewListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '운영관리',
  nav: 'bookFileChange',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-12, 'month').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(ReviewListContainer))
