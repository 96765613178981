import {
  ADMIN_PREPARE,
  ADMIN_SIGNIN,
  ADMIN_SIGNOUT
} from './actions'

import { initialState, preparedState } from './selectors'

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_PREPARE :
      return {
        ...state,
        ...(action.payload && action.payload.admin || {}),
        ...preparedState,
      }
    case ADMIN_SIGNIN :
      return {
        ...state,
        ...(action.payload && action.payload.admin || {}),
        ...preparedState,
      }
    case ADMIN_SIGNOUT :
      return { ...preparedState }
    default:
      return state
  }
}

export default reducer
