import React from 'react'

import api from 'services/api'
import _ from 'lodash'

import { getRegionPrice, regionZips } from 'services/constants/delivery'

import { Form, Button } from './utils'
import Modal from 'components/utils/Modal'

// 재고 반출 주소 정보 입력란
class StopStockStorageModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.initializedState = {
      loading: true,
      form: { receiver: '', mobile: '', zip: '', region: '', address1: '', address2: '' }
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doCreate = this.doCreate.bind(this)
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const form = JSON.parse(JSON.stringify(this.initializedState.form))
    const next = { form }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async doCreate() {
    const { productId, onSuccess } = this.props

    const { form } = this.state

    if (!form.receiver) { return alert('배송받을 분의 성명을 입력해주세요.') } 
    if (!form.mobile) { return alert('연락처를 반드시 기재해야 합니다.') } 

    if (!form.zip) { return alert('우편번호가 반드시 필요합니다.') } 
    if (!form.address1) { return alert('시군구 주소가 누락되었습니다.') } 
    if (!form.address2) { return alert('상세주소를 반드시 기재해주세요.') }

    if (!window.confirm(`해당 내용으로 반출을 진행할까요?`)) { return }
    
    const result = await api
      .post(`/products/admin2/${productId}/stopStockStorage`, { form })
      .catch((e) => { return { error: true, message: `오류가 발생하였습니다. ${e.message}` } })

    alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`)
    return !result.error ? onSuccess(result) : null
  }
  
  // 랜더링
  render() {
    const { onClose } = this.props
    const { doCreate } = this
    const { loading, form } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>재고 반출 주소지 정보 입력</Form.Header>
          <Form.Body>
            <Form.Field>
              <div className="control">
                <input
                  type="text"
                  value={form.receiver || ''}
                  placeholder="수하인 성명"
                  onChange={(e) => {
                    const value = e.target.value ? `${e.target.value}` : ''
                    const next = { ...form, receiver: value }
                    return this.setState({ form: next })
                  }}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <div className="control">
                <input
                  type="text"
                  value={form.mobile || ''}
                  placeholder="수하인 연락처(숫자만 기재)"
                  onChange={(e) => {
                    const value = e.target.value
                      ? `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                      : ''
                    const next = { ...form, mobile: value }
                    return this.setState({ form: next })
                  }}
                />
              </div>
            </Form.Field>

            <div style={{ padding: '1rem', lineHeight: '1.618em', letterSpacing: '-0.05rem' }}>
                🍀 
              <a
                href="#setBookkAddress"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const next = {}

                  _.set(next, 'zip', '08589')
                  _.set(next, 'region', false)
                  _.set(next, 'address1', '서울 금천구 가산디지털1로 119')
                  _.set(next, 'address2', 'SK트윈테크타워 A동 305-7호')

                  return this.setState({ form: next })
                }}
              >
                  부크크 주소지 배정하기
              </a>
            </div>

            <Form.Field>
              <div className="control">
                <input
                  type="text"
                  value={form.zip || ''}
                  placeholder="우편번호"
                  onChange={(e) => {
                    const value = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                    const next = { ...form, zip: value, region: regionZips(value) }                    
                    return this.setState(next)
                  }}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <div className="control">
                <input
                  type="text"
                  value={form.address1 || ''}
                  placeholder="시군구 주소지"
                  onChange={(e) => {
                    const value = e.target.value ? `${e.target.value}` : ''
                    const next = { ...form, address1: value }
                    return this.setState(next)
                  }}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <div className="control">
                <input
                  type="text"
                  value={form.address2 || ''}
                  placeholder="상세주소지"
                  onChange={(e) => {
                    const value = e.target.value ? `${e.target.value}` : ''
                    const next = { ...form, address2: value }
                    return this.setState(next)
                  }}
                />
              </div>
            </Form.Field>

            <div style={{ padding: '1rem', lineHeight: '1.618em', letterSpacing: '-0.05rem' }}>
                ☕ 재고서비스 사용중인 상태에서만 처리가 가능하며, 진행시 결제내역은 중지처리되며, 중지 주문내역이 생성되면서 발송대기건이 생깁니다. 총 계약수 만큼의 필요발주량이 증가가 됩니다.
            </div>
            
            <Button.Group>
              <Button
                href="#doCreate"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  
                  return doCreate()
                }}
              >
                주문결제취소 + 중단결제생성 + 반출발송 생성
              </Button>
            </Button.Group>

          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default StopStockStorageModalContainer
