import React from 'react'
import { Helmet } from 'react-helmet-async'

import _ from 'lodash'
import api from 'services/api'
import { isId }  from 'services/utils'

import Book from './Book'
import Solution from './Solution'

import { BookForm } from './utils'

// 도서와 작가서비스 형태의 상품만 고려한다.
// 상품삭제, 판매상태관리(판매상태, 공지메시지), 입점사 관리기능, 변경정보 저장기능
class ProductFormContainer extends React.Component {

  constructor(props) {
    super(props)

    this.abortController = new AbortController()
    
    this.initialize = this.initialize.bind(this)
    this.loadResource = this.loadResource.bind(this)
    this.loadItem = this.loadItem.bind(this)

    this.state = {
      loading: true, error: false, message: '',
      resource: {},
      item: {}
    }
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController) { this.abortController.abort() }
  }

  // 데이터를 초기화해온다.
  async initialize() {
    await this.loadResource()
    return await this.loadItem()
  }

  // 리소스 설정팩을 가져온다.
  async loadResource() {
    const that = this
    const resource = {}
    resource.retails = await api.get(`/resources/admin2/takeRetails`).catch(e => ({}))
    resource.freeBookCoverDesigns = await api.get(`/resources/admin2/takeFreeBookCoverDesigns`).catch(e => ({}))
    resource.bookStoreGenres = await api.get(`/resources/admin2/takeBookStoreGenres`).catch(e => ({}))
    await new Promise(r => that.setState({ resource }, r))
  }

  // 상품을 불러온다.
  async loadItem() {
    const { itemId } = this.props
    if (!itemId && !isId(itemId)) {
      return this.setState({ item: {}, loading: false, error: true, message: `itemId가 존재하지 않거나 올바른 ID값이 아닙니다.` })
    }
    const result = await api.get(`/products/admin2/${itemId}/detail`, { signal: this.abortController.signal }).catch(e => null)
    if (!result || result.error) {
      return this.setState({ item: {}, loading: false, error: true, message: (result && result.message) ? result.message : `데이터를 불러오는 도중 문제가 발생했습니다.` })
    }
    this.setState({ item: result.product, loading: false, error: false, message: (result && result.message) ? result.message : '정상적으로 불러왔습니다.' })
  }

  render() {
    const  { initialize } = this
    const  { loading, error, message, resource, item } = this.state
    if (loading) { return null }

    if (error) {
      return (
        <BookForm>
          <BookForm.Message>
            <header>처리 메시지</header>
            <section>{message}</section>
          </BookForm.Message>
        </BookForm>
      )
    }
    
    const productTypes = {}
    productTypes.paperBook = { name: 'paperBook', text: '종' }
    productTypes.electronicBook = { name: 'electronicBook', text: '전' }
    productTypes.solution = { name: 'solution', text: '작' }

    const childrenProps = { item, resource, initialize }

    return (
      <BookForm>
        {['paperBook', 'electronicBook'].includes(item.productType)
          ? (
            <>
              <Helmet>
                <title>{['paperBook'].includes(item.productType) ? `📖` : `📱`} ({_.get(productTypes, `${item.productType}.text`)})  {_.get(item, 'content.bookNo').toString()} : {_.get(item, 'content.title') || '표제 미기재'} / {_.get(item, 'content.author') || '저자명 미기재'} - BOOKK</title>
              </Helmet>
              <Book type={item.productType} {...childrenProps} />
            </>
          )
          : null}
        {['solution'].includes(item.productType)
          ? (
            <>
              <Helmet>
                <title>📔 ({_.get(productTypes, `${item.productType}.text`)})  {_.get(item, 'content.solutionNo').toString()} : {_.get(item, 'content.name') || '작가서비스명 미기재'} / {_.get(item, 'content.sellerName') || '판매자명 미기재'} - BOOKK</title>
              </Helmet>
              <Solution {...childrenProps} />
            </>
          )
          : null}

        {/* 모달들이 들어갈 예정 */}
      </BookForm>
    )
  }
}

export default ProductFormContainer
