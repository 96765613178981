import _ from 'lodash'
import moment from 'services/moment'

const createViewProps = (column, cIdx, cell, item, itemIndex, row, rowIndex) => {
  return { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: rowIndex%2 ? 'EAEAEAEA' : 'FFFFFFFF' } } }
}

const parser = {}

parser.sheetProps = {
  pageSetup: {
    paperSize: 9, orientation: 'portrait', fitToPage: true,
    margins: { top: 0, right: 0, bottom: 0, left: 0, header: 0, footer: 0 },
  },
}

const columns = [
  {
    header:  '날짜', key: 'createdDate',
    columnProps: { width: 10 },
    view: (item, itemIdx) => moment().format('YYYY-MM-DD'),
    viewProps: createViewProps
  },
  {
    header:  '책장번호', key: 'storageNo',
    columnProps: { width: 10 },
    view: (item) => {
      const storageNo = _.get(item, 'parcelStockStorage.storageNo')
        || _.get(item, 'activatedStockStorage.storageNo')
        || '-'
      return storageNo
    },
    viewProps: createViewProps
  },
  {
    header:  '상품ID', key: 'productId',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'id'),
    viewProps: createViewProps
  },
  {
    header:  '도서번호', key: 'bookNo',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'content.bookNo') || 0,
    viewProps: createViewProps
  },
  {
    header:  '도서명', key: 'bookTitle',
    columnProps: { width: 30 },
    view: (item) => _.get(item, 'content.title') || 0,
    viewProps: createViewProps
  },
  {
    header:  '수령부수', key: 'todayInstockQuantityAmount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'todayInstockQuantityAmount') || 0,
    viewProps: createViewProps
  },
  {
    header:  '당일 출고부수', key: 'todayPackingAmount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'todayPackingAmount') || 0,
    viewProps: createViewProps
  },
  {
    header:  '책장입고', key: 'inAmount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'inAmount') || 0,
    viewProps: createViewProps
  },
  {
    header:  '책장출고', key: 'outAmount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'outAmount') || 0,
    viewProps: createViewProps
  },
  {
    header:  '현재잔고', key: 'instockAmount',
    columnProps: { width: 10 },
    view: (item) => _.get(item, 'stock.instock') || 0,
    viewProps: createViewProps
  }
]

parser.columns = columns

export default parser
