import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Swiper, { SwiperSlide } from 'components/utils/Swiper'
export { Swiper, SwiperSlide }

export const Card = styled.div`
  position: relative; box-sizing: border-box;
  min-width: 100%;
`

Card.Desktop = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
  @media screen and (max-width: 680px) {
    display: none; 
  }
`

Card.Mobile = styled.div`
  position: relative; box-sizing: border-box;
  display: none;
  padding: 0.5rem;
  @media screen and (max-width: 680px) {
    display: block; 
  }
`




// PC버전의 박스
export const DesktopBox = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
  & small { color: ${palette('muted', 7)}; }
  & > div.header {
    position: relative; box-sizing: border-box;
    font-size: 3em; line-height: 1.8em;
  }
`

DesktopBox.Information = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; padding: 0.5rem 0;
  & > div.meta {
    position: relative; box-sizing: border-box;
    flex: 1 1 100%;
    & > div.label {
      position: relative; box-sizing: border-box;
      padding: 0.7rem 0; color: ${palette('muted', 4)};
    }
    & > div.value {
      position: relative; box-sizing: border-box;
      font-size: 2em; line-height: 1.8rem;
    }
  }

`

// PC버전의 아이템
export const DesktopItem = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 20px; line-height: 28px;
  padding: 1rem;
  & small { color: ${palette('muted', 7)}; }
  border-radius: 3px; border: 1px solid ${palette('muted', 12)};
  margin-bottom: 0.5rem;
  div.header {
    position: relative; box-sizing: border-box;
    font-size: 0.9em; padding: 0.5rem;
  }
  div.grid {
    position: relative; box-sizing: border-box; display: flex;
    align-items: center;
    & > div.thumbnail {
      position: relative; box-sizing: border-box;
      padding: 0.5rem;
    }
    & > div.content {
      position: relative; box-sizing: border-box;
      flex: 1 1 100%; text-overflow: hidden; white-space: normal; word-break: break-all;
      padding: 0.5rem;
      & > * { z-index: 1 }
      & > div.amount {
        position: absolute; right: 0.75rem; bottom: 0.75rem;
        z-index: 2;
      }
    }
  }
`

DesktopItem.Group = styled.div`
  position: relative; box-sizing: border-box;
`




// 모바일 버전의 박스
export const MobileBox = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 14px; line-height: 22px;
  & small { color: ${palette('muted', 7)}; }
`

// 모바일 버전의 아이템
export const MobileItem = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 14px;line-height: 22px;
  & small { color: ${palette('muted', 7)}; }
  border-radius: 3px; border: 1px solid ${palette('muted', 12)};
  margin-bottom: 0.5rem;
  div.header {
    position: relative; box-sizing: border-box;
    font-size: 0.9em; padding: 0.5rem;
  }
  div.grid {
    position: relative; box-sizing: border-box; display: flex;
    & > div.thumbnail {
      position: relative; box-sizing: border-box;
      padding: 0.5rem;
    }
    & > div.content {
      position: relative; box-sizing: border-box;
      flex: 1 1 100%; text-overflow: hidden; white-space: normal; word-break: break-all;
      padding: 0.5rem;
      & > * { z-index: 1 }
      & > div.amount {
        position: absolute; right: 0.75rem; bottom: 0.75rem;
        z-index: 2;
      }
    }
  }
`

MobileItem.Group = styled.div`
  position: relative; box-sizing: border-box;
`





export const Button = styled.a`
  position: relative; box-sizing: border-box;
  text-align: center; text-decoration: none; 
  display: block;transition: all 0.3s;
  flex: 1 1 100%; color: ${palette('muted', 2)};
  font-family: ${font('primary')};
  margin: 0.5rem; padding: 0.75rem 0.5rem; 
  border-radius: 0.35rem; background: ${palette('muted', 18)};
  &:hover {
    color: ${palette('muted', 21)};
    background: ${palette('muted', 3)};
  }
  &.primary {
    color: ${palette('muted', 21)};
    background: ${palette('muted', 3)};
    &:hover {
      color: ${palette('muted', 21)};
      background: ${palette('muted', 1)};
    }
  }
`

Button.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex;
  margin: 1rem 0;
`