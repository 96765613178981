import React from 'react'
import api from 'services/api'
import PropTypes from 'prop-types'

import { Wrapper, Signin, Lnr, Logo } from './utils'

const Form = ({ admin, location, history, match, form, initialize, handle, signin }) => {
  const commonProps = { admin, location, history, match }

  return (
    <Wrapper className="animate__animated animate__fadeInUp">
      <Signin>
        <Signin.Header><Logo style={{ fontSize: '2em', letterSpacing: '-0.5rem' }}/></Signin.Header>
        <Signin.Body>
          <Signin.Form>
            <Signin.Field>
              <div className="group">
                <label htmlFor="accountId">관리자 ID</label>
                <input
                  id="accountId" type="text" placeholder="계정ID"
                  value={form.accountId} onChange={e => handle('form.accountId', e.target.value)}
                />
              </div>
            </Signin.Field>
            <Signin.Field>
              <div className="group">
                <label htmlFor="password">비밀번호</label>
                <input
                  id="password" type="password" placeholder="비밀번호"
                  value={form.password} onChange={e => handle('form.password', e.target.value)}
                />
              </div>
            </Signin.Field>
            <Signin.Field>
              <div className="group">
                <label htmlFor="safty">자동<small> 14일간 유지</small></label>
                <input id="safty" type="checkbox" checked={form.safty ? true : false} onChange={e => handle('form.safty', `${form.accountId}:${Date.now()}`)} />
              </div>
            </Signin.Field>
          </Signin.Form>
          <Signin.Buttons>
            <button type="button" onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await signin()]}>
              <span>로그인</span>
              <Lnr c="lock" />
            </button>
          </Signin.Buttons>
        </Signin.Body>
        <Signin.Footer>
          <div className="copyright">Copyright all reserved, <strong>Bookk Co,.Ltd</strong>, since 2014</div>
        </Signin.Footer>
      </Signin>
    </Wrapper>
  )
}

Form.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  form: PropTypes.object,
  handle: PropTypes.func,

  signin: PropTypes.func,
}

Form.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},

  form: {},
  initialize: () => {},
  handle: () => {},

  signin: () => {},
}

export default Form
