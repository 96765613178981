import React from 'react'

import api, { REACT_APP_FILE_URL } from 'services/api'
import _ from 'lodash'

import { comma } from 'services/utils'
import moment from 'services/moment'

import { Swiper, SwiperSlide, Card, Button, DesktopBox, DesktopItem, MobileBox, MobileItem } from './utils'

const List = ({ ...props }) => {
  const { count, items, item, currentIdx, handle } = props

  const swiperRef = React.useRef(null)

  const columns = {}

  columns.IndexColumn = { name: 'IndexColumn', style: { width: '100px', minWidth: '100px', maxWidth: '100px', textAlign: 'center' } }
  columns.payNoColumn = { name: 'payNoColumn', style: { width: '180px', minWidth: '180px', maxWidth: '180px', textAlign: 'center' } }
  columns.buyerColumn = { name: 'buyerColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.productColumn = { name: 'productColumn', style: { flex: '1 1 100%', maxWidth: '100%' } }
  columns.priceColumn = { name: 'priceColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.receiverColumn = { name: 'receiverColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.paidAtColumn = { name: 'paidAtColumn', style: { width: '180px', minWidth: '180px', maxWidth: '180px', borderRight: 0, textAlign: 'center' } }

  const swiperProps = {
    slidesPerView: 1,
    spaceBetween: 50,
    autoHeight: true,
    initialSlide: currentIdx - 1,
    onSlideChange: async (swiper) => {
      const n = swiper.activeIndex + 1
      await handle({ item: items[n-1], currentIdx: n })
    }
  }

  return (
    <div>
      <Swiper {...swiperProps} ref={swiperRef}>
        {items.map((item, itemIdx) => {
          
          const extras = item.extras || {}
          const user = _.get(extras, 'user') || {}
          const orders = _.get(extras, 'orders') || []
          const products = _.get(extras, 'products') || []
          const quantitys = _.get(extras, 'quantitys') || {}
          const stockStorages = _.get(extras, 'stockStorages') || {}
          const pay = _.get(extras, 'pays[0]') || {}
          
          // 개별 정보 기준 처리 내용
          const meta = {}
          
          meta.idx = itemIdx + 1 // 순번 정보
          meta.payNo = _.get(pay, 'payNo') || '-' // 결제번호
          meta.packageName = _.get(item, 'name') || _.get(pay, 'subject') || '정보없음' // 결제자명
          meta.receiverName = _.get(item, 'receiver') || _.get(pay, 'delivery.receiver') || '-' // 수취인명
          meta.userAccountId = _.get(user, 'accountId') || '-'
          meta.receiverMobile = _.get(item, 'mobile') || `연락처 미기재` // 연락처
          meta.zip = `${_.get(item, 'zip') || '00000'}`
          meta.address = `${_.get(item, 'address1') || `동주소 없음`} ${_.get(item, 'address2') || '상세주소 없음'}`
          meta.totalAmount = orders.reduce((s, order) => (s + order.amount), 0)
          meta.payTotalPrice = _.get(pay, 'totalPrice') || 0 // 총 결제금액

          // 정렬기준 처리
          meta.sorted = _.get(item, '_sorted') || {}

          return (
            <SwiperSlide key={itemIdx}>
              <Card>
                
                <Card.Desktop>
                  <DesktopBox>
                    <div className="header">
                      <strong style={{ fontWeight: '900' }}>{meta.idx}</strong>
                      <small style={{ marginLeft: '0.35rem' }}>/</small>
                      <span style={{ marginLeft: '0.35rem', fontSize: '0.8em' }}>{count}</span>
                    </div>
                    <DesktopBox.Information>
                      <div className="meta">
                        <div className="label">주문번호</div>
                        <div className="value">
                          <strong style={{ fontWeight: 900 }}>{meta.payNo}</strong>
                        </div>
                      </div>
                      <div className="meta">
                        <div className="label">수령자</div>
                        <div className="value">
                          <strong style={{ fontWeight: 900 }}>{meta.receiverName}</strong>
                          <small style={{ marginLeft: '0.35rem' }}>{meta.userAccountId}</small>
                        </div>
                      </div>
                    </DesktopBox.Information>
                    <DesktopBox.Information>
                      <div className="meta">
                        <div className="label">연락처</div>
                        <div className="value">
                          <strong style={{ fontWeight: 900 }}>{meta.receiverMobile}</strong>
                        </div>
                      </div>
                      <div className="meta">
                        <div className="label">총권수</div>
                        <div className="value">
                          {meta.totalAmount > 1 ? <strong style={{ fontWeight: 900, fontSize: '1.3em', color: 'red' }}>{meta.totalAmount}</strong> : null}
                          {meta.totalAmount <= 1 ? <strong style={{ fontSize: '1.3em', fontWeight: 900 }}>{meta.totalAmount}</strong> : null}
                          <span style={{ fontSize: '0.9em', marginLeft: '0.25rem' }}>개</span>
                        </div>
                      </div>
                    </DesktopBox.Information>
                    <DesktopBox.Information>
                      <div className="meta">
                        <div className="label">주소</div>
                        <div className="value">
                          <div style={{ marginBottom: '1rem' }}>{meta.zip}</div>
                          <div><strong>{meta.address}</strong></div>
                        </div>
                      </div>
                    </DesktopBox.Information>

                    <div style={{ marginBottom: '2rem' }}></div>

                    <DesktopItem.Group>
                      {orders.map((order, oIdx) => {
                        // 전체상품 리스트 배정된 내역에서 추출해낸다.
                        const product = products.find(o => o.id === order.product) || {}
                        const itemInParcelItems = (item.items || []).find(o => `${o.product}` === `${order.product}`)

                        const oMeta = {}

                        // 도서판형, 규격, 용도, ISBN
                        oMeta.bookNo = _.get(product, 'content.bookNo') || '도서번호없음' // 도서번호 배정
                        oMeta.bookSize = _.get(product, 'content.bookSize') || { text: '알수없음' } // 판형정보
                        oMeta.bookCoverFlap = _.get(product, 'content.cover.flap') ? `날개있음` : `날개없음`
                        oMeta.bookPurpose = _.get(product, 'content.purpose') || { text: '알수없음' }
                        oMeta.bookIsbn = _.get(product, 'content.isbn') || 'ISBN 미기재'

                        // 상품정보
                        oMeta.thumbnailPath = _.get(product, 'content.thumbnail.path')
                        oMeta.thumbnail = oMeta.thumbnailPath ? `${REACT_APP_FILE_URL}/${oMeta.thumbnailPath}` : `/sample.book.png`

                        oMeta.bookTitle = _.get(product, 'content.title') || `도서 표제 미기재`
                        oMeta.bookAuthor = _.get(product, 'content.author') || `저자명 미기재`
                        oMeta.orderAmount = _.get(order, 'amount') || 1

                        // @ 보유한 내역 중 가장 최근 수령일
                        oMeta.quantity = quantitys[order.product] || {}
                        oMeta.lastQuantityMt = _.get(oMeta, 'quantity.createdAt') ? moment(_.get(oMeta, 'quantity.createdAt')) : null

                        // @ 책장번호는 명시된 책장번호 기준으로 처리하기
                        oMeta.stockStorage = stockStorages[order.product] || {}
                        oMeta.storageNo = itemInParcelItems.storageNo ? itemInParcelItems.storageNo : (_.get(oMeta, 'stockStorage.storageNo') || '-')
                        
                        return (
                          <DesktopItem key={oIdx}>
                            <div className="header">
                                <span style={{ marginRight: '0.25rem' }}>{oMeta.bookNo}</span>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <strong style={{ marginRight: '0.25rem' }}>{_.get(oMeta, 'bookSize.text')}</strong>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <span style={{ marginRight: '0.25rem' }}>{_.get(oMeta, 'bookCoverFlap')}</span>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <span style={{ marginRight: '0.25rem' }}>
                                  {['external'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span style={{ color: 'red', fontWeight: 900 }}>{oMeta.bookIsbn}</span> : null}
                                  {['internal'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span>일반판매용</span> : null}
                                  {['collection'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span>소장용</span> : null}
                                  {!_.get(oMeta, 'bookPurpose.name') ? <span>알수없음</span> : null}
                                </span>
                            </div>
                            <div className='grid'>
                              <div className="thumbnail">
                                <img
                                  src={oMeta.thumbnail}
                                  onError={e => e.target.src = '/sample.book.png' }
                                  style={{ width: '120px' }}
                                />
                              </div>
                              <div className="content">
                                <div className="amount">
                                  <span style={{ fontSize: '5rem', fontWeight: 900, marginRight: '0.25rem' }}>
                                    {oMeta.orderAmount > 1 ? <strong style={{ color: 'red' }}>{oMeta.orderAmount}</strong> : null}
                                    {oMeta.orderAmount <= 1 ? <strong>{oMeta.orderAmount}</strong> : null}
                                  </span>
                                  <small>권</small>
                                </div>
                                <div>
                                  <strong style={{ fontSize: '1.1em', fontWeight: 900 }}>{oMeta.bookTitle}</strong>
                                </div>
                                <div>
                                  <small>{oMeta.bookAuthor}</small>
                                </div>
                                <div style={{ fontSize: '0.8em' }}>
                                  {order.isFirstSelfOrder ? <strong style={{ color: 'red', marginRight: '0.35rem' }}>첫구매</strong> : null}
                                  <span style={{ marginRight: '0.35rem' }}>수령</span>
                                  <strong>{oMeta.lastQuantityMt ? oMeta.lastQuantityMt.format('YYYY.MM.DD HH:mm') : '0000.00.00 00:00'}</strong>
                                </div>
                                <div style={{ fontSize: '0.8em' }}>
                                  {oMeta.storageNo > 0
                                    ? (
                                      <>
                                        <span style={{ marginRight: '0.15rem' }}>책장</span>
                                        <strong>{oMeta.storageNo} 번</strong>
                                        {['deactivated'].includes(_.get(oMeta, 'stockStorage.status')) ? <small style={{ marginLeft: '0.35rem' }}>종료</small> : null}
                                        {(!['deactivated'].includes(_.get(oMeta, 'stockStorage.status')) && _.get(oMeta, 'stockStorage.expired'))
                                          ? <small style={{ marginLeft: '0.35rem' }}>만료</small>
                                          : null}
                                      </>
                                    )
                                    : null}
                                </div>
                              </div>
                            </div>
                          </DesktopItem>
                        )
                      })}
                    </DesktopItem.Group>

                  </DesktopBox>
                </Card.Desktop>

                <Card.Mobile>
                  <MobileBox style={{ marginTop: '0.618rem', marginBottom: '1.25rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.15rem 0' }}>
                      <div>
                        <strong style={{ marginRight: '0.25rem' }}>{meta.idx}번</strong>
                        <small style={{ marginRight: '0.25rem' }}>/</small>
                        <small style={{ marginRight: '0.25rem' }}>{count}</small>
                      </div>
                      <div>
                        <strong style={{ marginRight: '0.25rem', fontWeight: '900' }}>{meta.payNo}</strong>
                      </div>
                      <div>
                        <small style={{ marginRight: '0.25rem' }}>총</small>
                        {meta.totalAmount > 1
                          ? (<strong style={{ color: 'red', fontWeight: 900 }}>{meta.totalAmount}권</strong>)
                          : (<strong style={{ fontWeight: 900 }}>{meta.totalAmount}개</strong>)}
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.15rem 0' }}>
                      <div>
                        <strong style={{ fontWeight: 900, marginRight: '0.25rem' }}>{meta.receiverName}</strong>
                        <small style={{ marginRight: '0.25rem' }}>{meta.userAccountId}</small>
                      </div>
                      <div>
                        <strong>{meta.receiverMobile}</strong>
                      </div>
                    </div>
                    <div style={{ padding: '0.15rem 0' }}>
                      <strong>{meta.address}</strong>
                      <small style={{ marginLeft: '0.25rem' }}>{meta.zip}</small>
                    </div>
                  </MobileBox>

                  <MobileItem.Group>
                    {orders.map((order, oIdx) => {
                      // 전체상품 리스트 배정된 내역에서 추출해낸다.
                      const product = products.find(o => o.id === order.product) || {}
                      const itemInParcelItems = (item.items || []).find(o => `${o.product}` === `${order.product}`)

                      const oMeta = {}

                      // 도서판형, 규격, 용도, ISBN
                      oMeta.bookNo = _.get(product, 'content.bookNo') || '도서번호없음' // 도서번호 배정
                      oMeta.bookSize = _.get(product, 'content.bookSize') || { text: '알수없음' } // 판형정보
                      oMeta.bookCoverFlap = _.get(product, 'content.cover.flap') ? `날개있음` : `날개없음`
                      oMeta.bookPurpose = _.get(product, 'content.purpose') || { text: '알수없음' }
                      oMeta.bookIsbn = _.get(product, 'content.isbn') || 'ISBN 미기재'

                      // 상품정보
                      oMeta.thumbnailPath = _.get(product, 'content.thumbnail.path')
                      oMeta.thumbnail = oMeta.thumbnailPath ? `${REACT_APP_FILE_URL}/${oMeta.thumbnailPath}` : `/sample.book.png`

                      oMeta.bookTitle = _.get(product, 'content.title') || `도서 표제 미기재`
                      oMeta.bookAuthor = _.get(product, 'content.author') || `저자명 미기재`
                      oMeta.orderAmount = _.get(order, 'amount') || 1

                        // @ 보유한 내역 중 가장 최근 수령일
                        oMeta.quantity = quantitys[order.product] || {}
                        oMeta.lastQuantityMt = _.get(oMeta, 'quantity.createdAt') ? moment(_.get(oMeta, 'quantity.createdAt')) : null

                        // @ 해당 도서의 재고서비스를 가져온다.
                        oMeta.stockStorage = stockStorages[order.product] || {}
                        oMeta.storageNo = itemInParcelItems.storageNo ? itemInParcelItems.storageNo : (_.get(oMeta, 'stockStorage.storageNo') || '-')

                        return (
                          <MobileItem key={oIdx}>
                            <div className="header">
                                <span style={{ marginRight: '0.25rem' }}>{oMeta.bookNo}</span>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <strong style={{ marginRight: '0.25rem' }}>{_.get(oMeta, 'bookSize.text')}</strong>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <span style={{ marginRight: '0.25rem' }}>{_.get(oMeta, 'bookCoverFlap')}</span>
                                <span style={{ marginRight: '0.25rem' }}>·</span>
                                <span style={{ marginRight: '0.25rem' }}>
                                  {['external'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span style={{ color: 'red', fontWeight: 900 }}>{oMeta.bookIsbn}</span> : null}
                                  {['internal'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span>일반판매용</span> : null}
                                  {['collection'].includes(_.get(oMeta, 'bookPurpose.name')) ? <span>소장용</span> : null}
                                  {!_.get(oMeta, 'bookPurpose.name') ? <span>알수없음</span> : null}
                                </span>
                            </div>
                            <div className='grid'>
                              <div className="thumbnail">
                                <img
                                  src={oMeta.thumbnail}
                                  onError={e => e.target.src = '/sample.book.png' }
                                  style={{ width: '50px' }}
                                />
                              </div>
                              <div className="content">
                                <div className="amount">
                                  <span style={{ fontSize: '1.8rem', fontWeight: 900, marginRight: '0.25rem' }}>
                                    {oMeta.orderAmount > 1 ? <strong style={{ color: 'red' }}>{oMeta.orderAmount}</strong> : null}
                                    {oMeta.orderAmount <= 1 ? <strong>{oMeta.orderAmount}</strong> : null}
                                  </span>
                                  <small>권</small>
                                </div>
                                <div>
                                  <strong style={{ fontSize: '1.1em', fontWeight: 900 }}>{oMeta.bookTitle}</strong>
                                </div>
                                <div>
                                  <small>{oMeta.bookAuthor}</small>
                                </div>
                                <div style={{ fontSize: '0.8em' }}>
                                  {order.isFirstSelfOrder ? <strong style={{ color: 'red', marginRight: '0.35rem' }}>첫구매</strong> : null}
                                  <span style={{ marginRight: '0.15rem' }}>수령</span>
                                  <strong style={{ marginRight: '0.35rem' }}>{oMeta.lastQuantityMt ? oMeta.lastQuantityMt.format('YYYY.MM.DD HH:mm') : '0000.00.00 00:00'}</strong>
                                  {oMeta.storageNo > 0
                                    ? (
                                      <>
                                        <span style={{ marginRight: '0.15rem' }}>책장</span>
                                        <strong>{oMeta.storageNo} 번</strong>
                                        {['deactivated'].includes(_.get(oMeta, 'stockStorage.status')) ? <small style={{ marginLeft: '0.35rem' }}>종료</small> : null}
                                        {(!['deactivated'].includes(_.get(oMeta, 'stockStorage.status')) && _.get(oMeta, 'stockStorage.expired'))
                                          ? <small style={{ marginLeft: '0.35rem' }}>만료</small>
                                          : null}
                                      </>
                                    )
                                    : null}
                                </div>
                              </div>
                            </div>
                          </MobileItem>
                        )
                    })}
                  </MobileItem.Group>
                </Card.Mobile>

              </Card>
            </SwiperSlide>
          )
        })}
      </Swiper>

      <Button.Group>
        <Button
          href="#prev"
          className="primary"
          onClick={async (e) => {
            const n = (currentIdx - 1) <= 0
              ? count
              : currentIdx - 1
            swiperRef.current.swiper.slideTo(n-1)
            await [e.stopPropagation(), e.preventDefault(), await handle({ item: items[n-1], currentIdx: n })]
          }}
        >
          이전
        </Button>
        <Button
          href="#first"
          onClick={async (e) => {
            swiperRef.current.swiper.slideTo(0)
            return [e.stopPropagation(), e.preventDefault(), await handle({ item: items[0], currentIdx: 1 })]
          }}
        >
          처음
        </Button>
        <Button
          href="#last"
          onClick={async (e) => {
            swiperRef.current.swiper.slideTo(count-1)
            return [e.stopPropagation(), e.preventDefault(), await handle({ item: items[items.length-1], currentIdx: count })]
          }}
        >
          마지막
        </Button>
        <Button
          href="#next"
          className="primary"
          onClick={async (e) => {
            const n = (currentIdx + 1) >= count
              ? 1
              : currentIdx + 1
            swiperRef.current.swiper.slideTo(n-1)
            await [e.stopPropagation(), e.preventDefault(), await handle({ item: items[n-1], currentIdx: n })]
          }}
        >
          다음
        </Button>
      </Button.Group>
    </div>
  )
}

export default List