import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import backdoor from 'services/backdoor'

import styled from 'styled-components'
import { palette } from 'styled-theme'

const { REACT_APP_CLIENT_URL } = process.env

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`


class SolutionNoColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
    this.doOpenSolution = this.doOpenSolution.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  // 프론트에서 자동으로 로그인 후에 해당 유저의 입장으로 접근할 수 있도록 처리해주기
  async doOpenSolution(item = {}) {
    return await backdoor.login(item.user, `${REACT_APP_CLIENT_URL}/expert/make/${item.id}`)
  }
  
  render() {
    const { item } = this.props

    // 도서의 머리를 의미한다.
    const meta = {}
    meta.parent = _.get(item, 'parent')
    meta.solutionNo = _.get(item, 'solutionNo')
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <a
            href={`#${item.id}`}
            target="_blank"
            onClick={e => {
              return [e.stopPropagation(), e.preventDefault(), this.doOpenSolution(item)]
            }}
          >
            <strong style={{ fontSize: '0.9em' }}>
              {meta.solutionNo || '미발급'}
            </strong>
            <div style={{ textAlign: 'center' }}>
              <small>{meta.parent ? `🚩재등록` : `⭐신규`}</small>
            </div>
          </a>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

SolutionNoColumnContainer.propTypes = {
  item: PropTypes.object,
}

SolutionNoColumnContainer.defaultProps = {
  item: {}
}

export default SolutionNoColumnContainer
