import React from 'react'
import PropTypes from 'prop-types'
import { comma } from 'services/utils'
import { Menu } from './utils'

const Menus = ({
  items, onActive, isActive, style, 
  usedAll, ...props
}) => {
  const menus = [...items]
  if (usedAll) { menus.push({ name: 'all', text: '전체' }) }

  return (
    <Menu style={style}>
      <Menu.Items>
        {menus.map((item, index) => 
          <Menu.Item
            key={`nav_items_${index}`} href={`#${item.name}`} className={isActive(item) ? "active" : null}
            onClick={e => [e.stopPropagation(), e.preventDefault(), onActive(item)]}
          >
            <span>{item.text}</span>
            {item.name !== 'all' && item.count ? <small style={{ marginLeft: '0.25rem' }}>{comma(item.count || 0)}</small> : null}
          </Menu.Item>
        )}
        <Menu.Item href="#" onClick={e => [e.stopPropagation(), e.preventDefault()]} style={{ flex: '1 1 100%', minWidth: 'auto', maxWidth: '100%', width: '100%'  }}></Menu.Item>
      </Menu.Items>
    </Menu>
  )
}

Menus.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isActive: PropTypes.func,
  onActive: PropTypes.func,
  style: PropTypes.object,
}

Menus.defaultProps = {
  items: [],
  style: {},
  isActive: () => {},
  onActive: () => {},
}

Menus.Menu = Menu

export default Menus
