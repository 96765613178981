import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class NoColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const extras = item.extras || {}

    const parentPay = extras.parentPay || {}

    // 도서의 머리를 의미한다.
    const meta = {}
    meta.payType = _.get(item, 'payType')
    meta.payNo = _.get(item, 'payNo')
    meta.payId = _.get(item, 'id') || null
    meta.parent = _.get(item, 'parent') || null
    
    meta.parentPayNo = _.get(parentPay, 'payNo')
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <a
            href={`/pays/${meta.payType}/${meta.payId}`}
            target="_blank"
            onClick={e => {
              if (!meta.payId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong style={{ fontSize: '1.1em', fontWeight: 900 }}>
              {meta.payNo || '-'}
            </strong>
          </a>

          {meta.parentPayNo
            ? (
              <div>
                <a
                  href={`/pays/${meta.payType}/${meta.parent}`}
                  target="_blank"
                  onClick={e => {
                    if (!meta.payId) {
                      return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
                    }
                    return
                  }}
                >
                  <small style={{ fontSize: '0.7em' }}>
                    👨‍👧 상위 <strong>{meta.parentPayNo || '-'}</strong>
                  </small>
                </a>
              </div>
            )
            : null}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

NoColumnContainer.propTypes = {
  item: PropTypes.object,
}

NoColumnContainer.defaultProps = {
  item: {}
}

export default NoColumnContainer
