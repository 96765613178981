import _ from 'lodash'
import moment from 'services/moment'
import { retailsKeys } from 'services/constants/retail'

// pay에서 currentOrders을 winds 처리해서 뽑아내는 파서
const parser = {}

parser.columns = [
  {
    header:  '결제ID', key: 'payId',
    view: (item) => _.get(item, 'pay')
  },
  {
    header:  '주문결제·생성일', key: 'createdAt',
    view: (item) => {
      const pay = _.get(item, 'extras.pay') || {}
      const at = _.get(pay, 'paidAt') || _.get(pay, 'createdAt')
      return at ? moment(at).format('YYYYMMDD') : ''
    }
  },
  {
    header:  '판매처', key: 'retail',
    view: (item) => {
      const key = _.get(item, 'retail') || {}
      if (key === 'bookk') { return '부크크' }
      const rName = _.get(retailsKeys, key)
      return rName || '알수없음'
    }
  },
  {
    header:  '결제상태', key: 'createdAt',
    view: (item) => {
      const pay = _.get(item, 'extras.pay') || {}
      const status = _.get(pay, 'status')
      if (['wait'].includes(status)) { return '결제대기' }
      if (['finished'].includes(status)) { return '결제완료' }
      if (['cancel'].includes(status)) { return '결제취소' }
      if (['error'].includes(status)) { return '결제오류' }
      if (['error'].includes(status)) { return '결제오류' }
      return '임시'
    }
  },
  {
    header:  '환불단계', key: 'createdAt',
    view: (item) => {
      const pay = _.get(item, 'extras.pay') || {}
      const refundStatus = _.get(pay, 'refundStatus')
      if (['wait'].includes(refundStatus)) { return '환불요청' }
      if (['review'].includes(refundStatus)) { return '환불처리중' }
      if (['reject'].includes(refundStatus)) { return '환불거절' }
      if (['accept'].includes(refundStatus)) { return '환불완료' }
      if (['cancel'].includes(refundStatus)) { return '환불취소' }
      return '이슈없음'
    }
  },
  {
    header:  '분류', key: 'orderType',
    view: (item) => {
      const value = _.get(item, 'payType')
      if (value === 'paperBook') { return '종이도서' }
      if (value === 'electronicBook') { return '전자도서' }
      if (value === 'paperBook') { return '작가서비스' }
      if (value === 'etc') { return '기타' }
      return '기타'
    }
  },
  {
    header:  '칼라/흑백', key: 'bookTextColor',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      if (['electronicBook'].includes(book.type)) { return '' }
      return _.get(book, 'cover.color.text') || '알수없음'
    }
  },
  {
    header:  '출판일', key: 'bookPublishedAt',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return _.get(book, 'publishedAt') ? moment(_.get(book, 'publishedAt')).format('YYYYMMDD') : ''
    }
  },
  {
    header:  '도서등록구분', key: 'submittedType',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return book.brunch ? '브런치' : '부크크'
    }
  },
  {
    header:  '상품명', key: 'bookTitle',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return book.title || ''
    }
  },
  {
    header:  '저자명', key: 'bookAuthor',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return book.author || ''
    }
  },
  {
    header:  '장르', key: 'bookGenre',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return _.get(book, 'genre.text') || ''
    }
  },
  {
    header:  '구매개수', key: 'subject',
    view: (item) => {
      return _.get(item, 'amount') || 0
    }
  },
  {
    header:  '구매액', key: 'subject',
    view: (item) => {
      return _.get(item, 'totalPrice') || 0
    }
  },
  {
    header:  '본인구매', key: 'subject',
    view: (item) => {
      return item.user === item.seller ? '본인' : '타인'
    }
  },
  {
    header:  '판매자 계정ID', key: 'seller',
    view: (item) => {
      return item.seller || ''
    }
  },
  {
    header:  '저자 계정명', key: 'seller',
    view: (item) => {
      return _.get(item, 'content.authorAccountId') || ''
    }
  },
  {
    header:  '구매자 계정ID', key: 'buyer',
    view: (item) => {
      const pay = _.get(item, 'extras.pay') || {}
      return _.get(pay, 'userAccountId') || ''
    }
  },
  {
    header:  '출판사명', key: 'bookPublisher',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return _.get(book, 'publisher') || ''
    }
  },
  {
    header:  '출판용도', key: 'bookPurpose',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return _.get(book, 'purpose.text') || ''
    }
  },
  {
    header:  '부크크 ISBN', key: 'bookIsbn',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return _.get(book, 'isbn') || ''
    }
  },
  {
    header:  '교보 ISBN', key: 'kyoboIsbn',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}

      const retailIsbns = _.get(book, 'retailIsbns') || []
      const retailIsbn = retailIsbns.find(retailIsbn => retailIsbn.name === 'kyobo')
      return retailIsbn && retailIsbn.name ? retailIsbn.isbn : ''
    }
  },
  {
    header:  '성인물', key: 'bookAgeLimit',
    view: (item) => {
      const product = _.get(item, 'extras.product') || {}
      const book = _.get(product, 'content') || {}
      return (_.get(book, 'ageLimit') && _.get(book, 'ageLimit') === 'adult') ? `Y` : 'N'
    }
  }
]

export default parser
