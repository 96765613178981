import React from 'react'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import { comma } from 'services/utils'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import DataSet from 'components/utils/DataSet'
import Modal from 'components/utils/Modal'

const Menu = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; text-align: center; white-space: pre; padding: 1.25rem 3.5rem; font-weight: 700;
  text-decoration: none; font-family: ${font('primary')}; font-size: 1.2em; color: ${palette('muted', 5)}; 
  border-bottom: 1px solid ${palette('muted', 18)}; cursor: pointer;
  &:hover { color: ${palette('darkblue', 5)}; }
  &.active {
    color: ${palette('muted', 1)}; font-weight: 900;
    border-bottom: 1px solid ${palette('muted', 1)}; 
  }
`

Menu.Group = styled.nav`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
`

const Form = styled.section`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
`

Form.Wrapper = styled.div`
  position: relative; box-sizing: border-box;
  width: 1680px; max-width: 1680px; min-width: 1680px;
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1.5rem 1rem; font-size: 1.35em; line-height: 1.35rem;
  font-family: ${font('primary')}; font-weight: 900;
`

Form.Grid = styled.section`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; flex: 1 1 auto;
`



const Meta = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex: 1 1 auto; justify-content: space-between;
  min-width: 50%; max-width: 50%; width: 50%;
  padding: 0.5rem 0;
  & strong { font-weight: 900; color: #111 }
  & small { font-weight: 300; color: #777 }
`

Meta.Label = styled.div`
  position: relative; box-sizing: border-box;
  width: 110px; min-width: 110px; max-width: 110px;
  font-size: 0.8em; color: #666;
`

Meta.Text = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 auto;
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; flex: 1 1 auto;  justify-content: space-between;
`

Form.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  width: 110px; min-width: 110px; max-width: 110px; padding: 1rem;
  border-left: 1px dashed ${palette('muted', 15)}; 
`



const Button = styled.a`
  position: relative; box-sizing: border-box;
  text-decoration: none; display: block;
  text-align: center; color: #999;
  transition: all 0.3s;
  & > div.icon {
    position: relative; box-sizing: border-box;
    font-size: 2em; line-height: 2.1rem;
    padding: 0.75rem 0;
  }
  & > div.text {
    position: relative; box-sizing: border-box;
    font-size: 0.85em; line-height: 1.05rem;
    font-family: ${font('primary')};
  }
  &:hover { color: #111; }
`

Button.Group = styled.div`
  position: relative; box-sizing: border-box;
`

// 매출내역 상세 모달(목록 의존형)
class PayUploadDetailModalContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true, executed: false,
      item: {},
      tab: 'payload' // payload(반영전), executed(반영후)
    }

    this.initialize = this.initialize.bind(this)
    this.loadItem = this.loadItem.bind(this)
    this.doExecutePayUpload = this.doExecutePayUpload.bind(this)
    this.doUsedInvoice = this.doUsedInvoice.bind(this)
    this.deletePayUpload = this.deletePayUpload.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const { onClose } = this.props
    const item = await this.loadItem()
    if (!item) { return onClose() }
    return this.setState({ loading: false, item, tab: (['executed'].includes(item.status) ? 'executed' : 'payload') })
  }
  
  // 문서, 결제고객정보, 주문, 결제내역, 도서 상품정보 5가지의 디테일한 정보 (approval, customer, bookProduct, orders, pays)
  async loadItem() {
    const itemId = _.get(this, 'props.itemId')
    if (!itemId) { return null }
    return await api.get(`/payUploads/admin2/${itemId}/detail`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data || data.error) {
          return alert(data && data.message || `매출내역 데이터를 불러오지 못했습니다.`)
        }
        return data && data.payUpload
      })
      .catch(e => null)
  }

  // 해당 매출업로드 내역을 실반영하는 기능
  async doExecutePayUpload(item = {}, opts = {}) {
    if (this.state.executed) { return alert(`현재 진행중입니다. 반영이 정상적으로 끝날때까지 기다려주세요.`) }
    if (!window.confirm(`해당 내용을 기준으로 결제내역을 생성할까요? 응답이 끝날때까지 기다려주세요. 😥`)) { return false }

    this.setState({ executed: true })
    
    return await api.put(`/payUploads/admin2/${item.id}/execute`, opts)
      .then((data) => {
        if (!data && data.error) {
          alert(data && data.message ? data.message : `오류가 발생하였습니다.`)
          return this.initialize()
        }
        alert(`정상적으로 처리하였습니다.`)
        return this.initialize()
      })
      .catch((e) => {
        console.log(e.message)
        console.log(e.stack)
        alert(`오류가 발생하였습니다.`)
        return this.initialize()
      })
  }

  // 해당내용을 실반영 + 신규발주 물량합산처리 과정을 갖음
  async doUsedInvoice(item = {}, opts = {}) {
    if (!window.confirm(`해당 내용을 기준으로 신규발주에 물량을 합산 처리할까요?`)) { return false }
    if (!opts.forced && item.usedInvoice) { return alert(`이미 신규발주 요청 처리가 완료되었습니다.`) }

    return await api.put(`/payUploads/admin2/${item.id}/usedInvoice`, opts)
      .then((data) => {
        if (!data && data.error) {
          alert(data && data.message ? data.message : `오류가 발생하였습니다.`)
          return this.initialize()
        }
        alert(`정상적으로 처리하였습니다.`)
        return this.initialize()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return this.initialize()
      })
  }

  // 해당 업로드기능을 삭제하는 기능
  async deletePayUpload(item) {
    const { onClose } = this.props
    if (!window.confirm(`매출내역 삭제처리 기능을 진행합니다. 임시상태일 때만 가능합니다.`)) { return false }
    return await api.delete(`/payUploads/admin2/${item.id}`)
      .then((data) => {
        if (!data && data.error) {
          alert(data && data.message ? data.message : `오류가 발생하였습니다.`)
          return this.initialize()
        }
        alert(`정상적으로 처리하였습니다.`)
        return onClose()
      })
      .catch(e => {
        alert(`오류가 발생하였습니다.`)
        return onClose()
      })
  }

  render() {
    const { doExecutePayUpload, doUsedInvoice, deletePayUpload } = this
    const { onClose } = this.props
    const { loading, item = {}, tab } = this.state
    if (loading) { return null }

    // 모달관리 프로퍼티(Modal.js)
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => { })

    // 메타데이터 표현부
    const meta = {}
    meta.createdMt = _.get(item, 'createdAt')
      ? moment(_.get(item, 'createdAt')).format('YYYY.MM.DD HH:mm')
      : '0000.00.00 00:00'
    meta.executedMt = _.get(item, 'executedAt')
      ? moment(_.get(item, 'executedAt')).format('YYYY.MM.DD HH:mm')
      : '0000.00.00 00:00'
    meta.totalPayCount = item.totalPayCount ? comma(item.totalPayCount) : 0
    meta.totalAmount = item.totalAmount ? comma(item.totalAmount) : 0
    meta.totalPrice = item.totalPrice ? comma(item.totalPrice) : 0

    const statusTypes = {}
    statusTypes.wait = { name: 'wait', text: '임시', caret: 'muted' }
    statusTypes.executed = { name: 'executed', text: '반영완료', caret: 'success' }
    statusTypes.deleted = { name: 'deleted', text: '삭제됨', caret: 'danger' }
    meta.status = item.status || 'wait'
    meta.statusType = meta.status
      ? statusTypes[meta.status] || statusTypes.wait
      : statusTypes.wait

    const retail = item.config || { parcelCreatedMethod: 'none' }
    
    const items = ['executed'].includes(tab) ? (item.executed || []) : (item.payload || [])

    const columns = []
    
    if (['executed'].includes(tab)) {
      columns.push({ key: 'PayColumn', header: '주문번호', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center' } })
    }
    
    // 반영전 컬럼배열 : 배송지별일때, 상품종별 또는 발주없음일 때
    if (['address'].includes(item.parcelCreatedMethod) && ['paperBook'].includes(item.payType)) {
      columns.push({ key: 'ReceiverColumn', header: '수령인', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'flex-start' } })
      columns.push({ key: 'AddressColumn', header: '수령지', style: { minWidth: '320px', maxWidth: '320px', justifyContent: 'flex-start' } })
      columns.push({ key: 'DatasColumn', header: '주문내역', style: { minWidth: '0', maxWidth: '100%', justifyContent: 'flex-start' } })
      columns.push({ key: 'QuantityColumn', header: '계', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center' } })
    }
    if (['product', 'none'].includes(item.parcelCreatedMethod)) {
      columns.push({ key: 'IdendityColumn', header: '데이터', style: { minWidth: '0', maxWidth: '100%', justifyContent: 'flex-start' } })
      if (['executed'].includes(tab) && ['paperBook'].includes(item.payType)) {
        columns.push({ key: 'ReceiverColumn', header: '수령인', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'flex-start' } })
        columns.push({ key: 'AddressColumn', header: '수령지', style: { minWidth: '320px', maxWidth: '320px', justifyContent: 'flex-start' } })
      }
      columns.push({ key: 'PriceColumn', header: '단가', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center' } })
      columns.push({ key: 'QuantityColumn', header: '계', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center' } })
    }
    if (['executed'].includes(tab) && ['product', 'address'].includes(item.parcelCreatedMethod) && ['paperBook'].includes(item.payType)) {
      columns.push({ key: 'ResultColumn', header: '발주·생산단계', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center' } })
    }

    console.log({ item, columns, items })

    return (
      <Modal {...modalProps}>
        <Form.Wrapper>
          <Form.Header>
            매출업로드 상세내역
          </Form.Header>
          <Form.Grid>
            <Form.Content>

              <Menu.Group style={{ margin: '1rem 0' }}>
                <Menu
                  className={['payload'].includes(tab) ? "active" : ''}
                  onClick={e => {
                    return [e.stopPropagation(), e.preventDefault(), this.setState({ tab: 'payload' })]
                  }}
                >
                  반영대기 😮
                </Menu>
                <Menu
                  className={['executed'].includes(tab) ? "active" : ''}
                  onClick={e => {
                    if (!['executed'].includes(item.status)) { return alert(`반영이 되어야만 확인 할 수 있습니다.`) }
                    return [e.stopPropagation(), e.preventDefault(), this.setState({ tab: 'executed' })]
                  }}
                >
                  반영완료 😊
                </Menu>
              </Menu.Group>

              <Meta.Group style={{ margin: '1rem 0' }}>
                <Meta>
                  <Meta.Label>
                    <div>등록일자</div>
                    <div><small>반영일자</small></div>
                  </Meta.Label>
                  <Meta.Text>
                    <div><strong>{meta.createdMt}</strong></div>
                    <div><small>{meta.executedMt}</small></div>
                  </Meta.Text>
                </Meta>

                <Meta>
                  <Meta.Label>
                    <div>총 반영액</div>
                    <div><small>주문수·총 합산 갯수</small></div>
                  </Meta.Label>
                  <Meta.Text>
                    <div><strong>{meta.totalPrice}</strong> <small>원</small></div>
                    <div>
                      <small style={{ marginRight: '0.5rem' }}>{_.get(meta, 'statusType.text')}</small>
                      <strong style={{ marginRight: '0.35rem' }}>{meta.totalPayCount}</strong>
                      <small style={{ marginRight: '0.35rem' }}>건</small>
                      <strong style={{ marginRight: '0.35rem' }}>{meta.totalAmount}</strong>
                      <small style={{ marginRight: '0.35rem' }}>개</small>
                    </div>
                  </Meta.Text>
                </Meta>
              </Meta.Group>
          
              <DataSet>
                <DataSet.Header>
                  <DataSet.Item>
                    {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
                  </DataSet.Item>
                </DataSet.Header>

                {/* 배송지별일때 */}
                {['address'].includes(item.parcelCreatedMethod)
                  ? (
                    <DataSet.Body>
                      {items.map((obj, idx) => {
                        return (
                          <DataSet.Item key={`obj_${idx}`}>
                            {columns.map((column, cIdx) => {
                              const datas = obj.datas || []

                              const o = {}
                              o.payId = _.get(obj, 'payId') || '-'
                              o.payType = _.get(obj, 'payType') || 'paperBook'
                              o.totalPrice = datas.reduce((s, data) => data.productPrice * (data.quantity * 1) + s, 0)
                              o.totalQuantity = datas.reduce((s, data) => data.quantity * 1 + s, 0)

                              o.delivery = _.get(obj, 'delivery') || {}

                              return (
                                <DataSet.Col key={`${idx}_${cIdx}`} style={column.style}>
                                  {['PayColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <a
                                          href={`#openPay`}
                                          onClick={e => {
                                            return window.open(`/pays/${o.payType}/${o.payId}`)
                                          }}
                                        >
                                          <small>{o.payId}</small>
                                        </a>
                                        {o.productProduce}
                                      </div>
                                    )
                                    : null}
                                  {['ReceiverColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <div>
                                          <strong>{_.get(o, 'delivery.receiver') || '수취인명 없음'}</strong><br/>
                                          <small>{_.get(o, 'delivery.mobile') || '휴대폰 번호없음'}</small>
                                        </div>
                                      </div>
                                    )
                                    : null}
                                  {['AddressColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <div>
                                          <strong>{_.get(o, 'delivery.zip') || '000'}</strong>
                                          <small style={{ marginLeft: '0.35rem' }}>{_.get(o, 'delivery.address1') || '동주소 미기재'}</small>
                                        </div>
                                        <div>
                                          {_.get(o, 'delivery.address2') || '상세주소미기재'}
                                        </div>
                                        <div>
                                          <small>{_.get(o, 'delivery.message') || '배송메시지 미기재'}</small>
                                        </div>
                                      </div>
                                    )
                                    : null}
                                    {['DatasColumn'].includes(column.key)
                                      ? (
                                        <div>
                                          {datas.map((data, dIdx) => {
                                              const dMeta = {}
                                              dMeta.productName = _.get(data, 'productName') || ''
                                              dMeta.productPrice = _.get(data, 'productPrice') || 0
                                              dMeta.productId = _.get(data, 'identity') || ''
                                              dMeta.quantity = _.get(data, 'quantity') || 0
                                              dMeta.totalPrice = dMeta.quantity * dMeta.productPrice

                                            return (
                                              <div key={dIdx}>
                                                <strong style={{ marginRight: '0.5rem' }}>[{dMeta.productId}]</strong>
                                                <span style={{ marginRight: '0.5rem' }}>{dMeta.productName}</span>
                                                <small>{comma(dMeta.productPrice)} 원 * {comma(dMeta.quantity)} 개 = {comma(dMeta.totalPrice)} 원</small><br/>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      )
                                      : null}
                                  {['QuantityColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <strong>{comma(o.totalPrice)}원</strong><br/>
                                        <small>총 {comma(o.totalQuantity)} 개</small>
                                      </div>
                                    )
                                    : null}
                                  {['ResultColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        {item.usedInvoice ? `발주완료` : `대기중`}
                                      </div>
                                    )
                                    : null}
                                  </DataSet.Col>
                                  )
                              })}
                            </DataSet.Item>
                        )
                      })}
                    </DataSet.Body>
                  )
                  : null}

                {/* 종별, 발주없음일때 */}
                {['product', 'none'].includes(item.parcelCreatedMethod)
                  ? (
                    <DataSet.Body>
                      {items.map((obj, idx) => {
                        return (
                          <DataSet.Item key={`obj_${idx}`}>
                            {columns.map((column, cIdx) => {
                              const datas = obj.datas || []

                              const o = {}
                              o.productId = _.get(datas[0], 'identity')
                              o.payId = _.get(obj, 'payId') || '-'
                              o.payType = _.get(obj, 'payType') || 'paperBook'
                              o.productPrice = _.get(datas[0], 'productPrice')
                              o.productName = _.get(datas[0], 'productName')

                              o.totalPrice = datas.reduce((s, data) => data.productPrice * (data.quantity * 1) + s, 0)
                              o.totalQuantity = datas.reduce((s, data) => data.quantity * 1 + s, 0)

                              o.delivery = _.get(obj, 'delivery') || {}

                              return (
                                <DataSet.Col key={`${idx}_${cIdx}`} style={column.style}>
                                  {['PayColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <a
                                          href={`#openPay`}
                                          onClick={e => {
                                            return window.open(`/pays/${o.payType}/${o.payId}`)
                                          }}
                                        >
                                          <small>{o.payId}</small>
                                        </a>
                                      </div>
                                    )
                                    : null}
                                  {['ReceiverColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <div>
                                          <strong>{_.get(o, 'delivery.receiver') || '수취인명 없음'}</strong><br/>
                                          <small>{_.get(o, 'delivery.mobile') || '휴대폰 번호없음'}</small>
                                        </div>
                                      </div>
                                    )
                                    : null}
                                  {['AddressColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <div>
                                          <strong>{_.get(o, 'delivery.zip') || '000'}</strong>
                                          <small style={{ marginLeft: '0.35rem' }}>{_.get(o, 'delivery.address1') || '동주소 미기재'}</small>
                                        </div>
                                        <div>
                                          {_.get(o, 'delivery.address2') || '상세주소미기재'}
                                        </div>
                                        <div>
                                          <small>{_.get(o, 'delivery.message') || '배송메시지 미기재'}</small>
                                        </div>
                                      </div>
                                    )
                                    : null}
                                  {['IdendityColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <strong>{o.productName}</strong><br/>
                                        <small>{o.productId}</small>
                                      </div>
                                    )
                                    : null}
                                  {['PriceColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <strong>{comma(o.productPrice)}원</strong>
                                      </div>
                                    )
                                    : null}
                                  {['QuantityColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        <strong>{comma(o.totalPrice)}원</strong><br/>
                                        <small>총 {comma(o.totalQuantity)} 개</small>
                                      </div>
                                    )
                                    : null}
                                  {['ResultColumn'].includes(column.key)
                                    ? (
                                      <div>
                                        {item.usedInvoice ? `최종반영완료` : `대기중`}
                                      </div>
                                    )
                                    : null}
                                  </DataSet.Col>
                                  )
                              })}
                            </DataSet.Item>
                        )
                      })}
                    </DataSet.Body>
                  )
                  : null}

              </DataSet>
              
            </Form.Content>
            <Form.Aside>
              <Button.Group>
                {!['executed'].includes(item.status)
                  ? (
                    <Button
                      href="#closeModal"
                      style={{ marginBottom: '1.8rem' }}
                      onClick={async (e) => [e.stopPropagation(), e.preventDefault(), doExecutePayUpload(item)]}
                    >
                      <div className="icon"><Lnr c="check" /></div>
                      <div className="text">반영</div>
                    </Button>
                  )
                  : null}
                {/* {(['executed'].includes(item.status) && ['product', 'address'].includes(item.parcelCreatedMethod) && !item.usedInvoice && ['paperBook'].includes(item.payType) && !item.usedInvoice)
                  ? (
                    <Button
                      href="#closeModal"
                      style={{ marginBottom: '1.8rem' }}
                      onClick={async (e) => [e.stopPropagation(), e.preventDefault(), doUsedInvoice(item)]}
                    >
                      <div className="icon"><Lnr c="factory" /></div>
                      <div className="text">발주</div>
                    </Button>
                  )
                  : null} */}
                {!['executed'].includes(item.status)
                  ? (
                    <Button
                      href="#closeModal"
                      style={{ marginBottom: '1.8rem' }}
                      onClick={async (e) => [e.stopPropagation(), e.preventDefault(), deletePayUpload(item)]}
                    >
                      <div className="icon"><Lnr c="trash" /></div>
                      <div className="text">삭제</div>
                    </Button>
                  )
                  : null}
                <Button
                  href="#closeModal"
                  style={{ marginBottom: '1.8rem' }}
                  onClick={async (e) => [e.stopPropagation(), e.preventDefault(), modalProps.onClose()]}
                >
                  <div className="icon"><Lnr c="cross" /></div>
                  <div className="text">닫기</div>
                </Button>
              </Button.Group>
            </Form.Aside>
          </Form.Grid>
        </Form.Wrapper>
      </Modal>
    )
  }
}

export default PayUploadDetailModalContainer
