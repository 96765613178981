import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as actions from 'store/actions'
import { fromAdmin } from 'store/selectors'

import {
  Page as Wrapper, BreadCrumb, Nav, Aside, Container,
  Tree, Lnr, Logo,
} from './utils'

import api from 'services/api'
import moment from 'services/moment'

import tree from './tree'

const Page = ({ admin, aside, full, children, ...props }) => {

  const initPathName = window.location.pathname
  const initGroup = Object
    .values(tree)
    .find(group => {
      const allowPaths = (group.patterns || [])
        .map((pattern) => initPathName.indexOf(pattern) === 0)
      return allowPaths.includes(true)
    })
  const [selectedGroup, setGroup] = React.useState(initGroup || {})
  const [expendChilds, setExpendChild] = React.useState(false)

  const isOpend = selectedGroup && selectedGroup.name && expendChilds

  if (props.location.pathname !== '/signin') {
    if (!admin._prepare) {
      props.prepareAdmin()
      return null
    }
    if (admin._prepare && !admin.id) {
      props.history.push('/signin')
      return null
    }
  }

  // 웹페이지
  const containerStyles = full ? { marginLeft: 0 } : {}

  if (isOpend) { containerStyles.opacity = 0.1  }

  const actions = {}

  actions.isWideContainer = () => {
    const currentPathName = window.location.pathname
    if (currentPathName === '/') { return false }
    if (currentPathName.indexOf('/signin') > -1) { return false }
    if (currentPathName.indexOf('/packings') > -1) { return false }
    // 메인페이지, 패킹페이지 2가지 뺀 나머지는 모두 와이드모드
    return true
  }

  return (
    <Wrapper {...props}>
      {!full && (aside || (
        <Aside
          className={isOpend ? `active` : ''}
          onMouseLeave={(e) => {
            return [setGroup(initGroup),  setExpendChild(false)]
          }}
        >
          <Aside.Header><Logo /></Aside.Header>

          <Tree>
            {Object
              .values(tree)
              .map((group, gIdx) => {
                const isActive = selectedGroup && group.name === selectedGroup.name 
                return (
                  <Tree.Group
                    key={`TreeGroup_${gIdx}`}
                    direction={group.direction}
                    expand={_.get(group, 'items.length')}
                    className={(expendChilds && isActive) ? 'opened' : ''}
                  >
                    <Tree.Header
                      className={isActive ? `active` : ''}
                      onClick={(e) => {
                        if (group.to) {
                          e.stopPropagation()
                          e.preventDefault()
                          setGroup(group)
                          setExpendChild(false)
                          return props.history.push(group.to)
                        }
                        if (!isActive) {
                          return [setGroup(group), setExpendChild(true)]
                        }
                        return setExpendChild(!expendChilds)
                      }}
                    >
                      <div className="text">{group.text}</div>
                    </Tree.Header>

                    {(expendChilds && _.get(group, 'items.length'))
                      ? (
                          <Tree.Items>
                            {group.items
                              .map((item, index) => {
                                const itemProps = {}
                                if (item.to) { itemProps.href = item.to }
                                if (item.onClick) {
                                  itemProps.onClick = (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return item.onClick(e, item)
                                  }
                                }
                                return (
                                  <Tree.Item
                                    key={`Item_${index}`}
                                    {...itemProps || {}}
                                  >
                                    {item.text}
                                  </Tree.Item>
                                )
                              })}
                          </Tree.Items>
                        )
                        : null}
                  </Tree.Group>
                )
              })}
          </Tree>

          <Aside.Footer>
            <a
              href="#checkVersion"
              onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await api
                .get('/v')
                .then((res) => {
                  if (res.error) { return alert(res.message ? `${res.message}` : `서버가 응답하지 않습니다.`) }
                  const bootMt = moment(res.bootAt)
                  return alert(`[BOOKK API]\n- Version ${res.version} (${bootMt.format('YYYY.MM.DD HH시 mm분')} 기준)\n- 정상 응답중`)
                })
                .catch((e) => alert(`서버가 정상적으로 작동하지 않습니다. ${e.message}`))
            }}>
              API 버전 조회
            </a>
          </Aside.Footer>
        </Aside>
      ))}
      <Container
        className={actions.isWideContainer() ? "wide" : ""}
        style={containerStyles}>
          {children}
      </Container>
    </Wrapper>
  )
}

Page.propTypes = {
  admin: PropTypes.object,
  aside: PropTypes.any,
  full: PropTypes.bool,
  prepareAdmin: PropTypes.func,
}

Page.defaultProps = {
  admin: {},
  prepareAdmin: async () => { }
}

Page.Aside = Aside
Page.Container = Container
Page.BreadCrumb = BreadCrumb
Page.Nav = Nav

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

const mapDispatchToProps = (dispatch) => ({
  prepareAdmin: async () => dispatch(await actions.prepareAdmin())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Page))
