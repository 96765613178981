import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import BookStoreGenreModal from './Setups/BookStoreGenreModal'

import { Header, Task, Items, DataSet, Lnr, Pager, ProgressModal } from './utils'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds, resources,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'DIdxColumn', header: '순번', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ParentNameColumn', header: '부모코드', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'GenreNameColumn', header: '장르코드', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ParentTextColumn', header: '부모명칭', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'GenreTextColumn', header: '명칭', style: { flex: '1 1 auto', maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StatusColumn', header: '노출상태', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'SortOrderColumn', header: '순서변경', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}

  // 서점 장르 정보 변경 및 신규 추가기능
  actions.doOpenBookStoreGenreModal = async (itemName = '') => {
    const modalProps = { name: 'BookStoreGenreModal' }
    modalProps.props = { name: 'BookStoreGenreModal', itemName, onClose: async () => [setModal(null), await initialize()] }
    setModal(modalProps)
  }

  // 서점 장르 노출 상태 업데이트
  actions.doUpdateBookStoreGenreStatus = async (item, status) => {
    if (!window.confirm(`해당 서점 장르의 노출 상태를 변경할까요?`)) { return false }
    const form = {}
    form.parent = item.parent
    form.status = status
    const result = await api.put(`/resources/admin2/bookStoreGenres/${item.name}`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `상태를 변경하던 중 문제가 발생했습니다.`) }
    alert(`성공적으로 반영하였습니다.`)
    return initialize()
  }

  // 서점 장르 노출 순서 업데이트
  actions.doUpdateBookStoreGenreSortOrder = async (item, sortOrder) => {
    if (!window.confirm(`해당 서점 장르의 순서를 변경할까요?`)) { return false }
    const form = {}
    form.parent = item.parent
    form.sortOrder = sortOrder
    const result = await api.put(`/resources/admin2/bookStoreGenres/${item.name}`, { form })
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `순서를 변경하던 중 문제가 발생했습니다.`) }
    alert(`성공적으로 반영하였습니다.`)
    return initialize()
  }

  // 서점 장르 삭제 기능
  actions.doDeleteBookStoreGenre = async (item = {}) => {
    if (!window.confirm(`이미 담겨있는 사용중인 도서가 존재한다면 문제가 발생할 수 있습니다. 삭제를 진행할까요?`)) { return false }
    const result = await api.delete(`/resources/admin2/bookStoreGenres/${item.name}`)
    if (!result || result.error) { return alert(result && result.message ? `${result.message}` : `삭제를 하던 도중 문제가 발생하였습니다.`) }
    alert(`성공적으로 삭제하였습니다.`)
    return initialize()
  }

  // 서점 장르 정보값을 배치하기
  // React.useState(() => {
  //     const modalProps = { name: 'BookStoreGenreModal', props: { name: 'BookStoreGenreModal', onClose: async () => [setModal(null), await initialize()] } }
  //     setModal(modalProps)
  //   }, [])

  // 장르 커스텀 필터 배치
  const genres = _.get(resources, 'bookStoreGenres.genres') || {}
  const rootGenres = Object.values(genres).filter(o => !o.parent)

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 장르가 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <select
              value={filters.genre}
              onChange={e => {
                const next = { ...filters }
                next['genre'] = e.target.value
                return handle({ 'filters': next, page: 1 }, () => initialize())
              }}
            >
              <option value="all">전체 장르</option>
              {rootGenres.map((genre,  gIdx) => {
                return <option key={gIdx} value={genre.name}>{genre.text}</option>
              })}
              
            </select>
            {/* <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            /> */}
          </div>
        </Header.Search>
        <Header.Options>
          <a href="#doCreateBookStoreGenre" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal()]}>장르 추가</a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={itemIdx}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const meta = {}

                    // 부모코드, 부모명칭, 장르코드, 명칭, 노출상태, 순서변경, 관리 순
                    meta.parent = item.parent
                      ? rootGenres.find(o => [item.parent].includes(o.name))
                      : null
                    meta.parentName = _.get(meta, 'parent.name') || '-'
                    meta.parentText = _.get(meta, 'parent.text') || '🌱'

                    meta.genreName = item.name
                    meta.genreText = item.text
                    meta.statusText = `⚪ 미설정 `
                    if (['activated'].includes(meta.status)) { meta.statusText = `🟢 노출` }
                    if (['deactivated'].includes(meta.status)) { meta.statusText = `🔴 비노출` }
                    meta.sortOrder = item.sortOrder || -1

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            // case 'checkbox':
                            //   return (
                            //     <>
                            //       <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                            //     </>
                            //   )
                            case 'DIdxColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal(item.name)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.dIdx + 1}
                                </div>
                              )
                            
                            
                            // 부모코드
                            case 'ParentNameColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal(item.name)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{meta.parentName || '미지정'}</strong>
                                </div>
                              )
                            
                            // 장르코드
                            case 'GenreNameColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal(item.name)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{meta.genreName || '미지정'}</strong>
                                </div>
                              )
                            
                            // 부모명칭
                            case 'ParentTextColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal(item.name)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{meta.parentText || '미지정'}</strong>
                                </div>
                              )
                            
                            // 장르명칭
                            case 'GenreTextColumn':
                              return (
                                <div
                                  onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenBookStoreGenreModal(item.name)]}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <strong>{meta.genreText || '미지정'}</strong>
                                </div>
                              )

                            // 상태관리
                            case 'StatusColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <select
                                    value={item.status}
                                    style={{ flex: '1 1 100%', marginRight: '0.35rem' }}
                                    onChange={e => {
                                      const value = e.target.value
                                      if (!value) { return alert(`반드시 상태값을 지정해야합니다.`) }
                                      const next = {}
                                      next[`items[${itemIdx}].status`] = value
                                      
                                      handle(next)
                                    }}
                                  >
                                    <option value={``}>미설정</option>
                                    <option value={`activated`}>노출중</option>
                                    <option value={`deactivated`}>노출안함</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateBookStoreGenreStatus(item, item.status)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            
                            // 순서관리
                            case 'SortOrderColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    step={1}
                                    value={item.sortOrder || -1}
                                    style={{ flex: '1 1 100%', marginRight: '0.35rem' }}
                                    onChange={e => {
                                      const value = e.target.value
                                      const next = {}
                                      next[`items[${itemIdx}].sortOrder`] = value
                                      handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateBookStoreGenreSortOrder(item, item.sortOrder)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doDeleteBookStoreGenre(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>서점 장르 정보값이 존재하지 않습니다.</header>
          <section>리소스팩에서 서점 장르 파일이 누락되어 있는지 체크해주세요.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

      {modal && modal.name ? <BookStoreGenreModal {...modal.props} /> : null}
    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),
  resources: PropTypes.object,
  
  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],
  resources: {},

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '서점 장르',
  nav: 'bookStoreGenres'
}

export default List
