import React from 'react'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'

import moment from 'services/moment'

import Modal from 'components/utils/Modal'

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=password],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.65rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`

const buttonStyles = css`
  & div.buttons {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0; margin: 0 -0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.75rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 3px;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s;
 ${controlStyles}
 ${buttonStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
`

Form.Footer = styled.aside`
  position: relative; box-sizing: border-box;
  padding: 1rem;
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem;
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
`

// 새로운 유저를 만드는 기능
class MakeUserByManualModalContainer extends React.Component {
  constructor(props) {
    super(props)
    
    // 초기화 구간
    this.initializedState = {
      form: {
        accountId: '',
        email: '',
        password: ''
      }
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doSave = this.doSave.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    this.setState(JSON.parse(JSON.stringify(this.initializedState)))
  }

  async doSave() {
    const { onClose } = this.props
    const { form } = this.state

    if (!form.accountId) { return alert(`계정명을 입력해주세요.`) }
    if (!form.email) { return alert(`이메일주소를 설정해주세요.`) }
    if (!form.password) { return alert(`비밀번호를 설정해주세요.`) }

    const payload = { form }
    const result = await api
      .post(`/users/admin2/make`, payload)
      .catch(e => null)
    
    alert(result ? `${result.message}` : `오류가 발생하였습니다.`)
    if (result.error) { return false }
    return onClose()
  }

  // 랜더링
  render() {
    const { onClose } = this.props
    const { loading, form } = this.state
    if (loading) { return null }

    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '380px', minWidth: '380px', width: '380px' }}>
          <Form.Header>새 계정 만들기</Form.Header>
          <Form.Grid>

            <Form.Content>
              <Form.Field>
                <div className="control">
                  <input
                    type="text"
                    placeholder="계정명(영문,숫자)"
                    value={form.accountId}
                    onChange={e => {
                      const value = e.target.value
                      const next = { ...form }
                      next.accountId = value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <input
                    type="text"
                    placeholder="이메일 주소"
                    value={form.email}
                    onChange={e => {
                      const value = e.target.value
                      const next = { ...form }
                      next.email = value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <div className="control">
                  <input
                    type="password"
                    placeholder='비밀번호'
                    value={form.password}
                    onChange={e => {
                      const value = e.target.value
                      const next = { ...form }
                      next.password = value
                      return this.setState({ form: next })
                    }}
                  />
                </div>
              </Form.Field>
            </Form.Content>
              
            <Form.Footer>
              <div className="buttons">
                <button
                  type="button"
                  onClick={async (e) => {
                    return [e.stopPropagation(), e.preventDefault(), onClose()]
                  }}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="primary"
                  onClick={async (e) => {
                    return [e.stopPropagation(), e.preventDefault(), await this.doSave()]
                  }}
                >
                  생성
                </button>
              </div>
            </Form.Footer>

          </Form.Grid>
        </Form>
      </Modal>
    )
  }
}

export default MakeUserByManualModalContainer