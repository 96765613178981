import React from 'react'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { comma } from 'services/utils'

import api from 'services/api'
import backdoor from 'services/backdoor'

import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

const { REACT_APP_CLIENT_URL } = process.env

const Item = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  padding: 0.25rem; color: ${palette('muted', 5)};
  background: white; border-radius: 3px;
  border: 1px solid ${palette('muted', 12)};
  margin: 0.75rem 0;
`

Item.Col = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem; color: ${palette('muted', 5)};
  & strong { color: ${palette('muted', 1)}; }
`

Item.Group = styled.div`
  position: relative; box-sizing: border-box;
`

Item.NotFound = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem 1.5rem; 
  border: 1px solid ${palette('muted', 12)};
  background: white; border-radius: 3px;
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1rem 0; font-weight: 700; color: ${palette('muted', 2)};
  }
  & > section {
    position: relative; box-sizing: border-box;
    font-size: 0.8em;
    padding: 1rem 0; color: ${palette('muted', 4)};
  }
`

// 메타정보
const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em; color: ${palette('muted', 4)};
    padding: 0.25rem 0;
  }
  & > section {
    position: relative; box-sizing: border-box;
    & small { color: ${palette('muted', 7)}; }
    padding: 0.35rem;
  }
  & > div.extra {
    position: relative; box-sizing: border-box;
    padding: 0.35rem;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 하위 개체 컴포넌트를 랩핑한다.
const Form = styled.article`
  position: relative; box-sizing: border-box;
`

const controlStyles = css`
  & input[type=text],
  & input[type=number],
  & input[type=date],
  & input[type=time] {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & select {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem 0;
  display: flex; align-items: center;
  & > * { width: 100%; flex: 1 1 100%; min-width: 0; }
  & > div.label { font-size: 0.9em; }
  ${controlStyles}
`

// 입력상자 디자인
Form.Control = styled.div`
  position: relative; box-sizing: border-box;
`

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.fileFormControl = React.createRef(null)
    this.fileInputControl = React.createRef(null)

    this.doOpenWorkModal = this.doOpenWorkModal.bind(this)
    this.doDownloadSelectedFile = this.doDownloadSelectedFile.bind(this)
    this.doReplaceSelectedFile = this.doReplaceSelectedFile.bind(this)
    this.doUploadSelectedFile = this.doUploadSelectedFile.bind(this)
  }

  // 프론트에서 자동으로 로그인 후에 해당 유저의 입장으로 접근할 수 있도록 처리해주기
  async doOpenWorkModal(item = {}, side = 'buyer') {
    if (['buyer'].includes(side)) { return await backdoor.login(item.user, `${REACT_APP_CLIENT_URL}/author/solutionOrders/${item.id}`) }
    if (['seller'].includes(side)) { return await backdoor.login(item.seller, `${REACT_APP_CLIENT_URL}/expert/solutionOrders/${item.id}`) }
    return alert(`입장정보가 없습니다.`)
  }

  // @ 최종작업물을 다운로드 해주는 기능
  async doDownloadSelectedFile(item) {
    const files = item.files || []

    // @ 최종작업물이 이미 있는지 검사
    const selectedFile = files.find((file) => file.selected)
    if (!selectedFile) { return alert(`해당 거래건은 최종 작업물이 존재하지 않습니다.`) }
    
    // @ 최종작업물을 새창으로 올려주기
    const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL
    const url = `${REACT_APP_FILE_URL}/${_.get(selectedFile, `path`)}`
    return window.open(url)
  }
  
  // @ 관리자가 강제로 최종 작업물을 배정해주는 기능
  async doReplaceSelectedFile(item) {
    const that = this
    const files = item.files || []

    // 최종작업물이 이미 있는지 검사
    const selectedFile = files.find((file) => file.selected)
    if (!selectedFile) { return alert(`해당 거래건은 최종 작업물이 존재하지 않습니다.`) }
    
    // @ 최종 작업물을 올리는 기능을 구현하기
    this.fileFormControl.current.reset()
    setTimeout(() => that.fileInputControl.current.click(), 300)
  }

  // @ 파일 업로드 구성하기
  async doUploadSelectedFile(item = {}, e) {
    const file = e.target && e.target.files && e.target.files[0]
    if (!file) { return alert(`파일을 반드시 선택해주셔야 합니다.`) }

    const ret = { error: false, message: '해당 파일로 정상적으로 교체하였습니다.' }
    
    const formData = new FormData()
    formData.append('file', file)

    // @ 파일서버에 업로드해주기
    ret.file = await api.upload(`/orders/files`, formData, { base: 'file' })
      .catch((e) => ({ error: true, message: `파일업로드 에러가 발생하였습니다. (${e.message} ${e.stack})` }))
    if (!ret.file || ret.file.error) { return alert(ret.file.message || '파일업로드 도중 에러가 발생하였습니다.') }
    
    // @ 실디비에 업데이트해주기
    const uploadFile = ret.file.file
    if (uploadFile && uploadFile.path) {
      const files = item.files || [], fIdx = files.findIndex((file) => file.selected)
      ret.order = await api.put(`/pays/admin2/${item.pay}/updateSolutionSelectedFile/${item.id}`, { fIdx, file: uploadFile })
        .catch((e) => ({ error: true, message: e.message }))
      if (!ret.order || ret.order.error) { return alert(ret.order.message || '파일업로드 도중 에러가 발생하였습니다.') }
    } else {
      return alert(`파일서버에 업로드되어야할 파일 정보(path)가 누락되었습니다.`)
    }
    alert(`성공적으로 변경하였습니다.`)
    return window.location.reload()
  }

  render() {
    const { doOpenWorkModal, doDownloadSelectedFile, doReplaceSelectedFile, doUploadSelectedFile } = this
    const { api, parentProps = {} } = this.props

    // 작가서비스 작업단계 상태
    const dealStatusTypes = {
      none: { name: 'none', text: '임시', signal: '⚪' },
      wait: { name: 'wait', text: '대기', signal: '🟡' },
      review: { name: 'review', text: '작업중', signal: '🟢' },
      reject: { name: 'reject', text: '작업거절', signal: '🟠' },
      accept: { name: 'accept', text: '작업완료', signal: '🔵' },
      cancel: { name: 'cancel', text: '작업취소', signal: '⚪' }
    }

    // 환불 타입
    const refundStatusTypes = {}
    refundStatusTypes.none = { name: 'none', text: '해당없음' }
    refundStatusTypes.wait = { name: 'wait', text: '환불신청중' }
    refundStatusTypes.review = { name: 'review', text: '환불 처리중' }
    refundStatusTypes.reject = { name: 'reject', text: '환불거절' }
    refundStatusTypes.accept = { name: 'accept', text: '환불완료' }
    refundStatusTypes.cancel = { name: 'cancel', text: '환불취소' }


    const items = this.props.items || []
    
    if (!items || !items.length) {
      return (
        <Item.NotFound>
          <header>주문서가 존재하지 않습니다. 🌧️</header>
          <section>
            주문서가 이미 삭제되었거나, 존재하지 않은채로<br/>
            결제내역이 생성되었습니다.
          </section>
        </Item.NotFound>
      )
    }

    return (
      <Item.Group>
        {items.map((item, idx) => {
          const extras = item.extras || {}
          
          const meta ={}
          meta.idx = idx + 1
          meta.orderId = item.id

          // 구매자와 제공자 정보를 기재한다.
          meta.user = _.get(item, 'extras.user') || {}
          meta.seller = _.get(item, 'extras.seller') || {}

          // 구매한 상품정보와 주문내역을 불러온다.
          meta.solutionNo = _.get(item, 'content.solutionNo') || '0'
          meta.solutionCategory = _.get(item, 'content.solutionCategory') || { name: 'etc', text: '기타' }
          meta.solutionCategoryText = _.get(item, 'content.solutionCategoryText') || '-'
          meta.bookSize = _.get(item, 'option.text') || '판형정보 없음'
          meta.subject = item.subject || '상품명 없음'
          meta.amount = item.amount || 0
          meta.eachPrice = item.eachPrice || 0
          meta.totalPrice = item.totalPrice || 0

          // @ 최종 작업물 다운로드 위한 기능
          meta.hasWorkedSolution = ['deactivated'].includes(item.status)

          // 해당 상품의 상품번호를 불러온다.
          meta.productId = _.get(item, 'product')
          meta.solutionNo = _.get(item, 'content.solutionNo')

          // 판매상태와 환불상태를 명기한다.
          meta.dealStatus = _.get(dealStatusTypes, `${item.dealStatus}`) || dealStatusTypes.none
          meta.refundApprovals = item.refundApprovals || []
          meta.refundApprovalsCount = item.refundApprovals.length || 0
          meta.refundStatus = _.get(refundStatusTypes, `${item.refundStatus}`) || refundStatusTypes.none

          // 수익금 배정상태에 대한 정보를 구성
          meta.profits = _.get(extras, 'profits') || []
          meta.sumProfitTotal = meta.profits.reduce((s, profit) => s + profit.total * 1, 0)

          // 자식 주문내역이 있는지 체크
          meta.childOrders = _.get(extras, 'childOrders') || []
          meta.childOrdersCount = _.get(meta, 'childOrders.length') || 0
          
          return (
              <Item  key={idx}>
                <Item.Col style={{ minWidth: '80px', maxWidth: '80px' }}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <div style={{ marginBottom: '0.35rem' }}>
                      <strong style={{ fontSize: '2em', fontWeight: 900 }}>{meta.idx}</strong>
                    </div>
                    <div><small>{meta.solutionCategoryText}</small></div>
                    <div><small>{meta.solutionNo}</small></div>
                  </div>
                </Item.Col>
                <Item.Col style={{ flex: '1 1 100%', wordBreak: 'break-all' }}>
                  <div style={{ width: '100%' }}>

                    <div style={{ marginBottom: '0.35rem' }}>
                      <div
                        style={{ cursor: 'pointer', paddingBottom: '0.65rem' }}
                        onClick={e => {
                          if (!meta.solutionNo) { return alert(`작가서비스 번호가 존재하지 않는 주문입니다.`) }
                            return window.open(`/products/solution?keywordsOption=solutionNo&keywords=${meta.solutionNo}`)
                        }}
                      >
                        {['textDesign'].includes(_.get(meta, 'solutionCategory.name'))
                          ? (<strong style={{ fontSize: '1.2em', fontWeight: 900, color: '#111' }}>[{meta.bookSize}] {meta.subject}</strong>)
                          : null}
                        {!['textDesign'].includes(_.get(meta, 'solutionCategory.name'))
                          ? (<strong style={{ fontSize: '1.2em', fontWeight: 900, color: '#111' }}>{meta.subject}</strong>)
                          : null}
                        
                        </div>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                      <strong style={{ fontSize: '1.1em', fontWeight: 900 }}>{comma(meta.eachPrice)}</strong>
                      <small style={{ marginLeft: '0.25rem' }}>원</small>
                      <small style={{ marginLeft: '0.235rem' }}>({comma(meta.amount)}개)</small>
                    </div>

                    <Meta.Group>
                      {/* 구매자 정보 */}
                      <Meta style={{ borderRight: '1px dashed #ccc' }}>
                        <section>
                          <small style={{ marginRight: '0.75rem' }}>
                            <span style={{ marginRight: '0.35rem' }}>👨‍💻 구매자</span>
                            <small style={{ marginRight: '0.35rem' }}>{_.get(meta.user, 'userNo') || '0'}</small>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={async (e) => {
                                return [e.stopPropagation(), e.preventDefault(), await doOpenWorkModal(item, 'buyer')]
                              }}
                            >
                                    🔐
                            </span>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <strong>{_.get(meta.user, 'accountId') || '계정명 없음'}</strong>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <small>{_.get(meta.user, 'email') || '이메일 미기재'}</small>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <strong>{_.get(meta.user, 'mobile') || '연락처 미기재'}</strong>
                          </small>
                        </section>
                      </Meta>
                      
                      {/* 제공자 정보 */}
                      <Meta>
                        <section style={{ paddingLeft: '0.75rem'  }}>
                          <small style={{ marginRight: '0.75rem' }}>
                            <span style={{ marginRight: '0.35rem' }}>🧑‍🎨 제공자</span>
                            <small style={{ marginRight: '0.35rem' }}>{_.get(meta.seller, 'userNo') || '0'}</small>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={async (e) => {
                                return [e.stopPropagation(), e.preventDefault(), await doOpenWorkModal(item, 'seller')]
                              }}
                            >
                                    🔐
                            </span>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <strong>{_.get(meta.seller, 'accountId') || '계정명 없음'}</strong>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <small>{_.get(meta.seller, 'email') || '이메일 미기재'}</small>
                          </small>
                          <br />
                          <small style={{ marginRight: '0.75rem' }}>
                            <strong>{_.get(meta.seller, 'mobile') || '연락처 미기재'}</strong>
                          </small>
                        </section>
                      </Meta>
                        
                    </Meta.Group>

                    {meta.hasWorkedSolution
                      ? (
                        <div style={{ padding: '0.6rem 0', marginTop: '0.5rem', borderTop: '1px dashed #eee' }}>
                          <span style={{ marginRight: '0.65rem' }}>
                            <span style={{ marginRight: '0.25rem' }}>최종작업물</span>
                            <a
                              href="#download"
                              style={{ color: '#111', fontWeight: 900, textDecoration: 'none' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                return doDownloadSelectedFile(item)
                              }}
                            >
                              다운로드
                            </a>
                          </span>
                          <span>
                            <span style={{ marginRight: '0.25rem' }}>최종작업물</span>
                            <a
                              href="#upload"
                              style={{ color: '#111', fontWeight: 900, textDecoration: 'none' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                return doReplaceSelectedFile(item)
                              }}
                            >
                              변경
                            </a>
                          </span>
                        </div>
                      )
                      : null}

                    <div style={{ padding: '0.35rem 0', borderTop: '1px dashed #eee' }}>
                      <small
                        style={{ marginRight: '0.75rem', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          
                          const query = {}
                          query.keywords = item.id
                          query.keywordsOption = 'orderId'
                          const url = `/profits/deposits?${qs.stringify(query)}`
                          return window.open(url)
                        }}
                      >
                        <span style={{ marginRight: '0.25rem' }}>배정된 수익금</span>
                        <strong style={{ marginRight: '0.15rem' }}>{comma(meta.sumProfitTotal)}원</strong>
                        <small>({comma(_.get(meta, 'profits.length'))}건)</small>
                      </small>
                      
                      <small
                        style={{ marginRight: '0.75rem', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                                
                          const query = {}
                          query.keywords = item.parent
                          query.keywordsOption = 'orderId'
                          const url = `/pays/etcSolutionWork?${qs.stringify(query)}`
                          return window.open(url)
                        }}
                      >
                        <span style={{ marginRight: '0.25rem' }}>👨‍👩‍👦 하위 추가작업비</span>
                        <strong style={{ marginRight: '0.15rem' }}>{comma(meta.childOrdersCount)}건</strong>
                      </small>
                    </div>

                    <form ref={this.fileFormControl} style={{ display: 'none' }}>
                      <input ref={this.fileInputControl} type="file" onChange={(e) => doUploadSelectedFile(item, e)} />
                    </form>

                  </div>
                </Item.Col>
              </Item>
          )
        })}
      </Item.Group>
    )
  }
}

export default OrdersContainer
