import styled from 'styled-components'
import { font, palette } from 'styled-theme'

export const Wrapper = styled.div`
`

export const Text = styled.a`
  display: block; box-sizing: border-box; letter-spacing: -0.1rem; text-decoration: none;
  font-family: ${font('primary')}; font-weight: 900; font-size: 1.4em; color: ${palette('muted', 1)};
`
