import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class UserDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() {}
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentUser = extras.currentUser || {}

    const meta = {}
    meta.name = currentUser.name || '실명 미기재'
    meta.mobile = currentUser.mobile || '00000000000'
    meta.email = currentUser.email || '이메일 없음'
    meta.userId = currentUser.id || null
    meta.accountId = currentUser.accountId || '닉네임 없음'
    meta.email = currentUser.email || '닉네임 없음'

    return (
      <Column>
        <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
          <section
            style={{ cursor: 'pointer' }}
            onClick={e => {
              if (!meta.userId) { return alert(`해당 신청건은 회원정보가 없는 상태입니다.`) }
              const url = `/users/${meta.userId}`
              return window.open(url)
            }}
          >
            <strong>{meta.accountId}</strong>
          </section>
          <section
            style={{ cursor: 'pointer' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              await navigator.clipboard.writeText(`${meta.email || ''}`)
            }}
          >
            <small>{meta.email}</small>
          </section>
        </div>
      </Column>
    )
  }
}

UserDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

UserDetailColumnContainer.defaultProps = {
  item: {}
}

export default UserDetailColumnContainer
