import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import List from './List'

const constructors = {}

constructors.common = (that = {}) => {
  const curAt = new Date(), curMt = moment(curAt).startOf('month').add(1, 'months')
  // 와치 처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'all', basedAt: curMt.toDate(), profitType: 'all' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-withdrawAmount'] }
    if (watch.prop === 'keywordsOptions') { watch.defaultProp = 'accountId' }
    return watch
  })

  that.sortOptions.withdrawAmount = { name: 'withdrawAmount', text: '대상액수', multiple: true, asc: { text: '오름차순' }, desc: { text: '내림차순' } }
  
  // 커워드 셋팅
  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' }
  that.keywordsOptions.userNo = { name: 'userNo', text: '회원번호' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
}

// 대상자 목록
constructors.users = (that = {}) => {
  
  // that.filterOptions.status = { name: 'status', text: '구분', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'custom' }

  constructors.common(that)
}

class ProfitUserListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['users']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadScheduler = this.loadScheduler.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, message: '', withdrawEnabledUserIds: [], items: [], scheduler: {}, total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  async loadScheduler() {
    const scheduler = await api.get(`/profits/admin2/scheduler`).catch((e) => ({}))
    return new Promise((r) => this.setState({ scheduler }, r))
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const {
      withdrawEnabledUserIds,
      filters, sorts, keywordsOption, keywords, page, limit
    } = this.state

    const query = {}
    query.usedCount = true
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const payload = { withdrawEnabledUserIds }
    const output = await api
      .get(`/profits/admin2/schedules?${qs.stringify(query)}`, payload, { signal: this.abortController.signal })
      .then(({ count, rows, total }) => ({ rows, count, sumTotal: total, error: false }))
      .catch(e => ({ rows: [], count: 0, sumTotal: 0, error: e.message }))

    const { count, rows, sumTotal, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), sumTotal: sumTotal, total: count, loading: false, error, message: '' })
  }

  // 데이터 불러오기
  async initialize() {
    await this.loadScheduler()
    await this.loadItems()
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return null }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const { keywordsOption, keywords, filters, sorts, sumTotal, total, scheduler, items, page, limit, blockLimit, selecteds } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, items, selecteds,
      scheduler,
      sumTotal, total, page, limit, blockLimit,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    return <List {...listProps} />
  }
}

ProfitUserListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string)
}

ProfitUserListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},

  keywordsOption: 'accountId',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '지급가능',
  nav: 'users',

  filters: null,
  sorts: null
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(ProfitUserListContainer))
