import React from 'react'

import _ from 'lodash'
import api, { REACT_APP_FILE_URL } from 'services/api'

import { comma } from 'services/utils'
import moment from 'services/moment'

import { DataSet, Box } from './utils'

const List = ({ ...props }) => {
  const { count, items, handle } = props

  const columns = {}

  columns.IndexColumn = { name: 'IndexColumn', style: { width: '100px', minWidth: '100px', maxWidth: '100px', textAlign: 'center' } }
  columns.payNoColumn = { name: 'payNoColumn', style: { width: '180px', minWidth: '180px', maxWidth: '180px', textAlign: 'center' } }
  columns.buyerColumn = { name: 'buyerColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.productColumn = { name: 'productColumn', style: { minWidth: '300px', maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }
  columns.priceColumn = { name: 'priceColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.receiverColumn = { name: 'receiverColumn', style: { width: '160px', minWidth: '160px', maxWidth: '160px', textAlign: 'center' } }
  columns.paidAtColumn = { name: 'paidAtColumn', style: { width: '180px', minWidth: '180px', maxWidth: '180px', borderRight: 0, textAlign: 'center' } }

  return (
    <DataSet>
      <DataSet.Header>
        <DataSet.Item>
          <DataSet.Col className="desktop" style={columns.IndexColumn.style}>순번</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.payNoColumn.style}>주문번호</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.buyerColumn.style}>주문자</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.productColumn.style}>상품정보</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.priceColumn.style}>금액</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.receiverColumn.style}>수취인</DataSet.Col>
          <DataSet.Col className="desktop" style={columns.paidAtColumn.style}>수령일자</DataSet.Col>

          <DataSet.Col className="mobile">포장작업</DataSet.Col>
        </DataSet.Item>
      </DataSet.Header>

      <DataSet.Body>
        {!count
          ? (
            <DataSet.NotFound>
              <header>🏖️ 포장중 데이터가 존재하지 않습니다.</header>
              <section>포장중 단계로 넘긴 발송내역이 있다면 해당 유통사 기준으로 표현됩니다.<br/>이미 처리를 완료했거나, 현재는 존재하지 않습니다.</section>
            </DataSet.NotFound>
          )
          : null}
          
        {items.map((item, itemIdx) => {
          
          const extras = item.extras || {}
          const pay = _.get(extras, 'pays[0]') || {}
          const user = _.get(extras, 'user') || {}
          const firstProduct = extras.firstProduct || {}
          const quantitys = _.get(extras, 'quantitys') || {}
          const stockStorages = _.get(extras, 'stockStorages') || {}

          // 개별 정보 기준 처리 내용
          const meta = {}
          
          meta.idx = itemIdx + 1 // 순번 정보
          meta.payNo = _.get(pay, 'payNo') || '-' // 순번 정보
          meta.userAccountId = _.get(user, 'accountId') || '-' // 결제자명
          meta.buyerName = _.get(pay, 'buyer.name') || '-' // 결제자명
          meta.packageName = _.get(item, 'name') || _.get(pay, 'subject') || '정보없음' // 결제자명
          meta.receiverName = _.get(item, 'receiver') || _.get(pay, 'delivery.receiver') || '-' // 수취인명
          meta.payTotalPrice = _.get(pay, 'totalPrice') || 0 // 총 결제금액

          meta.thumbnailPath = _.get(firstProduct, 'content.thumbnail.path')
          meta.thumbnail = meta.thumbnailPath ? `${REACT_APP_FILE_URL}/${meta.thumbnailPath}` : `/sample.book.png`
          
          // 정렬기준 처리
          meta.sorted = _.get(item, '_sorted') || {}

          // @ 보유한 내역 중 가장 최근 수령일
          meta.lastQuantityAt = _.get(meta, 'sorted.lastQuantity.createdAt')
          meta.lastQuantityMt = meta.lastQuantityAt ? moment(meta.lastQuantityAt) : null

          return (
            <DataSet.Item
              key={itemIdx}
              cursor={`true`}
              onClick={async (e) => {
                return await handle({ viewport: 'item', item, currentIdx: itemIdx+1 })
              }}
            >
              <DataSet.Col className="desktop" style={columns.IndexColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  <strong>{meta.idx}</strong>
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.payNoColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  {meta.payNo}
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.buyerColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  {meta.buyerName}
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.productColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  <strong>{meta.packageName}</strong>
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.priceColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  <strong>{comma(meta.payTotalPrice)}</strong>
                  <small style={{ marginLeft: '0.35rem' }}>원</small>
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.receiverColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                  <strong>{meta.receiverName}</strong>
                </div>
              </DataSet.Col>
              <DataSet.Col className="desktop" style={columns.paidAtColumn.style}>
                <div style={{ minWidth: 'fit-content', whiteSpace: 'normal', textOverflow: 'hidden', wordBreak: 'break-all'}}>
                    <strong style={{ marginRight: '0.25rem' }}>{meta.lastQuantityMt ? meta.lastQuantityMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                    <small>{meta.lastQuantityMt ? meta.lastQuantityMt.format('HH:mm') : '00:00'}</small>
                </div>
              </DataSet.Col>

              <DataSet.Col className="mobile" style={{ flex: '1 1 100%', maxWidth: '100%', overflow: 'hidden' }}>
                <Box>
                  <div className="thumbnail">
                    <img
                      src={meta.thumbnail}
                      onError={e => e.target.src = '/sample.book.png' }
                      style={{ width: '50px' }}
                    />
                  </div>
                  <div className="thumbnail">
                    <div style={{ marginBottom: '0.35rem' }}>
                      <strong style={{ marginRight: '0.25rem' }}>{meta.idx}번</strong>
                      <span style={{ marginRight: '0.25rem' }}>주문</span>
                      <strong style={{ marginRight: '0.25rem' }}>{meta.payNo}</strong>
                      <span style={{ marginRight: '0.25rem' }}>·</span>
                      <strong>{meta.userAccountId}</strong>
                    </div>
                    <div style={{ marginBottom: '0.35rem', whiteSpace: 'wrap', maxWidth: '200px', width: '100%', overflow: 'hidden' }}>
                      <strong style={{ fontSize: '1.2em', fontWeight: 900 }}>{meta.packageName}</strong>
                    </div>
                    <div>
                      <strong>{comma(meta.payTotalPrice)}</strong>
                      <span style={{ marginRight: '0.25rem' }}>원</span>
                      <span style={{ marginRight: '0.25rem' }}>·</span>
                      <strong style={{ marginRight: '0.25rem' }}>{meta.lastQuantityMt ? meta.lastQuantityMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                      <small>{meta.lastQuantityMt ? meta.lastQuantityMt.format('HH:mm') : '00:00'}</small>
                    </div>
                  </div>
                </Box>
              </DataSet.Col>
            </DataSet.Item>
          )
        })}
      </DataSet.Body>
    </DataSet>
  )
}

export default List