import styled from 'styled-components'
import { font, palette, size } from 'styled-theme'

const DialogSet = styled.section`
  position: relative; box-sizing: border-box; display: flex;
  background: ${palette('muted', 20)}; min-width: 1600px; z-index: 2;
`

DialogSet.Aside = styled.aside`
  position: relative; box-sizing: border-box;
  min-width: 320px; max-width: 320px; 
  background: ${palette('muted', 20)};
  padding: 0rem 1rem; z-index: 1;
`

DialogSet.Main = styled.main`
  position: relative; box-sizing: border-box;
  background: ${palette('muted', 20)};
  flex: 1 1 100%; padding: 1rem; padding-bottom: 1rem; z-index: 1;
`

DialogSet.Cover = styled.section`
  position: absolute; box-sizing: border-box;
  left: 0; top: 0; width: 100%; height: 100%; 
  background: ${palette('muted', 1)}; opacity: 0.5; z-index: 3;
`

DialogSet.Sider = styled.aside`
  position: absolute; box-sizing: border-box; right: 0; top: 0; 
  min-width: 320px; max-width: 320px;  height: 100%;
  background: ${palette('muted', 21)}; box-shadow: 0 5px 5px ${palette('muted', 12)};
  padding: 0rem 1rem; z-index: 5;
`

DialogSet.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-start; align-items: center;
  padding: 0.5rem; margin: 0 -0.5rem;
`

DialogSet.Button = styled.a`
  position: relative; box-sizing: border-box; font-weight: 700;
  display: block; padding: 0.5rem 0; text-decoration: none;
  flex: 1 1 100%; text-align: center; transition: all 0.3s; cursor: pointer;
  background: ${palette('muted', 3)}; color: ${palette('muted', 18)};
  &:hover { color: ${palette('muted', 21)}; background: ${palette('muted', 1)}; }
  &:first-child { border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
  &:last-child { border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
`

DialogSet.Header = styled.header`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center; padding: 1rem 0;
`

DialogSet.Header.Lead = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; transition: all 0.3s;
  border: 0; color: ${palette('muted', 1)}; font-family: ${font('primary')};
  font-size: 1.2em; font-weight: 700; letter-spacing: -0.1rem;
`

DialogSet.Header.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; min-width: max-content;
`

DialogSet.Header.Drawer = styled.div`
  display: none; box-sizing: border-box; position: absolute; right: 0; top: 99.99%;
  border-radius: 5px; box-shadow: 0 0 5px ${palette('muted', 15)}; font-size: 13px; text-decoration: none; min-width: 180px; z-index: 99;
  &:hover { color: ${palette('darkblue', 3)}; }
  & > a {
    display: block; padding: 0.75rem 1rem; text-decoration: none; cursor: pointer;
    color: ${palette('muted', 5)}; background: ${palette('muted', 20)};
    &:hover { color: ${palette('muted', 20)}; background: ${palette('muted', 1)}; font-weight: 700; }
  }
`

DialogSet.Header.Button = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 1.5em; padding: 0.5rem; text-decoration: none;
  color: ${palette('muted', 5)}; font-weight: 700; cursor: pointer;
  &:hover {
    color: ${palette('darkblue', 3)};
    & > ${DialogSet.Header.Drawer} { display: block; }
  }
  &.active {
    &::after {
      content: ''; position: absolute; right: 5px; bottom: 5px;
      background: ${palette('primary', 14)}; width: 10px; height: 10px; border-radius: 50%;
    }
  }
`

export default DialogSet
