// 수동모달
import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'
import { enabledAutoChecks } from 'services/constants/retail'

import Modal from 'components/utils/Modal'

const Box = styled.div`
  position: relative; box-sizing: border-box;
   max-width: 760px; min-width: 760px;
   padding: 2.618rem;
  @media screen and (max-width: 980px) {
    min-width: 90%;
  }
`

const Progress = styled.div`
  position: relative; box-sizing: border-box;
  border-radius: 0.2618rem; background: #f1f1f1;
  margin: 1rem 0; transition: all 0.3s;
`

Progress.Bar = styled.div`
  position: relative; box-sizing: border-box;
  border-radius: 0.2618rem; transition: all 0.3s;
  background: #111; height: 1rem;
  &:hover { background: #444; }
`

const Rate = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 6em; line-height: 1.3618em; font-weight: 900;
`

const Amount = styled.div`
  position: relative; box-sizing: border-box;
  font-size: 1.6em; line-height: 1.3618em;
  padding: 1rem 0; margin-bottom: 3rem;
`

// @ 프로퍼티로 retail, classify, items 를 반드시 받아와야 함.
class AcceptCheckBookRetailModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {
      loading: true, message: '', rate: 0, idx: 0
    }

    this.initialize = this.initialize.bind(this)
    this.checkStatus = this.checkStatus.bind(this)
    this.updateAcceptApproval = this.updateAcceptApproval.bind(this)
    this.doExecute = this.doExecute.bind(this)
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() {
    const { retail, classify, items } = this.props
    if (!enabledAutoChecks.includes(retail)) {
      return await new Promise((r) => 
        this.setState({ 
          loading: false, 
          message: '자동 입점 체크가 불가능한 입점사입니다. 수동으로 엑셀 업로드를 통해서 상태값을 전환해주세요.'
        }, r))
    }
    await new Promise((r) => this.setState({ loading: false }, r))
    await this.doExecute(classify, items)
  }

  // @ 유통사 내용을 기준으로 점검하는 기능
  async checkStatus(item) {
    const { retail } = this.props
    const bookkIsbn = _.get(item, 'extras.currentBook.isbn')
    const retailIsbns = _.get(item, 'extras.currentBook.retailIsbns') || []
    let isbn = bookkIsbn
    if (!isbn && ['kyobo'].includes(retail)) {
      const kyboIsbn = retailIsbns.find(i => i.name === 'kyobo')
      if (!kyboIsbn) { return { error: true, message: '교보ISBN이 배정되지 않은 도서입니다.' } }
      isbn = kyboIsbn.isbn
    }
    isbn = isbn ? isbn.replace(/[^0-9]/ig, '') : ''
    if (!isbn) { return { error: true, message: '대질할 ISBN이 존재하지 않습니다.' } }

    const result = await api.get(`/tasks/retailStatus/${retail}/${isbn}`)
      .catch((e) => ({ error: true, message: `체크도중 오류가 발생하였습니다. ${e.message} ${e.stack}` }))
    return result
  }

  // @ 업데이트할지말지 결정하는 함수
  async updateAcceptApproval(item = {}) {
    const { classify } = this.props
    if (!['bookRetail', 'bookStopRetail'].includes(classify)) { return { error: true, message: '처리가 되지 않았습니다.' } }
    if (item.classify !== classify) { return { error: true, message: '현재 처리방식과 문서 처리방식이 일치 하지 않습니다.' } }

    const comment = []
    if (['bookRetail'].includes(classify)) { comment.push(`축하합니다. 외부유통 입점이 완료되었습니다.`) }
    if (['bookStopRetail'].includes(classify)) { comment.push(`외부유통 판매중지 처리가 완료되었습니다.`) }
    const result = await api.put(`/approvals/admin2/${item.id}/${classify}/accept`, { comment: comment.join('') })
      .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
    return result
  }

  // @ 문서에 명시된 상품과 ISBN을 조사해오기
  async doExecute(classify, items = []) {
    if (!items.length) {
      return that.setState({ message: `😨 처리할 데이터가 없습니다.`, idx: 0, rate: 0 })
    }
    const that = this
    const classifyType = ['bookStopRetail'].includes(classify) ? `퇴점체크` : `입점체크`
    const result = await items.reduce((prev, item, idx, arr) => 
      prev.then(async (report) => {
        const bookNo = _.get(item, 'content.bookNo')
        const rate = ((idx + 1)/items.length) * 100

        const feedback = await that.checkStatus(item)
          .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))

        const result = feedback // { error: false, message: '처리를 완료하였습니다.' }
        if (['bookRetail'].includes(classify) && ['activated'].includes(feedback.status)) {
          const updated = await that.updateAcceptApproval(item)
            .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
          result.error = updated.error
          result.message = updated.message
        }
        if (['bookStopRetail'].includes(classify) && ['deactivated'].includes(feedback.status)) {
          const updated = await that.updateAcceptApproval(item)
           .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
          result.error = updated.error
          result.message = updated.message
        }

        if (result.error) {
          that.setState({ message: `👀 도서번호 ${bookNo || 0}번 : ${classifyType} Error ${result.message} `, idx, rate })
        } else {
          that.setState({ message: `👀 도서번호 ${bookNo || 0}번 : ${classifyType} OK!`, idx, rate })
        }


        report.push({ error: false, message: '처리완료', item })
        await new Promise((r) => setTimeout(r, 600))
        return report
      }),
      Promise.resolve([]))
    .then((results) => {
      const successCount = results.filter(r => !r.error).length
      const failureCount = results.filter(r => r.error).length
      return ({ error: false, message: `🙋‍♂️ 처리를 완료하였습니다. (성공 ${successCount}건, 실패 ${failureCount}건)` })
    })
    .catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))

    that.setState({ message: result.message })
  }

  render() {
    const { classify, retail, items, onClose } = this.props
    const classifyType = ['bookStopRetail'].includes(classify) ? `퇴점체크` : `입점체크`
    const { loading, message, idx, rate } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Box>
          <div>
            <strong style={{ fontWeight: 900 }}>{`${retail}`.toUpperCase()} {classifyType}</strong>
          </div>
          <Rate>
            <span style={{ textDecoration: 'underline' }}>{rate.toFixed(0)}</span>
            <span>%</span>
          </Rate>
          <Amount>
            <span style={{ margin: '0 0.5rem' }}>현재</span>
            <strong style={{ fontWeight: 900 }}>{idx + 1}</strong>
            <small style={{ margin: '0 0.5rem', opacity: 0.1 }}>/</small>
            <strong style={{ margin: '0 0.5rem', fontWeight: 900 }}>{items.length}</strong>
            <small>건</small>
          </Amount>
          
          <div>{message}</div>
          <Progress>
            <Progress.Bar style={{ width: `${rate}%` }} />
          </Progress>
        </Box>
      </Modal>
    )
  }
}

export default AcceptCheckBookRetailModalContainer