import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layouts/Page'
import NotFound from 'components/utils/NotFound'

class NotFoundPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { admin, history, location, match, nav } = this.props
    const commonProps = { admin, history, location, match }
    const pageProps = { ...commonProps, nav }
    return (
      <Page>
        <NotFound {...pageProps} />
      </Page>
    )
  }
}

NotFoundPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,

  nav: PropTypes.string,
}

NotFoundPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},

  nav: '',
}

export default NotFoundPageContainer
