import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Pager, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'DateColumn', header: '생성일', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'UserColumn', header: '계정정보', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'TypeColumn', header: '상품종류', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProductDetailColumn', header: '상품명', style: { minWidth: '460px', maxWidth: '460px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'EachPriceColumn', header: '개당 단가', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center', borderRight: 0 } })
  columns.push({ key: 'AmountColumn', header: '수량', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'TotalPriceColumn', header: '구매금액', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: 0, maxWidth: '100%' } })
  columns.push({ key: 'ControlColumn', header: '삭제', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)
  
  // 상품 타입에 대한 설정
  const cartTypes = {}
  cartTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  cartTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  cartTypes.solution = { name: 'solution', text: '작가서비스' }
  cartTypes.etc = { name: 'etc', text: '기타' }

  // 로우 작동 액션들
  const actions = {}

  // 장바구니 업데이트 기능
  actions.doUpdateCart = async (item = {}) => {
    if (!window.confirm(`장바구니 정보를 변경할까요?`)) { return }
    const form = {}
    form.eachPrice = item.eachPrice
    form.amount = item.amount
    form.totalPrice = item.totalPrice
    const result = await api.put(`/activities/admin2/carts/${item.id}`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // 장바구니 삭제기능
  actions.doDeleteCart = async (item ={}) => {
    if (!window.confirm(`장바구니를 삭제할까요?`)) { return }
    const result = await api.delete(`/activities/admin2/carts/${item.id}`).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 장바구니가 검색되었습니다.</Task>
      
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
          {/* <a href="#doRefreshInvoices" onClick={e => [e.stopPropagation(), e.preventDefault()]}>새로 만들기</a> */}
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}

                    const meta = {}

                    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
                    meta.cartType = _.get(cartTypes, item.cartType)
                      ? _.get(cartTypes, item.cartType)
                      : cartTypes.etc
                    meta.userAccountId = _.get(extras, 'currentUser.accountId')
                      ? _.get(extras, 'currentUser.accountId')
                      : ''
                    meta.productIdentity = _.get(extras, 'currentProduct.productNo')
                      ? _.get(extras, 'currentProduct.productNo')
                      : item.product
                    meta.productName = _.get(extras, 'currentProduct.productName') || item.subject

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )

                            case 'DateColumn':
                              return (
                                <div>
                                  <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )

                            case 'TypeColumn':
                              return (
                                <div>
                                  <strong>{_.get(meta, 'cartType.text') || '알수없음'}</strong>
                                </div>
                              )
                            case 'UserColumn':
                              return (
                                <div>
                                  <strong>{meta.userAccountId || '-'}</strong>
                                </div>
                              )
                            case 'ProductDetailColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (!item.product) { return alert(`상품정보가 존재하지 않는 장바구니입니다.`) }
                                    const url  = `/products/${item.cartType}/${item.product}`
                                    return window.open(url)
                                  }}
                                >
                                  <strong>{meta.productName || '알수없음'}</strong>
                                </div>
                              )
                            case 'EachPriceColumn':
                              return (
                                <div>
                                  <input
                                    type="number"
                                    value={item.eachPrice || ''}
                                    placeholder={`0`}
                                    onChange={e => {
                                      const next ={}
                                      next[`items[${itemIdx}].eachPrice`] = e.target.value
                                      return handle(next)
                                    }}
                                    onBlur={e => {
                                      const next ={}
                                      next[`items[${itemIdx}].eachPrice`] = _.isInteger(item.eachPrice * 1) ? item.eachPrice  * 1 : 0
                                      if (next[`items[${itemIdx}].eachPrice`] <= 0) { next[`items[${itemIdx}].eachPrice`] = 0 }
                                      next[`items[${itemIdx}].totalPrice`] = next[`items[${itemIdx}].eachPrice`] * item.amount
                                      return handle(next)
                                    }}
                                  />
                                </div>
                              )
                            case 'AmountColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    value={item.amount || ''}
                                    style={{ marginRight: '0.35rem' }}
                                    placeholder={`0`}
                                    onChange={e => {
                                      const next ={}
                                      next[`items[${itemIdx}].amount`] = e.target.value
                                      if (['electronicBook'].includes(item.cartType)) { next[`items[${itemIdx}].amount`] = 1 }
                                      return handle(next)
                                    }}
                                    onBlur={e => {
                                      const next ={}
                                      next[`items[${itemIdx}].amount`] = _.isInteger(item.amount * 1) ? item.amount  * 1 : 1
                                      if (next[`items[${itemIdx}].amount`] <= 0) { next[`items[${itemIdx}].amount`] = 1 }
                                      if (['electronicBook'].includes(item.cartType)) { next[`items[${itemIdx}].amount`] = 1 }
                                      next[`items[${itemIdx}].totalPrice`] = item.eachPrice * next[`items[${itemIdx}].amount`]
                                      return handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doUpdateCart(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>
                              )
                            case 'TotalPriceColumn':
                              return (
                                <div>
                                  <strong>{comma(item.totalPrice)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>원</small>
                                </div>
                              )
                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doDeleteCart(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>장바구니가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '장바구니 관리',
  nav: 'carts'
}

export default List
