const parser = {}

parser.columns = [
  { header: '', key: 'a', view: (item) => '' }, // A
  { header: '', key: 'b', view: (item) => '' }, // B
  { header: '', key: 'c', view: (item) => '' }, // C
  { header: '', key: 'd', view: (item) => '' }, // D
  { header: '', key: 'e', view: (item) => '' }, // E
  { header: '', key: 'f', view: (item) => '' }, // F
  { header: '', key: 'g', view: (item) => '' }, // G
  { header: '', key: 'h', view: (item) => '' }, // H
  { header: 'ISBN', key: 'isbn', view: (item) => item ? item.isbn : '' }, // I
  { header: '', key: 'j', view: (item) => '' }, // J
  { header: '', key: 'k', view: (item) => '' }, // K
  { header: '', key: 'l', view: (item) => '' }, // L
  { header: '', key: 'm', view: (item) => '' }, // M
  { header: '', key: 'n', view: (item) => '' }, // N
  { header: '', key: 'o', view: (item) => '' }, // O
  { header: '', key: 'p', view: (item) => '' }, // P
  { header: '', key: 'q', view: (item) => '' }, // Q
  { header: '', key: 'r', view: (item) => '' }, // R
  { header: '', key: 's', view: (item) => '' }, // S
  { header: '발주량', key: 't', view: (item) => item ? item.amount : 0 }, // T
]

export default parser
