import styled from 'styled-components'
import { palette, font } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import Logo from 'components/utils/Logo'
import FormSet from 'components/utils/FormSet'
export { Lnr, Logo, FormSet }

export const Wrapper = styled.article`
  position: relative; box-sizing: border-box;
  width: 340px; max-width: 340px; margin: 8em auto;
`

export const Signin = styled.section`

`

Signin.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
`

Signin.Body = styled.main`
  position: relative; box-sizing: border-box;
  padding: 1rem 0;
`

Signin.Footer = styled.footer`
  position: relative; box-sizing: border-box; padding: 1rem 0;
  & > div.copyright {
    display: block; position: relative; box-sizing: border-box; text-align: right;
    font-family: ${font('primary')}; color: ${palette('muted', 9)}; font-size: 0.9em; letter-spacing: -0.05rem;
    & > strong { color: ${palette('muted', 1)};}
  }
`

Signin.Form = styled.div`
  position: relative; box-sizing: border-box;
  padding-bottom: 1rem;
`

Signin.Field = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.25rem 0;
  & > div.group {
    position: relative; box-sizing: border-box;
    display: flex; justify-content: space-between; align-items: center;
    & > label {
      font-family: ${font('primary')};
      display: block; padding-right: 1rem; letter-spacing: -0.05rem;
      min-width: 100px; max-width: 100px; width: 100px;
      position: relative; box-sizing: border-box;
      color: ${palette('muted', 3)};
      padding: 0.25rem 0; font-weight: 700;
      & > small { color: ${palette('muted', 6)}; font-weight: 300; }
    }
    & > input[type=password], & > input[type=text] { 
      font-family: ${font('primary')};
      display: block; border-radius: 5px; letter-spacing: -0.05rem;
      width: 100%; outline: none; color: ${palette('muted', 9)};
      background: ${palette('muted', 18)}; border: 0;
      padding: 0.75rem 0.75rem;
      position: relative; box-sizing: border-box;
      &:hover { box-shadow: 0 0 5px ${palette('muted', 12)}; }
      &:focus {
        background: ${palette('muted', 21)}; font-weight: 700; color: ${palette('muted', 2)};
        box-shadow: 0 0 5px ${palette('muted', 12)}; transition: 0.6s all; transition-delay: 0.3s;
      }
    }
  }
`

Signin.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0;
  button {
    font-family: ${font('primary')};
    display: flex;  justify-content: space-between; align-items: center;
    border-radius: 5px; letter-spacing: -0.05rem; cursor: pointer;
    width: 100%; outline: none; color: ${palette('muted', 19)};
    background: ${palette('muted', 3)}; border: 0; font-weight: 700;
    padding: 1.15rem 1.75rem; font-size: 1.614em;
    position: relative; box-sizing: border-box;
    &:hover {
      background: ${palette('muted', 1)}; color: ${palette('muted', 21)};
      box-shadow: 0 0 5px ${palette('muted', 12)}; transition: 0.6s all; transition-delay: 0.3s;
    }
  }
`

export default {
  Wrapper, Signin,
  Lnr, Logo, FormSet
}
