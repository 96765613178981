import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

// 환불 컬럼을 구성한다. 
const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

// 환불 신청명 및 사유 내용(사유내용은 명시하지 않게) @2023.02.23
class RefundDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {}

  componentDidMount() {
    this.initialize()
  }

  componentWillMount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item } = this.props

    const extras = item.extras || {}
    const currentPay = extras.currentPay || {}

    // 타입 설정부 구성완료
    const payTypes = {}
    payTypes.paperBook = { name: 'paperBook', text: '종이도서' }
    payTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
    payTypes.solution = { name: 'solution', text: '작가서비스' }
    payTypes.etc = { name: 'etc', text: '기타' }

    const meta = {}
    meta.payId = currentPay.id || null
    meta.payNo = currentPay.payNo || '-'
    meta.payType = _.get(item, 'content.payType') && payTypes[_.get(item, 'content.payType')]
      ? payTypes[_.get(item, 'content.payType')]
      : payTypes.etc
    meta.refundSubject = _.get(item, 'content.refundSubject') || '환불명 미기재'

    return (
      <Column>
        <div>
          <small style={{ marginRight: '0.35rem' }}>{_.get(meta, 'payType.text')}</small>
          <small>
            <strong>{meta.payNo}</strong>
          </small>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            const url = (_.get(meta, 'payType.name') && meta.payId)
              ? `/pays/${meta.payType.name}/${meta.payId}`
              : null
            return url
              ? window.open(url) 
              : alert(`해당 환불내역은 결제내역 데이터가 올바르지 않습니다.`)
          }}
        >
          <strong>{meta.refundSubject}</strong>
        </div>
      </Column>
    )
  }
}

RefundDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

RefundDetailColumnContainer.defaultProps = {
  item: {}
}

export default RefundDetailColumnContainer
