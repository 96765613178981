import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
export { Lnr }

export const Wrapper = styled.div`
  position: relative; box-sizing: border-box;
  margin: -0.25rem 0; padding: 0 0.25rem; margin-top: 0;
`

export const Header = styled.header`
  position: relative; box-sizing: border-box; color: ${palette('muted', 2)};
  padding: 0.05rem 0.25rem; font-size: 0.8em; padding-top: 0.45rem;
`

export const Items = styled.nav`
  position: relative; box-sizing: border-box; padding: 0.75rem 0.25rem;
  flex: 1 1 100%; min-width: max-content; display: flex; justify-content: flex-end;
`

export const Item = styled.a`
  min-width: max-content; text-align: center; white-space: pre; transition: all 0.3s; padding: 0.25rem 0.5rem;
  text-decoration: none; font-family: ${font('primary')}; font-size: 1em; color: ${palette('muted', 5)}; margin: 0 0.25rem;
  display: flex; align-items: center;
  &:hover { color: ${palette('darkblue', 5)}; }
  &.active { color: ${palette('muted', 1)}; font-weight: 500; background: ${palette('muted', 16)}; border-radius: 3px; }
  @media screen and (max-width: 960px) { min-width: max-content; }
  & :last-child { margin-left: 0.25rem; }
`
