import styled from 'styled-components'
import { font, palette } from 'styled-theme'

export const Pages = styled.nav`
  box-sizing: border-box; position: relative; z-index: 1; display: flex; align-items: center; justify-content: center;
`

export const Page = styled.a`
  display: flex; box-sizing: border-box; position: relative; z-index: 1; margin: 0.35rem; transition: all 0.3s;
  justify-content: center; align-items: center; font-size: 16px; border-radius: 50%; text-decoration: none;
  width: 40px; height: 40px; min-width: 40px; min-height: 40px; max-width: 40px; max-height: 40px; line-height: 40px;
  font-family: ${font('primary')}; color: ${palette('muted', 6)}; background: transparent; 
  &:hover { color: ${palette('muted', 3)}; }
  &.active { font-weight: 700; background: ${palette('muted', 1)}; color: ${palette('muted',20)}; }
`
