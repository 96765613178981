import React from 'react'


import api from 'services/api'
import _ from 'lodash'

import { Form, Button } from './utils'
import Modal from 'components/utils/Modal'

// 재고서비스 시작 모달
class StartStockStorageModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    // @ 도서정보에 기재된 판형정보 기반으로 2/4부를 나누어 배치해주어야함
    const book = _.get(props, 'product.content') || { content: null }
    const bookSizeName = _.get(book, 'content.bookSize.name')

    this.cutSize = ['pa4', 'pb5'].includes(bookSizeName) ? 4 : 8

    this.initializedState = {
      loading: true,
      form: { contract: this.cutSize, checkmate: this.cutSize/2 }
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.doCreate = this.doCreate.bind(this)
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const form = JSON.parse(JSON.stringify(this.initializedState.form))
    const next = { form }
    await new Promise((r) => this.setState({ ...next, loading: false }, r))
  }

  async doCreate() {
    const { productId, onSuccess } = this.props

    const { form } = this.state

    if (!window.confirm(`해당 내용으로 재고서비스를 시작할까요?`)) { return }
    
    const result = await api.post(`/products/admin2/${productId}/startStockStorage`, { form })
      .catch((e) => { return { error: true, message: `오류가 발생하였습니다. ${e.message}` } })

    alert(result && result.message ? `${result.message}` : `오류가 발생하였습니다.`)
    return !result.error ? onSuccess(result) : null
  }
  
  // 랜더링
  render() {
    const { onClose } = this.props
    
    const book = _.get(this.props, 'product.content') || {}
    const bookSize = _.get(book, 'bookSize') || {}

    const { doCreate } = this
    const { loading, form } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form style={{ maxWidth: '430px', minWidth: '430px', width: '430px' }}>
          <Form.Header>재고서비스 강제시작</Form.Header>
          <Form.Body>

              <div style={{ padding: '0.5rem 1rem', margin: '0.5rem', lineHeight: '1.618em', letterSpacing: '-0.05rem', background: '#eaeaea' , borderRadius: '0.5rem'}}>
                    🪓  판형 <strong>{_.get(bookSize, 'text') || '정보없음'}</strong> <small>{this.cutSize}부 공배 기반</small>
                </div>

            <Form.Field>
              <div className="control">
                <input
                  type="number"
                  step={this.cutSize}
                  value={form.contract || ''}
                  placeholder="계약수"
                  onChange={(e) => {
                    const value = e.target.value ? `${e.target.value}` : ''
                    const next = { ...form, contract: value, checkmate: value/2  }
                    return this.setState({ form: next })
                  }}
                  onBlur={(e) => {
                    let value = (!_.isNaN(form.contract * 1) && _.isInteger(form.contract * 1)) ? (form.contract * 1) : this.cutSize
                    if (value <= this.cutSize) { value = this.cutSize }
                    value = parseInt(value/this.cutSize) * this.cutSize
                    const next = { ...form, contract: value, checkmate: value/2 }
                    return this.setState({ form: next })
                  }}
                />
              </div>
              <div className="control">
                <input
                  type="text"
                  disabled={true}
                  placeholder={`체크메이트(${form.checkmate}부)`}
                />
              </div>
            </Form.Field>
            
            <Button.Group>
              <Button
                href="#doCreate"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  
                  return doCreate()
                }}
              >
                ✅ 강제시작
              </Button>
            </Button.Group>

          </Form.Body>
        </Form>
      </Modal>
    )
  }
}

export default StartStockStorageModalContainer
