export const BANKS  = {
  "002": { name: "002", text: "KDB산업", active: true },
  "003": { name: "003", text: "기업", active: true },
  "004": { name: "004", text: "국민", active: true },
  "005": { name: "005", text: "외환", active: true },
  "007": { name: "007", text: "수협", active: true },
  "011": { name: "011", text: "농협중앙회", active: true },
  "012": { name: "012", text: "단위농협", active: true },
  "020": { name: "020", text: "우리", active: true },
  "021": { name: "021", text: "조흥", active: true },
  "023": { name: "023", text: "SC제일", active: true },
  "027": { name: "027", text: "한국씨티", active: true },
  "031": { name: "031", text: "대구", active: true },
  "032": { name: "032", text: "부산", active: true },
  "034": { name: "034", text: "광주", active: true },
  "035": { name: "035", text: "제주", active: true },
  "037": { name: "037", text: "전북", active: true },
  "039": { name: "039", text: "경남", active: true },
  "041": { name: "041", text: "우리카드", active: true },
  "045": { name: "045", text: "새마을금고", active: true },
  "048": { name: "048", text: "신협", active: true },
  "064": { name: "064", text: "산림조합", active: true },
  "071": { name: "071", text: "우체국", active: true },
  "081": { name: "081", text: "하나", active: true },
  "088": { name: "088", text: "신한", active: true },
  "089": { name: "089", text: "케이뱅크", active: true },
  "090": { name: "090", text: "카카오", active: true },
  "092": { name: "092", text: "토스뱅크", active: true },
  "103": { name: "103", text: "SBI저축", active: true },
  "105": { name: "105", text: "웰컴저축", active: true },
  "209": { name: "209", text: "동양종금증권", active: true },
  "218": { name: "218", text: "현대차증권", active: true },
  "230": { name: "230", text: "미래에셋", active: true },
  "238": { name: "238", text: "대우증권", active: true },
  "240": { name: "240", text: "삼성증권", active: true },
  "243": { name: "243", text: "한국투자증권", active: true },
  "247": { name: "247", text: "우리투자증권", active: true },
  "262": { name: "262", text: "하이증권", active: true },
  "263": { name: "263", text: "HMC증권", active: true },
  "266": { name: "266", text: "SK증권", active: true },
  "267": { name: "267", text: "대신증권", active: true },
  "270": { name: "270", text: "하나대투증권", active: true },
  "278": { name: "278", text: "신한금융투자", active: true },
  "279": { name: "279", text: "동부증권", active: true },
  "280": { name: "280", text: "유진증권", active: true },
  "287": { name: "287", text: "메리츠증권", active: true },
  "288": { name: "288", text: "카카오페이증권", active: true },
  "291": { name: "291", text: "신영증권", active: true },
}
