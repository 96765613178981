import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import qs from 'query-string'
import _ from 'lodash'

import Page from 'components/layouts/Page'
import ManageList from 'components/extras/ManageList'

class ManagePageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params
  
    const search = location.search ? qs.parse(location.search) : {}

    let breadcrumb = ['홈', '운영관리'], title = '운영관리'

    let navs = []
    navs.push({ name: 'bookFileChange', text: '파일교체', active: false })
    navs.push({ name: 'bookModify', text: '도서정보', active: false })
    navs.push({ name: 'refund', text: '수기환불', active: false })
    navs.push({ name: 'payReview', text: '후기인증', active: false })
    navs.push({ name: 'brunchEvent', text: '브런치이벤트', active: false })
    navs.push({ name: 'paperSample', text: '샘플신청', active: false })
    navs.push({ name: 'report', text: '신고접수', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }
    
    const props = { ...search }

    // 숫자로 전달되어야하는 프로퍼티 캐스팅처리
    if (props.page) {
      props.page = Number(props.page)
      if (!_.isNaN(props.page) && !_.isNumber(props.page)) { delete props.page }
    }

    // 기본 데이터 나눔 숫자정의하기
    if (props.limit) {
      props.limit = Number(props.limit)
      if (!_.isNaN(props.limit) && !_.isNumber(props.limit)) { delete props.limit }
    }
    if (props.blockLimit) {
      props.blockLimit = Number(props.blockLimit)
      if (!_.isNaN(props.blockLimit) && !_.isNumber(props.blockLimit)) { delete props.blockLimit }
    }
    
    // 필터 복합옵션의 값이 1개라도 들어온 경우
    const propsKeys = Object.keys(props)
    const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
    if (filtersKeys && filtersKeys.length) {
      props.filters = {}
      filtersKeys.forEach(filtersKey => {
        const key = filtersKey.replace('filters_', '')
        props.filters[key] = props[filtersKey]
      })
    }

    // 소트 배열옵션으로 전환시켜주는 작업
    if (props.sorts) { props.sorts = props.sorts.split(',') }

    // 기간 검색이 가능하도록 배열한다.
    if (props.startAt) { props.startAt = new Date(props.startAt) }
    if (props.endAt) { props.endAt = new Date(props.endAt) }

    const listProps = { admin, history, location, match, title, nav, ...props }
    
    return (
      <Page>
        <Helmet>
          <title>운영관리({[...breadcrumb].pop()}) - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={breadcrumb} />

        <Page.Nav>
          <Page.Nav.Items>
            {navs.map((item, index) => 
              <Page.Nav.Item
                key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/manages/${item.name}`]}
              >
                <span>{item.text}</span>
              </Page.Nav.Item>
            )}
          </Page.Nav.Items>
        </Page.Nav>

        <ManageList {...listProps} />
      </Page>
    )
  }
}

ManagePageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

ManagePageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default ManagePageContainer
