import React from 'react'
import PropTypes from 'prop-types'
import { comma } from 'services/utils'
import { Box } from './utils'

const Dropdown = ({
  name, header, items, current, onActive, usedAll, style, ...props
}) => {
  const dropdowns = usedAll
    ? [{ name: 'all', text: header || '전체' }, ...items]
    : [...items]

  return (
    <Box style={style}>
      <select
        value={current}
        onChange={e => {
          const item = dropdowns.find((dropdown) => dropdown.name === e.target.value)
          return [e.stopPropagation(), e.preventDefault(), onActive(item)]
        }}
      >
        {dropdowns.map((item, index) => {
          return (
            <option key={index} value={item.name}>
              {item.text}
            </option>
          )
        })}
      </select>
    </Box>
  )
}

Dropdown.propTypes = {
  header: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object),
  current: PropTypes.string,
  usedAll: PropTypes.bool,
  onActive: PropTypes.func,
  style: PropTypes.object
}

Dropdown.defaultProps = {
  header: null,
  items: [],
  current: 'all',
  usedAll: false,
  onActive: () => {},
  style: {}
}

export default Dropdown
