import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

const controlStyles = css`
  & div.header {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem; white-space: nowrap;
    & small { color: ${palette('muted', 8)}; }
  }
  & div.control {
    position: relative; box-sizing: border-box;
    padding: 0.35rem 0.5rem;
    flex: 1 1 100%; display: flex;
    & > * { flex: 1 1 100%; }
    & input[type=text],
    & input[type=number],
    & input[type=time],
    & input[type=date] {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.5rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & select {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & textarea {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & button {
      position: relative; box-sizing: border-box;
      font-size: 1em; border-radius: 3px;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      background: #fafafa; border: 1px solid #929394; cursor: pointer;
      &:hover { background: #111; border: 1px solid #111; color: white; }
    }
  }
`


// 폼 사이즈 구성
export const Form = styled.div`
  position: relative; box-sizing: border-box;
  transition: all 0.3s; padding: 0.5rem 0.5rem;
 ${controlStyles}
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem 0.5rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Body = styled.div`
  position: relative; box-sizing: border-box;
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
`

export const Button = styled.a`
  position: relative; box-sizing: border-box;
  display: block; font-size: 1.16em; text-decoration: none;
  background: #222; color: white; padding: 1.316rem 0.5rem;
  font-weight: 700; letter-spacing: -0.05rem;
  text-align: center; border-radius: 0.3618rem;
  &:hover { background: #444; color: white; }
`

Button.Group = styled.div`
  position: relative; box-sizing: border-box;
  display: block; padding: 0.5rem;
`

