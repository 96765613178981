import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import qs from 'query-string'
import Page from 'components/layouts/Page'

import AdminDashboard from 'components/extras/AdminDashboard'

class IndexPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}
    const search = location.search ? qs.parse(location.search) : {}
    const commonProps = { admin, history, location, match }, dashboardProps = { ...commonProps, mode: nav }
    return (
      <Page>
        <Helmet>
          <title>현황 - BOOKK</title>
        </Helmet>
        <Page.BreadCrumb path={['홈', '대시보드']} />
        <AdminDashboard {...dashboardProps} />
      </Page>
    )
  }
}

IndexPageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

IndexPageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default IndexPageContainer
