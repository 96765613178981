import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & .statusBtn {
    padding: 0.5rem; border-radius: 0.35rem; font-weight: 500;
    transition: all 0.3s; cursor: pointer; margin: 0.25rem 0;
    background: ${palette('muted', 18)}; color: ${palette('muted', 3)};
    &:hover { background: ${palette('muted', 1)}; color: ${palette('muted', 21)}; }

    &.wait {
      background: ${palette('primary', 13)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 15)}; color: ${palette('muted', 21)}; }
    }
    &.review {
      background: ${palette('primary', 8)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 10)}; color: ${palette('muted', 21)}; }
    }
    &.reject {
      background: ${palette('danger', 10)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('danger', 18)}; color: ${palette('muted', 21)}; }
    }
    &.accept {
      background: ${palette('primary', 5)}; color: ${palette('muted', 21)};
      &:hover { background: ${palette('primary', 15)}; color: ${palette('muted', 21)}; }
    }
    &.cancel {
      background: ${palette('muted', 18)}; color: ${palette('muted', 3)};
      &:hover { background: ${palette('muted', 1)}; color: ${palette('muted', 21)}; }
    }
  }
`

const ModalForm = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; padding-top: 0;
  
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1.5rem 0; font-size: 1.3em;
    font-weight: 700;
  }

  & > section {
    position: relative; box-sizing: border-box;
    & > div { padding: 0.5rem 0; }
  }

  & > footer {
    position: relative; box-sizing: border-box;
    padding-top: 1rem;
  }

  & input[type=text],
  & input[type=date],
  & input[type=number] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 5)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & input[type=file] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & select {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem; font-weight: 500;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & textarea {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.75rem; line-height: 1.615rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & a.button {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    background: ${palette('darkblue', 8)}; color: ${palette('muted', 21)};
    border: 0; border-radius: 0.35rem; font-weight: 700; text-decoration: none;
    text-align: center; padding: 0.75rem 0; font-size: 1em;
    &:hover { background: ${palette('darkblue', 15)}; color: ${palette('muted', 21)}; }
  }
`
// 유저를 검색하고, 해당 유저에게 주문서을 생성해주는 기능
const SubmitModal = ({
  item = {},
  isOpen = false,
  onClose = () => {},
  parent = { loadItems: async () => {} },
}) => {

  const initForm = { accountId: '' }
  const [form, setForm] = React.useState(initForm)

  const modalProps = { isOpen, onClose }

  const doSubmit = async (form) => {
    // 데이터 값 저정해주기 (comment 데이터 넘기기)
    const data = {
      accountId: form.accountId
    }

    return await api.post(`/products/admin2/${item.id}/createSolutionOrder`, data)
      .then(async ({ error, message }) => {
        if (error) {
          alert(`처리 도중 문제가 발생하였습니다. (${message})`)
          return false
        }
        await parent.loadItems()
        alert(`성공적으로 처리하였습니다.`)
        onClose()
      })
      .catch(e => {
        alert(`데이터 저장중 문제가 발생하였습니다. (${e.message})`)
        return false
      })
  }
  
  return (
    <Modal {...modalProps}>
      <ModalForm>
        <header>
          계정입력
        </header>
        <section>
          <div>
            <input
              type="text"
              placeholder="계정명 입력"
              value={form.accountId}
              onChange={e => setForm({ ...form, accountId: e.target.value })}
            />
          </div>
        </section>
        <footer>
          <a
            href="#save"
            className="button"
            style={{ minWidith: '100%', maxWidith: '100%', width: '100%' }}
            onClick={(e) => [e.stopPropagation(), e.preventDefault(), doSubmit(form)]}
          >
            생성
          </a>
        </footer>
      </ModalForm>
    </Modal>
  )
}

// 관리자에서 주문서를 생성하는 기능
class CreateOrderColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { modal: {} }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const that = this
    const { modal } = this.state
    const { item, loadItems } = this.props

    const meta = {}

    return (
      <Column>
        <div
          className={`statusBtn ${item.status}`}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={e => {
            if (['activated'].includes(item.status)) {
              return [e.stopPropagation(), e.preventDefault(), that.setState({ modal: { name: 'SubmitModal' } })]
            } else {
              return alert(`이미 판매 중지된 상품은 진행 할 수 없습니다. 🙅‍♀️`)
            }
          }}
        >
          생성
        </div>

        {modal && modal.name === 'SubmitModal'
          ? (
            <SubmitModal
              item={item} parent={{ loadItems }}
              isOpen={true} onClose={e => that.setState({ modal: {} })}
            />
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

CreateOrderColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

CreateOrderColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default CreateOrderColumnContainer
