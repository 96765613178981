import React from 'react'
import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import moment from 'services/moment'
import { comma } from 'services/utils'

import Lnr from 'components/utils/Lnr'
import Lounge from 'components/utils/Lounge'

import Item from './Item'
import List from './List'

const controlStyles = css`
  input {
    display: block; box-sizing: border-box; border-radius: 5px; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0;
    background: ${palette('muted', 15)};
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
  select {
    display: block; box-sizing: border-box; border-radius: 5px; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0;
    background: ${palette('muted', 15)};
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
`

const View = styled.div`
  position: relative; box-sizing: border-box;
  ${controlStyles};
  padding: 1rem;
`

View.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0; font-family: ${font('primary')};
  display: flex; align-items: center;
  & > * { position: relative; box-sizing: border-box; flex: 1 1 100%; }
  & a {
    text-decoration: none; color: ${palette('muted', 5)};
    transition: all 0.3s;
    &:hover { color: ${palette('muted', 1)}; }
  }
`

View.Body = styled.main`
  position: relative; box-sizing: border-box;
`

class PackingList extends React.Component {
  constructor(props) {
    super(props)

    this.viewports =  ['list', 'item']

    this.state = {
      loading: true,
      viewport: 'item',
      count: 0, items: [],
      item: null, currentIdx: 1
    }

    this.abortController = new AbortController()

    this.loadRetailsSetup = this.loadRetailsSetup.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.initialize = this.initialize.bind(this)
    this.handle = this.handle.bind(this)
  }
  
  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/retails.json)
  async loadRetailsSetup() {
    const resources = { bookk: { name: 'bookk', text: '부크크' } }
    return await api.get('/resources/admin2/takeRetails')
      .then((data) => {
        if (!data) { return resources }
        return data
      })
      .catch((e) => resources)
  }

  // Parcel.packing status 데이터를 불러올때 규격별, 포장수량별, 이름순 등 신규 적용된 작업순서륿 반영해서 가져오기
  async loadItems() {
    const { nav } = this.props

    const query = {}
    query.retail = nav
    query.limit = 1000
    query.usedCount = true

    const result = await api.get(`/packings/admin2?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .catch(e => null)
    if (!result && result.error) { return { count: 0, rows: [] } }

    const ret = { count: result.count, rows: result.rows }
    return ret
  }

  // 포장관리 데이터를 불러오기
  async initialize() {
    await new Promise(r => this.setState({ loading: true, count: 0, items: [], setup: {} }, r) )
    
    const setup = await this.loadRetailsSetup()
    const result = await this.loadItems().catch(e => null)
    if (!result) { return await new Promise(r => this.setState({ loading: false, count: 0, items: [], setup }, r) ) }

    const items = result.rows || []
    await new Promise(r => this.setState({ loading: false, count: result.count, items, item: items[0], setup }, r) )
  }

  async handle(o) {
    return await new Promise(r => this.setState(o, r))
  }
  
  // 랜딩처리
  render() {
    const { handle } = this
    const { nav } = this.props
    const { loading, count, setup, items, item, currentIdx, viewport } = this.state
    if (loading) { return <Lounge /> }

    const cProps = { nav, setup, items, item, currentIdx, count, handle }

    return (
      <View>
        {(['list'].includes(viewport) || !item)
          ? (
            <View.Header>
              <div
                onClick={async (e) => {
                  let value = window.prompt(`이동하려는 포장번호 숫자를 입력해주세요.`, 1)
                  if (!value) { return }
                  let n = (_.isInteger(value * 1)) ? value * 1 : 1
                  await handle({ loading: true })
                  await handle({ item: items[n-1], viewport: 'item', currentIdx: n, loading: false })
                }}
                style={{ padding: '0 1rem', cursor: 'pointer' }}
              >
                <span style={{ marginRight: '0.25rem' }}>총 </span>
                <strong style={{ fontWeight: 900, marginRight: '0.35rem' }}>{comma(count)}</strong>
                <span>건</span>
              </div>
              <div style={{ minWidth: '140px', maxWidth: '140px' }}>
                <select
                  value={nav}
                  onChange={e => {
                    return window.location.href = `/packings/${e.target.value}`
                  }}
                >
                  {Object.values(setup).map((retail, rIdx) => {
                    const value = retail.name
                    return (<option key={rIdx} value={value}>{retail.text}</option>)
                  })}
                </select>
              </div>
            </View.Header>
          )
          : null}
        
        {(['item'].includes(viewport) && item)
          ? (
            <View.Header>
              <div style={{ padding: '0 1rem', fontSize: '1.3em', maxWidth: '60px' }}>
                <a
                  href="#doChangeViewport"
                  onClick={(e) => {
                    return [e.stopPropagation(), e.preventDefault(), handle({ viewport: 'list', item: null })]                    
                  }}
                >
                  <Lnr c="list" />
                </a>
              </div>
              <div
                style={{ padding: '0 1rem', maxWidth: '110px', cursor: 'pointer' }}
                onClick={async (e) => {
                  let value = window.prompt(`이동하려는 포장번호 숫자를 입력해주세요.`, 1)
                  if (!value) { return }
                  let n = (_.isInteger(value * 1)) ? value * 1 : 1
                  await handle({ loading: true })
                  await handle({ item: items[n-1], viewport: 'item', currentIdx: n, loading: false })
                }}
              >
                <span style={{ marginRight: '0.25rem' }}>총 </span>
                <strong style={{ fontWeight: 900, marginRight: '0.35rem' }}>{comma(count)}</strong>
                <span>건</span>
              </div>
              <div style={{ flex: '1 1 100%' }}>
                <select
                  value={currentIdx}
                  onChange={async (e)  => {
                    let n = (_.isInteger(e.target.value * 1)) ? e.target.value * 1 : 1
                    await handle({ loading: true })
                    await handle({ item: items[n-1], currentIdx: n, loading: false })
                  }}
                >
                  {items.map((item, idx) => {
                    const extras = item.extras || {}
                    const meta = {}
                    meta.idx = idx + 1
                    meta.pay = _.get(extras, 'pays[0]') || {}
                    meta.name = item.name || _.get(meta.pay, 'subject') || _.get(item, 'parcelId')
                    return (
                      <option key={idx} value={idx+1}>
                        {meta.idx === currentIdx ? `👉 ` : ''}
                        {meta.idx}. {meta.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </View.Header>
          )
          : null}
        <View.Body>
          {(['list'].includes(viewport) || !item) ? <List {...cProps} /> : null}
          {(['item'].includes(viewport) && item) ? <Item {...cProps} /> : null}
        </View.Body>
      </View>
    )
  }
}

export default PackingList
