import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

// 환불액수 정보 표현부
class PriceDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    this.setState()
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {}
    meta.refundPrice = _.get(item, 'content.refundPrice') || 0

    return (
      <Column>
        <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
          <strong
            style={{
              marginRight: '0.35rem',
              fontSize: '1.1em', fontWeight: 900
            }}
          >
            {comma(meta.refundPrice)}
          </strong>
          <small>원</small>
        </div>
      </Column>
    )
  }
}

PriceDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

PriceDetailColumnContainer.defaultProps = {
  item: {}
}

export default PriceDetailColumnContainer
