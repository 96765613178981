import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import { comma } from 'services/utils'

import StockLogModal from './Setups/StockLogModal'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'ProductTypeColumn', header: '종류', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'StockStorageColumn', header: '책장', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProductColumn', header: '상품', style: { minWidth: '0', flex: '1 1 auto', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'UserColumn', header: '보유자', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PurposeColumn', header: '용도', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PriceColumn', header: '소비자가격', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ProduceColumn', header: '생산방식', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  columns.push({ key: 'OrderColumn', header: '주문량', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'InvoiceColumn', header: '필요입고', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'OverflowColumn', header: '추가입고', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'InstockColumn', header: '재고', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'TotalStockColumn', header: '총보유', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ForwardingColumn', header: '출고예정', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'OutstockColumn', header: '출고', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DestroyColumn', header: '폐기', style: { minWidth: '70px', maxWidth: '70px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  columns.push({ key: 'ControlColumn', header: '관리', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const productTypes = {}
  productTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  productTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  productTypes.solution = { name: 'solution', text: '작가서비스' }
  productTypes.etc = { name: 'etc', text: '기타' }

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}

  // 재고현황정보를 변경하는 기능을 의미한다.
  actions.doUpdateStock = async (item = {}) => {
    if (!window.confirm(`강제로 현황을 변경해도 재고기록에는 남지만 발주내역은 추가되지 않습니다. 진행할까요?`)) { return }
    const stock = _.get(item, 'extras.currentStock') || {}
    if (!_.isInteger(stock.invoice * 1)) { return alert(`필요입고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.overflow * 1)) { return alert(`추가입고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.instock * 1)) { return alert(`보유재고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.forwarding * 1)) { return alert(`출고예정량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.outstock * 1)) { return alert(`누적출고량 숫자가 올바르지 않습니다.`) }
    if (!_.isInteger(stock.destroy * 1)) { return alert(`폐기량 숫자가 올바르지 않습니다.`) }

    const form = {}
    form.invoice = stock.invoice
    form.overflow = stock.overflow
    form.instock = stock.instock
    form.forwarding = stock.forwarding
    form.outstock = stock.outstock
    form.destroy = stock.destroy

    const result = await api.put(`/stocks/admin2/${stock.id}/stockAmounts`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `재고현황을 변경하던 도중 문제가 발생했습니다.`) }

    alert(`성공적으로 변경하였습니다.`)
    return await initialize()
  }

  // 재고현황 모달창 열기
  actions.doOpenStockLogModal = async (item ={}) => {
    // 현재 연동된 재고현황 내역을 불러온다.
    const extras = item.extras || {}
    const stockId = _.get(extras, 'currentStock.id')
    if (!stockId) { return alert(`해당 상품의 재고관려ID가 누락되어있습니다. 관리자에게 문의해주세요.`) }

    // 모달을 연다.
    const modalProps = {}
    modalProps.name = 'StockLogModal'
    modalProps.props = { stockId, onClose: () => [setModal(null), initialize()] }

    return setModal(modalProps)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      {modal && modal.name === 'StockLogModal' ? <StockLogModal {...(modal.props || {})} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 상품이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
          {/* <a
              href="#"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return
              }}>
              임시
          </a> */}
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}

                    const meta = {}
                    meta.productId = _.get(item, 'id') || null
                    meta.productType = _.get(item, 'productType')
                      ? productTypes[_.get(item, 'productType')]
                      : productTypes.etc

                    meta.alivedStatus = _.get(item, 'deleted') ?`⛔` : ``

                    meta.userId = _.get(item, 'extras.currentUser.id')
                    meta.userAccountId = _.get(item, 'extras.currentUser.accountId')
                    meta.userNo = _.get(item, 'extras.currentUser.userNo')
                    meta.productName = _.get(item, 'name')
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.isbn = _.get(item, 'content.isbn')
                    meta.bookPurpose = _.get(item, 'content.purpose')
                      ? _.get(item, 'content.purpose')
                      : { text: '알수없음' }
                    meta.productPrice = 0
                    if (['paperBook', 'electronicBook'].includes(_.get(item, 'productType'))) { meta.productPrice = _.get(item, 'content.basicPrice') || 0 }
                    if (['solution'].includes(_.get(item, 'productType'))) { meta.productPrice = _.get(item, 'content.defaultPrice') || 0 }
                    meta.produce = `알수없음`
                    if (['inhouse'].includes(_.get(item, 'produce'))) { meta.produce = '자체' }
                    if (['outsourcing'].includes(_.get(item, 'produce'))) { meta.produce = '외부' }

                    // 주문량, 필요입고량, 추가입고량, 재고, 총보유, 출고예정, 출고, 폐기
                    meta.parcelStates = _.get(item, 'extras.parcelStates') || { count: 0, totalAmount: 0 }
                    meta.invoiceAmount = _.get(item, 'extras.currentStock.invoice') || 0
                    meta.overflowAmount = _.get(item, 'extras.currentStock.overflow') || 0
                    meta.instockAmount = _.get(item, 'extras.currentStock.instock') || 0
                    meta.forwardingAmount = _.get(item, 'extras.currentStock.forwarding') || 0
                    meta.outstockAmount = _.get(item, 'extras.currentStock.outstock') || 0
                    meta.destroyAmount = _.get(item, 'extras.currentStock.destroy') || 0
                    meta.totalStockAmount = meta.invoiceAmount + meta.overflowAmount + meta.instockAmount
                    meta.storageNo = _.get(item, 'extras.stockStorage.storageNo') || '-'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'ProductTypeColumn':
                              return (
                                <div>
                                  <strong>{meta.productType.text}</strong>
                                </div>
                              )
                            case 'StockStorageColumn':
                              return (
                                <div>
                                  <strong style={{ fontWeight: 900, fontSize: '1.25em' }}>{meta.storageNo}</strong>
                                </div>
                              )
                            case 'ProductColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    const id = _.get(item, 'id')
                                    const productType = _.get(item, 'productType')
                                    return window.open(`/products/${productType}/${id}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal', maxWidth: '260px', overflowY: 'auto' }}
                                >
                                  {meta.alivedStatus
                                    ? <span style={{ marginRight: '0.35rem' }} title="삭제처리된 상품">{meta.alivedStatus}</span>
                                    : null}
                                  <strong>{meta.productName}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.bookNo}</small>
                                </div>
                              )
                            case 'UserColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    return window.open(`/users/${meta.userId}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                >
                                  <strong>{meta.userAccountId}</strong>
                                </div>
                              )
                            case 'PurposeColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <div><strong>{meta.bookPurpose.text}</strong></div>
                                  {meta.isbn
                                    ? <div><small>{meta.isbn}</small></div>
                                    : null}
                                </div>
                              )
                            case 'PriceColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.productPrice)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>원</small>
                                </div>
                              )
                            case 'ProduceColumn':
                              return (
                                <div>
                                  <strong>{meta.produce}</strong>
                                </div>
                              )

                            case 'OrderColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta.parcelStates, 'totalAmount'))}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'InvoiceColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.invoiceAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'OverflowColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.overflowAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'InstockColumn':
                              return (
                                <div>
                                  <input
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    value={`${meta.instockAmount}`}
                                    onChange={e => {
                                      const n = `${e.target.value}`.replace(/[^0-9]/g, '')
                                      const value = _.isInteger(n * 1) ? (n * 1) : meta.instockAmount
                                      const next = {}
                                      next[`items[${itemIdx}].extras.currentStock.instock`] = value
                                      return handle(next)
                                    }}
                                  />
                                </div>
                              )
                              
                            case 'TotalStockColumn':
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()

                                    return await actions.doOpenStockLogModal(item)
                                  }}>
                                  <strong>{comma(meta.totalStockAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'ForwardingColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.forwardingAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'OutstockColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.outstockAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )
                              
                            case 'DestroyColumn':
                              return (
                                <div>
                                  <strong>{comma(meta.destroyAmount)}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>건</small>
                                </div>
                              )

                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateStock(item)]
                                    }}
                                  >
                                    반영
                                  </button>
                                </div>
                              )

                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>해당 상품에 대한 재고 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '재고현황',
  nav: 'products'
}

export default List
