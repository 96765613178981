import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import qs from 'query-string'
import api from 'services/api'
import moment from 'services/moment'
import { comma } from 'services/utils'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'UserDetailColumn', header: '유저', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'PrimaryColumn', header: '구분', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center' } })
  columns.push({ key: 'BillingKeyColumn', header: '발급된 부트페이 빌링키', style: { minWidth: '290px', maxWidth: '290px', justifyContent: 'center' } })
  columns.push({ key: 'StatusColumn', header: '연동상태', style: { minWidth: '190px', maxWidth: '190px', justifyContent: 'center' } })
  columns.push({ key: 'DateColumn', header: '연동일', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center' } })
  columns.push({ key: 'CardNoColumn', header: '카드번호', style: { minWidth: '260px', maxWidth: '260px', justifyContent: 'center' } })
  columns.push({ key: 'DetailColumn', header: '이용중인 서비스', style: { minWidth: '0', flex: '1 1 100%', maxWidth: '100%', justifyContent: 'flex-start' } })
  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 인터페이스 기능
  const actions = {}

  // @ 상태변경기능
  actions.updateBillingStatus = async (item = {}, status = 'activated') => {
    return await api.put(`/users/admin2/updateBillingStatus/${item._id}`, { status })
      .catch((e) => ({ error: true, message: `오류가 발생하였습니다. ${e.message}` }))
  }

  // @ 여러개의 상태를 변경해주는 기능
  actions.updateBillingStatusByItems = async (items = selecteds, status = 'activated') => {
    if (['deactivated'].includes(status)
      && !window.confirm(`☔ 비활성화 처리하게 되면 PG사와 부트페이로 빌링키를 폐기한다는 명령을 보냅니다. 진행하시겠나요?`)) { return }
    if (!items.length) { return alert(`선택된 빌링키가 없습니다.`) }

    const results = await items.reduce((prev, item) => 
      prev.then(async (report) => {
        const result = await actions.updateBillingStatus(item, status)
        report.push(result)
        return report
      }),
      Promise.resolve([]))
    .catch((e) => [{ error: true, message: `${e.message} ${e.stack}` }])

    const success = results.filter(r => !r.error)
    const error = results.filter(r => r.error)

    alert(`진행되었습니다. (총 ${results.length}건, 성공 ${success.length}건, 실패 ${error.length}건)`)
    if (error.length) { return }

    return window.location.reload()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>

          {['resolved'].includes(filters.status)
            ? (
              <div className="selector">
                <input
                  type="date"
                  value={moment(startAt).format('YYYY-MM-DD')}
                  onChange={e => {
                    if (!e.target.value) { return }
                    const [year, month, date] = (e.target.value).split('-')
                    const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                    const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                    return startMt.diff(prevEndMt) > 0
                      ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                      : handle('startAt', startMt.toDate(), () => { initialize() })
                  }}
                />
                <input
                  type="date"
                  value={moment(endAt).format('YYYY-MM-DD')}
                  onChange={e => {
                    if (!e.target.value) { return }
                    const [year, month, date] = (e.target.value).split('-')
                    const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                    const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                    return prevStartMt.diff(endMt) > 0
                      ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                      : handle('endAt', endMt.toDate(), () => { initialize() })
                  }}
                />
              </div>
            )
            : null}
        </Header.Search>
        <Header.Options>
          <a
            href="#updateBillingStatusByItems"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.updateBillingStatusByItems(selecteds, 'deactivated')
            }}
          >
              ☔ 비활성화
          </a>
          {/* <a
            href="#updateBillingStatusByItems"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.updateBillingStatusByItems(selecteds, 'activated')
            }}
          >
            🔥활성화
          </a> */}
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const currentUser = _.get(item, 'extras.currentUser') || {}
                    const currentUsedStockStorageCount = _.get(item, 'extras.currentUsedStockStorageCount') || 0

                    const meta = {}
                    meta.createdMt = moment(item.createdAt)
                    meta.cardName = _.get(item, 'billingResult.data.card_name')
                      ? `${_.get(item, 'billingResult.data.card_name')}`.replace(/(\[|\])/ig, '')
                      : '알수없음'
                    meta.cardNo = _.get(item, 'billingResult.data.card_no') || '0000-0000-****-0000'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'UserDetailColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <span style={{ marginRight: '0.35rem' }}>{currentUser.emoji}</span>
                                  <strong>{currentUser.accountId}</strong>
                                </div>
                              )
                            case 'PrimaryColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong>{item.primary ? `주` : `부`}</strong>
                                </div>
                              )
                            case 'BillingKeyColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong>{item.billingKey}</strong>
                                </div>
                              )
                            case 'StatusColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  {['activated'].includes(item.status)
                                    ? <strong style={{ marginRight: '0.35rem' }}>🔥정상 이용중</strong> : null}
                                  {['deactivated'].includes(item.status)
                                    ? <strong style={{ marginRight: '0.35rem' }}>☔ 비활성화</strong> : null}
                                </div>
                              )
                            case 'DateColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong style={{ marginRight: '0.35rem' }}>
                                    {meta.createdMt.format('YYYY.MM.DD')}
                                  </strong>
                                  <small>
                                    {meta.createdMt.format('HH:mm')}
                                  </small>
                                </div>
                              )
                            case 'CardNoColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                  <strong style={{ marginRight: '0.45rem' }}>{meta.cardName}</strong>
                                  <span>{meta.cardNo}</span>
                                </div>
                              )
                            case 'DetailColumn':
                              return (
                                <div style={{ width: '100%', maxWidth: '100%' }}>
                                  <span style={{ marginRight: '0.35rem' }}>
                                    재고서비스
                                  </span>
                                  <strong
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      if (currentUsedStockStorageCount) { return alert(`재고서비스가 존재하지 않습니다.`) }

                                      const endpoint = `/products/paperBook`
                                      const queries = { filters_stockStorage: 'used', keywords: user.accountId }
                                      const url = `${endpoint}?${qs.stringify(queries)}`
                                      window.open(url)
                                    }}
                                  >
                                    {currentUsedStockStorageCount}건
                                  </strong>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
      
              {!items.length ? (
                <Items.NotFound>
                  <header>빌링 데이터가 없습니다.</header>
                  <section>PG사로 부터 발급 받은 고객님의 자동결제 Card Bliling Key 내역을 찾을 수 없습니다.</section>
                </Items.NotFound>
              ) : null}
            </DataSet.Body>
          </DataSet>

        </Items.Body>

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '지급관리',
  nav: 'withdraws'
}

export default List
