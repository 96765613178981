import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

// 하위 개체 컴포넌트를 랩핑한다.
export const Form = styled.article`
  position: relative; box-sizing: border-box;
`

// 최상위 헤더
Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
  display: flex; justify-content: space-between; align-items: center;
  border-bottom: 1px solid ${palette('muted', 15)};
  margin-bottom: 0.75rem;
`

Form.Lead = styled.div`
  position: relative; box-sizing: border-box;
  font-weight: 900; font-size: 1.8em;
  padding: 0.5rem;
  display: flex; justify-content: flex-start;
  `

Form.Units = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0 0.5rem;
`

// 단추 디자인
Form.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.5rem;
  display: flex; justify-content: flex-end;
`

Form.Button = styled.a`
  position: relative; box-sizing: border-box;
  display: block; transition: all 0.3s;
  font-size: 1.2em; font-weight: 700; text-decoration: none;
  display: block; padding: 0.75rem 1.75rem; border-radius: 0.35rem;
  color: ${palette('muted', 1)};
  margin: 0rem 0.5rem;
  &:hover { background: ${palette('muted', 15)}; color: ${palette('muted', 1)}; }
  &.primary {
    color: white; background: ${palette('muted', 1)};
    &:hover { background: ${palette('muted', 3)}; }
  }
`

Form.Links = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-end;
  padding: 0.5rem;
`

Form.Link = styled.a`
  position: relative; box-sizing: border-box;
  color: ${palette('muted', 3)}; letter-spacing: -0.05rem;
  display: block; padding: 0.75rem 1rem; text-decoration: none;
  border-radius: 0.35rem; transition: all 0.3s;
  &:hover { background: ${palette('muted', 15)}; color: ${palette('muted', 1)}; }
`

// 레이아웃
Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-start;
`

Form.Context = styled.div`
  flex: 1 1 100%; min-width: 0;
  position: relative; box-sizing: border-box;
  padding: 0 0.5rem;
  `

Form.Aside = styled.div`
  min-width: 350px; max-width: 350px;
  position: relative; box-sizing: border-box;
  padding: 0 0.5rem;
`

// 입력상자 디자인
Form.Boxs = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-start; flex-wrap: wrap;
  padding: 0.35rem 0;
`

Form.Box = styled.div`
  position: relative; box-sizing: border-box;
  min-width: 18%; max-width: 18%; width: 18%;
  margin: 0 0.99%;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.95em; color: ${palette('muted', 1)};
    font-family: ${font('primary')}; font-weight: 700;
    padding: 0.25rem 0;
    & small { color: ${palette('muted', 7)}; }
  }
  & > section {
    position: relative; box-sizing: border-box;
    padding: 0.35rem 0;
  }
`

const controlStyles = css`
  & input[type=text],
  & input[type=number],
  & input[type=date] {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & select {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
  & textarea {
    position: relative; box-sizing: border-box;
    display: block; border-radius: 3px;
    width: 100%; max-width: 100%; min-width: 0;
    padding: 0.35rem 0.5rem; outline: none;
    border: 1px solid ${palette('muted', 10)}; transition: all 0.3s;
    color: ${palette('muted', 5)};
    &:hover { border: 1px solid ${palette('muted', 5)}; color: ${palette('muted', 1)}; }
  }
`

Form.Field = styled.div`
  position: relative; box-sizing: border-box;
  padding: 0.35rem 0;
  display: flex; align-items: center;
  & > * { width: 100%; flex: 1 1 100%; min-width: 0; }
  & > div.label { font-size: 0.9em; }
  ${controlStyles}
`

// 입력상자 디자인
Form.Control = styled.div`
  position: relative; box-sizing: border-box;
`




// 메타정보
export const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em; color: ${palette('muted', 4)};
    padding: 0.25rem 0;
  }
  & > section {
    position: relative; box-sizing: border-box;
    & small { color: ${palette('muted', 7)}; }
    padding: 0.35rem;
  }
  & > div.extra {
    position: relative; box-sizing: border-box;
    padding: 0.35rem;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`