import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import {
  Wrapper, Text,
} from './utils'

class Logo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { styled, text, history, style } = this.props
    return (
      <Wrapper style={style}>
        {styled === 'text' ? (
          <Text
            href="#"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              return history.push('/') 
            }}
          >{text ? text : 'BOOKK'}</Text>
        ) : null}
      </Wrapper>
    )
  }
}

Logo.propTypes = {
  styled: PropTypes.string,
  text: PropTypes.string,
  history: PropTypes.object,
  style: PropTypes.object,
}

Logo.defaultProps = {
  styled: 'text',
  text: '',
  history: {},
  style: {},
}

export default withRouter(Logo)
