import styled from 'styled-components'
import { font, palette, size } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import Checkbox from 'components/utils/Checkbox'
import Caret from 'components/utils/Caret'
import MetaSet from 'components/utils/MetaSet'

const { Meta } = MetaSet

export { Lnr, Checkbox, Caret, Meta, MetaSet }

export const Snippet = styled.section`
  box-sizing: border-box; position: sticky; top: 0;
`

Snippet.Header = styled.header`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center; padding: 1rem 0;
`

Snippet.Header.Lead = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; transition: all 0.3s;
  border: 0; color: ${palette('muted', 1)}; font-family: ${font('primary')};
  font-size: 1.2em; font-weight: 700; letter-spacing: -0.1rem;
`

Snippet.Header.Buttons = styled.section`
  position: relative; box-sizing: border-box;
`

Snippet.Header.Button = styled.a`
  position: relative; box-sizing: border-box;
  text-decoration: none; white-space: nowrap; cursor: pointer;
  padding: 1rem; font-size: 1.2em; font-weight: 700; letter-spacing: -0.1rem;
`

Snippet.Body = styled.section`
  position: relative; box-sizing: border-box;
`

Snippet.Footer = styled.footer`
  position: relative; box-sizing: border-box;
`

Snippet.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: flex-start; align-items: center;
  padding: 0 0.5rem; margin: 0 -0.5rem;
`

Snippet.Button = styled.a`
  position: relative; box-sizing: border-box; font-weight: 700;
  display: block; padding: 0.5rem 0; text-decoration: none;
  flex: 1 1 100%; text-align: center; transition: all 0.3s; cursor: pointer;
  background: ${palette('muted', 3)}; color: ${palette('muted', 18)};
  &.primary, &:hover { color: ${palette('muted', 21)}; background: ${palette('muted', 1)}; }
  &:first-child { border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
  &:last-child { border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
`

export const Description = styled.div`
  position: relative; box-sizing: border-box; padding: 1rem 0;
  & > div {
    position: relative; box-sizing: border-box;
    font-size: 0.9em; line-height: 1.35rem;
    padding: 0.25rem; color: ${palette('muted', 3)};
    & strong { color: ${palette('muted', 1)}; }
    & small { color: ${palette('muted', 6)}; }
  }
`

export const Control = styled.form`
  position: relative; box-sizing: border-box;
  & > input {
    position: relative; box-sizing: border-box; outline: none; width: 100%;
    padding: 0.5rem 0.65rem;
    border: 0; border-radius: 5px; background: ${palette('muted', 16)};
  }
`
