import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fromAdmin } from 'store/selectors'

import moment from 'services/moment'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'

import BookList from './BookList'
import SolutionList from './SolutionList'
import EtcSolutionWorkList from './EtcSolutionWorkList'
import EtcApprovalBookFileChangeList from './EtcApprovalBookFileChangeList'
import EtcBookSubmitList from './EtcBookSubmitList'
import EtcEtcList from './EtcEtcList'
import EtcBookStockStorage from './EtcBookStockStorage'

/*
  [잊지 말아야할 절차]
  1. constructors에 추가
  2. this.navs 에 추가
  3. loadItems에 구분자값 전달 추가
  4. render에 컴포넌트 표현
  5. cloned import 컴포넌트

  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' } // 비동기
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' } // 비동기
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.approvalId = { name: 'approvalId', text: '문서ID' } // OK
*/

const constructors = {}

// 공통구간
constructors.common = (that = {}) => {
  // 와치처리
  that.watchs = that.watchs.map((watch) => {
    if (watch.prop === 'filters') { watch.defaultProp = { status: 'finished' } }
    if (watch.prop === 'sorts') { watch.defaultProp = ['-createdAt'] }
    return watch
  })

  // 소트세팅
  that.sortOptions.createdAt = { name: 'createdAt', text: '주문일', multiple: true, asc: { text: '과거' }, desc: { text: '최근' } }

  // 결제방식
  that.filterOptions.method = { name: 'method', text: '결제방식', path: 'method', state: 'filters.method', conditions: [], type: 'tool' }
  that.filterOptions.method.conditions.push({ name: 'card', text: '신용카드' })
  that.filterOptions.method.conditions.push({ name: 'card_rebill', text: '정기결제' })
  that.filterOptions.method.conditions.push({ name: 'card_rebill_rest', text: '정기결제 (REST API)' })
  that.filterOptions.method.conditions.push({ name: 'bank', text: '계좌이체' })
  that.filterOptions.method.conditions.push({ name: 'phone', text: '휴대폰결제' })
  that.filterOptions.method.conditions.push({ name: 'vbank', text: '가상계좌' })
  that.filterOptions.method.conditions.push({ name: 'kakao', text: '카카오페이 간편결제' })
  that.filterOptions.method.conditions.push({ name: 'payco', text: '페이코 간편결제' })
  that.filterOptions.method.conditions.push({ name: 'npay', text: '네이버페이 간편결제' })
  that.filterOptions.method.conditions.push({ name: 'free', text: '무료' })
  that.filterOptions.method.conditions.push({ name: 'etc', text: '기타' })

  // 결제상태
  that.filterOptions.status = { name: 'status', text: '결제상태', path: 'status', state: 'filters.status', item: 'status', conditions: [], type: 'tool' }
  that.filterOptions.status.conditions.push({ name: 'temporary', text: '임시' })
  that.filterOptions.status.conditions.push({ name: 'wait', text: '결제대기' })
  that.filterOptions.status.conditions.push({ name: 'finished', text: '결제완료' })
  that.filterOptions.status.conditions.push({ name: 'cancel', text: '결제취소' })
  that.filterOptions.status.conditions.push({ name: 'error', text: '결제오류' })

  // 환불상태
  that.filterOptions.refundStatus = { name: 'refundStatus', text: '환불상태', path: 'refundStatus', state: 'filters.refundStatus', conditions: [], type: 'tool' }
  that.filterOptions.refundStatus.conditions.push({ name: 'none', text: '해당없음' })
  that.filterOptions.refundStatus.conditions.push({ name: 'wait', text: '환불요청중' })
  that.filterOptions.refundStatus.conditions.push({ name: 'review', text: '환불처리중' })
  that.filterOptions.refundStatus.conditions.push({ name: 'reject', text: '환불불가' })
  that.filterOptions.refundStatus.conditions.push({ name: 'accept', text: '환불완료' })
  that.filterOptions.refundStatus.conditions.push({ name: 'cancel', text: '환불취소' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.userId = { name: 'userId', text: '회원ID' }
  that.keywordsOptions.userNo = { name: 'userNo', text: '회원번호' }
  that.keywordsOptions.accountId = { name: 'accountId', text: '계정명' }
  that.keywordsOptions.nickName = { name: 'nickName', text: '닉네임' }
  that.keywordsOptions.name = { name: 'name', text: '실명' }
  that.keywordsOptions.email = { name: 'email', text: '이메일' }
  that.keywordsOptions.mobile = { name: 'mobile', text: '연락처' }
  that.keywordsOptions.parcelReceiver = { name: 'parcelReceiver', text: '수취인' }
  that.keywordsOptions.parcelMobile = { name: 'parcelMobile', text: '수취인 전화번호' }
  that.keywordsOptions.parcelTrackingNumber = { name: 'parcelTrackingNumber', text: '운송장번호' }
  
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.payId = { name: 'payId', text: '결제ID' }
  that.keywordsOptions.payNo = { name: 'payNo', text: '주문번호' }
  that.keywordsOptions.orderId = { name: 'orderId', text: '주문ID' }
}

// 도서공통구간
constructors.bookCommon = (that = {}) => {
  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.bookNo = { name: 'bookNo', text: '도서번호' }
  that.keywordsOptions.bookId = { name: 'bookId', text: '도서ID' }
  that.keywordsOptions.bookTitle = { name: 'bookTitle', text: '도서명' }
  that.keywordsOptions.bookAuthor = { name: 'bookAuthor', text: '저자명' }
  that.keywordsOptions.bookIsbn = { name: 'bookIsbn', text: 'ISBN' }
}

// 종이도서 결제내역
constructors.paperBook = (that = {}) => {
  constructors.bookCommon(that)

  that.filterOptions.retails = { name: 'retails', text: '판매처', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '발송상태', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'temporary', text: '임시' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'wait', text: '발송대기' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '발송준비중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'packing', text: '포장중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '발송완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '발송취소' })

  constructors.common(that)
  
  that.keywordsOptions.parcelId = { name: 'parcelId', text: '발송ID' }
}

// 전자도서 결제내역
constructors.electronicBook = (that = {}) => {
  constructors.bookCommon(that)

  that.filterOptions.retails = { name: 'retails', text: '판매처', path: 'retails', state: 'filters.retails', conditions: [], type: 'tool' }

  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '다운로드 여부', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '다운로드 가능' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '다운로드 완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '다운로드 불가' })
  
  constructors.common(that)
}

// 도서전체
constructors.book = (that = {}) => {
  // 구매도서 종류
  that.filterOptions.payType = { name: 'payType', text: '구매도서 종류', path: 'payType', state: 'filters.payType', conditions: [], type: 'tool' }
  that.filterOptions.payType.conditions.push({ name: 'paperBook', text: '종이도서' })
  that.filterOptions.payType.conditions.push({ name: 'electronicBook', text: '전자도서' })

  constructors.paperBook(that)
  constructors.electronicBook(that)

  // 발송·작업 상태
  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '발송·작업 상태', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'temporary', text: '임시/결제 대기중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'wait', text: '발송대기' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '발송준비중/다운로드 가능' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'packing', text: '포장중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '발송완료/다운로드 완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '발송취소/다운로드 취소' })
}

// 작가서비스 결제내역
constructors.solution = (that = {}) => {
  // 필터 셋팅
  that.filterOptions.category = { name: 'category', text: '분류', path: 'category', state: 'filters.category', item: 'content.solution.category.name', conditions: [], type: 'tool' }
  that.filterOptions.category.conditions.push({ name: 'coverDesign', text: '표지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textDesign', text: '내지디자인' })
  that.filterOptions.category.conditions.push({ name: 'textCorrection', text: '교정교열' })
  that.filterOptions.category.conditions.push({ name: 'etc', text: '기타' })

  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '작업단계', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'temporary', text: '임시' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'wait', text: '작업대기' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '작업준비중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'packing', text: '작업중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '작업완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '작업취소' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.solutionNo = { name: 'solutionNo', text: '작가서비스번호' }
  that.keywordsOptions.solutionId = { name: 'solutionId', text: '작가서비스ID' }
  that.keywordsOptions.solutionName = { name: 'solutionName', text: '작가서비스명' }
  that.keywordsOptions.solutionSellerName = { name: 'solutionSellerName', text: '판매자명' }
  that.keywordsOptions.payId = { name: 'payId', text: '결제ID' }
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.orderId = { name: 'orderId', text: '주문ID' }

  constructors.common(that)
}

// 작가서비스 결제내역
constructors.etcSolutionWork = (that = {}) => {
  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '작업단계', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'temporary', text: '임시' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'wait', text: '작업대기' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '작업준비중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'packing', text: '작업중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '작업완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '작업취소' })

  // 커워드 셋팅
  that.keywordsOptions.all = { name: 'all', text: '전체' }
  that.keywordsOptions.solutionNo = { name: 'solutionNo', text: '작가서비스번호' }
  that.keywordsOptions.solutionId = { name: 'solutionId', text: '작가서비스ID' }
  that.keywordsOptions.solutionName = { name: 'solutionName', text: '작가서비스명' }
  that.keywordsOptions.solutionSellerName = { name: 'solutionSellerName', text: '판매자명' }
  that.keywordsOptions.payId = { name: 'payId', text: '결제ID' }
  that.keywordsOptions.productId = { name: 'productId', text: '상품ID' }
  that.keywordsOptions.orderId = { name: 'orderId', text: '주문ID' }

  constructors.common(that)
}

// 기타결제 결제내역
constructors.etc = (that = {}) => {
  constructors.bookCommon(that)
  constructors.common(that)
}

// 파일교체비 결제내역
constructors.etcApprovalBookFileChange = (that = {}) => {  
  // Approval 처리시점보다는 넓은 상태값을 보유중
  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '작업단계', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '진행중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '작업완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '작업취소' })

  constructors.bookCommon(that)
  constructors.common(that)
}

// 가격변경비 결제내역
constructors.etcApprovalBookSubmit = (that = {}) => {
  // Approval 처리시점보다는 넓은 상태값을 보유중
  that.filterOptions.supplyStatus = { name: 'supplyStatus', text: '작업단계', path: 'supplyStatus', state: 'filters.supplyStatus', conditions: [], type: 'tool' }
  that.filterOptions.supplyStatus.conditions.push({ name: 'ready', text: '진행중' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'sent', text: '심사완료' })
  that.filterOptions.supplyStatus.conditions.push({ name: 'cancel', text: '제출취소' })
  
  constructors.bookCommon(that)
  constructors.common(that)
}

// 개인결제 결제내역
constructors.etcEtc = (that = {}) => {

  constructors.common(that)
}

// 재고서비스
constructors.etcBookStockStorage = (that = {}) => {
  // Approval 처리시점보다는 넓은 상태값을 보유중
  that.filterOptions.orderContentType = { name: 'orderContentType', text: '결제종류', path: 'orderContentType', state: 'filters.orderContentType', conditions: [], type: 'tool' }
  that.filterOptions.orderContentType.conditions.push({ name: 'start', text: '자동연장 결제' })
  that.filterOptions.orderContentType.conditions.push({ name: 'end', text: '중지신청 결제' })

  constructors.common(that)

  that.keywordsOptions.stockStorage = { name: 'stockStorage', text: '책장번호' }
}

class PayListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.filterOptions = {}
    this.sortOptions = {}
    this.keywordsOptions = {}
    this.watchs = [
      { prop: 'keywordsOption', state: 'keywordsOption' },
      { prop: 'keywords', state: 'keywords' },
      { prop: 'page', state: 'page' },
      { prop: 'limit', state: 'limit' },
      { prop: 'blockLimit', state: 'blockLimit' },
      { prop: 'startAt', state: 'startAt' },
      { prop: 'endAt', state: 'endAt' },
      { prop: 'filters', state: 'filters' },
      { prop: 'sorts', state: 'sorts' },
    ]

    this.navs = ['book', 'paperBook', 'electronicBook', 'solution', 'etcSolutionWork', 'etc', 'etcApprovalBookFileChange', 'etcApprovalBookSubmit', 'etcEtc', 'etcBookStockStorage']
    if (this.navs.includes(props.nav) && constructors[props.nav]) { constructors[props.nav](this) }

    // 핸들러 값 처리
    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadResource = this.loadResource.bind(this)
    this.prepared = this.prepared.bind(this)
    this.loadItems = this.loadItems.bind(this)
    this.handle = this.handle.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  componentDidUpdate(prevProps) {
    const condition = this.watchs.some(({ prop }) => {
      const prev = _.get(prevProps, prop), next = _.get(this.props, prop)
      return typeof prev === 'object' || typeof next === 'object' ? JSON.stringify(prev) !== JSON.stringify(next) : prev !== next
    })
    if (condition) {
      return this.setState(this.initialState(this.props), () => this.initialize())
    }
  }

  initialState(props) {
    const next = { loading: true, error: false, items: [], total: 0, selecteds: [] }
    this.watchs.forEach(watch => {
      if (watch.state) { _.set(next, watch.state, props[watch.prop] || watch.defaultProp) }
    })
    return next
  }

  // 리소스 불러오기 @ 2023.01.30 최종판
  async loadResource(name, method) {
    const that = this
    const { resource = {} } = this.state

    // 리소스를 가져오면 필터의 컨디션에 세팅을 해준다.
    resource[name] = await api.get(`/resources/admin2/${method}`).catch(e => ({}))

    await new Promise(r => that.setState({ resource }, r))
  }

  async initialize() {
    await new Promise((r) => this.setState({ loading: true }, r))
    if (!this.navs.includes(this.props.nav) || !constructors[this.props.nav]) {
      return this.setState({ loading: false, error: true })
    }
    await this.loadResource('retail', 'takeRetails') // 유통사 정보 리소스 불러오기
    await this.prepared()
    await this.loadItems()
  }

  // 필요한 리소스 또는 컴포넌트 사용준비를 하는 구간
  async prepared() {
    const that = this
    const { resource = {} } = this.state
    
    // 외부유통 활동 유저를 찾기 위한 구분자
    if (resource.retail && _.get(that, 'filterOptions.retails')) {
      const conditions = Object.values(resource.retail)
        .map((retail) => ({ name: `${retail.name}`, text: `${retail.text}` }))
      _.set(that, 'filterOptions.retails.conditions', conditions)
    }
  }

  // 현재 페이지 데이터를 불러오는 기능
  async loadItems(settings = {}, options = { returnal: false }) {
    const { filters, sorts, keywordsOption, keywords, page, limit, startAt, endAt } = this.state

    const query = {}
    query.usedCount = true
    query.startAt = startAt
    query.endAt = endAt
    query.page = page
    query.limit = limit
    query.sort = sorts.join(',')
    
    query.method = filters.method
    query.status = filters.status
    query.refundStatus = filters.refundStatus
    query.supplyStatus = filters.supplyStatus

    // 종이도서 결제내역
    if (this.props.nav === 'paperBook') {
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 전자도서 결제내역
    if (this.props.nav === 'electronicBook') {
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 종이도서 결제내역
    if (this.props.nav === 'book') {
      query.payType = filters.payType
      query.retails = filters.retails
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 작가서비스 결제내역
    if (this.props.nav === 'solution') {
      query.category = filters.category
      query.flags = ['currentUser', 'currentSeller', 'currentProducts', 'currentOrders'].join(',')
    }

    // 작가서비스 추가작업비 결제내역
    if (this.props.nav === 'etcSolutionWork') {
      query.flags = ['currentUser', 'currentSeller', 'currentProducts', 'currentOrders'].join(',')
    }

    // 기타 결제내역
    if (this.props.nav === 'etc') {
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 파일교체비 결제내역
    if (this.props.nav === 'etcApprovalBookFileChange') {
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 가격변경비 결제내역
    if (this.props.nav === 'etcApprovalBookSubmit') {
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }

    // 재고서비스
    if (this.props.nav === 'etcBookStockStorage') {
      query.flags = ['currentUser', 'currentOrders', 'stockStorageState', 'childrenParcelStockStoragePay'].join(',')
    }

    // 기타 결제내역
    if (this.props.nav === 'etc') {
      query.flags = ['currentUser', 'currentOrders'].join(',')
    }
    
    if (keywordsOption) { query.keywordsOption = keywordsOption }
    if (keywords) { query.keywords = keywords }

    Object.keys(settings).forEach((key) => { query[key] = settings[key] })

    const output = await api.get(`/pays/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
      .then(({ count, rows }) => ({ rows, count, error: false }))
      .catch(e => ({ rows: [], count: 0, error: e.message }))

    const { count, rows, error } = output
    if (options.returnal) { return output }
    return this.setState({ items: rows.map((row, _index) => ({ ...row, _index })), total: count, loading: false, error })
  }

  handle(...values) {
    const next = { ...this.state }
    if (typeof values[0] === 'string') {
      _.set(next, values[0], values[1])
      return this.setState(next, () => values[2] ? values[2](null, next) : null)
    }
    if (typeof values[0] === 'object' && !values[0].length) { Object.keys(values[0]).forEach(key => _.set(next, key, values[0][key])) }
    if (typeof values[0] === 'object' && values[0].length) { values[0].forEach(e => Object.keys(e).forEach(key => _.set(next, key, e[key]))) }
    return this.setState(next, () => values[1] ? values[1](null, next) : null)
  }

  render() {
    const { loading, error } = this.state
    if (loading) { return null }

    const { initialize, handle, loadItems } = this
    const { admin, location, history, match, title, nav } = this.props
    const {
      keywordsOption, keywords, filters, sorts, total, resource, items, page, limit, blockLimit, selecteds,
      startAt, endAt
    } = this.state
    const listProps = {
      admin, location, history, match, title, nav, error,
      keywordsOption, keywords, filters, sorts, resource, items, selecteds,
      total, page, limit, blockLimit,
      startAt, endAt,
      initialize, handle, loadItems,
      filterOptions: this.filterOptions,
      sortOptions: this.sortOptions,
      keywordsOptions: this.keywordsOptions
    }

    if (['book'].includes(nav)) { return <BookList {...listProps} /> }
    if (['paperBook'].includes(nav)) { return <BookList type="paperBook" {...listProps} /> }
    if (['electronicBook'].includes(nav)) { return <BookList type="electronicBook" {...listProps} /> }
    if (['solution'].includes(nav)) { return <SolutionList {...listProps} /> }
    if (['etcSolutionWork'].includes(nav)) { return <EtcSolutionWorkList {...listProps} /> }
    if (['etcApprovalBookFileChange'].includes(nav)) { return <EtcApprovalBookFileChangeList {...listProps} /> }
    if (['etcApprovalBookSubmit'].includes(nav)) { return <EtcBookSubmitList {...listProps} /> }
    if (['etcEtc', 'etc'].includes(nav)) { return <EtcEtcList {...listProps} /> }
    if (['etcBookStockStorage'].includes(nav)) { return <EtcBookStockStorage {...listProps} /> }

    return null
  }
}

PayListContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,
  
  title: PropTypes.string,
  nav: PropTypes.string,

  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),

  startAt: PropTypes.instanceOf(Date),
  endAt: PropTypes.instanceOf(Date),
}

PayListContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  keywordsOption: 'all',
  keywords: '',
  page: 1,
  limit: 30,
  blockLimit: 5,
  
  title: '도서전체',
  nav: 'book',

  filters: null,
  sorts: null,

  startAt: moment().startOf('month').add(-10, 'years').toDate(),
  endAt: moment().endOf('day').toDate(),
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state)
})

export default withRouter(connect(mapStateToProps, null)(PayListContainer))
