import cryptoJs from 'crypto-js'

export const enc = (data, key) =>
  cryptoJs.AES.encrypt(JSON.stringify(data), key).toString()

export const dec = (text, key) => {
  try {
    const bytes = cryptoJs.AES.decrypt(text, key)
    return JSON.parse(bytes.toString(cryptoJs.enc.Utf8))
  } catch(e) {
    return ''
  }
}
