import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import BackupScheduler from 'components/extras/BackupScheduler'
import Page from 'components/layouts/Page'

class ResourcePageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav = 'all' } = match.params || {}

    let breadcrumb = ['홈', '백업'], title = '백업'

    return (
      <Page>
      <Helmet>
        <title>{title} - BOOKK</title>
      </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>
          <Route
              path="/backups/:nav?"
              exact
              component={(props) => {
                const { location } = props
                const search = location.search ? qs.parse(location.search) : {}
                return <BackupScheduler {...search} />
              }}
            />
        </Switch>
      </Page>
    )
  }
}

export default ResourcePageContainer
