import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL

class BookNoColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    // 도서의 머리를 의미한다.
    const meta = {}
    meta.parent = _.get(item, 'parent')
    meta.bookNo = _.get(item, 'content.bookNo')
    meta.isBrunch = _.get(item, 'content.brunch')
    meta.isOld = _.get(item, 'content.old')
    meta.productId = _.get(item, 'id') || null
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <a
            href={`${REACT_APP_CLIENT_URL}/bookStore/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong style={{ fontSize: '0.9em' }}>
              {meta.bookNo || '미발급'}
            </strong>
          </a>
          {meta.isBrunch
            ? (
            <div>
              <small>
                <small style={{ marginRight: '0.10rem' }}><strong>브런치</strong></small>
                <small>도서</small>
              </small>
            </div>
            ) : null}
          {meta.isOld
            ? (
            <div>
              <small>
                <small style={{ marginRight: '0.10rem' }}><strong>구판</strong></small>
              </small>
            </div>
            ) : null}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookNoColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookNoColumnContainer.defaultProps = {
  item: {}
}

export default BookNoColumnContainer
