import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api, { REACT_APP_FILE_URL } from 'services/api'
import moment from 'services/moment'

import { comma, unit } from 'services/utils'
import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Lnr from 'components/utils/Lnr'
import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

const ModalForm = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; padding-top: 0; max-width: 400px;
  
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1.5rem 0; font-size: 1.3em;
    font-weight: 700;
  }

  & > section.preview {
    position: relative; box-sizing: border-box;

    & > div.images {
      position: relative; box-sizing: border-box;
      & > img {
        display: block; margin: 0.5rem auto; border-radius: 0.35rem;
        max-width: 100%;
        position: relative; box-sizing: border-box;
      }
    }

    & > div.image {
      position: relative; box-sizing: border-box;
      & > img {
        display: block; margin: 0 auto; border-radius: 0.35rem;
        max-width: 100%;
        position: relative; box-sizing: border-box;
      }
    }

    & > div.document {
      position: relative; box-sizing: border-box;
      padding: 1rem; border-radius: 0.35rem;
      box-shadow: 0 0 3px ${palette('muted', 10)};
      & > div.extension {
        position: relative; box-sizing: border-box;
        font-size: 1.3em; font-weight: 700;
        padding: 0.35rem 0;
      }
      & > div.subscript {
        line-height: 1.612em; font-size: 0.9em;
        color: ${palette('muted', 4)};
      }
    }

    & > div.empty {
      position: relative; box-sizing: border-box;
      line-height: 1.612em; font-size: 0.9em; opacity: 0.3;
      padding: 3rem 0.5rem; color: ${palette('muted', 4)};
      & strong { color: ${palette('muted', 1)}; }
    }
  }

  & > section.form {
    position: relative; box-sizing: border-box;
    & > div { padding: 0.5rem 0; }
    & > form { padding: 0.5rem 0; }
  }

  & > footer {
    position: relative; box-sizing: border-box;
    padding-top: 1rem;
  }

  & .menus {
    display: flex; justify-content: flex-end;
    position: relative; box-sizing: border-box;
    & > a {
      position: relative; box-sizing: border-box;
      display: block; padding: 0.25rem 0.5rem; transition: all 0.3s;
      color: ${palette('muted', 5)}; text-decoration: none;
      &:hover { color: ${palette('darkblue', 2)} }
    }
  }

  & input[type=text],
  & input[type=date],
  & input[type=number] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 5)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & input[type=file] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & select {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem; font-weight: 500;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & textarea {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.75rem; line-height: 1.615rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & a.button {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    background: ${palette('darkblue', 8)}; color: ${palette('muted', 21)};
    border: 0; border-radius: 0.35rem; font-weight: 700; text-decoration: none;
    text-align: center; padding: 0.75rem 0; font-size: 1em;
    &:hover { background: ${palette('darkblue', 15)}; color: ${palette('muted', 21)}; }
  }
`

const File = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between; align-items: center;
  padding: 0.25rem;
  & > div.meta {
    position: relative; box-sizing: border-box;
    & > div.fileName {
      position: relative; box-sizing: border-box;
      font-weight: 500; color: ${palette('grayscale', 1)};
      padding: 0.35rem 0;
    }
    & > div.fileSize {
      position: relative; box-sizing: border-box;
      font-size: 0.8em; color: ${palette('grayscale', 3)};
    }
  }
  & > div.buttons {
    position: relative; box-sizing: border-box;
    display: flex; justify-content: flex-end;
    & > a {
      position: relative; box-sizing: border-box;
      display: block; padding: 0.35rem 0.5rem; 
      white-space: nowrap; color: ${palette('grayscale', 3)};
      text-decoration: none; font-size: 1.2em;
    }
  }
`

File.Group = styled.div`
  position: relative; box-sizing: border-box;
`


// 상태 값을 바꾸어주는 모달창
const DownloadsModal = ({ ...props }) => {
  const { isOpen = false, onClose = () => {}, parent } = props
  const modalProps = { isOpen, onClose }

  const { item = {}, loadItems = async () => {} } = parent

  const meta = {}
  meta.files = _.get(item, 'files') || []
  meta.fileCount = _.get(item, 'files.length') || 0


  // 파일 다운로드 기능
  const actions = {}
  
  actions.downloadFile = async (file = {}) => {
    if (!file.path) { return alert(`파일 다운로드 경로가 올바르지 않습니다.`) }
    return window.open(`${REACT_APP_FILE_URL}${file.path}`)
  }

  return (
    <Modal {...modalProps}>
      <ModalForm>
        <header>
          전문가심사 첨부파일 <small>총 {comma(meta.fileCount)}건</small>
        </header>
        <section>
          <File.Group>
            {(meta.files || []).map((file, fIdx) => {
              return (
                <File key={`file_${fIdx}`}>
                  <div className="meta">
                    <div className="fileName">{file.originalname}</div>
                    <div className="fileSize">{file.size ? unit(file.size) : '0KB'}</div>
                  </div>
                  <div className="buttons">
                    <a
                      href="#downloadFile"
                      onClick={(e) => {
                        return [e.stopPropagation(), e.preventDefault(), actions.downloadFile(file)]
                      }}
                    >
                      <Lnr c="download2"/>
                    </a>
                  </div>
                </File>
                )
              })}
          </File.Group>
        </section>
        <footer>
          <a
            href="#close"
            className="button"
            style={{ minWidith: '100%', maxWidith: '100%', width: '100%' }}
            onClick={(e) => [e.stopPropagation(), e.preventDefault(), modalProps.onClose()]}
          >
            닫기
          </a>
        </footer>
      </ModalForm>
    </Modal>
  )
}

// 도서의 썸네일을 보여주는 작업
class FilesColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { modal: {} }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { modal } = this.state
    const { item = {}, loadItems = () => {} } = this.props

    const meta = {}
    meta.fileCount = _.get(item, 'files.length') || 0

    return (
      <Column>
        <section
          onClick={e => {
            if (!meta.fileCount) {
              return [e.stopPropagation(), e.preventDefault(), alert(`해당 전문가 신청서에는 첨부파일이 존재하지 않습니다.`)]
            }
            return [e.stopPropagation(), e.preventDefault(), this.setState({ modal: { name: 'DownloadsModal' } })]
          }}
          style={{ cursor: 'pointer' }}
        >
          첨부파일 <strong>{comma(meta.fileCount)}</strong> 건          
      </section>

        {modal && modal.name === 'DownloadsModal'
          ? (
            <DownloadsModal parent={{ item, loadItems }} isOpen={true} onClose={e => this.setState({ modal: null })} />
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

FilesColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

FilesColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default FilesColumnContainer
