import React from 'react'
import api from 'services/api'

import _ from 'lodash'
import moment from 'services/moment'
import backdoor from 'services/backdoor'

import OptionGenerator from './OptionGenerator'

import SolutionThumbnailColumn from './Columns/SolutionThumbnailColumn'
import SolutionPortfolioColumn from './Columns/SolutionPortfolioColumn'

import { comma } from 'services/utils'

import { Form, Meta } from './utils'

// 추후 비동기 인터페이스 때문에 컴포형태로 보관
class SolutionContainer extends React.Component {

  constructor(props) {
    super(props)

    this.initiForm = this.initiForm.bind(this)
    this.doCopyText = this.doCopyText.bind(this)
    this.doSave = this.doSave.bind(this)
    this.doRemove = this.doRemove.bind(this)
    this.doHandOver = this.doHandOver.bind(this)

    this.state = {
      form: this.initiForm(props.item)
    }
  }

  initiForm(item = {}) {
    const form = {}

    form.solutionName = _.get(item, 'content.name')
    form.solutionSubName = _.get(item, 'content.subName')
    form.solutionSellerName = _.get(item, 'content.sellerName')
    
    form.productStatus = _.get(item, 'status')
    form.usedOptions = _.get(item, 'content.usedOptions') ? 'true' : 'false'
    form.options = _.get(item, 'content.options') // 해당 솔루션에 설정된 옵션들
    form.age =_.get(item, 'content.conditions.age')
    form.strategy =_.get(item, 'content.conditions.strategy')
    form.minAmount =_.get(item, 'content.conditions.minAmount')
    
    form.description =_.get(item, 'content.description')
    form.detail =_.get(item, 'content.content.detail')
    form.care =_.get(item, 'content.content.care')

    form.retails =_.get(item, 'retails') || []

    form.askMessage =_.get(item, 'content.content.askMessage')
    form.acceptMessage =_.get(item, 'content.content.acceptMessage')
    
    form.defaultPrice =_.get(item, 'content.defaultPrice') || 0

    form.memo = _.get(item, 'memo')
    form.royaltyRates = _.get(item, 'royaltyRates') || { bookk: { name: 'bookk', rate: 0.80 } }  

    form.sellerMessage = _.get(item, 'sellerMessage.message')
    form.eventKeywords = _.get(item, 'eventKeywords') && Array.isArray(_.get(item, 'eventKeywords'))
      ? _.get(item, 'eventKeywords').join(',')
      : ''

    // @ 통계정보 변경 기능
    form.readCount =_.get(item, 'analytics.readCount')
    form.sellCount =_.get(item, 'analytics.sellCount')
    form.populatePoint =_.get(item, 'analytics.populatePoint')
    
    return form
  }

  // 여러 파일명 복사 기능
  async doCopyText(text) {
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }

    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // 이관 이첩 기능
  async doHandOver() {
    const { initialize } = this.props
    const previous = this.props.item, form = {}

    form.accountId = window.prompt(`이첩을 받을 계정명을 적어주세요.`)
    if (!form.accountId) { return }

    const result = await api.put(`/products/admin2/${previous.id}/hand-over`, { previous, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }

    return initialize ? initialize() :null
  }

  // 변경된 상품정보를 업데이트 한다.
  async doSave() {
    const { initialize } = this.props
    const previous = this.props.item, form = this.state.form
    if (!window.confirm(`해당 변경내용으로 반영을 할까요?`)) { return }
    const result = await api.put(`/products/admin2/${previous.id}`, { previous, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 저장하였습니다.`)
    return initialize ? initialize() :null
  }

  // 상품을 삭제하는 기능
  async doRemove() {
    const { item } = this.props
    if (!window.confirm(`상품을 삭제할까요?`)) { return }

    const result = await api.delete(`/products/admin2/${item.id}`).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 처리하였습니다.`)
    return window.close()
  }

  render() {
    const { doHandOver, doCopyText, doSave, doRemove } = this
    const { form } = this.state
    const { item, resource, initialize } = this.props

    // 본래의 변동되지 않는 정보를 표현하기
    const meta = {}
    meta.retails = [{ name: 'bookk', text: '부크크' }]


    meta.solutionCategoryText = _.get(item, 'content.category.text') || '기타'
    meta.solutionCategoryName = _.get(item, 'content.category.text') || '기타'


    meta.solutionId = _.get(item, 'content.id') || '-'
    meta.solutionNo = _.get(item, 'content.solutionNo') || '-'

    meta.user = _.get(item, 'extras.user') || {}
    meta.accountId = _.get(meta.user, 'accountId') || '-'
    meta.email = _.get(meta.user, 'email') || '-'

    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
    meta.updatedMt = _.get(item, 'updatedAt') ? moment(_.get(item, 'updatedAt')) : null

    // 썸네일, 포트폴리오
    const uProps = { item, initialize }
    const ogProps = {
      solution: {
        category: _.get(item, 'content.category'),
        options: _.get(form, 'options'),
        defaultPrice: _.get(item, 'content.defaultPrice')
      },
      handleSave: async (data = {}) => {
        const next = { ...form, options: data.options }
        this.setState({ form: next })
      }
    }

    return (
      <Form>
        <Form.Header>
          <Form.Lead>작가서비스 상품관리</Form.Lead>
          <Form.Units>
            <Form.Buttons>
              <Form.Button
                href="#doRemove"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doHandOver()]
                }}
              >
                이첩
              </Form.Button>
              <Form.Button
                href="#doRemove"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doRemove()]
                }}
              >
                삭제
              </Form.Button>
              <Form.Button
                className="primary"
                href="#doSave"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doSave()]
                }}
              >
                저장
              </Form.Button>
            </Form.Buttons>
          </Form.Units>          
        </Form.Header>
        <Form.Grid>
          <Form.Context>
            <Form.Boxs>
              <Form.Box>
                <header>
                  서비스명·약식명·판매자명
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input type="text" placeholder={meta.solutionCategoryText} disabled />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="서비스명"
                        value={form.solutionName}
                        onChange={(e) => {
                          const next = { ...form }
                          next.solutionName = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="약식명"
                        value={form.solutionSubName}
                        onChange={(e) => {
                          const next = { ...form }
                          next.solutionSubName = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="판매자명"
                        value={form.solutionSellerName}
                        onChange={(e) => {
                          const next = { ...form }
                          next.solutionSellerName = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="메모"
                        value={form.memo}
                        style={{ minHeight: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.memo = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  
                </section>
                <header>
                  판매가·수익금(%)
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="number"
                        placeholder="기본 정가"
                        value={form.defaultPrice}
                        onChange={(e) => {
                          const next = { ...form }
                          next.defaultPrice = e.target.value ? e.target.value * 1 : 0
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>

                  {Object.values(meta.retails).map((retail, rIdx) => {

                    const meta = {}
                    meta.status = { name: 'notUsed', text: '미판매', signal: '⚪' }
                    
                    if (item.retails.includes(retail.name)) { meta.status = { name: 'notUsed', text: '판매중', signal: '🟢' } }

                    return (
                      <Form.Field key={rIdx}>
                        <div className="label">
                          <strong style={{ marginRight: '0.35rem' }}>{retail.text}</strong>
                          <small>{meta.status.signal} {meta.status.text}</small>
                        </div>
                        <div className="control" style={{ maxWidth: '60px', marginRight: '0.35rem' }}>
                          <select
                            value={form.retails.includes(retail.name) ? 'true' : 'false'}
                            onChange={(e) => {
                              const next = { ...form }
                              next.retails = [...new Set([...next.retails, retail.name])]
                              if (['false'].includes(e.target.value)) { next.retails = next.retails.filter(o => o !== retail.name) }
                              return this.setState({ form: next })
                            }}
                          >
                            <option value={`true`}>🟢</option>
                            <option value={'false'}>⚪</option>
                          </select>
                        </div>
                        <div className="control" style={{ maxWidth: '100px' }}>
                          <input
                            type="number"
                            placeholder={`0`}
                            value={_.get(form, `royaltyRates.${retail.name}.rate`)}
                            onChange={(e) => {
                              const next = { ...form }
                              next.royaltyRates[retail.name] = { name: retail.name, rate: e.target.value ? e.target.value * 1 : 0 }
                              return this.setState({ form: next })
                            }}
                          />
                        </div>
                      </Form.Field>
                    )
                  })}
                </section>  
                <header>
                  정책 및 옵션
                </header>
                <section>

                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.productStatus}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.productStatus = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">진열상태</option>
                        <option value="temporary">임시</option>
                        <option value="activated">진열중</option>
                        <option value="deactivated">진열안함</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.usedOptions}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.usedOptions = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">복합옵션</option>
                        <option value="true">옵션 사용</option>
                        <option value="false">옵션 사용안함</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="number"
                        placeholder={`최소구매수량`}
                        value={_.get(form, `minAmount`) || ''}
                        onChange={(e) => {
                          const next = { ...form }
                          next.minAmount = _.isInteger(e.target.value * 1) ? (e.target.value * 1) : 1
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <select
                        value={form.age}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.age = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">나이제한</option>
                        <option value="all">전연령</option>
                        <option value="adult">성인</option>
                      </select>
                    </div>
                    <div className="control">
                      <select
                        value={form.strategy}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.strategy = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">수량제한</option>
                        <option value="limited">한정판</option>
                        <option value="normal">제한없음</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="판매 안내사항 메시지"
                        value={form.sellerMessage}
                        style={{ minHeight: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.sellerMessage = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="기획전 태그(,구분자)"
                        value={form.eventKeywords}
                        onChange={(e) => {
                          const next = { ...form }
                          next.eventKeywords = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

                
                <header>
                  조회수 및 판매수
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.35rem' }}>
                      <input
                        type="number"
                        placeholder="조회수"
                        value={form.readCount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.readCount = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.readCount = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                    <div className="control">
                      <input
                        type="number"
                        placeholder="판매수"
                        value={form.sellCount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.sellCount = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.sellCount = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <header>
                  인기점수
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.35rem' }}>
                      <input
                        type="number"
                        placeholder="인기점수"
                        value={form.populatePoint}
                        onChange={(e) => {
                          const next = { ...form }
                          next.populatePoint = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.populatePoint = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

              </Form.Box>
              
              {['true'].includes(_.get(form, 'usedOptions'))
                ? (
                  <Form.Box style={{ minWidth: '38%', maxWidth: '38%',width: '38%' }}>
                    <section><OptionGenerator {...ogProps} /></section>
                  </Form.Box>
                  )
                : null}

              <Form.Box style={{ flex: '1 1 auto', minWidth: '0', maxWidth: '100%', width: '0' }}>
                <header>
                  작가서비스 소개
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="작가서비스에 대한 간단한 설명을 작성할 수 있습니다."
                        style={{ minHeight: '190px' }}
                        value={form.description}
                        onChange={(e) => {
                          const next = { ...form }
                          next.description = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <header>
                  주문절차 설명
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="주문을 하는 과정에 필요한 절차에 대해서 설명을 기재할 수 있습니다."
                        style={{ minHeight: '190px' }}
                        value={form.detail}
                        onChange={(e) => {
                          const next = { ...form }
                          next.detail = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <header>
                  자주 묻는 질문
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="고객분들이 자주 물어보는 질문과 답변을 작성 할 수 있습니다."
                        style={{ minHeight: '200px' }}
                        value={form.care}
                        onChange={(e) => {
                          const next = { ...form }
                          next.care = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>
              <Form.Box style={{ flex: '1 1 auto', minWidth: '0', maxWidth: '100%', width: '0' }}>
                <header>
                  구매후 수락전 진행양식 전달
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="작가서비스에 대한 간단한 설명을 작성할 수 있습니다."
                        style={{ minHeight: '315px' }}
                        value={form.askMessage}
                        onChange={(e) => {
                          const next = { ...form }
                          next.askMessage = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <header>
                  작업 수락후 메시지 전달
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="주문을 하는 과정에 필요한 절차에 대해서 설명을 기재할 수 있습니다."
                        style={{ minHeight: '315px' }}
                        value={form.acceptMessage}
                        onChange={(e) => {
                          const next = { ...form }
                          next.acceptMessage = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>
            </Form.Boxs>
            
          </Form.Context>
          <Form.Aside>
            <Meta.Group style={{ margin: '1rem 0' }}>
              <Meta>
                <header>
                  <small>서비스번호</small>
                </header>
                <section>
                  <strong
                    style={{ marginRight: '0.25rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.solutionNo)}
                  >
                    {meta.solutionNo}
                  </strong>
                  <br/>
                  <small
                    style={{ fontSize: '0.5rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.solutionId)}
                  >
                    {meta.solutionId}
                  </small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>계정정보</small>
                </header>
                <section>
                  <strong
                    style={{ marginRight: '0.25rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.accountId)}
                  >
                    {meta.accountId}
                  </strong>
                  <br/>
                  <small
                    style={{ cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.email)}
                  >
                    {meta.email}
                  </small>
                  <a
                    href="#login"
                    style={{
                      display: 'block', textDecoration: 'none', margin: '0.5rem 0', color: '#333',
                      fontSize: '0.9em', padding: '0.35rem 0.65rem', borderRadius: '0.5rem', background: '#eaeaea'
                    }}
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      return await backdoor.login(item.user, `/expert/take?keywords=${item.id}`)
                    }}
                  >
                    🔐 로그인
                  </a>
                </section>
              </Meta>
            </Meta.Group>

            <Meta.Group style={{ margin: '1rem 0' }}>
              <Meta>
                <header>
                  <small>생성일</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                  <small>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>최종수정일</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.updatedMt ? meta.updatedMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                  <small>{meta.updatedMt ? meta.updatedMt.format('HH:mm') : '00:00'}</small>
                </section>
              </Meta>
            </Meta.Group>

            <Meta.Group>
              <Meta style={{ minWidth: 0, maxWidth: '100%', width: '100%' }}>
                <header>
                  <small>대표이미지</small>
                </header>
                <div className="extra">
                  <SolutionThumbnailColumn {...uProps} />
                </div>
              </Meta>
            </Meta.Group>
            <Meta.Group>
              <Meta style={{ minWidth: 0, maxWidth: '100%', width: '100%' }}>
                <header>
                  <small>포트폴리오</small>
                </header>
                <div className="extra">
                  <SolutionPortfolioColumn {...uProps} />
                </div>
              </Meta>
            </Meta.Group>
            
            <div style={{ textAlign: 'right' }}>
              <small>미저장 후 첨부파일 업로드시 작성내용은 반영 안됨.</small>
            </div>

          </Form.Aside>
        </Form.Grid>
      </Form>
    )
  }
}

export default SolutionContainer
