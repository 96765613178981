import styled from 'styled-components'
import { font, palette, size } from 'styled-theme'

const MetaSet = styled.div`
  position: relative; box-sizing: border-box;
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: 'center'; padding: 0.25rem 0; color: ${palette('muted', 7)}; 
  & > :first-child { min-width: 100px; max-width: 100px; font-size: 0.85em; }
  & > :last-child {
    flex: 1 1 100%; font-size: 0.85em; text-align: right; color: ${palette('muted', 2)};
    & > small { color: ${palette('muted', 9)}; }
    & > a { color: ${palette('muted', 2)}; text-decoration: none; }
  }
  & > .pointer { cursor: pointer; }
`

Meta.Control = styled.div`
  position: relative; box-sizing: border-box; cursor: pointer;
  display: flex;
  & > * { flex: 1 1 100%;}
  & > input[type=text],
  & > input[type=date],
  & > input[type=password],
  & > input[type=time],
  & > input[type=number],
  & > textarea {
    display: block; box-sizing: border-box; color: ${palette('muted', 2)};
    width: 100%; outline: none; border: 0; font-weight: 900;
    font-size: 1em;
    text-align: right; background: transparent; transition: all 0.3s;
    &:hover { background: ${palette('muted', 15)} }
  }
  & > select {
    display: block; box-sizing: border-box; color: ${palette('muted', 2)};
    width: 100%; outline: none; border: 0; font-weight: 900; cursor: pointer;
    font-size: 1em; direction: rtl; background: transparent; transition: all 0.3s;
    & > option { direction: rtl; text-align: right; }
    &:hover { background: ${palette('muted', 15)} }
  }
`

MetaSet.Meta = Meta

export default MetaSet