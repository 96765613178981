import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

const fieldStyles = css`
  color: ${palette('muted', 6)};
  font-family: ${font('primary')};
  input[type=text], input[type=number], input[type=date], input[type=time] {
    display: block; box-sizing: border-box; border-radius: 5px;
    box-shadow: 0 0 2px ${palette('muted', 15)}; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0 10px;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
  button.button {
    position: relative; box-sizing: border-box; font-size: 1em;
    display: inline-block; border: 0; outline: none; letter-spacing: -0.05rem;
    text-decoration: none; border-radius: 3px;
    background: ${palette('muted', 2)}; font-weight: 700; color: ${palette('muted', 20)};
    cursor: pointer;
    &:hover { background: ${palette('muted', 1)}; }
    &:disabled {
      background: ${palette('muted', 5)};
      font-weight: 400;
      color: ${palette('muted', 18)};
      cursor: default;
      &:hover {
        font-weight: 400;
        color: ${palette('muted', 18)};
        background: ${palette('muted', 5)}; 
      }
    }
  }
  button.link {
    width: 100%; height: 100%; text-align: left;
    position: absolute; left: 0; top: 0;
    box-sizing: border-box; font-size: 1em;
    display: inline-block; border: 0; outline: none; letter-spacing: -0.05rem;
    padding: 0.35rem 0.55rem; text-decoration: none;
    font-weight: 700; color: ${palette('muted', 3)};
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover { background-color: ${palette('muted', 17)}; }
    &:after {
      position: absolute;
      top: 0; right: 0;
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-left: 15px solid ${palette('muted', 9)};
    }
  }
  textarea {
    display: block; box-sizing: border-box; border-radius: 5px;
    box-shadow: 0 0 2px ${palette('muted', 15)}; transition: all 0.3s;
    border: 0; outline: none; display: block; width: 100%; padding: 0 10px;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem; resize: none;
    &:focus { background: ${palette('muted', 15)}; }
  }
  select {
    display: block; box-sizing: border-box; border-radius: 5px; transition: all 0.3s;
    box-shadow: 0 0 2px ${palette('muted', 15)};
    border: 0; outline: none; display: block; width: 100%; padding: 0;
    font-family: ${font('primary')}; font-size: 1em; letter-spacing:-0.56px;
    padding: 0.25rem 0.5rem;
    &:focus { background: ${palette('muted', 15)}; }
  }
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
    & > .text {
      padding-right: 0.5rem;
    }
  }
  .preview {
    display: flex;
    justify-content: space-between;
    img {
      max-width: 50px;
    }
  }
`

const controlCSS = css`
  a { text-decoration: none; color: ${palette('muted', 6)}; }
`

const DataSet = styled.div`
  position: relative; box-sizing: border-box;
`

DataSet.Item = styled.header`
  position: relative; box-sizing: border-box;
  display: flex; min-width: max-content;
  &:nth-child(2n) { background: ${palette('muted', 17)}; }
  cursor: ${({ cursor }) => cursor ? 'pointer' : cursor};
`

DataSet.Col = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; color: ${palette('muted', 6)};
  display: flex; align-items: center; font-family: ${font('primary')};
  padding: 0.9rem 0.5rem; overflow: hidden; text-overflow: ellipsis; white-space: pre;
  max-width: 680px;
  :not(:last-child) {
    border-right: 1px solid ${palette('muted', 15)};
  }
  ${controlCSS}
  ${fieldStyles}

  &.mobile { display: none; }
  &.desktop { display: block; }

  @media screen and (max-width: 1190px) {
    &.mobile { display: block; }
    &.desktop { display: none; }
  }
`

DataSet.Header = styled.header`
  position: relative; box-sizing: border-box;
  & > ${DataSet.Item} {
    border-top: 3px solid ${palette('muted', 2)};
    border-bottom: 1px solid ${palette('muted', 11)};
    & > ${DataSet.Col} {
      font-weight: 700; color: ${palette('muted', 1)};
    }
  }
`

DataSet.Body = styled.section`
  position: relative; box-sizing: border-box;
  & > ${DataSet.Item} {
    transition: all 0.3s;
    border-bottom: 3px solid transparent; opacity: 1;
    &:hover { border-bottom: 3px solid ${palette('muted', 1)}; }
    & > ${DataSet.Col} {
      font-size: 0.95em; 
      & strong { font-weight: 500; color: ${palette('muted', 1)}; }
    }
  }
`

DataSet.Footer = styled.footer`
  position: relative; box-sizing: border-box;
  border-top: 1px solid ${palette('muted', 11)};
  border-bottom: 1px solid ${palette('muted', 8)};
  padding: 0.5rem;
  display: flex; justify-content: space-between; align-items: center;
  & > :first-child {
    flex: 1 1 100%;
  }
`

DataSet.Search = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; padding: 0.5rem;
  & > input {
    display: block; box-sizing: border-box; border: 0; outline: none;
    padding: 0.5rem 0.75rem; letter-spacing: -0.1rem; font-size: 1.05em;
    color: ${palette('muted', 1)}; background: ${palette('muted', 21)}; border-radius: 5px; transition: all 0.3s;
    &:focus {
      font-weight: 700; color: ${palette('muted', 1)};
      background: ${palette('muted', 15)}; width: 100%;
    }
  }
`

DataSet.NotFound = styled.div`
  position: relative; box-sizing: border-box; padding: 2.5rem 1.5rem;
  flex: 1 1 100%; border-bottom: 1px solid ${palette('muted', 11)};
  font-family: ${font('primary')}; line-height: 1.381rem;
  & header {
    position: relative; box-sizing: border-box;
    font-size: 1.3em; letter-spacing: -0.05rem; color: ${palette('darkblue', 5)};
    padding-bottom: 0.25rem;
  }
  & section {
    position: relative; box-sizing: border-box;
    font-size: 0.9em; letter-spacing: -0.05rem; color: ${palette('muted', 5)};
  }
`

DataSet.Tabs = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center; margin-bottom: 0.5rem;
  & > a {
    display: block; box-sizing: border-box;  padding: 0.5rem; text-decoration: none;
    color: ${palette('muted', 3)}; transition: all 0.3s; font-weight: 700;
    &:hover { color: ${palette('muted', 1)}; }
    &.active { color: ${palette('muted', 1)}; font-weight: 900; }
  }

`


export default DataSet
