import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

const columns = [
  { header:  '수하인명', key: 'target', view: (item) => _.get(item, 'receiver') || '' },
  { header:  '수하인전화번호', key: 'targetMobile', view: (item) => _.get(item, 'mobile') || '' },
  { header:  '받는분담당자', key: 'receiver', view: (item) => _.get(item, 'receiver') || '' },
  { header:  '수하인핸드폰번호', key: 'receiverMobile', view: (item) => _.get(item, 'mobile') || '' },

  { header:  '우편번호', key: 'zip', view: (item) => _.get(item, 'zip') || '' },
  { header:  '주소', key: 'address', view: (item) => `${_.get(item, 'address1') || ''} ${_.get(item, 'address2') || ''}` },
  { header:  '박수수량', key: 'amount', view: (item) => `1` },
  { header:  '품목', key: 'parcelType', view: (item) => item.name },
  {
    header:  '택배운임', key: 'expressType',
    view: (item) => `A`
  },
  {
    header:  '운임구분', key: 'charge',
    view: (item) => {
      if (['bookk'].includes(item.retail)) { return ['pre'].includes(_.get(item, 'charge')) ? '' : '착불' }
      if (!['bookk'].includes(item.retail)) { return ['pre'].includes(_.get(item, 'charge')) ? '선불' : '' }
      return ``
    }
  },
  { header:  '발송번호', key: 'parcelId', view: (item) => _.get(item, 'id') },
  { header:  '배송메시지', key: 'bookTitle', view: (item) => _.get(item, 'message') },
]

parser.columns = columns

export default parser
