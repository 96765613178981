import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { palette } from 'styled-theme'

const colors = {}

colors.on = css`
  border: 1px solid ${palette('primary', 16)}; background: ${palette('primary', 16)}; 
  &:hover { border: 1px solid ${palette('primary', 12)}; }
`
colors.off = css`
  border: 1px solid ${palette('muted', 15)}; background: ${palette('muted', 21)}; 
  &:hover { border: 1px solid ${palette('muted', 5)}; }
`

const StyledCheckbox = styled.span`
  display: inline-block; width: 12px; height: 12px;
  overflow: hidden; border-radius: 3px; position: relative; font-size: 0;
  vertical-align: middle;
  ${({ c }) => c && colors[c] ? colors[c] : null};
`

const Checkbox = (props) => <StyledCheckbox {...props} />

Checkbox.propTypes = {
  c: PropTypes.string,
}

Checkbox.defaultProps = {
  c: 'off',
}

export default Checkbox
