import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a strong { color: ${palette('primary', 157)} !important; transition: all 0.3s; }
  & a:hover { color: ${palette('primary', 15)} !important; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  text-align: center;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

const methodTypes = {
  card: '신용카드',
  card_rebill: '정기결제',
  card_rebill_rest: '정기결제 (REST API)',
  phone: '휴대폰',
  bank: '계좌이체',
  vbank: '가상계좌',
  kakao: '카카오페이 간편결제',
  payco: '페이코 간편결제',
  npay: '네이버페이 간편결제',
  free: '무료',
  etc: '기타'
}

// 목록부의 데이터에 의존성을 갖는 컬럼컴포넌트
class PriceColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }
  
  render() {
    const { item } = this.props


    const meta = {
      method: item.method,
      totalPrice: item.totalPrice || 0
    }

    meta.methodText = item.method
      ? methodTypes[item.method]
      : item.method

    return (
      <Column>
        <Meta.Group>
          <Meta style={{ maxWidth: '100%' }}>
            <header>
              <small>{meta.methodText}</small>
            </header>
            {['free'].includes(item.method)
              ? (
                <section>
                  <strong style={{ fontWeight: '700' }}>무료</strong>
                </section>
              )
              : null}
            {!['free'].includes(item.method)
              ? (
                <section>
                  <strong style={{ marginRight: '0.35rem', fontWeight: '700' }}>{comma(meta.totalPrice)}</strong>
                  <small>원</small>
                </section>
              )
              : null}
          </Meta>
        </Meta.Group>
      </Column>
    )
  }
}

PriceColumnContainer.propTypes = {
  item: PropTypes.object,
}

PriceColumnContainer.defaultProps = {
  item: {}
}

export default PriceColumnContainer
