import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './utils'

class NotFoundContainer extends Component {
  render() {
    const { user, location, history, match, message } = this.props
    return (
      <Wrapper>{message}</Wrapper>
    )
  }
}

NotFoundContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,

  message: PropTypes.any,
}

NotFoundContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},

  message: '페이지를 찾을 수 없습니다.',
}

export default NotFoundContainer
