import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'
import moment from 'services/moment'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  font-family: ${font('primary')};

  & > div.bookType { position: relative; box-sizing: border-box; }
  & > div.bookTitle { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookAuthor { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.bookPrice { position: relative; box-sizing: border-box; font-size: 0.85em; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class BookDetailWithApprovalColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {}

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item } = this.props

    const extras = item.extras || {}
    const currentBook = extras.currentBook || {}

    const meta = {}
    meta.bookTitle = _.get(currentBook, 'title') || '도서명 미기재'
    meta.bookAuthor = _.get(currentBook, 'author') || '저자명 미기재'
    meta.bookTypeAlias = _.get(currentBook, 'type') === 'paperBook'
      ? '종이도서'
      : (_.get(currentBook, 'type') === 'electronicBook' ? '전자도서' : '기타')
    meta.bookPurpose = _.get(currentBook, 'purpose.text') || '출판목적 불분명'
    meta.isbn = _.get(currentBook, 'isbn')

    meta.locked = _.get(item, 'content.locked') || false
    meta.lockedAt = _.get(item, 'content.lockedAt') || null
    meta.lockedMt = meta.lockedAt ? moment(meta.lockedAt) : null
    
    // 링크 이동을 위한 값 배정
    meta.productId = _.get(item, 'content.productId') || null
    meta.userId = _.get(item, 'user') || null

    // 해당 도서 가격에 대한 추적(출판목적, 도서종류)
    meta.bookPrice = 0
    if (['external', 'internal'].includes(_.get(currentBook, 'purpose.name'))) {
      meta.bookPrice = _.get(currentBook, 'type') === 'paperBook'
        ? _.get(currentBook, 'basicPrice') * 1
        : _.get(currentBook, 'defaultPrice') * 1
    }
    if (_.get(currentBook, 'purpose.name') === 'collection') {
      meta.bookPrice = _.get(currentBook, 'collectionPrice') * 1
    }
    meta.bookPriceWithComma = meta.bookPrice ? comma(meta.bookPrice) : 0

    return (
      <Column>
        {(meta.locked && ['review'].includes(item.status))
          ? (
            <div style={{ padding: '0.75rem', background: '#111', borderRadius: '0.35rem', marginBottom: '0.75rem' }}>
                <div>⛔ <strong style={{ color: 'white' }}>파일교체 작업중!</strong></div>
                {meta.lockedMt
                  ? (
                    <small>
                      <span style={{ color: '#929394', marginRight: '0.35rem' }}>{meta.lockedMt.format('YYYY.MM.DD')}</span>
                      <span style={{ color: '#929394' }}><strong style={{ color: 'white', fontWeight: 900 }}>{meta.lockedMt.format('HH:mm')} ~</strong></span>
                    </small>
                  ) : null}
            </div>
          )
          : null}
        <div className="bookType">
          <small style={{ marginRight: '0.15rem' }}>{meta.bookTypeAlias}</small>
          <small><strong>{meta.isbn || meta.bookPurpose}</strong></small>
        </div>
        <div className="bookTitle">
          <a
            href={`/products/book/${meta.productId}`}
            target="_blank"
            onClick={e => {
              if (!meta.productId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`아직 상품이 존재하지 않거나, 상품이 만들어지지 않은 문서입니다.`)]
              }
              return
            }}
          >
            <strong>{meta.bookTitle}
            </strong>
          </a>
        </div>
        <div className="bookAuthor">
          <a
            href={`/users/${meta.userId}`}
            target="_blank"
            onClick={e => {
              if (!meta.userId) {
                return [e.stopPropagation(), e.preventDefault(), alert(`유저정보가 배정되지 못한 문서입니다. 시스템 관리자에게 문의해주세요.`)]
              }
              return
            }}
          >
            <small>{meta.bookAuthor}</small>
          </a>
        </div>
        <div className="bookPrice">
          {meta.bookPrice > 0
            ? (
              <>
                <strong style={{ marginRight: '0.15rem' }}>{meta.bookPriceWithComma}</strong>
                <small>원</small>
              </>
            )
            : (
              <>
                <strong>무료</strong>
              </>
            )}
        </div>
      </Column>
    )
  }
}

BookDetailWithApprovalColumnContainer.propTypes = {
  item: PropTypes.object,
}

BookDetailWithApprovalColumnContainer.defaultProps = {
  item: {}
}

export default BookDetailWithApprovalColumnContainer
