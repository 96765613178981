import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const { REACT_APP_CLIENT_URL } = process.env

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
`

// 신청한 결제내역 기준으로 만들어진 문서를 불러온다.
class SubjectDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
    this.doShowApproval = this.doShowApproval.bind(this)
  }

  async initialize() {
    this.setState({})
  }

  async doShowApproval (item = {}) {
    const url = `${REACT_APP_CLIENT_URL}/community/payReview/?id=${item.id}`
    return window.open(url)
  }
  
  render() {
    const { item = {} } = this.props

    const extras = item.extras || {}
    const currentPay = extras.currentPay || {}

    const meta = {}
    meta.subject = _.get(item, 'subject') || '후기인증 제목없음'
    meta.payId = _.get(item, 'content.payId') || null
    meta.payNo = _.get(currentPay, 'payNo') || ''

    return (
      <Column>
        <div style={{ minWidth: '100%', maxWidth: '100%'}}>
          <div>
            <a
              href="#openApproval"
              onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await this.doShowApproval(item)]}
            >
              <strong>{meta.subject}</strong>
            </a>
          </div>
          <div>
            <small>
              {meta.payId
                ? `🧺 ${meta.payNo}`
                : `⚪ 주문번호 없음`}
            </small>
          </div>
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

SubjectDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

SubjectDetailColumnContainer.defaultProps = {
  item: {}
}

export default SubjectDetailColumnContainer
