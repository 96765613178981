import React from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'
import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'
import { BANKS } from 'services/constants/bank'
import { comma } from 'services/utils'

import { Header, Lnr, Caret, Checkbox, DataSet, MetaSet, Meta, Buttons, Button } from './utils'

class SummaryContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = this.initialState.bind(this)
    this.initialize = this.initialize.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize()
  }

  initialState(props) {
    const state = { error: false, loading: true || {} }
    return state
  }

  async initialize() {
    this.setState({ error: false, loading: false })
  }

  render() {
    const { error, loading } = this.state
    if (loading) { return null }

    if (error) {
      return (
        <div>
          <Header>
            <Header.Lead>기록정보</Header.Lead>
          </Header>
          <div>
            <div>문제가 발생하였습니다.</div>
            <div><small>{error}</small></div>
          </div>
        </div>
      )
    }

    const { parent } = this.props
    const item = parent.item || {}

    // 메타정보
    const meta = {}
    meta.bankAccountLastAt = _.get(item, 'bankAccount.updatedAt')
    meta.withdrawLimitAt = _.get(item, 'withdrawLimitAt')
    return (
      <div>
        <Header>
          <Header.Lead>사업자정보</Header.Lead>
        </Header>

        <MetaSet>
          <Meta>
            <div>구분</div>
            <Meta.Control>
              <select
                value={_.get(item, 'business.family') || ''}
                onChange={e => parent.handle({ 'item.business.family': e.target.value })}
                >
                <option value="natural">개인</option>
                <option value="legal">법인</option>
              </select>
            </Meta.Control>
          </Meta>
          <Meta>
            <div>업체명</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.name') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.name': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>사업자번호</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.registrationNumber') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.registrationNumber': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>업태</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.type') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.type': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>종목</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.item') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.item': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>회사전화</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.officeNumber') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.officeNumber': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>세금·이메일</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.email') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.email': e.target.value })}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>우편번호</div>
            <Meta.Control>
              <input
                type="text" readOnly
                defaultValue={_.get(item, 'business.zip') || ''} placeholder={`미설정`}
                onClick={e => {
                  const options = {}
                  options.onSelected = (selected) => {
                    parent.handle({ 'item.business.zip': selected.zipNo, 'item.business.address1': selected.roadAddr })
                  }
                  return parent.openSider('userFindAddress', options)
                }}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>동주소</div>
            <Meta.Control>
              <input
                type="text" readOnly
                defaultValue={_.get(item, 'business.address1') || ''} placeholder={`미설정`}
                onClick={e => {
                  const options = {}
                  options.onSelected = (selected, cb) => {
                    parent.handle({ 'item.business.zip': selected.zipNo, 'item.business.address1': selected.roadAddr }, cb)
                  }
                  return parent.openSider('userFindAddress', options)
                }}
              />
            </Meta.Control>
          </Meta>
          <Meta>
            <div>상세주소</div>
            <Meta.Control>
              <input
                type="text"
                value={_.get(item, 'business.address2') || ''} placeholder={`미설정`}
                onChange={e => parent.handle({ 'item.business.address2': e.target.value })}
              />
            </Meta.Control>
          </Meta>
        </MetaSet>
        
        <Header>
          <Header.Lead>기록정보</Header.Lead>
        </Header>

        <MetaSet>
          <Meta>
            <div>이메일인증</div>
            <div>{item.activatedToEmailAt ? <>{moment(item.activatedToEmailAt).format('YYYY.MM.DD')} <small>{moment(item.activatedToEmailAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>휴대폰인증</div>
            <div>{item.activatedToMobileAt ? <>{moment(item.activatedToMobileAt).format('YYYY.MM.DD')} <small>{moment(item.activatedToMobileAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>주민번호인증</div>
            <div>{item.activatedToIdentityAt ? <>{moment(item.activatedToIdentityAt).format('YYYY.MM.DD')} <small>{moment(item.activatedToIdentityAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>사업자인증</div>
            <div>{item.activatedToBusinessAt ? <>{moment(item.activatedToBusinessAt).format('YYYY.MM.DD')} <small>{moment(item.activatedToBusinessAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>가입</div>
            <div>{item.createdAt ? <>{moment(item.createdAt).format('YYYY.MM.DD')} <small>{moment(item.createdAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>계좌변동일</div>
            <div>
              {meta.bankAccountLastAt
                ? (
                  <>
                    {moment(meta.bankAccountLastAt).format('YYYY.MM.DD')}
                    <small>{moment(meta.bankAccountLastAt).format('HH:mm')}</small>
                  </>
                )
                : '정보없음'}
            </div>
          </Meta>
          <Meta>
            <div>기준금변경일</div>
            <div>{item.withdrawLimitAt ? <>{moment(item.withdrawLimitAt).format('YYYY.MM.DD')} <small>{moment(item.withdrawLimitAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>최근변경일</div>
            <div>{item.updatedAt ? <>{moment(item.updatedAt).format('YYYY.MM.DD')} <small>{moment(item.updatedAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>최근로그인</div>
            <div>{item.loggedAt ? <>{moment(item.loggedAt).format('YYYY.MM.DD')} <small>{moment(item.loggedAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>개인정보동의</div>
            <div>{item.privacyAgreementAt ? <>{moment(item.privacyAgreementAt).format('YYYY.MM.DD')} <small>{moment(item.privacyAgreementAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>서비스이용동의</div>
            <div>{item.serviceAgreementAt ? <>{moment(item.serviceAgreementAt).format('YYYY.MM.DD')} <small>{moment(item.serviceAgreementAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>SMS수신</div>
            <div>{item.allowSmsAt ? <>{moment(item.allowSmsAt).format('YYYY.MM.DD')} <small>{moment(item.allowSmsAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>레터수신</div>
            <div>{item.allowLetterAt ? <>{moment(item.allowLetterAt).format('YYYY.MM.DD')} <small>{moment(item.allowLetterAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>레터동의창</div>
            <div>{item.askLetterAt ? <>{moment(item.askLetterAt).format('YYYY.MM.DD')} <small>{moment(item.askLetterAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
          <Meta>
            <div>탈퇴</div>
            <div>{item.leavedAt ? <>{moment(item.leavedAt).format('YYYY.MM.DD')} <small>{moment(item.leavedAt).format('HH:mm')}</small></> : '정보없음'}</div>
          </Meta>
        </MetaSet>

        <Buttons>
          <Button
            href="#save"
            onClick={e => {
              if (!window.confirm(`최종 저장을 진행할까요?`)) { return [e.stopPropagation(), e.preventDefault()] }
              return [e.stopPropagation(), e.preventDefault(), parent.handleSave(null, () => alert(`정상적으로 저장하였습니다.`))]
            }}>
              저장하기
          </Button>
        </Buttons>

      </div>
    )
  }
}

SummaryContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,

  sider: PropTypes.object,
  openSider: PropTypes.func,
  closeSider: PropTypes.func,

  item: PropTypes.object,
  initialize: PropTypes.func
}

SummaryContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  
  sider: {},
  openSider: () => {},
  closeSider: () => {},
  
  item: {},
  initialize: () => {},
  handle: () => {},
  handleSave: () => {}
}

export default SummaryContainer
