import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router-dom'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import Page from 'components/layouts/Page'
import FreeBookCoverDesignList from 'components/extras/FreeBookCoverDesignList'
import BookStoreGenreList from 'components/extras/BookStoreGenreList'
import BookStoreCornerList from 'components/extras/BookStoreCornerList'
import SolutionStoreCategoryList from 'components/extras/SolutionStoreCategoryList'
import SolutionStoreCornerList from 'components/extras/SolutionStoreCornerList'
import HomeCornerList from 'components/extras/HomeCornerList'
import PrimaryFileList from 'components/extras/PrimaryFileList'
import PopupList from 'components/extras/PopupList'

class ResourcePageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { admin, history, location, match } = this.props
    const { nav } = match.params || {}

    let breadcrumb = ['홈', '설정'], title = '설정'

    let navs = []
    navs.push({ name: 'freeBookCoverDesigns', text: '무료표지 디자인', active: false })
    navs.push({ name: 'bookStoreGenres', text: '서점 장르', active: false })
    navs.push({ name: 'bookStoreCorners', text: '서점 기획전', active: false })
    navs.push({ name: 'solutionStoreCategories', text: '작가샵 카테고리', active: false })
    navs.push({ name: 'solutionStoreCorners', text: '작가샵 기획전', active: false })
    navs.push({ name: 'homeCorners', text: '공식 홈', active: false })
    navs.push({ name: 'primaryFiles', text: '자료실 파일', active: false })
    navs.push({ name: 'popups', text: '팝업창', active: false })
    navs = navs.map(n => n.name === nav ? { ...n, active: true } : n)

    const currentNav = navs.find(n => n.name === nav) || {}
    if (currentNav.text) {
      breadcrumb.push(currentNav.text)
      title = currentNav.text
    }

    return (
      <Page>
      <Helmet>
        <title>설정({[...breadcrumb].pop()}) - BOOKK</title>
      </Helmet>
        <Page.BreadCrumb path={breadcrumb} />
        <Switch>

          <Route
              path="/resources/:nav?"
              exact
              component={(props) => {
                const { location } = props
                const search = location.search ? qs.parse(location.search) : {}

                const cProps = { admin, title: currentNav.text, nav, ...props }

                //  페이지 인자 정리
                cProps.page = !_.isNaN(search.page) && _.isNumber(search.page) ? search.page * 1 : 1
                if (search.limit) {
                  cProps.limit = Number(search.limit)
                  if (!_.isNaN(cProps.limit) && !_.isNumber(cProps.limit)) { delete cProps.limit }
                }
                if (search.blockLimit) {
                  cProps.blockLimit = Number(search.blockLimit)
                  if (!_.isNaN(cProps.blockLimit) && !_.isNumber(cProps.blockLimit)) { delete cProps.blockLimit }
                }
                if (search.sorts) { cProps.sorts = search.sorts.split(',') }
                if (search.startAt) { cProps.startAt = new Date(search.startAt) }
                if (search.endAt) { cProps.endAt = new Date(search.endAt) }

                if (search.keywordsOption) { cProps.keywordsOption = search.keywordsOption }
                if (search.keywords) { cProps.keywords = search.keywords }

                // 필터구문 정리
                const propsKeys = Object.keys(search)
                const filtersKeys = propsKeys.filter(key => key.indexOf('filters_') === 0)
                if (filtersKeys && filtersKeys.length) {
                  cProps.filters = {}
                  filtersKeys.forEach(filtersKey => {
                    const key = filtersKey.replace('filters_', '')
                    cProps.filters[key] = search[filtersKey]
                  })
                }

                return <>
                  <Page.Nav>
                    <Page.Nav.Items>
                      {navs.map((item, index) => 
                        <Page.Nav.Item
                          key={`nav_items_${index}`} href={`#${item.name}`} className={item.active ? "active" : null}
                          onClick={e => [e.stopPropagation(), e.preventDefault(), window.location.href = `/resources/${item.name}`]}
                        >
                          <span>{item.text}</span>
                        </Page.Nav.Item>
                      )}
                    </Page.Nav.Items>
                  </Page.Nav>
                  {['freeBookCoverDesigns'].includes(nav) ? <FreeBookCoverDesignList {...cProps} /> : null}
                  {['bookStoreGenres'].includes(nav) ? <BookStoreGenreList {...cProps} /> : null}
                  {['bookStoreCorners'].includes(nav) ? <BookStoreCornerList {...cProps} /> : null}
                  {['solutionStoreCategories'].includes(nav) ? <SolutionStoreCategoryList {...cProps} /> : null}
                  {['solutionStoreCorners'].includes(nav) ? <SolutionStoreCornerList {...cProps} /> : null}
                  {['homeCorners'].includes(nav) ? <HomeCornerList {...cProps} /> : null}
                  {['primaryFiles'].includes(nav) ? <PrimaryFileList {...cProps} /> : null}
                  {['popups'].includes(nav) ? <PopupList {...cProps} /> : null}
                </>
              }}
            />

        </Switch>
      </Page>
    )
  }
}

ResourcePageContainer.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

ResourcePageContainer.defaultProps = {
  admin: {},
  history: {},
  location: {},
  match: {},
}

export default ResourcePageContainer
