import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class StatusContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async initialize() { }
  
  render() {
    const { item } = this.props

    const statusTypes = {}
    statusTypes.wait = { name: 'wait', text: '임시', caret: 'muted' }
    statusTypes.executed = { name: 'executed', text: '반영완료', caret: 'success' }
    statusTypes.deleted = { name: 'deleted', text: '삭제됨', caret: 'danger' }

    // 도서의 머리를 의미한다.
    const meta = {}

    // 최종 실행일자
    meta.status = _.get(item, 'status') || 'wait'
    meta.statusType = statusTypes[meta.status] || {}
    meta.executedAt = _.get(item, 'executedAt')
    meta.executedMt = meta.executedAt ? moment(meta.executedAt) : null
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '0.45rem' }}>
            <Caret c={_.get(meta, 'statusType.caret')} style={{marginRight: '0.35rem' }}/>
            <strong>{_.get(meta, 'statusType.text')}</strong>
          </div>
          <div>
            <small style={{ fontSize: '0.9em' }}>
              {meta.executedMt
                ? meta.executedMt.format('YYYY.MM.DD HH:mm')
                : `0000.00.00 00:00`}
            </small>
          </div>
        </div>
      </Column>
    )
  }
}

StatusContainer.propTypes = {
  item: PropTypes.object,
}

StatusContainer.defaultProps = {
  item: {}
}

export default StatusContainer
