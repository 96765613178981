import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import moment from 'services/moment'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};
  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// @ 해당 종이도서의 재고서비스 내용을 가져온다.
function BookStockStorageColumn({ ...props }) {
  const { item } = props

  const book = item.content || {}
  const stock = _.get(item, 'currentStock') || {}
  const stockStorage = _.get(item, 'activatedStockStorage') || {}

  // @ 종이도서, 도서전체에 뿌려지는 컴포넌트 임으로, 전자도서에는 해당하지 않도록
  if (!['paperBook'].includes(item.productType)) { return null }

  const meta = {}

  // 이용상태
  meta.stage = _.get(stockStorage, 'stage') || 'none' // 사용하지 않는 상태로 기본을 두기
  meta.status = _.get(stockStorage, 'status') || 'deactivated' // 사용하지 않는 상태로 기본을 두기
  meta.activatedAt = _.get(stockStorage, 'activatedAt') || null
  meta.activatedMt = meta.activatedAt ? moment(meta.activatedAt) : null
  meta.deactivatedAt = _.get(stockStorage, 'deactivatedAt') || null
  meta.activatedMt = meta.deactivatedAt ? moment(meta.deactivatedAt) : null

  // 이용기간
  meta.firstAt = _.get(stockStorage, 'firstAt')
  meta.firstMt = meta.firstAt ? moment(meta.firstAt) : null
  meta.startAt = _.get(stockStorage, 'startAt')
  meta.startMt = meta.startAt ? moment(meta.startAt) : null
  meta.endAt = _.get(stockStorage, 'endAt')
  meta.endMt = meta.endAt ? moment(meta.endAt) : null

  // 자동발주수, 총계약수
  meta.checkmateAmount = _.get(stockStorage, 'checkmate') || 0
  meta.contractAmount = _.get(stockStorage, 'contract') || 0
  meta.instockAmount = _.get(stock, 'instock') || 0
  
  // 책장번호
  meta.storageNo = _.get(stockStorage, 'storageNo') || 0

  // @ 링크 이동을 위한 값 배정
  meta.payUrl = `/pays/etcBookStockStorage?keywords=${item.id}`

  return (
    <Column>
      <Meta.Group>
        <Meta
          onClick={(e) => {
            return window.open(meta.payUrl)
          }}
          style={{ cursor: 'pointer' }}
        >
          <header>사용상태</header>
          {['activated'].includes(meta.status)
            ? (
              <section>
                <strong style={{ fontWeight: 900 }}>📚 {meta.storageNo}</strong>
                <br />
                {['none'].includes(meta.stage) ? <small>해당없음</small> : null}
                {['free'].includes(meta.stage) ? <small>무료기간</small> : null}
                {['normal'].includes(meta.stage) ? <small>유료기간</small> : null}
              </section>
            )
            : null}
          {['deactivated'].includes(meta.status)
            ? (
              <section>
                <strong>☔ 비사용중</strong><br />
                <small>해당없음</small>
              </section>
            )
            : null}
        </Meta>
        <Meta>
          <header>이용기간</header>
          <section>
            {meta.endMt
              ? (<strong>~ {meta.endMt.format('YYYY.MM.DD')} {meta.endMt.format('HH:mm')}</strong>)
              : (<strong>~ 0000.00.00 00:00</strong>)}
            <br />
            {meta.startMt
              ? (<small>{meta.startMt.format('YYYY.MM.DD')} {meta.startMt.format('HH:mm')} ~</small>)
              : (<small>0000.00.00 00:00 ~</small>)}
          </section>
        </Meta>
      </Meta.Group>
      <Meta.Group>
        <Meta>
          <header>자동발주/계약</header>
          <section>
            <strong>{meta.checkmateAmount}부/{meta.contractAmount}부</strong><br/>
            <small>현재 <strong>{meta.instockAmount}부</strong> 보유</small><br/>
          </section>
        </Meta>
        <Meta>
          <header>최초시작일</header>
          {meta.firstMt
            ? (
              <section>
                <strong>{meta.firstMt.format('YYYY.MM.DD')}</strong><br/>
                <small>{meta.firstMt.format('HH:mm')}</small>
              </section>
            )
            : null}
          {!meta.firstMt
            ? (
              <section>
                <strong>0000.00.00</strong><br/>
                <small>00:00</small>
              </section>
            )
            : null}
        </Meta>
      </Meta.Group>
    </Column>
  )
}

export default BookStockStorageColumn
