import _ from 'lodash'
import moment from 'services/moment'

// @ 2-4업 생산방식 스타일 적용
const style4u = (bookSize, invoiceAmount = 0) => {
  let type = '혼합';
  if (['pa5', 'p46'].includes(bookSize)) {
      if (invoiceAmount <= 4 && invoiceAmount%4 == 0) { type = 'IMP'; }
      if (invoiceAmount <= 4 && invoiceAmount%4 != 0) { type = 'CS'; }
      if (invoiceAmount >= 5 && invoiceAmount%4 == 0) { type = 'IMP'; }
      if (invoiceAmount >= 5 && invoiceAmount%4 != 0) { type = '혼합'; }
  } else if (['pb5', 'pa4'].includes(bookSize)) {
      if (invoiceAmount <= 2 && invoiceAmount%2 != 0) { type = 'CS'; }
      if (invoiceAmount <= 2 && invoiceAmount%2 == 0) { type = 'IMP'; }
      if (invoiceAmount >= 3 && invoiceAmount%2 == 0) { type = 'IMP'; }
      if (invoiceAmount >= 3 && invoiceAmount%2 != 0) { type = '혼합'; }
  }
  return type
}

// @ 작업순번 만들기
const getWorkState = (item = {}, rank = 0, inRank = 0) => {
  const createMt = moment(item.createdAt)
  const extras = _.get(item, 'extras') || {}

  const product = _.get(extras, 'currentProduct') || {}
  const book = product.content || {}
  const invoiceAmount = _.get(item, 'amount') || 99999
  const textPageCount = _.get(book, 'text.pageCount') || 99999

  const workStyle = style4u(_.get(book, 'bookSize.name'), invoiceAmount)

  const scores = {
    size: 0, coverFlap: 0, coverMaterial: 0,
    textMaterial: 0, workStyle: 0, textPageCount: 0, amount: 0
  }

  const folderName = []
  const inRankName = []
  const fileName = []
  const partName = []

  // 컬러여부
  if (['color'].includes(_.get(book, 'text.color.name'))) {
    folderName.push('컬')
  }
  if (!['color'].includes(_.get(book, 'text.color.name'))) {
    folderName.push('흑')
  }

  // 판형 가중치
  if (['pa4'].includes(_.get(book, 'bookSize.name'))) {
    fileName.push('A4')
    inRankName.push('A4')
    folderName.push('A4')
    scores.size = 4
  }
  if (['pa5'].includes(_.get(book, 'bookSize.name'))) {
    fileName.push('A5')
    inRankName.push('A5')
    folderName.push('A5')
    scores.size = 3
  }
  if (['pb5'].includes(_.get(book, 'bookSize.name'))) {
    fileName.push('B5')
    inRankName.push('B5')
    folderName.push('B5')
    scores.size = 2
  }
  if (['p46'].includes(_.get(book, 'bookSize.name'))) {
    fileName.push('46')
    inRankName.push('46')
    folderName.push('46')
    scores.size = 1
  }

  // 날개 있음 없음 가중치
  if ([true].includes(_.get(book, 'cover.flap'))) {
    fileName.push('날')
    inRankName.push('날')
    folderName.push('날')
    scores.coverFlap = 1
  }
  if (![true].includes(_.get(book, 'cover.flap'))) {
    fileName.push('단')
    inRankName.push('단')
    folderName.push('단')
    scores.coverFlap = 0
  }

  // 표지 재질 가중치
  if (['SPM250'].includes(_.get(book, 'cover.material.name'))) {
    fileName.push('스무')
    inRankName.push('스무')
    scores.coverMaterial = 4
  }
  if (['SGG250'].includes(_.get(book, 'cover.material.name'))) {
    fileName.push('스유')
    inRankName.push('스유')
    scores.coverMaterial = 3
  }
  if (['ARM210'].includes(_.get(book, 'cover.material.name'))) {
    fileName.push('아무')
    inRankName.push('아무')
    scores.coverMaterial = 2
  }
  if (['AGG210'].includes(_.get(book, 'cover.material.name'))) {
    fileName.push('아유')
    inRankName.push('아유')
    scores.coverMaterial = 1
  }
  if (['RTN270'].includes(_.get(book, 'cover.material.name'))) {
    fileName.push('르무')
    inRankName.push('르무')
    scores.coverMaterial = 0
  }

  // 워크스타일
  if (['CS'].includes(workStyle)) {
    scores.workStyle = 3
  }
  if (['IMP'].includes(workStyle)) {
    scores.workStyle = 2
  }
  if (['혼합'].includes(workStyle)) {
    scores.workStyle = 1
  }

  // 내지재질
  if (['white100g'].includes(_.get(book, 'text.material.name'))) {
    partName.push('백100')
    scores.textMaterial = 2
  }
  if (['yellow100g'].includes(_.get(book, 'text.material.name'))) {
    partName.push('미100')
    scores.textMaterial = 4
  }
  if (['white80g'].includes(_.get(book, 'text.material.name'))) {
    partName.push('백80')
    scores.textMaterial = 1
  }
  if (['yellow80g'].includes(_.get(book, 'text.material.name'))) {
    partName.push('미80')
    scores.textMaterial = 3
  }
  if (['elite80g'].includes(_.get(book, 'text.material.name'))) {
    partName.push('이80')
    scores.textMaterial = 5
  }
  
  // 부수 점수 : 적은 숫자인경우 부터
  scores.amount =  1/invoiceAmount
  partName.push(`${invoiceAmount}부`)
  partName.push(createMt.format('ddd'))
  partName.push(createMt.format('HHmm'))
  
  // 페이지수 점수 : 적은 숫자인경우 부터
  scores.textPageCount =  1/textPageCount

  // 동률이면, 책번호
  scores.bookNo = book.bookNo/1000000000
  
  const total = +(0
    + (scores.size * 1000000)
    + (scores.coverFlap * 100000)
    + (scores.coverMaterial * 10000)
    + (scores.textMaterial * 1000)
    + (scores.workStyle * 100)
    + (scores.amount * 10)
    + (scores.textPageCount * 1)
    + scores.bookNo)

  const fileNames = {}
  fileNames.rank = rank
  fileNames.inRank = inRank
  fileNames.group = [...inRankName].join('_')
  fileNames.file = [...fileName, `${inRank}`].join('_')
  fileNames.cover = [...fileName, `${inRank}`, ...partName, '[0COVER].pdf'].join('_')
  fileNames.imp = [...fileName, `${inRank}`, ...partName, '[1IMP].pdf'].join('_')
  fileNames.cs = [...fileName, `${inRank}`, ...partName, '[2CS].pdf'].join('_')
  fileNames.folder = [folderName[0], `${folderName[1]}_${folderName[2]}`].join('/')

  return { fileNames, total }
}

const parser = {}

parser.prepareDatas = (datas = []) => {
  console.log(datas)
  const combined = JSON.parse(JSON.stringify(datas))
    .map(item => {
      item.workState = getWorkState(item)
      return item
    })

  // 랭킹을 조사한다.
  const items = combined.map((item) => {
    const rank = combined
      .filter((o) => o.workState.total >= item.workState.total).length
    const inRank = combined
      .filter((o) => _.get(o, 'workState.fileNames.group') && [_.get(o, 'workState.fileNames.group')].includes(_.get(item, 'workState.fileNames.group')))
      .filter((o) => o.workState.total >= item.workState.total).length
    item.workState = getWorkState(item, rank, inRank)
    return item
  })

  // workState 토탈 점수로 정렬 작업을 다시한다.
  return _.sortBy(items, ['workState.total']).reverse()
}

parser.columns = [
  { header:  '순번', key: 'no', view: (item, idx) => `${idx+1}` },
  {
    header:  '도서번호', key: 'bookNo',
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const product = _.get(extras, 'currentProduct') || {}
      const value = _.get(product, 'content.bookNo') || ''
      return value
    }
  },
  {
    header:  '도서명', key: 'bookTitle',
    columnProps: { width: 60 },
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const product = _.get(extras, 'currentProduct') || {}
      const value = _.get(product, 'content.title') || '도서명 없음'
      return value
    }
  },
  { header:  '표지', key: 'bookCoverFile', view: (item) => `O` },
  {
    header:  '', key: 'bookTextIMP',
    view: (item) => {
      // const extras = _.get(item, 'extras') || {}
      // const product = _.get(extras, 'currentProduct') || {}
      // const invoiceAmount = _.get(item, 'amount') || 0
      // const type = style4u(_.get(product, 'content.bookSize.name'), invoiceAmount)
      // return ['IMP', '혼합'].includes(type) ? `O` : ''
      return ''
    }
  },
  {
    header:  '', key: 'bookTextCS',
    view: (item) => {
      // const extras = _.get(item, 'extras') || {}
      // const product = _.get(extras, 'currentProduct') || {}
      // const invoiceAmount = _.get(item, 'amount') || 0
      // const type = style4u(_.get(product, 'content.bookSize.name'), invoiceAmount)
      // return ['CS', '혼합'].includes(type) ? `O` : ''
      return ''
    }
  },
  { header:  '내지원본', key: 'bookTextFolder', view: (item) => `` },
  {
    header:  '표지파일명', key: 'CoverFileName',
    columnProps: { width: 15 },
    view: (item) => {
      const workState = item.workState || {}
      return _.get(workState, 'fileNames.cover')
    }
  },
  {
    header:  '내지_IMP', key: 'IMPFileName',
    columnProps: { width: 15 },
    view: (item) => {
      const workState = item.workState || {}
      return _.get(workState, 'fileNames.imp')
    }
  },
  {
    header:  '내지_CS', key: 'CSFileName',
    columnProps: { width: 15 },
    view: (item) => {
      const workState = item.workState || {}
      return _.get(workState, 'fileNames.cs')
    }
  },
  {
    header:  '원본', key: 'fileName',
    columnProps: { width: 15 },
    view: (item) => { return '' }
  },
  {
    header:  '폴더', key: 'folderName',
    columnProps: { width: 15 },
    view: (item) => {
      const workState = item.workState || {}
      return _.get(workState, 'fileNames.folder')
    }
  },
  {
    header:  '도서규격', key: 'bookSize',
    view: (item) => {
      const extras = _.get(item, 'extras')
      const product = _.get(extras, 'currentProduct') || {}
      const value = _.get(product, 'content.bookSize.text') || '알수없음'
      return value
    }
  },
  {
    header:  '발주량', key: 'invoiceAmount',
    view: (item) => (_.get(item, 'amount') || 0)
  },
  {
    header:  '도서색상', key: 'bookColor',
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const product = _.get(extras, 'currentProduct') || {}
      return ['color'].includes(_.get(product, 'content.text.color.name')) ? `컬러` : `흑백`
    }
  },
  {
    header:  '날개있음', key: 'bookFlap',
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const product = _.get(extras, 'currentProduct') || {}
      return _.get(product, 'content.cover.flap') ? `O` : `X`
    }
  },
  {
    header:  '날짜', key: 'curMt',
    columnProps: { width: 15 },
    view: (item) => {
      return moment().format('YYYY-MM-DD')
    }
  }
]

export default parser
