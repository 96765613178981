// 수동모달
import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'
import _ from 'lodash'

import moment from 'services/moment'

import Modal from 'components/utils/Modal'
import Lnr from 'components/utils/Lnr'

// 폼 사이즈 구성
const Form = styled.div`
  position: relative; box-sizing: border-box;
  max-width: 480px; min-width: 480px; width: 480px;
`

Form.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 2rem 1rem; font-weight: 900;
  font-size: 1.43em; font-family: ${font('primary')};
`

Form.Grid = styled.div`
  position: relative; box-sizing: border-box;
`

Form.Content = styled.div`
  position: relative; box-sizing: border-box;

  & > div.control {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem;
    display: flex;
    & > input {
      position: relative; box-sizing: border-box;
      flex: 1 1 auto; margin: 0.25rem;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > select {
      position: relative; box-sizing: border-box;
      flex: 1 1 auto; margin: 0.25rem;
      font-size: 1em; border-radius: 0.35rem;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      font-family: ${font('primary')};
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
    & > textarea {
      position: relative; box-sizing: border-box;
      flex: 1 1 auto; margin: 0.25rem;
      font-size: 1em; border-radius: 0.35rem;
      font-family: ${font('primary')};
      line-height: 1.55rem; border: 1px solid #e1e2e3;
      width: 100%; border: 1px solid #e1e2e3; outline: none;
      padding: 0.35rem 0.75rem; transition: 0.3s all;
      &:hover { background: #fafafa; border: 1px solid #929394 }
    }
  }
`

Form.Footer = styled.footer`
  position: relative; box-sizing: border-box;
  display: flex; justify-content: space-between;
  & > div.buttons {
    min-width: 280px; max-width: 280px;
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex; justify-content: space-between;
    & > button {
      position: relative; box-sizing: border-box;
      width: 100%; border: 0; outline: none; cursor: pointer;
      padding: 0.5rem 1rem; font-weight: 700; margin: 0.5rem;
      font-family: ${font('primary')};
      font-size: 1.02em; border-radius: 0.35rem;
      background: ${palette('darkblue', 15)}; color: #fff; transition: 0.3s all;
      &:hover { background: ${palette('darkblue', 4)}; color: #fff; }
      &.primary {
        background: ${palette('darkblue', 5)};
        &:hover { background: ${palette('darkblue', 5)}; }
      }
    }
  }
`

// 브런치 이벤트 스테이지 모달 설정
class BrunchEventRoundModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.initializedSetup = { working: false, startAt: null, endAt: null, round: 1, name: '', description: '' }
    
    this.initializedState = {
      loading: true,
      setup: this.initializedSetup
    }

    this.state = JSON.parse(JSON.stringify(this.initializedState))

    this.initialize = this.initialize.bind(this)
    this.loadSetup = this.loadSetup.bind(this)

    this.abortController = new AbortController()
  }

  componentDidMount() {
    return this.initialize()
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  async initialize() {
    const setup = await this.loadSetup()
    await new Promise((r) => this.setState({ loading: false, setup }, r))
  }

  // 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/brunchEventRound.json)
  async loadSetup() {
    return await api.get(`/approvals/admin2/brunchEventRound`, { signal: this.abortController.signal })
      .then((data) => {
        if (!data) { return JSON.parse(JSON.stringify(this.initializedSetup)) }
        return data
      })
      .catch(e => JSON.parse(JSON.stringify(this.initializedSetup)))
  }

  // 서버에 저장하는 기능 : 서버에 브런치 회차정보를 기록하는 리소스파일을 생성한다. (api 서버의 resources/brunchEventRound.json)
  async saveSetup(setup) {
    const { onClose } = this.props
    if (!window.confirm(`저장하게되면 브런치 회차정보가 일반 사용자 화면에 적용됩니다. 진행할까요?`)) { return }

    const round = _.get(setup, 'round') * 1
    if (!round) { return alert(`회차번호는 반드시 필요합니다.`) }
    if (_.isNaN(round) || !_.isInteger(round)) { return alert(`반드시 정수형 숫자여야합니다.`) }

    const payload = {}
    payload.setup = setup ? setup : _.get(this, 'state.setup')
    return await api.post(`/approvals/admin2/brunchEventRound`, payload)
      .then((res) => {
        if (res.error) { return alert(`에러가 발생하였습니다. (${res.description})`) }
        alert(`성공적으로 업데이트 하였습니다.`)
        setTimeout(() => onClose(), 1)
      })
      .catch(e => {
        return alert(`에러가 발생하였습니다. ${e.message}`)
      })
  }

  render() {
    const { onClose } = this.props
    const { loading, setup } = this.state
    if (loading) { return null }

    // 모달 프로퍼티 설정 구간, 닫기 버튼에 대한 컴포넌트 라이프 사이클에 대해서만 상위에서 관장한다.
    const modalProps = {}
    modalProps.isOpen = true
    modalProps.onClose = onClose ? onClose : (async () => {})

    return (
      <Modal {...modalProps}>
        <Form>
          <Form.Header>브런치 이벤트 회차정보</Form.Header>
          <Form.Grid>
            <Form.Content>

              <div className="control">
                <input
                  type="number"
                  placeholder="회차번호"
                  value={setup.round}
                  onChange={(e) => {
                    const value = e.target.value
                    const next = { ...setup, round: value }
                    return this.setState({ setup: next })
                  }}
                />
                <select
                  value={setup.working ? `true` : 'false'}
                  onChange={(e) => {
                    const value = e.target.value === 'true' ? true : false
                    const next = { ...setup, working: value }
                    return this.setState({ setup: next })
                  }}
                >
                  <option value="true">참여가능</option>
                  <option value="false">마감됨</option>
                </select>
              </div>

              <div className="control">
                <input
                  type="text"
                  placeholder="이벤트명"
                  value={setup.name}
                  onChange={(e) => {
                    const value = e.target.value
                    const next = { ...setup, name: value }
                    return this.setState({ setup: next })
                  }}
                />
              </div>

              <div className="control">
                <textarea
                  placeholder="현재 이벤트에 대한 간단한 공지를 작성해주세요."
                  style={{ height: '90px' }}
                  value={setup.description}
                  onChange={e => {
                    const next = { ...setup, description: e.target.value || '' }
                    return this.setState({ setup: next })
                  }}
                />
              </div>
            </Form.Content>
            <Form.Footer>
              <div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="primary"
                  onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await this.saveSetup(_.get(this, 'state.setup'))]}
                >
                  반영하기
                </button>
              </div>
            </Form.Footer>
          </Form.Grid>
        </Form>
      </Modal>
    )
  }
}

export default BrunchEventRoundModalContainer