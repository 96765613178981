import React from 'react'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import _ from 'lodash'
import api from 'services/api'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & > div.defaultPrice { position: relative; box-sizing: border-box; padding-bottom: 0.35rem; }
  & > div.options { position: relative; box-sizing: border-box; }

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

// 작가서비스에 설정된 가격정보 보기
class PriceDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()
    
    this.state = {}
  }
  
  render() {
    const { item = {} } = this.props

    const solution = item.content || {}

    const meta = {}
    meta.defaultPrice =_.get(solution, 'defaultPrice') || 0
    meta.usedOptions =_.get(solution, 'usedOptions') || false
    meta.options =_.get(solution, 'options') || []

    return (
      <Column style={{ minWidth: '100%', maxWidth: '100%', textAlign: 'center' }}>
        <div className="defaultPrice">
          <strong style={{ fontSize: '1.2em', fontWeight: 900 }}>{comma(meta.defaultPrice)}원</strong>
        </div>

        <div className="options">
          {meta.usedOptions
            ? (
              <small style={{ marginRight: '0.15rem' }}>
                <strong style={{ fontSize: '1.1em', fontWeight: 900, marginRight: '0.35rem' }}>{comma(_.get(meta, 'options.length'))}개</strong>
                <span>옵션</span>
              </small>
            )
            : (<small style={{ marginRight: '0.15rem' }}>옵션가 사용안함</small>)}
        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

export default PriceDetailColumnContainer
