import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

class CommentDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {
      caption: _.get(item, 'content.caption') || '미분류',
      question: _.get(item, 'question') || '신고제목을 작성하지 않았습니다.',
      comment: _.get(item, 'comment') || '아직 관리자가 답변하지 않았습니다.',
    }

    return (
      <Column>
          <div style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
            <small>
              {meta.question ? meta.question.split('\n').map(line => <div>{line}</div>) : ''}
           </small>    
          </div>
      </Column>
    )
  }
}

CommentDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

CommentDetailColumnContainer.defaultProps = {
  item: {}
}

export default CommentDetailColumnContainer
