import _ from 'lodash'
import { comma } from 'services/utils'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  { header: '은행명', key: 'bankAccountText', view: (item) => _.get(item, 'bill.bankAccount.text') },
  { header: '계좌번호', key: 'bankAccountNumber', view: (item) => _.get(item, 'bill.bankAccount.number') },
  { header: '송금액', key: 'profitTotal', view: (item) => comma(item.total) },
  { header: '예금주', key: 'bankAccountName', view: (item) => _.get(item, 'bill.bankAccount.name') },
  { header: 'CMS기록', key: 'cms', view: (item) => {
    const createdAt = item.createdAt, createdMt = moment(createdAt)
    return `${createdMt.format('MM')}월 부크크 정산금(${item.id})`
  } },
]

export default parser
