import React from 'react'

import _ from 'lodash'

import List from './List'

class PayQuoteFormContainer extends React.Component {
  constructor(props) {
    super(props)

    this.defaultAlias = `payQuote`

    this.initializeState = () => ({
        payload: { books: [] },
        createdAt: new Date()
      })

    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
    this.loadFromBrowser = this.loadFromBrowser.bind(this)
    this.saveToBrowser = this.saveToBrowser.bind(this)
    this.clearBrowser = this.clearBrowser.bind(this)
    this.handle = this.handle.bind(this)
    
    this.state = { loading: true }
  }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  async loadFromBrowser(alias = this.defaultAlias) {
    try {
      const data = window.localStorage.getItem(alias)
      return JSON.parse(data || JSON.parse(JSON.stringify(this.initializeState())))
    } catch(e) {
      return JSON.parse(JSON.stringify(this.initializeState()))
    }
  }

  async saveToBrowser(payload = {}, alias = this.defaultAlias) {
    try {
      const curAt = new Date()
      window.localStorage.setItem(alias, JSON.stringify({ payload, createdAt: curAt }))
      return true
    } catch(e) {
      return false
    }
  }

  async clearBrowser(alias = this.defaultAlias) {
    try {
      window.localStorage.removeItem(alias)
      await this.initialize()
      return true
    } catch(e) {
      return false
    }
  }

  async initialize() {
    const { quoteName } = this.props
    const { payload, createdAt } = await this.loadFromBrowser(quoteName || this.defaultAlias)
    this.setState({ payload, createdAt, loading: false })
  }

  // 상품 엔드포인트에서 불러오기
  async handle(state) {
    const that = this
    const { quoteName } = that.props
    await new Promise((r) => that.setState(state, r))
      .then(async () => {
        await that.saveToBrowser(that.state.payload, quoteName)
        return await that.initialize()
      })
      .catch(e => {
        console.log(e.message)
        return that
      })
  }

  render() {
    const { quoteName } = this.props
    const { abortController, handle, clearBrowser } = this
    const { loading, payload, createdAt } = this.state
    if (loading) { return null }


    const listProps = {
      quoteName,
      payload,
      createdAt,
      handle,
      clearBrowser,
      getSignal: () => abortController.signal
    }

    return (
      <div>
        <List {...listProps} />
      </div>
    )
  }
}

export default PayQuoteFormContainer