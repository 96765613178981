import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import api from 'services/api'

import qs from 'query-string'
import _ from 'lodash'
import moment from 'services/moment'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL

class StepColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {}
    meta.step = _.get(item, 'step') || { name: 'none', text: '알수없음' }
    meta.isBrunch = _.get(item, 'brunch')
    
    return (
      <Column>
        <div style={{ textAlign: 'center' }}>
          <strong style={{ fontSize: '0.9em' }}>{_.get(meta, 'step.text')}</strong>

          {meta.isBrunch
            ? (
              <div>
                <small>
                  <small style={{ marginRight: '0.10rem' }}><strong>브런치</strong></small>
                  <small>도서</small>
                </small>
              </div>
              ) : null}

          {!meta.isBrunch
            ? (
              <div>
                <small>
                  <small style={{ marginRight: '0.10rem' }}><strong>부크크</strong></small>
                  <small>도서</small>
                </small>
              </div>
              ) : null}

        </div>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

StepColumnContainer.propTypes = {
  item: PropTypes.object,
}

StepColumnContainer.defaultProps = {
  item: {}
}

export default StepColumnContainer
