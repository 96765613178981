import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'

import { Importer, Exporter } from 'services/transform'
import expressExportParser from './export.express' // 택배 운송장 요청 엑셀 템플릿
import receiptExportParser from './export.receipt' // 거래명세서 엑셀 템플릿
import legalExportParser from './export.legal' // 납본명세서 엑셀 템플릿
import storageParser from './export.storage' // 납본명세서 엑셀 템플릿

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

// 서버 측에서 셋업 데이터를 가져오기 (api 서버의 resources/retails.json)
const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items, selecteds,
  total, page, limit, blockLimit, retailsSetup,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const [modal, setModal] = React.useState(false)
  const [form, setForm] = React.useState({})
  const [progressModal, setProgressModal] = React.useState(false)

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'PayColumn', header: '주문번호', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PaidDateColumn', header: '입금일', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BuyerColumn', header: '결제자', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReceiverColumn', header: '수령인', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'OrdersDetailColumn', header: '주문정보', style: { flex:  '1 1 auto', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'AddressDetailColumn', header: '발송정보', style: { minWidth: '360px', maxWidth: '360px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  
  // 로우 작동 액션들
  const actions = {}

  // 현재 전체 데이터 불러오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const limit = query.limit || 100
    const output = await loadItems({ usedCount: true, limit, page: 1, limit: 1 }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }

    const chunks = Math.ceil(output.count/limit)
    const items = await Array.from({ length: chunks })
      .reduce((prev, b, bIdx, arr) => 
        prev.then(async (rows) => {
          const page = bIdx + 1
          if (options.progress) {
            setProgressModal({ message: `${page}/${chunks} 블록으로 나누어 다운로드중...`, rate: (page/arr.length) * 100, onClose: () => setProgressModal(null) })
          }
          const output = await loadItems({ usedCount: false, page, limit }, { returnal: true })
            .catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          if (_.get(output, 'rows.length')) { rows.push(...output.rows) }
          return rows
        }),
        Promise.resolve([]))
      .catch(e => [])

    if (options.progress) { setProgressModal(null) }
    return items
  }

  // 책장출납 기능
  actions.doDownloadStorages = async (method = 'endAt') => {
    const curAt = new Date(), curMt = moment(curAt)
    const curHour = curAt.getHours()
    const isAm = curHour <= 12 
    const isLazyPm = curHour > 13

    const times = ['endAt'].includes(method)
      ? window.prompt(`마지막 시간을 기재해주세요. (예, 17:04)`, curMt.format('HH:mm'))
      : window.prompt(`시작할 시간을 입력해주세요. (예, 17:04)`, isAm ? '00:00' : (isLazyPm ? `${curMt.format('HH')}:00` : '13:00'))
    if (!times) { return alert(`시간데이터가 존재해야합니다.`) }
    
    // * 책장정보+정상빌링 and (당일수령 or 당일출고의 상품) 데이터를 불러온다.
    const queries = { method, times }
    const result = await api.get(`/packings/admin2/today-stoarges?${qs.stringify(queries)}`)
      .catch((e) => {
        console.log(`${e.message} ${e.stack}`)
        return { error: true, message: `당일 책장출납 종합하는 과정에서 문제가 발생하였습니다.` }
      })
    if (result && result.error) { return alert(result.message) }

    const { rows, count } = result
    if (!count) { return alert('책장출납할 데이터가 존재하지 않습니다.') }

    if (!window.confirm(`${curMt.format(`총 ${count}건이 검색되었습니다. 책장출납 내역의 다운로드, 진행할까요?`)}`)) { return false }

    return await new Exporter({ parser: storageParser, items: rows })
      .download(`책장출납 총 ${count}건 (${times.split(':')[0]}시 ${times.split(':')[0]}분${['endAt'].includes(method) ? `까지` : `부터`}) (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
      .catch((e) => {
        alert(e.message)
        return false
      })
  }

  // 거래명세서 다운로드 기능
  actions.doDownloadReceipts = async () => {
    const retail = filters.retail || 'bookk'
    const retailSetup = retailsSetup && retailsSetup[retail] || { text: '알수없음' }
    
    const curAt = new Date(), curMt = moment(curAt)
    
    // 전체 데이터를 다시 받고
    const items = await actions.getItemsAll({}, { progress: true })
    if (!items || !items.length) { return alert(`최신 데이터를 불러올 수 없습니다.`) }

    // 전체 주문데이터 기준 orders로 값을 변경하기
    const orders = items
      .reduce((s, item) => {
        const _orders = _.get(item, 'extras.orders') || []
        s.push(..._orders)
        return s
      }, [])

    // 종별합산처리
    const distinctOrders = orders
      .reduce((s, order) => {
        const obj = s[order.product]
        s[order.product] = obj
          ? obj
          : {
              orders: [order],
              productName: order.subject,
              product: order.product,
              eachPrice: order.eachPrice,
              amount: order.amount,
              totalPrice: order.totalPrice,
              supplyPrice: Math.ceil(order.totalPrice * 0.8)
            }
        if (obj) {
          s[order.product].orders.push(order)
          s[order.product].amount = s[order.product].amount * 1 + order.amount * 1
          s[order.product].totalPrice = s[order.product].totalPrice * 1 + order.totalPrice * 1
          s[order.product].supplyPrice = Math.ceil(s[order.product].totalPrice * 0.8)
        }
        return s
      }, {})
      
    // 도서명으로 정렬
    let datas = Object.values(distinctOrders)
    _.sortBy(datas, ['productName'], ['asc'])
    
    // 종수와 권수 모두 표현
    const totalCount = datas.length
    const totalAmount = datas.reduce((s, data) => data.amount + s , 0)
    
    if (!window.confirm(`${curMt.format(`총 ${totalCount}종 ${totalAmount}부 입니다. ${retailSetup.text} 거래명세서를 다운로드 진행할까요?`)}`)) { return false }

    const headerData = {
      createdDate: `거래명세서 - 부크크`,
      supplyPrice: `${retailSetup.text}`
    }

    const totalData = {
      createdDate: '총합계',
      amount: datas.reduce((s, o) => s + o.amount, 0),
      totalPrice: datas.reduce((s, o) => s + o.totalPrice, 0),
      supplyPrice: datas.reduce((s, o) => s + o.supplyPrice, 0)
    }

    datas = [headerData, {}, {}, ...datas, totalData]

    return await new Exporter({ parser: receiptExportParser, items: datas })
      .download(`${retailSetup.text} 거래명세서 총 ${totalCount}종 ${totalAmount}부 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
      .catch((e) => {
        alert(e.message)
        return false
      })
  }

  // 납본명세서 다운로드 기능
  actions.doDownloadLegals = async () => {
    if (!['nlibrary'].includes(filters.retail)) { return alert(`해당 기능은 국립중앙도서관만 가능합니다.`) }
    
    const curAt = new Date(), curMt = moment(curAt)
    
    // 전체 데이터를 다시 받고
    const items = await actions.getItemsAll({}, { progress: true })
    if (!items || !items.length) { return alert(`최신 데이터를 불러올 수 없습니다.`) }

    // 전체 주문데이터 기준 orders로 값을 변경하기
    const orders = items
      .reduce((s, item) => {
        const products = _.get(item, 'extras.products') || []
        const _orders = (_.get(item, 'extras.orders') || [])
          .map(order => {
            order.extras = {}
            order.extras.product = products
              .find((o) => o.id === order.product)  // 상품정보를 불러가주기
            return order
          })
        s.push(..._orders)
        return s
      }, [])

    // 종별합산처리
    const distinctOrders = orders
      .reduce((s, order) => {
        const obj = s[order.product]
        const product = _.get(order, 'extras.product') || {}
        s[order.product] = obj
          ? obj
          : {
              orders: [order],
              product,
              bookIsbn: _.get(product, 'content.isbn') || '',
              bookName: _.get(product, 'content.title') || '',
              bookAuthor: _.get(product, 'content.author') || '',
              bookTextPageCount: _.get(product, 'content.text.pageCount') || 0,
              publisher: _.get(product, 'content.publisher') || '',
              publishedAt: _.get(product, 'content.publishedAt') || ''
                ? moment(_.get(product, 'content.publishedAt')).format('YYYY-MM-DD')
                : '',
              bookType: _.get(product, 'productType')
                ? (['paperBook'].includes(_.get(product, 'productType'))
                    ?  `종이책`
                    : (['electronicBook'].includes(_.get(product, 'productType')) ? `전자책` : `알수없음`))
                : '알수없음',
              eachPrice: order.eachPrice,
              amount: order.amount,
              totalValue: order.eachPrice * 0.5 * order.amount
            }
        if (obj) {
          s[order.product].orders.push(order)
          s[order.product].amount = s[order.product].amount * 1 + order.amount * 1
          s[order.product].totalValue = s[order.product].eachPrice * 1 + order.amount * 1
        }
        return s
      }, {})
          
    // 도서명으로 정렬
    let datas = Object.values(distinctOrders)
    _.sortBy(datas, ['productName'], ['asc'])

    // 번호 넘버링 배정하기
    datas = datas
      .map((data, dIdx) => {
        data.no = dIdx + 1
        return data
      })
    
    // 종수와 권수 모두 표현
    const totalCount = datas.length
    const totalAmount = datas.reduce((s, data) => data.amount + s , 0)

    if (!window.confirm(`${curMt.format(`총 ${totalCount}종 ${totalAmount}부 입니다. 납본명세서를 다운로드 진행할까요?`)}`)) { return false }

    const totalData = {
      no: '총합계',
      amount: datas.reduce((s, o) => s + o.amount, 0),
      totalValue: datas.reduce((s, o) => s + o.totalValue, 0)
    }

    datas = [{}, {}, ...datas, totalData]

    return await new Exporter({ parser: legalExportParser, items: datas })
      .download(`납본명세서 총 ${totalCount}종 ${totalAmount}부 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')} - 국립중앙도서관 국가자료납본센터 귀중)`)
      .catch((e) => {
        alert(e.message)
        return false
      })
  }

  // 택배 엑셀 다운로드 기능
  actions.doDownloadExpressExcel = async () => {
    const retail = filters.retail || 'bookk'
    const retailSetup = retailsSetup && retailsSetup[retail] || { text: '알수없음' }
    
    const curAt = new Date(), curMt = moment(curAt)
    if (!window.confirm(`${curMt.format(`총 ${total}건 입니다. ${retailSetup.text} 운송장 요청서 다운로드 진행할까요?`)}`)) { return false }
    
    // 전체 데이터를 다시 받고
    const items = await actions.getItemsAll({}, { progress: true })
    if (!items || !items.length) { return alert(`최신 데이터를 불러올 수 없습니다.`) }

    return await new Exporter({ parser: expressExportParser, items })
      .download(`롯데택배 ${retailSetup.text} 운송장 요청서 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
      .catch((e) => {
        alert(e.message)
        return false
      })
  }

  // 운송장업로드 + 발송완료처리 기능
  actions.doUploadTrackingNumberExcel = async () => {
    const curAt = new Date(), curMt = moment(curAt)
    if (!window.confirm(`운송장번호(B열), 발송내역ID값(AG)열에 존재해야합니다. 진행할까요?`)) { return false }

    const retail = filters.retail || 'bookk'
    const retailSetup = retailsSetup && retailsSetup[retail] || { text: '알수없음' }

    const start = 2
    const parser = {
      name: `${retailSetup.text} 운송장 업로드`,
      columns: {
        'B': { name: 'trackingNumber', text: '운송장번호', parse: (cell) => cell },
        'AG': { name: 'itemId', text: '발송내역번호', parse: (cell) => cell }
      }
    }

    const result = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, start))
      .catch(e => ({ items: [] }))

    const items = result.items
    if (!items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    // 반영처리
    const lastResult = await items
      .reduce((prev, item) => 
        prev.then(async (report) => {
          const body = { trackingNumber: item.trackingNumber }
          const result = await api.put(`/packings/admin2/${item.itemId}/updateSentStatus`, body).catch(e => null)
          if (!result || result.error) {
            report.failure.push({ error: true, message: (result && result.message) ? result.message : '반영오류', item })
            return report
          }
          report.success.push({ error: false, message: (result && result.message) ? result.message : '반영성공', item })
          return report
        }),
        Promise.resolve({ success: [], failure: [] }))
      .catch((e) => ({ success: [], failure: [] }))
      
    if (lastResult.failure.length) { alert(`성공 ${lastResult.success.length}건, 실패 ${lastResult.failure.length}건이 발생하였습니다.`) }
    if (!lastResult.failure.length) { alert(`${lastResult.success.length}건이 성공적으로 처리하였습니다.`) }
    
    return initialize()
  }

  // 테블릿 모드 기능
  actions.doOpenPackingsPage = async () => {
    const retail = filters.retail || 'bookk'
    return window.open(`/packings/${retail}`, "", "status=no, toolbar=no, menubar=no, location=no")
  }  

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 포장내역이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="selector">
            <select
              value={filters.retail}
              onChange={e => {
                const value = e.target.value
                return handle({ page: 1, filters: { ...filters, retail: value } }, () => initialize())
              }}
            >
              {Object.values(retailsSetup).map((retail, rIdx) => <option key={rIdx} value={retail.name}>{retail.text}({retail.name})</option>)}
            </select>
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#byDuration"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const day = window.prompt('💴 특정일자를 예시 문자열 기준으로 작성해주세요. 기준시간부터 23:59:59초까지의 물량을 검색합니다.', moment().format('YYYY.MM.DD 00:00:00'))
              if (!day) { return }

              const retail = filters.retail || 'bookk'
              const url = `/parcels/packing?filters_retail=${retail}&paidAt=${day}`
              return window.location.href = url
            }}>
            💴 입금기준
          </a>

          <a
            href="#byDuration"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const day = window.prompt('📦 특정일자를 예시 문자열 기준으로 작성해주세요. 기준시간부터 23:59:59초까지의 물량을 검색합니다.', moment().format('YYYY.MM.DD 00:00:00'))
              if (!day) { return }

              const retail = filters.retail || 'bookk'
              const url = `/parcels/packing?filters_retail=${retail}&packingAt=${day}`
              return window.location.href = url
            }}>
            📦포장기준
          </a>

          <a
            href="#byDuration"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const day = window.prompt('🚚 특정일자를 예시 문자열 기준으로 작성해주세요. 기준시간부터 23:59:59초까지의 물량을 검색합니다.', moment().format('YYYY.MM.DD 00:00:00'))
              if (!day) { return }

              const retail = filters.retail || 'bookk'
              const url = `/parcels/packing?filters_retail=${retail}&sentAt=${day}`
              return window.location.href = url
            }}>
            🚚발송기준
          </a>

          {!['nlibrary'].includes(filters.retail)
            ? (<a
                  href="#doDownloadStorages"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doDownloadStorages('startAt')
                  }}>
                  📚 책장출납(부터)
                </a>)
            : null}

          {!['nlibrary'].includes(filters.retail)
            ? (<a
                  href="#doDownloadStorages"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doDownloadStorages('endAt')
                  }}>
                  📚 책장출납(까지)
                </a>)
            : null}

          {!['nlibrary'].includes(filters.retail)
            ? (<a
                  href="#doDownloadReceipts"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doDownloadReceipts()
                  }}>
                  거래명세서 다운로드
                </a>)
            : null}

          {['nlibrary'].includes(filters.retail)
            ? (<a
                  href="#doDownloadLegals"
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    return await actions.doDownloadLegals()
                  }}>
                  납본명세서 다운로드
                </a>)
            : null}

          <a
            href="#doDownloadExpressExcel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadExpressExcel()
            }}>
            택배엑셀 다운로드
          </a>
          <a
            href="#doUploadTrackingNumberExcel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doUploadTrackingNumberExcel()
            }}>
            운송장(발송완료) 업로드
          </a>
          <a href="#doOpenPackingsPage" onClick={e => [e.stopPropagation(), e.preventDefault(), actions.doOpenPackingsPage()]}>테블릿모드</a>
        </Header.Options>
      </Header>

      <Items.Body>
        <DataSet>
          <DataSet.Header>
            <DataSet.Item>
              {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
            </DataSet.Item>
          </DataSet.Header>
          <DataSet.Body>
            {items.map((item, itemIdx) => (
              <DataSet.Item key={item.id}>
                {columns.map((column, columIdx) => {
                  const columnProps = { column, columIdx, item, loadItems }
                  const extras = item.extras || {}

                  const meta = {}
                  meta.pay = _.get(extras, 'pays[0]') || {}
                  meta.payNo = _.get(meta.pay, 'payNo') || '-'
                  meta.retail = _.get(item, 'retail')
                  meta.retailsSetup = retailsSetup && retailsSetup[meta.retail] || { text: '기타' }
                  meta.paidAt = _.get(meta.pay, 'paidAt')
                  meta.payTotalPrice = _.get(meta.pay, 'totalPrice')
                  meta.paidMt = meta.paidAt ? moment(meta.paidAt) : null
                  meta.payName = _.get(item, 'name') || _.get(meta.pay, 'subject')
                  meta.user = _.get(extras, 'user') || {}
                  meta.userId = _.get(meta.pay, 'user')
                  meta.userNo = _.get(meta.user, 'userNo') || '-'
                  meta.userAccountId = _.get(meta.user, 'accountId') || '-'
                  meta.receiver = _.get(item, 'receiver') || '-'
                  meta.mobile = _.get(item, 'mobile') || '-'

                  meta.zip = _.get(item, 'zip') || '00000'
                  meta.address1 = _.get(item, 'address1') || '동주소 없음'
                  meta.address2 = _.get(item, 'address2') || '상세주소 없음'

                  // 정렬기준 처리
                  meta.sorted = _.get(item, '_sorted') || {}

                  // @ 보유한 내역 중 가장 최근 수령일
                  meta.lastQuantityAt = _.get(meta, 'sorted.lastQuantity.createdAt')
                  meta.lastQuantityMt = meta.lastQuantityAt ? moment(meta.lastQuantityAt) : null

                  return (
                    <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                      {(key => {
                        switch (key) {
                          case 'checkbox':
                            return (
                              <>
                                <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                              </>
                            )
                          case 'PayColumn':
                            return (
                              <div
                                onClick={(e) => {
                                  const payType = _.get(meta.pay, 'payType')
                                  const id = _.get(meta.pay, 'id')
                                  return window.open(`/pays/${payType}/${id}`)
                                }}
                                style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                              >
                                <div>
                                  <small>{_.get(meta, 'retailsSetup.text') || _.get(meta, 'retail')}</small>
                                </div>
                                <div>
                                  <strong>{meta.payNo}</strong>
                                </div>
                              </div>
                            )
                          case 'PaidDateColumn':
                            return (
                              <div>
                                <strong>{meta.paidMt ? meta.paidMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                <small style={{ marginLeft: '0.35rem' }}>{meta.paidMt ? meta.paidMt.format('HH:mm') : '00:00'}</small>
                              </div>
                            )
                          case 'BuyerColumn':
                            return (
                              <div
                                onClick={(e) => {
                                  return window.open(`/users/${meta.userId}`)
                                }}
                                style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                              >
                                <div><strong>{meta.userAccountId}</strong></div>
                                <div style={{ paddingTop: '0.15rem' }}><small>{meta.userNo}</small></div>
                              </div>
                            )
                          case 'ReceiverColumn':
                            return (
                              <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                <div><strong>{meta.receiver}</strong></div>
                                <div style={{ paddingTop: '0.15rem' }}><small>{meta.mobile}</small></div>
                              </div>
                            )
                          case 'OrdersDetailColumn':
                            return (
                              <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                <div>
                                  <strong>{meta.payName}</strong>
                                </div>
                                <div style={{ paddingTop: '0.15rem' }}>
                                  <small>총</small>
                                  <small style={{ marginLeft: '0.35rem' }}>
                                    <strong>{comma(meta.payTotalPrice)}</strong>
                                  </small>
                                  <small style={{ marginLeft: '0.25rem' }}>원</small>
                                </div>

                                <div style={{ paddingTop: '0.5rem' }}>
                                  <small>
                                    <strong title={meta.lastQuantityMt ? meta.lastQuantityMt.format('YYYY.MM.DD HH:mm') : '기록 없음'}>
                                      {_.get(meta, 'sorted.hasTodayFinishedInvoiceQuantity') ? `🟢 당일수령` : '⚪ 재고활용'}
                                    </strong>
                                  </small>
                                  <small> · </small>
                                  <small><strong>{_.get(meta, 'sorted.firstBook.bookSize.text') || '기타'}</strong></small>
                                  <small> · </small>
                                  <small><strong>{_.get(meta, 'sorted.firstBook.cover.flap') ? '🟢 날개있음' : '⚪ 날개없음'}</strong></small>
                                  <small> · </small>
                                  <small><strong>{`총 ${_.get(meta, 'sorted.totalAmount')}개`}</strong></small>
                                  <small> · </small>
                                  <small><strong>{`총 ${_.get(meta, 'sorted.totalAmount')}개`}</strong></small>
                                </div>

                              </div>
                            )
                          case 'AddressDetailColumn':
                            return (
                              <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                <div>
                                  <small style={{ marginRight: '0.35rem' }}>({meta.zip})</small>
                                  <strong>{meta.address1}</strong>
                                </div>
                                <div style={{ paddingTop: '0.15rem' }}><small>{meta.address2}</small></div>
                              </div>
                            )
                          default:
                        }
                      })(column.key)}
                    </DataSet.Col>
                  )
                })}
              </DataSet.Item>
            ))}
          </DataSet.Body>
          {!items.length ? (
            <Items.NotFound>
              <header>😄 포장중 데이터가 존재하지 않습니다.</header>
              <section>당일 모든 포장작업이 완료 되었거나 더이상 표현할 포장내역이 없습니다.</section>
            </Items.NotFound>
          ) : null}
        </DataSet>
      </Items.Body>
    
      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '포장목록',
  nav: 'packing'
}

export default List
