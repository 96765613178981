import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Caret from 'components/utils/Caret'
import Lnr from 'components/utils/Lnr'
import Swiper from 'components/utils/Swiper'

const Dashboard = styled.section`
  position: relative; box-sizing: border-box;
  max-width: 1400px; font-family: ${font('primary')};
  padding: 0rem 0;
  @media screen and (max-width: 980px) { padding: 1rem 0; }
`

Dashboard.Header = styled.header`
  position: relative; box-sizing: border-box;
  padding: 1rem; display: flex; justify-content: space-between; align-items: center;
  & > div.head { 
    position: relative; box-sizing: border-box;
    font-size: 1.6em; font-weight: 700;
    @media screen and (max-width: 980px) { font-size: 1.1em; }
    & > small { font-weight: 300; color: ${palette('muted', 4)}; margin-left: 0.55rem }
  }
  & > div.buttons { 
    position: relative; box-sizing: border-box;
    display: flex; justify-content: flex-end; align-items: center;
    & > span.char {
      display: block; padding: 0.35rem 0.65rem; transition: all 0.6s; letter-spacing: 0;
      font-family: ${font('primary')}; font-size: 1.1em; font-weight: 700; text-decoration: none;
      color: ${palette('muted', 1)}; border-radius: 10px;
      & > small {
        font-weight: 300; color: ${palette('muted', 9)};
        @media screen and (max-width: 980px) { display: none; }
      }
      &:hover { background: ${palette('muted', 15)}; color: ${palette('muted', 1)}; }
    }
    & > a {
      display: block; padding: 0.35rem 0.65rem; transition: all 0.6s;
      font-size: 1.8em; text-decoration: none; color: ${palette('muted', 6)}; border-radius: 10px;
      &:hover { background: ${palette('muted', 15)}; color: ${palette('muted', 1)}; }
    }
  }
`

Dashboard.Tools =  styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  overflow-x: auto; padding: 0.1rem;
`

Dashboard.Dates =  styled.div`
  position: relative; box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  & input {
    position: relative;
    min-width: 160px; max-width: 160px; width: 160px;
    min-height: 28px; max-height: 28px; height: 28px;
    margin-right: 0.618rem; background: white;
    box-shadow: 0 0 3px #ccc; padding: 0.25rem 0.5rem;
    border-radius: 0.5rem; border: 0; outline: none;
    border: 3px solid transparent; transition: all 0.3s;
    &:focus { border: 3px solid #232425; }
    }
`

Dashboard.Buttons = styled.div`
  position: relative; box-sizing: border-box;
  display: flex; align-items: center;
  padding: 1rem 0.35rem;
  & a {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 1rem; text-decoration: none;
    border-radius: 0.5rem; background: #eaeaea;
    white-space: nowrap;
    margin: 0.35rem; color: #999; opacity: 0.8;
    &:hover { color: #111; opacity: 1; }
  }
`

Dashboard.Hero = styled.article`
  position: relative; box-sizing: border-box;
  padding: 1rem; border-radius: 1.618rem;
  background: ${palette('muted', 16)};
  display: flex; justify-content: space-between; align-items: center;
  & > :first-child {
    flex: 1 1 auto; min-height: 260px;
    @media screen and (max-width: 980px) { min-height: 200px; }
  }
  & > :last-child {
    max-width: 340px; max-width: 340px;
    @media screen and (max-width: 980px) { display: none; }
  }
`

Dashboard.Box = styled.article`
  position: relative; box-sizing: border-box;
  border: 1px solid #ededed; margin-bottom: 1rem;
  border-radius: 0.5rem;
`

Dashboard.Head = styled.header`
  position: relative; box-sizing: border-box;
  font-weight: 700; font-size: 1.1em;
  padding: 0.75rem 1rem; border-bottom: 1px solid #ededed;
  color: ${palette('darkblue', 1)};
`

Dashboard.Body = styled.header`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 1rem; background: #f1f2f3;
`

Dashboard.Meta = styled.section`
  position: relative; box-sizing: border-box;
  padding: 0.5rem 0; font-size: 14px; line-height: 14px;
  display: flex; justify-content: space-between;
`

Dashboard.Label = styled.div`
  position: relative; box-sizing: border-box;
  min-width: 80px; max-width: 80px;
  color: ${palette('muted', 6)};
`

Dashboard.Value = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; text-align: right;
  color: ${palette('darkblue', 3)};
`

Dashboard.Menus = styled.div`
  display: flex; position: relative; box-sizing: border-box;
  overflow-x: auto;

  & > a {
    position: relative; box-sizing: border-box;
    padding: 0.5rem 0.75rem; text-decoration: none;
    white-space: nowrap; color: #333; transition: all 0.3s;
    &:hover { color: #111; }
  }
`

export { Dashboard, Caret, Lnr, Swiper }
