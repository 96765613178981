import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import Caret from 'components/utils/Caret'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 33%; min-width: 33%; max-width: 33%;
  text-align: center;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class StateColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {}
    meta.payType = item.payType
    meta.status = item.status
    meta.supplyStatus = item.supplyStatus
    meta.refundStatus = item.refundStatus

    const statusTypes = {}
    statusTypes.temporary = { name: 'temporary', text: '임시', caret: 'muted' }
    statusTypes.wait = { name: 'wait', text: '대기', caret: 'muted' }
    statusTypes.finished = { name: 'finished', text: '완료', caret: 'success' }
    statusTypes.cancel = { name: 'cancel', text: '취소', caret: 'danger' }
    statusTypes.error = { name: 'error', text: '에러', caret: 'muted' }
    statusTypes.deleted = { name: 'deleted', text: '삭제', caret: 'muted' }
    meta.statusType = statusTypes[meta.status]

    const refundStatusTypes = {}
    refundStatusTypes.none = { name: 'none', text: '없음', caret: 'muted' }
    refundStatusTypes.wait = { name: 'wait', text: '요청', caret: 'info' }
    refundStatusTypes.review = { name: 'review', text: '예정', caret: 'info' }
    refundStatusTypes.reject = { name: 'reject', text: '거절', caret: 'danger' }
    refundStatusTypes.accept = { name: 'accept', text: '완료', caret: 'success' }
    refundStatusTypes.cancel = { name: 'cancel', text: '취소', caret: 'muted' }
    meta.refundStatusType = refundStatusTypes[meta.refundStatus]

    const supplyStatusTypes = {}
    if (['paperBook'].includes(meta.payType)) {
      supplyStatusTypes.temporary = { name: 'temporary', text: '임시', caret: 'muted' }
      supplyStatusTypes.wait = { name: 'wait', text: '대기', caret: 'muted' }
      supplyStatusTypes.ready = { name: 'ready', text: '준비', caret: 'info' }
      supplyStatusTypes.packing = { name: 'packing', text: '포장', caret: 'info' }
      supplyStatusTypes.sent = { name: 'sent', text: '완료', caret: 'success' }
      supplyStatusTypes.cancel = { name: 'cancel', text: '취소', caret: 'danger' }
      supplyStatusTypes.error = { name: 'error', text: '오류', caret: 'muted' }
    }
    if (['electronicBook'].includes(meta.payType)) {
      supplyStatusTypes.temporary = { name: 'temporary', text: '임시', caret: 'muted' }
      supplyStatusTypes.wait = { name: 'wait', text: '대기', caret: 'muted' }
      supplyStatusTypes.ready = { name: 'ready', text: '가능', caret: 'info' }
      supplyStatusTypes.packing = { name: 'packing', text: '가능', caret: 'info' }
      supplyStatusTypes.sent = { name: 'sent', text: '완료', caret: 'success' }
      supplyStatusTypes.cancel = { name: 'cancel', text: '취소', caret: 'danger' }
      supplyStatusTypes.error = { name: 'error', text: '오류', caret: 'muted' }
    }
    meta.supplyStatusType = supplyStatusTypes[meta.supplyStatus]

    // 텍스트만 배정
    meta.statusText = meta.statusType.text || '미지정'
    meta.supplyStatusText = meta.supplyStatusType.text || '미지정'
    meta.refundStatusText = meta.refundStatusType.text || '미지정'

    return (
      <Column>

        <Meta.Group>
          <Meta>
            <header>
              <small>결제</small>
            </header>
            <section>
              <Caret c={meta.statusType.caret} />
              <strong style={{ marginRight: '0.25rem' }}>{meta.statusText || meta.status || '-'}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              {['paperBook'].includes(meta.payType) ? <small>발송</small> : null}
              {['electronicBook'].includes(meta.payType) ? <small>다운로드</small> : null}
              {!['paperBook', 'electronicBook'].includes(meta.payType) ? <small>진행</small> : null}
            </header>
            <section>
              <Caret c={meta.supplyStatusType.caret} />
              <strong style={{ marginRight: '0.25rem' }}>{meta.supplyStatusText || '-'}</strong>
            </section>
          </Meta>
          <Meta>
            <header>
              <small>환불</small>
            </header>
            <section>
              <Caret c={meta.refundStatusType.caret} />
              <strong style={{ marginRight: '0.25rem' }}>{meta.refundStatusText || '-'}</strong>
            </section>
          </Meta>
        </Meta.Group>

      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

StateColumnContainer.propTypes = {
  item: PropTypes.object,
}

StateColumnContainer.defaultProps = {
  item: {}
}

export default StateColumnContainer
