import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import Caret from 'components/utils/Caret'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

// 제출정보
class SubmitDetailContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {}
    
    // 상품제출일자
    meta.createdAt = _.get(item, 'createdAt')
    meta.createdMt = meta.createdAt ? moment(meta.createdAt) : null

    // 판매승인일자
    meta.activatedAt = _.get(item, 'activatedAt')
    meta.activatedMt = meta.activatedAt ? moment(meta.activatedAt) : null

    // 목적구분
    meta.purpose = _.get(item, 'content.purpose') || { name: 'internal', text: '일반판매용' }

    // 출판일자
    meta.publishedAt = _.get(item, 'content.publishedAt')
    meta.publishedMt = meta.publishedAt ? moment(meta.publishedAt) : null

    // 작가서비스 구매여부
    meta.isPurcharsed = ['purchased'].includes(_.get(item, 'content.cover.strategy.name'))
    meta.coverStrategy = _.get(item, 'content.cover.strategy.text')

    return (
      <Column>
        <Meta.Group style={{ minWidth: '100%' }}>
          <Meta style={{ minWidth: '100%' }}>
            <header>
              <small>제출일시</small>
            </header>
            <section>
              <strong>{meta.createdMt ? meta.createdMt.format('YY.MM.DD') : '-'}</strong>
              <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '-'}</small>
            </section>
          </Meta>
        </Meta.Group>
          {/* <Meta>
            <header>
              <small>출판일자</small>
            </header>
            {['external'].includes(_.get(meta, 'purpose.name'))
              ? (
                <>
                  <section>
                    <strong>{meta.publishedMt ? meta.publishedMt.format('YY.MM.DD') : '-'}</strong>
                  </section>
                  <section>
                    <small>{meta.publishedMt ? meta.publishedMt.format('HH:mm') : '-'}</small>
                  </section>
                </>
              )
              : null}
            {!['external'].includes(_.get(meta, 'purpose.name'))
              ? (
                <>
                  <section>
                    <strong>-</strong>
                  </section>
                  <section>
                    <small>-</small>
                  </section>
                </>
              )
              : null}
          </Meta>
          <Meta>
            <header>
              <small>판매개시일</small>
            </header>
            <section>
              <strong>{meta.activatedMt ? meta.activatedMt.format('YY.MM.DD') : '-'}</strong>
            </section>
            <section>
              <small>{meta.activatedMt ? meta.activatedMt.format('HH:mm') : '-'}</small>
            </section>
          </Meta> */}
        <Meta.Group style={{ minWidth: '100%' }}>
          <Meta style={{ minWidth: '100%' }}>
            <header>
              <small>표지 구매자 여부</small>
            </header>
            {meta.isPurcharsed ? (
              <section>
                <Caret c="info" style={{ marginRight: '0.25rem' }} />
                <strong>작가서비스</strong>
              </section>
            ) : null}
            {!meta.isPurcharsed ? (
              <section>
                <Caret c="muted" style={{ marginRight: '0.25rem' }} />
                <strong>{meta.coverStrategy}</strong>
              </section>
            ) : null}
          </Meta>
        </Meta.Group>
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

SubmitDetailContainer.propTypes = {
  item: PropTypes.object,
}

SubmitDetailContainer.defaultProps = {
  item: {}
}

export default SubmitDetailContainer
