import React from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma } from 'services/utils'
import { Importer, Exporter } from 'services/transform'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, resource, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  // columns.push({
  //   key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
  //   style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  // })
  columns.push({ key: 'PayColumn', header: '주문번호', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PaidDateColumn', header: '입금일', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BuyerColumn', header: '결제자', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReceiverColumn', header: '수령인', style: { minWidth: '140px', maxWidth: '140px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'OrdersDetailColumn', header: '주문정보', style: { minWidth: '450px', maxWidth: '450px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'AddressDetailColumn', header: '발송정보', style: { minWidth: '320px', maxWidth: '320px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  if (['sent'].includes(nav)) {
    columns.push({ key: 'TrackingDetailColumn', header: '운송장정보', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
    columns.push({ key: 'BlankColumn', header: '', style: { flex:  '1 1 auto', minWidth: 0, maxWidth: '100%' } })
    columns.push({ key: 'SentDateColumn', header: '발송완료일', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  } else {
    columns.push({ key: 'BlankColumn', header: '', style: { flex:  '1 1 auto', minWidth: 0, maxWidth: '100%' } })
    columns.push({ key: 'CancelDateColumn', header: '발송취소일', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  }

  
  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const parcelTypes = {}
  parcelTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  parcelTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  parcelTypes.solution = { name: 'solution', text: '작가서비스' }
  parcelTypes.etc = { name: 'etc', text: '기타' }

  const [form, setForm] = React.useState({})

  // 로우 작동 액션들
  const actions = {}

  // 운송장번호를 변경 할 수 있도록 처리함
  actions.doUpdateTrackingNumber = async (item = {}) => {
    if (item.trackingNumber && !window.confirm(`해당 운송장번호로 변경해둘까요?`)) { return }
    if (!item.trackingNumber && !window.confirm(`기재할 운송장번호가 없습니다. 그래도 반영할까요?`)) { return }

    const form = {}
    form.trackingNumber = item.trackingNumber

    const result = await api.put(`/parcels/admin2/${item.id}/trackingNumber`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `재고현황을 변경하던 도중 문제가 발생했습니다.`) }

    alert(`성공적으로 변경하였습니다.`)
    return await initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 발송내역이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
          <div className="selector">
            <input
              type="date"
              value={moment(startAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const startAt = new Date(year, month - 1, date), startMt = moment(startAt).startOf('day')
                const prevEndAt = new Date(endAt), prevEndMt = moment(prevEndAt).endOf('day')
                return startMt.diff(prevEndMt) > 0
                  ? handle({ 'startAt': prevEndMt.startOf('day').toDate(), 'endAt': startMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('startAt', startMt.toDate(), () => { initialize() })
              }}
            />
            <input
              type="date"
              value={moment(endAt).format('YYYY-MM-DD')}
              onChange={e => {
                if (!e.target.value) { return }
                const [year, month, date] = (e.target.value).split('-')
                const prevStartAt = new Date(startAt), prevStartMt = moment(prevStartAt).startOf('day')
                const endAt = new Date(year, month - 1, date), endMt = moment(endAt).endOf('day')
                return prevStartMt.diff(endMt) > 0
                  ? handle({ 'startAt': endMt.startOf('day').toDate(), 'endAt': prevStartMt.endOf('day').toDate() }, () => { initialize() })
                  : handle('endAt', endMt.toDate(), () => { initialize() })
              }}
            />
          </div>
        </Header.Search>
        <Header.Options>
          {/* <a href="#doOpenDepositCreateModal" onClick={e => [e.stopPropagation(), e.preventDefault()]}>포장중 단계전환</a> */}
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentPays = extras.currentPays || []
                    const lastPay = [...currentPays].pop() || {}
                    const currentUser = extras.currentUser || {}

                    const meta = {}

                    meta.parcelType = item.parcelType ? parcelTypes[item.parcelType] : parcelTypes.etc

                    meta.pay = lastPay
                    meta.payNo = _.get(meta.pay, 'payNo') || '-'
                    meta.paidAt = _.get(meta.pay, 'paidAt')
                    meta.payTotalPrice = _.get(meta.pay, 'totalPrice')
                    meta.paidMt = meta.paidAt ? moment(meta.paidAt) : null
                    meta.payName = _.get(item, 'name') || _.get(meta.pay, 'subject')

                    meta.user = currentUser
                    meta.userId = _.get(meta.pay, 'user')
                    meta.userNo = _.get(meta.user, 'userNo') || '-'
                    meta.userAccountId = _.get(meta.user, 'accountId') || '-'
                    meta.receiver = _.get(item, 'receiver') || '-'
                    meta.mobile = _.get(item, 'mobile') || '-'

                    meta.sentAt = _.get(item, 'sentAt')
                    meta.sentMt = meta.sentAt ? moment(meta.sentAt) : null

                    meta.cancelAt = _.get(item, 'cancelAt')
                    meta.cancelMt = meta.cancelAt ? moment(meta.cancelAt) : null

                    meta.zip = _.get(item, 'zip') || '00000'
                    meta.address1 = _.get(item, 'address1') || '동주소 없음'
                    meta.address2 = _.get(item, 'address2') || '상세주소 없음'
                    meta.trackingNumber = _.get(item, 'trackingNumber') || ''

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'PayColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    const payType = _.get(meta.pay, 'payType')
                                    const id = _.get(meta.pay, 'id')
                                    return window.open(`/pays/${payType}/${id}`)
                                  }}
                                  style={{
                                    cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal',
                                    width: '100%', minWidth: '100%', maxWidth: '100%'
                                  }}
                                >
                                  <div><strong>{meta.payNo}</strong></div>
                                  <div><small>{_.get(meta, 'parcelType.text')}</small></div>
                                </div>
                              )
                            case 'PaidDateColumn':
                              return (
                                <div style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }}>
                                  <strong>{meta.paidMt ? meta.paidMt.format('YYYY.MM.DD') : '0000.00.00'}</strong><br/>
                                  <small>{meta.paidMt ? meta.paidMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'BuyerColumn':
                              return (
                                <div
                                  onClick={(e) => {
                                    return window.open(`/users/${meta.userId}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                >
                                  <div><strong>{meta.userAccountId}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}><small>{meta.userNo}</small></div>
                                </div>
                              )
                            case 'ReceiverColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <div><strong>{meta.receiver}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}><small>{meta.mobile}</small></div>
                                </div>
                              )
                            case 'OrdersDetailColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <div><strong>{meta.payName}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}>
                                    <small>
                                      <small>총 결제액</small>
                                      <strong style={{ marginLeft: '0.35rem' }}>{comma(meta.payTotalPrice)}</strong>
                                      <small style={{ marginLeft: '0.25rem' }}>원</small>
                                    </small>
                                  </div>
                                </div>
                              )
                            case 'AddressDetailColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <div><strong>{meta.address1}</strong></div>
                                  <div style={{ paddingTop: '0.15rem' }}>
                                    <small>{meta.address2}</small>
                                    <small style={{ marginLeft: '0.35rem' }}>({meta.zip})</small>
                                  </div>
                                </div>
                              )
                            case 'TrackingDetailColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    style={{ marginRight: '0.35rem' }}
                                    value={`${meta.trackingNumber}`}
                                    onChange={e => {
                                      const n = `${e.target.value}`.replace(/[^0-9]/g, '')
                                      const value = _.isInteger(n * 1) ? (n * 1) : meta.trackingNumber
                                      const next = {}
                                      next[`items[${itemIdx}].trackingNumber`] = value
                                      return handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={async (e) => {
                                      return [e.stopPropagation(), e.preventDefault(), await actions.doUpdateTrackingNumber(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>
                              )
                            case 'SentDateColumn':
                              return (
                                <div>
                                  <strong>{meta.sentMt ? meta.sentMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.sentMt ? meta.sentMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            case 'CancelDateColumn':
                              return (
                                <div>
                                  <strong>{meta.cancelMt ? meta.cancelMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.cancelMt ? meta.cancelMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>해당 발송내역 정보가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '발송내역',
  nav: 'sent'
}

export default List
