import React from 'react'
import PropTypes from 'prop-types'
import _, { remove } from 'lodash'
import api, { REACT_APP_FILE_URL } from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import Modal from 'components/utils/Modal'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  font-family: ${font('primary')};

  & .filePreview {
    position: relative; box-sizing: border-box;
    & > div.file {
      position: relative; box-sizing: border-box;
      padding: 0.15rem 0; transition: all 0.3s; border-radius: 0.35rem;
      cursor: pointer;
      & > div.image {
        position: relative; box-sizing: border-box;
        display: flex; justify-content: center; align-items: center;
        min-height: 80px; max-height: 80px; height: 80px;
        margin: 0.35rem 0;
        & > img { height: 100%; }
      }
      & > div.document {
        position: relative; box-sizing: border-box;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }
      & > div.empty {
        position: relative; box-sizing: border-box; opacity: 0.3;
        padding: 0.5rem; border-radius: 0.35rem; margin: 0.35rem;
        min-height: 60px; max-height: 60px; height: 60px;
        background: ${palette('muted', 21)};
        box-shadow: 0 0 3px ${palette('muted', 13)};
        & strong { font-weight: 900; color: ${palette('muted', 1)}; }
        & > img { height: 100%; }
      }
      & > div.meta {
        text-align: center;
        font-size: 0.95em;
      }
      &:hover { background: ${palette('muted', 15)}; }
    }
  }

  & a.downloadButton {
    padding: 0.35rem; border-radius: 0.35rem; font-weight: 500;
    transition: all 0.3s; cursor: pointer; margin: 0.25rem 0;
    text-decoration: none;
    background: ${palette('muted', 2)}; color: ${palette('muted', 21)};
    &:hover { background: ${palette('muted', 0)}; color: ${palette('muted', 21)}; }
  }
`

const ModalForm = styled.div`
  position: relative; box-sizing: border-box;
  padding: 1rem; padding-top: 0; max-width: 400px;
  
  & > header {
    position: relative; box-sizing: border-box;
    padding: 1.5rem 0; font-size: 1.3em;
    font-weight: 700;
  }

  & > section.preview {
    position: relative; box-sizing: border-box;

    & div.document {
      position: relative; box-sizing: border-box;
      padding: 1rem; border-radius: 0.35rem;
      box-shadow: 0 0 3px ${palette('muted', 10)};
      & > div.extension {
        position: relative; box-sizing: border-box;
        font-size: 1.3em; font-weight: 700;
        padding: 0.35rem 0;
      }
      & > div.subscript {
        line-height: 1.612em; font-size: 0.9em;
        color: ${palette('muted', 4)};
      }
    }

    & div.empty {
      position: relative; box-sizing: border-box;
      line-height: 1.612em; font-size: 0.9em; opacity: 0.3;
      padding: 3rem 0.5rem; color: ${palette('muted', 4)};
      & strong { color: ${palette('muted', 1)}; }
    }
  }

  & > section.form {
    position: relative; box-sizing: border-box;
    & > div { padding: 0.5rem 0; }
    & > form { padding: 0.5rem 0; }
  }

  & > footer {
    position: relative; box-sizing: border-box;
    padding-top: 1rem;
  }

  & .menus {
    display: flex; justify-content: flex-end;
    position: relative; box-sizing: border-box;
    & > a {
      position: relative; box-sizing: border-box;
      display: block; padding: 0.25rem 0.5rem; transition: all 0.3s;
      color: ${palette('muted', 5)}; text-decoration: none;
      &:hover { color: ${palette('darkblue', 2)} }
    }
  }

  & input[type=text],
  & input[type=date],
  & input[type=number] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 5)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & input[type=file] {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & select {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.5rem 0.75rem; font-weight: 500;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & textarea {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    flex: 1 1 100%; padding: 0.75rem; line-height: 1.615rem;
    width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-decoration: none; font-family: ${font('primary')}; font-size: 1em;
    color: ${palette('muted', 3)}; border: 0; border-radius: 0.35rem;
    background: ${palette('muted', 18)};
    &:hover { background: ${palette('muted', 15)}; }
    &:focus { background: ${palette('muted', 12)}; }
  }

  & a.button {
    display: block; box-sizing: border-box; transition: all 0.3s; cursor: pointer;
    background: ${palette('darkblue', 8)}; color: ${palette('muted', 21)};
    border: 0; border-radius: 0.35rem; font-weight: 700; text-decoration: none;
    text-align: center; padding: 0.75rem 0; font-size: 1em;
    &:hover { background: ${palette('darkblue', 15)}; color: ${palette('muted', 21)}; }
  }
`

// 상태 값을 바꾸어주는 모달창
const BookTextColumnModal = ({
  item = {},
  isOpen = false,
  onClose = () => {},
  parent = { loadItems: async () => {} },
}) => {

  const meta = {}
  meta.bookType = _.get(item, 'extras.currentBook.type')
  meta.allowExts = ['paperBook'].includes(meta.bookType)
    ? ['HWP', 'DOC', 'DOCX', 'PDF']
    : ['HWP', 'DOC', 'DOCX', 'EPUB', 'PDF']

  const initForm = {
    files: [], // FileElement Objects
    previews: 
      _.get(item, 'content.text.path')
      ? (() => {
        // 해당 파일이 어떤 확장자인지 구분해주기
        const obj = { ext: 'jpg', name: '이미지파일.jpg', url: `${REACT_APP_FILE_URL}${_.get(item, 'content.text.path')}` }
        if (obj.url) {
          const ext = obj.url.split('.').reverse()[0]
          const name = obj.url.split('/').reverse()[0]
          if (name) { obj.name = name }
          if (ext) { obj.ext = ext.toUpperCase() }
        }
        return [obj]
      })()
      : [] // Readed Binary Bolb URLs
  }

  const uploadForm = React.useRef(null)
  const [form, setForm] = React.useState(initForm)
  const modalProps = { isOpen, onClose }

  // 미리보기 파일들 (표지 업로드 파일에 관련된 파일이 있는지를 검사하고 미리보기 파일에 담는다.)
  const previews = (form.previews && form.previews.length > 0)
    ? form.previews
    : initForm.previews.length > 0 ? initForm.previews : []

  // 파일 올리기 기능
  const doSubmit = async (form) => {
    const curAt = new Date()
    // 삭제 누르고 저장눌렀을때도 반영해줘야한다.
    if (!form.files.length) { return alert(`업로드할 파일이 지정되지 않았습니다.`) }

    // 작업하는 파일 큐를 구성한다.
    const uploadeds = await form.files.reduce((prev, file) =>
      prev.then(async (uploadeds) => {
        const bookId = _.get(item, 'content.book.id') || _.get(item, 'content.bookId')
        if (!bookId) { return uploadeds }
        const formData = new FormData()
        formData.append(`file`, file)
        const path = await api.upload(`/approvals/admin/${bookId}/textByBookFileChange`, formData, { base: 'file' })
          .then(({ path }) => path)
          .catch(e => null)
        if (path) { uploadeds.push({ path, file }) }
        return uploadeds
      }),
      Promise.resolve([]))

    // 해당 도서번호 기준으로 파일을 업로드한다. BookSubmit 컨셉으로 파일서버에 업로드한다. 관리자 엔드포인트로 직접 날린다.
    return await uploadeds.reduce((prev, uploaded) =>
      prev.then(async (report) => {
        const { file, path } = uploaded
        const data = { path: path, ext: path.split('.').reverse()[0], name: file.name, mime: file.mimetype, status: 'completed', createdAt: curAt, updatedAt: curAt, size: file.size ? file.size * 1 : 0 }
        const obj = await api.put(`/approvals/admin2/${item.id}/bookFileChangeTextFile`, data)
          .catch(e => ({ error: true, message: e.message }))
        if (obj) { report.push(obj) }
        return report
      }),
      Promise.resolve([])
    )
    .then(async (reports) => {
      const isHasError = reports.filter(report => report.error).length > 0 ? true : false
      if (isHasError) {
        alert(`처리 도중 문제가 발생하였습니다.`)
        console.log(reports)
        return false
      }
      await parent.loadItems()
      return onClose()
    })
    .catch(e => {
      alert(`데이터 저장중 문제가 발생하였습니다. (${e.message})`)
      return false
    })
  }

  // 큐에 보관된 데이터 다중삭제
  const removeFiles = async () => {
    // 현재 파일업로드가 임시로 선택이 되어있는 상태라면
    if (form.files.length > 0) { return setForm(initForm) }

    if (!window.confirm(`삭제시 바로 반영됩니다. 삭제를 진행할까요?`)) { return }

    // 아무파일도 업로드 하지 않고 이미 업로드된 파일을 삭제하는 경우라면
    if (form.files.length === 0 && form.previews.length) {
      const emptyData = { path: '', size: 0, ext: '', mime: '', status: 'wait', name: '', createdAt: null }
      await api.put(`/approvals/admin2/${item.id}/bookFileChangeTextFile`, emptyData)
        .then(async ({ error, message }) => {
          if (error) {
            alert(`처리 도중 문제가 발생하였습니다. ${message}`)
            return false
          }
          await parent.loadItems()
          return onClose()
        })
        .catch(e => {
          alert(`데이터 저장중 문제가 발생하였습니다. (${e.message})`)
          return false
        })
      await parent.loadItems()
      return onClose()
    }

    return alert(`삭제할 이미지가 없습니다.`)
  }

  return (
    <Modal {...modalProps}>
      <ModalForm>
        <header>
          파일교체 원고 요청파일 <small>관리</small>
        </header>

        <section className="preview">

          {previews
            .map((preview, pIdx) => {
              return (
                <div className="document" key={`file_${pIdx}`}>
                  <div className="extension">{preview.ext}</div>
                  <div className="subscript">{preview.name}</div>
                </div>
              )
            })}

          {previews.length === 0 ? (
            <div className="empty">
              <strong>내지(원고)파일이 없습니다.</strong><br/>
              원고를 업로드해주세요.
            </div>
          ) : null}
        </section>
        
        <section className="form">
          {previews.length ? (
            <div className="menus">
              <a
                href="#delete"
                onClick={e => [e.preventDefault(), e.stopPropagation(), removeFiles()]}
              >
                {previews.length === 1 ? `삭제` : `모두삭제`}
              </a>
            </div>
          ) : null}

          <form ref={uploadForm}>
            <input
              type="file"
              onChange={async (e) => {
                if (e.target.files.length === 0) { return }

                const files = [], previews = [], maxIdx = 1 // e.target.files.length
                
                // 업로드될 파일들을 선검사하기
                for(let i = 0; i < maxIdx; i++) {
                  let ext = e.target.files[i].name.split('.').reverse()[0]
                  if (ext) { ext = ext.toUpperCase() }
                  if (!ext || !meta.allowExts.includes(ext)) {
                    uploadForm.current.reset()
                    return alert(`허용되는 확장자가 아닙니다.`)
                  }
                }

                // 파일 미리보기 읽기 기능
                const readFile = (file) => {
                  const reader = new FileReader()
                  reader.onload = e => {
                    files.push(file)
                    let ext = file.name.split('.').reverse()[0]
                    if (ext) { ext = ext.toLowerCase() }
                    previews.push({ ext, name: file.name, url: e.target.result })
                    setForm({ ...form, files, previews })
                  }
                  reader.readAsDataURL(file)
                }

                // 파일 리드해서 처리하는 기능
                for(let i = 0; i < maxIdx; i++) { readFile(e.target.files[i]) }
                setTimeout(() => uploadForm.current.reset(), 1)
              }}
            />
          </form>
        </section>
        <footer>
          <a
            href="#save"
            className="button"
            style={{ minWidith: '100%', maxWidith: '100%', width: '100%' }}
            onClick={(e) => [e.stopPropagation(), e.preventDefault(), doSubmit(form)]}
          >
            파일 올리기
          </a>
        </footer>
      </ModalForm>
    </Modal>
  )

}

class BookTextByApprovalColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = { modal: {} }

    this.uploadEmptyFile = this.uploadEmptyFile.bind(this)
    this.clearFile = this.clearFile.bind(this)
  }

  async uploadEmptyFile(item = {}) {
    const { loadItems } = this.props
    const emptyData = { path: '/empty-process.pdf', size: 1, ext: 'pdf', mime: 'application/pdf', status: 'finished', name: '빈파일 업로드.pdf', createdAt: new Date() }
    await api.put(`/approvals/admin2/${item.id}/bookFileChangeTextFile`, emptyData).catch((e) => null)
    await loadItems().catch((e) => null)
  }

  async clearFile(item = {}) {
    const { loadItems } = this.props
    const emptyData = { path: '', size: 0, ext: '', mime: '', status: 'wait', name: '', createdAt: null }
    await api.put(`/approvals/admin2/${item.id}/bookFileChangeTextFile`, emptyData).catch((e) => null)
    await loadItems().catch((e) => null)
  }
  
  render() {
    const that = this
    const { uploadEmptyFile, clearFile } = this
    const { modal } = this.state
    const { item, loadItems } = this.props

    // 해당 문건의 표지파일이 문서인지, 이미지인지 구분한다.
    const file = _.get(item, 'content.text.path') || ''
    const productId = _.get(item, 'content.productId') || _.get(item, 'content.product') || ''
    const createdAt = _.get(item, 'content.text.createdAt') || null
    const createdMt = createdAt ? moment(createdAt) : null
    const ext = (file ? file.split('.').reverse()[0] : '').toLowerCase()

    const actions = {}

    // @ 내지파일명 종이도서 파일명 결정, 2024.11.07
    actions.getPaperBookFileTextName = (book = {}, productId = '') => {
      if (!book) { return alert(`상품정보가 존재하지 않습니다.`) }
      if (['electronicBook'].includes(book.type)) { return alert('전자책입니다.') }

      const curAt = new Date(), curMt = moment(curAt)
      const bookTitle = _.get(book, 'title').substring(0, 60)
      const bookSize = _.get(book, 'bookSize.text').replace('판', '')
      const textPageCount = _.get(book, 'text.pageCount') || 0
      const textBlankPageCount = _.get(book, 'text.blankPageCount') || 0
      const isColor = ['color'].includes(_.get(book, 'text.color.name'))
      const isCoverFlap = _.get(book, 'cover.flap')
      const isTextFullPrint = _.get(book, 'fullPrint')
      const arr = [
        `[${bookSize}]${bookTitle}(${curMt.format('YY.MM.DD')}수정)`,
        `${textPageCount}p`,
        !isColor ? `BK` : `CL`, // 흑백, 컬러
        isCoverFlap ? `CF` : `NCF`, // 흑백, 컬러
        isTextFullPrint ? `FP` : `NFP`, // 흑백, 컬러
        `BN${textBlankPageCount ? textBlankPageCount : 0}.${productId}`
      ]
      
      let text = arr.join('_').replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
      return text
    }

    // @ 내지파일명 전자도서 파일명 결정, 2024.11.07
    actions.getElectronicBookFileTextName = (book = {}, productId = '') => {
      const curAt = new Date(), curMt = moment(curAt)
      const bookIdentify =[]
      bookIdentify.push(`[전자책]`)
      bookIdentify.push(book.title || '원고')
      const fileName = []
      fileName.push(bookIdentify.join(''))
      fileName.push(book.author || '저자명')
      return `${fileName.join(`_`)}(${curMt.format('YY.MM.DD')}수정)`
    }

    // 원고파일 다운로드명 구성해주기 : [전자책]우리동네에 놀러와요_하나둘셋SLP.pdf
    actions.getDownloadFileName = (book = {}, productId = '') => {
      const ext = (_.get(item, 'content.text.ext') || _.get(item, 'content.text.path').split('.').pop()).toLowerCase()

      // @ 종이도서일 때 파일명 결정방식
      if (['paperBook'].includes(book.type)) {
        const paperBookFilenName = actions.getPaperBookFileTextName(book, productId)
        return `${paperBookFilenName}.${ext}`
      // @ 전자도서일 때 파일명 결정방식
      } else {
        const electronicBookFilenName = actions.getElectronicBookFileTextName(book, productId)
        return `${electronicBookFilenName}.${ext}`
      }
    }

    return (
      <Column>

        <div className="filePreview">
          <div
            className="file"
            title={file}
            onClick={e => {
              return [e.stopPropagation(), e.preventDefault(), that.setState({ modal: { name: 'BookTextColumnModal' } })]
            }}
          >
            {file
              ? (
                <div className="document">
                  <div><strong>{ext.toUpperCase()}</strong></div>
                  <div><small>문서파일</small></div>
                </div>
              )
              : null}

            {!file || !ext ? (
              <div className="empty">
                <div><strong>-</strong></div>
                <div><small>빈파일</small></div>
              </div>
            ) : null}

            <div className="meta">
              {createdMt ? (
                <div>
                  <small style={{ fontWeight: '500', marginRight: '0.15rem' }}>
                  <strong>{createdMt.format('YYYY.MM.DD')}</strong></small>
                  <small>{createdMt.format('HH:mm')}</small>
                </div>
              ) : null}
            </div>

          </div>
        </div>

        {file ? (
          <a
            className={`downloadButton`}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            href={`#원고`}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const extras = item.extras || {}
              const book = extras.currentBook || {}

              await fetch(`${REACT_APP_FILE_URL}${file}`)
                .then(response => response.blob())
                .then(blob => {
                  let url = window.URL.createObjectURL(blob)
                  let a = document.createElement('a')
                  a.href = url
                  a.download = actions.getDownloadFileName(book, productId)
                  document.body.appendChild(a);
                  a.click();    
                  a.remove();  
                })
                .catch(e => alert(`다운로드를 할 수 없습니다.`))
            }}
          >
            다운로드
          </a>
        ) : null}

        <div style={{ padding: '0.5rem 0' }}>
          <a
            href="#uploadEmptyFile"
            style={{ padding: '0.35rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await uploadEmptyFile(item)
            }}
          >
            빈파일처리
          </a>
          <a
            href="#clearFile"
            style={{ padding: '0.35rem' }}
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await clearFile(item)
            }}
          >
            비우기
          </a>
        </div>

        {modal && modal.name === 'BookTextColumnModal'
          ? (
            <BookTextColumnModal
              item={item} parent={{ loadItems }}
              isOpen={true} onClose={e => that.setState({ modal: {} })}
            />
          )
          : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

BookTextByApprovalColumnContainer.propTypes = {
  item: PropTypes.object,
  loadItems: PropTypes.func,
}

BookTextByApprovalColumnContainer.defaultProps = {
  item: {},
  loadItems: async () => { console.log(`loadItems 미연동`) }
}

export default BookTextByApprovalColumnContainer
