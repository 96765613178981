import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'

import Page from 'components/layouts/Page'
import AdminSiginForm from 'components/extras/AdminSiginForm'

class SigninPageContainer extends Component {
  render() {
    const { admin, history, location, match } = this.props, { successUrl, failedUrl } = qs.parse(location.search)
    const commonProps = { admin, history, location, match }, formProps = { ...commonProps, successUrl, failedUrl }
    return (
      <Page full><AdminSiginForm { ...formProps } /></Page>
    )
  }
}

SigninPageContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

SigninPageContainer.defaultProps = {
  history: {},
  location: {},
  match: {},
}

export default SigninPageContainer
