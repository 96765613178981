import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class ApprovalDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }
  
  render() {
    const { item = {} } = this.props

    const meta = {
      firstAt: null,
      currentStatus: _.get(item, 'status'), currentStatusText: '-',
      currentAt: null, currentMt: null
    }
    
    // 최초 제출일시를 기록한다.
    meta.firstAt = _.get(item, 'createdAt')
    meta.firstMt = meta.firstAt ? moment(meta.firstAt) : null

    // 문서 제출 상태에 따른 표현방식을 구현하기
    if (meta.currentStatus === 'wait') {
      meta.currentStatusText = '제출일시'
      meta.currentAt = _.get(item, 'waitAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'review') {
      meta.currentStatusText = '처리중일시'
      meta.currentAt = _.get(item, 'reviewAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'reject') {
      meta.currentStatusText = '반려처리일시'
      meta.currentAt = _.get(item, 'rejectAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'accept') {
      meta.currentStatusText = '처리완료일시'
      meta.currentAt = _.get(item, 'acceptAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }
    if (meta.currentStatus === 'cancel') {
      meta.currentStatusText = '취소처리일시'
      meta.currentAt = _.get(item, 'cancelAt') || _.get(item, 'updatedAt')
      meta.currentMt = meta.currentAt ? moment(meta.currentAt) : null
    }

    return (
      <Column>
        <Meta.Group>
          <Meta>
            <header>
              <small>생성일시</small>
            </header>
            <section>
              <strong>{meta.firstMt ? meta.firstMt.format('YY.MM.DD') : '-'}</strong>
            </section>
            <section>
              <small>{meta.firstMt ? meta.firstMt.format('HH:mm') : '-'}</small>
            </section>
          </Meta>
          {meta.currentStatusText ? (
            <Meta>
              <header>
                <small>{meta.currentStatusText}</small>
              </header>
              <section>
                <strong>{meta.currentMt ? meta.currentMt.format('YY.MM.DD') : '-'}</strong>
              </section>
              <section>
                <small>{meta.currentMt ? meta.currentMt.format('HH:mm') : '-'}</small>
              </section>
            </Meta>
          ) : null}
        </Meta.Group>
      </Column>
    )
  }
}

ApprovalDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

ApprovalDetailColumnContainer.defaultProps = {
  item: {}
}

export default ApprovalDetailColumnContainer
