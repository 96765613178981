import React from 'react'
import PropTypes from 'prop-types'
import { retailsKeys } from 'services/constants/retail'

import _ from 'lodash'
import api from 'services/api'
import { comma } from 'services/utils'
import qs from 'query-string'
import moment from 'services/moment'
import { Exporter } from 'services/transform'

import { connect } from 'react-redux'
import * as actions from 'store/actions'
import { fromAdmin } from 'store/selectors'

import Swiper, { SwiperSlide } from 'components/utils/Swiper'
import ProgressModal  from 'components/utils/ProgressModal'

import { Dashboard, Lnr } from './utils'

class AdminDashboardContainer extends React.Component {
  constructor(props) {
    super(props)

    this.swiper1 = React.createRef(null)
    this.swiper2 = React.createRef(null)

    this.abortController = new AbortController()

    this.initialState = this.initialState.bind(this)
    this.downloadExcel = this.downloadExcel.bind(this)
    this.downloadStaticsExcel = this.downloadStaticsExcel.bind(this)
    this.initialize = this.initialize.bind(this)
    this.loadItem = this.loadItem.bind(this)

    this.state = this.initialState(props)
  }

  componentDidMount() {
    this.initialize(this.props)
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  // 현황 대시보드 불러오기 기능
  async loadItem(panel = 'none', label = '라벨없음', query = {}) {
    const execQuery = { ...query, label }
    return api
      .get(`/admins/dashboard/${panel}?${qs.stringify(execQuery)}`, { 
        signal: this.abortController.signal })
      .then((data) => data)
      .catch((e) => {
        console.log(panel, e.message, execQuery)
        return null
      })
  }

  // 최초 상태값 
  initialState(props = this.props) {
    const query = qs.parse(window.location.search)

    const basedAt = query.basedAt ? moment(query.basedAt).toDate() : new Date()

    const state = {
      basedAt,
      loading: true, swiper: null, 
      pending: false,
      progress: null // { rate: 0, message: '', onClose: () => {} }
    }
    
    const panels = []
    panels.push({ name: 'userPanel', header: '🙆‍♂️ 회원', caption: '탈퇴자 제외', items: [] })
    panels.push({ name: 'productPanel', header: '📦 상품', caption: '상품 삭제 제외', items: [] })
    panels.push({ name: 'reviewPanel', header: '📋 심사', caption: '삭제 제외', items: [] })
    panels.push({ name: 'managePanel', header: '🧞‍♂️ 운영', caption: '삭제 제외', items: [] })
    panels.push({ name: 'stockPanel', header: '🚚 재고·발송', caption: '삭제 제외', items: [] })
    panels.push({ name: 'bookkPayPanel', header: '🏠 부크크', caption: '환불 제외', items: [] })
    panels.push({ name: 'retailPaperBookPayPanel', header: '🏬 외부유통(종이책)', caption: '환불 제외', items: [] })
    panels.push({ name: 'retailElectronicBookPayPanel', header: '🏬 외부유통(전자책)', caption: '환불 제외', items: [] })
    panels.push({ name: 'profitAPanel', header: '💰 정산', caption: '삭제 제외', items: [] })
    panels.push({ name: 'profitBPanel', header: '🏦 지급', caption: '삭제 제외', items: [] })
    state.panels = panels

    return state
  }

  // 한달치 데이터 다운로드 기능
  async downloadExcel() {
    const that = this
    if (this.state.pending) { return alert(`처리중입니다. 잠시만 기다려주세요.`) }

    const curAt = new Date(), curMt = moment(curAt)

    const input = window.prompt(`기준월을 년월을 기재해주세요. (예, 2023.07 처럼 쩜을 넣어주시면 구분자가 됩니다. 홑수 월인경우 앞에 0을 반드시 넣어주세요.)`, curMt.format('YYYY.MM'))
    if (!input) { return }

    // 입력 숫자 구분처리
    let [year, month] = input.split('.')
    year = parseInt(year)
    month = parseInt(month)
    if (!year || _.isNaN(year * 1)) { return alert(`년도는 반드시 숫자여야 합니다.`) }
    if (`${year}`.toString().length !== 4) { return alert(`년도는 반드시 4자리 숫자여야 합니다.`) }
    year = Math.abs(year)
    if (!month || _.isNaN(month * 1)) { return alert(`월은 반드시 숫자여야 합니다.`) }
    month = Math.abs(month)
    if (!(month >= 1 && month <= 12)) { return alert(`월은 반드시 2자리 이내 숫자여야 합니다.`) }

    const startMt = moment(new Date(year, (month * 1) -1, 1, 0, 0, 0)).startOf('month'), startMonthAt = startMt.toDate()
    const endMonthMt = (curMt.format('YYYY-MM') === startMt.format('YYYY-MM'))
      ? moment(curAt).endOf('day')
      : moment(startMonthAt).endOf('month')
    const endMonthAt = endMonthMt.toDate()

    // 총 불러올 작업일수를 구성한다.
    const days = Array.from({ length: endMonthMt.format('DD') * 1 }).map((b, bIdx) => bIdx + 1)
    
    this.setState({ pending: true, progress: null })

    // 보고 문서 취합하기
    const document = await days.reduce((prev, day, idx, arr) => 
      prev.then(async (report) => {
        const progress = { 
          message: `${idx+1}/${arr.length} 일 데이터를 일자별로 다운로드중... 🍟`,
          rate: ((idx + 1)/arr.length) * 100,
          onClose: () => this.setState({ pending: false, progress: null })
        }
        this.setState({ pending: true, progress })

        const startMt = moment(startMonthAt).set('date', day).startOf('day'), startAt = startMt.toDate()
        const endMt = moment(startMonthAt).set('date', day).endOf('day'), endAt = endMt.toDate()

        // @ 부크크 매출 종합
        const elem = { day, startMt, startAt, endMt, endAt }
        const bookk = { paperBook: {}, electronicBook: {}, solution: {}, etc: {} }
        bookk.paperBook = await this.loadItem('payCount', '종이도서', { payType: 'paperBook', retail: 'bookk', startAt, endAt, atKey: 'paidAt' }).catch(e => ({ error: true, message: e.message }))
        bookk.electronicBook = await this.loadItem('payCount', '전자도서', { payType: 'electronicBook', retail: 'bookk', startAt, endAt, atKey: 'paidAt' }).catch(e => ({ error: true, message: e.message }))
        bookk.solution = await this.loadItem('payCount', '작가서비스', { payType: 'solution', retail: 'bookk', startAt, endAt, atKey: 'paidAt' }).catch(e => ({ error: true, message: e.message }))
        bookk.etc = await this.loadItem('payCount', '기타', { payType: 'etc', retail: 'bookk', startAt, endAt, atKey: 'paidAt' }).catch(e => ({ error: true, message: e.message }))
        elem.bookk = bookk

        // @ 외부유통 합산종합
        const retails = {}
        Object.keys(retailsKeys).reduce((p, key) => 
          p.then(async (s) => {
            const rName = retailsKeys[key]
            const payload = { payType: 'paperBook', retail: key, startAt, endAt }
            retails[key] = await that.loadItem('payCount', rName, payload)
              .catch(e => ({ error: true, message: e.message }))
            return s
          }),
          Promise.resolve({}))
          .catch(e => null)
        elem.retails = retails

        report.items.push(elem)
        return report
      }),
      Promise.resolve({ items: [] }))
    .catch((e) => {
      console.log(e.message)
      console.log(e.stack)
      return ({ items: [] })
    })

  /* { "label": "전자도서", "primary": "amount", "count": 5, "amount": 24000, "unit": "원", "startMt": "2023.12.07 00:00", "endMt": "2023.12.07 23:59" } */
  
    // 컬럼 구성
    const columns = {}
    columns.month = { key: 'month', header: '월', view: () => startMt.format('MM') }
    columns.day = { key: 'day', header: '일', view: (item = {}) => item.day ? `${item.day}` : `-` }
    columns.dayOf = { key: 'dayOf', header: '요일', view: (item = {}) => item.startMt ? `${item.startMt.format('ddd')}` : `-` }

    // 부크크 매출구간 컬럼 파싱부
    const bookkKeys = { paperBook: '종이도서', electronicBook: '전자도서', solution: '작가서비스', etc: '기타' }
    Object.keys(bookkKeys)
      .forEach(key => {
        const col = bookkKeys[key]
        columns[`bookk_${key}_amount`] = { key: `bookk_${key}_amount`, header: `${col} 액수`, view: (item = {}) => _.get(item, `bookk.${key}.amount`) || 0 }
        columns[`bookk_${key}_count`] = { key: `bookk_${key}_count`, header: `${col} 건수`, view: (item = {}) => _.get(item, `bookk.${key}.count`) || 0 }
      })

    // 외부유통 매출구간 컬럼 파싱부
    Object.keys(retailsKeys)
      .forEach(key => {
        const col = retailsKeys[key]
        columns[`retails_${key}_amount`] = { key: `retails_${key}_amount`, header: `${col} 액수`, view: (item = {}) => _.get(item, `retails.${key}.amount`) || 0 }
        columns[`retails_${key}_count`] = { key: `retails_${key}_count`, header: `${col} 건수`, view: (item = {}) => _.get(item, `retails.${key}.count`) || 0 }
      })
    columns.memo = { key: 'memo', header: '비고', view: (item = {}) => ''}
    
    // 다운로드
    const parser = { key: 'bookk-sales-report-by-monthly', text: `${input} 기준 부크크 매출종합 데이터 (${endMonthMt.format('YYYY-MM-DD')}까지)`}
    parser.columns = Object.values(columns)
    const items = document.items || []

    await new Exporter({ parser, items })
      .download(parser.text)
      .catch((e) => {
        console.log(`${e.message} ${e.stack}`)
        alert(`엑셀로 변환하여 다운로드하던 도중 문제가 발생하였습니다.`)
      })
    this.setState({ pending: false, progress: null })
  }

  // 전체회원 운영보고서 다운로드
  async downloadStaticsExcel() {
    if (this.state.pending) { return alert(`처리중입니다. 잠시만 기다려주세요.`) }
    const progress = { 
      message: `다운로드 대상 데이터 집계중... 🌳`,
      rate: 0,
      onClose: () => this.setState({ pending: false, progress: null })
    }
    this.setState({ pending: true, progress })

    const curAt = new Date(), curMt = moment(curAt)
    
    // 총 페이지수 구하기
    const limit = 100
    const queryString = qs.stringify({ page: 1, limit, usedCount: true })
    const output = await api.get(`/admins/statics-users?${queryString}`)

    if (!output.count) {
      this.setState({ pending: false, progress: null })
      return alert('다운로드 받을 수 있는 데이터가 존재하지 않습니다.')
    }

    const totalCount = output.count
    const pageCount = Math.ceil(totalCount/limit)

    const pages = Array.from({ length: pageCount })

    const rows = await pages.reduce((prev, blank, idx, arr) => 
      prev.then(async (report) => {
        const progress = { 
          message: `${idx+1}/${arr.length} 데이터(총 ${totalCount}건)를 다운로드중... 🐘`,
          rate: ((idx + 1)/arr.length) * 100,
          onClose: () => this.setState({ pending: false, progress: null })
        }
        this.setState({ pending: true, progress })
        if (!idx) { return report }

        const page = idx + 1
        const queryString = qs.stringify({ page, limit, usedCount: false })
        const output = await api.get(`/admins/statics-users?${queryString}`)
        report.push(...output.rows)
        return report
      }),
      Promise.resolve(output.rows))

    const columns = {}
    columns.accountId = { key: 'accountId', header: '계정명', view: (item = {}) => item.accountId }
    columns.createdAt = { key: 'createdAt', header: '생성일', view: (item = {}) => item.createdAt ? item.createdAt : '' }
    columns.email = { key: 'email', header: '이메일', view: (item = {}) => item.email ? item.email : '' }
    columns.loggedCount = { key: 'loggedCount', header: '로그인횟수', view: (item = {}) => item.loggedCount ? item.loggedCount : '' }
    columns.loggedAt = { key: 'loggedAt', header: '최근로그인일자', view: (item = {}) => item.loggedAt ? item.loggedAt : '' }
    columns.birthday = { key: 'birthday', header: '생년월일', view: (item = {}) => item.birthday ? item.birthday : '' }
    columns.joinedRoute = { key: 'joinedRoute', header: '계정가입형태', view: (item = {}) => item.joinedRoute ? item.joinedRoute : '' }

    columns.paperBookFirstCreatedAt = { key: 'paperBookFirstCreatedAt', header: '종이도서 최초생성일', view: (item = {}) => item.paperBookFirstCreatedAt ? item.paperBookFirstCreatedAt : '' }
    columns.paperBookCount = { key: 'paperBookCount', header: '종이도서  건수', view: (item = {}) => item.paperBookCount ? item.paperBookCount : '' }
    columns.paperBookActivatedProductCount = { key: 'paperBookActivatedProductCount', header: '종이도서 상품 활성종수', view: (item = {}) => item.paperBookActivatedProductCount ? item.paperBookActivatedProductCount : '' }
    columns.paperBookDeactivatedProductCount = { key: 'paperBookDeactivatedProductCount', header: '종이도서 상품 비활성종수', view: (item = {}) => item.paperBookDeactivatedProductCount ? item.paperBookDeactivatedProductCount : '' }
    columns.paperBookProductCount = { key: 'paperBookProductCount', header: '종이도서 상품 종수', view: (item = {}) => item.paperBookProductCount ? item.paperBookProductCount : '' }

    columns.electronicBookFirstCreatedAt = { key: 'electronicBookFirstCreatedAt', header: '전자도서 최초생성일', view: (item = {}) => item.electronicBookFirstCreatedAt ? item.electronicBookFirstCreatedAt : '' }
    columns.electronicBookCount = { key: 'electronicBookCount', header: '전자도서  건수', view: (item = {}) => item.electronicBookCount ? item.electronicBookCount : '' }
    columns.electronicBookActivatedProductCount = { key: 'electronicBookActivatedProductCount', header: '전자도서 상품 활성종수', view: (item = {}) => item.electronicBookActivatedProductCount ? item.electronicBookActivatedProductCount : '' }
    columns.electronicBookDeactivatedProductCount = { key: 'electronicBookDeactivatedProductCount', header: '전자도서 상품 비활성종수', view: (item = {}) => item.electronicBookDeactivatedProductCount ? item.electronicBookDeactivatedProductCount : '' }
    columns.electronicBookProductCount = { key: 'electronicBookProductCount', header: '전자도서 상품 종수', view: (item = {}) => item.electronicBookProductCount ? item.electronicBookProductCount : '' }

    columns.paperBookSalesCount = { key: 'paperBookSalesCount', header: '종이도서 판매건수', view: (item = {}) => item.paperBookSalesCount ? item.paperBookSalesCount : '' }
    columns.paperBookSalesTotal = { key: 'paperBookSalesTotal', header: '종이도서 판매액수', view: (item = {}) => item.paperBookSalesTotal ? item.paperBookSalesTotal : '' }
    columns.electronicBookSalesCount = { key: 'electronicBookSalesCount', header: '전자도서 판매건수', view: (item = {}) => item.electronicBookSalesCount ? item.electronicBookSalesCount : '' }
    columns.electronicBookSalesTotal = { key: 'electronicBookSalesTotal', header: '전자도서 판매액수', view: (item = {}) => item.electronicBookSalesTotal ? item.electronicBookSalesTotal : '' }
    columns.bookSalesCount = { key: 'bookSalesCount', header: '도서 판매건수', view: (item = {}) => item.bookSalesCount ? item.bookSalesCount : '' }
    columns.bookSalesTotal = { key: 'bookSalesTotal', header: '도서 판매액수', view: (item = {}) => item.bookSalesTotal ? item.bookSalesTotal : '' }

    columns.paperBookOrderCount = { key: 'paperBookOrderCount', header: '종이도서 구매개수', view: (item = {}) => item.paperBookOrderCount ? item.paperBookOrderCount : '' }
    columns.paperBookOrderTotal = { key: 'paperBookOrderTotal', header: '종이도서 구매액수', view: (item = {}) => item.paperBookOrderTotal ? item.paperBookOrderTotal : '' }
    columns.electronicBookOrderCount = { key: 'electronicBookOrderCount', header: '전자도서 구매액수', view: (item = {}) => item.electronicBookOrderCount ? item.electronicBookOrderCount : '' }
    columns.electronicBookOrderTotal = { key: 'electronicBookOrderTotal', header: '전자도서 구매액수', view: (item = {}) => item.electronicBookOrderTotal ? item.electronicBookOrderTotal : '' }
    columns.bookOrderCount = { key: 'bookOrderCount', header: '도서 구매액수', view: (item = {}) => item.bookOrderCount ? item.bookOrderCount : '' }
    columns.bookOrderTotal = { key: 'bookOrderTotal', header: '도서 구매액수', view: (item = {}) => item.bookOrderTotal ? item.bookOrderTotal : '' }

    const parser = { key: 'bookk-statics-report' }
    parser.text = `부크크 전체회원 통계데이터 ${curMt.format('YYYY-MM-DD HH시 mm분')}`
    parser.columns = Object.values(columns)
    
    await new Exporter({ parser, items: rows })
      .download(parser.text)
      .catch((e) => {
        console.log(`${e.message} ${e.stack}`)
        alert(`엑셀로 변환하여 다운로드하던 도중 문제가 발생하였습니다.`)
      })

    this.setState({ pending: false, progress: null })
  }

  // 초기화 기능
  async initialize() {
    const curAt = this.state.basedAt

    // 금일 날짜 기준으로 범위를 잡기
    const dayStartAt = moment(curAt).startOf('day').toDate()
    const dayEndAt = moment(curAt).endOf('day').toDate()

    const initialState = this.initialState(this.props)

    const panels = initialState.panels
      .reduce((s, panel) => {
        s[panel.name] = panel
        return s
      }, {})

    // 회원정보 패널
    await Promise.all([
      this.loadItem('userCount', '총', { endAt: dayEndAt, endAt: dayEndAt })
        .then((item) => item ? panels.userPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('userCount', '저자', { joined: 'author', endAt: dayEndAt })
        .then((item) => item ? panels.userPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('userCount', '전문가', { joined: 'expert', endAt: dayEndAt })
        .then((item) => item ? panels.userPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('newUserCount', '신규 ⭐', { startAt: dayStartAt, endAt: dayEndAt })
        .then((item) => item ? panels.userPanel.items.push(item) : null)
        .catch(e => null)
      ])
      .catch((e) => [])

    // 상품정보 패널
      await Promise.all([
        this.loadItem('productCount', '종이도서', { productType: 'paperBook', endAt: dayEndAt })
          .then((item) => item ? panels.productPanel.items.push(item) : null)
          .catch(e => null),
        this.loadItem('productCount', '전자도서', { productType: 'electronicBook', endAt: dayEndAt })
          .then((item) => item ? panels.productPanel.items.push(item) : null)
          .catch(e => null),
        this.loadItem('productCount', '서비스', { productType: 'solution', endAt: dayEndAt })
          .then((item) => item ? panels.productPanel.items.push(item) : null)
          .catch(e => null)
      ])
      .catch((e) => [])

    // 결제정보 판매패널
    await Promise.all([
      this.loadItem('payCount', '종이도서', { payType: 'paperBook', retail: 'bookk', startAt: dayStartAt, endAt: dayEndAt })
        .then((item) => item ? panels.bookkPayPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('payCount', '전자도서', { payType: 'electronicBook', retail: 'bookk', startAt: dayStartAt, endAt: dayEndAt })
        .then((item) => item ? panels.bookkPayPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('payCount', '서비스', { payType: 'solution', retail: 'bookk', startAt: dayStartAt, endAt: dayEndAt })
        .then((item) => item ? panels.bookkPayPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('payCount', '기타', { payType: 'etc', retail: 'bookk', startAt: dayStartAt, endAt: dayEndAt })
        .then((item) => item ? panels.bookkPayPanel.items.push(item) : null)
        .catch(e => null)
    ])
      .catch((e) => [])

    // 결제정보 외부유통패널 (종이책)
    await Promise.all(
      Object.keys(retailsKeys)
        .map((key) => {
          const rName = retailsKeys[key]
          return this.loadItem('payCount', rName, { payType: 'paperBook', retail: key, startAt: dayStartAt, endAt: dayEndAt })
            .then((item) => item ? panels.retailPaperBookPayPanel.items.push(item) : null)
            .catch(e => null)
        })
      ).catch((e) => [])

    // 결제정보 외부유통패널 (전자책)
    await Promise.all(
      Object.keys(retailsKeys)
        .map((key) => {
          const rName = retailsKeys[key]
          return this.loadItem('payCount', rName, { payType: 'electronicBook', retail: key, startAt: dayStartAt, endAt: dayEndAt })
            .then((item) => item ? panels.retailElectronicBookPayPanel.items.push(item) : null)
            .catch(e => null)
        })
      ).catch((e) => [])

    // 심사 패널
    await Promise.all([
      this.loadItem('approvalCount', '최초제출', { classify: 'bookSubmit', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.reviewPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '판매신청', { classify: 'bookRetail', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.reviewPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '중지신청', { classify: 'bookStopRetail', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.reviewPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '전문가등록', { classify: 'expertApply', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.reviewPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '서비스입점', { classify: 'solutionSubmit', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.reviewPanel.items.push(item) : null)
        .catch(e => null)
    ])
      .catch((e) => [])

    // 운영 패널
    await Promise.all([
      this.loadItem('approvalCount', '수기환불', { classify: 'refund', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '파일교체', { classify: 'bookFileChange', status: 'review', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '도서정보', { classify: 'bookModify', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '구매후기', { classify: 'payReview', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '샘플신청', { classify: 'paperSample', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '브런치이벤트', { classify: 'brunchEvent', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('approvalCount', '신고', { classify: 'report', status: 'wait', endAt: dayEndAt })
        .then((item) => item ? panels.managePanel.items.push(item) : null)
        .catch(e => null)
    ])
      .catch((e) => [])

    // 재고 패널
    await Promise.all([
      this.loadItem('totalStockAmount', '재고권수', {})
        .then((item) => item ? panels.stockPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('invoiceCount', '발주', {})
        .then((item) => item ? panels.stockPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('parcelCount', '발송대기', { status: 'wait' })
        .then((item) => item ? panels.stockPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('parcelCount', '발송준비', { status: 'ready' })
        .then((item) => item ? panels.stockPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('parcelCount', '포장중', { status: 'packing' })
        .then((item) => item ? panels.stockPanel.items.push(item) : null)
        .catch(e => null)  
    ])
      .catch((e) => [])

    // 정산 및 지급
    await Promise.all([
      this.loadItem('currentDesposit', '정산대기', { status: 'pending' })
        .then((item) => item ? panels.profitAPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('currentDesposit', '정산완료', { status: 'resolved' })
        .then((item) => item ? panels.profitAPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('currentWithdraw', '지급대기', { status: 'pending' })
        .then((item) => item ? panels.profitBPanel.items.push(item) : null)
        .catch(e => null),
      this.loadItem('currentWithdraw', '당월지급', { status: 'resolved' })
        .then((item) => item ? panels.profitBPanel.items.push(item) : null)
        .catch(e => null)
    ])
      .catch((e) => [])

    initialState.panels = Object.values(panels)

    const nextState = { ...initialState, loading: false }
    await new Promise(r => this.setState(nextState, () => r(this.state)))

    if (this.swiper1 && this.swiper1.current) {
      const swiper = this.swiper1.current.swiper || {}
      swiper.update()
    }

    if (this.swiper2 && this.swiper2.current) {
      const swiper = this.swiper2.current.swiper || {}
      swiper.update()
    }

    return nextState
  }

  render() {
    const { admin, signoutAdmin } = this.props
    const { loading, panels, basedAt } = this.state
    const basedMt = moment(basedAt)
    const curAt = new Date(), curMt = moment(curAt)
    const isToday = curMt.format('YYYY.MM.DD') === basedMt.format('YYYY.MM.DD')

    const prevDayMt = moment(basedAt).add(-1, 'days'), prevDayAt = prevDayMt.toDate()
    const nextDayMt = moment(basedAt).add(1, 'days'), nextDayAt = nextDayMt.toDate()

    /*
      1. 종이도서 금일통계 (현재사이트 판매종수, 권수, 판매금액/Retail별 판매종수, 권수, 판매금액, 환불신청)<br />
      4. 전자도서 판매건수, 판매 합계 금액, 환불신청, 미다운로드수<br/>
      2. 작가서비스 판매건수, 판매 합계 금액, 환불신청<br/>
      3. 기타 판매건수, 판매 합계 금액, 환불신청<br/>
      4. 임시도서, 제출된 도서, 심사완료된 도서, 심사반려된 도서<br/>
      5. 원고수정요청수, 원고 수정 제출 도서
      6. 샘플신청

      1. 전체회원수 (월, 일, 당일)
      2. 전체방문수 (월, 일, 당일)
    */

    const swiperProps = {
      slidesPerView: 1, spaceBetween: 50, autoHeight: true,
      breakpoints: {
        1280: { slidesPerView: 5, spaceBetween: 40 },
        1024: { slidesPerView: 3, spaceBetween: 40 },
        768: { slidesPerView: 2, spaceBetween: 30 },
        640: { slidesPerView: 1, spaceBetween: 20 }
      }
    }

    return (
      <Dashboard>
        {this.state.progress ? <ProgressModal {...(this.state.progress || {})} /> : null}

        <Dashboard.Header>
          {isToday
            ? (<div className="head">
                <span>{moment(basedAt).format('YYYY.MM.DD')}</span>
                <small>{moment(basedAt).format('HH:mm')}</small>
              </div>)
            : null}
          {!isToday
            ? (<div className="head">
                <span>{moment(basedAt).format('YYYY.MM.DD')}</span>
              </div>)
            : null}
          <div className="buttons">
            <span className="char">{admin.accountId.charAt(0).toUpperCase()} <small>@{admin.accountId} ({admin.name})</small></span>
            <a href="#signout" onClick={async (e) => [e.stopPropagation(), e.preventDefault(), await signoutAdmin()]}><Lnr c="exit" /></a>
          </div>
        </Dashboard.Header>
        
        <Dashboard.Tools>
          <Dashboard.Dates>
            <input type="date"
              value={moment(basedAt).format('YYYY-MM-DD')}
              pattern='YYYY-MM-DD'
              onChange={(e) => {
                const value = moment(e.target.value, 'YYYY-MM-DD').startOf('day')
                this.setState({ basedAt: value.toDate() })
                window.location.href = `/?basedAt=${value.toDate()}`
              }}
              />
          </Dashboard.Dates>
          <Dashboard.Buttons>
            <a
              href={`#download-excel-monthly`}
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                return await this.downloadExcel()
                  .catch((e) => {
                    alert(e.message)
                    console.log(e.message)
                    console.log(e.stack)
                  })
              }}
            >
              {this.state.pending ? `👷‍♂️ 다운로드중...` : `📥 엑셀`}
            </a>
          </Dashboard.Buttons>
        </Dashboard.Tools>
        
        <Swiper ref={this.swiper1} {...swiperProps}>
          {panels
            .filter(panel => ['userPanel',  'productPanel', 'reviewPanel', 'parcelPanel', 'managePanel', 'stockPanel'].includes(panel.name))
            .map((panel, pIdx) => {
            return (
              <SwiperSlide key={`Panel_${pIdx}`}>
                <Dashboard.Box>
                  {panel.header ? <Dashboard.Head>{panel.header}</Dashboard.Head> : null}
                  <Dashboard.Body>
                    {!panel.items.length ? <div style={{ padding: '0.5rem', opacity: '0.5' }}>🙋‍♂️ 불러오는중...</div> : null}
                    {panel.items.map((item, idx) => {
                      return (
                        <Dashboard.Meta
                          key={`Panel_${pIdx}_${idx}`}
                          onClick={e => [e.stopPropagation(), e.preventDefault()]}
                        >
                          <Dashboard.Label>{item.label}</Dashboard.Label>
                          <Dashboard.Value>
                            {['count'].includes(item.primary)
                              ? (
                                <strong>
                                  <div style={_.get(item, 'amount')  ? { paddingBottom: '0.35rem 0' } : {}}>
                                    {comma(item.count) || '0'}
                                    {item.unit ? <small style={{ marginLeft: '0.15rem', fontWeight: 100 }}>{item.unit}</small> : null}
                                  </div>
                                  {_.get(item, 'amount') ? <small>{comma(item.amount)} {item.subUnit || '원'}</small> : null}
                                </strong>
                              )
                              : null}
                            {['amount'].includes(item.primary)
                              ? (
                                <strong>
                                  <div style={_.get(item, 'count')  ? { paddingBottom: '0.35rem 0' } : {}}>
                                    {comma(item.amount) || '0'}
                                    {item.unit ? <small style={{ marginLeft: '0.15rem', fontWeight: 100 }}>{item.unit}</small> : null}
                                  </div>
                                  {_.get(item, 'count') ? <small>{comma(item.count)} {item.subUnit || '건'}</small> : null}
                                </strong>
                              )
                              : null}
                          </Dashboard.Value>
                        </Dashboard.Meta>
                      )
                    })}
                  </Dashboard.Body>
                </Dashboard.Box>
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Swiper ref={this.swiper2} {...swiperProps}>
          {panels
            .filter(panel => ['bookkPayPanel', 'retailElectronicBookPayPanel', 'retailPaperBookPayPanel', 'profitAPanel', 'profitBPanel'].includes(panel.name))
            .map((panel, pIdx) => {
            return (
              <SwiperSlide key={`Panel_${pIdx}`}>
                <Dashboard.Box>
                  {panel.header ? <Dashboard.Head>{panel.header}</Dashboard.Head> : null}
                  <Dashboard.Body>
                    {!panel.items.length ? <div style={{ padding: '0.5rem', opacity: '0.5' }}>🙋‍♂️ 불러오는중...</div> : null}
                    {panel.items.map((item, idx) => {
                      return (
                        <Dashboard.Meta
                          key={`Panel_${pIdx}_${idx}`}
                          onClick={e => [e.stopPropagation(), e.preventDefault()]}
                        >
                          <Dashboard.Label>{item.label}</Dashboard.Label>
                          <Dashboard.Value>
                            {['count'].includes(item.primary)
                              ? (
                                <strong>
                                  <div style={_.get(item, 'amount')  ? { paddingBottom: '0.35rem 0' } : {}}>
                                    {comma(item.count) || '0'}
                                    {item.unit ? <small style={{ marginLeft: '0.15rem', fontWeight: 100 }}>{item.unit}</small> : null}
                                  </div>
                                  {_.get(item, 'amount') ? <small>{comma(item.amount)} {item.subUnit || '원'}</small> : null}
                                </strong>
                              )
                              : null}
                            {['amount'].includes(item.primary)
                              ? (
                                <strong>
                                  <div style={_.get(item, 'count')  ? { paddingBottom: '0.35rem 0' } : {}}>
                                    {comma(item.amount) || '0'}
                                    {item.unit ? <small style={{ marginLeft: '0.15rem', fontWeight: 100 }}>{item.unit}</small> : null}
                                  </div>
                                  {_.get(item, 'count') ? <small>{comma(item.count)} {item.subUnit || '건'}</small> : null}
                                </strong>
                              )
                              : null}
                          </Dashboard.Value>
                        </Dashboard.Meta>
                      )
                    })}
                  </Dashboard.Body>
                </Dashboard.Box>
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Dashboard.Menus>
          <a
          href="#all-statics-downloads"
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            return await this.downloadStaticsExcel()
              .catch((e) => {
                console.log(e.message)
                console.log(e.stack)
                this.setState({ pending: false, progress: null })
              })
          }}
          >
             📅 전체회원 운영현황보고서
          </a>

          <a
          href="#hotfix-button"
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            const password = window.prompt('개발자 핫픽스 콜을 하기 위한 버튼으로, 시스템 관리자 비밀번호를 입력해주세요.')
            if (!password) { return alert(`비밀번호를 입력하지 않았습니다.`) }

            const query = {}
            query.password = password
            const queryString = qs.stringify(query)
            
            const output = await  api.get(`/admins/fixed-services?${queryString}`, { signal: this.abortController.signal })
              .catch((e) => {
                console.log(e.message)
                console.log(e.stack)
                return { error: true, message: e.message }
              })
            if (!output || output.error) { return output ? alert(output.message || '에러가 발생하였습니다.')  : alert('에러가 발생하였습니다. 시스템 점검이 필요합니다.') }

            alert('정상적으로 로직을 처리하였습니다. 결과물을 확인하세요.')
          }}
          >
            🌳 개발자 핫픽스버튼
          </a>
        </Dashboard.Menus>


      </Dashboard>
    )
  }
}

AdminDashboardContainer.propTypes = {
  admin: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  signoutAdmin: PropTypes.func
}

AdminDashboardContainer.defaultProps = {
  admin: {},
  location: {},
  history: {},
  match: {},
  signoutAdmin: async () => { }
}

const mapStateToProps = (state) => ({
  admin: fromAdmin.getInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  signoutAdmin: async () => dispatch(await actions.signoutAdmin())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardContainer)
