import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'services/moment'
import qs from 'query-string'
import api from 'services/api'
import _ from 'lodash'
import { comma, filterFileName } from 'services/utils'
import { Importer, Exporter } from 'services/transform'
import booksExportParser from './export.books'

import BookNoColumn from './Columns/BookNoColumn'
import BookColumn from './Columns/BookColumn'
import BookThumbnailColumn from './Columns/BookThumbnailColumn'
import ComplexDetailColumn from './Columns/ComplexDetailColumn'
import RetailColumn from './Columns/RetailColumn'
import UserDetailColumn from './Columns/UserDetailColumn'
import SubmitDetailColumn from './Columns/SubmitDetailColumn'
import BookCoverColumn from './Columns/BookCoverColumn'
import BookTextColumn from './Columns/BookTextColumn'
import BookPreviewColumn from './Columns/BookPreviewColumn'
import StockStorageColumn from './Columns/StockStorageColumn'
import BrunchColumn from './Columns/BrunchColumn'

import Dropdown from './Dropdown'
// import Menus from './Menus'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'

const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, resource, items, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems, getItems
}) => {
  const commonProps = { user, location, history, match }

    const [pending, setPending] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [progressModal, setProgressModal] = React.useState(false)

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  const columns = []
  columns.push({ key: 'BookNoColumn', header: '번호', style: { minWidth: '90px', maxWidth: '90px', justifyContent: 'center' } })
  columns.push({ key: 'BookColumn', header: '제출 또는 승인도서', style: { minWidth: '0', minWidth: '300px', maxWidth: '300px', justifyContent: 'flex-start' } })
  columns.push({ key: 'ComplexDetailColumn', header: '상세정보', style: { minWidth: '200px', maxWidth: '200px', justifyContent: 'flex-start' } })
  columns.push({ key: 'RetailColumn', header: '판매·입점', style: { minWidth: '180px', maxWidth: '180px', justifyContent: 'flex-start' } })
  columns.push({ key: 'UserDetailColumn', header: '등록자', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'flex-start' } })
  columns.push({ key: 'BookCoverColumn', header: '표지 원본', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookTextColumn', header: '원고 원본', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'SubmitDetailColumn', header: '제출일자', style: { minWidth: '130px', maxWidth: '130px', justifyContent: 'center' } })
  columns.push({ key: 'BookThumbnailColumn', header: '서점 썸네일', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'BookPreviewColumn', header: '서점 미리보기', style: { minWidth: '110px', maxWidth: '110px', justifyContent: 'center' } })
  columns.push({ key: 'StockStorageColumn', header: '재고서비스 이용현황', style: { minWidth: '290px', maxWidth: '290px', justifyContent: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 100%', minWidth: 0, maxWidth: '100%', justifyContent: 'center' } })
  columns.push({ key: 'BrunchColumn', header: '브런치', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center' } })
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })

  // 엑셀 파싱을 해야하는 양식 만들기
  const formats = {}

  // 조판작업 엑셀 양식
  formats.bookTextPages = async () => {
    const columns = []
    columns.push({
      header: '도서종류', key: 'bookType',
      view: (item) => { 
        const content = item.content || {}
        const { type } = content
        return ['paperBook'].includes(type) ? `종이도서` : `전자도서`
      }
    })
    columns.push({
      header: '도서번호', key: 'bookNo',
      view: (item) => { 
        const content = item.content || {}
        const { bookNo } = content

        return `${bookNo}`
      }
    })
    columns.push({
      header: '제목', key: 'bookTitle',
      view: (item) => { 
        const content = item.content || {}
        const fileName = content.title
        return `${fileName}`
      }
    })
    columns.push({
      header: '풀빼다', key: 'blank1',
      view: (item) => { 
        return ``
      }
    })
    columns.push({
      header: '빈페이지', key: 'blank2',
      view: (item) => { 
        return ``
      }
    })
    columns.push({
      header: '도서ID(해시값)', key: 'bookId',
      view: (item) => { 
        return `${item.id || item._id}`
      }
    })
    columns.push({
      header: '사이트명', key: 'siteFileName',
      view: (item) => { 
        const content = item.content || {}

        const attr = {}
        attr.bookSize = `${_.get(content, 'bookSize.text') || '판형없음'}`.replace('판', '')
        attr.title = filterFileName(_.get(content, 'title'))
        attr.textPageCount = _.get(content, 'text.pageCount')
        attr.textColor = _.get(content, 'text.color.name')
        attr.coverFlap = _.get(content, 'cover.flap')

        const str = []
        str.push(`[${attr.bookSize}]${attr.title}`)
        str.push(`${attr.textPageCount}p`)
        str.push(attr.textColor === 'color' ? 'CL' : 'BK')
        str.push(attr.coverFlap ? 'CF' : 'NCF')
        return str.join('_')
      }
    })
    columns.push({
      header: '빈칸', key: 'blank3',
      view: (item) => { 
        return ``
      }
    })
    columns.push({
      header: '표지파일명', key: 'coverFileName',
      view: (item) => { 
        const content = item.content || {}

        const attr = {}
        attr.bookSize = `${_.get(content, 'bookSize.text') || '판형없음'}`.replace('판', '')
        attr.title = filterFileName(_.get(content, 'title'))
        attr.textPageCount = _.get(content, 'text.pageCount')
        attr.textColor = _.get(content, 'text.color.name')
        attr.coverFlap = _.get(content, 'cover.flap')
        attr.hashed = item.id || item._id

        const str = []
        str.push(`[CV][${attr.bookSize}]${attr.title}`)
        str.push(`${attr.hashed}-CV`)
        return str.join('_')
      }
    })
    return { columns }
  }

  const actions = {}

  // @ 전도서 할인설정 기능
  actions.updateDiscountAmount = async (rate = 0) => {
    const payload = { form: { rate } }
    return await api.put(`/products/admin2/discount-amount-all`, payload)
      .catch((e) => ({ error: true, message: e.message }))
  }

  // @ 전체 데이터 불러오기
  actions.getItems = async (query = {}, options = {}) => {
    const lump = 100
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length} 블록으로 나누어 다운로드중...`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }

  // 검색필터 기준으로 도서 데이터 가져오기
  actions.downloadExcel = async (items = selecteds) => {
    const options = _.get(filterOptions, 'retails.conditions') || [], current = filters.retails
    const retail = options.find(option => option.name === current)
    if (!retail) { return alert(`다운로드하기 전에 반드시 유통사 필터를 선택 해주세요.`) }
    if (!items || items.length === 0) { return alert(`선택한 데이터가 없습니다.`) }

    const curAt = new Date(), curMt = moment(curAt)
    return await new Exporter({ parser: booksExportParser, items })
      .download(`도서목록 - ${retail.text} 총 ${items.length}건 (${curMt.format('YYYY년 MM월 DD일 HH시 mm분')})`)
  }

  // @ 내역기준으로 조판작업 엑셀 다운로드
  actions.doDownloadBookTextPagesExcel = async (items = [], options = {}) => {
    const curAt = new Date(), curMt = moment(curAt)
    const curSt = `${curMt.format('YYYY년 MM월 DD일 HH시 mm분')}`
    if (!items || items.length === 0) { return alert(`다운로드할 데이터가 없습니다.`) }

    const fileName = options.fileNameAs || `도서상품 페이지 관리 총 ${items.length}건 다운로드(${curSt})`
    const parser = await formats
      .bookTextPages()
      .catch(e => { return { error: true, message: e.message, columns: [] } })
    if (!parser || parser.error) {
      alert((parser && parser.message) || `올바른 엑셀 파서 데이터가 아닙니다.`)
      return false
    }

    return await new Exporter({ parser, items })
      .download(fileName)
      .catch((e) => {
        console.log(`${e.message} ${e.stack}`)
        alert(`엑셀로 변환하여 다운로드하던 도중 문제가 발생하였습니다.`)
        return false
      })
  }

  // @ 내지 페이지 정보 일괄 업데이트 프로그램
  actions.doUpdateBookTextPages = async () => {
    if (!window.confirm(`A열(도서번호, 숫자), D열(풀빼다, 해당 또는 비해당), E열(빈페이지1 or 2 or 3)으로 엑셀이 준비 되어야 합니다.`)) { return false }

    const start = 2
    const parser = {
      name: `도서 페이지수 일괄 업데이트`,
      columns: {
        'A': { name: 'bookNo', text: '도서번호', parse: (cell) => cell },
        'D': { name: 'textFullPrint', text: '풀빼다(해당,비해당)', parse: (cell) => cell },
        'E': { name: 'textBlankPageNos', text: '빈페이지 번호(1 or 2 or 3)', parse: (cell) => cell }
      }
    }

    const parsed = await new Importer({ parser }).open()
      .then(o => o.read(o.importedFile, start))
      .catch(e => ({ items: [] }))

    const items = parsed.items
    if (!items.length) { return alert(`해당 파일에서 데이터를 추출해내지 못했습니다.`) }

    const results = await items.reduce((prev, item) => 
      prev.then(async (report) => {
        const result = await api.put(`/products/admin2/updateBookTextPages`, { target: item })
          .catch((e) => ({ error: true, message: `${e.messasge} ${e.stack}` }))
        report.push(result)
        return report
      }),
      Promise.resvole([]))
    .catch((e) => [{ error: true, message: `${e.message} ${e.stack}` }])

    const errors = results.filter(r => r.error)
    if (errors.length) { return alert(`${errors.length}건의 에러가 발생하였습니다.`) }

    return alert(`총 ${items.length}건, 성공적으로 처리하였습니다.`)
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}건</strong>이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize({ reload: true }))]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize({ reload: true })) : null}
            />
          </div>
        </Header.Search>

        <Header.Options>
          <a
            href="#updateDiscountAmount"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              if (pending) { return alert(`전도서 대상 적용중입니다. 잠시만 기다려주세요.`) }
              let confirmed = window.confirm('전도서에 할인비율을 적용합니다. 0.1, 0.2 처럼 1 이하의 숫자로 입력해주세요. 0인경우 할인을 하지 않습니다. 진행할까요?')
              if (!confirmed) { return }
              let rate = window.prompt('1보다 작은 숫자를 입력해주세요. 예) 0.1 (10%)', '0.1')
              setPending(true)
              if (_.isNaN(rate * 1)) { rate = 0 }
              if (_.isNumber(rate * 1)) { rate = parseFloat(rate) }
              if (rate < 0) { rate = 0 }
              if (rate > 1) { rate = 1 }
              const result = await actions.updateDiscountAmount(rate).catch((e) => ({ error: true, message: `${e.message} ${e.stack}` }))
              setPending(false)
              alert(result.message)
            }}
          >
              전도서 할인설정 🎟️
          </a>
          {filterOptions.retails
            ? (
              <a
                href="#downloadExcelAll"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
    
                  const options = _.get(filterOptions, 'retails.conditions') || [], current = filters.retails
                  const retail = options.find(option => option.name === current)
                  if (!retail) { return alert(`다운로드하기 전에 반드시 외부유통 필터를 선택 해주세요.`) }
    
                  const targets = await actions.getItems({}, { progress: true }).catch(e => [])
                  return await actions.downloadExcel(targets)
                }}
              >
                  전체 엑셀 📥
              </a>
            )
            : null}
        </Header.Options>
        {/* <Header.Options>
          <a
            href="#doUpdateBookProducts"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return actions.doUpdateBookTextPages()
            }}
          >
            🏷️ 풀빼다/빈페이지 수 업데이트
          </a>
          <a
            href="#doUpdateBookProducts"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doDownloadBookTextPagesExcel(selecteds)
            }}
          >
             📥 조판작업(선택엑셀)
          </a>
          <a
            href="#doUpdateBookProducts"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              const items = await actions.getItems({ limit: 1000 }, { progress: true })
              return await actions.doDownloadBookTextPagesExcel(items)
            }}
          >
             📥 조판작업(전체엑셀)
          </a>
        </Header.Options> */}
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize({ reload: true }))
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize({ reload: true }))
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize({ reload: true }))
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize({ reload: true }))
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map(item => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    const columnProps = { column, columIdx, item, loadItems }

                    const meta = {}
                    meta.parent = _.get(item, 'parent')
                    meta.bookNo = _.get(item, 'content.bookNo')
                    meta.isBrunch = _.get(item, 'content.brunch')
                    meta.productId = _.get(item, 'id') || null

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'BookNoColumn':
                              return <BookNoColumn {...columnProps} />
                            case 'BookColumn':
                              return <div style={{ maxWidth: 'fit-content' }}><BookColumn {...columnProps} /></div>
                            case 'ComplexDetailColumn':
                              return <ComplexDetailColumn {...columnProps} />
                            case 'RetailColumn':
                              return <RetailColumn {...columnProps} />
                            case 'UserDetailColumn':
                              return <UserDetailColumn {...columnProps} />
                            case 'SubmitDetailColumn':
                              return <SubmitDetailColumn {...columnProps} />
                            case 'BrunchColumn':
                              return <BrunchColumn {...columnProps} />
                            case 'BookCoverColumn':
                              return <BookCoverColumn {...columnProps} />
                            case 'BookTextColumn':
                              return <BookTextColumn {...columnProps} />
                            case 'BookThumbnailColumn':
                              return <BookThumbnailColumn {...columnProps} />
                            case 'StockStorageColumn':
                              return <StockStorageColumn {...columnProps} />
                            case 'BookPreviewColumn':
                              return <BookPreviewColumn {...columnProps} />
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      {!items.length ? (
        <Items.NotFound>
          <header>앗! 데이터가 존재하지 않습니다.</header>
          <section>현재 데이터가 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize({ reload: true }))} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 30,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '',
  nav: ''
}

export default List
